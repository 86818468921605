// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Box, Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListRow, TableListHeader } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./PlanVariables.module.css";

const PlanVariablesList = ({ planVariables, handlePlanVariableUpdate, handlePlanVariableDelete }) => {
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.planVariable.idmenu);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  const theme = useTheme();

  return (
    <>
      {planVariables.length ? (
        <Box>
          <TableListHeader>
            <Grid item xs={5}>
              <Typography color={theme.palette.primary.main}>Variable Name</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color={theme.palette.primary.main}>Variable ID</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color={theme.palette.primary.main}>Type</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color={theme.palette.primary.main}>Default Value</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <Typography color={theme.palette.primary.main}>Actions</Typography>
            </Grid>
          </TableListHeader>
          {addNewLoader && (
            <div className={styles.Fake}>
              <div className={`${styles.Cover} ${styles.Disabled}`}>
                <CircularProgress size={25} className={styles.Loader} />
              </div>
              <TableListRow>
                <div></div>
              </TableListRow>
            </div>
          )}
          {planVariables.map((planVariable) => (
            <div key={planVariable.creadate}>
              <TableListRow>
                <Grid item xs={5}>
                  <Typography color={theme.palette.text.primary}>{planVariable.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.text.primary}>{planVariable.varname}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.text.primary}>{planVariable.xtype}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.text.primary}>{planVariable.xvalue}</Typography>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" display="flex">
                  <SettingsButton
                    options={[
                      {
                        text: "Edit",
                        type: "edit",
                        callback: () => handlePlanVariableUpdate(planVariable),
                        access: hasEditAccess,
                      },
                      {
                        text: "Delete",
                        type: "delete",
                        callback: () => handlePlanVariableDelete(planVariable),
                        danger: true,
                        access: hasDeleteAccess,
                      },
                    ]}
                  />
                </Grid>
              </TableListRow>
            </div>
          ))}
        </Box>
      ) : (
        <>{paginationLoading ? null : <NoDataScreen pageName="planVariables" />}</>
      )}
    </>
  );
};

export default PlanVariablesList;
