import React from "react";
import { Link } from "react-router-dom";
import { Grid, useTheme, Box, Typography, Button } from "@mui/material";
import styles from "./Landing.module.css";
// ? RESOURCES
import landingHero from "resource/images/landing-hero.png";
import theme from "theme";
import { ROUTES } from "resource/routes/routes";

const LandingHero = () => {
  return (
    <>
      <Box
        p={5}
        sx={{
          backgroundImage: `url(${landingHero})`,
        }}
        className={styles.HeroImage}
      >
        <Typography className={styles.Heading}>
          Accounting, project management, purchasing, and estimating — made simple
        </Typography>
        <Typography color={theme.palette.text.primary} className={styles.HeroText}>
          A single platform for everything your construction project needs — designed by construction professionals, for
          construction professionals.
        </Typography>
        <Link to={ROUTES.signup.pathname} className={styles.Button}>
          <Typography variant="h2" color={theme.palette.background.default}>
            Get started now
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default LandingHero;
