import { useEffect, useState, FC } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchPartByIdAction } from "redux-store/redux-actions/parts";
import { updatePartAction } from "redux-store/redux-actions/parts";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";

import PartsVendorPricingList from "pages/parts/PartsVendorPricingList";
import PartsVendorPricingForm from "pages/parts/PartsVendorPricingForm";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { CircularProgress } from "@mui/material";
import { PartOnResponse } from "resource/interfaces/part";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

// ? INTERFACE
import { PartOnRequest } from "resource/interfaces/part";

// ? RESOURCES
import { PARTS, PARTS_NAME } from "resource/constants/parts";
import { ActionName } from "resource/constants/api";
import PartsForm from "pages/parts/PartsForm";

export const nameUpdate = `${PARTS_NAME}/${ActionName.UPDATE}`;
interface PartUpdateProps {
  className: string;
}

const PartUpdate: FC<PartUpdateProps> = ({ className }) => {
  const dispatch = useDispatch();

  const {
    close,
    isOpened,
    data: { upc },
  } = useModal(nameUpdate);

  const part = useSelector((state: RootStateOrAny): PartOnResponse | undefined => {
    if (isOpened && upc !== undefined) {
      return state.parts.parts.find((it: PartOnResponse) => it.upc === upc);
    }
  });

  useEffect(() => {
    if (isOpened && upc !== undefined) {
      dispatch(fetchPartByIdAction(upc));
    }
  }, [upc, isOpened, dispatch]);

  const [isLoading, setLoader] = useState(false);
  const updatePart = useAsync(updatePartAction);

  const handleSubmit = async (data: PartOnRequest) => {
    setLoader(true);

    try {
      await updatePart({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={className}>
      {isOpened && part ? (
        <div className={styles.PartsForm}>
          <div className={styles.PartsFormWrapper}>
            <PartsForm
              title={PARTS.EDIT_MODAL_TITLE}
              formData={part}
              buttonText={PARTS.EDIT_MODAL_SUBMIT_BUTTON}
              handleClose={close}
              actionType={ActionName.UPDATE}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
            />
            <div className={`${styles.PartsBlock} ${styles.PartsVendorPricingBlock}`}>
              <PartsVendorPricingList
                idtrack={part.idtrack}
                upc={part.upc}
                vendorPricingList={part.vendorpricing ? part.vendorpricing : []}
                close={close}
              />
              <PartsVendorPricingForm idtrack={part.idtrack} upc={part.upc} actionType={ActionName.CREATE} />
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PartUpdate;
