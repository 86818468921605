import React from "react";
import { Container, Typography, useTheme, Box, Grid } from "@mui/material";
import LandingNavigation from "pages/landing/LandingNavigation";
import AppBar from "components/app-bar/AppBar";
import styles from "./Landing.module.css";
import LandingPricingCard from "./LandingPricingCard";
import LandingFooter from "./LandingFooter";
import { Plans } from "resource/constants/landing";

const LandingPricing = () => {
  const theme = useTheme();

  return (
    <>
      <AppBar navigation={<LandingNavigation />} />
      <Box className={styles.Pricing}>
        <Box className={styles.Moto} sx={{ background: "inherit" }}>
          <Typography
            sx={{ width: "50%", mb: 2, fontWeight: "600", fontSize: "44px", mx: "auto" }}
            variant="h1"
            color={theme.palette.background.default}
          >
            Pricing
          </Typography>
          <Typography sx={{ width: "40%", lineHeight: "140%", mx: "auto" }} variant="h1" color={theme.palette.background.default}>
            Plans that scale with your business growth
          </Typography>
          <Box className={styles.CardWrapper}>
            <Grid container spacing={{ xs: 1, md: 10 }} className={styles.CardInner}>
              <Grid item md={4} sx={{ display: "flex" }}>
                <LandingPricingCard
                  plan={Plans.team.planName}
                  text={Plans.team.features}
                  subtitle={Plans.team.subtitile}
                  price={Plans.team.price}
                  btn={Plans.team.btnText}
                  color={Plans.team.color}
                />
              </Grid>

              <Grid item md={4} sx={{ display: "flex" }}>
                <LandingPricingCard
                  plan={Plans.professional.planName}
                  text={Plans.professional.features}
                  subtitle={Plans.professional.subtitle}
                  price={Plans.professional.price}
                  btn={Plans.professional.btnText}
                  color={Plans.professional.color}
                />
              </Grid>

              <Grid item md={4} sx={{ display: "flex" }}>
                <LandingPricingCard
                  plan={Plans.enterprise.planName}
                  text={Plans.enterprise.features}
                  subtitle={Plans.enterprise.subtitle}
                  price={Plans.enterprise.price}
                  btn={Plans.enterprise.btnText}
                  color={Plans.enterprise.color}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: "70%", m: "auto", py: "50px" }}>
            <Typography variant="h1" sx={{ fontWeight: "600", my: "25px" }}>
              Use with confidence
            </Typography>
            <Grid container spacing={3} sx={{ px: "70px" }}>
              <Grid item md={4}>
                <Typography variant="h3" sx={{ my: "10px", fontWeight: "600" }}>
                  Money Back Guarantee
                </Typography>
                <Typography variant="body1">Full 30-day guarantee, no questions asked.</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="h3" sx={{ my: "10px", fontWeight: "600" }}>
                  Keep Discount For Life
                </Typography>
                <Typography variant="body1">Your discount stays for as long as you subscribe.</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="h3" sx={{ my: "10px", fontWeight: "600" }}>
                  Cancel Whenever
                </Typography>
                <Typography variant="body1">No lock-in period. Cancel whenever you want.</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <LandingFooter />
      </Box>
    </>
  );
};

export default LandingPricing;
