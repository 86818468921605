import { FC, KeyboardEvent } from "react";
import DatePicker from "react-datepicker";

// ? STYLES
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Calendar.module.css";

interface CalendarProps {
  handleDatePickerChange: (update) => void;
  handleKeyDown: (evt: KeyboardEvent<HTMLImageElement>) => void;
  options: any;
  name?: string;
  className?: string;
  disabled?: boolean;
}

const Calendar: FC<CalendarProps> = ({ handleDatePickerChange, handleKeyDown, options, name, className, disabled }) => {
  return (
    <div>
      <DatePicker
        {...options}
        onChange={(update) => {
          handleDatePickerChange(update);
        }}
        onKeyDown={handleKeyDown}
        wrapperClassName={className ? `${styles.DatePickerWide}` : `${styles.DatePicker}`}
        className={`${styles.DatePickerInput}`}
        popperClassName={`${styles.DatePickerPopper}`}
        calendarClassName={`${styles.DatePickerCalendar}`}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};

export default Calendar;
