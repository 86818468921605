//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { CHANGE_ORDERS_NAME } from "resource/constants/change-order";
import { CHANGE_ORDERS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${CHANGE_ORDERS_NAME}/uploadFile`;

const ChangeOrderFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    name: data.idproject1,
  };

  const dataForDelete = {
    name: data.idproject1,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Change Order-${data.idnumber} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={CHANGE_ORDERS_API}
    />
  );
};

export default ChangeOrderFileServer;
