import React from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Divider, Button } from "@mui/material";
import styles from "./Landing.module.css";
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";
import { CONTACT_EMAIL } from "resource/consts";

const LandingPricingCard = ({ plan, text, subtitle, price, btn, color }) => {
  const mainPath = ROUTES.pay.pathname;
  const subDirectories = [plan];

  const isPriceFixed = typeof price === "number";

  return (
    <Box className={styles.Card}>
      <Typography variant="h2" className={styles.CardTitle} sx={{ color: `${color}` }}>
        {plan}
      </Typography>

      <Typography className={styles.CardSubtitle}>{subtitle}</Typography>
      <Typography className={styles.CardPrice}>{isPriceFixed ? `${price}$ / month` : price}</Typography>
      <Divider />
      <Box className={styles.CardFlexible}>
        {text.map((item) => (
          <Typography key={`${plan}-${item}`} className={styles.CardText}>
            {`- ${item}`}
          </Typography>
        ))}
      </Box>
      <Box sx={{ mx: "auto" }}>
        {isPriceFixed ? (
          <Link to={resolvePath(mainPath, subDirectories)} className={styles.Button}>
            <Typography className={styles.CardBtn}>{btn}</Typography>
          </Link>
        ) : (
          <Button className={styles.Button} onClick={() => window.open(`mailto:${CONTACT_EMAIL}`)}>
            <Typography className={styles.CardBtn}>{btn}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default LandingPricingCard;
