import { useState } from "react";

// ? REDUX
import { releasePurchaseOrderAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import PurchaseOrderEmailForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderEmailForm";
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { PurchaseOrderReleaseRequest } from "resource/interfaces/purchase-order";

interface emailData {
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
}

export const nameCreateReleaseEmail = `${PURCHASE_ORDER_NAME}/createReleaseEmail`;

const PurchaseOrderCreateReleaseEmail = () => {
  const releasePurchaseOrder = useAsync(releasePurchaseOrderAction);
  const { close, data } = useModal(nameCreateReleaseEmail);
  const [isLoading, setLoader] = useState<boolean>(false);

  const handleReleasePurchaseOrder = async (emailValues: emailData) => {
    const dataToSubmit: PurchaseOrderReleaseRequest = { ...data, ...emailValues };

    setLoader(true);
    try {
      await releasePurchaseOrder({ data: dataToSubmit, actionType: PurchaseOrderEndpoints.RELEASE });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreateReleaseEmail}>
      <PurchaseOrderEmailForm
        handleSubmit={handleReleasePurchaseOrder}
        title={`Release PO-${data.iddoc}`}
        buttonText="Save and Release"
        isLoading={isLoading}
        handleClose={close}
        total={data.total}
        subject={`Release >>> ${data.nproject} ${data.iddoc}`}
        idvendor={data.idvendor}
      />
    </ModalManager>
  );
};

export default PurchaseOrderCreateReleaseEmail;
