import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import PaymentSummaryItem from "./PaymentSummaryItem";

interface PaymentSummaryProps {
  paymentSummaries: any[];
}

// ? STYLES
import styles from "./PaymentSummary.module.css";

const PaymentSummary: FC<PaymentSummaryProps> = ({ paymentSummaries }) => {
  const theme = useTheme();
  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Project ID</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.primary.main}>Name</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Location</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.primary.main}>Plan Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Open</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Take Off</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
            Total
          </Typography>
        </Grid>
      </TableListHeader>
      {paymentSummaries.map((paymentSummary, i) => (
        <div key={i}>
          <PaymentSummaryItem paymentSummary={paymentSummary} />
        </div>
      ))}
    </>
  );
};

export default PaymentSummary;
