import { useState } from "react";

// ? REDUX
import { addProjectTakeoffBudgetAction } from "redux-store/redux-actions/projectTakeoffs";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";

import TakeoffsBudgetForm from "pages/takeoffs/TakeoffsBudgetForm";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";

// ? RESOURCES
import { PROJECT_TAKEOFFS_NAME, TAKEOFFS, TakeoffEndpoints } from "resource/constants/takeoffs";
import { ActionName } from "resource/constants/api";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
export const nameAddBudget = `${PROJECT_TAKEOFFS_NAME}Budget/${ActionName.CREATE}`;

const initFormValues = {
  idcostcode: null,
  budgetrevised: 0,
  notes: "",
};

const ProjectTakeoffAddBudget = () => {
  const { close, isOpened, data } = useModal(nameAddBudget);
  const [isLoading, setLoader] = useState(false);
  const [setNotice] = useNotice();
  const addProjectTakeoffBudget = useAsync(addProjectTakeoffBudgetAction);

  const handleSubmit = async (formData) => {
    setLoader(true);
    const valuesForBudgetCreate = {
      idnumber: "0",
      idproject1: String(data.id),
      idcia: String(data.idcia),
      idcostcode: formData.idcostcode,
    };

    const valuesForBudgetUpdate = { ...formData };
    try {
      await addProjectTakeoffBudget({
        valuesForBudgetCreate,
        valuesForBudgetUpdate,
        endpoint: TakeoffEndpoints.UpdateBudget,
        dataForFetch: { id: data.id, idcia: data.idcia },
      });
      setNotice("Budget was successfully added", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameAddBudget}>
      {isOpened ? (
        <div className={styles.LargeForm}>
          <TakeoffsBudgetForm
            title={TAKEOFFS.ADD_BUDGET}
            formData={initFormValues}
            buttonText="Save"
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default ProjectTakeoffAddBudget;
