import { useMemo, useState } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchUsersAction, clearDataAction } from "redux-store/redux-actions/membersProfile";

// ? COMPONENTS
import { CircularProgress } from "@mui/material";
import { MembersList } from "./MembersList";
import { Search } from "components/search/Search";
import { nameCreate } from "components/modals/create/MembersCreate";
import { withLayout } from "hoc/withLayout";
import PageHeader from "components/page-header/PageHeader";
import MembersCreate from "components/modals/create/MembersCreate";
import MembersUpdate from "components/modals/update/MembersUpdate";
import MemberDelete from "components/modals/delete/MembersDelete";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import MembersProfileFileServer from "components/modals/file-server-modal/MembersProfileFileServer";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { MEMBERS_PROFILE } from "resource/constants/members-profile";
import { filterOptions } from "resource/constants/members-profile";
import { menu } from "resource/constants/menu-list";

const MembersProfile = () => {
  const dispatch = useDispatch();
  const { open: openCreate } = useModal(nameCreate);
  const { hasAddAccess } = useAccess(menu.membersProfile.idmenu);
  const users = useSelector((state: RootStateOrAny) => state.members.users);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const usersCount = useSelector((state: RootStateOrAny) => state.members.usersCount);
  const hasMoreToLoad = usersCount > users.length;

  const [searchKeyword, setSearchKeyword] = useState<any>(null);
  const [status, setStatus] = useState(filterOptions[0]);

  const additionalData = useMemo(
    () => ({
      status,
      keywords: searchKeyword,
    }),
    [searchKeyword, status]
  );

  const { setPage } = useInfiniteLoading(fetchUsersAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  const handleFilterChange = (evt): void => {
    setStatus(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  return (
    <>
      <PageHeader pageName="membersProfile">
        <FilterSelect handleChange={handleFilterChange} select={status} options={filterOptions} />
        <Search value={searchValue} placeholder="Search members" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={MEMBERS_PROFILE.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <MembersList users={users} />
      {paginationLoading && (
        <div className={`LoaderScroll ${!users.length && "center"}`}>
          <CircularProgress />
        </div>
      )}

      <MembersCreate />
      <MembersUpdate />
      <MemberDelete />
      <MembersProfileFileServer />

      {/* <div>
        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={styles.Modal}
        >
          <>
            {!deleteUser ? (
              <MemberProfileForm
                title={!editUser ? MEMBERS_PROFILE.NEW_MODAL_TITLE : MEMBERS_PROFILE.EDIT_MODAL_TITLE}
                buttonText={!editUser ? MEMBERS_PROFILE.NEW_MODAL_SUBMIT_BUTTON : MEMBERS_PROFILE.EDIT_MODAL_SUBMIT_BUTTON}
                formData={editUser}
                handleClose={closeModal}
                validation={{}}
                size="xlarge"
                isLoading={uploadLoading}
              />
            ) : (
              <DeleteForm
                name={deleteUser.name}
                data={deleteUser.data}
                type={deleteUser.type}
                closeModal={closeModal}
                actionName={deleteUserServerAction}
                isLoading={uploadLoading}
              />
            )}
          </>
        </Modal>
      </div> */}
    </>
  );
};

export default withLayout(MembersProfile);
