import { useState, FC } from "react";

// ? REDUX
import { addNewVendorAction } from "redux-store/redux-actions/vendors";
import { useSelector, RootStateOrAny } from "react-redux";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { VendorsForm } from "pages/vendors/VendorsForm";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import { VENDORS_NAME, VENDORS } from "resource/constants/vendors";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { convertLocalDateToISOString, trimFormStrings } from "resource/utils";

export const nameCreate = `${VENDORS_NAME}/${ActionName.CREATE}`;


const VendorCreate: FC = () => {
  const { close, isOpened } = useModal(nameCreate);
  const [isLoading, setLoader] = useState(false);
  const createVendor = useAsync(addNewVendorAction);
  const [setNotice] = useNotice();

  const defaultType = useSelector((state: RootStateOrAny) => state.vendors.options.vtype.list[0].value);
  const defaultStatus = useSelector((state: RootStateOrAny) => state.vendors.options.inactiveyn.list[0].value);
  const defaultCategory = useSelector(
    (state: RootStateOrAny) =>
      state.vendors.options.category?.list &&
      state.vendors.options.category.list[0] &&
      state.vendors.options.category.list[0].value
  );
  const defaultPayterm = useSelector(
    (state: RootStateOrAny) =>
      state.vendors.options.payterms?.list &&
      state.vendors.options.payterms.list[0] &&
      state.vendors.options.payterms.list[0].value
  );

  const initialValues = {
    name: null,
    taddress: null,
    vtype: defaultType,
    inactiveyn: defaultStatus,
    category: defaultCategory,
    payterms: defaultPayterm,
    costcodeList: [],
    paymentledger: null,
    primaryAccount: "",
    contact: null,
    federalid: null,
    tcity: null,
    tstate: null,
    tzip: null,
    phone1: null,
    phone2: null,
    phone3: null,
    email: null,
    website: null,
    insdate: null,
    workdate: null,
    inspercent: null,
    insexpire: false,
    disapply: false,
    taxrate: null,
    disrate: null,
    disterm: null,
    dueterm: null,
    invoiceyn: false,
    form1099: false,
  };

  // SUBMIT
  const handleSubmit = async (data, workDate, insuranceDate) => {
    setLoader(true);

    if (data.insexpire) {
      data.workdate = convertLocalDateToISOString(workDate.toLocaleDateString().slice(0, 10));
      data.insdate = convertLocalDateToISOString(insuranceDate.toLocaleDateString().slice(0, 10));
    }

    if (data.email === "") {
      data.email = null;
    }

    try {
      await createVendor({
        values: trimFormStrings(data),
        actionType: ActionName.CREATE,
      });
      setNotice("Vendor created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={styles.Modal}>
      {isOpened && initialValues ? (
        <VendorsForm
          title={VENDORS.NEW_MODAL_TITLE}
          buttonText={VENDORS.NEW_MODAL_SUBMIT_BUTTON}
          formData={initialValues}
          handleClose={close}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default VendorCreate;
