import { JobCostActions } from "redux-store/redux-actions/jobCost";
import { getUniqueData } from "resource/utils";

const initialState = {
  jobCosts: [],
  jobCostsError: "",
  jobCostsCount: 0,
  jobCostSublist: [],
};

export const jobCostReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case JobCostActions.PUT_JOB_COSTS:
      return {
        ...state,
        jobCosts: [...state.jobCosts, ...getUniqueData(payload.data, state.jobCosts, "idnumber")],
        jobCostsCount: payload.count as number,
      };
    case JobCostActions.PUT_JOB_COST_SUBLIST:
      return {
        ...state,
        jobCostSublist: [...payload],
      };
    case JobCostActions.CLEAR_JOB_COST_DATA:
      return {
        ...state,
        jobCosts: [],
        jobCostsCount: 0,
      };
    case JobCostActions.CATCH_JOB_COSTS_ERROR:
      return { ...state, jobCostsError: payload as string };
    default:
      return state;
  }
};
