import { useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import {
  fetchDevelopmentDetailAction,
  editDevelopmentAction,
  getDevelopmentDetailAction,
} from "redux-store/redux-actions/developments";
import { clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { Formik, Form } from "formik";
import { Grid, CircularProgress, Button } from "@mui/material";
import { TabsUnstyled, TabsListUnstyled, TabPanelUnstyled, buttonUnstyledClasses, TabUnstyled } from "@mui/base";
import { styled } from "@mui/system";
import DevelopmentsDetailFieldTab from "./DevelopmentsDetailFieldTab";
import DevelopmentsDetailLinkedProjectsTab from "./DevelopmentsDetailLinkedProjectsTab";
import DevelopmentsDetailFilesTab from "./DevelopmentsDetailFilesTab";
import DevelopmentsDetailMembersTab from "./DevelopmentsDetailMembersTab";
import DevelopmentsDetailHeader from "./DevelopmentsDetailHeader";
import { FormField } from "components/form-field/FormField";
import { SearchField } from "components/form-field/SearchField";
import useAsync from "custom-hooks/useAsync";
import DevelopmentDelete from "components/modals/delete/DevelopmentDelete";
import useModal from "custom-hooks/useModal";
import { nameDelete } from "components/modals/delete/DevelopmentDelete";
import DevelopmentsFileServer from "components/modals/file-server-modal/DevelopmentsFileServer";
import FilePreview from "components/modals/file-preview/FilePreview";
import { nameFilePreview } from "components/modals/file-preview/FilePreview";
import useAccess from "custom-hooks/useAccess";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "./Developments.module.css";

// ? RESOURCES
import { DEVELOPMENTS_API } from "resource/constants/api";
import { useSearch } from "custom-hooks/useSearch";
import { ActionName } from "resource/constants/api";
import { menu } from "resource/constants/menu-list";

const Tab = styled(TabUnstyled)`
  color: #596a72;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0;
  border: none;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background: transparent;

  &.Mui-selected {
    color: #0100be;
    font-weight: bold;
    background: #f4f4ff;
    border-radius: 12px;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #0100be;
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
`;

const TabsList = styled(TabsListUnstyled)`
  border-radius: 12px;
  padding: 0 10px 0 10px;
  margin: 20px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  background: #ffffff;
  padding: 8px 16px;
`;

const statuses = [
  { label: "Active", value: "Active" },
  { label: "Closed", value: "Closed" },
];

const DevelopmentsDetail = ({ id, idcia }) => {
  const dispatch = useDispatch();

  const [setNotice] = useNotice();
  const { hasEditAccess } = useAccess(menu.developments.idmenu);
  const developmentDetail = useSelector((state: RootStateOrAny) => state.developments.developmentDetail);

  const [banksOptions, setBanksQuery, isBanksPending, initialBanksCondition, banksErrorMessage] = useSearch(
    `${DEVELOPMENTS_API}/bank`,
    { idcia }
  );

  const { open: openDelete } = useModal(nameDelete);
  const handleDevelopmentDelete = (): void => {
    openDelete({ ...developmentDetail, type: "Development" });
  };

  const { open: openFilePreview } = useModal(nameFilePreview);

  const [updateLoader, setUpdateLoader] = useState(false);
  const updateDevelopment = useAsync(editDevelopmentAction);

  const [isUpdate, setUpdateCounter] = useState(0);

  const handleSubmit = async (data) => {
    setUpdateLoader(true);

    try {
      await updateDevelopment({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setNotice("Development updated", noticeTypes.success, 4000);
      setUpdateLoader(false);
      setUpdateCounter((prev) => prev + 1);
      close();
    } catch (e) {
      console.error(e);
      setUpdateLoader(false);
    }
  };

  const formikValues = developmentDetail && {
    idnumber: developmentDetail.idnumber,
    idcia: developmentDetail.idcia,
    name: developmentDetail.name,
    status: developmentDetail.status,
    taddress: developmentDetail.taddress,
    tcity: developmentDetail.tcity,
    tstate: developmentDetail.tstate,
    tzip: developmentDetail.tzip,
    bankledger: developmentDetail.bankledger,
    idtrack: developmentDetail.idtrack,
  };

  const [localFieldList, setLocalFieldList] = useState([]);

  const preparedData = (values) => {
    const valuesCopy = values;
    if (localFieldList && localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any, index) => {
          return {
            idnumber: field.idnumber,
            xid: field.xid,
            name: field.name,
            xtype: field.xtype,
            txtvalue: field.txtvalue || "",
          };
        });
    }

    return valuesCopy;
  };

  const [detailsLoader, setDetailsLoader] = useState(false);

  const fetchDetailsRequest = useAsync(fetchDevelopmentDetailAction);

  const fetchDetails = async () => {
    setDetailsLoader(true);

    try {
      await fetchDetailsRequest({ idnumber: id, idcia });
      setDetailsLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setDetailsLoader(false);
    }
  };

  useEffect(() => {
    fetchDetails();

    return () => {
      dispatch(getDevelopmentDetailAction({}));
      dispatch(clearFileServerDataAction());
    };
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [isUpdate]);

  return (
    <>
      {developmentDetail && !detailsLoader && (
        <>
          <Formik initialValues={formikValues} onSubmit={(values: any) => handleSubmit(preparedData(values))}>
            {({ handleChange, values, setFieldValue, handleSubmit }) => (
              <>
                <DevelopmentsDetailHeader
                  developmentItem={developmentDetail}
                  submit={handleSubmit}
                  handleDevelopmentDelete={handleDevelopmentDelete}
                  updateLoader={updateLoader}
                />
                <div className={styles.DevelopmentsForm}>
                  <Form>
                    <fieldset className={styles.Fieldset} disabled={!hasEditAccess}>
                      <Grid className={styles.SubForm} container justifyContent="space-between">
                        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="Development Name"
                              name="name"
                              placeholder="Enter development name"
                              type="text"
                              disabled={idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="Status"
                              name="status"
                              placeholder="Select status"
                              type="select"
                              options={statuses}
                              disabled={!hasEditAccess || idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="Address"
                              name="taddress"
                              placeholder="Enter address"
                              type="text"
                              disabled={idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="City"
                              name="tcity"
                              placeholder="Enter city name"
                              type="text"
                              disabled={idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="State"
                              name="tstate"
                              placeholder="Enter state name"
                              type="text"
                              disabled={idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <FormField
                              size="100%"
                              title="Zip Code"
                              name="tzip"
                              placeholder="Enter zip code"
                              type="number"
                              disabled={idcia === null ? true : false}
                            />
                          </Grid>
                          <Grid item xs="auto" sm={6} lg={3}>
                            <SearchField
                              size="100%"
                              title="Prefered Bank Account"
                              responseName="bankledger"
                              name="bankledger"
                              label="name"
                              placeholder="Search bank account"
                              setFieldValue={setFieldValue}
                              initialFormValues={formikValues}
                              defaultValue={
                                developmentDetail.bankledger
                                  ? { name: developmentDetail.bankname, label: developmentDetail.bankledger }
                                  : null
                              }
                              options={banksOptions}
                              onQueryChange={setBanksQuery}
                              isPending={isBanksPending}
                              initialCondition={initialBanksCondition}
                              disabled={!hasEditAccess || idcia === null ? true : false}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </fieldset>
                  </Form>

                  <TabsUnstyled defaultValue={0}>
                    <TabsList>
                      <Tab>Fields</Tab>
                      <Tab>Linked Projects</Tab>
                      <Tab>Development Files</Tab>
                      <Tab>Members</Tab>
                    </TabsList>
                    <TabPanel value={0}>
                      <DevelopmentsDetailFieldTab
                        idnumber={developmentDetail.idnumber}
                        idcia={idcia}
                        type="update"
                        callback={setLocalFieldList}
                        hasAccess={hasEditAccess}
                      />
                    </TabPanel>
                    <TabPanel value={1}>
                      <DevelopmentsDetailLinkedProjectsTab idnumber={developmentDetail.idnumber} idcia={idcia} />
                    </TabPanel>
                    <TabPanel value={2}>
                      <DevelopmentsDetailFilesTab
                        idnumber={developmentDetail.idnumber}
                        idcia={idcia}
                        openFilePreview={openFilePreview}
                      />
                    </TabPanel>
                    <TabPanel value={3}>
                      <DevelopmentsDetailMembersTab idnumber={developmentDetail.idnumber} idcia={idcia} />
                    </TabPanel>
                  </TabsUnstyled>
                </div>
              </>
            )}
          </Formik>
        </>
      )}

      <DevelopmentDelete />
      <DevelopmentsFileServer />
      <FilePreview />

      {detailsLoader && (
        <div className={`LoaderScroll  center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(DevelopmentsDetail);
