//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { PURCHASE_ORDER_NAME } from "resource/constants/purchase-order";
import { PURCHASE_ORDER_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${PURCHASE_ORDER_NAME}/uploadFile`;

const PurchaseOrderFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    iddoc: data.iddoc,
    idcia: data.idcia,
  };

  const dataForDelete = {
    name: data.idproject,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
    idcia: data.idcia,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`PO-${data.iddoc} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={PURCHASE_ORDER_API}
    />
  );
};

export default PurchaseOrderFileServer;
