import { FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Collapse } from "@mui/material";
import PlanTakeoffPartItem from "./PlanTakeoffPartItem";
import PlanTakeoffPartForm from "./PlanTakeoffAddPart";

interface PlanTakeoffPartsProps {
  partIds: number[];
  open: boolean;
  takeoffId: number;
  assemblyId: number;
  searchQuery: string;
}

const PlanTakeoffParts: FC<PlanTakeoffPartsProps> = ({ partIds, open, takeoffId, assemblyId, searchQuery }) => {
  const parts = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffParts);

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <>
        <PlanTakeoffPartForm takeoffId={takeoffId} assemblyId={assemblyId} />
        {partIds.map((item: number, i: number) => (
          <div key={item}>
            <PlanTakeoffPartItem part={parts[item]} takeoffId={takeoffId} searchQuery={searchQuery} />
          </div>
        ))}
      </>
    </Collapse>
  );
};

export default PlanTakeoffParts;
