export const DEVELOPMENTS_NAME = "developments";

export const DEVELOPMENTS = {
  TITLE: "Developments",
  NEW_MODAL_TITLE: "Add New Development",
  EDIT_MODAL_TITLE: "Update Development",
  NEW_MODAL_PLUS_BUTTON: "Add New Development",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Development",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Development",
};

export const developmentsFiltersList = ["-All-", "Active", "Closed"];
