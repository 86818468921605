// ? REACT
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme, Box, Tooltip } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";

// ? RESOURCES
import { formatPrice, resolvePath } from "resource/utils";
import { ColorListMap } from "resource/consts";
import { ROUTES } from "resource/routes/routes";
import FileServerClipIcon from "components/shared/file-server/FileServerClipIcon";
import { getFormattedDate } from "resource/utils";
import styles from "./PurchaseOrder.module.css";

const PurchaseOrderItem = ({ order }) => {
  const { fs, doc, idcia, nproject, nvendor, invoice, total, refdate, status, iddoc, idnumber, notes } = order;
  const theme = useTheme();
  const mainPath = ROUTES.purchaseOrder.pathname;
  const subDirectories = [idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1.2} display="flex" alignItems="center">
          <FileServerClipIcon fs={fs} />
          <Typography sx={{ marginLeft: "12px" }} color={theme.palette.text.primary}>
            {iddoc}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.text.primary}>{doc}</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.text.primary}>{idcia}</Typography>
        </Grid>
        <Grid item xs={2.3}>
          <Typography color={theme.palette.text.primary}>{nproject}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Box component="span" display="flex" justifyContent="space-between">
            <Box component="span">
              <Typography component="span">{nvendor}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{invoice}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{formatPrice(total.toFixed(2))}</Typography>
        </Grid>
        <Grid item xs={1.8}>
          <Typography sx={{ color: `rgb(${ColorListMap[status]})` }}>{`${status} #${iddoc}`}</Typography>
        </Grid>
        <Grid item xs={0.8}>
          <Typography color={theme.palette.text.primary}>{getFormattedDate(refdate)}</Typography>
        </Grid>
        <Grid item xs={0.8}>
          <Tooltip title={notes}>
            <Typography color={theme.palette.text.primary} className={styles.NotesTruncated}>
              {notes && notes}
            </Typography>
          </Tooltip>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default PurchaseOrderItem;
