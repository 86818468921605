import { useMemo, useState, ChangeEvent, useEffect } from "react";

// ?REDUX
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import {
  fetchVendorsAction,
  clearDataAction,
  fetchVendorsTypeListAction,
} from "redux-store/redux-actions/vendors";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import { VendorsList } from "./VendorsList";
import { withLayout } from "hoc/withLayout";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import { Search } from "components/search/Search";
import VendorsFileServer from "components/modals/file-server-modal/VendorsFileServer";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import useModal from "custom-hooks/useModal";
import VendorCreate from "components/modals/create/VendorCreate";
import VendorUpdate from "components/modals/update/VendorUpdate";
import VendorDelete from "components/modals/delete/VendorDelete";

// ? RESOURCES
import { VENDORS } from "resource/constants/vendors";
import { menu } from "resource/constants/menu-list";
import { nameCreate } from "components/modals/create/VendorCreate";

const Vendors = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.vendors.idmenu);

  const { open: openCreate } = useModal(nameCreate);

  useEffect(() => {
    dispatch(fetchVendorsTypeListAction());
  }, []);

  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const vendors = useSelector((state: RootStateOrAny) => state.vendors.vendors);
  const vendorsCount = useSelector((state: RootStateOrAny) => state.vendors.vendorsCount);
  const hasMoreToLoad = vendorsCount > vendors.length;

  const vendorsFilter = useSelector(
    (state: RootStateOrAny) => vendors && ["-All-", ...state.vendors.options.inactiveyn.list.map((option) => option.label)]
  );
  const vendorsCategory = useSelector(
    (state: RootStateOrAny) => vendors && ["-All-", ...state.vendors.options.category.list.map((option) => option.label)]
  );

  const [searchKeyword, setSearchKeyword] = useState<any>(null);
  const [filter, setFilter] = useState<any>(vendorsFilter[0]);
  const [category, setCategory] = useState<any>("-All-");

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
      category: category === "-All-" ? null : category,
      filter,
    }),
    [searchKeyword, filter, category]
  );

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setFilter(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handleCategoryChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setCategory(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const { setPage } = useInfiniteLoading(fetchVendorsAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  return (
    <>
      <PageHeader pageName="vendors">
        <FilterSelect handleChange={handleFilterChange} select={filter} options={vendorsFilter} />
        {vendorsCategory.length > 0 && (
          <FilterSelect handleChange={handleCategoryChange} select={category} options={vendorsCategory} />
        )}
        <Search value={searchValue} placeholder="Search vendors" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={VENDORS.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <VendorsList />

      <VendorCreate />
      <VendorUpdate />
      <VendorDelete />
      <VendorsFileServer />
    </>
  );
};

export default withLayout(Vendors);
