import { takeLatest, put } from "redux-saga/effects";
import { VENDOR_PRICING_API, PARTS_API, COMPANY_LOCATIONS_API, VENDORS_API } from "resource/constants/api";
import { get, post, catchError } from "./_utils-abstract-saga-network";

import {
  VenorPricingActions,
  getVendorAction,
  getIdgeoareaAction,
  getDataAction,
  getDataDetailsAction,
  fetchDataDetailsAction,
  deleteProductStoreAction,
  putProductAction,
} from "../redux-actions/vendorPricing";

import { setGlobalLoaderAction, setButtonLoaderAction, setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchDataWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { idgeoarea, keywords },
      page,
    },
  } = action;

  const params = { idgeoarea, pageIndex: page, pageSize: 10 };

  const { errno, message, data } = yield get(`${VENDOR_PRICING_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getDataAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDataDetailsWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords, idgeoarea, idvendor },
      page,
    },
  } = action;

  const params = { idgeoarea, idvendor, pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${VENDOR_PRICING_API}/vendorparts`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getDataDetailsAction({ data: data.list, count: data.count }));
    yield put(clearNotifierAction());
    yield put(setPaginationLoaderAction(false));
  }
}

function* updateProductWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { upc, idgeoarea, idvendor } = values;

  if (actionType === "create") {
    const { data: partData } = yield get(`${PARTS_API}/${upc}`);
    values.idtrack = partData.idtrack;
  }

  const { errno, message } = yield post(`${PARTS_API}/${actionType}vendor`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`${actionType} product successfull`);
    yield put(fetchDataDetailsAction({ additionalData: { idgeoarea, idvendor, keywords: "" }, page: 0 }));
    yield put(clearNotifierAction());
  }
}

function* fetchIdgeoareaWorker() {
  yield put(setGlobalLoaderAction(true));

  const { errno, message, data } = yield get(`${COMPANY_LOCATIONS_API}/list`, { pageIndex: 0, pageSize: 200 });

  if (errno !== 0) {
    yield put(setGlobalLoaderAction(false));
    yield catchError(message);
  } else {
    console.log("Download location successfull");
    const { idnumber, name } = data.list[0];
    yield put(getIdgeoareaAction({ idnumber, name }));
    yield put(clearNotifierAction());
    yield put(setGlobalLoaderAction(false));
  }
}

function* deleteProductWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { upc } = values;

  const { errno, message } = yield post(`${PARTS_API}/${actionType}vendor`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete Product successfull");
    yield put(deleteProductStoreAction({ upc }));
    yield put(clearNotifierAction());
  }
}

function* productActivationWorker(action) {
  const {
    payload: { formData, setChecked, setLocalLoading },
  } = action;

  yield setChecked((prev) => !prev);
  yield setLocalLoading(true);

  const { errno, message } = yield post(`${PARTS_API}/updatevendor`, formData, "json");

  if (errno !== 0) {
    yield setLocalLoading(false);
    yield catchError(message);
    yield setChecked((prev) => !prev);
  } else {
    console.log("Vendor activate/inactivate");
    yield put(clearNotifierAction());
    yield put(
      fetchDataDetailsAction({
        formData,
        mode: "edit",
        setLocalLoading,
      })
    );
  }
}

function* fetchVendorWorker(action) {
  const {
    payload: { idvendor },
  } = action;

  const { errno, message, data } = yield get(`${VENDORS_API}/${idvendor}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    console.log("Vendor download successful");
    yield put(clearNotifierAction());
    yield put(getVendorAction(data));
  }
}

export function* vendorPricingSaga() {
  yield takeLatest(VenorPricingActions.FETCH_DATA, fetchDataWorker);
  yield takeLatest(VenorPricingActions.FETCH_DATA_DETAILS, fetchDataDetailsWorker);
  yield takeLatest(VenorPricingActions.ADD_NEW_PRODUCT, updateProductWorker);
  yield takeLatest(VenorPricingActions.EDIT_PRODUCT, updateProductWorker);
  yield takeLatest(VenorPricingActions.FETCH_IDGEOAREA, fetchIdgeoareaWorker);
  yield takeLatest(VenorPricingActions.DELETE_PRODUCT_FROM_SERVER, deleteProductWorker);
  yield takeLatest(VenorPricingActions.PRODUCT_ACTIVATION, productActivationWorker);
  yield takeLatest(VenorPricingActions.FETCH_VENDOR_INFO_BY_ID, fetchVendorWorker);
}
