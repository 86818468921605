import { FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { AssemblingCard } from "components/info-card/AssemblingCard";
import { Grid } from "@mui/material";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import { AssemblyRequest, AssemblingDeleteType } from "resource/interfaces/assemblies";

interface AssembliesListProps {
  assemblies: AssemblyRequest[];
}

export const AssembliesList: FC<AssembliesListProps> = ({ assemblies }) => {
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  if (!paginationLoading && !assemblies.length) {
    return <NoDataScreen pageName="usersProfile" />;
  }

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
      {addNewLoader && (
        <Grid item xs="auto" sm={6} lg={4}>
          <AssemblingCard data={null} fake />
        </Grid>
      )}
      {assemblies.map((assembling: AssemblyRequest, index: number) => (
        <Grid key={index} item xs="auto" sm={6} lg={4}>
          <AssemblingCard data={assembling} />
        </Grid>
      ))}
    </Grid>
  );
};
