import { FC } from "react";

// ? COMPONENTS
import { Box, Typography, useTheme } from "@mui/material";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

// ? RESOURCES
import { PurchaseOrderDetailResponse } from "resource/interfaces/purchase-order";
import notesIcon from "resource/images/notes.svg";
import exclamationIcon from "resource/images/exclamation.svg";

interface PurchaseOrderNotesProps {
  order: PurchaseOrderDetailResponse;
  noteType: string;
}

const PurchaseOrderNotes: FC<PurchaseOrderNotesProps> = ({ order, noteType }) => {
  const theme = useTheme();

  const TYPES = {
    disapprove: {
      text: order.disapprnotes ? `${order.disapprreason}: ${order.disapprnotes}` : order.disapprreason,
      icon: exclamationIcon,
      className: styles.PoDetailsDisapp,
      textColor: theme.palette.error.light,
      iconStyle: { width: "30px", marginRight: "10px" },
    },
    void: {
      text: order.voidnotes ? `${order.voidreason}: ${order.voidnotes}` : order.voidreason,
      icon: notesIcon,
      className: styles.PoDetailsNotes,
      textColor: theme.palette.primary.main,
      iconStyle: { width: "14px", marginRight: "10px" },
    },
  };

  return (
    <Box className={TYPES[noteType].className} display="flex" alignItems="center">
      <img src={TYPES[noteType].icon} alt="Notes icon" style={TYPES[noteType].iconStyle} />
      <Typography variant="h3" color={TYPES[noteType].textColor}>
        {TYPES[noteType].text}
      </Typography>
    </Box>
  );
};

export default PurchaseOrderNotes;
