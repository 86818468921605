import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

// ? COMPONENTS
import { Card, Avatar, CardHeader, CardContent, Divider } from "@mui/material";
import { CardField } from "components/card-field/CardField";

// ? STYLES
import styles from "./InfoCard.module.css";

// ? UTILS
import { getAvatar, updateFullName } from "resource/utils";

// ? TYPES
export interface CardPropsTypes {
  data: any;
  idgeoarea: number | string;
}

export const VendorPricingCard = ({ data, idgeoarea }: CardPropsTypes) => {
  const { idvendor, vendor_name, contact, phone, mobile, email, found } = data;

  const location = useLocation();

  const [avatar, setAvatar] = useState("");
  const [avatarColor, setAvatarColor] = useState("");

  const createDetailsData = () => {
    const vendorDetails = {
      vendorName: vendor_name,
      idvendor,
      idgeoarea,
    };
    localStorage.setItem("vendorDetails", JSON.stringify(vendorDetails));
  };

  useEffect(() => {
    getAvatar(vendor_name, setAvatar, setAvatarColor);
  }, [vendor_name]);

  return (
    <Card sx={{ maxWidth: 345 }} className={styles.InfoCard} onClick={createDetailsData}>
      <Link to={`${location.pathname}/${idvendor}`}>
        <CardHeader
          className={styles.CardHeader}
          avatar={
            <Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar">
              {avatar}
            </Avatar>
          }
          title={updateFullName(vendor_name)}
          subheader={`some email`}
        />
        <Divider />
        <CardContent className={styles.InfoCardContent}>
          <CardField size="50%" title="Vendor No" name={idvendor} />
          <CardField size="50%" title="Contact Name" name={contact} />
          <CardField size="50%" title="Phone Number" name={phone} />
          <CardField size="50%" title="Mobile Number" name={mobile} />
          <CardField size="50%" title="Email Address" name={email} />
          <CardField size="50%" title="Found" name={found} />
        </CardContent>
      </Link>
    </Card>
  );
};
