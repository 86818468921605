import { useState, useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPurchaseOrderByIdAction } from "redux-store/redux-actions/purchaseOrder";
import { clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { Box, Grid, CircularProgress, Typography, useTheme } from "@mui/material";
import PurchaseOrderNotes from "./PurchaseOrderNotes";
import PurchaseOrderVoidConfirm from "components/modals/confirm/PurchaseOrderVoidConfirm";
import PurchaseOrderCreateReleaseEmail from "components/modals/create/PurchaseOrderCreateReleaseEmail";
import PurchaseOrderRequestApprovalEmail from "components/modals/create/PurchaseOrderRequestApprovalEmail";
import PurchaseOrderCreateDisapproveEmail from "components/modals/create/PurchaseOrderCreateDisapproveEmail";
import PurchaseOrderCreateApproveEmail from "components/modals/create/PurchaseOrderCreateApproveEmail";
import PurchaseOrderUpdate from "components/modals/update/PurchaseOrderUpdate";
import PurchaseOrderDetailParts from "./PurchaseOrderDetailParts";
import PurchaseOrderAddPartForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderAddPartForm";
import PurchaseOrderDetailFooter from "pages/purchase-order/purchase-order-details/PurchaseOrderDetailFooter";
import PurchaseOrderFileServer from "components/modals/file-server-modal/PurchaseOrderFileServer";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { PurchaseOrderDetailResponse } from "resource/interfaces/purchase-order";
import { StatusList } from "resource/consts";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";
import PurchaseOrderDetailHeader from "./PurchaseOrderDetailHeader";

interface PurchaseOrderDetailProps {
  id: string;
}

const PurchaseOrderDetail: FC<PurchaseOrderDetailProps> = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.purchaseOrder.idmenu);
  const [loader, setLoader] = useState(true);
  const isPurchaseOrderDetailExist = useSelector((state: RootStateOrAny) => state.purchaseOrder.isPurchaseOrderDetailExist);
  const order = useSelector((state: RootStateOrAny) => {
    if (isPurchaseOrderDetailExist) {
      return state.purchaseOrder.purchaseOrder.find((order: PurchaseOrderDetailResponse) => order.idnumber === id);
    }
  });

  useEffect(() => {
    if (order) {
      setLoader(false);
    }
  }, [order]);

  useEffect(() => {
    setLoader(true);
    dispatch(fetchPurchaseOrderByIdAction(id));

    return () => {
      dispatch(clearFileServerDataAction());
    };
  }, [id, dispatch]);

  return (
    <>
      {!loader && order && (
        <>
          <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ minHeight: "inherit" }}>
            <div>
              <PurchaseOrderDetailHeader order={order} />
              <Box>
                <Typography variant="body1" color={theme.palette.text.primary}>
                  Project:
                </Typography>
                <Typography variant="h3" color={theme.palette.info.main}>
                  {order.nproject}
                </Typography>
              </Box>
              <Grid container sx={{ my: 2 }} spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    Bill to:
                  </Typography>
                  <Typography variant="body1" color={theme.palette.info.main}>
                    {order.billto}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    Notes:
                  </Typography>
                  <Typography variant="body1" color={theme.palette.info.main}>
                    {order.shipto}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ mb: 1 }}>{order.voidreason && <PurchaseOrderNotes order={order} noteType="void" />}</Box>
              <Box sx={{ mb: 1 }}>{order.disapprreason && <PurchaseOrderNotes order={order} noteType="disapprove" />}</Box>

              <Grid container className={styles.PoDetailsInfo}>
                <Grid item sm={2} className={`${styles.PoDetailsInfoItem} ${styles.PoDetailsInfoItemFisrt}`}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Reason
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.nreason}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={styles.PoDetailsInfoItem}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Cost Code Number
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.idcostcode}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={styles.PoDetailsInfoItem}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Cost Code Name
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.ncostcode}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={styles.PoDetailsInfoItem}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Delivery Date
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.delivery}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={styles.PoDetailsInfoItem}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Invoice
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.invoice}
                  </Typography>
                </Grid>
                <Grid item sm={2} className={`${styles.PoDetailsInfoItem} ${styles.PoDetailsInfoItemLast}`}>
                  <Typography variant="body1" color={theme.palette.info.main} className={styles.PoListSpacing}>
                    Payment Reference
                  </Typography>
                  <Typography variant="h3" color={theme.palette.text.primary}>
                    {order.paidreference}
                  </Typography>
                </Grid>
              </Grid>
              {order.partsList && <PurchaseOrderDetailParts order={order} isEditable={order.status === StatusList.DRAFT} />}
            </div>
            {order.status === StatusList.DRAFT && hasEditAccess && <PurchaseOrderAddPartForm order={order} />}
            <PurchaseOrderDetailFooter order={order} />
          </Box>
          <PurchaseOrderVoidConfirm />
          <PurchaseOrderCreateReleaseEmail />
          <PurchaseOrderRequestApprovalEmail />
          <PurchaseOrderCreateDisapproveEmail />
          <PurchaseOrderCreateApproveEmail />
          <PurchaseOrderFileServer />
          <PurchaseOrderUpdate className={styles.PoModal} />
        </>
      )}

      {loader && (
        <div className={`LoaderScroll  center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(PurchaseOrderDetail);
