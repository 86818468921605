import { VariablesActions } from "redux-store/redux-actions/planVariables";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const initialState = {
  planVariables: [],
  planVariablesCount: 0,
  planVariablesError: "",
};

export const planVariablesReducer = (state = initialState, actions) => {
  const { type, payload } = actions;

  switch (type) {
    case VariablesActions.PUT_PLAN_VARIABLES:
      return {
        ...state,
        planVariables: [...state.planVariables, ...getUniqueData(payload.data, state.planVariables, "idnumber")],
        planVariablesCount: payload.count,
      };
    case VariablesActions.PUT_PLAN_VARIABLE_BY_ID:
      return {
        ...state,
        planVariables: updateCollection(state.planVariables, payload, "idnumber"),
      };
    case VariablesActions.DELETE_PLAN_VARIABLE_FROM_STORE:
      return {
        ...state,
        planVariables: [...state.planVariables.filter((variable: any) => variable.idnumber !== payload.id)],
      };
    case VariablesActions.CLEAR_VARIABLES:
      return {
        ...state,
        planVariables: [],
        planVariablesCount: 0,
      };
    case VariablesActions.CATCH_PLAN_VARIABLES_ERROR:
      return { ...state, planVariablesError: payload };
    default:
      return state;
  }
};
