import { FileServerActions } from "redux-store/redux-actions/fileServer";

const initialState = {
  files: {},
  filesOrder: {},
  fileFolders: [],
  fileServerErrors: "",
};

export const fileServerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FileServerActions.PUT_FILE_FOLDERS:
      return {
        ...state,
        fileFolders: payload,
      };
    case FileServerActions.PUT_FILES:
      return {
        ...state,
        files: { ...state.files, [payload.folder]: payload.data.entities[payload.folder] },
        filesOrder: { ...state.filesOrder, [payload.folder]: payload.data.result },
      };
    case FileServerActions.DELETE_FILE_FROM_STORE:
      return {
        ...state,
        filesOrder: {
          ...state.filesOrder,
          [payload.folder]: state.filesOrder[payload.folder].filter((item: string) => item !== String(payload.id)),
        },
      };
    case FileServerActions.CLEAR_FILE_SERVER_DATA:
      return {
        ...state,
        files: {},
        filesOrder: {},
        fileFolders: [],
      };
    case FileServerActions.CATCH_FILE_SERVER_ERROR:
      return { ...state, fileServerErrors: payload as string };
    default:
      return state;
  }
};
