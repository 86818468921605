import { useState, useRef, FC } from "react";
//  ?  COMPONENTS
import { Formik, Field, Form } from "formik";
import { Box, Grid, Typography, useTheme, Select, MenuItem, InputLabel, FormGroup } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? RESOURCES
import { convertBase64, removeBase64Prefix } from "resource/utils";
import attachmentIcon from "resource/images/attachment-icon.svg";
import notesIcon from "resource/images/notes.svg";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

interface FileServerUploadFileProps {
  handleSubmit: any;
  buttonText: string;
  folders: string[];
  isLoading: boolean;
}

const initUploadedFileValue = { fname: "", fileData: "" };

const FileServerUploadFile: FC<FileServerUploadFileProps> = ({ handleSubmit, buttonText, folders, isLoading }) => {
  const theme = useTheme();
  const attachmentRef = useRef<HTMLInputElement>(null);

  const [uploadedFile, setUploadedFile] = useState<any>(initUploadedFileValue);
  const [isDisabled, setIsDisabled] = useState(false);

  const uploadFile = async (evt) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);

      setIsDisabled(Number(file.size) / 1024 / 1024 >= 10);
      setUploadedFile({
        fname: file.name,
        fileData: String(convertedFile),
      });
    }
  };

  return (
    <Formik
      initialValues={{
        folder: folders[0],
      }}
      onSubmit={(values) => {
        const updatedValues = {
          fileData: removeBase64Prefix(uploadedFile.fileData),
          fname: uploadedFile.fname,
          folder: values.folder,
        };

        !isDisabled &&
          handleSubmit({
            updatedValues,
            reset: () => {
              setUploadedFile(initUploadedFileValue);
              setIsDisabled(false);
            },
          });
      }}
    >
      <Form style={{ padding: 0, width: "100%", margin: 0 }}>
        <Grid container spacing={2} sx={{ p: 0 }}>
          <Grid item sm={5}>
            <FormGroup>
              <InputLabel htmlFor="fileData">
                <Typography variant="body1" color={theme.palette.info.main}>
                  Choose File
                </Typography>
              </InputLabel>
              <input
                ref={attachmentRef}
                hidden
                type="file"
                onChange={(evt) => {
                  setIsDisabled(false);
                  uploadFile(evt);
                }}
              />
              <Box
                className={styles.PartsFormUpload}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  attachmentRef?.current?.click();
                }}
              >
                {!uploadedFile.fileData ? (
                  <>
                    <Typography className={styles.PartsFormUploadText}>Upload File</Typography>
                    <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                  </>
                ) : (
                  <Box display="flex" alignItems="center">
                    <img src={notesIcon} alt={uploadedFile.fname} style={{ width: "35px", marginRight: "5px" }} />
                    <Typography sx={{ wordBreak: "break-all" }}>{uploadedFile.fname}</Typography>
                  </Box>
                )}
              </Box>
            </FormGroup>
          </Grid>
          <Grid item sm={5}>
            <InputLabel htmlFor="folder">
              <Typography variant="body1" color={theme.palette.info.main}>
                Choose Folder
              </Typography>
            </InputLabel>
            <Field style={{ width: "100%" }} name="folder" id="folder" type="select" as={Select}>
              {folders.map((value) => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Field>
          </Grid>
          <Grid item md={2} alignSelf="center">
            <SubmitButton text={buttonText} isLoading={isLoading} isDisabled={isDisabled} />
          </Grid>
        </Grid>

        {isDisabled && <ValidationError>The file size can not exceed 10MB</ValidationError>}
      </Form>
    </Formik>
  );
};

export default FileServerUploadFile;
