import { FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import PlanTakeoffItem from "./PlanTakeoffItem";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";

interface PlanTakeoffsProps {
  planTakeoffs: any[];
  planTakeoffIds: string[] | number[];
}

const PlanTakeoffsList: FC<PlanTakeoffsProps> = ({ planTakeoffs, planTakeoffIds }) => {
  const theme = useTheme();

  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);
  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Plan No</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={theme.palette.primary.main}>Plan Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Marketing Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Revision #</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Sq.Ft.</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Price Sq.Ft.</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Takeoff</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
      </TableListHeader>
      {addNewLoader && (
        <div className={styles.Fake}>
          <div className={`${styles.Cover} ${styles.Disabled}`}>
            <CircularProgress size={25} className={styles.Loader} />
          </div>
          <TableListRow>
            <div></div>
          </TableListRow>
        </div>
      )}
      {planTakeoffIds.map((id: string | number) => (
        <div key={id}>
          <PlanTakeoffItem planTakeoff={planTakeoffs[id]} />
        </div>
      ))}
    </>
  );
};

export default PlanTakeoffsList;
