import { useMemo } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchDevelopmentsFilesAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import { Box, CircularProgress, IconButton } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import { DevelopmetsDetailsFilesTabItem } from "./DevelopmetsDetailsFilesTabItem";

// ? STYLES
import styles from "./Developments.module.css";

// ? RESOURCES
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useFileSelector } from "custom-hooks/useFileSelector";

const DevelopmentsDetailFilesTab = ({ idnumber, idcia, openFilePreview }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // STORE
  const files = useSelector((state: RootStateOrAny) => state.developments.developmentsFiles);
  const filesCount = useSelector((state: RootStateOrAny) => state.developments.developmentsFilesCount);
  const hasMoreToLoad = filesCount > files.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia,
    }),
    [idnumber, idcia]
  );

  // COPY FILES LINKS
  const [selectedFiles, selectFile, copySelectedFilesLinks] = useFileSelector("fid");

  useInfiniteLoading(fetchDevelopmentsFilesAction, hasMoreToLoad, paginationLoading, additionalData);

  return (
    <>
      <Box className={styles.DevelopmentsDetails}>
        <TableListHeader>
          <Grid item xs={4}>
            <Typography color={theme.palette.primary.main}>Document Name</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Size</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Type</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Attach</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>View</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Delete</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton className={styles.CopyButton} disabled={selectedFiles.length === 0} onClick={copySelectedFilesLinks}>
              <ContentCopyIcon />
            </IconButton>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Updated By</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Date</Typography>
          </Grid>
        </TableListHeader>
        <div className={styles.FormList}>
          {files.length > 0 &&
            files.map((file, index) => (
              <div key={index}>
                <DevelopmetsDetailsFilesTabItem
                  file={file}
                  index={index}
                  idnumber={idnumber}
                  idcia={idcia}
                  select={selectFile}
                  openFilePreview={openFilePreview}
                />
              </div>
            ))}
          {paginationLoading ? (
            <div className={`LoaderScroll ${!files.length && "center"}`}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default DevelopmentsDetailFilesTab;
