import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum VenorPricingActions {
  ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  GET_DATA = "GET_DATA",
  FETCH_DATA = "FETCH_DATA",
  GET_DATA_DETAILS = "GET_DATA_DETAILS",
  FETCH_DATA_DETAILS = "FETCH_DATA_DETAILS",
  FETCH_IDGEOAREA = "FETCH_IDGEOAREA",
  GET_IDGEOAREA = "GET_IDGEOAREA",
  PRODUCT_ACTIVATION = "PRODUCT_ACTIVATION",
  DELETE_PRODUCT_FROM_SERVER = "DELETE_PRODUCT_FROM_SERVER",
  DELETE_PRODUCT_FROM_STORE = "DELETE_PRODUCT_FROM_STORE",
  CATCH_VENDOR_PRICING_ERROR = "CATCH_VENDOR_PRICING_ERROR",
  FETCH_VENDOR_INFO_BY_ID = "FETCH_VENDOR_INFO_BY_ID",
  GET_VENDOR_INFO_BY_ID = "GET_VENDOR_INFO_BY_ID",
  CLEAR_VPRICING_DATA = "CLEAR_VPRICING_DATA",
  PUT_PRODUCT_BY_UPC = "PUT_PRODUCT_BY_UPC",
  FETCH_PRODUCT_BY_UPC = "FETCH_PRODUCT_BY_UPC",
}

// ? ACTION CREATORS

export const addNewProductAction = (payload: any, promise?: PromiseType) => {
  return {
    type: VenorPricingActions.ADD_NEW_PRODUCT,
    payload,
    promise,
  };
};

export const editProductAction = (payload: any, promise?: PromiseType) => {
  return {
    type: VenorPricingActions.EDIT_PRODUCT,
    payload,
    promise,
  };
};

export const getDataAction = (payload: any) => {
  return {
    type: VenorPricingActions.GET_DATA,
    payload,
  };
};

export const fetchDataAction = (payload: any) => {
  return {
    type: VenorPricingActions.FETCH_DATA,
    payload,
  };
};

export const getDataDetailsAction = (payload: any) => {
  return {
    type: VenorPricingActions.GET_DATA_DETAILS,
    payload,
  };
};

export const fetchDataDetailsAction = (payload: any) => {
  return {
    type: VenorPricingActions.FETCH_DATA_DETAILS,
    payload,
  };
};

export const putProductAction = (payload: any) => {
  return {
    type: VenorPricingActions.PUT_PRODUCT_BY_UPC,
    payload,
  };
};

export const fetchProductAction = (payload: any) => {
  return {
    type: VenorPricingActions.FETCH_PRODUCT_BY_UPC,
    payload,
  };
};

export const deleteProductServerAction = (payload: any, promise?: PromiseType) => {
  return {
    type: VenorPricingActions.DELETE_PRODUCT_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteProductStoreAction = (payload: any) => {
  return {
    type: VenorPricingActions.DELETE_PRODUCT_FROM_STORE,
    payload,
  };
};

export const fetchIdgeoareaAction = () => {
  return {
    type: VenorPricingActions.FETCH_IDGEOAREA,
  };
};

export const getIdgeoareaAction = (payload: any) => {
  return {
    type: VenorPricingActions.GET_IDGEOAREA,
    payload,
  };
};

export const catchVendorPricingErrorAction = (payload: any) => {
  return {
    type: VenorPricingActions.CATCH_VENDOR_PRICING_ERROR,
    payload,
  };
};

export const getVendorAction = (payload: any) => {
  return {
    type: VenorPricingActions.GET_VENDOR_INFO_BY_ID,
    payload,
  };
};

export const fetchVendorAction = (payload: any) => {
  return {
    type: VenorPricingActions.FETCH_VENDOR_INFO_BY_ID,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: VenorPricingActions.CLEAR_VPRICING_DATA,
  };
};

export const productActivationAction = (payload: any) => {
  return {
    type: VenorPricingActions.PRODUCT_ACTIVATION,
    payload,
  };
};
