import { FC } from "react";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";

// ? RESOURCES
import { PurchaseOrderDetailResponse } from "resource/interfaces/purchase-order";
import { getFormattedDateTime } from "resource/utils";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

interface PurchaseOrderDetailFooterProps {
  order: PurchaseOrderDetailResponse;
}

const PurchaseOrderDetailFooter: FC<PurchaseOrderDetailFooterProps> = ({ order }) => {
  const theme = useTheme();

  return (
    <div>
      <Grid container className={`${styles.PoDetailsInfo} ${styles.PoDetailsFooterSummary}`}>
        <Grid item sm={1.5} className={`${styles.PoDetailsInfoItem} ${styles.PoDetailsInfoItemFisrt}`}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            Non Taxable
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.nontaxable}
          </Typography>
        </Grid>
        <Grid item sm={1.5} className={styles.PoDetailsInfoItem}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            Taxable
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.taxable}
          </Typography>
        </Grid>
        <Grid item sm={1.5} className={styles.PoDetailsInfoItem}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            {`Tax ${order.taxrate}%`}
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.tax}
          </Typography>
        </Grid>
        <Grid item sm={1.5} className={styles.PoDetailsInfoItem}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            Shipping
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.shipping}
          </Typography>
        </Grid>
        <Grid item sm={1.5} className={styles.PoDetailsInfoItem}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            Insurance
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.insurance}
          </Typography>
        </Grid>
        <Grid item sm={1.5} className={`${styles.PoDetailsInfoItem} ${styles.PoDetailsInfoItemLast}`}>
          <Typography variant="h3" color={theme.palette.info.main} className={styles.PoListSpacing}>
            Others
          </Typography>
          <Typography variant="h3" color={theme.palette.text.primary}>
            {order.others}
          </Typography>
        </Grid>
        <Grid item sm={1.5}></Grid>
        <Grid
          item
          sm={1.5}
          className={`${styles.PoDetailsInfoItem} ${styles.PoDetailsInfoItemLast}`}
          sx={{ borderLeft: "2px solid #e0e0e0" }}
        >
          <Typography variant="h3" color={theme.palette.info.main} sx={{ mb: "7px", textAlign: "right" }}>
            Total Amount
          </Typography>
          <Typography variant="h1" color={theme.palette.primary.main} sx={{ textAlign: "right" }}>
            {order.total}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sx={{ display: "flex" }}>
          <Typography variant="body1" color={theme.palette.info.main} sx={{ pr: "3px" }}>
            Created:
          </Typography>
          <Typography variant="body1" color={theme.palette.text.primary} sx={{ borderRight: "1px solid gray", pr: "6px" }}>{` ${
            order.creaby
          } ${getFormattedDateTime(order.modidate)}`}</Typography>
          <Typography variant="body1" color={theme.palette.info.main} sx={{ pl: "6px", pr: "3px" }}>
            Modified:
          </Typography>
          <Typography variant="body1" color={theme.palette.text.primary}>
            {`${order.modiby} ${getFormattedDateTime(order.modidate)}`}
          </Typography>
          <Typography></Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PurchaseOrderDetailFooter;
