import { FC } from "react";

import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ChangeOrderDetailResponse } from "resource/interfaces/change-order";
import { formatPrice } from "resource/utils";
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";

import styles from "./ChangeOrder.module.css";

interface ChangeOrderDetailSummaryProps {
  order: ChangeOrderDetailResponse;
}

const ChangeOrderDetailSummary: FC<ChangeOrderDetailSummaryProps> = ({ order }) => {
  const theme = useTheme();

  return (
    <Grid sx={{ my: "15px" }}>
      <Grid item display="flex" justifyContent="flex-end" sx={{ mb: "10px" }}>
        <Typography sx={{ pl: "10px" }} variant="h3" color={theme.palette.text.primary} alignSelf="center">
          Terms:
        </Typography>
        <img className={styles.ChangeOrderCheckbox} src={order.spec ? checkboxChecked : uncheckedCheckbox} alt="Agreement" />
        <Typography sx={{ pl: "10px" }} variant="body1" color={theme.palette.text.primary} alignSelf="center">
          We agreed hereby to make the change(s) specified above at this price
        </Typography>
        <Box className={styles.ChangeOrderBox}>
          <Typography>{formatPrice(order.tcheck.toString())}</Typography>
        </Box>
      </Grid>

      <Grid item display="flex" justifyContent="flex-end" sx={{ mb: "10px" }}>
        <Typography sx={{ pl: "10px" }} variant="body1" color={theme.palette.text.primary} alignSelf="center">
          Previous contract amount
        </Typography>
        <Box className={styles.ChangeOrderBox}>
          <Typography>{formatPrice(order.tcontract.toString())}</Typography>
        </Box>
      </Grid>
      <Grid item display="flex" justifyContent="flex-end" sx={{ mb: "10px" }}>
        <Typography sx={{ pl: "10px" }} variant="body1" color={theme.palette.text.primary} alignSelf="center">
          Revised contract total
        </Typography>
        <Box className={styles.ChangeOrderBox}>
          <Typography>{formatPrice(order.total.toString())}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChangeOrderDetailSummary;
