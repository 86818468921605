// ? COMPONENTS
import { Typography, Grid, useTheme } from "@mui/material";

// ? STYLES
import styles from "./Assemblies.module.css";

export const AssembliesFormSplitsHeader = () => {
  const theme = useTheme();

  return (
    <div className={styles.PaymentHeader}>
      <Grid className={styles.HeaderItem} item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Name</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={7}>
        <Typography color={theme.palette.primary.main}>Notes</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={3} md={3}>
        <Typography color={theme.palette.primary.main}>Split Percentage</Typography>
      </Grid>
    </div>
  );
};
