import { FC, useState, useMemo } from "react";

// ? REDUX
import { deleteProjectTakeoffPartAction, updateProjectTakeoffPartAction } from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import { Grid, Typography, useTheme, IconButton } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import ToggleField from "components/shared/toggle-field/ToggleField";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";
import HighlightedText from "components/shared/highlighted-text/HighlightedText";
import { useNotice } from "custom-hooks/useNotice";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";

// ? RESOURCES
import deleteIcon from "resource/images/delete.svg";
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";
import { getFormattedDateTime, formatPrice } from "resource/utils";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { projectTakeoffPartQtyUpdateSchema, projectTakeoffPartNotesUpdateSchema } from "resource/schemas/projectTakeoffSchema";
import { PartInterface } from "resource/interfaces/plan-takeoff";
import { noticeTypes } from "resource/consts";
import { menu } from "resource/constants/menu-list";

interface ProjectTakeoffPartItemProps {
  part: PartInterface;
  takeoffId: string;
  takeoffIdcia: number;
  assemblyId: number;
  isReleased: boolean;
  searchQuery: string;
}

const ProjectTakeoffPartItem: FC<ProjectTakeoffPartItemProps> = ({
  part,
  takeoffId,
  takeoffIdcia,
  assemblyId,
  isReleased,
  searchQuery,
}) => {
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.projectTakeoff.idmenu);
  const [isLoading, setLoader] = useState(false);
  const [note, setNote] = useState(part.notes);
  const [qty, setQty] = useState(part.quantity);
  const [amount, setAmount] = useState(part.quantity * part.price);
  const [setNotice] = useNotice();
  const updateProjectTakeoffPart = useAsync(updateProjectTakeoffPartAction);
  const deleteProjectTakeoffPart = useAsync(deleteProjectTakeoffPartAction);
  const dataForFetch = { id: takeoffId, idcia: takeoffIdcia };

  useMemo(() => {
    setNote(part.notes);
    setQty(part.quantity);
  }, [part]);

  const getAmount = (qty: number) => {
    setAmount(qty * part.price);
  };

  const handleSubmit = async (data) => {
    setNote(data.notes);
    setQty(data.budgetrevised);
    setLoader(true);

    const values = {
      idassembly1: assemblyId,
      idproject1: String(takeoffId),
      idcia: String(takeoffIdcia),
      upc: part.partNo,
      partName: part.partName,
      price: String(part.price),
      amount: String(amount),
      idnumber: part.idnumber,
      ...data,
    };

    try {
      await updateProjectTakeoffPart({ values, endpoint: TakeoffEndpoints.UpdatePart, dataForFetch });
      setLoader(false);
      setNotice("Part was successfully updated", noticeTypes.success, 4000);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleDelete = async () => {
    setLoader(true);
    const values = {
      idassembly1: assemblyId,
      idproject1: String(takeoffId),
      idcia: String(takeoffIdcia),
      idnumber: part.idnumber,
    };

    try {
      await deleteProjectTakeoffPart({ values, endpoint: TakeoffEndpoints.DeletePart, dataForFetch });
      setLoader(false);
      setNotice("Part was successfully deleted", noticeTypes.success, 4000);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <TableListRow isReleased={isReleased}>
      <Grid item xs={0.5}>
        <Typography color={theme.palette.text.primary}>{part.partNo}</Typography>
      </Grid>
      <Grid item xs={1.75}>
        <Typography color={theme.palette.text.primary}>
          <HighlightedText searchQuery={searchQuery} text={part.partName} />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{part.unit}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{part.formula}</Typography>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} sx={{ minHeight: "32px" }}>
        <ToggleField
          schema={projectTakeoffPartNotesUpdateSchema}
          handleSubmit={handleSubmit}
          fieldName="notes"
          field={note}
          isLoading={isLoading}
          isTogglable={!isReleased}
          type="text"
          initValues={{ notes: note, quantity: qty }}
          hasAccess={hasEditAccess}
        />
      </Grid>
      <Grid item xs={0.75} sx={{ minHeight: "32px" }}>
        <ToggleField
          schema={projectTakeoffPartQtyUpdateSchema}
          handleSubmit={handleSubmit}
          fieldName="quantity"
          field={qty}
          isLoading={isLoading}
          isTogglable={!isReleased}
          type="number"
          initValues={{ quantity: qty, notes: note }}
          cb={getAmount}
          hasAccess={hasEditAccess}
        />
      </Grid>
      <Grid item xs={0.5}>
        <Typography color={theme.palette.text.primary}>
          <img className={styles.Checkbox} src={part.taxable ? checkboxChecked : uncheckedCheckbox} alt="Taxable" />
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{formatPrice(String(part.price))}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{formatPrice(amount.toFixed(2))}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{part.modiby}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{getFormattedDateTime(part.modidate)}</Typography>
      </Grid>
      <Grid item xs={0.5} justifyContent="flex-end" display="flex">
        {hasEditAccess && (
          <>
            {isLoading ? (
              <SmallLoader />
            ) : (
              <>
                {!isReleased && (
                  <IconButton name="delete" disabled={isLoading} onClick={handleDelete} sx={{ width: "40px", height: "100%" }}>
                    <img src={deleteIcon} alt="Delete icon" />
                  </IconButton>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </TableListRow>
  );
};

export default ProjectTakeoffPartItem;
