import { FC, useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { uploadFileAction, fetchFileFoldersAction, clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

//  ?  COMPONENTS
import FileServerUploadFile from "components/shared/file-server/FileServerUploadFile";
import FileServerList from "components/shared/file-server/FileServerList";
import ModalManager from "components/modals/ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { Alert, IconButton, Box, Typography, useTheme, CircularProgress } from "@mui/material";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";

interface FileServerModalProps {
  name: string;
  title: string;
  dataForSubmit: any;
  dataForDelete: any;
  dataForFetch: any;
  isFileServerExist: boolean;
  api: string;
  shouldGetCleans?: boolean;
}

const FileServerModal: FC<FileServerModalProps> = ({
  name,
  title,
  dataForSubmit,
  dataForDelete,
  dataForFetch,
  isFileServerExist,
  api,
  shouldGetCleans,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const uploadFile = useAsync(uploadFileAction);
  const [isUploading, setUploadingLoader] = useState<boolean>(false);
  const { close, isOpened } = useModal(name);
  const folders = useSelector((state: RootStateOrAny) => state.fileServer.fileFolders);
  const error = useSelector((state: RootStateOrAny) => state.fileServer.fileServerErrors);

  useEffect(() => {
    isOpened && dispatch(fetchFileFoldersAction(api));
  }, [isOpened, api, dispatch]);

  const handleUploadFile = async (formData) => {
    const { updatedValues, reset } = formData;
    const dataToSubmit = {
      ...updatedValues,
      ...dataForSubmit,
    };

    setUploadingLoader(true);
    try {
      await uploadFile({ data: dataToSubmit, dataForFetch, api });
      setUploadingLoader(false);
      reset();
    } catch (e) {
      console.error(e);
      setUploadingLoader(false);
    }
  };

  return (
    <>
      {error && <Alert severity="error">{`${error}`}</Alert>}
      <ModalManager name={name}>
        <Box className="Form" sx={{ p: "25px" }}>
          <div className="FormHeader">
            <Typography variant="h1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton
              onClick={() => {
                close();
                if (shouldGetCleans) {
                  dispatch(clearFileServerDataAction());
                }
              }}
            >
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </div>
          {folders.length ? (
            <>
              <FileServerUploadFile buttonText="Save" folders={folders} handleSubmit={handleUploadFile} isLoading={isUploading} />
              <FileServerList
                folders={folders}
                dataForDelete={dataForDelete}
                dataForFetch={dataForFetch}
                isFileServerExist={isFileServerExist}
                api={api}
              />
            </>
          ) : (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </ModalManager>
    </>
  );
};

export default FileServerModal;
