import { takeLatest, put } from "redux-saga/effects";
import { PLAN_TAKEOFF_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";
import { normalizeItemsList, normalizeTakeoffItemById } from "resource/normalize";

import {
  PlanTakeoffActions,
  putPlanTakeoffByIdAction,
  putPlanTakeoffsAction,
  fetchPlanTakeoffByIdAction,
  clearDataAction,
} from "../redux-actions/planTakeoffs";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchPlanTakeoffsWorker(action) {
  const {
    payload: {
      additionalData: { status, keywords, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PLAN_TAKEOFF_API}/list`, {
    ...params,
    ...(idcia && { idcia }),
    ...(keywords && { keywords }),
    ...(status && { filter: status }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putPlanTakeoffsAction({ data: normalizeItemsList(data.list, "planTakeoffs"), count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPlanTakeoffByIdWorker(action) {
  const {
    payload: { id },
  } = action;

  const { errno, message, data } = yield get(`${PLAN_TAKEOFF_API}/${id}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putPlanTakeoffByIdAction(normalizeTakeoffItemById(data, "planTakeoff")));
  }
}

function* updatePlanTakeoffWorker(action) {
  const {
    payload: { values, endpoint, dataForFetch },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PLAN_TAKEOFF_API}/${endpoint}`, { ...values }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Plan Takeoff's ${endpoint} endpoint was updated successfully`);
    yield put(fetchPlanTakeoffByIdAction({ ...dataForFetch }));
    yield put(clearNotifierAction());
  }
}

function* updatePlanTakeoffBudgetWorker(action) {
  const {
    payload: {
      values: { takeoffId, idnumber, budgetrevised, notes, idcostcode },
      endpoint,
    },
    promise: { resolve, reject },
  } = action;

  let budgetId = idnumber;
  if (budgetId === "0") {
    const { errno, message, data } = yield post(
      `${PLAN_TAKEOFF_API}/${TakeoffEndpoints.AddBudget}`,
      {
        idplan1: takeoffId,
        idcostcode: Number(idcostcode),
      },
      "json"
    );

    if (errno !== 0) {
      reject(message);
      yield catchError(message);
    } else {
      budgetId = data.newId;
    }
  }

  if (budgetId !== "0") {
    const { errno, message } = yield post(
      `${PLAN_TAKEOFF_API}/${endpoint}`,
      { budgetrevised, notes, idnumber: budgetId },
      "urlEncode"
    );

    if (errno !== 0) {
      reject(message);
      yield catchError(message);
    } else {
      resolve(`Plan Takeoff's updateBudget endpoint was updated successfully`);
      yield put(fetchPlanTakeoffByIdAction({ id: takeoffId }));
      yield put(clearNotifierAction());
    }
  }
}

function* deletePlanTakeoffWorker(action) {
  const {
    payload: { values, endpoint },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PLAN_TAKEOFF_API}/${endpoint}`, { ...values }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Plan Takeoff was deleted successfully`);
    yield put(clearDataAction());
  }
}

export function* planTakeoffSaga() {
  yield takeLatest(PlanTakeoffActions.FETCH_PLAN_TAKEOFFS, fetchPlanTakeoffsWorker);
  yield takeLatest(PlanTakeoffActions.FETCH_PLAN_TAKEOFF_BY_ID, fetchPlanTakeoffByIdWorker);
  yield takeLatest(PlanTakeoffActions.ADD_NEW_PLAN_TAKEOFF, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.EDIT_PLAN_TAKEOFF, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.DELETE_PLAN_TAKEOFF, deletePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_ASSEMBLY_NOTES, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_BUDGET, updatePlanTakeoffBudgetWorker);
  yield takeLatest(PlanTakeoffActions.ADD_PLAN_TAKEOFF_BUDGET, updatePlanTakeoffBudgetWorker);
  yield takeLatest(PlanTakeoffActions.DELETE_PLAN_TAKEOFF_BUDGET, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.DELETE_PLAN_TAKEOFF_ASSEMBLY, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.ADD_PLAN_TAKEOFF_ASSEMBLY, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.DELETE_PLAN_TAKEOFF_PART, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.ADD_PLAN_TAKEOFF_PART, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_PART, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.IMPORT_PLAN_TAKEOFF_PLAN, updatePlanTakeoffWorker);
  yield takeLatest(PlanTakeoffActions.IMPORT_PLAN_TAKEOFF_PROJECT, updatePlanTakeoffWorker);
}
