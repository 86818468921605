import { roleOnGet, roleOnPost, actionsType, menuCollection, menuItem } from "resource/interfaces/master-roles";

export const processDataForSubmit = (data: roleOnPost, actionsList: any[]): roleOnPost => {
  // create an empty arr to collect updated menu items with actions
  const roles: string[] = [];

  // loop through the data that Formik has collected
  Object.keys(data.actions).forEach((menuId): void => {
    if (Number(menuId) === 0) {
      data.actions[menuId] = [];
    }

    const selectedActionRoles = data.actions[menuId];

    // fill in the array with {action: boolean} pairs
    const role = actionsList.reduce(
      (newObject: actionsType, action: string): actionsType => {
        Object.assign(newObject, { [`${action}`]: selectedActionRoles.includes(action) });

        return newObject;
      },
      { idmenu: Number(menuId) }
    );

    roles.push(role);
  });

  const processedData = {
    idrole: data.idrole,
    name: data.name,
    project: Boolean(data.project),
    sort: data.sort,
    roles,
  };

  return data.idtrack ? { ...processedData, idtrack: data.idtrack } : processedData;
};

export const processDataForEdit = (formData: roleOnGet, menuCollection: menuCollection[]) => {
  const dataToManipulate = formData.roles.filter((el: menuItem) => {
    return menuCollection.some((f): boolean => {
      return f.idmenu === el.item;
    });
  });

  const newObj = {};

  dataToManipulate.forEach((el: menuItem): void => {
    for (const key in el) {
      if (key.charAt(0) === "x" && el[key] === true) {
        if (!newObj[el.item]) {
          newObj[el.item] = [];
        }

        newObj[el.item].push(key);
      }
    }
  });

  return newObj;
};
