export const COST_CODES_NAME = "costCodes";

export const COST_CODES = {
  TITLE: "Cost Codes",
  NEW_MODAL_TITLE: "Add New Cost Code",
  EDIT_MODAL_TITLE: "Edit Cost Code",
  NEW_MODAL_PLUS_BUTTON: "Add New Cost Code",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Cost Code",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Cost Code",
};
