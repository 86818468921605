import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { resolvePath, isBiggerThanZero } from "resource/utils";
import { ColorListMap } from "resource/consts";
import { ROUTES } from "resource/routes/routes";

const PlanTakeoffItem = ({ planTakeoff }) => {
  const theme = useTheme();
  const mainPath = ROUTES.planTakeoff.pathname;
  const subDirectories = [planTakeoff.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{planTakeoff.idnumber}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={theme.palette.text.primary}>{planTakeoff.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.text.primary}>{planTakeoff.marketing}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{planTakeoff.revision}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(planTakeoff.sqft)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(planTakeoff.takeoffsqft)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(planTakeoff.takeoff)}</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography sx={{ color: `rgb(${ColorListMap[planTakeoff.status]})` }} justifyContent="flex-end" display="flex">
            {planTakeoff.status}
          </Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default PlanTakeoffItem;
