import { MasterRolesActions } from "redux-store/redux-actions/masterRoles";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";
import { roleOnGet } from "resource/interfaces/master-roles";

interface MasterRolesStore {
  masterRoles: roleOnGet[];
  masterRolesError: string;
  masterRolesCount: number;
}

const initialState: MasterRolesStore = {
  masterRoles: [],
  masterRolesError: "",
  masterRolesCount: 0,
};

export const masterRolesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MasterRolesActions.GET_MASTER_ROLES:
      return {
        ...state,
        masterRoles: [...state.masterRoles, ...getUniqueData<roleOnGet>(payload.data, state.masterRoles, "idnumber")],
        masterRolesCount: payload.count,
      };
    case MasterRolesActions.GET_MASTER_ROLE_BY_ID:
      return {
        ...state,
        masterRoles: updateCollection<roleOnGet>(state.masterRoles, payload, "idnumber"),
      };
    case MasterRolesActions.DELETE_ROLE_FROM_STORE:
      return {
        ...state,
        masterRoles: [...state.masterRoles.filter((role: roleOnGet) => role.idnumber !== payload)],
      };
    case MasterRolesActions.CATCH_MASTER_ROLES_ERROR:
      return { ...state, masterRolesError: payload };
    case MasterRolesActions.CLEAR_ROLES_DATA:
      return {
        ...state,
        masterRoles: [],
        masterRolesCount: 0,
      };
    default:
      return state;
  }
};
