import { useState, useEffect } from "react";

// ? COMPONENTS
import { Card, Avatar, CardHeader, CardContent, Divider, CircularProgress } from "@mui/material";
import { CardField } from "components/card-field/CardField";
import { SettingsButton } from "components/settings-button/SettingsButton";
import useAccess from "custom-hooks/useAccess";
import useModal from "custom-hooks/useModal";

// ? STYLES
import styles from "./InfoCard.module.css";

// ? RESOURCES
import { nameFileUpload } from "components/modals/file-server-modal/CompaniesFileServer";
import { nameUpdate } from "components/modals/update/CompaniesUpdate";
import { nameDelete } from "components/modals/delete/CompaniesDelete";
import { getAvatar, updateFullName } from "resource/utils";
import { URL } from "resource/constants/api";
import { menu } from "resource/constants/menu-list";

// ? TYPES
export interface CardPropsTypes {
  data: any;
  fake?: boolean;
}

export const CompanyCard = ({ data, fake }: CardPropsTypes) => {
  const { name, taddress, tcity, tstate, email, website, tel, fax, federalid, taxrate1, latefee, photoUrl } = data;
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.companies.idmenu);
  const [avatar, setAvatar] = useState("");
  const [avatarColor, setAvatarColor] = useState("");
  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openFileServer } = useModal(nameFileUpload);
  const { open: openDelete } = useModal(nameDelete);

  useEffect(() => {
    if (!photoUrl) {
      getAvatar(name, setAvatar, setAvatarColor);
    } else {
      setAvatar(`${URL}${photoUrl}`);
    }
  }, [name, photoUrl]);

  if (fake) {
    return (
      <Card sx={{ maxWidth: 345 }} className={`${styles.InfoCard} ${styles.CompaniesCard} ${styles.FakeCard}`}>
        <div className={`${styles.Cover} ${styles.Disabled}`}>
          <CircularProgress className={styles.Loader} />
        </div>

        <CardHeader
          avatar={<Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar"></Avatar>}
          action={<SettingsButton options={[]} />}
          title=""
          subheader=""
        />
        <Divider />
        <CardContent className={styles.InfoCardContent}></CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: 345, cursor: "auto" }} className={styles.InfoCard}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar">
            {photoUrl ? <img className={styles.CardAvatar} src={avatar} alt="Company icon" /> : avatar}
          </Avatar>
        }
        action={
          <SettingsButton
            options={[
              { text: "Edit", type: "edit", callback: () => openUpdate(data), access: hasEditAccess },
              {
                text: "Fileserver",
                type: "fileserver",
                callback: () => openFileServer(data),
                access: true,
              },
              {
                text: "Delete",
                type: "delete",
                callback: () => openDelete(data),
                danger: true,
                access: hasDeleteAccess,
              },
            ]}
          />
        }
        title={updateFullName(name)}
        subheader={taddress}
      />
      <Divider />
      <CardContent className={styles.InfoCardContent}>
        <CardField size="50%" title="City" name={tcity} />
        <CardField size="50%" title="States" name={tstate} />
        <CardField size="50%" title="Company Email" name={email} />
        <CardField size="50%" title="Company Website" name={website} />
        <CardField size="50%" title="Telephone No" name={tel} />
        <CardField size="50%" title="Fax Number" name={fax} />
        <CardField size="50%" title="Federal ID" name={federalid} />
        <CardField size="50%" title="Sales Tax" name={taxrate1} />
        <CardField size="50%" title="Late Fee" name={latefee} />
      </CardContent>
    </Card>
  );
};
