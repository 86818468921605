import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPaymentSummaryAction, clearPaymentSummaryDataAction } from "redux-store/redux-actions/paymentSummary";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import PaymentSummaryList from "./PaymentSummaryList";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import FilterSelectWithSearch from "components/shared/filter-select/FilterSelectWithSearch";

// ? RESOURCES
import { PAYMENT_SUMMARY_NAME, paymentSummaryFiltersList } from "resource/constants/payment-summary";
import { COMPANIES_API } from "resource/constants/api";

const PaymentSummary = () => {
  const dispatch = useDispatch();
  const paymentSummaries = useSelector((state: RootStateOrAny) => state.paymentSummary.paymentSummaryList);
  const paymentSummaryCount = useSelector((state: RootStateOrAny) => state.paymentSummary.paymentSummaryCount);
  const hasMoreToLoad = paymentSummaryCount > paymentSummaries.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(paymentSummaryFiltersList[0]);

  // COMPANY SERACH
  const [idcia, setCompanyID] = useState(0);
  const companyHandleChange = (value) => {
    setCompanyID(value !== null ? value.value : 0);
    setPage(0);
    dispatch(clearPaymentSummaryDataAction());
  };
  // = = =

  const additionalData = useMemo(
    () => ({
      filter: select,
      keywords: searchKeyword,
      idcia,
    }),
    [select, searchKeyword, idcia]
  );

  const { setPage } = useInfiniteLoading(fetchPaymentSummaryAction, hasMoreToLoad, paginationLoading, additionalData);

  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearPaymentSummaryDataAction());
  };

  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearPaymentSummaryDataAction());
  };

  return (
    <>
      <PageHeader pageName={PAYMENT_SUMMARY_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={paymentSummaryFiltersList} />
        <FilterSelectWithSearch handleChange={companyHandleChange} api={`${COMPANIES_API}/list`} />
        <Search value={searchKeyword} placeholder="Search payment summary" handleChange={handleSearchChange} />
      </PageHeader>

      {paymentSummaries.length <= 0 && !paginationLoading && <NoDataScreen pageName={PAYMENT_SUMMARY_NAME} />}
      {paymentSummaries.length > 0 && <PaymentSummaryList paymentSummaries={paymentSummaries} />}
      {paginationLoading ? (
        <div className={`LoaderScroll ${!paymentSummaries.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(PaymentSummary);
