//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { DEVELOPMENTS_NAME } from "resource/constants/developments";
import { DEVELOPMENTS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${DEVELOPMENTS_NAME}/file/uploadFile`;

const DevelopmentsFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    idcia: data.idcia,
    name: data.name,
  };

  const dataForDelete = {
    name: data.name,
    developmentID: data.idnumber,
    idcia: data.idcia,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
    idcia: data.idcia,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Development-${data.idnumber} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={DEVELOPMENTS_API}
    />
  );
};

export default DevelopmentsFileServer;
