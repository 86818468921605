import { useState } from "react";

// ? REDUX
import { addPlanTakeoffAssemblyAction } from "redux-store/redux-actions/planTakeoffs";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import TakeoffsAssemblyForm from "pages/takeoffs/TakeoffsAssemblyForm";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";

// ? RESOURCES
import { PLAN_TAKEOFFS_NAME, TAKEOFFS, TakeoffEndpoints } from "resource/constants/takeoffs";
import { ActionName } from "resource/constants/api";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
export const nameAddAssembly = `${PLAN_TAKEOFFS_NAME}Assembly/${ActionName.CREATE}`;

const initFormValues = {
  idnumber: null,
};

const PlanTakeoffAddAssembly = () => {
  const { close, isOpened, data } = useModal(nameAddAssembly);
  const [isLoading, setLoader] = useState(false);
  const [setNotice] = useNotice();
  const addPlanTakeoffAssembly = useAsync(addPlanTakeoffAssemblyAction);

  const handleSubmit = async (formData) => {
    setLoader(true);
    const values = { ...formData, idplan1: String(data) };
    try {
      await addPlanTakeoffAssembly({
        values,
        endpoint: TakeoffEndpoints.AddAssembly,
        dataForFetch: { id: data },
      });
      setNotice("Assembly was successfully added", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameAddAssembly}>
      {isOpened ? (
        <div className={styles.AddForm}>
          <TakeoffsAssemblyForm
            title={TAKEOFFS.ADD_ASSEMBLY}
            formData={initFormValues}
            buttonText="Save"
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PlanTakeoffAddAssembly;
