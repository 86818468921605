import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Button, Grid, Typography, useTheme, Menu, MenuItem } from "@mui/material";
import StatusMark from "components/shared/status-mark/StatusMark";
import { nameAddAssembly } from "components/modals/create/ProjectTakeoffAddAssembly";
import { nameAddBudget } from "components/modals/create/ProjectTakeoffAddBudget";
import { nameDeleteProjectTakeoff } from "components/modals/delete/ProjectTakeoffDelete";
import { nameAddPlanImport } from "components/modals/import/ProjectTakeoffPlanImport";
import { nameAddProjectImport } from "components/modals/import/ProjectTakeoffProjectImport";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";
import { menu } from "resource/constants/menu-list";
import arrow from "resource/images/arrow-down.svg";
import addIcon from "resource/images/plus.svg";
import importIcon from "resource/images/import.svg";
import deleteIcon from "resource/images/delete.svg";
import tickIcon from "resource/images/tick-mark.svg";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
import pageHeaderStyles from "components/page-header/PageHeader.module.css";

interface ProjectTakeoffDetailHeaderProps {
  id: string;
  idcia: number;
  name: string;
  status: string;
}

const ProjectTakeoffDetailHeader: FC<ProjectTakeoffDetailHeaderProps> = ({ id, idcia, name, status }) => {
  const theme = useTheme();
  const roleRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  const { hasEditAccess, hasDeleteAccess, hasAddAccess } = useAccess(menu.projectTakeoff.idmenu);
  const hasAccess = hasEditAccess || hasDeleteAccess || hasAddAccess;
  const { open: openAddAssembly } = useModal(nameAddAssembly);
  const { open: openAddBudget } = useModal(nameAddBudget);
  const { open: openAddPlanImport } = useModal(nameAddPlanImport);
  const { open: openAddProjectImport } = useModal(nameAddProjectImport);
  const { open: openProjectTakeoffDelete } = useModal(nameDeleteProjectTakeoff);
  const mainPath = ROUTES.projectTakeoff.pathname;
  const subDirectories = [id, idcia, "release-takeoff"];

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.projectTakeoff.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${id} - ${name}`}
            </Typography>
            <StatusMark status={status} />
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your project takeoff details will be shown below
          </Typography>
        </Grid>
        <Grid item>
          {hasAccess && (
            <Button
              variant={"outlined"}
              className={`${styles.ActionButton}`}
              onClick={toggleMenuOpen}
              ref={roleRef}
              type="button"
            >
              <Typography sx={{ mr: "5px" }}>Actions</Typography>
              <img
                src={arrow}
                alt=""
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(4%) hue-rotate(30deg) brightness(116%) contrast(100%)",
                }}
              />
            </Button>
          )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={roleRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        classes={{ root: styles.AccountMenuDropdown }}
        PaperProps={{ elevation: 0 }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        sx={{ "& .MuiPopover-paper": { boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)" } }}
      >
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddAssembly({ id, idcia })}>
            <img src={addIcon} alt="" />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Add Assembly
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddBudget({ id, idcia })}>
            <img src={addIcon} alt="" />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Add Budget
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddPlanImport({ id, idcia, name })}>
            <img src={importIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Import from Plan
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddProjectImport({ id, idcia, name })}>
            <img src={importIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Import from Project
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem}>
            <Link style={{ display: "flex" }} to={resolvePath(mainPath, subDirectories)}>
              <img src={tickIcon} alt="" style={{ width: "10px" }} />
              <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
                Release Takeoffs
              </Typography>
            </Link>
          </MenuItem>
        )}
        {hasDeleteAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openProjectTakeoffDelete({ id, idcia, name })}>
            <img src={deleteIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Delete Project Takeoff
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ProjectTakeoffDetailHeader;
