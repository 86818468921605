import React, { useState, useMemo, FC } from "react";

// ? COMPONENTS
import { TableListRow } from "components/shared/table-list/TableList";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Box,
  IconButton,
  Typography,
  Alert,
  useTheme,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { useSearch } from "custom-hooks/useSearch";
import styles from "pages/takeoffs/Takeoffs.module.css";

// ? RESOURCE
import { projectTakeoffAddPartSchema } from "resource/schemas/projectTakeoffSchema";
import { formatPrice } from "resource/utils";
import { PARTS_API } from "resource/constants/api";
import { getLumber } from "resource/consts";
import checkIcon from "resource/images/tick-mark.svg";
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";

interface TakeoffPartFormProps {
  assemblyId: number;
  handleSubmit: (
    formData: {
      lumber: string | number;
      upc: string;
      description: string;
      notes: string;
      qty: number;
      price: number | null;
      amount: number;
      taxyn: boolean;
      idassembly1: number;
    },
    resetForm: () => void,
    resetControlledFields: () => void
  ) => void;
  isLoading: boolean;
}

const TakeoffPartForm: FC<TakeoffPartFormProps> = ({ assemblyId, handleSubmit, isLoading }) => {
  const theme = useTheme();
  const [part, setPart] = useState<any | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [qty, setQty] = useState(0);
  const [amount, setAmount] = useState(0);
  const [lumber, setLumber] = useState<string | null>(null);
  const [optionsParts, setQueryParts, isPending, initialConditionParts, errorMessageParts] = useSearch(PARTS_API);
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);

  useMemo(() => {
    if (part) {
      setPrice(part.minprice);
      qty && price && setAmount(qty * price);
      if (part.lumber && Number(lumber) && typeof Number(lumber) === "number") {
        setPrice((prevPrice) => prevPrice && prevPrice * Number(lumber));
      }
    } else {
      setPart(null);
      setQty(0);
    }
  }, [part, qty, lumber, price]);

  const resetControlledFields = () => {
    setPart(null);
    setPrice(null);
    setAmount(0);
    setLumber(null);
    setQty(0);
    setCounter((prevCount) => prevCount + 1);
  };

  return (
    <>
      {errorMessageParts && <Alert severity="error">{errorMessageParts}</Alert>}
      <TableListRow>
        <Formik
          enableReinitialize
          initialValues={{
            upc: "",
            qty: "",
            notes: "",
            lumber: "Random Length",
          }}
          validationSchema={projectTakeoffAddPartSchema}
          onSubmit={(values, { resetForm }) => {
            const formData = {
              lumber: lumber ? lumber : 0,
              upc: part.upc,
              description: part.description,
              notes: values.notes,
              qty,
              price,
              amount,
              taxyn: part.taxable,
              idassembly1: assemblyId,
            };
            handleSubmit(formData, resetForm, resetControlledFields);
          }}
        >
          {({ setFieldValue, handleBlur }) => {
            return (
              <Form className={styles.PartsForm}>
                <Grid container spacing={0.5} height="60px">
                  <Grid item xs={4.5}>
                    <Autocomplete
                      key={counter}
                      className={styles.SearchField}
                      id="upc"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      sx={{
                        "&.MuiAutocomplete-root": { marginTop: "5px" },
                        "& div.MuiOutlinedInput-root": { p: "5px !important" },
                        "& .MuiTextField-root": { margin: "0 !important", height: "45px" },
                      }}
                      options={optionsParts}
                      getOptionLabel={(option: any) => option["description"]}
                      loading={isLoading}
                      noOptionsText={!initialConditionParts ? "Start typing" : "No options"}
                      onChange={(e, value: any) => {
                        setPart(value);
                        setFieldValue("upc", value ? value.upc : "");
                        setFieldValue("price", part ? part.minprice : 0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={styles.SearchFieldInput}
                          placeholder="Search part"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setQueryParts(e.target.value);
                          }}
                          name="upc"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={1.5}>
                    <Field
                      as={TextField}
                      type="text"
                      name="notes"
                      id="notes"
                      placeholder="Add notes"
                      sx={{
                        width: "100%",
                        "& div.MuiOutlinedInput-root": { p: "5px !important" },
                        "& .MuiTextField-root ": { marginBottom: "0 !important" },
                        "& input": { height: "45px" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Field
                      as={TextField}
                      type="number"
                      name="qty"
                      id="qty"
                      placeholder="Qty"
                      sx={{
                        "& div.MuiOutlinedInput-root": { p: "5px !important" },
                        "& .MuiTextField-root ": { marginBottom: "0 !important" },
                        "& input": { height: "45px" },
                      }}
                      onChange={(evt) => {
                        setQty(evt.target.value);
                        setFieldValue("qty", evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={0.5}>
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%", mt: "-6px" }}>
                      <img
                        className={styles.Checkbox}
                        src={part && part.taxable ? checkboxChecked : uncheckedCheckbox}
                        alt="Taxable"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box className={styles.MockField}>
                      <Typography color={theme.palette.text.primary}>{price && formatPrice(String(price))}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Box className={styles.MockField}>
                      <Typography color={theme.palette.text.primary}>{formatPrice(amount.toFixed(2))}</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={1.5}>
                    {part && part.lumber && (
                      <>
                        <Field
                          name="lumber"
                          id="lumber"
                          type="select"
                          as={Select}
                          disabled={!part || !part.lumber}
                          sx={{
                            width: "100%",
                            "& div.MuiOutlinedInput-root": { p: "5px !important" },
                            "& .MuiTextField-root ": { marginBottom: "0 !important" },
                            "& .MuiInputBase-input": { height: "45px !important", mt: "5px", p: "12px !important" },
                          }}
                          onChange={(evt) => {
                            setPrice((prevPrice) => prevPrice && prevPrice * evt.target.value);
                            setLumber(evt.target.value);
                            setFieldValue("lumber", evt.target.value);
                          }}
                        >
                          {getLumber().map((value: number | string) => {
                            return (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={0.5}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
                      <IconButton type="submit" sx={{ width: "35px", height: "35px", mt: "-6px" }}>
                        <img src={checkIcon} alt="Add icon" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <ErrorMessage name="upc">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                <ErrorMessage name="notes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                <ErrorMessage name="qty">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                <ErrorMessage name="lumber">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </Form>
            );
          }}
        </Formik>
      </TableListRow>
    </>
  );
};

export default TakeoffPartForm;
