import { useState, FC } from "react";

// ? REDUX
import { addNewAssemblingAction } from "redux-store/redux-actions/assemblies";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import { AssembliesFormCreate } from "pages/assemblies/AssembliesFormCreate";

//  ? STYLES
import styles from "pages/assemblies/Assemblies.module.css";

// ? RESOURCES
import { ASSEMBLIES, ASSEMBLIES_NAME } from "resource/constants/assemblies";
import { ActionName } from "resource/constants/api";

export const nameCreate = `${ASSEMBLIES_NAME}/${ActionName.CREATE}`;


interface AssembliesCreateProps {
  className: string;
}

const AssembliesCreate: FC<AssembliesCreateProps> = ({ className }) => {
  const { close, isOpened } = useModal(nameCreate);

  const [isLoading, setLoader] = useState(false);
  const createAssembling = useAsync(addNewAssemblingAction);

  const [splits, setSplit] = useState([{ name: "St1" }]);

  const createSplitRow = (): void => {
    if (splits.length < 10) {
      setSplit((prev): any => [...prev, { name: `St${splits.length + 1}` }]);
    }
  };

  const removeSplitRow = (setFieldValue): void => {
    if (splits.length > 1) {
      setFieldValue(`st${splits.length}des`, null);
      setFieldValue(`stage${splits.length}`, 0);
      setSplit((prev): any => {
        return [...prev.slice(0, prev.length - 1)];
      });
    } else if (splits.length === 1) {
      setFieldValue(`st${splits.length}des`, "");
      setFieldValue(`stage${splits.length}`, 0);
      setSplit([{ name: `St${splits.length}` }]);
    }
  };

  const [parts, setParts] = useState<any>([
    {
      tempId: Date.now(),
      idnumber: 0,
      upc: "",
      description: "",
      qty: 0,
      price: 0,
      amount: 0,
      notes: "",
      lumber: 0,
      updateyn: false,
      deleteyn: false,
    },
  ]);

  const createPartRow = (): void => {
    if (parts.length < 30 && parts[parts.length - 1].upc) {
      setParts((prev): any => [
        ...prev,
        {
          tempId: Date.now(),
          idnumber: 0,
          upc: "",
          description: "",
          qty: 0,
          price: 0,
          amount: 0,
          notes: "",
          lumber: 0,
          updateyn: false,
          deleteyn: false,
        },
      ]);
    }
  };

  const removePartRow = (id) => {
    if (parts.length > 1) {
      setParts((parts): any => [...parts.filter((part) => part.tempId !== id)]);
    } else if (parts.length === 1) {
      setParts([
        {
          tempId: Date.now(),
          idnumber: 0,
          upc: "",
          description: "",
          qty: 0,
          price: 0,
          amount: 0,
          notes: "",
          lumber: 0,
          updateyn: false,
          deleteyn: false,
        },
      ]);
    }
  };

  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      data.list = parts;
      await createAssembling({
        values: data,
        actionType: ActionName.CREATE,
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      {isOpened ? (
        <div className={styles.DevelopmentsAddForm}>
          <AssembliesFormCreate
            title={ASSEMBLIES.NEW_MODAL_TITLE}
            buttonText={ASSEMBLIES.NEW_MODAL_SUBMIT_BUTTON}
            closeModal={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            splits={splits}
            createSplitRow={createSplitRow}
            removeSplitRow={removeSplitRow}
            parts={parts}
            setParts={setParts}
            createPartRow={createPartRow}
            removePartRow={removePartRow}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default AssembliesCreate;
