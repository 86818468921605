import { PromiseType } from "resource/interfaces";
import { ChangeOrderDetailResponse, ChangeOrderRequest } from "resource/interfaces/change-order";

// ? ACTIONS
export enum ChangeOrdersActions {
  ADD_NEW_CHANGE_ORDER = "ADD_NEW_CHANGE_ORDER",
  EDIT_CHANGE_ORDER = "EDIT_CHANGE_ORDER",
  PUT_CHANGE_ORDER_BY_ID = "PUT_CHANGE_ORDER_BY_ID",
  PUT_CHANGE_ORDERS = "PUT_CHANGE_ORDERS",
  FETCH_CHANGE_ORDERS = "FETCH_CHANGE_ORDERS",
  FETCH_CHANGE_ORDER_BY_ID = "FETCH_CHANGE_ORDER_BY_ID",
  APPROVE_CHANGE_ORDER = "APPROVE_CHANGE_ORDER",
  DISAPPROVE_CHANGE_ORDER = "DISAPPROVE_CHANGE_ORDER",
  VOID_CHANGE_ORDER = "VOID_CHANGE_ORDER",
  CLEAR_CHANGE_ORDER_DATA = "CLEAR_CHANGE_ORDER_DATA",
  CATCH_CHANGE_ORDERS_ERROR = "CATCH_CHANGE_ORDERS_ERROR",
}

// ? ACTION CREATORS
export const addNewChangeOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: ChangeOrdersActions.ADD_NEW_CHANGE_ORDER,
    payload,
    promise,
  };
};

export const editChangeOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: ChangeOrdersActions.EDIT_CHANGE_ORDER,
    payload,
    promise,
  };
};

export const putChangeOrdersAction = (payload: {
  data: { entities: { [key: string]: { [key: number]: ChangeOrderRequest } | undefined }; result: number[] };
  count: number;
}) => {
  return {
    type: ChangeOrdersActions.PUT_CHANGE_ORDERS,
    payload,
  };
};

export const putChangeOrderByIdAction = (payload: {
  entities: { [key: string]: { [key: number]: ChangeOrderDetailResponse } | undefined };
  result: number;
}) => {
  return {
    type: ChangeOrdersActions.PUT_CHANGE_ORDER_BY_ID,
    payload,
  };
};

export const fetchChangeOrdersAction = (payload: number) => {
  return {
    type: ChangeOrdersActions.FETCH_CHANGE_ORDERS,
    payload,
  };
};

export const fetchChangeOrderByIdAction = (payload: number) => {
  return {
    type: ChangeOrdersActions.FETCH_CHANGE_ORDER_BY_ID,
    payload,
  };
};

export const approveChangeOrderAction = (
  payload: { data: { idnumber: number; idtrack: number }; actionType: string },
  promise?: PromiseType
) => {
  return {
    type: ChangeOrdersActions.APPROVE_CHANGE_ORDER,
    payload,
    promise,
  };
};

export const disapproveChangeOrderAction = (
  payload: { data: { idnumber: number; idtrack: number }; actionType: string },
  promise?: PromiseType
) => {
  return {
    type: ChangeOrdersActions.DISAPPROVE_CHANGE_ORDER,
    payload,
    promise,
  };
};

export const voidChangeOrderAction = (
  payload: { data: { idnumber: number; idtrack: number }; actionType: string },
  promise?: PromiseType
) => {
  return {
    type: ChangeOrdersActions.VOID_CHANGE_ORDER,
    payload,
    promise,
  };
};

export const clearChangeOrderDataAction = () => {
  return {
    type: ChangeOrdersActions.CLEAR_CHANGE_ORDER_DATA,
  };
};

export const catchChangeOrdersErrorAction = (payload: string) => {
  return {
    type: ChangeOrdersActions.CATCH_CHANGE_ORDERS_ERROR,
    payload,
  };
};
