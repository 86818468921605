import { useState, useEffect, FC } from "react";

// ? REDUX
import { createPartsVendorPricingAction } from "redux-store/redux-actions/parts";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Grid, TextField, Autocomplete } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import useHeaders from "custom-hooks/useHeaders";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? RESOURCES
import { VENDORS_API } from "resource/constants/api";
import { initialValuesPricingForm } from "resource/constants/parts";
import { partsVendorPricingSchema } from "resource/schemas/partsSchema";
import { Colors } from "resource/consts";
import deleteIcon from "resource/images/delete.svg";
import checkIcon from "resource/images/tick-mark.svg";
import SmallLoader from "components/shared/small-loader/SmallLoader";

// ? STYLES
import styles from "./Parts.module.css";
const inlineStyle = {
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      padding: 0,
      paddingRight: "0 !important",
      backgroundColor: Colors.ALABASTER_WHITE,
      border: "1px solid #E2E7ED",
      borderRadius: 0,
    },
    "& .MuiAutocomplete-input": {
      border: "none",
      height: "33px",
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
  input: {
    margin: 0,
    width: "100%",
    borderRadius: 0,
    "& input": { borderRadius: 0, height: "35px", border: "1px solid #E2E7ED" },
    "& .MuiOutlinedInput-input:-webkit-autofill": { borderRadius: 0 },
  },
} as const;

interface formDataType {
  idgeoarea: string;
  idvendor: string;
  lastprice: string;
  skunumber: string;
  nvendor?: string;
}

interface PartsVendorPricingFormProps {
  idtrack: number;
  upc: string;
  setEdit?: (any) => void;
  formData?: formDataType;
  actionType: string;
  vendorPricingDisabled?: boolean;
}

const PartsVendorPricingForm: FC<PartsVendorPricingFormProps> = ({
  idtrack,
  upc,
  setEdit,
  formData,
  actionType,
  vendorPricingDisabled,
}) => {
  const headers = useHeaders();
  const [vendorsOptions, setVendorsOptions] = useState<any[]>([]);
  const [vendorsLoader, setVendorsLoader] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const [idvendorValue, setIdvendorValue] = useState<any | null>(null);

  useEffect(() => {
    if (formData) {
      setIdvendorValue(vendorsOptions.find((it) => it.idnumber === formData.idvendor));
    }
  }, [vendorsOptions, formData]);

  const createVendorPricing = useAsync(createPartsVendorPricingAction);

  const handleVendorPricingSubmit = async (data: formDataType, resetForm: () => void) => {
    const values = {
      upc,
      idgeoarea: 1,
      idvendor: idvendorValue ? Number(idvendorValue.idnumber) : "",
      skunumber: data.skunumber,
      lastprice: data.lastprice ? Number(data.lastprice) : "",
      idtrack,
    };

    setLoader(true);

    try {
      await createVendorPricing({
        values,
        actionType,
      });
      setLoader(false);
      resetForm();
      setIdvendorValue(null);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleVendorPricingUpdateCancel = (handleReset: () => void) => {
    if (setEdit) {
      setEdit(initialValuesPricingForm);
    } else {
      handleReset();
      setIdvendorValue(null);
    }
  };

  useEffect(() => {
    fetch(`${VENDORS_API}/list`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        setVendorsOptions(response.data.list);
        setVendorsLoader(false);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  return (
    <>
      <Formik
        initialValues={formData ? formData : initialValuesPricingForm}
        onSubmit={async (data, { resetForm }) => {
          await handleVendorPricingSubmit(data, resetForm);
        }}
        validationSchema={partsVendorPricingSchema}
      >
        {({ handleReset }) => (
          <Form className={styles.PartsVendorPricingForm}>
            <fieldset disabled={isLoading} className={`${styles.PartsFieldset}`}>
              <Grid container>
                <Grid item xs={7}>
                  <>
                    {!vendorsLoader ? (
                      <Autocomplete
                        options={vendorsOptions}
                        getOptionLabel={(option) => option.name}
                        value={idvendorValue}
                        id="idvendor"
                        sx={{
                          ...inlineStyle.autocomplete,
                        }}
                        disabled={formData !== undefined}
                        onChange={(evt, newValue) => {
                          setIdvendorValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ ...inlineStyle.input }} placeholder="Choose vendor" />
                        )}
                      />
                    ) : (
                      <SmallLoader />
                    )}
                  </>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="text"
                    name="skunumber"
                    id="skunumber"
                    placeholder="Enter sku"
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="number"
                    name="lastprice"
                    id="lastprice"
                    placeholder="Price"
                  />
                </Grid>
                <Grid item xs={1.5} className={styles.PartsVendorPricingBtnWrapper}>
                  {isLoading ? (
                    <SmallLoader />
                  ) : (
                    <>
                      <IconButton type="submit" disabled={vendorPricingDisabled}>
                        <img src={checkIcon} alt="Add icon" />
                      </IconButton>
                      <IconButton onClick={() => handleVendorPricingUpdateCancel(handleReset)} disabled={vendorPricingDisabled}>
                        <img src={deleteIcon} alt="Delete icon" />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </fieldset>

            <ErrorMessage name="skunumber">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
            <ErrorMessage name="lastprice">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PartsVendorPricingForm;
