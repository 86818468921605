import { FC } from "react";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCE
import { formatPrice } from "resource/utils";
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";
import { menu } from "resource/constants/menu-list";

// ? INTERFACES
import { PartOnResponse } from "resource/interfaces/part";

// ? STYLES
import styles from "./Parts.module.css";

interface PartsItemProps {
  part: PartOnResponse;
  handlePartUpdate: (part: PartOnResponse) => void;
  handlePartDelete: (part: PartOnResponse) => void;
  openConfirm: (part: PartOnResponse) => void;
}

const PartsItem: FC<PartsItemProps> = ({ part, handlePartUpdate, handlePartDelete, openConfirm }) => {
  const theme = useTheme();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.parts.idmenu);
  return (
    <TableListRow>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{part.upc}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{part.description}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{part.lengthunit}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.text.primary}>{part.formula}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{formatPrice(String(part.minprice))}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{formatPrice(String(part.maxprice))}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{formatPrice(String(part.avgprice))}</Typography>
      </Grid>
      <Grid item xs={1} display={"flex"} justifyContent={"center"}>
        <img
          className={styles.PartsCheckboxes}
          src={part.discontinued ? uncheckedCheckbox : checkboxChecked}
          alt="project status"
        />
      </Grid>
      <Grid item xs={1} justifyContent="flex-end" display="flex">
        <>
          <SettingsButton
            options={[
              {
                text: "Edit",
                type: "edit",
                callback: () => handlePartUpdate(part),
                access: hasEditAccess,
              },
              {
                activeFlag: "Activate",
                text: part.discontinued ? "Activate" : "Discontinue",
                type: "switch",
                callback: () => openConfirm(part),
                danger: false,
                access: hasEditAccess,
              },
              {
                text: "Delete",
                type: "delete",
                callback: () => handlePartDelete(part),
                access: hasDeleteAccess,
              },
            ]}
          />
        </>
      </Grid>
    </TableListRow>
  );
};

export default PartsItem;
