import { FC } from "react";

import { Typography, useTheme, Grid } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import TakeoffsBudgetList from "./TakeoffsBudgetList";

interface TakeoffsBudgetTabProps {
  takeoffId: number | string;
  takeoffBudget: number[];
  takeoffType: string;
  takeoffIdcia?: number;
}

const TakeoffsBudgetTab: FC<TakeoffsBudgetTabProps> = ({ takeoffId, takeoffBudget, takeoffType, takeoffIdcia }) => {
  const theme = useTheme();

  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Number</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.primary.main}>Cost Code</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Notes*</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Original Budget</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Revised Budget*</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Updated</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
            Date
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
            Actions
          </Typography>
        </Grid>
      </TableListHeader>
      <TakeoffsBudgetList
        takeoffBudget={takeoffBudget}
        takeoffId={takeoffId}
        takeoffType={takeoffType}
        takeoffIdcia={takeoffIdcia}
      />
    </>
  );
};

export default TakeoffsBudgetTab;
