// ? ROUTING
import { Route, Redirect } from "react-router-dom";

// ? COMPONENTS
import AccessForbidden from "pages/access-forbidden/AccessForbidden";
import Assemblies from "pages/assemblies/Assemblies";
import ChangeOrder from "pages/change-order/ChangeOrder";
import ChangeOrderDetail from "pages/change-order/ChangeOrderDetail";
import Companies from "pages/companies/Companies";
import Contracts from "pages/contracts/Contracts";
import ContractsDetail from "pages/contracts/ContractsDetail";
import CostCodes from "pages/cost-codes/CostCodes";
import Developments from "pages/developments/Developments";
import DevelopmentsDetail from "pages/developments/DevelopmentsDetail";
import JobCost from "pages/job-cost/JobCost";
import JobCostDetail from "pages/job-cost/JobCostDetail";
import Landing from "pages/landing/Landing";
import LandingPay from "pages/landing/LandingPay";
import LandingSuccess from "pages/landing/LandingSuccess";
import MasterFloorplan from "pages/master-floorplan/MasterFloorplan";
import MasterFloorplansDetails from "pages/master-floorplan/MasterFloorplansDetails";
import MasterRoles from "pages/master-roles/MasterRoles";
import MembersProfile from "pages/members-profile/MembersProfile";
import NotFound from "./pages/not-found/NotFound";
import Parts from "./pages/parts/Parts";
import PaymentSummary from "pages/payment-summary/PaymentSummary";
import PaymentSummaryDetail from "pages/payment-summary/PaymentSummaryDetail";
import PlanDrawer from "pages/plan-drawer/PlanDrawer";
import PlanTakeoff from "pages/takeoffs/plan-takeoff/PlanTakeoffs";
import PlanTakeoffDetail from "pages/takeoffs/plan-takeoff/PlanTakeoffDetail";
import PlanVariables from "./pages/plan-variables/PlanVariables";
import LandingPricing from "pages/landing/LandingPricing";
import ProjectTakeoffs from "pages/takeoffs/project-takeoff/ProjectTakeoffs";
import ProjectTakeoffDetail from "pages/takeoffs/project-takeoff/ProjectTakeoffDetail";
import ProjectTakeoffRelease from "pages/takeoffs/project-takeoff/ProjectTakeoffRelease";
import ProjectTakeoffPO from "pages/takeoffs/project-takeoff/ProjectTakeoffPO";
import Projects from "pages/projects/Projects";
import ProjectsDetail from "pages/projects/ProjectsDetail";
import PurchaseOrder from "pages/purchase-order/PurchaseOrder";
import PurchaseOrderDetail from "pages/purchase-order/purchase-order-details/PurchaseOrderDetail";
import SignUp from "pages/sign-up/SignUp";
import VendorPricing from "pages/vendor-pricing/VendorPricing";
import VendorPricingDetail from "pages/vendor-pricing/VendorPricingDetail";
import Vendors from "pages/vendors/Vendors";
import { Login } from "pages/login/Login";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import SwitchWithAuth from "routes/switch-with-auth/SwitchWithAuth";

export const App = () => {
  return (
    <SwitchWithAuth>
      <Route path="/" exact>
        <Redirect to={ROUTES.login.pathname} />
      </Route>
      <Route path={ROUTES.login.pathname}>
        <Login />
      </Route>
      <Route path={ROUTES.signup.pathname} exact>
        <SignUp />
      </Route>
      <Route path={ROUTES.pricing.pathname} exact>
        <LandingPricing />
      </Route>
      <Route path={ROUTES.success.pathname} exact>
        <LandingSuccess />
      </Route>
      <Route exact path={`${ROUTES.pay.pathname}/:plan`} render={({ match }) => <LandingPay plan={match.params.plan} />} />
      <Route path={ROUTES.landing.pathname}>
        <Landing />
      </Route>
      <Route path={ROUTES.login.login} exact auth>
        <Redirect to={ROUTES.companies} />
      </Route>
      <Route path={ROUTES.companies.pathname} id={ROUTES.companies.idmenu} exact auth>
        <Companies />
      </Route>
      <Route path={ROUTES.costCodes.pathname} id={ROUTES.costCodes.idmenu} exact auth>
        <CostCodes />
      </Route>
      <Route path={ROUTES.masterRole.pathname} id={ROUTES.masterRole.idmenu} exact auth>
        <MasterRoles />
      </Route>
      <Route path={ROUTES.membersProfile.pathname} id={ROUTES.membersProfile.idmenu} exact auth>
        <MembersProfile />
      </Route>
      <Route path={ROUTES.planVariable.pathname} id={ROUTES.planVariable.idmenu} exact auth>
        <PlanVariables />
      </Route>
      <Route path={ROUTES.planDrawer.pathname} id={ROUTES.planDrawer.idmenu} exact auth>
        <PlanDrawer />
      </Route>
      <Route path={ROUTES.vendors.pathname} id={ROUTES.vendors.idmenu} exact auth>
        <Vendors />
      </Route>
      <Route path={ROUTES.purchaseOrder.pathname} id={ROUTES.purchaseOrder.idmenu} exact auth>
        <PurchaseOrder />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.purchaseOrder.pathname}/:id`}
        render={({ match }) => <PurchaseOrderDetail id={match.params.id} />}
      />
      <Route path={ROUTES.parts.pathname} id={ROUTES.parts.idmenu} exact auth>
        <Parts />
      </Route>
      <Route path={ROUTES.assemblies.pathname} id={ROUTES.assemblies.idmenu} exact auth>
        <Assemblies />
      </Route>
      <Route path={ROUTES.vendorPricing.pathname} id={ROUTES.vendorPricing.idmenu} exact auth>
        <VendorPricing />
      </Route>
      <Route path={`${ROUTES.vendorPricing.pathname}/*`} id={ROUTES.vendorPricing.idmenu} exact auth>
        <VendorPricingDetail />
      </Route>
      <Route path={ROUTES.planTakeoff.pathname} id={ROUTES.planTakeoff.idmenu} exact auth>
        <PlanTakeoff />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.planTakeoff.pathname}/:id`}
        render={({ match }) => <PlanTakeoffDetail id={match.params.id} />}
      />
      <Route path={ROUTES.projectTakeoff.pathname} id={ROUTES.projectTakeoff.idmenu} exact auth>
        <ProjectTakeoffs />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.projectTakeoff.pathname}/:id/:idcia`}
        render={({ match }) => <ProjectTakeoffDetail idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route
        exact
        auth
        path={`${ROUTES.projectTakeoff.pathname}/:id/:idcia/release-takeoff`}
        render={({ match }) => <ProjectTakeoffRelease idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route
        exact
        auth
        path={`${ROUTES.projectTakeoff.pathname}/:id/:idcia/:idassembly1/:idvendor`}
        render={({ match }) => (
          <ProjectTakeoffPO
            idcia={match.params.idcia}
            id={match.params.id}
            idassembly1={match.params.idassembly1}
            idvendor={match.params.idvendor}
          />
        )}
      />
      <Route path={`${ROUTES.masterFloorplans.pathname}`} id={ROUTES.masterFloorplans.idmenu} exact auth>
        <MasterFloorplan />
      </Route>
      <Route path={`${ROUTES.masterFloorplans.pathname}/*`} id={ROUTES.masterFloorplans.idmenu} exact auth>
        <MasterFloorplansDetails />
      </Route>
      <Route path={`${ROUTES.contracts.pathname}`} id={ROUTES.contracts.idmenu} exact auth>
        <Contracts />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.contracts.pathname}/:id`}
        render={({ match }) => <ContractsDetail id={match.params.id} />}
      />
      <Route path={`${ROUTES.projectPage.pathname}`} id={ROUTES.projectPage.idmenu} exact auth>
        <Projects />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.projectPage.pathname}/:idcia/:id`}
        render={({ match }) => <ProjectsDetail idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route path={`${ROUTES.jobCostReport.pathname}`} id={ROUTES.jobCostReport.idmenu} exact auth>
        <JobCost />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.jobCostReport.pathname}/:idcia/:id`}
        render={({ match }) => <JobCostDetail idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route path={`${ROUTES.changeOrders.pathname}`} id={ROUTES.changeOrders.idmenu} exact auth>
        <ChangeOrder />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.changeOrders.pathname}/:id`}
        render={({ match }) => <ChangeOrderDetail id={match.params.id} />}
      />
      <Route path={`${ROUTES.developments.pathname}`} id={ROUTES.developments.idmenu} exact auth>
        <Developments />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.developments.pathname}/:idcia/:id`}
        render={({ match }) => <DevelopmentsDetail idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route path={`${ROUTES.paymentSummary.pathname}`} id={ROUTES.paymentSummary.idmenu} exact auth>
        <PaymentSummary />
      </Route>
      <Route
        exact
        auth
        path={`${ROUTES.paymentSummary.pathname}/:idcia/:id`}
        render={({ match }) => <PaymentSummaryDetail idcia={match.params.idcia} id={match.params.id} />}
      />
      <Route auth path={ROUTES.accessForbidden}>
        <AccessForbidden />
      </Route>
      <Route auth path="*">
        <NotFound />
      </Route>
    </SwitchWithAuth>
  );
};
