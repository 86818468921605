import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { resolvePath, formatPrice } from "resource/utils";
import { ROUTES } from "resource/routes/routes";
import { ColorListMap } from "resource/consts";
import { ChangeOrderRequest } from "resource/interfaces/change-order";
import FileServerClipIcon from "components/shared/file-server/FileServerClipIcon";

interface ChangeOrderItemsProps {
  changeOrder: ChangeOrderRequest;
}

const ChangeOrderItem: FC<ChangeOrderItemsProps> = ({ changeOrder }) => {
  const theme = useTheme();
  const mainPath = ROUTES.changeOrders.pathname;
  const subDirectories = [changeOrder.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1.5} display="flex">
          <FileServerClipIcon fs={changeOrder.fs} />
          <Typography sx={{ marginLeft: "12px" }} color={theme.palette.text.primary}>
            {changeOrder.idcia}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{changeOrder.idproject1}</Typography>
        </Grid>
        <Grid item xs={6.5}>
          <Typography color={theme.palette.text.primary}>{changeOrder.nproject}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography sx={{ color: `rgb(${ColorListMap[changeOrder.status]})` }} justifyContent="flex-end" display="flex">
            {changeOrder.status}
          </Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.text.primary}>{formatPrice(String(changeOrder.total))}</Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default ChangeOrderItem;
