import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Typography, useTheme } from "@mui/material";

// ? RESOURCES
import notFoundImage from "resource/images/404.svg";

const NotFound = () => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <img src={notFoundImage} alt="Page Not Found" style={{ width: "25%", paddingBottom: "20px" }} />
      <Typography variant="h1">Sorry, Page not found!</Typography>
      <Typography variant="h3" sx={{ width: "25%", pt: "18px", textAlign: "center" }} color={theme.palette.text.secondary}>
        Sorry, we know it’s frustrating when things doesn’t work as it was supposed to be.
      </Typography>
      <Link to="/" className="GoHomeButton">
        <Typography textAlign="center" variant="h3">
          Go Home
        </Typography>
      </Link>
    </Box>
  );
};

export default NotFound;
