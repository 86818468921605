import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import PurchaseOrderItem from "./PurchaseOrderItem";

import { PurchaseOrderListItem } from "resource/interfaces/purchase-order";

const PurchaseOrderList = (props) => {
  const { purchaseOrder } = props;
  const theme = useTheme();
  return (
    <>
      <TableListHeader>
        <Grid item xs={1.2}>
          <Typography color={theme.palette.primary.main}>PO</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Doc</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Co</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.primary.main}>Project Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Vendor Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Invoice</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Total Amount</Typography>
        </Grid>
        <Grid item xs={1.8}>
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
        <Grid item xs={0.6}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
        <Grid item xs={0.8}>
          <Typography color={theme.palette.primary.main}>Notes</Typography>
        </Grid>
      </TableListHeader>
      {purchaseOrder.map((order: PurchaseOrderListItem, i: string) => (
        <div key={i}>
          <PurchaseOrderItem order={order} {...props} />
        </div>
      ))}
    </>
  );
};

export default PurchaseOrderList;
