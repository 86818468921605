// ? ACTIONS
export const GLOBAL_DATA_LOADER = "GLOBAL_DATA_LOADER";
export const MODAL_LOADER = "MODAL_LOADER";
export const BUTTON_LOADER = "BUTTON_LOADER";
export const ADD_NEW_LOADER = "CARD_LOADER";
export const UPDATE_ENITY_LOADER = "UPDATE_ENITY_LOADER";
export const PAGINATION_LOADER = "PAGINATION_LOADER";
export const CARD_LOADER = "PAGINATION_LOADER";
export const SET_LOADER = "SET_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";

// ? ACTION CREATORS

export const setGlobalLoaderAction = (payload: any) => {
  return {
    type: GLOBAL_DATA_LOADER,
    payload,
  };
};

export const setModalLoaderAction = (payload: any) => {
  return {
    type: MODAL_LOADER,
    payload,
  };
};

export const setButtonLoaderAction = (payload: any) => {
  return {
    type: BUTTON_LOADER,
    payload,
  };
};

export const setAddNewLoaderAction = (payload: any) => {
  return {
    type: ADD_NEW_LOADER,
    payload,
  };
};

export const setUpdateEnityLoaderAction = (payload: any) => {
  return {
    type: UPDATE_ENITY_LOADER,
    payload,
  };
};

export const setPaginationLoaderAction = (payload: any) => {
  return {
    type: PAGINATION_LOADER,
    payload,
  };
};

export const setCardLoaderAction = (payload: any) => {
  return {
    type: CARD_LOADER,
    payload,
  };
};

export const setLoaderAction = (payload: any) => {
  return {
    type: SET_LOADER,
    payload,
  };
};

export const removeLoaderAction = (payload: any) => {
  return {
    type: REMOVE_LOADER,
    payload,
  };
};
