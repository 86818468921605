import React from "react";
import { useState, useEffect } from "react";

// ? COMPONENTS
import { Typography, Grid, TextField, Autocomplete, CircularProgress, IconButton, useTheme } from "@mui/material";
import { PARTS_API as api } from "resource/constants/api";

// ? STYLES
import styles from "./Assemblies.module.css";

// ? RESOURCES
import { useSearch } from "custom-hooks/useSearch";
import { getFormattedDate } from "resource/utils";
import deleteIcon from "resource/images/delete.svg";

export const AssembliesFormPartField = ({
  part,
  parts,
  setParts,
  isNew,
  removePartRow,
}: {
  part: any;
  parts: any;
  setParts: (data: any) => void;
  isNew: boolean;
  removePartRow: (id: number) => void;
}) => {
  const theme = useTheme();

  const [tPart, setTpart] = useState({ ...part, updateyn: false, deleteyn: false });

  const [openUpcList, setOpenUpcList] = useState(false);
  const [openDescriptionList, setOpenDescriptionList] = useState(false);

  const [optionsUpc, setQueryUpc, isPendingUpc, initialConditionUpc, errorMessageUpc] = useSearch(
    { api: "", keyword: "", params: "" },
    1000,
    1
  );
  const [optionsDesc, setQueryDesc, isPendingDesc, initialConditionDesc, errorMessageDesc] = useSearch(
    { api: "", keyword: "", params: "" },
    1000,
    1
  );

  useEffect(() => {
    setTpart((part) => {
      return {
        ...part,
        amount: tPart.qty * tPart.price,
      };
    });
  }, [tPart.qty]);

  useEffect(() => {
    if (tPart.tempId) {
      setParts((parts) => {
        return [
          ...parts.map((part) => {
            if (part.tempId === tPart.tempId) {
              return tPart;
            } else {
              return part;
            }
          }),
        ];
      });
    }
    if (!tPart.tempId) {
      setParts((parts) => {
        return [
          ...parts.map((part) => {
            if (part.idnumber === tPart.idnumber) {
              return tPart;
            } else {
              return part;
            }
          }),
        ];
      });
    }
  }, [tPart]);

  if (!part.deleteyn) {
    return (
      <div className={`${styles.PaymentRow} ${parts.length === 1 && styles.Alone}`} key={part.name}>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Autocomplete
            className={styles.SearchField}
            id="upc"
            open={openUpcList}
            onOpen={() => {
              setOpenUpcList(true);
            }}
            onClose={() => {
              setOpenUpcList(false);
            }}
            options={optionsUpc}
            getOptionLabel={(option: any) => option.upc || ""}
            loading={isPendingUpc}
            noOptionsText={!initialConditionUpc ? "Start typing" : "No options"}
            value={tPart}
            onChange={(e, value: any) => {
              value !== null &&
                setTpart((part) => {
                  return {
                    ...part,
                    upc: value.upc,
                    description: value.description,
                    price: value.minprice,
                    formula: value.formula,
                    updateyn: isNew ? false : true,
                    deleteyn: false,
                  };
                });
            }}
            renderInput={(params) => (
              <TextField
                className={styles.SearchFieldInput}
                placeholder="Enter UPC"
                onChange={(e) => {
                  setQueryUpc(e.target.value);
                }}
                name={tPart.upc}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isPendingUpc ? (
                        <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid className={styles.PartItem} item xs={1}>
          <Autocomplete
            className={styles.SearchField}
            id="description"
            open={openDescriptionList}
            onOpen={() => {
              setOpenDescriptionList(true);
            }}
            onClose={() => {
              setOpenDescriptionList(false);
            }}
            options={optionsDesc}
            getOptionLabel={(option: any) => option.description || ""}
            loading={isPendingDesc}
            value={tPart}
            noOptionsText={!initialConditionDesc ? "Start typing" : "No options"}
            onChange={(e, value: any) => {
              value !== null &&
                setTpart((part) => {
                  return {
                    ...part,
                    upc: value.upc,
                    description: value.description,
                    price: value.minprice,
                    formula: value.formula,
                    updateyn: isNew ? false : true,
                    deleteyn: false,
                  };
                });
            }}
            renderInput={(params) => (
              <TextField
                className={styles.SearchFieldInput}
                placeholder="Enter name"
                onChange={(e) => {
                  setQueryDesc(e.target.value);
                }}
                name={tPart.description}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isPendingDesc ? (
                        <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>{part.formula}</Typography>
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <TextField
            type="number"
            value={tPart.qty}
            placeholder="Enter qty"
            disabled={!tPart.upc}
            onChange={(e) => {
              if (e.target.value.length < 12 && Number(e.target.value) >= 0) {
                setTpart((part) => {
                  return { ...part, qty: Number(e.target.value), updateyn: isNew ? false : true, deleteyn: false };
                });
              }
            }}
          />
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>{tPart.price}</Typography>
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>{tPart.amount}</Typography>
        </Grid>
        <Grid className={styles.PartItem} item xs={3} md={3}>
          <TextField
            type="text"
            placeholder="Enter note"
            value={tPart.notes}
            onChange={(e) => {
              if (e.target.value.length < 40) {
                setTpart((part) => {
                  return { ...part, notes: e.target.value, updateyn: isNew ? false : true, deleteyn: false };
                });
              }
            }}
          />
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>{tPart.modiby}</Typography>
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>{tPart.modidate && getFormattedDate(part.modidate)}</Typography>
        </Grid>
        <Grid className={styles.PartItem} item xs={1} md={1}>
          <IconButton
            onClick={() => {
              if (part.tempId) {
                removePartRow(part.tempId);
              }
              if (part.idnumber !== 0) {
                setTpart((part) => {
                  return { ...part, deleteyn: true };
                });
              }
            }}
          >
            <img src={deleteIcon} alt="Delete" />
          </IconButton>
        </Grid>
      </div>
    );
  } else {
    return null;
  }
};
