import { FC } from "react";

//  ?  COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Typography, useTheme, TextField, InputLabel, FormGroup, Button } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { purchaseOrderDiasapproveSchema } from "resource/schemas/purchaseOrderSchema";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

interface FormValues {
  disapprreason: string;
  disapprnotes: string;
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
}

interface PurchaseOrderDisapprovalFormProps {
  handleSubmit: (values: FormValues) => void;
  isLoading: boolean;
  buttonText: string;
  handleClose: () => void;
}

const initialValues = {
  disapprreason: "",
  disapprnotes: "",
  emailto: "",
  emailcc: "",
  emailsubject: "",
  emailbody: "",
};

const PurchaseOrderDisapprovalForm: FC<PurchaseOrderDisapprovalFormProps> = ({
  handleSubmit,
  isLoading,
  buttonText,
  handleClose,
}) => {
  const theme = useTheme();

  return (
    <Box className={styles.PartsConfirm}>
      <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
        <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
          Disapprove
        </Typography>
        <IconButton onClick={handleClose}>
          <img src={closeButtonIcon} alt="close-button" />
        </IconButton>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={purchaseOrderDiasapproveSchema}
      >
        <Form style={{ padding: 0, width: "100%", margin: 0 }}>
          <Grid container spacing={2} sx={{ p: 0 }}>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="disapprreason">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Disapproval reason
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="disapprreason" id="disapprreason" placeholder="Add reason" />
                <ErrorMessage name="disapprreason">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="disapprnotes">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Disapproval notes
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="disapprnotes" id="disapprnotes" placeholder="Add notes" />
                <ErrorMessage name="disapprnotes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="emailto">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Email To
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="emailto" id="emailto" placeholder="Enter email" />
                <ErrorMessage name="emailto">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="emailcc">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Email cc
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="emailcc" id="emailcc" placeholder="Enter email cc" />
                <ErrorMessage name="emailcc">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="emailsubject">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Email subject
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="emailsubject" id="emailsubject" placeholder="Enter email subject" />
                <ErrorMessage name="emailsubject">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="emailbody">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Email message
                  </Typography>
                </InputLabel>
                <Field
                  className="Textarea"
                  type="textarea"
                  name="emailbody"
                  id="emailbody"
                  as={TextField}
                  placeholder="Enter message"
                  multiline
                  minRows={3}
                />
                <ErrorMessage name="emailbody">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
          </Grid>
          <Box justifyContent="flex-start" className="FormFooter">
            <Button onClick={handleClose} className={styles.PartsCancelButton}>
              Cancel
            </Button>
            <SubmitButton text={buttonText} isLoading={isLoading} />
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default PurchaseOrderDisapprovalForm;
