import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum ProjectTakeoffActions {
  ADD_NEW_PROJECT_TAKEOFF = "ADD_NEW_PROJECT_TAKEOFF",
  EDIT_PROJECT_TAKEOFF = "EDIT_PROJECT_TAKEOFF",
  DELETE_PROJECT_TAKEOFF = "DELETE_PROJECT_TAKEOFF",
  PUT_PROJECT_TAKEOFF_BY_ID = "PUT_PROJECT_TAKEOFF_BY_ID",
  PUT_PROJECT_TAKEOFFS = "PUT_PROJECT_TAKEOFFS",
  UPDATE_PROJECT_TAKEOFF_ASSEMBLY_STATUS = "UPDATE_PROJECT_TAKEOFF_ASSEMBLY_STATUS",
  UPDATE_PROJECT_TAKEOFF_ASSEMBLY_NOTES = "UPDATE_PROJECT_TAKEOFF_ASSEMBLY_NOTES",
  ADD_PROJECT_TAKEOFF_ASSEMBLY = "ADD_PROJECT_TAKEOFF_ASSEMBLY",
  DELETE_PROJECT_TAKEOFF_ASSEMBLY = "DELETE_PROJECT_TAKEOFF_ASSEMBLY",
  UPDATE_PROJECT_TAKEOFF_BUDGET = "UPDATE_PROJECT_TAKEOFF_BUDGET",
  DELETE_PROJECT_TAKEOFF_BUDGET = "DELETE_PROJECT_TAKEOFF_BUDGET",
  ADD_PROJECT_TAKEOFF_BUDGET = "ADD_PROJECT_TAKEOFF_BUDGET",
  ADD_PROJECT_TAKEOFF_PART = "ADD_PROJECT_TAKEOFF_PART",
  DELETE_PROJECT_TAKEOFF_PART = "DELETE_PROJECT_TAKEOFF_PART",
  UPDATE_PROJECT_TAKEOFF_PART = "UPDATE_PROJECT_TAKEOFF_PART",
  FETCH_PROJECT_TAKEOFFS = "FETCH_PROJECT_TAKEOFFS",
  FETCH_PROJECT_TAKEOFF_BY_ID = "FETCH_PROJECT_TAKEOFF_BY_ID",
  IMPORT_PROJECT_TAKEOFF_PLAN = "IMPORT_PROJECT_TAKEOFF_PLAN",
  IMPORT_PROJECT_TAKEOFF_PROJECT = "IMPORT_PROJECT_TAKEOFF_PROJECT",
  FETCH_PROJECT_TAKEOFFS_RELEASE_LIST = "FETCH_PROJECT_TAKEOFFS_RELEASE_LIST",
  PUT_PROJECT_TAKEOFF_RELEASE_LIST = "PUT_PROJECT_TAKEOFF_RELEASE_LIST",
  RELEASE_PROJECT_TAKEOFFS_LIST = "RELEASE_PROJECT_TAKEOFFS_LIST",
  CLEAR_PROJECT_TAKEOFF_DATA = "CLEAR_PROJECT_TAKEOFF_DATA",
  CLEAR_PROJECT_TAKEOFF_RELEASE_DATA = "CLEAR_PROJECT_TAKEOFF_RELEASE_DATA",
}

// ? ACTION CREATORS
export const addNewProjectTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.ADD_NEW_PROJECT_TAKEOFF,
    payload,
    promise,
  };
};

export const editProjectTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.EDIT_PROJECT_TAKEOFF,
    payload,
    promise,
  };
};

export const deleteProjectTakeoffAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF,
    payload,
    promise,
  };
};

export const updateProjectTakeoffPartAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const putProjectTakeoffsAction = (payload) => {
  return {
    type: ProjectTakeoffActions.PUT_PROJECT_TAKEOFFS,
    payload,
  };
};

export const putProjectTakeoffByIdAction = (payload) => {
  return {
    type: ProjectTakeoffActions.PUT_PROJECT_TAKEOFF_BY_ID,
    payload,
  };
};

export const fetchProjectTakeoffsAction = (payload) => {
  return {
    type: ProjectTakeoffActions.FETCH_PROJECT_TAKEOFFS,
    payload,
  };
};

export const fetchProjectTakeoffByIdAction = (payload: any) => {
  return {
    type: ProjectTakeoffActions.FETCH_PROJECT_TAKEOFF_BY_ID,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: ProjectTakeoffActions.CLEAR_PROJECT_TAKEOFF_DATA,
  };
};

export const clearReleaseDataAction = () => {
  return {
    type: ProjectTakeoffActions.CLEAR_PROJECT_TAKEOFF_RELEASE_DATA,
  };
};

export const addProjectTakeoffAssemblyAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_ASSEMBLY,
    payload,
    promise,
  };
};

export const updateProjectTakeoffAssemblyNotesAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_ASSEMBLY_NOTES,
    payload,
    promise,
  };
};

export const updateProjectTakeoffAssemblyStatusAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_ASSEMBLY_STATUS,
    payload,
    promise,
  };
};

export const updateProjectTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const addProjectTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const deleteProjectTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const addProjectTakeoffPartAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const deleteProjectTakeoffPartAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const deleteProjectTakeoffAssemblyAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_ASSEMBLY,
    payload,
    promise,
  };
};

export const importPlanTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.IMPORT_PROJECT_TAKEOFF_PLAN,
    payload,
    promise,
  };
};

export const importProjectTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.IMPORT_PROJECT_TAKEOFF_PROJECT,
    payload,
    promise,
  };
};

export const releaseTakeoffsAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectTakeoffActions.RELEASE_PROJECT_TAKEOFFS_LIST,
    payload,
    promise,
  };
};

export const fetchReleaseListAction = (payload) => {
  return {
    type: ProjectTakeoffActions.FETCH_PROJECT_TAKEOFFS_RELEASE_LIST,
    payload,
  };
};

export const putReleaseListAction = (payload) => {
  return {
    type: ProjectTakeoffActions.PUT_PROJECT_TAKEOFF_RELEASE_LIST,
    payload,
  };
};
