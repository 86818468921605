import { ProjectsActions } from "redux-store/redux-actions/projects";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const initialState = {
  projectList: [],
  projectsCount: 0,
  projectDetails: {},
  projectsError: "",
  projectFields: [],
  projectFieldsCount: 0,
  projectVariables: [],
  projectVariablesCount: 0,
  projectBasePlans: [],
  projectBasePlansCount: 0,
  projectMembers: [],
  projectMembersCount: 0,
};

export const projectsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectsActions.PUT_PROJECTS:
      return {
        ...state,
        projectList: [...state.projectList, ...getUniqueData(payload.data, state.projectList, "idnumber")],
        projectsCount: payload.count as number,
      };
    case ProjectsActions.PUT_PROJECT_BY_ID:
      return {
        ...state,
        projectList: updateCollection(state.projectList, payload, "modidate"),
      };
    case ProjectsActions.PUT_PROJECT_FIELDS:
      return {
        ...state,
        projectFields: [...state.projectFields, ...getUniqueData(payload.data, state.projectFields, "idnumber")],
        projectFieldsCount: payload.count as number,
      };
    case ProjectsActions.PUT_PROJECT_FORMULA_VARIABLE:
      return {
        ...state,
        projectVariables: [...state.projectVariables, ...getUniqueData(payload.data, state.projectVariables, "idnumber")],
        projectVariablesCount: payload.count as number,
      };
    case ProjectsActions.PUT_PROJECT_BASE_PLANS:
      return {
        ...state,
        projectBasePlans: [...payload],
      };
    case ProjectsActions.PUT_PROJECT_MEMBERS:
      return {
        ...state,
        projectMembers: [...state.projectMembers, ...getUniqueData(payload.data, state.projectMembers, "idnumber")],
        projectMembersCount: payload.count as number,
      };
    case ProjectsActions.CLEAR_PROJECT_DETAIL_DATA:
      return {
        ...state,
        projectFields: [],
        [`project${payload}`]: [],
        [`project${payload}Count`]: 0,
      };
    case ProjectsActions.CLEAR_PROJECT_DATA:
      return {
        ...state,
        projectList: [],
        projectsCount: 0,
      };
    case ProjectsActions.DELETE_PROJECT_FROM_STORE:
      return {
        ...state,
        projectList: [...state.projectList.filter((project: any) => project.idnumber !== payload)],
      };
    case ProjectsActions.PUT_PROJECT_FILE_IN_STORE:
      return {
        ...state,
        projectBasePlans: [
          ...state.projectBasePlans.map((file: any) => {
            if (file.idnumber === payload.idnumber) {
              return { name: file.name, modidate: "", ...payload };
            } else {
              return file;
            }
          }),
        ],
      };
    case ProjectsActions.DELETE_PROJECT_FILE_FROM_STORE:
      return {
        ...state,
        projectBasePlans: [
          ...state.projectBasePlans.map((file: any) => {
            if (file.fid === payload.fid) {
              return {
                name: file.name,
                idnumber: file.idnumber,
                folderName: file.folderName,
                modidate: "",
                attachyn: true,
                deleteyn: false,
                viewyn: false,
              };
            } else {
              return file;
            }
          }),
        ],
      };
    case ProjectsActions.PUT_PROJECT_MEMBER_IN_STORE:
      return {
        ...state,
        projectMembers: [...state.projectMembers, payload],
      };
    case ProjectsActions.DELETE_PROJECT_MEMBER_FROM_STORE:
      return {
        ...state,
        projectMembers: [...state.projectMembers.filter((member: any) => member.idnumber !== payload.idnumber)],
      };
    case ProjectsActions.GET_PROJECT_DETAIL:
      return { ...state, projectDetails: payload };
    default:
      return state;
  }
};
