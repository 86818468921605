import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum DevelopmentsActions {
  ADD_NEW_DEVELOPMENT = "ADD_NEW_DEVELOPMENT",
  EDIT_DEVELOPMENT = "EDIT_DEVELOPMENT",
  DELETE_DEVELOPMENT = "DELETE_DEVELOPMENT",
  DELETE_DEVELOPMENT_FROM_STORE = "DELETE_DEVELOPMENT_FROM_STORE",
  PUT_DEVELOPMENT_BY_ID = "PUT_DEVELOPMENT_BY_ID",
  PUT_DEVELOPMENTS = "PUT_DEVELOPMENTS",
  FETCH_DEVELOPMENTS = "FETCH_DEVELOPMENTS",
  FETCH_DEVELOPMENT_BY_ID = "FETCH_DEVELOPMENT_BY_ID",
  FETCH_DEVELOPMENTS_NEXT_ID = "FETCH_DEVELOPMENTS_NEXT_ID",
  GET_DEVELOPMENTS_NEXT_ID = "GET_DEVELOPMENTS_NEXT_ID",
  GET_DEVELOPMENT_DETAIL = "GET_DEVELOPMENT_DETAIL",
  FETCH_DEVELOPMENT_DETAIL = "FETCH_DEVELOPMENT_DETAIL",

  FETCH_DEVELOPMENTS_BANKS = "FETCH_DEVELOPMENTS_BANKS",
  FETCH_DEVELOPMENTS_FIELDS = "FETCH_DEVELOPMENTS_FIELDS",
  FETCH_DEVELOPMENTS_LINKED_PROJECTS = "FETCH_DEVELOPMENTS_LINKED_PROJECTS",
  FETCH_DEVELOPMENTS_FILES = "FETCH_DEVELOPMENTS_FILES",
  FETCH_DEVELOPMENTS_MEMBERS = "FETCH_DEVELOPMENTS_MEMBERS",

  PUT_DEVELOPMENTS_BANKS = "PUT_DEVELOPMENTS_BANKS",
  PUT_DEVELOPMENTS_FIELDS = "PUT_DEVELOPMENTS_FIELDS",
  PUT_DEVELOPMENTS_LINKED_PROJECTS = "PUT_DEVELOPMENTS_LINKED_PROJECTS",
  PUT_DEVELOPMENTS_FILES = "PUT_DEVELOPMENTS_FILES",
  PUT_DEVELOPMENTS_MEMBERS = "PUT_DEVELOPMENTS_MEMBERS",

  UPLOAD_DEVELOPMENT_FILE = "UPLOAD_DEVELOPMENT_FILE",
  DELETE_DEVELOPMENT_FILE = "DELETE_DEVELOPMENT_FILE",
  PUT_DEVELOPMENT_FILE_IN_STORE = "PUT_DEVELOPMENT_FILE_IN_STORE",
  DELETE_DEVELOPMENT_FILE_FROM_STORE = "DELETE_DEVELOPMENT_FILE_FROM_STORE",

  CLEAR_DEVELOPMENTS_DATA = "CLEAR_DEVELOPMENTS_DATA",
  CLEAR_DEVELOPMENTS_DETAILS_DATA = "CLEAR_DEVELOPMENTS_DETAILS_DATA",
  CATCH_DEVELOPMENTS_ERROR = "CATCH_DEVELOPMENTS_ERROR",

  UPLOAD_DEVELOPMENT_MEMBER = "UPLOAD_DEVELOPMENT_MEMBER",
  DELETE_DEVELOPMENT_MEMBER = "DELETE_DEVELOPMENT_MEMBER",
  PUT_DEVELOPMENT_MEMBER_IN_STORE = "PUT_DEVELOPMENT_MEMBER_IN_STORE",
  DELETE_DEVELOPMENT_MEMBER_FROM_STORE = "DELETE_DEVELOPMENT_MEMBER_FROM_STORE",
}

// ? ACTION CREATORS
export const addNewDevelopmentAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.ADD_NEW_DEVELOPMENT,
    payload,
    promise,
  };
};

export const editDevelopmentAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.EDIT_DEVELOPMENT,
    payload,
    promise,
  };
};

export const deleteDevelopmentAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT,
    payload,
    promise,
  };
};

export const deleteDevelopmentFromStoreAction = (payload: number) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT_FROM_STORE,
    payload,
  };
};

export const putDevelopmentsAction = (payload: { data; count: number }) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS,
    payload,
  };
};

export const putDevelopmentByIdAction = (payload) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENT_BY_ID,
    payload,
  };
};

export const fetchDevelopmentsAction = (payload: number) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS,
    payload,
  };
};

export const fetchDevelopmentByIdAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENT_BY_ID,
    payload,
  };
};

export const fetchDevelopmentsBanksAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_BANKS,
    payload,
  };
};

export const putDevelopmentsBanksAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS_BANKS,
    payload,
  };
};

export const fetchDevelopmentsFieldsAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_FIELDS,
    payload,
  };
};

export const putDevelopmentsFieldsAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS_FIELDS,
    payload,
  };
};

export const fetchDevelopmentsLinkedProjectsAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_LINKED_PROJECTS,
    payload,
  };
};

export const putDevelopmentsLinkedProjectsAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS_LINKED_PROJECTS,
    payload,
  };
};

export const fetchDevelopmentsFilesAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_FILES,
    payload,
  };
};

export const putDevelopmentsFilesAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS_FILES,
    payload,
  };
};

export const fetchDevelopmentsMembersAction = (payload: any) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_MEMBERS,
    payload,
  };
};

export const putDevelopmentsMembersAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENTS_MEMBERS,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: DevelopmentsActions.CLEAR_DEVELOPMENTS_DATA,
  };
};

export const clearDetailDataAction = (payload: string) => {
  return {
    type: DevelopmentsActions.CLEAR_DEVELOPMENTS_DETAILS_DATA,
    payload,
  };
};

export const catchDevelopmentsErrorAction = (payload: string) => {
  return {
    type: DevelopmentsActions.CATCH_DEVELOPMENTS_ERROR,
    payload,
  };
};

export const fetchDevelopmentsNextIdAction = (payload: string) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENTS_NEXT_ID,
    payload,
  };
};

export const getDevelopmentsNextIdAction = (payload: string) => {
  return {
    type: DevelopmentsActions.GET_DEVELOPMENTS_NEXT_ID,
    payload,
  };
};

export const uploadDevelopmentFileAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.UPLOAD_DEVELOPMENT_FILE,
    payload,
    promise,
  };
};

export const deleteDevelopmentFileAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT_FILE,
    payload,
    promise,
  };
};

export const putDevelopmentFileInStoreAction = (payload) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENT_FILE_IN_STORE,
    payload,
  };
};

export const deleteDevelopmentFileFromStoreAction = (payload) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT_FILE_FROM_STORE,
    payload,
  };
};

export const uploadDevelopmentMemberAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.UPLOAD_DEVELOPMENT_MEMBER,
    payload,
    promise,
  };
};

export const deleteDevelopmentMemberAction = (payload, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT_MEMBER,
    payload,
    promise,
  };
};

export const putDevelopmentsMembersInStoreAction = (payload: any) => {
  return {
    type: DevelopmentsActions.PUT_DEVELOPMENT_MEMBER_IN_STORE,
    payload,
  };
};

export const deleteDevelopmentsMembersFromStoreAction = (payload: any) => {
  return {
    type: DevelopmentsActions.DELETE_DEVELOPMENT_MEMBER_FROM_STORE,
    payload,
  };
};

export const fetchDevelopmentDetailAction = (payload: any, promise?: PromiseType) => {
  return {
    type: DevelopmentsActions.FETCH_DEVELOPMENT_DETAIL,
    payload,
    promise,
  };
};

export const getDevelopmentDetailAction = (payload: any) => {
  return {
    type: DevelopmentsActions.GET_DEVELOPMENT_DETAIL,
    payload,
  };
};
