import { useState } from "react";

// ? REDUX
import { uploadPlanFileAction, deletePlanFileAction, putPlanFileInStoreAction } from "redux-store/redux-actions/masterFloorplans";
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { CircularProgress, IconButton, Checkbox, Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

// ? RESOURCES
import { getFormattedDateTime, formattedDateTimeNow, getFileSize } from "resource/utils";
import { menu } from "resource/constants/menu-list";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import attachmentIcon from "resource/images/attachment-icon2.svg";
import deleteIcon from "resource/images/delete.svg";
import viewIcon from "resource/images/view.svg";
import UploadIcon from "@mui/icons-material/Upload";

export const MasterFloorplanFileListItem = ({ el, index, idnumber, name, revision, select, openFilePreview }) => {
  const theme = useTheme();
  const [setNotice] = useNotice();

  const { hasEditAccess } = useAccess(menu.masterFloorplans.idmenu);
  const user = useSelector((state: RootStateOrAny) => state.login.userData);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const uploadFileRequest = useAsync(uploadPlanFileAction);

  const uploadFile = async (evt) => {
    const file = evt.target.files[0];
    const convertedFile = await convertBase64(file);
    const preparedData: any = {
      name,
      revision,
      fname: file.name,
      fileData: removeBase64Prefix(String(convertedFile)),
      path: window.URL.createObjectURL(file),
      ftype: file.name.slice(file.name.indexOf(".") + 1).toUpperCase(),
      modiby: user.username,
      modidate: formattedDateTimeNow(),
      size: getFileSize(file.size),
      idbaseplan: el.idnumber,
    };

    setUploadLoading(true);

    if (file && el.name !== "Marketing Brochure") {
      preparedData.folder = `Rev${revision}`;
      preparedData.fileData = removeBase64Prefix(String(convertedFile));
      preparedData.tablaid = idnumber;

      try {
        await uploadFileRequest({
          data: preparedData,
          actionType: "uploadPlanFile",
        });
        setNotice("File uploaded", noticeTypes.success, 4000);
      } catch (e) {
        console.error(e);
      }
    }

    if (file && el.name === "Marketing Brochure" && file.type.includes("pdf")) {
      preparedData.fileMarketing = removeBase64Prefix(String(convertedFile));
      preparedData.idnumber = idnumber;
      try {
        await uploadFileRequest({
          data: preparedData,
          actionType: "saveMarketingBrochure",
        });
        setNotice("Marketing brochure uploaded", noticeTypes.success, 4000);
      } catch (e) {
        console.error(e);
        setNotice("Marketing brochure didn't upload", noticeTypes.error, 4000);
      }
    } else if (file && el.name === "Marketing Brochure" && !file.type.includes("pdf")) {
      setUploadLoading(false);
      setNotice("Marketing brochure shuld be PDF", noticeTypes.error, 4000);
    }

    setUploadLoading(false);
  };

  const deleteFileRequest = useAsync(deletePlanFileAction);

  const deleteFile = async () => {
    setDeleteLoading(true);

    try {
      if (el.name !== "Marketing Brochure") {
        await deleteFileRequest({
          data: { fid: el.fid, id: el.idnumber, revision: el.revision },
          actionType: "deletePlanFile",
        });
        setNotice("File deleted", noticeTypes.success, 4000);
      }

      if (el.name === "Marketing Brochure") {
        await deleteFileRequest({
          data: { idnumber, id: el.idnumber },
          actionType: "deleteMarketingBrochure",
        });
        setNotice("Marketing brochure deleted", noticeTypes.success, 4000);
      }

      setDeleteLoading(false);
    } catch (e) {
      console.error(e);
      setDeleteLoading(false);
    }
  };

  return (
    <TableListRow>
      <Grid item xs={4}>
        <Typography color={theme.palette.text.primary}>{el.name}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.text.primary}>{el.size}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.text.primary}>{el.ftype}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        {el.url ? (
          <IconButton>
            <img src={attachmentIcon} alt="Attachment icon" title="Have attachment" />
          </IconButton>
        ) : (
          ""
        )}
        {!el.url && el.attachyn && !uploadLoading ? (
          <>
            {hasEditAccess && (
              <>
                <label className={styles.UploadIcon} htmlFor={`upload-file${index}`}>
                  <UploadIcon fontSize="small" />
                </label>
                <input hidden type="file" name="photo" id={`upload-file${index}`} onChange={(evt) => uploadFile(evt)} />
              </>
            )}
          </>
        ) : (
          ""
        )}
        {uploadLoading && <CircularProgress className={styles.CardLoader} size={20} />}
      </Grid>
      <Grid item xs={1} md={1}>
        {el.url && el.viewyn ? (
          <IconButton onClick={() => openFilePreview(el)}>
            <img src={viewIcon} alt="View icon" />
          </IconButton>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={1} md={1}>
        {hasEditAccess && (
          <>
            {el.url && el.deleteyn && !deleteLoading ? (
              <IconButton onClick={deleteFile}>
                <img src={deleteIcon} alt="Delete icon" />
              </IconButton>
            ) : (
              ""
            )}
            {deleteLoading && <CircularProgress className={styles.CardLoader} size={20} />}
          </>
        )}
      </Grid>
      <Grid item xs={1}>
        {el.url && !el.url.includes("blob") && el.viewyn ? <Checkbox size="small" onClick={() => select(el)} /> : ""}
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.text.primary}>{el.modiby}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.text.primary}>{getFormattedDateTime(el.modidate)}</Typography>
      </Grid>
    </TableListRow>
  );
};
