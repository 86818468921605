import React from "react";

// ? COMPONENTS
import { Grid, Button } from "@mui/material";
import { Search } from "components/search/Search";

// ? RESOURCES
import plusIcon from "resource/images/plus.svg";
import minusIcon from "resource/images/minus.svg";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
const TakeoffsExpandSearch = ({ setSearchQuery, setAllCollapsed, isAllCollapsed }) => {
  const handleChange = (value) => {
    setSearchQuery(value.target.value);
  };

  return (
    <Grid container justifyContent="space-between" sx={{ mb: 2 }}>
      <Grid item xs={5}>
        {isAllCollapsed ? (
          <Button className={styles.CollapseBtn} onClick={() => setAllCollapsed(!isAllCollapsed)}>
            <img src={plusIcon} alt="plus icon" style={{ paddingRight: "10px" }} />
            Expand All
          </Button>
        ) : (
          <Button className={styles.CollapseBtn} onClick={() => setAllCollapsed(!isAllCollapsed)}>
            <img src={minusIcon} alt="minus icon" style={{ paddingRight: "10px" }} />
            Collapse All
          </Button>
        )}
      </Grid>
      <Grid item xs={2}>
        <Search value="" placeholder="Search" handleChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export default TakeoffsExpandSearch;
