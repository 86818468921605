import { hexToRgb } from "@material-ui/core/styles";

export const ACCEPT_CONTENT_TYPE = {
  json: "application/json",
  urlEncode: "application/x-www-form-urlencoded",
};

export const CURRENT_TIME_FOR_SERVER = new Date().toISOString().slice(0, 10);
export const ERROR_MESSAGE_DELAY_TIME = 5000;

export const CONTACT_EMAIL = "Kevin@buildersaccess.com";

export const noticeTypes = {
  error: "error",
  info: "info",
  warning: "warning",
  success: "success",
};

export const Colors = {
  BLUE: "#0100BE",
  LIGHT_BLUE: "#4A72FC",
  ORANGE: "#ff9829",
  GREEN: "#00A764",
  RED: "#EB5757",
  GREY: "#596A72",
  WHITE: "#ffffff",
  ATHEN_GRAY: "#E2E5ED",
  GRAY: "#9197a0",
  ALABASTER: "#fbfbfb",
  ALABASTER_WHITE: "#F9F9F9",
};

export const StatusList = {
  ACTIVE: "Active",
  DRAFT: "Draft",
  PENDING: "Pending",
  RELEASED: "Released",
  DISAPPROVED: "Disapproved",
  BILLED: "Billed",
  PRINT_CHECK: "Print Check",
  PAID: "Paid",
  VOID: "Void",
  FOR_APPROVE: "For Approve",
  APPROVED: "Approved",
  CLOSED: "Closed",
  UNDER_REVISION: "Under Revision",
  INACTIVE: "Inactive",
  SPECS: "Specs",
  SOLD: "SOLD",
};

export const ColorListMap = {
  [StatusList.ACTIVE]: hexToRgb(Colors.GREEN).slice(4, -1),
  [StatusList.PAID]: hexToRgb(Colors.GREEN).slice(4, -1),
  [StatusList.APPROVED]: hexToRgb(Colors.GREEN).slice(4, -1),
  [StatusList.SPECS]: hexToRgb(Colors.GREEN).slice(4, -1),
  [StatusList.DRAFT]: hexToRgb(Colors.GREY).slice(4, -1),
  [StatusList.RELEASED]: hexToRgb(Colors.BLUE).slice(4, -1),
  [StatusList.VOID]: hexToRgb(Colors.RED).slice(4, -1),
  [StatusList.SOLD]: hexToRgb(Colors.RED).slice(4, -1),
  [StatusList.PRINT_CHECK]: hexToRgb(Colors.ORANGE).slice(4, -1),
  [StatusList.BILLED]: hexToRgb(Colors.ORANGE).slice(4, -1),
  [StatusList.DISAPPROVED]: hexToRgb(Colors.RED).slice(4, -1),
  [StatusList.PENDING]: hexToRgb(Colors.ORANGE).slice(4, -1),
  [StatusList.FOR_APPROVE]: hexToRgb(Colors.ORANGE).slice(4, -1),
  [StatusList.CLOSED]: hexToRgb(Colors.GREY).slice(4, -1),
  [StatusList.INACTIVE]: hexToRgb(Colors.GREY).slice(4, -1),
  [StatusList.UNDER_REVISION]: hexToRgb(Colors.ORANGE).slice(4, -1),
};

export const Statuses = {
  DRAFT: {
    buttonText: "Save as a Draft",
    status: "Draft",
  },
  FOR_APPROVE: {
    buttonText: "Submit and Approve",
    status: "For Approve",
  },
};

export const getLumber = () => {
  const arr: number[] = [];
  for (let i = 8; i < 32; i = i + 2) {
    arr.push(i);
  }

  const options = [...arr, "Random Length"];

  return options;
};
