import { takeLatest, put, takeEvery } from "redux-saga/effects";
import { MASTER_FLOORPLANS_API } from "resource/constants/api";
import { get, post, catchError } from "./_utils-abstract-saga-network";

import {
  FloorplansActions,
  getFloorplansAction,
  fetchFloorplanAction,
  getFloorplanAction,
  deleteFloorlanStoreAction,
  getFloorplanDetailAction,
  putFloorplanAction,
  putPlanFileInStoreAction,
  deletePlanFileFromStoreAction,
  putFloorlanCompaniesAction,
  putFloorlanFieldsAction,
  putFloorlanFilesAction,
  putFloorlanVariableAction,
} from "../redux-actions/masterFloorplans";

import { setButtonLoaderAction, setPaginationLoaderAction, setAddNewLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchFloorplansWorker(action) {
  const {
    payload: {
      additionalData: { filter, keywords, idcia },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  yield put(setPaginationLoaderAction(true));

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/list`, {
    ...params,
    ...(idcia && { idcia }),
    ...(filter && { filter }),
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getFloorplansAction({ data: data.list, count: data.count }));
    yield put(clearNotifierAction());
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchFloorplanWorker(action) {
  const {
    payload: { idnumber, mode, loader },
  } = action;

  yield loader && put(loader(true));

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/${idnumber}`);

  if (errno !== 0) {
    yield loader && put(loader(false));
    yield catchError(message);
  } else {
    yield put(mode !== "edit" ? getFloorplanAction(data) : putFloorplanAction(data));
    yield mode === "edit" && getFloorplanDetailAction(data);
    yield loader && put(loader(false));
  }
}

function* addNewFloorlanWorker(action) {
  const {
    payload: { formData, closeModal },
  } = action;

  yield put(setButtonLoaderAction(true));

  const {
    data: { nextId },
  } = yield get(`${MASTER_FLOORPLANS_API}/getNextId`);

  formData.idnumber = nextId;

  const { errno, message } = yield post(`${MASTER_FLOORPLANS_API}/create`, formData, "json");

  if (errno !== 0) {
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    console.log("Add floorplan successfull");
    yield put(fetchFloorplanAction({ idnumber: nextId, mode: "create", loader: setAddNewLoaderAction }));
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
    yield closeModal();
  }
}

function* editFloorplanWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${MASTER_FLOORPLANS_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Edit floorplan successfull");
    yield put(fetchFloorplanAction({ idnumber: values.idnumber, mode: actionType, category: values.category }));
    yield put(clearNotifierAction());
  }
}

function* deleteFloorplanWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${MASTER_FLOORPLANS_API}/delete`, { idnumber: payload.idnumber }, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Delete floorplan successfull");
    yield put(deleteFloorlanStoreAction({ idnumber: payload.idnumber }));
    yield put(clearNotifierAction());
  }
}

function* uploadPlanFileWorker(action) {
  const {
    payload: { data, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data: responseData } = yield post(`${MASTER_FLOORPLANS_API}/${actionType}`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Upload plan file successfull");
    yield put(
      putPlanFileInStoreAction({
        attachyn: false,
        viewyn: true,
        deleteyn: true,
        fid: responseData.newId,
        idnumber: data.idbaseplan,
        url: data.path,
        ftype: data.ftype,
        modiby: data.modiby,
        modidate: data.modidate,
        size: data.size,
        revision: data.revision,
      })
    );
    yield put(clearNotifierAction());
  }
}

function* deletePlanFileWorker(action) {
  const {
    payload: { data, actionType },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${MASTER_FLOORPLANS_API}/${actionType}`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Deleting plan file successfull");
    yield put(deletePlanFileFromStoreAction(data));
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* fetchFloorplanDetailWorker(action) {
  const {
    payload: { idnumber },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/${idnumber}`);

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Floorplan detail uploaded");
    yield put(getFloorplanDetailAction(data));
    yield put(clearNotifierAction());
  }
}

function* fetchFloorplansCompaniesWorker(action) {
  const {
    payload: {
      page,
      additionalData: { idnumber },
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber };

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/cialist`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putFloorlanCompaniesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchFloorplansFieldsWorker(action) {
  const {
    payload: {
      page,
      additionalData: { idnumber },
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber };

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/fieldlist`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putFloorlanFieldsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchFloorplansFilesWorker(action) {
  const {
    payload: {
      page,
      additionalData: { idnumber, revision },
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber, revision };

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/filelist`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putFloorlanFilesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchFloorplansVatiablesWorker(action) {
  const {
    payload: {
      page,
      additionalData: { idnumber },
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber };

  const { errno, message, data } = yield get(`${MASTER_FLOORPLANS_API}/formulavariablelist`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putFloorlanVariableAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

export function* masterFloorplansSaga() {
  yield takeLatest(FloorplansActions.FETCH_FLOORPLANS, fetchFloorplansWorker);
  yield takeLatest(FloorplansActions.ADD_NEW_FLOORPLAN, addNewFloorlanWorker);
  yield takeLatest(FloorplansActions.EDIT_FLOORPLAN, editFloorplanWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLAN_BY_ID, fetchFloorplanWorker);
  yield takeLatest(FloorplansActions.DELETE_FLOORPLAN_FROM_SERVER, deleteFloorplanWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLAN_DETAIL_BY_ID, fetchFloorplanDetailWorker);
  yield takeEvery(FloorplansActions.UPLOAD_PLAN_FILE, uploadPlanFileWorker);
  yield takeEvery(FloorplansActions.DELETE_PLAN_FILE, deletePlanFileWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLANS_COMPANIES, fetchFloorplansCompaniesWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLANS_FIELDS, fetchFloorplansFieldsWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLANS_FILES, fetchFloorplansFilesWorker);
  yield takeLatest(FloorplansActions.FETCH_FLOORPLANS_FORMULA_VARIABLES, fetchFloorplansVatiablesWorker);
}
