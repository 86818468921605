import { useState } from "react";

// ? REDUX
import { updatePlanVariableAction } from "redux-store/redux-actions/planVariables";

// ? COMPONENTS
import { PLAN_VARIABLES_NAME, PLAN_VARIABLES } from "resource/constants/plan-variables";
import { ActionName } from "resource/constants/api";
import ModalManager from "../ModalManager";
import PlanVariablesForm from "pages/plan-variables/PlanVariablesForm";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

export const nameUpdate = `${PLAN_VARIABLES_NAME}/${ActionName.UPDATE}`;

const PlanVariableUpdate = () => {
  const { close, data } = useModal(nameUpdate);

  const initialFormValues = {
    idnumber: data.idnumber,
    sort: data.sort,
    xtype: data.xtype,
    xvalue: data.xvalue,
    varname: data.varname,
    name: data.name,
    idtrack: data.idtrack,
  };

  const [isLoading, setLoader] = useState(false);
  const updatePlanVariable = useAsync(updatePlanVariableAction);

  const handleSubmit = async (values) => {
    setLoader(true);

    try {
      await updatePlanVariable({ values, actionType: ActionName.UPDATE });
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate}>
      <PlanVariablesForm
        title={PLAN_VARIABLES.EDIT_MODAL_TITLE}
        buttonText={PLAN_VARIABLES.EDIT_MODAL_SUBMIT_BUTTON}
        formData={initialFormValues}
        handleClose={close}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </ModalManager>
  );
};

export default PlanVariableUpdate;
