import { VendorsActions } from "redux-store/redux-actions/vendors";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";
import { optionsType } from "resource/interfaces";
import { basicOptions } from "resource/constants/vendors";
import { Vendor, VendorDetail } from "resource/interfaces/vendors";

interface vendorsState {
  vendors: Vendor[];
  vendorsCount: number;
  options: { [name: string]: optionsType };
  editVendor: VendorDetail | Record<string, unknown>;
  vendorsError: string;
}
const initialState: vendorsState = {
  vendors: [],
  vendorsCount: 0,
  options: basicOptions,
  editVendor: {},
  vendorsError: "",
};

export const vendorsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VendorsActions.GET_VENDORS:
      return {
        ...state,
        vendors: [...state.vendors, ...getUniqueData(payload.data, state.vendors, "idnumber")],
        vendorsCount: payload.count,
      };
    case VendorsActions.GET_VENDOR_BY_ID:
      return {
        ...state,
        vendors: updateCollection(state.vendors, payload, "idnumber"),
      };
    case VendorsActions.PUT_VENDOR_BY_ID:
      return {
        ...state,
        vendors: [
          ...state.vendors.map((vendor: any) => {
            if (vendor.idnumber === payload.idnumber) {
              return payload;
            } else {
              return vendor;
            }
          }),
        ],
      };
    case VendorsActions.DELETE_VENDOR_FROM_STORE:
      return {
        ...state,
        vendors: [...state.vendors.filter((vendor: any) => vendor.idnumber !== payload.idnumber)],
      };
    case VendorsActions.GET_EDIT_VENDOR:
      return { ...state, editVendor: payload };
    case VendorsActions.PUT_VENDORS_TYPE_LIST:
      return {
        ...state,
        options: {
          ...state.options,
          payterms: {
            field: "payterms",
            list: payload.paymentTerms.map((el: string, index: number) => {
              return { label: el, value: index };
            }),
          },
          category: {
            field: "category",
            list: payload.category.map((el: string) => {
              return { label: el, value: el };
            }),
          },
        },
      };
    case VendorsActions.CLEAR_EDIT_VENDORS_DATA:
      return {
        ...state,
        editVendor: {},
      };
    case VendorsActions.CLEAR_VENDORS_DATA:
      return {
        ...state,
        vendors: [],
        vendorsCount: 0,
      };
    case VendorsActions.CATCH_VENDORS_ERROR:
      return { ...state, vendorsError: payload };
    default:
      return state;
  }
};
