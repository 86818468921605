import { useState } from "react";

// ? REDUX
import { editCostCodeAction } from "redux-store/redux-actions/costCodes";

// ? COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import CostCodesForm from "pages/cost-codes/CostCodesForm";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { COST_CODES_NAME, COST_CODES } from "resource/constants/cost-codes";
import { ActionName } from "resource/constants/api";

import { CostCodeOnResponse } from "resource/interfaces/cost-codes";

export const nameUpdate = `${COST_CODES_NAME}/${ActionName.UPDATE}`;

const CostCodesUpdate = ({ costCodesGroups }) => {
  const { close, data } = useModal(nameUpdate);

  const [isLoading, setLoader] = useState(false);
  const editCostCode = useAsync(editCostCodeAction);

  const formData = {
    costcode: data.name,
    number: data.code,
    group: data.area,
    description: data.notes,
    idtrack: data.idtrack,
  };

  const handleSubmit = async (values: CostCodeOnResponse) => {
    setLoader(true);

    try {
      await editCostCode({ values, actionType: ActionName.UPDATE });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate}>
      <CostCodesForm
        title={COST_CODES.EDIT_MODAL_TITLE}
        buttonText={COST_CODES.EDIT_MODAL_SUBMIT_BUTTON}
        formData={formData}
        handleClose={close}
        handleSubmit={handleSubmit}
        costCodesGroups={costCodesGroups}
        isLoading={isLoading}
        isUpdate={true}
      />
    </ModalManager>
  );
};

export default CostCodesUpdate;
