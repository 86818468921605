import { useEffect, FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPlanTakeoffByIdAction } from "redux-store/redux-actions/planTakeoffs";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { TabsUnstyled } from "@mui/base";
import { CircularProgress } from "@mui/material";
import { Tab, TabPanel, TabsList } from "resource/styled";
import PlanTakeoffDetailHeader from "./PlanTakeoffDetailHeader";
import PlanTakeoffAddAssembly from "components/modals/create/PlanTakeoffAddAssembly";
import PlanTakeoffAddBudget from "components/modals/create/PlanTakeoffAddBudget";
import PlanTakeoffPlanImport from "components/modals/import/PlanTakeoffPlanImport";
import PlanTakeoffProjectImport from "components/modals/import/PlanTakeoffProjectImport";
import TakeoffImportConfirm from "components/modals/confirm/TakeoffImportConfirm";
import PlanTakeoffDelete from "components/modals/delete/PlanTakeoffDelete";
import PlanTakeoffDetailTab from "./PlanTakeoffDetailTab";
import TakeoffsBudgetTab from "pages/takeoffs/TakeoffsBudgetTab";
import TakeoffsDetailInfo from "../TakeoffsDetailInfo";

// ? RESOURCES
import { PlanTakeoffInterface } from "resource/interfaces/plan-takeoff";
import { TAKEOFFS } from "resource/constants/takeoffs";

interface PlanTakeoffDetailProps {
  id: number;
}

const PlanTakeoffDetail: FC<PlanTakeoffDetailProps> = ({ id }) => {
  const dispatch = useDispatch();

  const planTakeoff: PlanTakeoffInterface = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffItems[id]);

  useEffect(() => {
    dispatch(fetchPlanTakeoffByIdAction({ id }));
  }, [id, dispatch]);

  return (
    <>
      {planTakeoff ? (
        <>
          <PlanTakeoffDetailHeader id={id} name={planTakeoff.name} status={planTakeoff.status} />
          <TakeoffsDetailInfo takeoff={planTakeoff} />
          <TabsUnstyled defaultValue={0}>
            <TabsList>
              <Tab>Takeoff</Tab>
              <Tab>Budget</Tab>
            </TabsList>
            <TabPanel value={0}>
              <PlanTakeoffDetailTab takeoff={planTakeoff} />
            </TabPanel>
            <TabPanel value={1}>
              <TakeoffsBudgetTab
                takeoffId={planTakeoff.idnumber}
                takeoffBudget={planTakeoff.budget}
                takeoffType={TAKEOFFS.PLAN_TITLE}
              />
            </TabPanel>
          </TabsUnstyled>
          <PlanTakeoffAddAssembly />
          <PlanTakeoffAddBudget />
          <PlanTakeoffPlanImport />
          <PlanTakeoffProjectImport />
          <PlanTakeoffDelete />
          <TakeoffImportConfirm />
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(PlanTakeoffDetail);
