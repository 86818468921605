import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { fetchFloorlanVariablesAction, clearDataDetailsAction } from "redux-store/redux-actions/masterFloorplans";

// ? COMPONENTS
import { Box, CircularProgress, TextField } from "@mui/material";
import { Field } from "formik";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

// ? RESOURCES
import { formattedDateTimeNow, getFormattedDateTime } from "resource/utils";
import { menu } from "resource/constants/menu-list";

const MasterFloorplanDetailVariablesTab = ({ idnumber, callback }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { hasEditAccess } = useAccess(menu.masterFloorplans.idmenu);
  const user = useSelector((state: RootStateOrAny) => state.login.userData);

  const variables = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanVariables);
  const variablesCount = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanVariablesCount);
  const hasMoreToLoad = variablesCount > variables.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
    }),
    [idnumber]
  );

  const localData = [...variables];

  useInfiniteLoading(fetchFloorlanVariablesAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDataDetailsAction("Variables"));
    };
  }, []);

  return (
    <Box className={styles.MasterFloorplanDetailsDataList}>
      <TableListHeader>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Variable</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Formula ID</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Type</Typography>
        </Grid>
        <Grid item xs={5} md={5}>
          <Typography color={theme.palette.primary.main}>Note</Typography>
        </Grid>
        <Grid item xs={1.5} md={1.5}>
          <Typography color={theme.palette.primary.main}>Updated By</Typography>
        </Grid>
        <Grid item xs={1.5} md={1.5}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
      </TableListHeader>
      <div className={styles.FormList}>
        {variables.length > 0 &&
          variables.map((el, index: number) => (
            <div key={index}>
              <TableListRow>
                <Grid item xs={2}>
                  <Typography color={theme.palette.text.primary}>{el.name}</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Typography color={theme.palette.text.primary}>{el.idfield}</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Typography color={theme.palette.text.primary}>{el.field_type}</Typography>
                </Grid>
                <Grid item xs={5} md={5}>
                  {hasEditAccess && (
                    <Field
                      as={TextField}
                      className={styles.ListInput}
                      placeholder="Enter note"
                      value={el.field_value}
                      onChange={(e) => {
                        localData[index].field_value = e.target.value;
                        localData[index].modiby = user.username;
                        localData[index].modidate = formattedDateTimeNow();
                        localData[index].changed = true;
                        if (callback) {
                          callback(localData);
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={1.5} md={1.5}>
                  <Typography color={theme.palette.text.primary}>{el.modiby}</Typography>
                </Grid>
                <Grid item xs={1.5} md={1.5}>
                  <Typography color={theme.palette.text.primary}>
                    {el.modidate ? getFormattedDateTime(el.modidate) : ""}
                  </Typography>
                </Grid>
              </TableListRow>
            </div>
          ))}
      </div>
      {paginationLoading && (
        <div className={`LoaderScroll ${!variables.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default MasterFloorplanDetailVariablesTab;
