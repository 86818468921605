export const URL = "https://test2.costruct.app";
export const BASE_URL = `${URL}/api`;

// ? ACTIONS
export enum ActionName {
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

// ? LOGIN
export const LOGIN_API = `${BASE_URL}/auth`;

// ? DATABASE
export const COMPANIES_API = `${BASE_URL}/cia`;
export const COST_CODES_API = `${BASE_URL}/costcode`;
export const MASTER_ROLES_API = `${BASE_URL}/masterrole`;
export const USERS_PROFILE_API = `${BASE_URL}/user`;
export const COMPANY_LOCATIONS_API = `${BASE_URL}/geolocation`;
export const PLAN_VARIABLES_API = `${BASE_URL}/formulavariables`;
export const BASE_PLANS_API = `${BASE_URL}/baseplans`;

// ? ACCOUNTING
export const VENDORS_API = `${BASE_URL}/vendor`;

// ? PURCHASING
export const PARTS_API = `${BASE_URL}/part`;
export const ASSEMBLIES_API = `${BASE_URL}/assemblies`;
export const PURCHASE_ORDER_API = `${BASE_URL}/purchaseorder`;
export const VENDOR_PRICING_API = `${BASE_URL}/vendorpricing`;
export const MASTER_ACCOUNT_API = `${BASE_URL}/masteraccount`;
export const PLAN_TAKEOFF_API = `${BASE_URL}/plantakeoff`;
export const PROJECT_TAKEOFF_API = `${BASE_URL}/projecttakeoff`;

// ? PROJECTS
export const MASTER_FLOORPLANS_API = `${BASE_URL}/floorplan`;
export const CUSTOM_FIELDS_API = `${BASE_URL}/customfields`;
export const CONTRACTS_API = `${BASE_URL}/contract`;
export const PROJECTS_API = `${BASE_URL}/project`;
export const CHANGE_ORDERS_API = `${BASE_URL}/changeorder`;
export const JOB_COST_API = `${BASE_URL}/jobcost`;
export const DEVELOPMENTS_API = `${BASE_URL}/developments`;
export const PAYMENT_SUMMARY_API = `${BASE_URL}/paymentsummary`;
