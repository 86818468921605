import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// ? REDUX
import {
  updateProjectTakeoffAssemblyNotesAction,
  updateProjectTakeoffAssemblyStatusAction,
  deleteProjectTakeoffAssemblyAction,
} from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import { Grid, Typography, useTheme, CircularProgress, IconButton, Button } from "@mui/material";
import { TableListDivider } from "components/shared/table-list/TableList";
import { nameAddVendor } from "components/modals/create/ProjectTakeoffAddVendor";
import { SettingsButton } from "components/settings-button/SettingsButton";
import ProjectTakeoffParts from "./ProjectTakeoffParts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useAsync from "custom-hooks/useAsync";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import ToggleField from "components/shared/toggle-field/ToggleField";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import HighlightedText from "components/shared/highlighted-text/HighlightedText";

// ? RESOURCES
import { TakeoffEndpoints, Statuses } from "resource/constants/takeoffs";
import { getFormattedDateTime, isBiggerThanZero, formatPrice, resolvePath } from "resource/utils";
import { projectTakeoffNotesSchema } from "resource/schemas/projectTakeoffSchema";
import { AssemblyInterface } from "resource/interfaces/project-takeoff";
import { menu } from "resource/constants/menu-list";
import { ROUTES } from "resource/routes/routes";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";

interface ProjectTakeoffAssemblyItemsProps {
  assembly: AssemblyInterface;
  takeoffId: string;
  takeoffIdcia: number;
  isAllCollapsed: boolean;
  searchQuery: string;
}

const ProjectTakeoffAssemblyItem: FC<ProjectTakeoffAssemblyItemsProps> = ({
  assembly,
  takeoffId,
  takeoffIdcia,
  searchQuery,
  isAllCollapsed,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const isReleased = assembly.status === Statuses.Released;
  const dataForFetch = { id: takeoffId, idcia: takeoffIdcia };
  console.log();
  const mainPath = ROUTES.projectTakeoff.pathname;
  const subDirectories = [takeoffId, takeoffIdcia, assembly.idassembly1, assembly.idvendor];

  const updateAssemblyNotes = useAsync(updateProjectTakeoffAssemblyNotesAction);
  const updateAssemblyStatus = useAsync(updateProjectTakeoffAssemblyStatusAction);
  const deleteProjectTakeoffAssembly = useAsync(deleteProjectTakeoffAssemblyAction);

  const [open, setOpen] = useState(!isAllCollapsed);
  const [note, setNote] = useState(assembly.notes);
  const [isLoading, setLoader] = useState(false);
  const { open: openAddVendor } = useModal(nameAddVendor);
  const { hasDeleteAccess, hasEditAccess } = useAccess(menu.projectTakeoff.idmenu);

  useEffect(() => {
    setOpen(!isAllCollapsed);
  }, [isAllCollapsed]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleStatusUpdate = async () => {
    setLoader(true);
    const values = {
      idassembly1: assembly.idassembly1,
      idproject1: takeoffId,
      idcia: String(takeoffIdcia),
      idtrack: assembly.idtrack,
    };

    try {
      await updateAssemblyStatus({ values, endpoint: TakeoffEndpoints.UpdateAssemblyStatus, dataForFetch });
      setTimeout(() => setLoader(false), 1000);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleSubmit = async ({ notes }) => {
    setNote(notes);
    if (notes !== assembly.notes) {
      setLoader(true);
      const values = {
        idassembly1: assembly.idassembly1,
        idproject1: String(takeoffId),
        idcia: String(takeoffIdcia),
        notes,
        idtrack: assembly.idtrack,
      };

      try {
        await updateAssemblyNotes({ values, endpoint: TakeoffEndpoints.UpdateAssemblyNotes, dataForFetch });
        setTimeout(() => setLoader(false), 1000);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    }
  };

  const handleAssemblyDelete = async () => {
    setLoader(true);
    const values = {
      idassembly1: assembly.idassembly1,
      idproject1: takeoffId,
      idcia: takeoffIdcia,
    };

    try {
      await deleteProjectTakeoffAssembly({ values, endpoint: TakeoffEndpoints.DeleteAssembly, dataForFetch });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {assembly ? (
        <>
          <div onDoubleClick={() => history.push(resolvePath(mainPath, subDirectories))}>
            <TableListDivider isReleased={isReleased}>
              <Grid item xs={0.5}>
                <IconButton aria-label="Open Menu" onClick={handleClick} sx={{ ml: "-10px" }}>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
              <Grid item xs={0.75}>
                <Typography color={theme.palette.text.primary}>{assembly.idassembly1}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color={theme.palette.text.primary}>
                  <HighlightedText searchQuery={searchQuery} text={assembly.name} />
                </Typography>
                {!isReleased && (
                  <Button
                    onClick={() =>
                      hasEditAccess &&
                      openAddVendor({ ...dataForFetch, idassembly1: assembly.idassembly1, idtrack: assembly.idtrack })
                    }
                    className={styles.InlineButton}
                  >
                    {assembly.vendor ? assembly.vendor : "Assign Vendor"}
                  </Button>
                )}
              </Grid>
              <Grid item xs={1}>
                {isReleased ? (
                  <Typography color={theme.palette.text.primary}>{assembly.status}</Typography>
                ) : (
                  <>
                    {isLoading ? (
                      <SmallLoader />
                    ) : (
                      <Button onClick={() => hasEditAccess && handleStatusUpdate()} className={styles.InlineButton}>
                        {assembly.status === Statuses.Completed ? assembly.status : "Click"}
                      </Button>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={1} sx={{ minHeight: "32px" }}>
                <ToggleField
                  schema={projectTakeoffNotesSchema}
                  handleSubmit={handleSubmit}
                  fieldName="notes"
                  field={note}
                  isLoading={isLoading}
                  isTogglable={!isReleased}
                  type="text"
                  initValues={{ notes: note ? note : "" }}
                  hasAccess={hasEditAccess}
                />
              </Grid>
              <Grid item xs={0.75}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={0.5}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(String(isBiggerThanZero(assembly.taxable)))}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(String(isBiggerThanZero(assembly.total)))}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>{assembly.modiby}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                  {getFormattedDateTime(assembly.modidate)}
                </Typography>
              </Grid>
              <Grid item xs={0.5} justifyContent="flex-end" display="flex">
                {hasEditAccess && (
                  <>
                    {isLoading ? (
                      <SmallLoader />
                    ) : (
                      <SettingsButton
                        options={[
                          {
                            text: "Delete",
                            type: "delete",
                            callback: () => handleAssemblyDelete(),
                            access: hasDeleteAccess,
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </Grid>
            </TableListDivider>
          </div>
          <ProjectTakeoffParts
            projectIds={assembly.list}
            open={open}
            takeoffId={takeoffId}
            takeoffIdcia={takeoffIdcia}
            assemblyId={assembly.idassembly1}
            isReleased={isReleased}
            searchQuery={searchQuery}
          />
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default ProjectTakeoffAssemblyItem;
