import { CostCodeOnRequest, CostCodeOnResponse } from "resource/interfaces/cost-codes";
import { PromiseType } from "resource/interfaces";

interface ResponsePayload {
  values: CostCodeOnResponse;
  actionType: string;
}

// ? ACTIONS
export enum CostCodesActions {
  ADD_NEW_COST_CODE = "ADD_NEW_COST_CODE",
  EDIT_COST_CODE = "EDIT_COST_CODE",
  DELETE_COST_CODE = "DELETE_COST_CODE",
  DELETE_COST_CODE_FROM_STORE = "DELETE_COST_CODE_FROM_STORE",
  PUT_COST_CODE_BY_ID = "PUT_COST_CODE_BY_ID",
  PUT_COST_CODES = "PUT_COST_CODES",
  PUT_COST_CODES_GROUPS = "PUT_COST_CODES_GROUPS",
  FETCH_COST_CODES = "FETCH_COST_CODES",
  FETCH_COST_CODE_BY_ID = "FETCH_COST_CODE_BY_ID",
  FETCH_COST_CODES_GROUPS = "FETCH_COST_CODES_GROUPS",
  CLEAR_COST_CODE_DATA = "CLEAR_COST_CODE_DATA",
  CATCH_COST_CODES_ERROR = "CATCH_COST_CODES_ERROR",
}

// ? ACTION CREATORS
export const addNewCostCodeAction = (payload: ResponsePayload, promise?: PromiseType) => {
  return {
    type: CostCodesActions.ADD_NEW_COST_CODE,
    payload,
    promise,
  };
};

export const editCostCodeAction = (payload: ResponsePayload, promise?: PromiseType) => {
  return {
    type: CostCodesActions.EDIT_COST_CODE,
    payload,
    promise,
  };
};

export const deleteCostCodeAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: CostCodesActions.DELETE_COST_CODE,
    payload,
    promise,
  };
};

export const deleteCostCodeFromStoreAction = (payload: number) => {
  return {
    type: CostCodesActions.DELETE_COST_CODE_FROM_STORE,
    payload,
  };
};

export const putCostCodesAction = (payload: { data: CostCodeOnRequest[]; count: number }) => {
  return {
    type: CostCodesActions.PUT_COST_CODES,
    payload,
  };
};

export const putCostCodeByIdAction = (payload: CostCodeOnRequest) => {
  return {
    type: CostCodesActions.PUT_COST_CODE_BY_ID,
    payload,
  };
};

export const putCostCodesGroupsAction = (payload: string[]) => {
  return {
    type: CostCodesActions.PUT_COST_CODES_GROUPS,
    payload,
  };
};

export const fetchCostCodesAction = (payload: number) => {
  return {
    type: CostCodesActions.FETCH_COST_CODES,
    payload,
  };
};

export const fetchCostCodeByIdAction = (payload: any) => {
  return {
    type: CostCodesActions.FETCH_COST_CODE_BY_ID,
    payload,
  };
};

export const fetchCostCodesGroupsAction = () => {
  return {
    type: CostCodesActions.FETCH_COST_CODES_GROUPS,
  };
};

export const clearDataAction = () => {
  return {
    type: CostCodesActions.CLEAR_COST_CODE_DATA,
  };
};

export const catchCostCodesErrorAction = (payload: string) => {
  return {
    type: CostCodesActions.CATCH_COST_CODES_ERROR,
    payload,
  };
};
