import { useState, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { reOpenPurchaseOrderAction } from "redux-store/redux-actions/purchaseOrder";

// ? COMPONENTS
import { Grid } from "@mui/material";
import { nameConfirmVoid } from "components/modals/confirm/PurchaseOrderVoidConfirm";
import { nameCreateReleaseEmail } from "components/modals/create/PurchaseOrderCreateReleaseEmail";
import { nameApprovalRequestEmail } from "components/modals/create/PurchaseOrderRequestApprovalEmail";
import { nameCreateApproveEmail } from "components/modals/create/PurchaseOrderCreateApproveEmail";
import { nameCreateDisapproveEmail } from "components/modals/create/PurchaseOrderCreateDisapproveEmail";
import { nameUpdate } from "components/modals/update/PurchaseOrderUpdate";
import useAsync from "custom-hooks/useAsync";
import useModal from "custom-hooks/useModal";
import ActionButton from "components/shared/action-button/ActionButton";

// ? RESOURCES
import { ActionButtons, PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { StatusList } from "resource/consts";
import { PurchaseOrderDetailResponse } from "resource/interfaces/purchase-order";

interface Buttons {
  color: string;
  text: string;
  action: string;
}

interface PurchaseOrderActionsProps {
  order: PurchaseOrderDetailResponse;
}

const getButtons = (status: string, total: number, limit: number): Buttons[] => {
  switch (status) {
    case StatusList.DRAFT:
      return total > limit
        ? [ActionButtons.requestApproval, ActionButtons.saveAndRelease, ActionButtons.void, ActionButtons.update]
        : [ActionButtons.saveAndRelease, ActionButtons.void, ActionButtons.update];
    case StatusList.PENDING:
      return total > limit
        ? [ActionButtons.void, ActionButtons.requestApproval, ActionButtons.update]
        : [ActionButtons.approve, ActionButtons.disapprove, ActionButtons.void];
    case StatusList.RELEASED:
      return [ActionButtons.reOpen, ActionButtons.void];
    case StatusList.DISAPPROVED:
      return [ActionButtons.reOpen, ActionButtons.void];
    case StatusList.BILLED:
      return [ActionButtons.reOpen, ActionButtons.void];
    case StatusList.PRINT_CHECK:
      return [ActionButtons.reOpen, ActionButtons.void];
    case StatusList.PAID:
      return [ActionButtons.print, ActionButtons.email];
    case StatusList.VOID:
      return [ActionButtons.reOpen];
    default:
      return [];
  }
};

const PurchaseOrderActions: FC<PurchaseOrderActionsProps> = ({ order }) => {
  const approvalLimit = useSelector((state: RootStateOrAny) => state.login.userData.billauth);
  const buttonsToRender = getButtons(order.status, order.total, approvalLimit);
  const [loader, setLoader] = useState(false);
  const reOpenPurchaseOrder = useAsync(reOpenPurchaseOrderAction);

  const { open: openConfirmVoid } = useModal(nameConfirmVoid);
  const { open: openCreateReleaseEmail } = useModal(nameCreateReleaseEmail);
  const { open: openApprovalRequestEmail } = useModal(nameApprovalRequestEmail);
  const { open: openDisapproveEmail } = useModal(nameCreateDisapproveEmail);
  const { open: openApproveEmail } = useModal(nameCreateApproveEmail);
  const { open: openUpdate } = useModal(nameUpdate);

  const handleReOpenPurchaseOrder = async (data: PurchaseOrderDetailResponse) => {
    const dataForSubmit = { idnumber: Number(data.idnumber), idtrack: data.idtrack };

    setLoader(true);
    try {
      await reOpenPurchaseOrder({ data: dataForSubmit, actionType: PurchaseOrderEndpoints.RE_OPEN });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleActionsButton = (actionType: string) => {
    switch (actionType) {
      case ActionButtons.saveAndRelease.action:
        openCreateReleaseEmail(order);
        break;
      case ActionButtons.reOpen.action:
        handleReOpenPurchaseOrder(order);
        break;
      case ActionButtons.void.action:
        openConfirmVoid(order);
        break;
      case ActionButtons.update.action:
        openUpdate(order);
        break;
      case ActionButtons.requestApproval.action:
        openApprovalRequestEmail(order);
        break;
      case ActionButtons.disapprove.action:
        openDisapproveEmail(order);
        break;
      case ActionButtons.approve.action:
        openApproveEmail(order);
        break;
      default:
        console.log(`${actionType}`);
    }
  };

  return (
    <>
      <Grid item display={"flex"} flexDirection={"row"}>
        {buttonsToRender?.map((button) => (
          <div key={button.text}>
            <ActionButton button={button} handleActionsButton={handleActionsButton} isLoading={loader} />
          </div>
        ))}
      </Grid>
    </>
  );
};

export default PurchaseOrderActions;
