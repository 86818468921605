import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum ProjectsActions {
  ADD_NEW_PROJECT = "ADD_NEW_PROJECT",
  EDIT_PROJECT = "EDIT_PROJECT",
  PUT_PROJECT_BY_ID = "PUT_PROJECT_BY_ID",
  PUT_PROJECTS = "PUT_PROJECTS",
  FETCH_PROJECTS = "FETCH_PROJECTS",
  FETCH_PROJECT_BY_ID = "FETCH_PROJECT_BY_ID",
  CLEAR_PROJECT_DATA = "CLEAR_PROJECT_DATA",
  CLEAR_PROJECT_DETAIL_DATA = "CLEAR_PROJECT_DETAIL_DATA",
  DELETE_PROJECT = "DELETE_PROJECT",
  DELETE_PROJECT_FROM_STORE = "DELETE_PROJECT_FROM_STORE",
  FETCH_PROJECT_DETAIL = "FETCH_PROJECT_DETAIL",
  GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL",

  UPLOAD_PROJECT_FILE = "UPLOAD_PROJECT_FILE",
  DELETE_PROJECT_FILE = "DELETE_PROJECT_FILE",
  PUT_PROJECT_FILE_IN_STORE = "PUT_PROJECT_FILE_IN_STORE",
  DELETE_PROJECT_FILE_FROM_STORE = "DELETE_PROJECT_FILE_FROM_STORE",

  FETCH_PROJECT_FIELDS = "FETCH_PROJECT_FIELDS",
  FETCH_PROJECT_FORMULA_VARIABLE = "FETCH_PROJECT_FORMULA_VARIABLE",
  FETCH_PROJECT_BASE_PLANS = "  FETCH_PROJECT_BASE_PLANS",
  FETCH_PROJECT_MEMBERS = "FETCH_PROJECT_MEMBERS",

  PUT_PROJECT_FIELDS = "PUT_PROJECT_FIELDS",
  PUT_PROJECT_FORMULA_VARIABLE = "PUT_PROJECT_FORMULA_VARIABLE",
  PUT_PROJECT_BASE_PLANS = "  PUT_PROJECT_BASE_PLANS",
  PUT_PROJECT_MEMBERS = "PUT_PROJECT_MEMBERS",

  UPLOAD_PROJECT_MEMBER = "UPLOAD_PROJECT_MEMBER",
  DELETE_PROJECT_MEMBER = "DELETE_PROJECT_MEMBER",
  PUT_PROJECT_MEMBER_IN_STORE = "PUT_PROJECT_MEMBER_IN_STORE",
  DELETE_PROJECT_MEMBER_FROM_STORE = "DELETE_PROJECT_MEMBER_FROM_STORE",
}

// ? ACTION CREATORS
export const addNewProjectAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.ADD_NEW_PROJECT,
    payload,
    promise,
  };
};

export const editProjectAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.EDIT_PROJECT,
    payload,
    promise,
  };
};

export const putProjectsAction = (payload: { data; count: number }) => {
  return {
    type: ProjectsActions.PUT_PROJECTS,
    payload,
  };
};

export const putProjectByIdAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_BY_ID,
    payload,
  };
};

export const fetchProjectsAction = (payload: number) => {
  return {
    type: ProjectsActions.FETCH_PROJECTS,
    payload,
  };
};

export const fetchProjectByIdAction = (payload: any) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_BY_ID,
    payload,
  };
};
export const fetchProjectFieldsAction = (payload) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_FIELDS,
    payload,
  };
};

export const putProjectFieldsAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_FIELDS,
    payload,
  };
};

export const fetchProjectFormulaVariableAction = (payload) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_FORMULA_VARIABLE,
    payload,
  };
};

export const putProjectFormulaVariableAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_FORMULA_VARIABLE,
    payload,
  };
};

export const fetchProjectBasePlanAction = (payload) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_BASE_PLANS,
    payload,
  };
};

export const putProjectBasePlanAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_BASE_PLANS,
    payload,
  };
};

export const fetchProjectMembersAction = (payload) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_MEMBERS,
    payload,
  };
};

export const putProjectMembersAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_MEMBERS,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: ProjectsActions.CLEAR_PROJECT_DATA,
  };
};

export const clearDataDetailAction = (payload) => {
  return {
    type: ProjectsActions.CLEAR_PROJECT_DETAIL_DATA,
    payload,
  };
};

export const deleteProjectAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: ProjectsActions.DELETE_PROJECT,
    payload,
    promise,
  };
};

export const deleteProjectFromStoreAction = (payload: number) => {
  return {
    type: ProjectsActions.DELETE_PROJECT_FROM_STORE,
    payload,
  };
};

export const uploadProjetFileAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.UPLOAD_PROJECT_FILE,
    payload,
    promise,
  };
};

export const deleteProjetFileAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.DELETE_PROJECT_FILE,
    payload,
    promise,
  };
};

export const putProjetFileInStoreAction = (payload) => {
  return {
    type: ProjectsActions.PUT_PROJECT_FILE_IN_STORE,
    payload,
  };
};

export const deleteProjetFileFromStoreAction = (payload) => {
  return {
    type: ProjectsActions.DELETE_PROJECT_FILE_FROM_STORE,
    payload,
  };
};

export const uploadProjectMemberAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.UPLOAD_PROJECT_MEMBER,
    payload,
    promise,
  };
};

export const deleteProjectMemberAction = (payload, promise?: PromiseType) => {
  return {
    type: ProjectsActions.DELETE_PROJECT_MEMBER,
    payload,
    promise,
  };
};

export const putProjectMembersInStoreAction = (payload: any) => {
  return {
    type: ProjectsActions.PUT_PROJECT_MEMBER_IN_STORE,
    payload,
  };
};

export const deleteProjectMembersFromStoreAction = (payload: any) => {
  return {
    type: ProjectsActions.DELETE_PROJECT_MEMBER_FROM_STORE,
    payload,
  };
};

export const fetchProjectDetailAction = (payload: any, promise?: PromiseType) => {
  return {
    type: ProjectsActions.FETCH_PROJECT_DETAIL,
    payload,
    promise,
  };
};

export const getProjectDetailAction = (payload: any) => {
  return {
    type: ProjectsActions.GET_PROJECT_DETAIL,
    payload,
  };
};
