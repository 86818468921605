import React, { useMemo, useState, useEffect } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { addNewFloorplanAction, clearDataAction, fetchFloorplansAction } from "redux-store/redux-actions/masterFloorplans";

// ? COMPONENTS
import { Modal } from "@mui/material";
import { MasterFloorplansForm } from "./MasterFloorplansForm";
import { Search } from "components/search/Search";
import { MasterFloorplansList } from "./MasterFloorplansList";
import { withLayout } from "hoc/withLayout";
import PageHeader from "components/page-header/PageHeader";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import FilterSelectWithSearch from "components/shared/filter-select/FilterSelectWithSearch";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

// ? RESOURCES
import { MASTER_FLOORPLANS } from "resource/constants/master-floorplan";
import { masterFloorplanSchema } from "resource/schemas/masterFloorplanSchema";
import { filterOptions } from "resource/constants/master-floorplan";
import { COMPANIES_API } from "resource/constants/api";

const MasterFloorplan = () => {
  const dispatch = useDispatch();

  const uploadLoading = useSelector((state: RootStateOrAny) => state.loaders.buttonLoader);
  const [isModalOpen, toggleModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<any>(null);
  const [filter, setFilter] = useState(filterOptions[0]);

  const floorplans = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplans);
  const floorplansCount = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplansCount);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const hasMoreToLoad = floorplansCount > floorplans.length;

  // COMPANY SERACH
  const [idcia, setCompanyID] = useState(0);
  const companyHandleChange = (value) => {
    setCompanyID(value !== null ? value.value : 0);
    setPage(0);
    dispatch(clearDataAction());
  };
  // = = =

  const openModal = () => {
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const additionalData = useMemo(
    () => ({
      filter,
      keywords: searchKeyword,
      idcia,
    }),
    [filter, searchKeyword, idcia]
  );

  const { setPage } = useInfiniteLoading(fetchFloorplansAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  const handleFilterChange = (evt): void => {
    setFilter(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  useEffect(() => {
    dispatch(clearDataAction());
  }, []);

  return (
    <>
      <PageHeader pageName="masterFloorplans">
        <FilterSelect handleChange={handleFilterChange} select={filter} options={filterOptions} />
        <FilterSelectWithSearch handleChange={companyHandleChange} api={`${COMPANIES_API}/list`} />
        <Search value={searchValue} placeholder="Search floorplan" handleChange={handleSearchChange} />
        <AddNewButton text={MASTER_FLOORPLANS.NEW_MODAL_PLUS_BUTTON} openModal={openModal} disabled={paginationLoading} />
      </PageHeader>

      <MasterFloorplansList openModal={openModal} />

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.Modal}
      >
        <>
          <MasterFloorplansForm
            title={MASTER_FLOORPLANS.NEW_MODAL_TITLE}
            buttonText={MASTER_FLOORPLANS.NEW_MODAL_SUBMIT_BUTTON}
            closeModal={closeModal}
            callback={addNewFloorplanAction}
            validation={masterFloorplanSchema}
            size="large"
            isLoading={uploadLoading}
          />
        </>
      </Modal>
    </>
  );
};

export default withLayout(MasterFloorplan);
