import { useState, useEffect } from "react";
import useHeaders from "./useHeaders";
import useTimer from "./useTimer";

export const useSearch = (api, params = {}, debounceTime = 1000, charSkip = 2) => {
  const headers = useHeaders();
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [query, setQuery] = useState("");
  const [isPending, setPending] = useState(false);
  const [initialCondition, setCondition] = useState(false);

  let paramsString = "";
  for (const param in params) {
    paramsString += `&${param}=${params[param]}`;
  }

  const fetchData = () => {
    setPending(true);
    fetch(`${api}/list?keywords=${query}${paramsString}`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errno > 0) {
          setErrorMessage(response.message);
          setOptions([]);
        } else {
          setOptions(response.data.list);
        }
        setPending(false);
      });
    setCondition(true);
  };

  const { cleanedTimeout, settedTimeout } = useTimer(fetchData, debounceTime);

  useEffect(() => {
    if (query.length > charSkip) {
      cleanedTimeout();
      settedTimeout();
    } else if (query.length === 0) {
      setOptions([]);
      setCondition(false);
    }
  }, [query]);

  return [options, setQuery, isPending, initialCondition, errorMessage] as [
    unknown[],
    (query: string | number) => void,
    boolean,
    boolean,
    string
  ];
};
