import { useState, useEffect } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { editVendorAction, fetchEditVendorAction, clearEditVendorAction } from "redux-store/redux-actions/vendors";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { VendorsForm } from "pages/vendors/VendorsForm";
import ModalManager from "../ModalManager";

// ? RESOURCES
import { VENDORS_NAME, VENDORS } from "resource/constants/vendors";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { convertLocalDateToISOString, trimFormStrings } from "resource/utils";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

export const nameUpdate = `${VENDORS_NAME}/${ActionName.UPDATE}`;

const VendorUpdate = () => {
  const dispatch = useDispatch();
  const vendor = useSelector((state: RootStateOrAny) => state.vendors.editVendor);

  const { close, isOpened, data } = useModal(nameUpdate);
  const [isLoading, setLoader] = useState(false);
  const [setNotice] = useNotice();
  const updateCopmany = useAsync(editVendorAction);

  // SUBMIT
  const handleSubmit = async (data, workDate, insuranceDate) => {
    setLoader(true);

    if (data.insexpire) {
      data.workdate = convertLocalDateToISOString(workDate.toLocaleDateString().slice(0, 10));
      data.insdate = convertLocalDateToISOString(insuranceDate.toLocaleDateString().slice(0, 10));
    }

    if (data.email === "") {
      data.email = null;
    }

    try {
      await updateCopmany({
        values: trimFormStrings(data),
        actionType: ActionName.UPDATE,
      });
      setNotice("Vendor updated", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(fetchEditVendorAction({ idnumber: data.idnumber }));
    }

    return () => {
      dispatch(clearEditVendorAction());
    };
  }, [isOpened, dispatch, data.idnumber]);

  return (
    <ModalManager name={nameUpdate} className={styles.Modal}>
      {isOpened && vendor.idnumber ? (
        <div className={styles.Form}>
          <VendorsForm
            title={VENDORS.EDIT_MODAL_TITLE}
            buttonText={VENDORS.EDIT_MODAL_SUBMIT_BUTTON}
            formData={vendor}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default VendorUpdate;
