import { menu } from "resource/constants/menu-list";

interface RoutesDictionaryType {
  [key: string]: { pathname: string; idmenu: number } | any;
}

let pages: RoutesDictionaryType;
const getDataAboutPages = () => {
  for (const key in menu) {
    if (menu[key].pathname) {
      const item = (({ pathname, idmenu }) => ({ pathname, idmenu }))(menu[key]);
      pages = { ...pages, [key]: item };
    }
  }
  return pages;
};

const routes = getDataAboutPages();

export const ROUTES: RoutesDictionaryType = {
  login: {
    pathname: "/login",
  },
  signup: {
    pathname: "/signup",
  },
  landing: {
    pathname: "/landing",
  },
  pricing: {
    pathname: "/pricing",
  },
  pay: {
    pathname: "/pay",
  },
  success: {
    pathname: "/order-received",
  },
  ...routes,
  accessForbidden: "/403",
  notFound: "/404",
};
