import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchProjectTakeoffsAction, clearDataAction } from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import ProjectTakeoffList from "./ProjectTakeoffList";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import FilterSelectWithSearch from "components/shared/filter-select/FilterSelectWithSearch";

// ? RESOURCES
import { PROJECT_TAKEOFFS_NAME, projectTakeOffFilterList } from "resource/constants/takeoffs";
import { COMPANIES_API } from "resource/constants/api";

const ProjectTakeoffs = () => {
  const dispatch = useDispatch();
  const projectTakeoffIds: number[] = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffIds);
  const projectTakeoffs = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffItems);
  const projectTakeoffsCount: number = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffsCount);
  const hasMoreToLoad: boolean = projectTakeoffsCount > projectTakeoffIds.length;
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [select, setSelect] = useState(projectTakeOffFilterList[1]);
  const [idcia, setCompanyID] = useState(0);

  const additionalData = useMemo(
    () => ({
      status: select,
      keywords: searchKeyword,
      idcia,
    }),
    [select, searchKeyword, idcia]
  );

  const { setPage } = useInfiniteLoading(fetchProjectTakeoffsAction, hasMoreToLoad, paginationLoading, additionalData);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // COMPANY SERACH
  const companyHandleChange = (value) => {
    setCompanyID(value !== null ? value.value : 0);
    setPage(0);
    dispatch(clearDataAction());
  };
  // = = =

  return (
    <>
      <PageHeader pageName={PROJECT_TAKEOFFS_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={projectTakeOffFilterList} />
        <FilterSelectWithSearch handleChange={companyHandleChange} api={`${COMPANIES_API}/list`} />
        <Search value={searchKeyword} placeholder="Search Project Takeoff" handleChange={handleSearchChange} />
      </PageHeader>
      {projectTakeoffIds.length <= 0 && !paginationLoading && <NoDataScreen pageName={PROJECT_TAKEOFFS_NAME} />}
      {projectTakeoffIds.length > 0 && (
        <ProjectTakeoffList projectTakeoffIds={projectTakeoffIds} projectTakeoffs={projectTakeoffs} />
      )}

      {paginationLoading ? (
        <div className={`LoaderScroll ${!projectTakeoffIds.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(ProjectTakeoffs);
