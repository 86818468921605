import { FC } from "react";

// ? COMPONENTS
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, useTheme } from "@mui/material";

// ? STYLES
import styles from "./CardField.module.css";

// ? TYPES
interface CardFieldPropsTypes {
  size: string;
  title: string;
  name: string | number;
  type?: string;
  status?: boolean;
  className?: string;
}

export const CardField: FC<CardFieldPropsTypes> = ({ size, title, name, type, status, className }) => {
  const theme = useTheme();
  return (
    <Box className={styles.CardField} style={{ width: size }}>
      <Typography className={styles.CardFieldTitle} variant="body1" color={theme.palette.info.main}>
        {title}
      </Typography>

      {type !== "checkbox" ? (
        <Typography variant="body1" color={theme.palette.text.primary}>
          {name || name === 0 ? name : "No Data"}
        </Typography>
      ) : (
        <FormGroup className={className}>
          <FormControlLabel
            control={<Checkbox disabled sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }} checked={status} />}
            label={status ? "Yes" : "No"}
          />
        </FormGroup>
      )}
    </Box>
  );
};
