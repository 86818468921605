import { useState, FC } from "react";

// ? REDUX
import { addNewProjectAction } from "redux-store/redux-actions/projects";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import { ProjectsForm } from "pages/projects/ProjectsForm";

// ? STYLES
import styles from "pages/projects/Projects.module.css";

// ? RESOURCES
import { PROJECTS, PROJECTS_NAME } from "resource/constants/projects";
import { ActionName } from "resource/constants/api";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

export const nameCreate = `${PROJECTS_NAME}/${ActionName.CREATE}`;

interface ProjectsCreateProps {
  className: string;
}

const ProjectsCreate: FC<ProjectsCreateProps> = ({ className }) => {
  const { close, isOpened } = useModal(nameCreate);

  const [setNotice] = useNotice();

  const [isLoading, setLoader] = useState(false);
  const updateProject = useAsync(addNewProjectAction);

  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      await updateProject({
        values: data,
        actionType: ActionName.CREATE,
      });
      setNotice("Project created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      {isOpened ? (
        <div className={styles.ProjectsAddForm}>
          <ProjectsForm
            title={PROJECTS.NEW_MODAL_TITLE}
            formData={{}}
            buttonText={PROJECTS.NEW_MODAL_SUBMIT_BUTTON}
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default ProjectsCreate;
