// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { UserCard } from "components/info-card/UserCard";
import { Grid } from "@mui/material";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

export const MembersList = ({ users }) => {
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  if (!paginationLoading && !users.length) {
    return <NoDataScreen pageName="usersProfile" />;
  }

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
      {addNewLoader && (
        <Grid item xs="auto" sm={6} lg={4}>
          <UserCard data={{}} fake />
        </Grid>
      )}
      {users.map((user, index) => (
        <Grid key={index} item xs="auto" sm={6} lg={4}>
          <UserCard data={user} />
        </Grid>
      ))}
    </Grid>
  );
};
