export const PARTS_NAME = "parts";

export const PARTS = {
  TITLE: "Parts",
  NEW_MODAL_TITLE: "Add New Parts",
  EDIT_MODAL_TITLE: "Edit Parts Information",
  NEW_MODAL_PLUS_BUTTON: "Add New Parts",
  NEW_MODAL_SUBMIT_BUTTON: "Save Parts Information",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Parts Information",
};

export const filterOptions = ["All", "Discontinued", "Active"];

export const initialValuesPricingForm = {
  idgeoarea: "",
  lastprice: "",
  idvendor: "",
  skunumber: "",
};
