import { useState, FC } from "react";

// ? REDUX
import { addNewPlanAction } from "redux-store/redux-actions/planDrawer";

//  ?  COMPONENTS
import { PlanDrawerForm } from "pages/plan-drawer/PlanDrawerForm";
import { CircularProgress } from "@mui/material";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import { PLAN_DRAWER, PLAN_DRAWER_NAME } from "resource/constants/plan-drawer";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { trimFormStrings } from "resource/utils";

export const nameCreate = `${PLAN_DRAWER_NAME}/${ActionName.CREATE}`;


interface PlanDrawerCreateProps {
  category: string;
}

const PlanDrawerCreate: FC<PlanDrawerCreateProps> = ({ category }) => {
  const { close, isOpened } = useModal(nameCreate);
  const [isLoading, setLoader] = useState(false);
  const createPlan = useAsync(addNewPlanAction);
  const [setNotice] = useNotice();

  const initialFormValues = {
    name: "",
  };

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);
    data.category = category;

    try {
      await createPlan({
        values: trimFormStrings(data),
        actionType: ActionName.CREATE,
      });
      setNotice("Plan created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.Form}>
          <PlanDrawerForm
            title={PLAN_DRAWER.NEW_MODAL_TITLE}
            buttonText={PLAN_DRAWER.NEW_MODAL_SUBMIT_BUTTON}
            formData={initialFormValues}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PlanDrawerCreate;
