import { useState, useMemo, ChangeEvent, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchDevelopmentsAction, clearDataAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import DevelopmentsList from "./DevelopmentsList";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import useModal from "custom-hooks/useModal";
import { nameCreate } from "components/modals/create/DevelopmentsCreate";
import DevelopmentsCreate from "components/modals/create/DevelopmentsCreate";
import useAccess from "custom-hooks/useAccess";
import FilterSelectWithSearch from "components/shared/filter-select/FilterSelectWithSearch";

// ? STYLES
import styles from "./Developments.module.css";

// ? RESOURCES
import { DEVELOPMENTS, DEVELOPMENTS_NAME, developmentsFiltersList } from "resource/constants/developments";
import { menu } from "resource/constants/menu-list";
import { COMPANIES_API } from "resource/constants/api";

const Developments = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.developments.idmenu);

  const developments = useSelector((state: RootStateOrAny) => state.developments.developments);
  const developmentsCount = useSelector((state: RootStateOrAny) => state.developments.developmentsCount);
  const hasMoreToLoad = developmentsCount > developments.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(developmentsFiltersList[0]);

  const { open: openCreate } = useModal(nameCreate);

  // COMPANY SERACH
  const [idcia, setCompanyID] = useState(0);
  const companyHandleChange = (value) => {
    setCompanyID(value !== null ? value.value : 0);
    setPage(0);
    dispatch(clearDataAction());
  };
  // = = =

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const additionalData = useMemo(
    () => ({
      status: select,
      keywords: searchKeyword,
      idcia,
    }),
    [select, searchKeyword, idcia]
  );

  const { setPage } = useInfiniteLoading(fetchDevelopmentsAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDataAction());
    };
  }, []);

  return (
    <>
      <PageHeader pageName={DEVELOPMENTS_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={developmentsFiltersList} />
        <FilterSelectWithSearch handleChange={companyHandleChange} api={`${COMPANIES_API}/list`} />
        <Search value={searchKeyword} placeholder="Search developments" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={DEVELOPMENTS.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      {developments.length <= 0 && !paginationLoading && <NoDataScreen pageName={DEVELOPMENTS_NAME} />}
      {developments.length > 0 && <DevelopmentsList developments={developments} />}

      <DevelopmentsCreate className={styles.DevelopmentsModal} />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!developments.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(Developments);
