import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import ChangeOrderItem from "./ChangeOrderItem";

interface ChangeOrderListProps {
  changeOrderIds: any;
  changeOrderItems: any;
}

// ? STYLES
import styles from "./ChangeOrder.module.css";

const ChangeOrderList: FC<ChangeOrderListProps> = ({ changeOrderItems, changeOrderIds }) => {
  const theme = useTheme();
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);
  return (
    <>
      <TableListHeader>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Co. No</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Project ID</Typography>
        </Grid>
        <Grid item xs={6.5}>
          <Typography color={theme.palette.primary.main}>Project Name</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Total</Typography>
        </Grid>
      </TableListHeader>
      {addNewLoader && (
        <div className={styles.Fake}>
          <div className={`${styles.Cover} ${styles.Disabled}`}>
            <CircularProgress size={25} className={styles.Loader} />
          </div>
          <TableListRow>
            <div></div>
          </TableListRow>
        </div>
      )}
      <>
        {changeOrderIds &&
          changeOrderIds.map((idnumber: number) => (
            <div key={idnumber}>
              <ChangeOrderItem changeOrder={changeOrderItems[idnumber]} />
            </div>
          ))}
      </>
    </>
  );
};

export default ChangeOrderList;
