import { takeLatest, put } from "redux-saga/effects";
import { CONTRACTS_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import { ContractsRequest, ContractDetailResponse } from "resource/interfaces/contracts";
import { normalizeItemsList, normalizeItemById } from "resource/normalize";
import { trimFormStrings } from "resource/utils";

import { ContractsActions, putContractByIdAction, putContractsAction, fetchContractByIdAction } from "../redux-actions/contracts";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchContractsWorker(action) {
  const {
    payload: {
      additionalData: { date_from, date_to, status, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${CONTRACTS_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(date_from && { date_from }),
    ...(date_to && { date_to }),
    ...(status && { status }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putContractsAction({ data: normalizeItemsList<ContractsRequest>(data.list, "contracts"), count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchContractByIdWorker(action) {
  const { payload: idnumber } = action;

  const { errno, message, data } = yield get(`${CONTRACTS_API}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putContractByIdAction(normalizeItemById<ContractDetailResponse>(data, "contract")));
  }
}

function* createContractWorker(action: { type: string; payload: { values: any; actionType: string }; promise: any }) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const {
    data: { nextId: idnumber },
  } = yield get(`${CONTRACTS_API}/nextId`);

  const updatedValues = { ...values, idnumber };

  const { errno, message } = yield post(`${CONTRACTS_API}/${actionType}`, updatedValues, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Change Order was createded successfully`);
    yield put(fetchContractByIdAction(idnumber));
    yield put(clearNotifierAction());
  }
}

function* updateContractWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${CONTRACTS_API}/${actionType}`, trimFormStrings({ ...values }), "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Contract was ${actionType}ed successfully`);
    yield put(fetchContractByIdAction(values.idnumber));
    yield put(clearNotifierAction());
  }
}

export function* contractsSaga() {
  yield takeLatest(ContractsActions.FETCH_CONTRACTS, fetchContractsWorker);
  yield takeLatest(ContractsActions.FETCH_CONTRACT_BY_ID, fetchContractByIdWorker);
  yield takeLatest(ContractsActions.ADD_NEW_CONTRACT, createContractWorker);
  yield takeLatest(ContractsActions.EDIT_CONTRACT, updateContractWorker);
  yield takeLatest(ContractsActions.CLOSE_CONTRACT, updateContractWorker);
  yield takeLatest(ContractsActions.DISAPPROVE_CONTRACT, updateContractWorker);
  yield takeLatest(ContractsActions.APPROVE_CONTRACT, updateContractWorker);
  yield takeLatest(ContractsActions.VOID_CONTRACT, updateContractWorker);
}
