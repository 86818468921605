import { useState } from "react";

// ? COMPONENTS
import { Box, Menu, MenuItem, IconButton, Typography, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// ? STYLES
import styles from "./SettingsButton.module.css";

// ? RESOURCES
import editIcon from "resource/images/edit1.svg";
import deleteIcon from "resource/images/delete.svg";
import switchActiveIcon from "resource/images/switch-active.svg";
import switchInactiveIcon from "resource/images/switch-inactive.svg";
import addIcon from "resource/images/plus.svg";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { OptionsSettingButtons } from "resource/interfaces";

// ? TYPES
interface SettingButtonPropsTypes {
  options?: OptionsSettingButtons[];
  loading?: boolean;
}

const ICONS = {
  edit: {
    icon: editIcon,
    alt: "Edit icon",
  },
  activation: {
    icon: editIcon,
    alt: "Activation icon",
  },
  delete: {
    icon: deleteIcon,
    alt: "Delete icon",
  },
  add: {
    icon: addIcon,
    alt: "Add icon",
  },
};

export const SettingsButton = ({ options, loading }: SettingButtonPropsTypes) => {
  const theme = useTheme();
  const [menuElement, toggleMenu] = useState(null);
  const isMenuOpen = Boolean(menuElement);
  const isMenuVisible = options?.find((item) => item.access);

  const handleClick = (event) => {
    toggleMenu(event.currentTarget);
  };

  const handleClose = () => {
    toggleMenu(null);
  };

  return (
    <>
      {isMenuVisible && (
        <div>
          <IconButton onClick={handleClick} disabled={loading}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuElement}
            open={isMenuOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {options &&
              options.map(({ text, type, callback, danger, activeFlag, access }, index) => (
                <div key={`${text}${index}`}>
                  {access && (
                    <MenuItem
                      key={index}
                      className={styles.MenuItem}
                      onClick={() => {
                        callback();
                        handleClose();
                      }}
                      disableRipple
                    >
                      <>
                        {type === "switch" && (
                          <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
                            <Typography>{text}</Typography>
                            <img
                              src={text === activeFlag ? switchActiveIcon : switchInactiveIcon}
                              alt={text}
                              style={{ width: "22px" }}
                            />
                          </Box>
                        )}
                        {type !== "switch" && type !== "fileserver" && (
                          <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
                            <Typography color={!danger ? theme.palette.text.primary : theme.palette.error.main}>
                              {text}
                            </Typography>
                            <img src={ICONS[type].icon} alt={ICONS[type].alt} />
                          </Box>
                        )}
                        {type === "fileserver" && (
                          <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
                            <Typography color={!danger ? theme.palette.text.primary : theme.palette.error.main}>
                              {text}
                            </Typography>
                            <DriveFileMoveIcon />
                          </Box>
                        )}
                      </>
                    </MenuItem>
                  )}
                </div>
              ))}
          </Menu>
        </div>
      )}
    </>
  );
};
