import { takeLatest, put } from "redux-saga/effects";
import { PLAN_VARIABLES_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  VariablesActions,
  putPlanVariableByIdAction,
  putPlanVariablesAction,
  fetchPlanVariableByIdAction,
  deletePlanVariableStoreAction,
} from "../redux-actions/planVariables";

import { setButtonLoaderAction, setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchPlanVariablesWorker(action) {
  const {
    payload: {
      additionalData: { keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${PLAN_VARIABLES_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putPlanVariablesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPlanVariableByIdWorker(action) {
  const { payload } = action;

  const { errno, message, data } = yield get(`${PLAN_VARIABLES_API}/${payload}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putPlanVariableByIdAction(data));
  }
}

function* addNewPlanVariableWorker(action) {
  const {
    payload: { values },
    promise: { resolve, reject },
  } = action;

  const {
    data: { nextId },
  } = yield get(`${PLAN_VARIABLES_API}/getNextId`);

  values.idnumber = nextId;

  const { errno, message } = yield post(`${PLAN_VARIABLES_API}/create`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Add Plan variable succeeded");
    yield put(fetchPlanVariableByIdAction(nextId));
    yield put(clearNotifierAction());
  }
}

function* editPlanVariableWorker(action) {
  const {
    payload: { values },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${PLAN_VARIABLES_API}/update`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Edit Plan variable succeeded");
    yield put(fetchPlanVariableByIdAction(values.idnumber));
    yield put(clearNotifierAction());
  }
}

function* deletePlanVariableWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));
  const { errno, message } = yield post(`${PLAN_VARIABLES_API}/delete`, { idnumber: payload }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Deleted Plan Variable");
    yield put(deletePlanVariableStoreAction({ id: payload }));
    yield put(clearNotifierAction());
  }
}

export function* planVariablesSaga() {
  yield takeLatest(VariablesActions.FETCH_PLAN_VARIABLES, fetchPlanVariablesWorker);
  yield takeLatest(VariablesActions.FETCH_PLAN_VARIABLE_BY_ID, fetchPlanVariableByIdWorker);
  yield takeLatest(VariablesActions.ADD_NEW_PLAN_VARIABLE, addNewPlanVariableWorker);
  yield takeLatest(VariablesActions.UPDATE_PLAN_VARIABLE, editPlanVariableWorker);
  yield takeLatest(VariablesActions.DELETE_PLAN_VARIABLE_FROM_SERVER, deletePlanVariableWorker);
}
