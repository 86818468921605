// ? REACT
import { useRef, useState } from "react";

// ? COMPONENTS
import { Avatar, Box, CardHeader, IconButton, Menu, MenuItem, Typography, useTheme } from "@mui/material";

// ? STYLES
import styles from "./AccountMenu.module.css";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { logoutWorkerAction } from "redux-store/redux-actions/login";

const AccountMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const roleRef = useRef(null);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  const theme = useTheme();

  const dispatch = useDispatch();
  const { fname, lname, modiby, photo } = useSelector((state: RootStateOrAny) => state.login.userData);

  const logout = () => {
    dispatch(logoutWorkerAction());
  };

  return (
    <>
      <CardHeader
        className={styles.AccountMenu}
        sx={{ "& .MuiCardHeader-avatar": { mr: "10px" } }}
        avatar={<Avatar className={styles.AccountMenuAvatar} src={photo} aria-label="avatar" />}
        title={
          <Box className={styles.AccountMenuName}>
            <Typography>{`${fname} ${lname}`}</Typography>
          </Box>
        }
        subheader={
          <Box component="span" ref={roleRef} onClick={toggleMenuOpen}>
            <Box component="span" className={styles.AccountMenuRole}>
              <Typography component="span">{`${modiby}`}</Typography>
            </Box>
            <IconButton aria-label="Open Menu">
              <img src="/images/arrow-small.svg" alt="" role="presentation" />
            </IconButton>
          </Box>
        }
      />
      <Menu
        anchorEl={roleRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        classes={{ root: styles.AccountMenuLogout }}
        PaperProps={{ elevation: 0 }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MenuItem className={styles.AccountMenuItem} onClick={logout}>
          <Typography color={theme.palette.primary.main}>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
