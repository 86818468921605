import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Typography } from "@mui/material";
import { withLayout } from "hoc/withLayout";

// ? RESOURCES
import accessForbiddenImage from "resource/images/403.svg";

const AccessForbidden = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="80vh">
      <img src={accessForbiddenImage} alt="Access Forbidden" style={{ width: "30%", marginBottom: "18px" }} />
      <Typography variant="h1">Sorry, your access is forbidden!</Typography>
      <Link to="/" className="GoHomeButton">
        <Typography textAlign="center" variant="h3">
          Go Home
        </Typography>
      </Link>
    </Box>
  );
};

export default withLayout(AccessForbidden);
