import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum FileServerActions {
  FETCH_FILE_FOLDERS = "FETCH_FILE_FOLDERS",
  PUT_FILE_FOLDERS = "PUT_FILE_FOLDERS",
  FETCH_FILES = "FETCH_FILES",
  PUT_FILES = "PUT_FILE",
  DELETE_FILE = "DELETE_FILE",
  DELETE_FILE_FROM_STORE = "DELETE_FILE_FROM_STORE",
  CLEAR_FILE_SERVER_DATA = "CLEAR_FILE_SERVER_DATA",
  UPLOAD_FILE = "UPLOAD_FILE",
  CATCH_FILE_SERVER_ERROR = "CATCHS_FILE_SERVER_ERROR",
}

export const fetchFileFoldersAction = (payload) => {
  return {
    type: FileServerActions.FETCH_FILE_FOLDERS,
    payload,
  };
};

export const putFileFoldersAction = (payload) => {
  return {
    type: FileServerActions.PUT_FILE_FOLDERS,
    payload,
  };
};

export const fetchFilesAction = (payload) => {
  return {
    type: FileServerActions.FETCH_FILES,
    payload,
  };
};

export const putFilesAction = (payload) => {
  return {
    type: FileServerActions.PUT_FILES,
    payload,
  };
};

export const deleteFileAction = (payload, promise?: PromiseType) => {
  return {
    type: FileServerActions.DELETE_FILE,
    payload,
    promise,
  };
};

export const deleteFileFromStoreAction = (payload) => {
  return {
    type: FileServerActions.DELETE_FILE_FROM_STORE,
    payload,
  };
};

export const uploadFileAction = (payload, promise?: PromiseType) => {
  return {
    type: FileServerActions.UPLOAD_FILE,
    payload,
    promise,
  };
};

export const clearFileServerDataAction = () => {
  return {
    type: FileServerActions.CLEAR_FILE_SERVER_DATA,
  };
};

export const catchErrorAction = (payload: string) => {
  return {
    type: FileServerActions.CATCH_FILE_SERVER_ERROR,
    payload,
  };
};
