import { useMemo, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPartsAction, clearDataAction } from "redux-store/redux-actions/parts";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import PartsList from "./PartsList";
import useModal from "custom-hooks/useModal";
import PartCreate from "components/modals/create/PartCreate";
import PartUpdate from "components/modals/update/PartUpdate";
import PartDelete from "components/modals/delete/PartDelete";
import PartVendorPricingCreate from "components/modals/create/PartVendorPricingCreate";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { nameUpdate } from "components/modals/update/PartUpdate";
import { nameCreate } from "components/modals/create/PartCreate";
import { nameConfirm } from "components/modals/confirm/PartConfirm";
import { nameDelete } from "components/modals/delete/PartDelete";
import { Search } from "components/search/Search";

// ? RESOURCES
import { PARTS, PARTS_NAME } from "resource/constants/parts";
import PartConfirm from "components/modals/confirm/PartConfirm";
import { filterOptions } from "resource/constants/parts";
import { menu } from "resource/constants/menu-list";

// ? INTERFACES
import { PartOnResponse } from "resource/interfaces/part";

import styles from "./Parts.module.css";

const Parts = () => {
  const dispatch = useDispatch();
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const parts = useSelector((state: RootStateOrAny) => state.parts.parts);
  const partsCount = useSelector((state: RootStateOrAny) => state.parts.partsCount);
  const hasMoreToLoad = partsCount > parts.length;
  const { open: openCreate } = useModal(nameCreate);
  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openConfirm } = useModal(nameConfirm);
  const { open: openDelete } = useModal(nameDelete);
  const { hasAddAccess } = useAccess(menu.parts.idmenu);
  const [select, setSelect] = useState(filterOptions[0]);

  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const additionalData = useMemo(
    () => ({
      select,
      keywords: searchKeyword,
    }),
    [select, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchPartsAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  const handleChange = (evt): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handlePartUpdate = (part: PartOnResponse): void => {
    openUpdate(part);
  };

  const handlePartDelete = (part: PartOnResponse): void => {
    openDelete(part);
  };

  return (
    <>
      <PageHeader pageName={PARTS_NAME}>
        <FilterSelect handleChange={handleChange} select={select} options={filterOptions} />
        <Search value={searchValue} placeholder="Search parts" handleChange={handleSearchChange} />
        {hasAddAccess && <AddNewButton text={PARTS.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />}
      </PageHeader>

      {!parts.length && !paginationLoading ? (
        <NoDataScreen pageName={PARTS_NAME} />
      ) : (
        <PartsList
          parts={parts}
          handlePartUpdate={handlePartUpdate}
          handlePartDelete={handlePartDelete}
          openConfirm={openConfirm}
        />
      )}

      <PartCreate className={styles.PartsModal} />
      <PartUpdate className={styles.PartsModal} />
      <PartConfirm />
      <PartDelete />
      <PartVendorPricingCreate />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!parts.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(Parts);
