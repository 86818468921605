import { useState } from "react";

// ? REDUX
import { addNewContractAction } from "redux-store/redux-actions/contracts";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import ContractsCreateForm from "pages/contracts/ContractsCreateForm";

// ? RESOURCES
import { CONTRACTS_NAME, CONTRACTS, INITIAL_FORM_VALUES } from "resource/constants/contracts";
import { ActionName } from "resource/constants/api";
import { CURRENT_TIME_FOR_SERVER } from "resource/consts";

export const nameCreate = `${CONTRACTS_NAME}/${ActionName.CREATE}`;

const ContractsCreate = ({ className }) => {
  const { close, isOpened } = useModal(nameCreate);

  const [isLoading, setLoader] = useState<boolean>(false);
  const createContracts = useAsync(addNewContractAction);

  const handleSubmit = async (values) => {
    const valueForSubmit = {
      ...values.formBasic,
      ...values.formContract,
      ...values.formBroker,
      refdate: CURRENT_TIME_FOR_SERVER,
      idproject: values.idproject,
      idcia: values.idcia,
      status: values.status,
    };
    setLoader(true);

    try {
      await createContracts({
        values: valueForSubmit,
        actionType: "create",
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {isOpened && (
        <ModalManager name={nameCreate} className={className}>
          <ContractsCreateForm
            title={CONTRACTS.NEW_MODAL_TITLE}
            handleSubmit={handleSubmit}
            formData={INITIAL_FORM_VALUES}
            handleClose={close}
            isLoading={isLoading}
          />
        </ModalManager>
      )}
    </>
  );
};

export default ContractsCreate;
