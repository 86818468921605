import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum PlanTakeoffActions {
  ADD_NEW_PLAN_TAKEOFF = "ADD_NEW_PLAN_TAKEOFF",
  EDIT_PLAN_TAKEOFF = "EDIT_PLAN_TAKEOFF",
  DELETE_PLAN_TAKEOFF = "DELETE_PLAN_TAKEOFF",
  PUT_PLAN_TAKEOFF_BY_ID = "PUT_PLAN_TAKEOFF_BY_ID",
  PUT_PLAN_TAKEOFFS = "PUT_PLAN_TAKEOFFS",
  UPDATE_PLAN_TAKEOFF_ASSEMBLY_NOTES = "UPDATE_PLAN_TAKEOFF_ASSEMBLY_NOTES",
  ADD_PLAN_TAKEOFF_ASSEMBLY = "ADD_PLAN_TAKEOFF_ASSEMBLY",
  DELETE_PLAN_TAKEOFF_ASSEMBLY = "DELETE_PLAN_TAKEOFF_ASSEMBLY",
  UPDATE_PLAN_TAKEOFF_BUDGET = "UPDATE_PLAN_TAKEOFF_BUDGET",
  DELETE_PLAN_TAKEOFF_BUDGET = "DELETE_PLAN_TAKEOFF_BUDGET",
  ADD_PLAN_TAKEOFF_BUDGET = "ADD_PLAN_TAKEOFF_BUDGET",
  ADD_PLAN_TAKEOFF_PART = "ADD_PLAN_TAKEOFF_PART",
  DELETE_PLAN_TAKEOFF_PART = "DELETE_PLAN_TAKEOFF_PART",
  UPDATE_PLAN_TAKEOFF_PART = "UPDATE_PLAN_TAKEOFF_PART",
  FETCH_PLAN_TAKEOFFS = "FETCH_PLAN_TAKEOFFS",
  FETCH_PLAN_TAKEOFF_BY_ID = "FETCH_PLAN_TAKEOFF_BY_ID",
  IMPORT_PLAN_TAKEOFF_PLAN = "IMPORT_PLAN_TAKEOFF_PLAN",
  IMPORT_PLAN_TAKEOFF_PROJECT = "IMPORT_PLAN_TAKEOFF_PROJECT",
  CLEAR_PLAN_TAKEOFF_DATA = "CLEAR_PLAN_TAKEOFF_DATA",
  CATCH_PLAN_TAKEOFFS_ERROR = "CATCH_PLAN_TAKEOFFS_ERROR",
}

// ? ACTION CREATORS
export const addNewPlanTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.ADD_NEW_PLAN_TAKEOFF,
    payload,
    promise,
  };
};

export const editPlanTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.EDIT_PLAN_TAKEOFF,
    payload,
    promise,
  };
};

export const deletePlanTakeoffAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.DELETE_PLAN_TAKEOFF,
    payload,
    promise,
  };
};

export const updatePlanTakeoffPartAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const putPlanTakeoffsAction = (payload) => {
  return {
    type: PlanTakeoffActions.PUT_PLAN_TAKEOFFS,
    payload,
  };
};

export const putPlanTakeoffByIdAction = (payload) => {
  return {
    type: PlanTakeoffActions.PUT_PLAN_TAKEOFF_BY_ID,
    payload,
  };
};

export const fetchPlanTakeoffsAction = (payload) => {
  return {
    type: PlanTakeoffActions.FETCH_PLAN_TAKEOFFS,
    payload,
  };
};

export const fetchPlanTakeoffByIdAction = (payload: any) => {
  return {
    type: PlanTakeoffActions.FETCH_PLAN_TAKEOFF_BY_ID,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: PlanTakeoffActions.CLEAR_PLAN_TAKEOFF_DATA,
  };
};

export const addPlanTakeoffAssemblyAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.ADD_PLAN_TAKEOFF_ASSEMBLY,
    payload,
    promise,
  };
};

export const updatePlanTakeoffAssemblyNotesAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_ASSEMBLY_NOTES,
    payload,
    promise,
  };
};

export const updatePlanTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.UPDATE_PLAN_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const addPlanTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.ADD_PLAN_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const deletePlanTakeoffBudgetAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.DELETE_PLAN_TAKEOFF_BUDGET,
    payload,
    promise,
  };
};

export const addPlanTakeoffPartAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.ADD_PLAN_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const deletePlanTakeoffPartAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.DELETE_PLAN_TAKEOFF_PART,
    payload,
    promise,
  };
};

export const deletePlanTakeoffAssemblyAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.DELETE_PLAN_TAKEOFF_ASSEMBLY,
    payload,
    promise,
  };
};

export const importPlanTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.IMPORT_PLAN_TAKEOFF_PLAN,
    payload,
    promise,
  };
};

export const importProjectTakeoffAction = (payload, promise?: PromiseType) => {
  return {
    type: PlanTakeoffActions.IMPORT_PLAN_TAKEOFF_PROJECT,
    payload,
    promise,
  };
};

export const catchPlanTakeoffsErrorAction = (payload: string) => {
  return {
    type: PlanTakeoffActions.CATCH_PLAN_TAKEOFFS_ERROR,
    payload,
  };
};
