import { FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Collapse } from "@mui/material";
import ProjectTakeoffPartItem from "./ProjectTakeoffPartItem";
import ProjectTakeoffAddForm from "./ProjectTakeoffAddPart";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

interface ProjectTakeoffPartsProps {
  projectIds: string[];
  open: boolean;
  takeoffId: string;
  takeoffIdcia: number;
  assemblyId: number;
  isReleased: boolean;
  searchQuery: string;
}

const ProjectTakeoffParts: FC<ProjectTakeoffPartsProps> = ({
  projectIds,
  open,
  takeoffId,
  takeoffIdcia,
  assemblyId,
  isReleased,
  searchQuery,
}) => {
  const parts = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffParts);
  const { hasEditAccess } = useAccess(menu.projectTakeoff.idmenu);

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <>
        {!isReleased && hasEditAccess && (
          <ProjectTakeoffAddForm takeoffId={takeoffId} assemblyId={assemblyId} takeoffIdcia={takeoffIdcia} />
        )}
        {projectIds.map((item: string) => (
          <div key={item}>
            <ProjectTakeoffPartItem
              part={parts[item]}
              takeoffId={takeoffId}
              takeoffIdcia={takeoffIdcia}
              assemblyId={assemblyId}
              isReleased={isReleased}
              searchQuery={searchQuery}
            />
          </div>
        ))}
      </>
    </Collapse>
  );
};

export default ProjectTakeoffParts;
