import { useRef, useState } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, useTheme, FormGroup, InputLabel, Box } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { TabsUnstyled } from "@mui/base";
import { Tab, TabsList, TabPanel } from "resource/styled";
import MasterFloorplanDetailGeneralTab from "./MasterFloorplanDetailGeneralTab";
import MasterFloorplanDetailCompaniesTab from "./MasterFloorplanDetailCompaniesTab";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { trimFormStrings } from "resource/utils";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import attachmentIcon from "resource/images/attachment-icon.svg";

// ? TYPES
const FORM_TYPE = {
  small: "",
  large: styles.CreateFormLarge,
  xlarge: styles.CreateFormExtraLarge,
};

const statuses = [
  { label: "Active", value: "Active" },
  { label: "Under Revision", value: "Under Revision" },
  { label: "Inactive", value: "Inactive" },
];

export const MasterFloorplansForm = ({ title, buttonText, closeModal, callback, validation, size, isLoading }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const attachmentRef1 = useRef<HTMLInputElement>(null);
  const attachmentRef2 = useRef<HTMLInputElement>(null);
  const [elevationImage, setElevationImage] = useState("");
  const [floorplanImage, setFloorplanImage] = useState("");

  const initialFormValues = {
    name: null,
    marketing: null,
    status: statuses[0].value,
    revision: 0,
    sqft: null,
    fieldList: [],
    ciaList: [],
  };

  const [localFieldList, setLocalFieldList] = useState([
    {
      idbaseplan: 0,
      idformulavariable: 0,
      idfield: 0,
      field_type: "",
      field_value: "",
      updateyn: true,
    },
  ]);
  const [localCiaList, setLocalCiaList] = useState([
    {
      idcia: 0,
      xview: true,
    },
  ]);

  const formikValues = initialFormValues;

  const submit = async (values) => {
    const valuesCopy = values;
    if (localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any) => {
          return {
            idbaseplan: field.idbaseplan,
            idformulavariable: 0,
            idfield: 0,
            field_type: field.field_type,
            field_value: field.field_value,
            updateyn: true,
          };
        });
    }
    if (localCiaList.length > 0) {
      valuesCopy.ciaList = localCiaList.map((field: any) => {
        return {
          idcia: field.idnumber,
          xview: field.xview,
        };
      });
    }
    valuesCopy.fileElevation = elevationImage ? removeBase64Prefix(elevationImage) : null;
    valuesCopy.fileFloorplan = floorplanImage ? removeBase64Prefix(floorplanImage) : null;

    const data = trimFormStrings(valuesCopy);

    dispatch(
      await callback({
        formData: { ...data },
        closeModal,
      })
    );
  };

  const uploadImage = async (evt, callback) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);
      callback(String(convertedFile));
    }
  };

  return (
    <div className={`${styles.CreateForm} ${FORM_TYPE[size]}`}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik initialValues={formikValues} onSubmit={(values: any) => submit(values)} validationSchema={validation}>
          {({ handleSubmit }) => (
            <div className={styles.FlorplansForm}>
              <Form>
                <div className={styles.FormHeader}>
                  <Typography variant="h1" color={theme.palette.text.primary}>
                    {title}
                  </Typography>
                  <IconButton onClick={closeModal}>
                    <img src={closeButtonIcon} alt="close-button" />
                  </IconButton>
                </div>

                <Grid className={styles.SubForm} container justifyContent="space-between">
                  <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField size="100%" title="Plan Name" name="name" placeholder="Enter floorplan name" type="text" />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Marketing Name"
                        name="marketing"
                        placeholder="Enter marketing name"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Status"
                        name="status"
                        placeholder="Select status"
                        type="select"
                        options={statuses}
                      />
                    </Grid>
                    {/* <Grid item xs="auto" sm={6} lg={3}>
                      <FormField size="100%" title="Revision" name="revision" placeholder="Enter revision" type="number" />
                    </Grid> */}
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField size="100%" title="SqFt" name="sqft" placeholder="Enter sqft" type="number" />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormGroup>
                        <InputLabel htmlFor="photo">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Elevation Image
                          </Typography>
                        </InputLabel>
                        <input ref={attachmentRef1} hidden type="file" onChange={(evt) => uploadImage(evt, setElevationImage)} />
                        <Box
                          className={styles.PartsFormUpload}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => {
                            attachmentRef1?.current?.click();
                          }}
                        >
                          {!elevationImage ? (
                            <Typography className={styles.PartsFormUploadText}>Upload image</Typography>
                          ) : (
                            <img src={elevationImage} alt="Preview" className={styles.PartsFormImagePreview} />
                          )}
                          <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                        </Box>
                        {/* <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
                      </FormGroup>
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormGroup>
                        <InputLabel htmlFor="photo">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Floorplan Image
                          </Typography>
                        </InputLabel>
                        <input ref={attachmentRef2} hidden type="file" onChange={(evt) => uploadImage(evt, setFloorplanImage)} />
                        <Box
                          className={styles.PartsFormUpload}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => {
                            attachmentRef2?.current?.click();
                          }}
                        >
                          {!floorplanImage ? (
                            <Typography className={styles.PartsFormUploadText}>Upload image</Typography>
                          ) : (
                            <img src={floorplanImage} alt="Preview" className={styles.PartsFormImagePreview} />
                          )}
                          <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                        </Box>
                        {/* <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>

              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab>General</Tab>
                  <Tab>Companies</Tab>
                </TabsList>
                <TabPanel value={0}>
                  <MasterFloorplanDetailGeneralTab idnumber="0" callback={setLocalFieldList} hasAccess={true} />
                </TabPanel>
                <TabPanel value={1}>
                  <MasterFloorplanDetailCompaniesTab idnumber="1" callback={setLocalCiaList} hasAccess={true} />
                </TabPanel>
              </TabsUnstyled>

              <div className={styles.FormFooter}>
                <SubmitButton
                  text={buttonText}
                  large
                  isLoading={isLoading}
                  callback={(e) => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
