import { Colors } from "resource/consts";

export const CONTRACTS_NAME = "contracts";

export const CONTRACTS = {
  TITLE: "Contracts",
  NEW_MODAL_TITLE: "Add New Contract",
  EDIT_MODAL_TITLE: "Update Contract",
  NEW_MODAL_PLUS_BUTTON: "Add New Contract",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Contract",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Contract",
};

export const brokerRepresents = ["Buyer only as Buyer's agent", "Seller as Listing Broker's subagent"];

export const contractsFiltersList = ["-All-", "Active", "Draft", "For Approve", "Closed", "Void"];

export const ContractEndpoints = {
  CREATE: "create",
  UPDATE: "update",
  APPROVE: "approve",
  DISAPPROVE: "disapprove",
  VOID: "void",
  CLOSE: "close",
};

export const INITIAL_FORM_VALUES = {
  idproject: "",
  formBasic: {
    notes: "",
  },
  formContract: {
    closing_notice: "",
    close_date: "",
    sold: 0,
    floorplan: "",
    buyer1: "",
    buyer1_email: "",
    legal_description: "",
    buyer2: "",
    buyer2_email: "",
    buyer_city_state: "",
    buyer_address: "",
    buyer_zipcode: "",
    buyer1_office: "",
    buyer1_fax: "",
    buyer1_mobile: "",
    buyer2_office: "",
    buyer2_fax: "",
    buyer2_mobile: "",
  },
  formBroker: {
    broker_firm: "",
    broker_license: "",
    broker_percentage: "",
    agent_name: "",
    agent_license: "",
    btsa: 0,
    changes: false,
    supervisor: "",
    supervisor_license: "",
    broker_email: "",
    broker_office: "",
    broker_fax: "",
    broker_mobile: "",
    broker_address: "",
    broker_represents: brokerRepresents[0],
    broker_city_state: "",
    broker_zipcode: "",
    hoa_name: "",
    hoa_phone: "",
    hoa_transfer: 0,
  },
};

export const ActionButtons = {
  approve: {
    color: Colors.GREEN,
    text: "Approve",
    action: ContractEndpoints.APPROVE,
  },
  forApprove: {
    color: Colors.ORANGE,
    text: "Submit For Approve",
    action: "For Approve",
  },
  disapprove: {
    color: Colors.RED,
    text: "Disapprove",
    action: ContractEndpoints.DISAPPROVE,
  },
  void: {
    color: Colors.RED,
    text: "Void",
    action: ContractEndpoints.VOID,
  },
  close: {
    color: Colors.RED,
    text: "Close",
    action: ContractEndpoints.CLOSE,
  },
  draft: {
    color: Colors.BLUE,
    text: "Update",
    action: ContractEndpoints.UPDATE,
  },
};
