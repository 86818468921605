import { FC, ReactNode } from "react";
import { Grid } from "@mui/material";

import styles from "./TableList.module.css";

interface TableListProps {
  children: ReactNode;
  isReleased?: boolean;
}

export const TableListHeader: FC<TableListProps> = ({ children }) => {
  return (
    <Grid container className={`${styles.TableList} ${styles.TableListHead}`} spacing={0.2}>
      {children}
    </Grid>
  );
};

export const TableListRow: FC<TableListProps> = ({ children, isReleased }) => {
  const listStyle = isReleased
    ? `${styles.TableList} ${styles.TableListRow} ${styles.TableRowActive}`
    : `${styles.TableList} ${styles.TableListRow}`;
  return (
    <Grid container className={listStyle} spacing={0.2}>
      {children}
    </Grid>
  );
};

export const TableListDivider: FC<TableListProps> = ({ children, isReleased }) => {
  const listStyle = isReleased
    ? `${styles.TableList} ${styles.TableListActive}`
    : `${styles.TableList} ${styles.TableListDivider}`;
  return (
    <Grid container className={listStyle} spacing={0.3}>
      {children}
    </Grid>
  );
};
