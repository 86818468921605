import { takeLatest, put } from "redux-saga/effects";
import { BASE_PLANS_API } from "resource/constants/api";
import { get, post, catchError } from "./_utils-abstract-saga-network";

import {
  PlansActions,
  getPlansAction,
  getPlanAction,
  putPlanAction,
  fetchPlanAction,
  deletePlanStoreAction,
} from "../redux-actions/planDrawer";

import { setButtonLoaderAction, setPaginationLoaderAction, setAddNewLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchPlansWorker(action) {
  const {
    payload: {
      additionalData: { category, keywords },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  yield put(setPaginationLoaderAction(true));

  const { errno, message, data } = yield get(`${BASE_PLANS_API}/${category}/list`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getPlansAction({ data: data.list, category, categoryCount: `${category}Count`, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPlanWorker(action) {
  const {
    payload: { idnumber, mode, category },
  } = action;

  if (mode === "create") {
    yield put(setAddNewLoaderAction(true));
  }

  const { errno, message, data } = yield get(`${BASE_PLANS_API}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
    yield put(setAddNewLoaderAction(false));
  } else {
    yield put(mode === "create" ? getPlanAction({ ...data, category }) : putPlanAction({ ...data, category }));
    yield put(setAddNewLoaderAction(false));
  }
}

function* updatePlanWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const URL = actionType === "create" ? `${BASE_PLANS_API}/${values.category}/${actionType}` : `${BASE_PLANS_API}/${actionType}` 

  if (actionType === "create") {
    const {
      data: { nextId },
    } = yield get(`${BASE_PLANS_API}/${values.category}/getNextId`);
  
    values.idnumber = nextId;
  }

  const { errno, message } = yield post(`${URL}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`${actionType} plan ${values.name} successfull`);
    yield put(fetchPlanAction({ idnumber: values.idnumber, mode: actionType, category: values.category }));
    yield put(clearNotifierAction());
  }
}

function* deletePlanWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${BASE_PLANS_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete plan successfull");
    yield put(deletePlanStoreAction({ idnumber: values.idnumber, category: values.category }));
    yield put(clearNotifierAction());
  }
}

export function* planDrawerSaga() {
  yield takeLatest(PlansActions.FETCH_PLANS, fetchPlansWorker);
  yield takeLatest(PlansActions.ADD_NEW_PLAN, updatePlanWorker);
  yield takeLatest(PlansActions.EDIT_PLAN, updatePlanWorker);
  yield takeLatest(PlansActions.FETCH_PLAN_BY_ID, fetchPlanWorker);
  yield takeLatest(PlansActions.DELETE_PLAN_FROM_SERVER, deletePlanWorker);
}
