import { useState, useRef, useEffect, FC } from "react";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Typography, useTheme, TextField, InputLabel, FormGroup, Checkbox } from "@mui/material";
import ReactTagInput from "@pathofdev/react-tag-input";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { CircularProgress } from "@mui/material";
import { CheckboxIcon } from "components/shared/checkbox-icon/CheckboxIcon";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import attachmentIcon from "resource/images/attachment-icon.svg";
import { partsSchema } from "resource/schemas/partsSchema";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import { PartOnResponse, PartOnRequest } from "resource/interfaces/part";

// ? STYLES
import styles from "./Parts.module.css";
import "@pathofdev/react-tag-input/build/index.css";
import { URL } from "resource/constants/api";

const processDataForSubmit = (data, image: string, prevPhoto): PartOnRequest => {
  const DEFAULT_VALUE = false;
  const tags = Array.isArray(data.tags) && data.tags.length ? data.tags.join(";") : data.tags;

  const dataForSubmit: PartOnRequest = {
    upc: data.upc,
    description: data.description,
    taxable: data.taxable,
    lumber: data.lumber,
    discontinued: data.discontinued ? data.discontinued : DEFAULT_VALUE,
    lengthunit: data.lengthunit,
    formula: data.formula,
    idtrack: data.idtrack,
    tags: tags ? tags : [],
  };

  return {
    ...dataForSubmit,
    ...(image !== `${URL}${prevPhoto}` && image !== "" && { photo: removeBase64Prefix(image) }),
  };
};

interface PartsFormProps {
  title: string;
  buttonText: string;
  handleClose: () => void;
  handleSubmit: (data: PartOnRequest) => void;
  formData: PartOnResponse;
  actionType: string;
  isLoading: boolean;
  vendorPricingDisabled?: boolean;
  formDisabled?: boolean;
}

const PartsForm: FC<PartsFormProps> = ({ title, buttonText, handleSubmit, formData, handleClose, isLoading }) => {
  const theme = useTheme();

  const attachmentRef = useRef<HTMLInputElement>(null);
  const tagsArr: string[] = [];
  const [image, setImage] = useState("");
  const [tags, setTags] = useState(tagsArr);

  const uploadImage = async (evt) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);
      setImage(String(convertedFile));
    }
  };

  //  SHOW IMAGE
  const [showPreview, tooglePopper] = useState(false);
  const showImage = () => {
    tooglePopper((prev) => !prev);
  };

  useEffect(() => {
    if (formData && formData.photo) {
      setImage(`${URL}${formData.photo}`);
    }

    if (formData && formData.tags && formData.tags.length) {
      setTags(formData.tags.split(";"));
    }
  }, [formData.photo, formData.tags]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(data) => {
        const processedData = processDataForSubmit(data, image, formData.photo);
        handleSubmit(processedData);
      }}
      validationSchema={partsSchema}
    >
      {(props) => (
        <Form className={styles.PartsBlock}>
          <div className={styles.PartsFormHeader}>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </div>

          <Grid container spacing={1}>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="photo">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Upload Image of the Part
                  </Typography>
                </InputLabel>
                <input ref={attachmentRef} hidden type="file" onChange={(evt) => uploadImage(evt)} />
                <Box
                  className={styles.PartsFormUpload}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    attachmentRef?.current?.click();
                  }}
                >
                  {!image ? (
                    <Typography className={styles.PartsFormUploadText}>Upload image</Typography>
                  ) : (
                    <div className={styles.PartsFormImagePreviewWrapper}>
                      <img
                        src={image}
                        alt="Content preview"
                        className={styles.PartsFormImagePreview}
                        onMouseEnter={showImage}
                        onMouseLeave={showImage}
                      />
                      {showPreview && (
                        <div className={styles.PartsFormImagePreviewModal}>
                          <img src={image} alt="Content preview" />
                        </div>
                      )}
                    </div>
                  )}
                  <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                </Box>
                <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>

            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="upc">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Part Number
                  </Typography>
                </InputLabel>
                {!formData.upc ? (
                  <CircularProgress />
                ) : (
                  <Field as={TextField} type="text" name="upc" id="upc" placeholder="Enter number" disabled />
                )}
                <ErrorMessage name="upc">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="Part Description">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Part Description
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="description" id="description" placeholder="Enter description" />
                <ErrorMessage name="description">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="tags">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Part Tags
                  </Typography>
                </InputLabel>
                <ReactTagInput
                  tags={tags}
                  editable={true}
                  onChange={(newTags) => {
                    setTags(newTags);
                    props.setFieldValue("tags", newTags);
                  }}
                  placeholder="Press enter to add new tag"
                />
                <ErrorMessage name="tags">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <InputLabel className={styles.PartsFormCheckbox} htmlFor="taxable">
                <Field name="taxable" id="taxable" type="checkbox" as={Checkbox} checkedIcon={<CheckboxIcon />} />
                <Typography variant="body1" color={theme.palette.info.main}>
                  Taxable
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item sm={6}>
              <InputLabel className={styles.PartsFormCheckbox} htmlFor="lumber">
                <Field name="lumber" id="lumber" type="checkbox" as={Checkbox} checkedIcon={<CheckboxIcon />} />
                <Typography variant="body1" color={theme.palette.info.main}>
                  Lumber
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="Unit">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Units
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="lengthunit" id="lengthunit" placeholder="Enter Unit" />
                <ErrorMessage name="group">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="formula">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Formula
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="formula" id="formula" placeholder="Add part formula" />
                <ErrorMessage name="formula">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
          </Grid>
          <Box className={styles.PartsFormFooter}>
            <SubmitButton text={buttonText} isLoading={isLoading} />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PartsForm;
