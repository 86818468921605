// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, Box, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";

// ? STYLES
import styles from "./PlanDrawer.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { planDrawerSchema } from "resource/schemas/planDrawerSchema";

export const PlanDrawerForm = ({
  title,
  buttonText,
  handleClose,
  handleSubmit,
  formData,
  isLoading,
}) => {
  const theme = useTheme();
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  return (
    <div className={styles.CreateForm}>
      {paginationLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik initialValues={formData} onSubmit={(values: any) => handleSubmit(values)} validationSchema={planDrawerSchema}>
          {() => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container justifyContent="space-between">
                <Box className={styles.Group}>
                  <FormField size="100%" title="Field Name" name="name" placeholder="Enter field name" type="text" />
                </Box>
              </Grid>
              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
