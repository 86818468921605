import { useState, useMemo } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { fetchAssembliesAction, clearDataAction } from "redux-store/redux-actions/assemblies";

// ? COMPONENTS
import { Modal, CircularProgress } from "@mui/material";
import { AssembliesList } from "./AssembliesList";
import { withLayout } from "hoc/withLayout";
import { DeleteForm } from "components/delete-form/DeleteForm";
import { Search } from "components/search/Search";
import { nameCreate } from "components/modals/create/AssembliesCreate";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import AssembliesCreate from "components/modals/create/AssembliesCreate";
import AssembliesUpdate from "components/modals/update/AssembliesUpdate";
import AssembliesDelete from "components/modals/delete/AssembliesDelete";
import FilterSelect from "components/shared/filter-select/FilterSelect";

// ? STYLES
import styles from "./Assemblies.module.css";

// ? RESOURCES
import { ASSEMBLIES } from "resource/constants/assemblies";
import { AssemblyRequest } from "resource/interfaces/assemblies";
import { menu } from "resource/constants/menu-list";

const Assemblies = () => {
  const { open: openCreate } = useModal(nameCreate);
  const { hasAddAccess } = useAccess(menu.assemblies.idmenu);
  const dispatch = useDispatch();

  // STORE
  const assemblies: AssemblyRequest[] = useSelector((state: RootStateOrAny) => state.assemblies.assemblies);
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const assembliesCount: number = useSelector((state: RootStateOrAny) => state.assemblies.assembliesCount);
  const hasMoreToLoad: boolean = assembliesCount > assemblies.length;
  const assembliesFilters = useSelector((state: RootStateOrAny) => state.assemblies.filters);

  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);

  const [filter, setFilter] = useState<any>("-All-");

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
      filter,
    }),
    [searchKeyword, filter]
  );

  const { setPage } = useInfiniteLoading(fetchAssembliesAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  // handle filters change
  const handleFilterChange = (evt) => {
    setFilter(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  return (
    <>
      <PageHeader pageName="assemblies">
        <FilterSelect handleChange={handleFilterChange} select={filter} options={assembliesFilters} />
        <Search value={searchValue} placeholder="Search assemblies" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={ASSEMBLIES.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <AssembliesList assemblies={assemblies} />

      <AssembliesCreate className={styles.Modal} />
      <AssembliesUpdate className={styles.Modal} />
      <AssembliesDelete />

      {paginationLoading && (
        <div className={`LoaderScroll ${!assemblies.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(Assemblies);
