import { VenorPricingActions } from "redux-store/redux-actions/vendorPricing";

import { updateCollection, getUniqueData } from "resource/utils";
interface State {
  vendorPricing: any[];
  vendorPricingCount: number;
  vendorPricingDetails: any[];
  vendorPricingDetailsCount: number;
  idgeoarea: null | any;
  options: any;
  vendor: any;
  vendorPricingError: string;
}

const initialState: State = {
  vendorPricing: [],
  vendorPricingCount: 0,
  vendorPricingDetails: [],
  vendorPricingDetailsCount: 0,
  idgeoarea: null,
  vendor: {},
  options: {},
  vendorPricingError: "",
};

export const vendorPricingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VenorPricingActions.CLEAR_VPRICING_DATA:
      return {
        ...state,
        vendorPricing: [],
        vendorPricingCount: 0,
        vendorPricingDetails: [],
        vendorPricingDetailsCount: 0,
      };
    case VenorPricingActions.GET_DATA:
      return {
        ...state,
        vendorPricing: [...state.vendorPricing, ...payload.data],
        vendorPricingCount: payload.count,
      };
    case VenorPricingActions.GET_DATA_DETAILS:
      return {
        ...state,
        // vendorPricingDetails: [...state.vendorPricingDetails, ...getUniqueData(payload.data, state.vendorPricingDetails, "upc")],
        // vendorPricingDetailsCount: payload.count,
        vendorPricingDetails: [...payload.data],
        vendorPricingDetailsCount: payload.count,
      };
    case VenorPricingActions.GET_IDGEOAREA:
      return {
        ...state,
        idgeoarea: payload,
      };
    case VenorPricingActions.GET_VENDOR_INFO_BY_ID:
      return {
        ...state,
        vendor: payload,
      };
    // case VenorPricingActions.PUT_PRODUCT_BY_UPC:
    //   return {
    //     ...state,
    //     vendorPricingDetails: [
    //       ...state.vendorPricingDetails.map((product: any) => {
    //         if (product.upc === payload.upc) {
    //           return payload;
    //         } else {
    //           return product;
    //         }
    //       }),
    //     ],
    //   };
    case VenorPricingActions.DELETE_PRODUCT_FROM_STORE:
      return {
        ...state,
        vendorPricingDetails: [...state.vendorPricingDetails.filter((product: any) => product.upc !== payload.upc)],
      };
    case VenorPricingActions.CATCH_VENDOR_PRICING_ERROR:
      return { ...state, vendorPricingError: payload };
    default:
      return state;
  }
};
