import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useTimer from "custom-hooks/useTimer";

const useSearchUpdate = (setPage: (page: number) => void, clearDataAction, setSearchKeyword) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<any>("");
  const { cleanedTimeout, settedTimeout } = useTimer(() => {
    setSearchKeyword(searchValue);
    setPage(0);
    dispatch(clearDataAction());
  }, 1000);

  const handleSearchChange = (evt): void => {
    cleanedTimeout();
    setSearchValue(evt.target.value);
  };

  useEffect(() => {
    if (searchValue) {
      settedTimeout();
    } else {
      setSearchKeyword("");
      setPage(0);
      dispatch(clearDataAction());
    }
    return () => cleanedTimeout();
  }, [searchValue]);

  return { handleSearchChange, searchValue, setSearchValue };
};

export default useSearchUpdate;
