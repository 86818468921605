import { takeLatest, put } from "redux-saga/effects";
import { COST_CODES_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  CostCodesActions,
  putCostCodeByIdAction,
  putCostCodesAction,
  fetchCostCodeByIdAction,
  putCostCodesGroupsAction,
  deleteCostCodeFromStoreAction,
} from "../redux-actions/costCodes";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchCostCodesWorker(action) {
  const {
    payload: {
      additionalData: { select, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${COST_CODES_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(select && { group: select }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putCostCodesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchCostCodesGroupWorker() {
  const { errno, message, data } = yield get(`${COST_CODES_API}/groups`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putCostCodesGroupsAction(data));
  }
}

function* fetchCostCodeByIdWorker(action) {
  const { payload: number } = action;

  const { errno, message, data } = yield get(`${COST_CODES_API}/${number}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putCostCodeByIdAction(data));
  }
}

function* updateCostCodeWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${COST_CODES_API}/${actionType}`, { ...values, budget: false }, "urlEncode");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Cost Code was ${actionType}ed successfully`);
    yield put(fetchCostCodeByIdAction(values.number));
    yield put(clearNotifierAction());
  }
}

function* deleteCostCodeWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${COST_CODES_API}/delete`, { number: payload }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Cost Code was deleted successfully`);
    yield put(deleteCostCodeFromStoreAction(payload));
    yield put(clearNotifierAction());
  }
}

export function* costCodesSaga() {
  yield takeLatest(CostCodesActions.FETCH_COST_CODES, fetchCostCodesWorker);
  yield takeLatest(CostCodesActions.FETCH_COST_CODE_BY_ID, fetchCostCodeByIdWorker);
  yield takeLatest(CostCodesActions.ADD_NEW_COST_CODE, updateCostCodeWorker);
  yield takeLatest(CostCodesActions.EDIT_COST_CODE, updateCostCodeWorker);
  yield takeLatest(CostCodesActions.FETCH_COST_CODES_GROUPS, fetchCostCodesGroupWorker);
  yield takeLatest(CostCodesActions.DELETE_COST_CODE, deleteCostCodeWorker);
}
