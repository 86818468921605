import { CostCodesActions } from "redux-store/redux-actions/costCodes";
import { CostCodeOnRequest } from "resource/interfaces/cost-codes";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

interface CostCodesStore {
  costCodes: CostCodeOnRequest[];
  costCodesGroup: string[];
  costCodesError: string;
  costCodesCount: number;
}

const initialState: CostCodesStore = {
  costCodes: [],
  costCodesGroup: [],
  costCodesError: "",
  costCodesCount: 0,
};

export const costCodesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CostCodesActions.PUT_COST_CODES:
      return {
        ...state,
        costCodes: [...state.costCodes, ...getUniqueData<CostCodeOnRequest>(payload.data, state.costCodes, "code")],
        costCodesCount: payload.count as number,
      };
    case CostCodesActions.PUT_COST_CODE_BY_ID:
      return {
        ...state,
        costCodes: updateCollection<CostCodeOnRequest>(state.costCodes, payload, "code"),
      };
    case CostCodesActions.PUT_COST_CODES_GROUPS:
      return {
        ...state,
        costCodesGroups: [...payload] as string[],
      };
    case CostCodesActions.DELETE_COST_CODE_FROM_STORE:
      return {
        ...state,
        costCodes: [...state.costCodes.filter((code: CostCodeOnRequest) => code.code !== payload)],
      };
    case CostCodesActions.CLEAR_COST_CODE_DATA:
      return {
        ...state,
        costCodes: [],
        costCodesCount: 0,
      };
    case CostCodesActions.CATCH_COST_CODES_ERROR:
      return { ...state, costCodesError: payload as string };
    default:
      return state;
  }
};
