import { FC, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { disapproveContractAction, approveContractAction, closeContractAction } from "redux-store/redux-actions/contracts";

// ? COMPONENTS
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import ActionButton from "components/shared/action-button/ActionButton";
import { Grid } from "@mui/material";
import { nameConfirmVoid } from "components/modals/confirm/ContractVoidConfirm";

// ? RESOURCES
import { ActionButtons, ContractEndpoints } from "resource/constants/contracts";
import { StatusList } from "resource/consts";
import { PromiseType } from "resource/interfaces";
import { ContractDetailResponse } from "resource/interfaces/contracts";

interface ButtonType {
  color: string;
  text: string;
  action: string;
}

interface ContractsDetailActionsProps {
  contract: ContractDetailResponse;
  setFieldValue: any;
  setActivatedButton: any;
  isDisabled: boolean;
}

const getButtons = (status: string, total: number, isCreatedByCurrentUser: boolean, limit: number): ButtonType[] => {
  switch (status) {
    case StatusList.ACTIVE && isCreatedByCurrentUser:
      return [ActionButtons.void];
    case StatusList.DRAFT:
      return [ActionButtons.void, ActionButtons.forApprove, ActionButtons.draft];
    case StatusList.FOR_APPROVE:
      return total > limit ? [ActionButtons.void] : [ActionButtons.void, ActionButtons.approve, ActionButtons.disapprove];
    case StatusList.DISAPPROVED:
      return [ActionButtons.void];
    case StatusList.APPROVED:
      return [ActionButtons.close];
    default:
      return [];
  }
};

const ContractsDetailActions: FC<ContractsDetailActionsProps> = ({ contract, setActivatedButton }) => {
  const approvalLimit = useSelector((state: RootStateOrAny) => state.login.userData.billauth);
  const isCreatedByCurrentUser = useSelector((state: RootStateOrAny) => contract.creaby === state.login.userData.username);
  const buttonsToRender = getButtons(contract.status, contract.sold, isCreatedByCurrentUser, approvalLimit);
  const [loader, setLoader] = useState(false);
  const disapproveContract = useAsync(disapproveContractAction);
  const approveContract = useAsync(approveContractAction);
  const closeContract = useAsync(closeContractAction);
  const { open: openConfirmVoid } = useModal(nameConfirmVoid);

  const dataActionBoilerplate = {
    idnumber: contract.idnumber,
    idtrack: contract.idtrack,
  };

  const handleActionContract = async (
    action: (payload: any, promise?: PromiseType | undefined) => void,
    actionType: string,
    values
  ) => {
    setLoader(true);
    try {
      action({
        values,
        actionType,
      });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleActionsButton = (actionType: string) => {
    switch (actionType) {
      case ActionButtons.forApprove.action:
        setActivatedButton(StatusList.FOR_APPROVE);
        break;
      case ActionButtons.draft.action:
        setActivatedButton(StatusList.DRAFT);
        break;
      case ActionButtons.void.action:
        openConfirmVoid(contract);
        break;
      case ActionButtons.disapprove.action:
        handleActionContract(disapproveContract, ContractEndpoints.DISAPPROVE, dataActionBoilerplate);
        break;
      case ActionButtons.approve.action:
        handleActionContract(approveContract, ContractEndpoints.APPROVE, dataActionBoilerplate);
        break;
      case ActionButtons.close.action:
        handleActionContract(closeContract, ContractEndpoints.CLOSE, {
          ...dataActionBoilerplate,
          close_date: new Date().toISOString().slice(0, 10),
        });
        break;
      default:
        console.log(`${actionType}`);
    }
  };

  return (
    <>
      <Grid item display={"flex"} flexDirection={"row"}>
        {buttonsToRender?.map((button) => (
          <div key={button.text}>
            <ActionButton
              button={button}
              handleActionsButton={handleActionsButton}
              isLoading={loader}
              buttonType={
                button.text === ActionButtons.draft.text || button.text === ActionButtons.forApprove.text ? "submit" : "button"
              }
            />
          </div>
        ))}
      </Grid>
    </>
  );
};

export default ContractsDetailActions;
