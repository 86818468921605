import { useState } from "react";

// ? REDUX
import { updatePartAction } from "redux-store/redux-actions/parts";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { Box, Button, Typography, IconButton, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PARTS_NAME } from "resource/constants/parts";
import { ActionName } from "resource/constants/api";
import closeButtonIcon from "resource/images/close-btn.svg";

// ? INTERFACES
import { PartOnRequest } from "resource/interfaces/part";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

export const nameConfirm = `${PARTS_NAME}/confirm`;

const PartConfirm = () => {
  const theme = useTheme();

  const { close, data } = useModal(nameConfirm);
  const [isLoading, setLoader] = useState<boolean>(false);

  const updateValues = (part: PartOnRequest) => {
    return {
      description: part.description,
      upc: part.upc,
      tags: part.tags,
      formula: part.formula,
      lengthunit: part.lengthunit,
      taxable: part.taxable,
      lumber: part.lumber,
      discontinued: !part.discontinued,
      idtrack: part.idtrack,
    };
  };

  const updatePart = useAsync(updatePartAction);

  const handlePartDeactivation = async () => {
    setLoader(true);
    try {
      await updatePart({
        values: updateValues(data),
        actionType: ActionName.UPDATE,
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const text = data.discontinued === "true" ? "activate" : "discontinue";

  return (
    <ModalManager name={nameConfirm}>
      <Box className={styles.PartsConfirm}>
        <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
          <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
            Update Part?
          </Typography>
          <IconButton onClick={close}>
            <img src={closeButtonIcon} alt="close-button" />
          </IconButton>
        </Box>
        <Typography
          color={theme.palette.text.secondary}
        >{`Are you sure you want to ${text} "${data.description}" part?`}</Typography>
        <Box display="flex" justifyContent="flex-start" sx={{ marginTop: "10px" }}>
          <Button onClick={close} className={styles.PartsCancelButton}>
            Cancel
          </Button>
          <SubmitButton callback={() => handlePartDeactivation()} isLoading={isLoading} text="Confirm" large type="submit" />
        </Box>
      </Box>
    </ModalManager>
  );
};

export default PartConfirm;
