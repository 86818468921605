import { CircularProgress, Box } from "@mui/material";

import styles from "./SmallLoader.module.css";
const SmallLoader = () => {
  return (
    <Box className={styles.LoaderBox}>
      <CircularProgress className={styles.Loader} />
    </Box>
  );
};

export default SmallLoader;
