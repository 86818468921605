import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import JobCostItem from "./JobCostItem";

interface JobCostListProps {
  jobCosts: any[];
  loading: boolean;
}

// ? STYLES
import styles from "./JobCost.module.css";

const JobCostList: FC<JobCostListProps> = ({ jobCosts, loading }) => {
  const theme = useTheme();

  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Project ID</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Project Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Budget</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Incurred</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Open PO</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Job Cost</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Over under Budget</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Estimated</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Budget SF</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Job Cost SF</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
      </TableListHeader>
      {jobCosts.map((jobCost, i) => (
        <div key={i}>
          <JobCostItem jobCost={jobCost} />
        </div>
      ))}
    </>
  );
};

export default JobCostList;
