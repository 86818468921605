import { Colors } from "resource/consts";
export const CHANGE_ORDERS_NAME = "changeOrders";

export const CHANGE_ORDERS = {
  TITLE: "Change Orders",
  NEW_MODAL_TITLE: "Add New Change Order",
  EDIT_MODAL_TITLE: "Update Change Order",
  NEW_MODAL_PLUS_BUTTON: "Add New Change Order",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Change Order",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Change Order",
};

export const changeOrderFiltersList = ["-All-", "Active", "Draft", "For Approve", "Void"];

export const ChangeOrderEndpoints = {
  CREATE: "create",
  UPDATE: "update",
  APPROVE: "approve",
  DISAPPROVE: "disapprove",
  VOID: "void",
};

export const ActionButtons = {
  update: {
    color: Colors.BLUE,
    text: "Edit",
    action: ChangeOrderEndpoints.UPDATE,
  },
  approve: {
    color: Colors.GREEN,
    text: "Approve",
    action: ChangeOrderEndpoints.APPROVE,
  },
  disapprove: {
    color: Colors.RED,
    text: "Disapprove",
    action: ChangeOrderEndpoints.DISAPPROVE,
  },
  void: {
    color: Colors.RED,
    text: "Void",
    action: ChangeOrderEndpoints.VOID,
  },
};
