import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { nameFileUpload } from "components/modals/file-server-modal/PurchaseOrderFileServer";
import PurchaseOrderActions from "./PurchaseOrderActions";
import FileServerIcon from "components/shared/file-server/FileServerIcon";
import StatusMark from "components/shared/status-mark/StatusMark";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { PurchaseOrderDetailResponse } from "resource/interfaces/purchase-order";
import { resolvePath } from "resource/utils";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import pageHeaderStyles from "components/page-header/PageHeader.module.css";

interface PurchaseOrderDetailHeaderProps {
  order: PurchaseOrderDetailResponse;
}

const PurchaseOrderDetailHeader: FC<PurchaseOrderDetailHeaderProps> = ({ order }) => {
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.purchaseOrder.idmenu);
  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.purchaseOrder.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`PO ${order.idnumber}`}
            </Typography>
            <StatusMark status={order.status} />
            <FileServerIcon data={order} nameFileUpload={nameFileUpload} />
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your purchase order details will be shown below
          </Typography>
        </Grid>
        {hasEditAccess && <PurchaseOrderActions order={order} />}
      </Grid>
    </>
  );
};

export default PurchaseOrderDetailHeader;
