import { ContractsActions } from "redux-store/redux-actions/contracts";
import { updateNormalizedCollection } from "resource/utils";

const initialState = {
  contractItems: {},
  contractIds: [],
  contractsError: "",
  contractsCount: 0,
};

export const contractsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ContractsActions.PUT_CONTRACTS:
      return {
        ...state,
        contractItems: { ...state.contractItems, ...payload.data.entities.contracts },
        contractIds: Array.from(new Set([...state.contractIds, ...payload.data.result])) as number[],
        contractsCount: payload.count as number,
      };
    case ContractsActions.PUT_CONTRACT_BY_ID:
      return {
        ...state,
        contractItems: { ...state.contractItems, [payload.result]: payload.entities.contract[payload.result] },
        contractIds: updateNormalizedCollection(state.contractIds, payload.result),
      };
    case ContractsActions.CLEAR_CONTRACT_DATA:
      return {
        ...state,
        contractIds: [],
        contractsCount: 0,
      };
    case ContractsActions.CATCH_CONTRACTS_ERROR:
      return { ...state, contractsError: payload as string };
    default:
      return state;
  }
};
