import { useEffect, useMemo, useState } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchCostCodesAction, fetchCostCodesGroupsAction, clearDataAction } from "redux-store/redux-actions/costCodes";

// ? COMPONENTS
import { CircularProgress } from "@mui/material";
import { withLayout } from "hoc/withLayout";
import { Search } from "components/search/Search";
import { nameUpdate } from "components/modals/update/CostCodesUpdate";
import { nameCreate } from "components/modals/create/CostCodesCreate";
import { nameDelete } from "components/modals/delete/CostCodesDelete";
import CostCodesList from "./CostCodesList";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import CostCodesCreate from "components/modals/create/CostCodesCreate";
import CostCodesUpdate from "components/modals/update/CostCodesUpdate";
import CostCodesDelete from "components/modals/delete/CostCodesDelete";
import useModal from "custom-hooks/useModal";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";

// ? RESOURCES
import { COST_CODES } from "resource/constants/cost-codes";
import { menu } from "resource/constants/menu-list";

const CostCodes = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.costCodes.idmenu);
  const loading = useSelector((state: RootStateOrAny) => state.loaders.globalLoader);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const costCodes = useSelector((state: RootStateOrAny) => state.costCodes.costCodes);
  const costCodesGroups = useSelector((state: RootStateOrAny) => state.costCodes.costCodesGroups);
  const costCodesCount = useSelector((state: RootStateOrAny) => state.costCodes.costCodesCount);
  const hasMoreToLoad = costCodesCount > costCodes.length;
  const [select, setSelect] = useState("All");
  const [options, setOptions] = useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openCreate } = useModal(nameCreate);
  const { open: openDelete } = useModal(nameDelete);

  const additionalData = useMemo(
    () => ({
      select,
      keywords: searchKeyword,
    }),
    [searchKeyword, select]
  );

  const { setPage } = useInfiniteLoading(fetchCostCodesAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  const handleCostCodeUpdate = (code) => {
    openUpdate(code);
  };

  const handleCostCodeDelete = (code) => {
    openDelete(code);
  };

  const handleChange = (evt): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  useEffect(() => {
    dispatch(fetchCostCodesGroupsAction());
  }, []);

  useEffect(() => {
    if (costCodesGroups && costCodesGroups.length) {
      setOptions(["All", ...costCodesGroups]);
    }
  }, [costCodesGroups]);

  return (
    <>
      <PageHeader pageName="costCodes">
        {options.length > 0 && <FilterSelect handleChange={handleChange} select={select} options={options} />}
        <Search value={searchValue} placeholder="Search cost codes" handleChange={handleSearchChange} />
        {hasAddAccess && <AddNewButton text={COST_CODES.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={loading} />}
      </PageHeader>
      <CostCodesList
        costCodes={costCodes}
        loading={loading}
        handleCostCodeUpdate={handleCostCodeUpdate}
        handleCostCodeDelete={handleCostCodeDelete}
      />

      <CostCodesCreate costCodesGroups={costCodesGroups} />
      <CostCodesUpdate costCodesGroups={costCodesGroups} />
      <CostCodesDelete />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!costCodes.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(CostCodes);
