import { useState, useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchContractByIdAction, editContractAction } from "redux-store/redux-actions/contracts";
import { clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

// ? COMPONENTS
import ContractsFormContract from "./ContractsFormContract";
import ContractsFormBroker from "./ContractsFormBroker";
import ContractsDetailHeader from "./ContractsDetailHeader";
import ContractsFormBasic from "./ContractsFormBasic";
import ContractFileServer from "components/modals/file-server-modal/ContractFileServer";
import ContractVoidConfirm from "components/modals/confirm/ContractVoidConfirm";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";
import { useFormik } from "formik";
import { Box, CircularProgress } from "@mui/material";
import { TabsUnstyled } from "@mui/base";
import { withLayout } from "hoc/withLayout";

// ? RESOURCES
import { brokerRepresents, INITIAL_FORM_VALUES } from "resource/constants/contracts";
import { StatusList, CURRENT_TIME_FOR_SERVER } from "resource/consts";
import { Tab, TabPanel, TabsList } from "resource/styled";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Contracts.module.css";

interface ContractsDetailProps {
  id: string;
}

const getInitFormValues = (contract) => {
  return {
    formBasic: {
      notes: contract.notes,
    },
    formContract: {
      closing_notice: contract.closing,
      sold: contract.sold,
      floorplan: contract.floorplan,
      buyer1: contract.buyer1,
      buyer1_email: contract.buyer1_email,
      legal_description: contract.legal_description,
      buyer2: contract.buyer2,
      buyer2_email: contract.buyer2_email,
      buyer_city_state: contract.buyer_city_state,
      buyer_address: contract.buyer_address,
      buyer_zipcode: contract.buyer_zipcode,
      buyer1_office: contract.buyer1_office,
      buyer1_fax: contract.buyer1_fax,
      buyer1_mobile: contract.buyer1_mobile,
      buyer2_office: contract.buyer2_office,
      buyer2_fax: contract.buyer2_fax,
      buyer2_mobile: contract.buyer2_mobile,
    },
    formBroker: {
      broker_firm: contract.broker_firm,
      broker_license: contract.broker_license,
      broker_percentage: contract.broker_percentage,
      agent_name: contract.agent_name,
      agent_license: contract.agent_license,
      btsa: contract.btsa,
      changes: contract.changes,
      supervisor: contract.supervisor,
      supervisor_license: contract.supervisor_license,
      broker_email: contract.broker_email,
      broker_office: contract.broker_office,
      broker_fax: contract.broker_fax,
      broker_mobile: contract.broker_mobile,
      broker_address: contract.broker_address,
      broker_represents: contract.broker_represents
        ? contract.broker_represents
        : contract.broker_represents
        ? contract.broker_represents
        : brokerRepresents[0],
      broker_city_state: contract.broker_city_state,
      broker_zipcode: contract.broker_zipcode,
      hoa_name: contract.hoa_name,
      hoa_phone: contract.hoa_phone,
      hoa_transfer: contract.hoa_transfer,
    },
  };
};

const ContractsDetail: FC<ContractsDetailProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { hasEditAccess } = useAccess(menu.contracts.idmenu);
  const [loader, setLoader] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [initFormValue, setInitFormValue] = useState<any>(null);
  const [activatedButton, setActivatedButton] = useState("");
  const updateContract = useAsync(editContractAction);
  const contract = useSelector((state: RootStateOrAny) => state.contracts.contractItems[id]);

  const formik = useFormik({
    initialValues: initFormValue ? initFormValue : INITIAL_FORM_VALUES,
    onSubmit: (values) => {
      handleUpdateSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (contract) {
      setInitFormValue(getInitFormValues(contract));
      setLoader(false);
      if ((contract.status !== StatusList.DRAFT && contract.status !== StatusList.FOR_APPROVE) || !hasEditAccess) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [contract]);

  useEffect(() => {
    setLoader(true);
    dispatch(fetchContractByIdAction(Number(id)));

    return () => {
      dispatch(clearFileServerDataAction());
    };
  }, []);

  const handleUpdateSubmit = async (values) => {
    const valueForSubmit = {
      ...values.formBasic,
      ...values.formContract,
      ...values.formBroker,
      idnumber: contract.idnumber,
      refdate: CURRENT_TIME_FOR_SERVER,
      idtrack: contract.idtrack,
      status: activatedButton,
    };

    try {
      await updateContract({
        values: valueForSubmit,
        actionType: "update",
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!loader && initFormValue && (
        <>
          <form onSubmit={formik.handleSubmit} className={styles.MainForm}>
            <ContractsDetailHeader
              contract={contract}
              setFieldValue={formik.setFieldValue}
              setActivatedButton={setActivatedButton}
              isDisabled={isDisabled}
            />
            <fieldset disabled={isDisabled} className={styles.Fieldset}>
              <ContractsFormBasic formik={formik} contract={contract} isDisabled={isDisabled} />
            </fieldset>
            <TabsUnstyled defaultValue={0}>
              <TabsList>
                <Tab type={"button"} sx={{ mr: "5px" }}>
                  Contract
                </Tab>
                <Tab type={"button"}>Broker</Tab>
              </TabsList>
              <Box className={styles.Tab}>
                <TabPanel value={0}>
                  <fieldset disabled={isDisabled} className={styles.Fieldset}>
                    <ContractsFormContract
                      formik={formik}
                      contractId={contract.idnumber}
                      contractIdtrack={contract.idtrack}
                      contractStatus={contract.status}
                    />
                  </fieldset>
                </TabPanel>
                <TabPanel value={1}>
                  <fieldset disabled={isDisabled} className={styles.Fieldset}>
                    <ContractsFormBroker formik={formik} />
                  </fieldset>
                </TabPanel>
              </Box>
            </TabsUnstyled>
          </form>
          <ContractVoidConfirm />
          <ContractFileServer />
        </>
      )}

      {loader && (
        <div className={`LoaderScroll center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(ContractsDetail);
