import { useState } from "react";

// ? REDUX
import { disapprovePurchseOrderAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import PurchaseOrderDisapprovalForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderDisapprovalForm";
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";

interface emailData {
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
  disapprreason: string;
  disapprnotes: string;
}

export const nameCreateDisapproveEmail = `${PURCHASE_ORDER_NAME}/disapproveEmail`;

const PurchaseOrderCreateDisapproveEmail = () => {
  const disapprovePurchaseOrder = useAsync(disapprovePurchseOrderAction);
  const { close, data } = useModal(nameCreateDisapproveEmail);
  const [isLoading, setLoader] = useState<boolean>(false);

  const prepareData = {
    idnumber: Number(data.idnumber),
    idtrack: Number(data.idtrack),
  };

  const handleDisapprovePurchaseOrder = async (emailValues: emailData) => {
    const dataToSubmit = { ...prepareData, ...emailValues };

    setLoader(true);
    try {
      await disapprovePurchaseOrder({ data: dataToSubmit, actionType: PurchaseOrderEndpoints.DISAPPROVE });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreateDisapproveEmail}>
      <PurchaseOrderDisapprovalForm
        handleSubmit={handleDisapprovePurchaseOrder}
        buttonText="Disapprove PO"
        isLoading={isLoading}
        handleClose={close}
      />
    </ModalManager>
  );
};

export default PurchaseOrderCreateDisapproveEmail;
