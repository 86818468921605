import { useState } from "react";
import { Link } from "react-router-dom";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { loginAction } from "redux-store/redux-actions/login";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  Divider,
  Box,
  Alert,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Grid,
  FormGroup,
  TextField,
  useTheme,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? STYLES
import styles from "./SignUp.module.css";

// ? RESOURCES
import logo from "resource/images/logo.svg";
import visibilityOnIcon from "resource/images/visibility-on.svg";
import visibilityOffIcon from "resource/images/visibility-off.svg";
import { loginSchema } from "resource/schemas/loginSchema";

const SignUp = () => {
  const theme = useTheme();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const dispatch = useDispatch();
  const uploadLoading = useSelector((state: RootStateOrAny) => state.loaders.buttonLoader);
  const serverMessage = useSelector((state: RootStateOrAny) => state.notifier.notification);

  const login = (values) => {
    const { appname, email, password } = values;
    const loginData = {
      appname,
      email,
      password,
    };
    dispatch(loginAction(loginData));
  };

  return (
    <>
      <Box className={styles.LinkBox}>
        <Typography variant="h3">
          Already have an account?
          <Link to="/login" className={styles.LinkText}>
            Log in
          </Link>
        </Typography>
      </Box>
      <div className={styles.SignupPage}>
        {serverMessage && <Alert severity={serverMessage.type}>{`${serverMessage.message}`}</Alert>}

        <Formik
          initialValues={{
            appname: "",
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            login(values);
          }}
          validationSchema={loginSchema}
        >
          <Form className={styles.SignupForm}>
            <img src={logo} alt="logo" />
            <Typography variant="h2" className={styles.SignupPageHeading}>
              Create your account
            </Typography>
            <Typography variant="body1" className={styles.SignupPageText}>
              Please enter the needful informations below to sign up on Builder Access
            </Typography>
            <Divider className={styles.SignupPageDivider}></Divider>

            <FormField size="100%" title="Full Name" name="name" placeholder="Enter your name" type="text" />
            <FormField size="100%" title="Email" name="email" placeholder="Enter your email" type="text" />
            <Grid item style={{ width: "100%" }}>
              <FormGroup className={styles.FormField}>
                <InputLabel htmlFor="password">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Password
                  </Typography>
                </InputLabel>
                <div style={{ position: "relative" }}>
                  <Field
                    as={TextField}
                    id="password"
                    name="password"
                    placeholder=""
                    type={values.showPassword ? "text" : "password"}
                  />
                  <InputAdornment position="end" className={styles.SignupFormAdornment}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      className={styles.SignupFormVisibilityIcon}
                    >
                      {values.showPassword ? (
                        <img src={visibilityOnIcon} style={{ width: "15px" }} alt="Visible" />
                      ) : (
                        <img src={visibilityOffIcon} style={{ width: "15px" }} alt="invisible" />
                      )}
                    </IconButton>
                  </InputAdornment>
                </div>

                <ErrorMessage name="password">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>

            <SubmitButton text="Sign Up" isLoading={uploadLoading} />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default SignUp;
