import * as Yup from "yup";

export const planTakeoffNotesSchema = Yup.object().shape({
  notes: Yup.string().max(50),
});

export const planTakeoffAssembliesSchema = Yup.object().shape({
  idnumber: Yup.string().required("Assemblies field cannot be empty").nullable(),
});

export const planTakeoffBudgetSchema = Yup.object().shape({
  idcostcode: Yup.string().required("Cost Code is Required").nullable(),
  notes: Yup.string().max(50),
  budgetrevised: Yup.number(),
});

export const planTakeoffPartSchema = Yup.object().shape({
  notes: Yup.string().required("Required").max(50),
  qty: Yup.number().required("Required").moreThan(0),
});

export const planTakeoffAddPartSchema = Yup.object().shape({
  upc: Yup.string().required("Part is Required"),
  notes: Yup.string().required("Notes are Required").max(50),
  qty: Yup.number().required("Quantity is Required").moreThan(0),
  lumber: Yup.string().required("Lumber is Required"),
});

export const planTakeoffPartQtyUpdateSchema = Yup.object().shape({
  // qty: Yup.number().required("Required").moreThan(0),
});

export const planTakeoffPartNotesUpdateSchema = Yup.object().shape({
  // notes: Yup.string().required("Required").max(50),
});
