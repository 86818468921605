import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileServerIcon from "components/shared/file-server/FileServerIcon";
import { nameFileUpload } from "components/modals/file-server-modal/DevelopmentsFileServer";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Developments.module.css";
import pageHeaderStyles from "components/page-header/PageHeader.module.css";
import { resolvePath } from "resource/utils";

interface DevelopmentsDetailHeaderProps {
  developmentItem: any;
  submit: () => void;
  handleDevelopmentDelete: () => void;
  updateLoader: boolean;
}

const DevelopmentsDetailHeader: FC<DevelopmentsDetailHeaderProps> = ({
  developmentItem,
  submit,
  handleDevelopmentDelete,
  updateLoader,
}) => {
  const theme = useTheme();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.developments.idmenu);

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.developments.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${developmentItem.idnumber} - ${developmentItem.name}`}
            </Typography>
            <FileServerIcon data={developmentItem} nameFileUpload={nameFileUpload} />
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your developments details will be shown below
          </Typography>
        </Grid>
        <Grid item>
          {hasEditAccess && (
            <LoadingButton
              variant="outlined"
              className={`${!updateLoader ? styles.ButtonUpdate : styles.LoadingButton}`}
              loadingPosition="start"
              onClick={() => {
                submit();
              }}
              loading={updateLoader}
            >
              Update
            </LoadingButton>
          )}
          {hasDeleteAccess && (
            <Button variant="outlined" className={styles.ButtonDelete} onClick={handleDevelopmentDelete}>
              Delete
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DevelopmentsDetailHeader;
