// ? COMPONENTS
import { VendorPricingCard } from "components/info-card/VendorPricingCard";
import { Grid } from "@mui/material";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

export const VendorPricingList = ({ data, paginationLoading, idgeoarea }) => {
  if (!paginationLoading && !data.length) {
    return <NoDataScreen pageName="vendorsPricing" />;
  }

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
      {data.map((el, index) => (
        <Grid key={index} item xs="auto" sm={6} lg={4}>
          <VendorPricingCard data={el} idgeoarea={idgeoarea} />
        </Grid>
      ))}
    </Grid>
  );
};
