import { useState } from "react";

// ? REDUX
import { addPlanTakeoffBudgetAction } from "redux-store/redux-actions/planTakeoffs";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import TakeoffsBudgetForm from "pages/takeoffs/TakeoffsBudgetForm";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";

// ? RESOURCES
import { PLAN_TAKEOFFS_NAME, TAKEOFFS, TakeoffEndpoints } from "resource/constants/takeoffs";
import { ActionName } from "resource/constants/api";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
export const nameAddBudget = `${PLAN_TAKEOFFS_NAME}Budget/${ActionName.CREATE}`;

const initFormValues = {
  idcostcode: null,
  budgetrevised: 0,
  notes: "",
};

const PlanTakeoffAddBudget = () => {
  const { close, isOpened, data } = useModal(nameAddBudget);
  const [isLoading, setLoader] = useState(false);
  const [setNotice] = useNotice();
  const addPlanTakeoffBudget = useAsync(addPlanTakeoffBudgetAction);

  const handleSubmit = async (formData) => {
    setLoader(true);
    const values = { ...formData, idnumber: "0", takeoffId: String(data), idplan1: String(data) };
    try {
      await addPlanTakeoffBudget({
        values,
        endpoint: TakeoffEndpoints.UpdateBudget,
        dataForFetch: { id: data },
      });
      setNotice("Budget was successfully added", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameAddBudget}>
      {isOpened ? (
        <div className={styles.LargeForm}>
          <TakeoffsBudgetForm
            title={TAKEOFFS.ADD_BUDGET}
            formData={initFormValues}
            buttonText="Save"
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PlanTakeoffAddBudget;
