import { assembliesReducer } from "./assembliesReducer";
import { changeOrdersReducer } from "./changeOrdersReducer";
import { combineReducers } from "redux";
import { companiesReducer } from "./companiesReducer";
import { connectRouter } from "connected-react-router";
import { contractsReducer } from "./contractsReducer";
import { costCodesReducer } from "./costCodesReducer";
import { createBrowserHistory } from "history";
import { developmentsReducer } from "./developmentsReducer";
import { fileServerReducer } from "./fileServerReducer";
import { jobCostReducer } from "./jobCostReducer";
import { loadersReducer } from "./loadersReducer";
import { loginReducer } from "./loginReducer";
import { masterFloorplanReducer } from "./masterFloorplansReducer";
import { masterRolesReducer } from "./masterRolesReducer";
import { membersProfileReducer } from "./membersProfileReducer";
import { modalsReducer } from "./modalsReducer";
import { partsReducer } from "./partsReducer";
import { paymentSummaryReducer } from "./paymentSummaryReducer";
import { persistReducer } from "redux-persist";
import { planDrawerReducer } from "./planDrawerReducer";
import { planVariablesReducer } from "./planVariablesReducer";
import { projectsReducer } from "./projectsReducer";
import { purchaseOrderReducer } from "./purchaseOrderReducer";
import { vendorPricingReducer } from "./vendorPricingReducer";
import { vendorsReducer } from "./vendorsReducer";
import { planTakeoffsReducer } from "./planTakeoffsReducer";
import { projectTakeoffsReducer } from "./projectTakeoffsReducer";
import { notifierReducer } from "./notifierReducer";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login", "vendorPricing"],
  blacklist: ["options", "vendorPricing", "idgeoarea", "vendorPricingCount", "vendorPricingDetails", "vendorPricingDetailsCount"],
};
const vendorPricingPersistConfig = {
  key: "vendorPricing",
  storage,
  whitelist: ["vendor"],
};

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  assemblies: assembliesReducer,
  changeOrders: changeOrdersReducer,
  companies: companiesReducer,
  contracts: contractsReducer,
  costCodes: costCodesReducer,
  developments: developmentsReducer,
  fileServer: fileServerReducer,
  jobCosts: jobCostReducer,
  loaders: loadersReducer,
  login: loginReducer,
  masterFloorplans: masterFloorplanReducer,
  masterRoles: masterRolesReducer,
  members: membersProfileReducer,
  modals: modalsReducer,
  parts: partsReducer,
  paymentSummary: paymentSummaryReducer,
  planVariables: planVariablesReducer,
  plans: planDrawerReducer,
  projects: projectsReducer,
  purchaseOrder: purchaseOrderReducer,
  router: connectRouter(history),
  vendorPricing: persistReducer(vendorPricingPersistConfig, vendorPricingReducer),
  vendors: vendorsReducer,
  projectTakeoffs: projectTakeoffsReducer,
  planTakeoffs: planTakeoffsReducer,
  notifier: notifierReducer,
});

export default persistReducer(persistConfig, rootReducer);
