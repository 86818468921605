import { FC } from "react";
import { useLocation } from "react-router-dom";

// ? COMPONENTS
import { Link } from "react-router-dom";
import { Box, Typography, Menu, MenuItem } from "@mui/material";

// ? STYLES
import styles from "./MenuDropdown.module.css";

// ? RESOURCES
import { menuList } from "resource/constants/menu-list";
import { activeMenuItem } from "resource/interfaces";

interface MenuDropDownProps {
  active: number;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  userAccess: number[];
}

const MenuDropdown: FC<MenuDropDownProps> = ({ active, anchorEl, open, handleClose, userAccess }) => {
  const location = useLocation();
  const toggleActiveClass = (text: string): string => {
    let locationName = location.pathname;
    if (location.pathname.includes("projects/")) {
      locationName = location.pathname.replace("projects/", "");
    }

    return locationName.includes(text.toLowerCase().replace(/ /g, "-")) ? `${styles.MenuDropdownItemActive}` : "";
  };

  const disableAccess = (idmenu: number): string => {
    return !userAccess.includes(idmenu) ? `${styles.MenuDropdownLinkDisabled}` : "";
  };

  const getActiveMenuList = (active: number): activeMenuItem[] | undefined => {
    const activeMenuList = menuList.find((menuItem) => menuItem.idmenu === active)?.subMenu;
    return activeMenuList;
  };

  return (
    <Menu
      className={styles.MenuDropdown}
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
      classes={{ paper: `${styles.MenuDropdownPaper}` }}
      MenuListProps={{
        "aria-labelledby": "menu-list",
        role: "listbox",
      }}
    >
      <Box className={styles.MenuDropdownLinksList}>
        {getActiveMenuList(active)?.map((item) => (
          <MenuItem
            key={item.idmenu}
            onClick={handleClose}
            className={`${toggleActiveClass(item.text)} ${disableAccess(item.idmenu)}`}
          >
            <Link
              to={{
                pathname: item.pathname,
                state: { dropdownItem: item.idmenu },
              }}
            >
              <Box className={styles.MenuDropdownItem}>
                <img src={item.icon} alt="" role="presentation" className={styles.MenuDropdownIcon} />
                <Typography className={styles.MenuDropdownText}>{item.text}</Typography>
              </Box>
            </Link>
          </MenuItem>
        ))}
      </Box>
    </Menu>
  );
};

export default MenuDropdown;
