import { Grid, useTheme, Box, Typography, Container } from "@mui/material";

import decorImage from "resource/images/moto-decor.svg";
import logoWhite from "resource/images/logo-white.svg";

import styles from "./Landing.module.css";

const LandingFooter = () => {
  const theme = useTheme();
  return (
    <Box className={`${styles.Footer} ${styles.Decor}`} sx={{ backgroundImage: `url(${decorImage})` }}>
      <Container maxWidth="xl" color={theme.palette.secondary.light}>
        <Grid container className={styles.FooterBlock} justifyContent="flex-end">
          <Grid item md={3}>
            <img src={logoWhite} style={{ width: "50%", marginBottom: "40px" }} alt="Builders access logo" />
            <Typography
              color={theme.palette.background.default}
              sx={{ mb: "8px" }}
              variant="body1"
            >{`© 2010 — ${new Date().getFullYear()}`}</Typography>
            <Typography variant="body1" color={theme.palette.background.default}>
              Privacy — Terms
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingFooter;
