import { takeLatest, put } from "redux-saga/effects";
import { PARTS_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  PartsActions,
  putPartsAction,
  putPartByIdAction,
  putPartUpcAction,
  fetchPartByIdAction,
  deletePartFromStoreAction,
} from "redux-store/redux-actions/parts";
import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchPartsWorker(action) {
  const {
    payload: {
      additionalData: { select, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PARTS_API}/list`, {
    ...params,
    ...(select && { status: select }),
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield catchError(message);
    yield put(setPaginationLoaderAction(false));
  } else {
    yield put(putPartsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPartByIdWorker(action) {
  const { payload: upc } = action;

  const { errno, message, data } = yield get(`${PARTS_API}/${upc}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putPartByIdAction(data));
  }
}

function* updatePartWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PARTS_API}/${actionType}`, values, "urlEncode");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Part was ${actionType}ed successfully`);
    yield put(fetchPartByIdAction(values.upc));
    yield put(clearNotifierAction());
  }
}

function* deletePartWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PARTS_API}/delete`, { upc: payload }, "urlEncode");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Part was deleted successfully`);
    yield put(deletePartFromStoreAction(payload));
    yield put(clearNotifierAction());
  }
}

function* fetchPartUpcWorker() {
  const { errno, message, data } = yield get(`${PARTS_API}/nextupc`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    console.log("Get UPC number");
    yield put(putPartUpcAction(data.upc));
  }
}

function* deletePartsPricingWorker(action) {
  const {
    payload: { idtrack, upc, vendorPricing },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(
    `${PARTS_API}/deletevendor`,
    { upc, idtrack, idgeoarea: vendorPricing.idgeoarea, idvendor: vendorPricing.idvendor },
    "urlEncode"
  );

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Part's Pricing was deleted successfully`);
    yield put(fetchPartByIdAction(upc));
    yield put(clearNotifierAction());
  }
}

function* createPartsPricingWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PARTS_API}/${actionType}vendor`, values, "urlEncode");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Part's Pricing was updated successfully`);
    yield put(fetchPartByIdAction(values.upc));
    yield put(clearNotifierAction());
  }
}

export function* partsSaga() {
  yield takeLatest(PartsActions.FETCH_PARTS, fetchPartsWorker);
  yield takeLatest(PartsActions.FETCH_PART_BY_ID, fetchPartByIdWorker);
  yield takeLatest(PartsActions.FETCH_PART_UPC, fetchPartUpcWorker);
  yield takeLatest(PartsActions.UPDATE_PART, updatePartWorker);
  yield takeLatest(PartsActions.CREATE_PART, updatePartWorker);
  yield takeLatest(PartsActions.DELETE_PART, deletePartWorker);
  yield takeLatest(PartsActions.DELETE_PARTS_PRICING, deletePartsPricingWorker);
  yield takeLatest(PartsActions.CREATE_PARTS_VENDOR_PRICING, createPartsPricingWorker);
}
