import { FC } from "react";

// ? COMPONENTS
import { Container, Grid } from "@mui/material";

// ? RESOURCES
import logo from "resource/images/logo.svg";

// ? STYLES
import styles from "./AppBar.module.css";

interface AppBarProps {
  navigation?: React.ReactNode;
  menu?: React.ReactNode;
}

const AppBar: FC<AppBarProps> = ({ navigation, menu }) => {
  return (
    <Container maxWidth="xl" className={styles.AppBar}>
      <Grid className={styles.AppBarGrid} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item className={styles.AppBarLogo} xs="auto">
          <img src={logo} alt="Builders logo" />
        </Grid>
        <Grid item className={styles.AppBarNavigation}>
          {navigation}
        </Grid>
        {menu && (
          <Grid item className={styles.AppBarAccountMenu}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>{menu}</Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AppBar;
