import { useState } from "react";

// ? REDUX
import { uploadProjetFileAction, deleteProjetFileAction } from "redux-store/redux-actions/projects";
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { CircularProgress, IconButton, Grid, Typography, Checkbox, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import { getFormattedDateTime, formattedDateTimeNow, getFileSize } from "resource/utils";
import attachmentIcon from "resource/images/attachment-icon2.svg";
import deleteIcon from "resource/images/delete.svg";
import viewIcon from "resource/images/view.svg";
import UploadIcon from "@mui/icons-material/Upload";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import { menu } from "resource/constants/menu-list";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

export const ProjectsDetailBasePlansTabItem = ({ plan, index, idnumber, idcia, select, openFilePreview }) => {
  const theme = useTheme();

  const [setNotice] = useNotice();
  const { hasEditAccess } = useAccess(menu.projectPage.idmenu);
  const user = useSelector((state: RootStateOrAny) => state.login.userData);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const uploadFileRequest = useAsync(uploadProjetFileAction);

  const uploadFile = async (evt, fid) => {
    setUploadLoading(true);

    try {
      if (evt.target.files.length) {
        const file = evt.target.files[0];
        const convertedFile = await convertBase64(file);
        const preparedData = {
          idcia,
          projectID: idnumber,
          idnumber: fid,
          fname: file.name,
          fileData: removeBase64Prefix(String(convertedFile)),
          path: window.URL.createObjectURL(file),
          modiby: user.username,
          ftype: file.name.slice(file.name.indexOf(".") + 1).toUpperCase(),
          folderName: plan.folderName,
          modidate: formattedDateTimeNow(),
          fsizekb: getFileSize(file.size),
        };
        await uploadFileRequest({ data: preparedData });
        setNotice("File uploaded", noticeTypes.success, 4000);
        setUploadLoading(false);
      } else {
        console.log("File didn't upload");
      }
    } catch (e) {
      console.error(e);
      setUploadLoading(false);
    }
  };

  const deleteFileRequest = useAsync(deleteProjetFileAction);

  const deleteFile = async (data) => {
    setDeleteLoading(true);

    try {
      await deleteFileRequest({
        data,
      });
      setNotice("File deleted", noticeTypes.success, 4000);
      setDeleteLoading(false);
    } catch (e) {
      console.error(e);
      setDeleteLoading(false);
    }
  };

  return (
    <TableListRow>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{plan.idnumber}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{plan.name}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{plan.fsizekb}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{plan.ftype}</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        {plan.url ? (
          <IconButton>
            <img src={attachmentIcon} alt="Attachment icon" title="Have attachment" />
          </IconButton>
        ) : (
          ""
        )}
        {hasEditAccess && (
          <>
            {!plan.url && plan.attachyn && !uploadLoading ? (
              <>
                <label className={styles.UploadIcon} htmlFor={`upload-file${index}`}>
                  <UploadIcon fontSize="small" />
                </label>
                <input
                  hidden
                  type="file"
                  name="photo"
                  id={`upload-file${index}`}
                  onChange={(evt) => uploadFile(evt, plan.idnumber)}
                />
              </>
            ) : (
              ""
            )}
            {uploadLoading && <CircularProgress className={styles.CardLoader} size={20} />}
          </>
        )}
      </Grid>
      <Grid item xs={1} md={1}>
        {plan.url && plan.viewyn ? (
          <IconButton onClick={() => openFilePreview(plan)}>
            <img src={viewIcon} alt="View icon" />
          </IconButton>
        ) : (
          ""
        )}
      </Grid>
      <Grid item xs={1} md={1}>
        {hasEditAccess && (
          <>
            {plan.url && plan.deleteyn && plan.fid && !deleteLoading ? (
              <IconButton onClick={() => deleteFile({ fid: plan.fid, folderName: plan.folderName, idcia, projectID: idnumber })}>
                <img src={deleteIcon} alt="Delete icon" />
              </IconButton>
            ) : (
              ""
            )}
            {deleteLoading && <CircularProgress className={styles.CardLoader} size={20} />}
          </>
        )}
      </Grid>
      <Grid item xs={1}>
        {plan.url && !plan.url.includes("blob") && plan.viewyn ? <Checkbox size="small" onClick={() => select(plan)} /> : ""}
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{plan.modiby}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{getFormattedDateTime(plan.modidate)}</Typography>
      </Grid>
    </TableListRow>
  );
};
