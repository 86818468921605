import { useMemo, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchPlanVariablesAction, clearDataAction } from "redux-store/redux-actions/planVariables";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import PlanVariablesList from "./PlanVariablesList";
import PlanVariableCreate from "components/modals/create/PlanVariableCreate";
import PlanVariableUpdate from "components/modals/update/PlanVariableUpdate";
import PlanVariableDelete from "components/modals/delete/PlanVariableDelete";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import useModal from "custom-hooks/useModal";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import { withLayout } from "hoc/withLayout";
import { Search } from "components/search/Search";
import { CircularProgress } from "@mui/material";
import { nameUpdate } from "components/modals/update/PlanVariableUpdate";
import { nameCreate } from "components/modals/create/PlanVariableCreate";
import { nameDelete } from "components/modals/delete/PlanVariableDelete";

// ? RESOURCES
import { PLAN_VARIABLES } from "resource/constants/plan-variables";
import { menu } from "resource/constants/menu-list";

const PlanVariables = () => {
  const { hasAddAccess } = useAccess(menu.planVariable.idmenu);
  const planVariables = useSelector((state: RootStateOrAny) => state.planVariables.planVariables);
  const planVariablesCount = useSelector((state: RootStateOrAny) => state.planVariables.planVariablesCount);
  const hasMoreToLoad = planVariablesCount > planVariables.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openCreate } = useModal(nameCreate);
  const { open: openDelete } = useModal(nameDelete);
  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
    }),
    [searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchPlanVariablesAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);
  const handlePlanVariableUpdate = (code) => {
    openUpdate(code);
  };

  const handlePlanVariableDelete = (code) => {
    openDelete(code);
  };

  return (
    <>
      <PageHeader pageName="planVariables">
        <Search value={searchValue} placeholder="Search plan variables" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={PLAN_VARIABLES.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      <PlanVariablesList
        planVariables={planVariables}
        handlePlanVariableUpdate={handlePlanVariableUpdate}
        handlePlanVariableDelete={handlePlanVariableDelete}
      />
      <PlanVariableCreate />
      <PlanVariableUpdate />
      <PlanVariableDelete />
      {paginationLoading ? (
        <div className={`LoaderScroll ${!planVariables.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(PlanVariables);
