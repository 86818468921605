import { useState } from "react";

// ? REDUX
import { voidChangeOrderAction } from "redux-store/redux-actions/changeOrders";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { IconButton, Box, Button, Typography, useTheme } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import { SubmitButton } from "components/submit-button/SubmitButton";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { CHANGE_ORDERS_NAME, ChangeOrderEndpoints } from "resource/constants/change-order";

export const nameConfirmVoid = `${CHANGE_ORDERS_NAME}/confirmVoid`;


const ChangeOrderVoidConfirm = () => {
  const theme = useTheme();

  const { close, data } = useModal(nameConfirmVoid);
  const [isLoading, setLoader] = useState<boolean>(false);

  const voidPurchaseOrder = useAsync(voidChangeOrderAction);

  const handleVoidChangeOrder = async () => {
    setLoader(true);
    try {
      await voidPurchaseOrder({
        data: {
          idnumber: data.idnumber,
          idtrack: data.idtrack,
        },
        actionType: ChangeOrderEndpoints.VOID,
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameConfirmVoid}>
      <Box className={styles.PartsConfirm}>
        <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
          <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
            {`Void Contract "${data.idnumber}"?`}
          </Typography>
          <IconButton onClick={close}>
            <img src={closeButtonIcon} alt="close-button" />
          </IconButton>
        </Box>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          If record is voided you will not be able to recover it.
        </Typography>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          Are you sure you want to void?
        </Typography>
        <Box display="flex" justifyContent="flex-start" sx={{ marginTop: "10px" }}>
          <Button onClick={close} className={styles.PartsCancelButton} sx={{ minWidth: "120px" }}>
            No
          </Button>
          <SubmitButton callback={() => handleVoidChangeOrder()} isLoading={isLoading} text="Yes" large type="submit" />
        </Box>
      </Box>
    </ModalManager>
  );
};

export default ChangeOrderVoidConfirm;
