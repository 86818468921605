// ? COMPONENTS
import { Box, Grid, Typography, useTheme, TextField, Checkbox, FormGroup, InputLabel, Select, MenuItem } from "@mui/material";

// ? RESOURCES
import { brokerRepresents } from "resource/constants/contracts";
import checkboxCheckedIcon from "resource/images/checkbox-checked-icon.svg";

const ContractsFormBroker = ({ formik }) => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_firm">
            <Typography variant="body1" color={theme.palette.info.main}>
              Broker Firm
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            name="formBroker.broker_firm"
            id="broker_firm"
            value={formik.values.formBroker.broker_firm}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_license">
            <Typography variant="body1" color={theme.palette.info.main}>
              Broker License
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            name="formBroker.broker_license"
            id="broker_license"
            value={formik.values.formBroker.broker_license}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_percentage">
            <Typography variant="body1" color={theme.palette.info.main}>
              Broker %
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            name="formBroker.broker_percentage"
            id="broker_percentage"
            value={`${formik.values.formBroker.broker_percentage}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter Broker Percentage"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="agent_name">
            <Typography variant="body1" color={theme.palette.info.main}>
              Agent Name
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            id="agent_name"
            name="formBroker.agent_name"
            value={formik.values.formBroker.agent_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter Agent Name"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="agent_license">
            <Typography variant="body1" color={theme.palette.info.main}>
              Agent License
            </Typography>
          </InputLabel>
          <TextField type="text" name="agent_license" id="agent_license" placeholder="Enter Agent License" />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="btsa">
            <Typography variant="body1" color={theme.palette.info.main}>
              BTSA
            </Typography>
          </InputLabel>
          <TextField
            type="number"
            id="btsa"
            name="formBroker.btsa"
            value={formik.values.formBroker.btsa}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter BTSA"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="supervisor">
            <Typography variant="body1" color={theme.palette.info.main}>
              Supervisor
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Supervisor"
            id="supervisor"
            name="formBroker.supervisor"
            value={formik.values.formBroker.supervisor}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="supervisor_license">
            <Typography variant="body1" color={theme.palette.info.main}>
              Supervisor License
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Supervisor"
            id="supervisor_license"
            name="formBroker.supervisor_license"
            value={formik.values.formBroker.supervisor_license}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2">
            <Typography variant="body1" color={theme.palette.info.main}>
              Changes
            </Typography>
          </InputLabel>
          <Box display="flex" alignItems="center" sx={{ width: "28px" }}>
            <Checkbox
              name="formBroker.changes"
              id="changes"
              checked={formik.values.formBroker.changes}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checkedIcon={<img alt="checkbox" src={checkboxCheckedIcon} style={{ width: "24px", height: "24px" }} />}
            />
          </Box>
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_email">
            <Typography variant="body1" color={theme.palette.info.main}>
              Email Address
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Email Address"
            id="broker_email"
            name="formBroker.broker_email"
            value={formik.values.formBroker.broker_email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_city_state">
            <Typography variant="body1" color={theme.palette.info.main}>
              City, State
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter City, State"
            id="broker_city_state"
            name="formBroker.broker_city_state"
            value={formik.values.formBroker.broker_city_state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="hoa_name">
            <Typography variant="body1" color={theme.palette.info.main}>
              HOA Name
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter HOA Name"
            id="hoa_name"
            name="formBroker.hoa_name"
            value={formik.values.formBroker.hoa_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_office">
            <Typography variant="body1" color={theme.palette.info.main}>
              Office
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Office"
            id="broker_office"
            name="formBroker.broker_office"
            value={formik.values.formBroker.broker_office}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_fax">
            <Typography variant="body1" color={theme.palette.info.main}>
              Fax
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Fax"
            name="formBroker.broker_fax"
            value={formik.values.formBroker.broker_fax}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="hoa_phone">
            <Typography variant="body1" color={theme.palette.info.main}>
              HOA Phone
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter HOA Phone"
            id="hoa_phone"
            name="formBroker.hoa_phone"
            value={formik.values.formBroker.hoa_phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_mobile">
            <Typography variant="body1" color={theme.palette.info.main}>
              Mobile
            </Typography>
          </InputLabel>
          <TextField
            type="tel"
            placeholder="Enter Mobile"
            id="broker_mobile"
            name="formBroker.broker_mobile"
            value={formik.values.formBroker.broker_mobile}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_address">
            <Typography variant="body1" color={theme.palette.info.main}>
              Address
            </Typography>
          </InputLabel>
          <TextField
            type="email"
            id="broker_address"
            placeholder="Enter Address"
            name="formBroker.broker_address"
            value={formik.values.formBroker.broker_address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="hoa_transfer">
            <Typography variant="body1" color={theme.palette.info.main}>
              HOA Transfer
            </Typography>
          </InputLabel>
          <TextField
            type="number"
            id="hoa_transfer"
            placeholder="Enter HOA Transfer"
            name="formBroker.hoa_transfer"
            value={formik.values.formBroker.hoa_transfer}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="group">
            <Typography variant="body1" color={theme.palette.info.main}>
              Broker Represents
            </Typography>
          </InputLabel>
          <Select
            id="broker_represents"
            name="formBroker.broker_represents"
            value={formik.values.formBroker.broker_represents}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="select"
          >
            {brokerRepresents.map((value: string) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="broker_zipcode">
            <Typography variant="body1" color={theme.palette.info.main}>
              Zip Code
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            id="broker_zipcode"
            placeholder="Enter Zip Code"
            name="formBroker.broker_zipcode"
            value={formik.values.formBroker.broker_zipcode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default ContractsFormBroker;
