// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import useAccess from "custom-hooks/useAccess";
import useModal from "custom-hooks/useModal";
import { nameUpdate } from "components/modals/update/PlanDrawerUpdate";
import { nameDelete } from "components/modals/delete/PlanDrawerDelete";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

export interface CardPropsTypes {
  fake?: boolean;
  name: string;
  type: string;
  idnumber: string | number;
  idtrack: number;
  category: string;
}

export const PlanDrawerListCard = ({
  idnumber,
  name,
  type,
  idtrack,
  fake,
}: CardPropsTypes) => {
  const theme = useTheme();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.planDrawer.idmenu);

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openDelete } = useModal(nameDelete);

  if (fake) {
    return (
      <div className="Fake">
        <div className="Cover Disabled">
          <CircularProgress size={25} className="Loader" />
        </div>
        <TableListRow>
          <div></div>
        </TableListRow>
      </div>
    );
  }

  return (
    <div>
      <TableListRow>
        <Grid item xs={2} md={2}>
          <Typography color={theme.palette.text.primary}>{idnumber}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography color={theme.palette.text.primary}>{name}</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.text.primary}>{type}</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <SettingsButton
            options={[
              { text: "Edit", type: "edit", callback: () => openUpdate({ name, idtrack, idnumber }), access: hasEditAccess },
              {
                text: "Delete",
                type: "delete",
                callback: () => openDelete({ idnumber, name }),
                danger: true,
                access: hasDeleteAccess,
              },
            ]}
          />
        </Grid>
      </TableListRow>
    </div>
  );
};
