import { useState } from "react";

// ? RESOURCES
import { useNotice } from "./useNotice";
import { noticeTypes } from "resource/consts";
import { URL } from "resource/constants/api";

export const useFileSelector = (id) => {
  const [selectedFiles, setFileSelected] = useState<any>([]);
  const [setNotice] = useNotice();

  const selectFile = (file) => {
    if (!selectedFiles.find((el: any) => file[id] === el[id])) {
      setFileSelected((prev) => [...prev, file]);
    } else if (selectedFiles.find((el: any) => file[id] === el[id])) {
      setFileSelected((prev) => [...prev.filter((el: any) => file[id] !== el[id])]);
    }
  };

  const copySelectedFilesLinks = () => {
    let links = "";
    selectedFiles.forEach((file) => (links += `${URL}${file.url} \n`));
    navigator.clipboard.writeText(links).then(() => setNotice("Links copied", noticeTypes.success, 4000));
  };

  return [selectedFiles, selectFile, copySelectedFilesLinks] as [any, (file: any) => void, () => void];
};
