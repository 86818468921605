// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, Box, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { SearchField } from "components/form-field/SearchField";

// ? STYLES
import styles from "./VendorPricing.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { useSearch } from "custom-hooks/useSearch";
import { PARTS_API } from "resource/constants/api";
import { vendorPricingSchema } from "resource/schemas/vendorPricingSchema";

export const VendorPricingDetailForm = ({ title, buttonText, handleClose, formData, handleSubmit, isLoading, type }) => {
  const theme = useTheme();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const [options, setQuery, isPending, initialCondition, errorMessage] = useSearch(PARTS_API);

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik initialValues={formData} onSubmit={(values: any) => handleSubmit(values)} validationSchema={vendorPricingSchema}>
          {({ setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container justifyContent="space-between">
                <Box className={styles.Group}>
                  {type === "create" ? (
                    <SearchField
                      size="100%"
                      title="Part Name"
                      responseName="upc"
                      name="upc"
                      label="description"
                      placeholder="Enter part name"
                      setFieldValue={setFieldValue}
                      initialFormValues={formData}
                      options={options}
                      onQueryChange={setQuery}
                      isPending={isPending}
                      initialCondition={initialCondition}
                    />
                  ) : (
                    <FormField size="100%" title="Part UPC" name="upc" placeholder="Enter part name" type="text" disabled />
                  )}
                  <FormField
                    size="47%"
                    title="SKU (Max 25 Characters)"
                    name="skunumber"
                    placeholder="Enter SKU number"
                    type="text"
                  />
                  <FormField size="47%" title="Last Price" name="lastprice" placeholder="Enter last price" type="text" />
                </Box>
              </Grid>
              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
