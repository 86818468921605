import { FC, useState } from "react";

// ? REDUX
import { updateMasterRoleAction } from "redux-store/redux-actions/masterRoles";

// ? COMPONENTS
import { Formik, FastField, Form, ErrorMessage } from "formik";
import {
  IconButton,
  Checkbox,
  Grid,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Typography,
  useTheme,
  TextField,
  InputLabel,
  FormGroup,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { CheckboxIcon } from "components/shared/checkbox-icon/CheckboxIcon";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { menuCollection } from "resource/constants/menu-list";
import { roleActionMap } from "resource/constants/master-roles";
import { capitalizeChar } from "resource/utils";
import { masterRolesSchema } from "resource/schemas/masterRolesSchema";
import { processDataForSubmit } from "./master-role-util";

// ? INTERFACES
import { roleOnPost } from "resource/interfaces/master-roles";

// ? STYLES
import styles from "./MasterRoles.module.css";

const actionNames = Array.from(roleActionMap.keys());
const actionValues = Array.from(roleActionMap.values());

interface MasterRolesFormProps {
  title: string;
  buttonText: string;
  handleClose: () => void;
  formData?: any;
  actionType: string;
}

const MasterRolesForm: FC<MasterRolesFormProps> = ({ title, buttonText, handleClose, formData, actionType }) => {
  const theme = useTheme();
  const [isLoading, setLoader] = useState(false);
  const updateMasterRole = useAsync(updateMasterRoleAction);

  const handleSubmit = async (values: roleOnPost) => {
    setLoader(true);

    try {
      await updateMasterRole({ values, actionType });
      handleClose();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <div className={styles.MasterRolesForm}>
      <Formik
        initialValues={formData}
        onSubmit={(data) => {
          const processedData = processDataForSubmit(data, actionValues);
          handleSubmit(processedData);
        }}
        validationSchema={masterRolesSchema}
      >
        <Form>
          <Grid container display="flex" justifyContent="space-between" alignItems="center" mb={2.6}>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={3}>
              <FormGroup>
                <InputLabel htmlFor="name">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Role Name
                  </Typography>
                </InputLabel>
                <FastField as={TextField} type="text" name="name" id="name" placeholder="Enter Role Name" />
                <ErrorMessage name="name">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={3}>
              <FormGroup>
                <InputLabel htmlFor="sort">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Sort
                  </Typography>
                </InputLabel>
                <FastField as={TextField} type="number" name="sort" id="sort" placeholder="Enter Sort Number" />
                <ErrorMessage name="sort">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={3}>
              <FormGroup>
                <InputLabel htmlFor="project">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Access
                  </Typography>
                </InputLabel>
                <FastField
                  id="project"
                  name="project"
                  type="select"
                  as={Select}
                  MenuProps={{
                    className: `${styles.MasterRolesSelect}`,
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Inactive</MenuItem>
                </FastField>
                <ErrorMessage name="project">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={styles.MasterRolesFormPaper}>
            <Table className={styles.MasterRolesFormTable} aria-label="Roles table">
              <TableHead className={styles.MasterRolesFormTableHead}>
                <TableRow sx={{ borderBottom: 0, borderColor: "red" }} className={styles.MasterRolesFormTableHeadRow}>
                  <TableCell className={styles.MasterRolesFormTableHeadCell} align="left">
                    Description
                  </TableCell>
                  {actionNames.map((action, index) => (
                    <TableCell className={styles.MasterRolesFormTableHeadCell} key={`${action}-${index}`} align="center">
                      {capitalizeChar(action)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {menuCollection.map((item) => (
                  <TableRow key={item.idmenu} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {Number.isInteger(item.idmenu) ? (
                      <TableCell style={{ border: "none", padding: "30px 0" }} key={item.idmenu}>
                        <Typography variant="h2">{item.text}</Typography>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell align="left" className={styles.MasterRolesFormDescriptionCell}>
                          {item.text}
                        </TableCell>

                        {actionValues.map((action) => {
                          return (
                            <TableCell key={action} padding="none" align="center" className={styles.MasterRolesFormCheckboxCell}>
                              <FastField
                                style={{ transform: `translate3d(0, 0, 0)` }}
                                name={`actions[${item.idmenu}]`}
                                type="checkbox"
                                value={action}
                                as={Checkbox}
                                checkedIcon={<CheckboxIcon />}
                              />
                            </TableCell>
                          );
                        })}
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <SubmitButton text={buttonText} isLoading={isLoading} />
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};

export default MasterRolesForm;
