import { useState } from "react";

// ? REDUX
import { deleteDevelopmentMemberAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import { CircularProgress, IconButton } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./Developments.module.css";

// ? RESOURCES
import deleteIcon from "resource/images/delete.svg";
import { menu } from "resource/constants/menu-list";

export const DevelopmetsDetailsMemersTabItem = ({ member }) => {
  const theme = useTheme();

  const { hasEditAccess } = useAccess(menu.developments.idmenu);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteMemberRequest = useAsync(deleteDevelopmentMemberAction);

  const deleteMember = async ({ data }) => {
    setDeleteLoading(true);

    try {
      await deleteMemberRequest(data);
      setDeleteLoading(false);
    } catch (e) {
      console.error(e);
      setDeleteLoading(false);
    }
  };

  return (
    <TableListRow>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{member.idnumber}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{member.fullname}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.text.primary}>{member.username}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{member.ac}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.text.primary}>{member.ndepto}</Typography>
      </Grid>
      <Grid item xs={1}>
        {hasEditAccess && (
          <>
            {!deleteLoading ? (
              <IconButton onClick={() => deleteMember({ data: { idnumber: member.idnumber } })}>
                <img src={deleteIcon} alt="Delete icon" />
              </IconButton>
            ) : (
              <CircularProgress className={styles.CardLoader} />
            )}
          </>
        )}
      </Grid>
    </TableListRow>
  );
};
