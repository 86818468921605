export const PLAN_DRAWER_NAME = "baseplans";

export const PLAN_DRAWER = {
  TITLE: "Plan Drawer",
  NEW_MODAL_TITLE: "Add New Base Plan",
  EDIT_MODAL_TITLE: "Edit Base Plan  ",
  NEW_MODAL_PLUS_BUTTON: "Add New Plan",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Base Plan",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Base Plan",
};
