import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum JobCostActions {
  PUT_JOB_COST_SUBLIST = "PUT_JOB_COST_SUBLIST",
  PUT_JOB_COSTS = "PUT_JOB_COSTS",
  FETCH_JOB_COSTS = "FETCH_JOB_COSTS",
  FETCH_JOB_COST_SUBLIST = "FETCH_JOB_COST_SUBLIST",
  CLEAR_JOB_COST_DATA = "CLEAR_JOB_COST_DATA",
  CATCH_JOB_COSTS_ERROR = "CATCH_JOB_COSTS_ERROR",
}

// ? ACTION CREATORS
export const putJobCostsAction = (payload: { data; count: number }) => {
  return {
    type: JobCostActions.PUT_JOB_COSTS,
    payload,
  };
};

export const putJobCostSublistAction = (payload) => {
  return {
    type: JobCostActions.PUT_JOB_COST_SUBLIST,
    payload,
  };
};

export const fetchJobCostsAction = (payload: number) => {
  return {
    type: JobCostActions.FETCH_JOB_COSTS,
    payload,
  };
};

export const fetchJobCostSublistAction = (payload: any) => {
  return {
    type: JobCostActions.FETCH_JOB_COST_SUBLIST,
    payload,
  };
};

export const clearJobCostDataAction = () => {
  return {
    type: JobCostActions.CLEAR_JOB_COST_DATA,
  };
};

export const catchJobCostsErrorAction = (payload: string) => {
  return {
    type: JobCostActions.CATCH_JOB_COSTS_ERROR,
    payload,
  };
};
