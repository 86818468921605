import { useEffect, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

//  ?  COMPONENTS
import Calendar from "components/shared/calendar/Calendar";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  Alert,
  IconButton,
  Box,
  Grid,
  Typography,
  useTheme,
  TextField,
  CircularProgress,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SearchField } from "components/form-field/SearchField";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { useSearch } from "custom-hooks/useSearch";
import { nameCreate } from "components/modals/create/PurchaseOrderCreate";
import useModal from "custom-hooks/useModal";
import useHeaders from "custom-hooks/useHeaders";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { VENDORS_API, COST_CODES_API, PROJECTS_API, PURCHASE_ORDER_API } from "resource/constants/api";
import { CURRENT_TIME_FOR_SERVER } from "resource/consts";
import { Reason } from "resource/interfaces/purchase-order";
import { purchaseOrderSchema } from "resource/schemas/purchaseOrderSchema";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

const PurchaseOrderCreateFormInit = ({ handleSubmit, isLoading, buttonText, handleClose, formData }): JSX.Element => {
  const headers = useHeaders();
  const theme = useTheme();

  const { close } = useModal(nameCreate);
  const [reasons, setReasons] = useState<Reason[]>([]);
  const [fetchLoader, setFetchLoader] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [options, setQuery, isPending, initialCondition, errorMessageCostCodes] = useSearch(COST_CODES_API);
  const [optionsVendors, setQueryVendors, isPendingVendors, initialConditionVendors, errorMessageVendors] =
    useSearch(VENDORS_API);
  const [optionsProjects, setQueryProjects, isPendingProjects, initialConditionProjects, errorMessageProjects] =
    useSearch(PROJECTS_API);
  const errors = [errorMessageCostCodes, errorMessageProjects, errorMessageVendors];

  const getData = async () => {
    try {
      setFetchLoader(true);
      const response = await fetch(`${PURCHASE_ORDER_API}/reasons`, {
        headers,
      });
      const data = await response.json();
      setReasons(data.data.list);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(err);
        errors.push(err.message);
        close();
      }
    } finally {
      setFetchLoader(false);
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      const value = new Date(evt.target.value);
      setDeliveryDate(value);
    }
  };

  const handleDeliverydDate = (update) => {
    setDeliveryDate(update);
  };

  const getDatePickerOptions = () => {
    return {
      selected: deliveryDate,
      placeholderText: "Choose date",
    };
  };

  useEffect(() => {
    if (reasons.length) {
      setFetchLoader(false);
    }
  }, [reasons]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {errors.map((err, i) => (
        <>{err.length > 0 && <Alert severity="error" key={err + i}>{`${err}`}</Alert>}</>
      ))}
      {!fetchLoader && (
        <div className={styles.PoLargeForm}>
          <div className={styles.PoFormWrapper}>
            <Formik
              initialValues={formData}
              onSubmit={(values) => {
                const delivery = deliveryDate.toISOString();
                const currentProject: any =
                  optionsProjects.length > 0 && optionsProjects.find((project: any) => project.idnumber === values.idproject1);

                handleSubmit({
                  idproject1: values.idproject1,
                  refdate: CURRENT_TIME_FOR_SERVER,
                  idcia: currentProject.idcia,
                  idvendor: Number(values.idvendor),
                  invoice: values.invoice,
                  delivery: delivery.slice(0, 10),
                  idreason: values.idreason,
                  idcostcode: values.idcostcode,
                  shipping: Number(values.shipping),
                  insurance: Number(values.insurance),
                  others: Number(values.others),
                  total: Number(values.others) + Number(values.insurance) + Number(values.shipping),
                  shipto: values.shipto,
                  partsList: [],
                });
              }}
              validationSchema={purchaseOrderSchema}
            >
              {({ setFieldValue, values }) => (
                <Form style={{ width: "100%" }}>
                  <Box className="FormHeader" display="flex" justifyContent="space-between">
                    <Typography variant="h1" color={theme.palette.text.primary}>
                      Create Purchase Order
                    </Typography>
                    <IconButton onClick={handleClose}>
                      <img src={closeButtonIcon} alt="close-button" />
                    </IconButton>
                  </Box>
                  <Grid sx={{ padding: 0 }} container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="idvendor">
                        <Typography variant="body1" color={theme.palette.info.main}>
                          Select Vendor
                        </Typography>
                      </InputLabel>
                      <SearchField
                        size="100%"
                        title=""
                        responseName="idnumber"
                        name="idvendor"
                        label="name"
                        placeholder="Search vendors"
                        setFieldValue={setFieldValue}
                        initialFormValues={formData}
                        options={optionsVendors}
                        initialCondition={initialConditionVendors}
                        onQueryChange={setQueryVendors}
                        isPending={isPendingVendors}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="idproject1">
                        <Typography variant="body1" color={theme.palette.info.main}>
                          Select Project
                        </Typography>
                      </InputLabel>
                      <SearchField
                        size="100%"
                        title=""
                        responseName="idnumber"
                        name="idproject1"
                        label="name"
                        placeholder="Search project"
                        setFieldValue={setFieldValue}
                        initialFormValues={formData}
                        options={optionsProjects}
                        initialCondition={initialConditionProjects}
                        onQueryChange={setQueryProjects}
                        isPending={isPendingProjects}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SearchField
                        size="100%"
                        title="Cost Code"
                        responseName="code"
                        name="idcostcode"
                        label="name"
                        placeholder="Search cost code or number"
                        setFieldValue={setFieldValue}
                        initialFormValues={""}
                        options={options}
                        onQueryChange={setQuery}
                        isPending={isPending}
                        initialCondition={initialCondition}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="idreason">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Reasons
                          </Typography>
                        </InputLabel>
                        <Field name="idreason" id="idreason" type="select" as={Select}>
                          {reasons.length &&
                            reasons.map((reason: Reason) => {
                              return (
                                <MenuItem key={reason.idnumber} value={reason.idnumber}>
                                  {reason.name}
                                </MenuItem>
                              );
                            })}
                        </Field>
                        <ErrorMessage name="idreason">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="invoice">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Delivery Date
                          </Typography>
                        </InputLabel>
                        <Box sx={{ pb: "10px" }}>
                          <Calendar
                            options={getDatePickerOptions()}
                            handleDatePickerChange={handleDeliverydDate}
                            handleKeyDown={handleKeyDown}
                            name="deliveryDate"
                            className="wide"
                          />
                        </Box>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="invoice">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Invoice
                          </Typography>
                        </InputLabel>
                        <Field as={TextField} type="text" name="invoice" id="invoice" placeholder="Enter invoice" />
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="others">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Others
                          </Typography>
                        </InputLabel>
                        <Field as={TextField} type="text" name="others" id="others" placeholder="Enter others" />
                        <ErrorMessage name="others">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="shipping">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Shipping
                          </Typography>
                        </InputLabel>
                        <Field as={TextField} type="text" name="shipping" id="shipping" placeholder="Enter shipping" />
                        <ErrorMessage name="shipping">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <FormGroup>
                        <InputLabel htmlFor="insurance">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Insurance
                          </Typography>
                        </InputLabel>
                        <Field as={TextField} type="text" name="insurance" id="insurance" placeholder="Enter insurance" />
                        <ErrorMessage name="insurance">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Grid>
                    <Grid item sm={12}>
                      <FormGroup>
                        <InputLabel htmlFor="shipto">
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Notes
                          </Typography>
                        </InputLabel>
                        <Field
                          className="Textarea"
                          type="textarea"
                          name="shipto"
                          id="shipto"
                          as={TextField}
                          placeholder="Enter message"
                          multiline
                          minRows={5}
                        />
                        <ErrorMessage name="shipto">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Box justifyContent="flex-start" className="FormFooter">
                    <SubmitButton text={buttonText} isLoading={isLoading} />
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {fetchLoader && (
        <Box className="LoaderScroll  center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default PurchaseOrderCreateFormInit;
