import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

interface CostCodesProps {
  costCodes: any[];
  loading: boolean;
  handleCostCodeUpdate: (code: any) => void;
  handleCostCodeDelete: (code: any) => void;
}

const CostCodesList: FC<CostCodesProps> = ({ costCodes, loading, handleCostCodeUpdate, handleCostCodeDelete }) => {
  const theme = useTheme();
  const { hasDeleteAccess, hasEditAccess } = useAccess(menu.costCodes.idmenu);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);
  return (
    <>
      {costCodes.length ? (
        <>
          <TableListHeader>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.primary.main}>Cost Code No</Typography>
            </Grid>
            <Grid item xs={4.5}>
              <Typography color={theme.palette.primary.main}>Cost Code Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color={theme.palette.primary.main}>Cost Dode Description</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.primary.main}>Cost Type</Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent="flex-end" display="flex">
              <Typography color={theme.palette.primary.main}>Actions</Typography>
            </Grid>
          </TableListHeader>
          {addNewLoader && (
            <div className="Fake">
              <div className="Cover Disabled">
                <CircularProgress size={25} className="Loader" />
              </div>
              <TableListRow>
                <div></div>
              </TableListRow>
            </div>
          )}
          {costCodes.map((costCode) => (
            <div key={costCode.code}>
              <TableListRow>
                <Grid item xs={1.5}>
                  <Typography color={theme.palette.text.primary}>{costCode.code}</Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography color={theme.palette.text.primary}>{costCode.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={theme.palette.text.primary} sx={{ width: "80%" }}>
                    {costCode.notes}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography color={theme.palette.text.primary}>{costCode.area}</Typography>
                </Grid>
                <Grid item xs={0.5} justifyContent="flex-end" display="flex">
                  <SettingsButton
                    options={[
                      {
                        text: "Edit",
                        type: "edit",
                        callback: () => handleCostCodeUpdate(costCode),
                        access: hasEditAccess,
                      },
                      {
                        text: "Delete",
                        type: "delete",
                        callback: () => handleCostCodeDelete(costCode),
                        access: hasDeleteAccess,
                      },
                    ]}
                  />
                </Grid>
              </TableListRow>
            </div>
          ))}
        </>
      ) : (
        <>{paginationLoading ? null : <NoDataScreen pageName="purchaseOrder" />}</>
      )}
    </>
  );
};

export default CostCodesList;
