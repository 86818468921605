import { useState, FC } from "react";

// ? REDUX
import { addNewCompanyAction } from "redux-store/redux-actions/companies";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { CompaniesForm } from "pages/companies/CompaniesForm";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import { COMPANIES, COMPANIES_NAME } from "resource/constants/companies";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

export const nameCreate = `${COMPANIES_NAME}/${ActionName.CREATE}`;

const CompaniesCreate: FC = () => {
  const { close, isOpened } = useModal(nameCreate);
  const [isLoading, setLoader] = useState(false);
  const createCopmany = useAsync(addNewCompanyAction);
  const [setNotice] = useNotice();

  const initialFormValues = {
    name: null,
    taddress: null,
    email: null,
    website: null,
    tcity: null,
    tstate: null,
    tel: null,
    fax: null,
    zipcode: null,
    federalid: null,
    taxrate1: null,
    latefee: null,
    photo: null,
  };

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      await createCopmany({
        values: data,
        actionType: ActionName.CREATE,
      });
      setNotice("Company created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.Form}>
          <CompaniesForm
            title={COMPANIES.NEW_MODAL_TITLE}
            buttonText={COMPANIES.NEW_MODAL_SUBMIT_BUTTON}
            formData={initialFormValues}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default CompaniesCreate;
