import { FC, useEffect, useState } from "react";

// ? REDUX
import { updatePlanTakeoffAssemblyNotesAction, deletePlanTakeoffAssemblyAction } from "redux-store/redux-actions/planTakeoffs";

// ? COMPONENTS
import PlanTakeoffParts from "./PlanTakeoffParts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { Grid, Typography, useTheme, CircularProgress, IconButton } from "@mui/material";
import { TableListDivider } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import ToggleField from "components/shared/toggle-field/ToggleField";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import useAccess from "custom-hooks/useAccess";
import HighlightedText from "components/shared/highlighted-text/HighlightedText";

// ? RESOURCES
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { getFormattedDateTime, isBiggerThanZero, formatPrice } from "resource/utils";
import { planTakeoffNotesSchema } from "resource/schemas/planTakeoffSchema";
import { AssemblyInterface } from "resource/interfaces/plan-takeoff";
import { noticeTypes } from "resource/consts";
import { menu } from "resource/constants/menu-list";

interface PlanTakeoffAssemblyItemsProps {
  assembly: AssemblyInterface;
  takeoffId: number;
  takeoffIdtrack: number;
  isAllCollapsed: boolean;
  searchQuery: string;
}

const PlanTakeoffAssemblyItem: FC<PlanTakeoffAssemblyItemsProps> = ({
  assembly,
  takeoffId,
  takeoffIdtrack,
  isAllCollapsed,
  searchQuery,
}) => {
  const theme = useTheme();
  const { hasDeleteAccess, hasEditAccess } = useAccess(menu.planTakeoff.idmenu);
  const updateAssemblyNotes = useAsync(updatePlanTakeoffAssemblyNotesAction);
  const deletePlanTakeoffAssembly = useAsync(deletePlanTakeoffAssemblyAction);

  const [open, setOpen] = useState(!isAllCollapsed);
  const [isLoading, setLoader] = useState(false);
  const [note, setNote] = useState(assembly.notes);
  const [setNotice] = useNotice();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(!isAllCollapsed);
  }, [isAllCollapsed]);

  const handleSubmit = async ({ notes }) => {
    setNote(notes);
    if (notes !== assembly.notes) {
      setLoader(true);
      const values = {
        idassembly1: assembly.idassembly1,
        idplan1: takeoffId,
        notes,
        idtrack: assembly.idtrack,
      };

      try {
        await updateAssemblyNotes({
          values,
          endpoint: TakeoffEndpoints.UpdateAssemblyNotes,
          dataForFetch: { id: takeoffId },
        });
        setNotice("Assembly was successfully updated", noticeTypes.success, 4000);
        setLoader(false);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    }
  };

  const handleAssemblyDelete = async () => {
    setLoader(true);
    const values = {
      idassembly1: assembly.idassembly1,
      idplan1: takeoffId,
    };

    try {
      await deletePlanTakeoffAssembly({
        values,
        endpoint: TakeoffEndpoints.DeleteAssembly,
        dataForFetch: { id: String(takeoffId) },
      });
      setNotice("Assembly was successfully deleted", noticeTypes.success, 4000);
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {assembly ? (
        <>
          <TableListDivider>
            <Grid item xs={0.5}>
              <IconButton aria-label="Open Menu" onClick={handleClick} sx={{ ml: "-10px" }}>
                {open ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
            <Grid item xs={0.75}>
              <Typography color={theme.palette.text.primary}>{assembly.idassembly1}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.text.primary}>
                <HighlightedText searchQuery={searchQuery} text={assembly.name} />
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>{assembly.idcostcode}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={1.5} sx={{ minHeight: "32px" }}>
              <ToggleField
                schema={planTakeoffNotesSchema}
                handleSubmit={handleSubmit}
                fieldName="notes"
                field={note}
                isLoading={isLoading}
                isTogglable={!assembly.status}
                type="text"
                initValues={{ notes: note ? note : "" }}
                hasAccess={hasEditAccess}
              />
            </Grid>
            <Grid item xs={0.75}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography color={theme.palette.text.primary}>{isBiggerThanZero(assembly.taxable)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>{formatPrice(String(isBiggerThanZero(assembly.total)))}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>{assembly.modiby}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                {getFormattedDateTime(assembly.modidate)}
              </Typography>
            </Grid>
            <Grid item xs={0.5} justifyContent="flex-end" display="flex">
              {isLoading ? (
                <SmallLoader />
              ) : (
                <SettingsButton
                  options={[
                    {
                      text: "Delete",
                      type: "delete",
                      callback: () => handleAssemblyDelete(),
                      access: hasDeleteAccess,
                    },
                  ]}
                />
              )}
            </Grid>
          </TableListDivider>
          <PlanTakeoffParts
            partIds={assembly.list}
            open={open}
            takeoffId={takeoffId}
            assemblyId={assembly.idassembly1}
            searchQuery={searchQuery}
          />
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PlanTakeoffAssemblyItem;
