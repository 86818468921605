import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum ContractsActions {
  ADD_NEW_CONTRACT = "ADD_NEW_CONTRACT",
  EDIT_CONTRACT = "EDIT_CONTRACT",
  DELETE_CONTRACT = "DELETE_CONTRACT",
  DELETE_CONTRACT_FROM_STORE = "DELETE_CONTRACT_FROM_STORE",
  PUT_CONTRACT_BY_ID = "PUT_CONTRACT_BY_ID",
  PUT_CONTRACTS = "PUT_CONTRACTS",
  FETCH_CONTRACTS = "FETCH_CONTRACTS",
  FETCH_CONTRACT_BY_ID = "FETCH_CONTRACT_BY_ID",
  FOR_APPROVE_CONTRACT = "FOR_APPROVE_CONTRACT",
  APPROVE_CONTRACT = "APPROVE_CONTRACT",
  DISAPPROVE_CONTRACT = "DISAPPROVE_CONTRACT",
  VOID_CONTRACT = "VOID_CONTRACT",
  CLOSE_CONTRACT = "CLOSE_CONTRACT",
  CLEAR_CONTRACT_DATA = "CLEAR_CONTRACT_DATA",
  CATCH_CONTRACTS_ERROR = "CATCH_CONTRACTS_ERROR",
}

// ? ACTION CREATORS
export const addNewContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.ADD_NEW_CONTRACT,
    payload,
    promise,
  };
};

export const editContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.EDIT_CONTRACT,
    payload,
    promise,
  };
};

export const deleteContractAction = (payload: { code: number }, promise?: PromiseType) => {
  return {
    type: ContractsActions.DELETE_CONTRACT,
    payload,
    promise,
  };
};

export const deleteContractFromStoreAction = (payload: number) => {
  return {
    type: ContractsActions.DELETE_CONTRACT_FROM_STORE,
    payload,
  };
};

export const putContractsAction = (payload: { data; count: number }) => {
  return {
    type: ContractsActions.PUT_CONTRACTS,
    payload,
  };
};

export const putContractByIdAction = (payload) => {
  return {
    type: ContractsActions.PUT_CONTRACT_BY_ID,
    payload,
  };
};

export const fetchContractsAction = (payload: number) => {
  return {
    type: ContractsActions.FETCH_CONTRACTS,
    payload,
  };
};

export const fetchContractByIdAction = (payload: any) => {
  return {
    type: ContractsActions.FETCH_CONTRACT_BY_ID,
    payload,
  };
};

export const approveContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.APPROVE_CONTRACT,
    payload,
    promise,
  };
};

export const forApproveContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.FOR_APPROVE_CONTRACT,
    payload,
    promise,
  };
};

export const disapproveContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.DISAPPROVE_CONTRACT,
    payload,
    promise,
  };
};

export const voidContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.VOID_CONTRACT,
    payload,
    promise,
  };
};

export const closeContractAction = (payload, promise?: PromiseType) => {
  return {
    type: ContractsActions.CLOSE_CONTRACT,
    payload,
    promise,
  };
};

export const clearDataAction = () => {
  return {
    type: ContractsActions.CLEAR_CONTRACT_DATA,
  };
};

export const catchContractsErrorAction = (payload: string) => {
  return {
    type: ContractsActions.CATCH_CONTRACTS_ERROR,
    payload,
  };
};
