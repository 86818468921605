import { useState } from "react";

// ? REDUX
import { requestApprovalPurchaseOrderAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import PurchaseOrderEmailForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderEmailForm";
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";

interface emailData {
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
}

export const nameApprovalRequestEmail = `${PURCHASE_ORDER_NAME}/requestApprovalEmail`;

const PurchaseOrderRequestApprovalEmail = () => {
  const requestApprovalPurchaseOrder = useAsync(requestApprovalPurchaseOrderAction);
  const { close, data } = useModal(nameApprovalRequestEmail);
  const [isLoading, setLoader] = useState<boolean>(false);

  const prepareData = {
    idnumber: Number(data.idnumber),
    refdate: data.refdate,
    idvendor: Number(data.idvendor),
    invoice: data.invoice,
    delivery: data.delivery,
    received: data.received,
    shipto: data.shipto,
    idreason: Number(data.idreason),
    shipping: Number(data.shipping),
    insurance: Number(data.insurance),
    others: Number(data.others),
    total: Number(data.total),
    idtrack: Number(data.idtrack),
  };

  const handleApprovalRequestPurchaseOrder = async (emailValues: emailData) => {
    const dataToSubmit = { ...prepareData, ...emailValues };

    setLoader(true);
    try {
      await requestApprovalPurchaseOrder({ data: dataToSubmit, actionType: PurchaseOrderEndpoints.FOR_APPROVE });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameApprovalRequestEmail}>
      <PurchaseOrderEmailForm
        handleSubmit={handleApprovalRequestPurchaseOrder}
        buttonText="Request Approval"
        isLoading={isLoading}
        handleClose={close}
        title="Create Request"
        subject={`Request Approval >>> ${data.nproject} ${data.iddoc}`}
        total={data.total}
        idvendor={data.idvendor}
      />
    </ModalManager>
  );
};

export default PurchaseOrderRequestApprovalEmail;
