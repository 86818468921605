import { useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchProjectTakeoffByIdAction, clearReleaseDataAction } from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { TabsUnstyled } from "@mui/base";
import { CircularProgress } from "@mui/material";
import ProjectTakeoffDetailHeader from "./ProjectTakeoffDetailHeader";
import ProjectTakeoffDetailTab from "./ProjectTakeoffDetailTab";
import TakeoffsDetailInfo from "../TakeoffsDetailInfo";
import TakeoffsBudgetTab from "pages/takeoffs/TakeoffsBudgetTab";
import ProjectTakeoffAddAssembly from "components/modals/create/ProjectTakeoffAddAssembly";
import ProjectTakeoffAddBudget from "components/modals/create/ProjectTakeoffAddBudget";
import ProjectTakeoffPlanImport from "components/modals/import/ProjectTakeoffPlanImport";
import ProjectTakeoffProjectImport from "components/modals/import/ProjectTakeoffProjectImport";
import TakeoffImportConfirm from "components/modals/confirm/TakeoffImportConfirm";
import ProjectTakeoffDelete from "components/modals/delete/ProjectTakeoffDelete";
import ProjectTakeoffAddVendor from "components/modals/create/ProjectTakeoffAddVendor";

// ? RESOURCE
import { TAKEOFFS } from "resource/constants/takeoffs";
import { Tab, TabPanel, TabsList } from "resource/styled";

interface ProjectTakeoffDetailProps {
  idcia: number;
  id: string;
}

const ProjectTakeoffDetail: FC<ProjectTakeoffDetailProps> = ({ idcia, id }) => {
  const dispatch = useDispatch();
  const projectTakeoff = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffItems[`${idcia}${id}`]);

  useEffect(() => {
    dispatch(fetchProjectTakeoffByIdAction({ id, idcia }));

    return () => {
      dispatch(clearReleaseDataAction());
    };
  }, [id, idcia, dispatch]);

  return (
    <>
      {projectTakeoff ? (
        <>
          <ProjectTakeoffDetailHeader id={id} idcia={idcia} name={projectTakeoff.name} status={projectTakeoff.status} />
          <TakeoffsDetailInfo takeoff={projectTakeoff} />
          <TabsUnstyled defaultValue={0}>
            <TabsList>
              <Tab>Takeoff</Tab>
              <Tab>Budget</Tab>
            </TabsList>
            <TabPanel value={0}>
              <ProjectTakeoffDetailTab takeoff={projectTakeoff} />
            </TabPanel>
            <TabPanel value={1}>
              <TakeoffsBudgetTab
                takeoffId={id}
                takeoffBudget={projectTakeoff.budget}
                takeoffType={TAKEOFFS.PROJECT_TITLE}
                takeoffIdcia={idcia}
              />
            </TabPanel>
          </TabsUnstyled>
          <ProjectTakeoffAddAssembly />
          <ProjectTakeoffAddBudget />
          <ProjectTakeoffPlanImport />
          <ProjectTakeoffProjectImport />
          <ProjectTakeoffDelete />
          <ProjectTakeoffAddVendor />
          <TakeoffImportConfirm />
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(ProjectTakeoffDetail);
