import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { fetchFloorlanCompaniesAction, clearDataDetailsAction } from "redux-store/redux-actions/masterFloorplans";

// ? COMPONENTS
import { Box, CircularProgress, Checkbox } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { TableListRow } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

const MasterFloorplanDetailCompaniesTab = ({ idnumber, callback, hasAccess }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const companies = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanCompanies);
  const companiesCount = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanCompaniesCount);
  const hasMoreToLoad = companiesCount > companies.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
    }),
    [idnumber]
  );

  const localData = [...companies];

  useInfiniteLoading(fetchFloorlanCompaniesAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDataDetailsAction("Companies"));
    };
  }, []);

  return (
    <Box className={styles.MasterFloorplanDetailsDataList}>
      <TableListHeader>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Company No.</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography color={theme.palette.primary.main}>Company Name</Typography>
        </Grid>
        <Grid item xs={2} md={2}>
          <Typography color={theme.palette.primary.main}>Available</Typography>
        </Grid>
      </TableListHeader>
      <div className={styles.FormList}>
        {companies.length > 0 &&
          companies.map((el, index: number) => (
            <div key={index}>
              <TableListRow>
                <Grid item xs={1} md={1}>
                  <Typography color={theme.palette.text.primary}>{el.idnumber}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography color={theme.palette.text.primary}>{el.name}</Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Checkbox
                    disabled={!hasAccess}
                    checked={el.xview}
                    onChange={() => {
                      localData[index].xview = !localData[index].xview;
                      if (callback) {
                        callback(localData);
                      }
                    }}
                  />
                </Grid>
              </TableListRow>
            </div>
          ))}
      </div>
      {paginationLoading && (
        <div className={`LoaderScroll ${!companies.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default MasterFloorplanDetailCompaniesTab;
