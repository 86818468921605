import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  fetchFloorplanDetailAction,
  editFloorplanAction,
  getFloorplanDetailAction,
} from "redux-store/redux-actions/masterFloorplans";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { Formik, Form } from "formik";
import { Grid, CircularProgress, FormGroup, InputLabel, Typography, Box, useTheme } from "@mui/material";
import { TabsUnstyled } from "@mui/base";
import { Tab, TabsList, TabPanel } from "resource/styled";
import { FormField } from "components/form-field/FormField";
import MasterFloorplanDelete from "components/modals/delete/MasterFloorplanDelete";
import useModal from "custom-hooks/useModal";
import { nameDelete } from "components/modals/delete/MasterFloorplanDelete";
import useAsync from "custom-hooks/useAsync";
import FloorplansFileServer from "components/modals/file-server-modal/FloorplansFileServer";
import MasterFloorplansDetailHeader from "./MasterFloorplansDetailsHeader";
import FilePreview from "components/modals/file-preview/FilePreview";
import { nameFilePreview } from "components/modals/file-preview/FilePreview";
import MasterFloorplanDetailGeneralTab from "./MasterFloorplanDetailGeneralTab";
import MasterFloorplanDetailVariablesTab from "./MasterFloorplanDetailVariablesTab";
import MasterFloorplanDetailFilesTab from "./MasterFloorplanDetailFilesTab";
import MasterFloorplanDetailCompaniesTab from "./MasterFloorplanDetailCompaniesTab";
import useAccess from "custom-hooks/useAccess";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

// ? RESOURCES
import { masterFloorplanSchema } from "resource/schemas/masterFloorplanSchema";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import attachmentIcon from "resource/images/attachment-icon.svg";
import { ActionName } from "resource/constants/api";
import { menu } from "resource/constants/menu-list";
import { URL } from "resource/constants/api";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

const statuses = [
  { label: "Active", value: "Active" },
  { label: "Under Revision", value: "Under Revision" },
  { label: "Inactive", value: "Inactive" },
];

const MasterFloorplansDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [setNotice] = useNotice();

  const { hasEditAccess } = useAccess(menu.masterFloorplans.idmenu);

  const floorplanDetail = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanDetail);
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const initialFormValues = {
    name: floorplanDetail.name,
    marketing: floorplanDetail.marketing,
    status: floorplanDetail.status,
    revision: floorplanDetail.revision,
    sqft: floorplanDetail.sqft,
    idtrack: floorplanDetail.idtrack,
    fileElevation: null,
    fileFloorplan: null,
    fieldList: [],
    formulavariableList: [],
    ciaList: [],
  };

  const idnumber = location.pathname.split("/").pop();

  const [localFieldList, setLocalFieldList] = useState([]);
  const [localVariablesList, setLocalVariablesList] = useState([]);
  const [localCiaList, setLocalCiaList] = useState([]);

  const attachmentRef1 = useRef<HTMLInputElement>(null);
  const attachmentRef2 = useRef<HTMLInputElement>(null);
  const [elevationImage, setElevationImage] = useState("");
  const [floorplanImage, setFloorplanImage] = useState("");
  const [revision, setRevision] = useState([{ label: "Revision 0", value: 0 }]);

  const formikValues = initialFormValues;

  const { open: openDelete } = useModal(nameDelete);
  const handleFloorplanDelete = (): void => {
    openDelete({ ...floorplanDetail, type: "Floorplan" });
  };

  const { open: openFilePreview } = useModal(nameFilePreview);

  const [updateLoader, setUpdateLoader] = useState(false);
  const updateFloorplan = useAsync(editFloorplanAction);

  const [isUpdate, setUpdateCounter] = useState(0);

  const handleSubmit = async (data) => {
    setUpdateLoader(true);

    try {
      await updateFloorplan({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setUpdateLoader(false);
      setUpdateCounter((prev) => prev + 1);
      setNotice("Floorplan updated", noticeTypes.success, 4000);
    } catch (e) {
      console.error(e);
      setUpdateLoader(false);
    }
  };

  const preparedData = (values) => {
    const valuesCopy = values;
    valuesCopy.idnumber = idnumber;
    if (localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any) => {
          return {
            idbaseplan: field.idbaseplan,
            idformulavariable: 0,
            idfield: field.idfield,
            field_type: field.field_type,
            field_value: field.field_value,
            updateyn: true,
          };
        });
    }
    if (localVariablesList.length > 0) {
      valuesCopy.formulavariableList = localVariablesList
        .filter((field: any) => field.changed)
        .map((field: any) => {
          return {
            idformulavariable: field.idformulavariable,
            idfield: field.idfield,
            field_type: field.field_type,
            field_value: field.field_value,
            updateyn: true,
          };
        });
    }
    if (localCiaList.length > 0) {
      valuesCopy.ciaList = localCiaList.map((field: any) => {
        return {
          idcia: field.idnumber,
          xview: field.xview,
        };
      });
    }
    valuesCopy.fileElevation =
      elevationImage !== `${URL}${floorplanDetail.fileElevationUrl}` &&
      elevationImage !== "" &&
      removeBase64Prefix(elevationImage);
    valuesCopy.fileFloorplan =
      floorplanImage !== `${URL}${floorplanDetail.fileFloorplanUrl}` &&
      floorplanImage !== "" &&
      removeBase64Prefix(floorplanImage);

    return valuesCopy;
  };

  const uploadImage = async (evt, callback) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);
      callback(String(convertedFile));
    }
  };

  const getRivison = () => {
    let revision = [
      { label: `Revision ${floorplanDetail.revision}`, value: floorplanDetail.revision },
      { label: `Revision ${floorplanDetail.revision + 1}`, value: floorplanDetail.revision + 1 },
    ];

    console.log("It's working");

    setRevision(revision);
  };

  const openFile = (path) => {
    window.open(`${URL}${path}`, "_blank");
  };

  const [detailsLoader, setDetailsLoader] = useState(false);
  const fetchDetailsRequest = useAsync(fetchFloorplanDetailAction);
  const fetchDetails = async () => {
    setDetailsLoader(true);

    try {
      await fetchDetailsRequest({ idnumber });
      setDetailsLoader(false);
    } catch (e) {
      console.error(e);
      setDetailsLoader(false);
    }
  };

  useEffect(() => {
    fetchDetails();

    return () => {
      dispatch(getFloorplanDetailAction({}));
    };
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [isUpdate]);

  useEffect(() => {
    getRivison();
  }, [floorplanDetail]);

  useEffect(() => {
    floorplanDetail.fileElevationUrl ? setElevationImage(`${URL}${floorplanDetail.fileElevationUrl}`) : setElevationImage("");
    floorplanDetail.fileFloorplanUrl ? setFloorplanImage(`${URL}${floorplanDetail.fileFloorplanUrl}`) : setFloorplanImage("");
  }, [floorplanDetail.fileElevationUrl, floorplanDetail.fileFloorplanUrl]);

  return (
    <>
      {floorplanDetail && !detailsLoader && (
        <>
          <Formik
            initialValues={formikValues}
            onSubmit={(values: any) => handleSubmit(preparedData(values))}
            validationSchema={masterFloorplanSchema}
          >
            {({ values, handleSubmit }) => (
              <>
                <MasterFloorplansDetailHeader
                  floorplanDetail={floorplanDetail}
                  submit={handleSubmit}
                  handleFloorplanDelete={handleFloorplanDelete}
                  updateLoader={updateLoader}
                />
                <Form className={`${styles.LargeForm} ${styles.FlorplansForm}`}>
                  {modalLoading && (
                    <div className={styles.Loader}>
                      <CircularProgress />
                    </div>
                  )}
                  <fieldset className={styles.Fieldset} disabled={!hasEditAccess}>
                    <Grid container justifyContent="space-between">
                      <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormField size="100%" title="Plan Name" name="name" placeholder="Enter floorplan name" type="text" />
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormField
                            size="100%"
                            title="Marketing Name"
                            name="marketing"
                            placeholder="Enter marketing name"
                            type="text"
                          />
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormField
                            size="100%"
                            title="Status"
                            name="status"
                            placeholder="Select status"
                            type="select"
                            options={statuses}
                            disabled={!hasEditAccess}
                          />
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormField
                            size="100%"
                            title="Revision"
                            name="revision"
                            placeholder="Enter revision"
                            type="select"
                            options={revision}
                            disabled={!hasEditAccess}
                          />
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormField size="100%" title="SqFt" name="sqft" placeholder="Enter sqft" type="number" />
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormGroup>
                            <InputLabel htmlFor="photo">
                              <Typography variant="body1" color={theme.palette.info.main}>
                                Elevation Image
                              </Typography>
                            </InputLabel>
                            <input
                              ref={attachmentRef1}
                              hidden
                              type="file"
                              onChange={(evt) => {
                                hasEditAccess && uploadImage(evt, setElevationImage);
                              }}
                            />
                            <Box
                              className={styles.PartsFormUpload}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              onClick={() => {
                                attachmentRef1?.current?.click();
                              }}
                            >
                              {!elevationImage ? (
                                <Typography className={styles.PartsFormUploadText}>Upload image</Typography>
                              ) : (
                                <img src={elevationImage} alt="preview" className={styles.PartsFormImagePreview} />
                              )}
                              <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                            </Box>
                            {/* <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
                          </FormGroup>
                        </Grid>
                        <Grid item xs="auto" sm={6} lg={3}>
                          <FormGroup>
                            <InputLabel htmlFor="photo">
                              <Typography variant="body1" color={theme.palette.info.main}>
                                Floorplan Image
                              </Typography>
                            </InputLabel>
                            <input
                              ref={attachmentRef2}
                              hidden
                              type="file"
                              onChange={(evt) => {
                                hasEditAccess && uploadImage(evt, setFloorplanImage);
                              }}
                            />
                            <Box
                              className={styles.PartsFormUpload}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              onClick={() => {
                                attachmentRef2?.current?.click();
                              }}
                            >
                              {!floorplanImage ? (
                                <Typography className={styles.PartsFormUploadText}>Upload image</Typography>
                              ) : (
                                <img src={floorplanImage} alt="preview" className={styles.PartsFormImagePreview} />
                              )}
                              <img className={styles.PartsFormAttachmentIcon} src={attachmentIcon} alt="Attachment icon" />
                            </Box>
                            {/* <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(elevationImage || floorplanImage) && (
                      <Grid
                        className={styles.ImageBox}
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid className={styles.ImageBoxContentHidden} item xs="auto" sm={6} lg={3}></Grid>
                        {elevationImage && (
                          <Grid
                            className={styles.ImageBoxContent}
                            onClick={() => openFile(floorplanDetail.fileElevationUrl)}
                            item
                            xs="auto"
                            sm={6}
                            lg={3}
                          >
                            <img src={elevationImage} alt="preview" />
                          </Grid>
                        )}
                        {floorplanImage && (
                          <Grid
                            className={styles.ImageBoxContent}
                            onClick={() => openFile(floorplanDetail.fileFloorplanUrl)}
                            item
                            xs="auto"
                            sm={6}
                            lg={3}
                          >
                            <img src={floorplanImage} alt="preview" />
                          </Grid>
                        )}
                        <Grid className={styles.ImageBoxContentHidden} item xs="auto" sm={6} lg={3}></Grid>
                      </Grid>
                    )}
                  </fieldset>
                </Form>

                <TabsUnstyled defaultValue={0}>
                  <TabsList>
                    <Tab>General</Tab>
                    <Tab>Formula Variables</Tab>
                    <Tab>Plan Files</Tab>
                    <Tab>Companies</Tab>
                  </TabsList>
                  <TabPanel value={0}>
                    <MasterFloorplanDetailGeneralTab idnumber={idnumber} callback={setLocalFieldList} hasAccess={hasEditAccess} />
                  </TabPanel>
                  <TabPanel value={1}>
                    <MasterFloorplanDetailVariablesTab idnumber={idnumber} callback={setLocalVariablesList} />
                  </TabPanel>
                  <TabPanel value={2}>
                    <MasterFloorplanDetailFilesTab
                      idnumber={idnumber}
                      name={floorplanDetail.name}
                      revision={values.revision}
                      openFilePreview={openFilePreview}
                    />
                  </TabPanel>
                  <TabPanel value={3}>
                    <MasterFloorplanDetailCompaniesTab idnumber={idnumber} callback={setLocalCiaList} hasAccess={hasEditAccess} />
                  </TabPanel>
                </TabsUnstyled>
              </>
            )}
          </Formik>
        </>
      )}

      <MasterFloorplanDelete />
      <FloorplansFileServer />
      <FilePreview />

      {detailsLoader && (
        <div className={styles.Loader}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(MasterFloorplansDetails);
