import { PROJECTS_API } from "resource/constants/api";
import { takeLatest, put, takeEvery } from "redux-saga/effects";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  ProjectsActions,
  putProjectByIdAction,
  putProjectsAction,
  fetchProjectByIdAction,
  putProjectFieldsAction,
  putProjectFormulaVariableAction,
  putProjectBasePlanAction,
  putProjectMembersAction,
  deleteProjectFromStoreAction,
  putProjetFileInStoreAction,
  deleteProjetFileFromStoreAction,
  putProjectMembersInStoreAction,
  deleteProjectMembersFromStoreAction,
  getProjectDetailAction,
} from "../redux-actions/projects";
import { setPaginationLoaderAction, setButtonLoaderAction, setAddNewLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchProjectsWorker(action) {
  const {
    payload: {
      additionalData: { fromDate, toDate, status, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PROJECTS_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
    ...(status && { status }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putProjectsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectFieldsWorker(action) {
  const {
    payload: {
      additionalData: { idnumber, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber, idcia };

  const { errno, message, data } = yield get(`${PROJECTS_API}/field/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putProjectFieldsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectFormulaVariablesWorker(action) {
  const {
    payload: {
      additionalData: { idnumber, idcia, idplan1 },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber, idcia, idplan1 };

  const { errno, message, data } = yield get(`${PROJECTS_API}/formulaVariable/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putProjectFormulaVariableAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectBasePlansWorker(action) {
  const {
    payload: { idnumber, idcia, idplan1, revision },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { idnumber, idcia, idplan1, revision };

  const { errno, message, data } = yield get(`${PROJECTS_API}/baseplan/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putProjectBasePlanAction(data.list));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectFMembersWorker(action) {
  const {
    payload: {
      additionalData: { idnumber, idcia, idplan1 },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber, idcia, idplan1 };

  const { errno, message, data } = yield get(`${PROJECTS_API}/members/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putProjectMembersAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectByIdWorker(action) {
  const {
    payload: { idnumber, idcia, mode },
  } = action;

  yield mode === "create" && put(setAddNewLoaderAction(true));

  const { errno, message, data } = yield get(`${PROJECTS_API}/${idcia}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
    yield mode === "create" && put(setAddNewLoaderAction(false));
  } else {
    yield put(putProjectByIdAction(data[0]));
    yield mode === "create" && put(setAddNewLoaderAction(false));
  }
}

function* updateProjectWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  if (actionType === "create") {
    const {
      data: { nextId },
    } = yield get(`${PROJECTS_API}/getNextId`, { idcia: values.idcia, idnumber: values.devid });

    values.idnumber = nextId;
    delete values.devid;
  }

  const { errno, message } = yield post(`${PROJECTS_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Project was ${actionType}ed successfully`);
    yield put(fetchProjectByIdAction({ idnumber: values.idnumber, idcia: values.idcia, mode: actionType }));
    yield put(clearNotifierAction());
  }
}

function* deleteProjectWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PROJECTS_API}/delete`, payload, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Project was deleted successfully`);
    yield put(deleteProjectFromStoreAction(payload.idnumber));
    yield put(clearNotifierAction());
  }
}

function* uploaProjectFileWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message, data: responseData } = yield post(`${PROJECTS_API}/basePlan/uploadFile`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Upload project file successfull");
    yield put(
      putProjetFileInStoreAction({
        attachyn: false,
        viewyn: true,
        deleteyn: true,
        fid: responseData.newId,
        idnumber: data.idnumber,
        url: data.path,
        ftype: data.ftype,
        modiby: data.modiby,
        folderName: data.folderName,
        modidate: data.modidate,
        fsizekb: data.fsizekb,
      })
    );
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* deleteProjectFileWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${PROJECTS_API}/basePlan/deleteFile`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Deleting project file successfull");
    yield put(deleteProjetFileFromStoreAction(data));
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* uploadProjectMemberWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data: responseData } = yield post(`${PROJECTS_API}/member/create`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Upload project member successfull");
    yield put(
      putProjectMembersInStoreAction({
        ndepto: data.ndepto,
        username: data.xusername,
        fullname: data.name,
        idnumber: responseData.newId,
        idproject1: data.idnumber,
        ac: "Project",
      })
    );
    yield put(clearNotifierAction());
  }
}

function* deleteProjectMemberWorker(action) {
  const {
    payload: { idnumber, idsub },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PROJECTS_API}/member/delete`, { idnumber, idsub }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete project member successfull");
    yield put(deleteProjectMembersFromStoreAction({ idnumber }));
    yield put(clearNotifierAction());
  }
}

function* fetchProjectDetailWorker(action) {
  const {
    payload: { idnumber, idcia },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data } = yield get(`${PROJECTS_API}/${idcia}/${idnumber}`);

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Project detail uploaded");
    yield put(getProjectDetailAction(data[0]));
  }
}

export function* projectsSaga() {
  yield takeLatest(ProjectsActions.FETCH_PROJECTS, fetchProjectsWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_BY_ID, fetchProjectByIdWorker);
  yield takeLatest(ProjectsActions.ADD_NEW_PROJECT, updateProjectWorker);
  yield takeLatest(ProjectsActions.EDIT_PROJECT, updateProjectWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_FIELDS, fetchProjectFieldsWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_FORMULA_VARIABLE, fetchProjectFormulaVariablesWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_BASE_PLANS, fetchProjectBasePlansWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_MEMBERS, fetchProjectFMembersWorker);
  yield takeLatest(ProjectsActions.DELETE_PROJECT, deleteProjectWorker);
  yield takeEvery(ProjectsActions.UPLOAD_PROJECT_FILE, uploaProjectFileWorker);
  yield takeEvery(ProjectsActions.DELETE_PROJECT_FILE, deleteProjectFileWorker);
  yield takeLatest(ProjectsActions.UPLOAD_PROJECT_MEMBER, uploadProjectMemberWorker);
  yield takeLatest(ProjectsActions.DELETE_PROJECT_MEMBER, deleteProjectMemberWorker);
  yield takeLatest(ProjectsActions.FETCH_PROJECT_DETAIL, fetchProjectDetailWorker);
}
