import { FC } from "react";
// ? COMPONENTS
import LoadingButton from "@mui/lab/LoadingButton";

// ? STYLES
import styles from "./SubmitButton.module.css";

// ? TYPES
interface ButtonPropsTypes {
  text: string;
  large?: boolean | undefined;
  isLoading?: boolean;
  callback?: (e: any) => void;
  type?: string | undefined;
  isDisabled?: boolean;
}

export const SubmitButton: FC<ButtonPropsTypes> = ({ text, large = false, isLoading, callback, type, isDisabled }) => {
  return (
    <LoadingButton
      onClick={callback}
      loading={isLoading}
      type="submit"
      variant="outlined"
      className={`${!isLoading ? styles.SubmitButton : styles.LoadingSubmitButton} 
      ${large && styles.Large} ${type === "delete" ? styles.Delete : styles.Upload}`}
      disabled={isDisabled}
    >
      {text}
    </LoadingButton>
  );
};
