import React, { useState, useEffect, FC } from "react";

// ? COMPONENTS
import { useFormik } from "formik";
import { TabsUnstyled } from "@mui/base";
import {
  Alert,
  IconButton,
  Typography,
  Box,
  useTheme,
  InputLabel,
  Grid,
  FormGroup,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { useSearch } from "custom-hooks/useSearch";
import ContractsFormContract from "./ContractsFormContract";
import ContractsFormBroker from "./ContractsFormBroker";

// ? RESOURCES
import { Tab, TabPanel, TabsList } from "resource/styled";
import { PROJECTS_API } from "resource/constants/api";
import { Statuses } from "resource/consts";
import { ContractSubmitType, ContractFormData } from "resource/interfaces/contracts";
import closeButtonIcon from "resource/images/close-btn.svg";
import { contractSchema } from "resource/schemas/contractScema";

// ? STYLES
import styles from "./Contracts.module.css";

interface ContractsCreateFormProps {
  title: string;
  handleSubmit: (values: ContractSubmitType) => void;
  formData: ContractFormData;
  handleClose: () => void;
  isLoading: boolean;
}

const ContractsCreateForm: FC<ContractsCreateFormProps> = ({ title, handleSubmit, formData, handleClose, isLoading }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<null | any>(null);
  const [activatedButton, setActivatedButton] = useState("");

  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      handleSubmit({ ...values, idcia: Number(project.idcia), idproject: project.idnumber, status: activatedButton });
    },
    validationSchema: contractSchema,
  });

  const [optionsProjects, setQueryProjects, isPendingProjects, initialConditionProjects, errorMessageProjects] =
    useSearch(PROJECTS_API);

  useEffect(() => {
    setLoading(isPendingProjects);
  }, [isPendingProjects]);

  return (
    <>
      {errorMessageProjects && <Alert severity="error">{errorMessageProjects}</Alert>}
      <div className={styles.CreateFormExtraLarge}>
        <div className={styles.FormHeader}>
          <Typography variant="h1" color={theme.palette.text.primary}>
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={closeButtonIcon} alt="close-button" />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit} className={styles.MainForm}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="idproject">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Add Project*
                  </Typography>
                </InputLabel>
                <Autocomplete
                  className={styles.SearchField}
                  id="idproject"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={optionsProjects}
                  getOptionLabel={(option: any) => option["name"]}
                  loading={loading}
                  noOptionsText={!initialConditionProjects ? "Start typing" : "No options"}
                  onChange={(e, value: any) => {
                    setProject(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className={styles.SearchFieldInput}
                      placeholder="Search project"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        setQueryProjects(e.target.value);
                      }}
                      name="idproject"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="notes">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Notes
                  </Typography>
                </InputLabel>
                <TextField
                  type="text"
                  name="formBasic.notes"
                  id="notes"
                  value={formik.values.formBasic.notes}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <TabsUnstyled defaultValue={0}>
            <TabsList>
              <Tab type={"button"}>Contract</Tab>
              <Tab type={"button"} sx={{ mr: "5px" }}>
                Broker
              </Tab>
            </TabsList>
            <Box className={styles.Tab}>
              <TabPanel value={0}>
                <ContractsFormContract formik={formik} />
              </TabPanel>
              <TabPanel value={1}>
                <ContractsFormBroker formik={formik} />
              </TabPanel>
            </Box>
          </TabsUnstyled>
          <Grid container spacing={2} sx={{ marginTop: "15px" }}>
            <Grid item className={styles.StatusSubmitButtons}>
              <SubmitButton
                text={Statuses.DRAFT.buttonText}
                isLoading={activatedButton === Statuses.DRAFT.status && isLoading}
                callback={() => {
                  setActivatedButton(Statuses.DRAFT.status);
                }}
              />
            </Grid>
            <Grid item className={styles.StatusSubmitButtons}>
              <SubmitButton
                text={Statuses.FOR_APPROVE.buttonText}
                isLoading={activatedButton === Statuses.FOR_APPROVE.status && isLoading}
                callback={() => {
                  setActivatedButton(Statuses.FOR_APPROVE.status);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default ContractsCreateForm;
