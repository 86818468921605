import { DevelopmentsActions } from "redux-store/redux-actions/developments";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const initialState = {
  developments: [],
  developmentsCount: 0,
  developmentDetail: {},
  developmentsBanks: [],
  developmentsBanksCount: 0,
  developmentsFields: [],
  developmentsFieldsCount: 0,
  developmentsLinkedProjects: [],
  developmentsLinkedProjectsCount: 0,
  developmentsFiles: [],
  developmentsFilesCount: 0,
  developmentsMembers: [],
  developmentsMembersCount: 0,
  developmentsError: "",
};

export const developmentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DevelopmentsActions.PUT_DEVELOPMENTS:
      return {
        ...state,
        developments: [...state.developments, ...getUniqueData(payload.data, state.developments, "idnumber")],
        developmentsCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENT_BY_ID:
      return {
        ...state,
        developments: updateCollection(state.developments, payload, "modidate"),
      };
    case DevelopmentsActions.PUT_DEVELOPMENTS_BANKS:
      return {
        ...state,
        developmentsBanks: [...state.developmentsBanks, ...getUniqueData(payload.data, state.developmentsBanks, "idnumber")],
        developmentsBanksCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENTS_FIELDS:
      return {
        ...state,
        developmentsFields: [...state.developmentsFields, ...getUniqueData(payload.data, state.developmentsFields, "idnumber")],
        developmentsFieldsCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENTS_LINKED_PROJECTS:
      return {
        ...state,
        developmentsLinkedProjects: [
          ...state.developmentsLinkedProjects,
          ...getUniqueData(payload.data, state.developmentsLinkedProjects, "idnumber"),
        ],
        developmentsLinkedProjectsCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENTS_FILES:
      return {
        ...state,
        developmentsFiles: [...state.developmentsFiles, ...getUniqueData(payload.data, state.developmentsFiles, "idnumber")],
        developmentsFilesCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENT_FILE_IN_STORE:
      return {
        ...state,
        developmentsFiles: [
          ...state.developmentsFiles.map((file: any) => {
            if (file.idnumber === payload.idnumber) {
              return { name: file.name, modidate: "", ...payload };
            } else {
              return file;
            }
          }),
        ],
      };
    case DevelopmentsActions.DELETE_DEVELOPMENT_FILE_FROM_STORE:
      return {
        ...state,
        developmentsFiles: [
          ...state.developmentsFiles.map((file: any) => {
            if (file.fid === payload.fid) {
              return { name: file.name, modidate: "", attachyn: true, deleteyn: false, viewyn: false, revision: 0 };
            } else {
              return file;
            }
          }),
        ],
        developmentsFilesCount: state.developmentsFilesCount - 1,
      };
    case DevelopmentsActions.PUT_DEVELOPMENTS_MEMBERS:
      return {
        ...state,
        developmentsMembers: [
          ...state.developmentsMembers,
          ...getUniqueData(payload.data, state.developmentsMembers, "idnumber"),
        ],
        developmentsMembersCount: payload.count as number,
      };
    case DevelopmentsActions.PUT_DEVELOPMENT_MEMBER_IN_STORE:
      return {
        ...state,
        developmentsMembers: [...state.developmentsMembers, payload],
      };
    case DevelopmentsActions.DELETE_DEVELOPMENT_MEMBER_FROM_STORE:
      return {
        ...state,
        developmentsMembers: [...state.developmentsMembers.filter((member: any) => member.idnumber !== payload.idnumber)],
        developmentsMembersCount: state.developmentsMembersCount - 1,
      };
    case DevelopmentsActions.CLEAR_DEVELOPMENTS_DATA:
      return {
        ...state,
        developments: [],
        developmentsCount: 0,
      };
    case DevelopmentsActions.CLEAR_DEVELOPMENTS_DETAILS_DATA:
      return {
        ...state,
        [`developments${payload}`]: [],
        [`developments${payload}Count`]: 0,
      };
    case DevelopmentsActions.DELETE_DEVELOPMENT_FROM_STORE:
      return {
        ...state,
        developments: [...state.developments.filter((development: any) => development.idnumber !== payload)],
      };
    case DevelopmentsActions.GET_DEVELOPMENT_DETAIL:
      return { ...state, developmentDetail: payload };
    case DevelopmentsActions.CATCH_DEVELOPMENTS_ERROR:
      return { ...state, developmentsError: payload as string };
    default:
      return state;
  }
};
