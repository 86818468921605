// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import PartsVendorPricingList from "pages/parts/PartsVendorPricingList";
import PartsVendorPricingForm from "pages/parts/PartsVendorPricingForm";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { PARTS_NAME } from "resource/constants/parts";
import { ActionName } from "resource/constants/api";
import { CircularProgress } from "@mui/material";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

// ? INTERFACE
import { PartOnResponse } from "resource/interfaces/part";

export const nameVendorCreate = `vendor${PARTS_NAME}/${ActionName.CREATE}`;

const PartVendorPricingCreate = () => {
  const { close, isOpened, data } = useModal(nameVendorCreate);

  const newPart = useSelector((state: RootStateOrAny) => {
    if (isOpened && data.upc !== undefined) {
      return state.parts.parts.find((it: PartOnResponse) => it.upc === data.upc);
    }
  });

  return (
    <ModalManager name={nameVendorCreate}>
      {isOpened && newPart ? (
        <div className={styles.PartsAddForm}>
          <div className={`${styles.PartsBlock}`}>
            <PartsVendorPricingList
              idtrack={newPart.idtrack}
              upc={newPart.upc}
              vendorPricingList={newPart.vendorpricing ? newPart.vendorpricing : []}
              close={close}
            />
            <PartsVendorPricingForm idtrack={newPart.idtrack} upc={newPart.upc} actionType={ActionName.CREATE} />
          </div>
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PartVendorPricingCreate;
