import { FC, ReactNode } from "react";

// ? COMPONENTS
import { Box, Typography, useTheme } from "@mui/material";

// ? RESOURCES
import errorIcon from "resource/images/error.svg";

interface ValidationErrorProps {
  children: ReactNode;
}

export const ValidationError: FC<ValidationErrorProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box mb={1} display="flex" alignItems="center">
      <img src={errorIcon} alt="Error sign" />
      <Typography component="span" variant="caption" color={theme.palette.error.main} pl={0.5}>
        {children}
      </Typography>
    </Box>
  );
};
