//  ?  COMPONENTS
import { useEffect } from "react";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { PROJECTS_NAME } from "resource/constants/projects";
import { PROJECTS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${PROJECTS_NAME}/file/uploadFile`;

const ProjectsFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    idcia: data.idcia,
    name: data.name,
  };

  const dataForDelete = {
    name: data.name,
    projectID: data.idnumber,
    idcia: data.idcia,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
    idcia: data.idcia,
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Project-${data.idnumber} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={PROJECTS_API}
    />
  );
};

export default ProjectsFileServer;
