import { FC } from "react";

// ? COMPONENTS
import { Typography } from "@mui/material";

// ? RESOURCES
import { ColorListMap } from "resource/consts";

// ? STYLES
import styles from "./StatusMark.module.css";

interface StatusMarkProps {
  status: string;
}

const StatusMark: FC<StatusMarkProps> = ({ status }) => {
  return (
    <Typography
      className={styles.StatusMark}
      sx={{
        border: `1px solid rgb(${ColorListMap[status]})`,
        color: `rgb(${ColorListMap[status]})`,
        backgroundColor: `rgba(${ColorListMap[status]}, 0.1)`,
      }}
    >
      {status}
    </Typography>
  );
};

export default StatusMark;
