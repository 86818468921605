// ? ACTIONS

export enum MembersActions {
  ADD_NEW_USER = "ADD_NEW_USER",
  EDIT_USER = "EDIT_USER",
  GET_USER_BY_NAME = "GET_USER_BY_NAME",
  PUT_USER_BY_NAME = "PUT_USER_BY_NAME",
  GET_USERS = "GET_USERS",
  FETCH_USER_BY_NAME = "FETCH_USER_BY_NAME",
  FETCH_USERS = "FETCH_USERS",
  FETCH_EDIT_USER = "FETCH_EDIT_USER",
  GET_EDIT_USER = "GET_EDIT_USER",
  USER_ACTIVATION = "USER_ACTIVATION",
  CATCH_USERS_ERROR = "CATCH_USERS_ERROR",
  CLEAR_MEMBERS_DATA = "CLEAR_MEMBERS_DATA",
  DELETE_USER_FROM_SERVER = "DELETE_USER_FROM_SERVER",
  DELETE_USER_FROM_STORE = "DELETE_USER_FROM_STORE",

  FETCH_USERS_COMPANIES = "FETCH_USERS_COMPANIES",
  FETCH_USERS_ROLES = "FETCH_USERS_ROLES",

  PUT_USERS_COMPANIES = "PUT_USERS_COMPANIES",
  PUT_USERS_ROLES = "PUT_USERS_ROLES",

  CLEAR_USERS_DETAIL_DATA = "CLEAR_USERS_DETAIL_DATA",
}

// ? ACTION CREATORS

export const addNewUserAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: MembersActions.ADD_NEW_USER,
    payload,
    promise,
  };
};

export const editUserAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: MembersActions.EDIT_USER,
    payload,
    promise,
  };
};

export const getUserAction = (payload: any) => {
  return {
    type: MembersActions.GET_USER_BY_NAME,
    payload,
  };
};
export const putUserAction = (payload: any) => {
  return {
    type: MembersActions.PUT_USER_BY_NAME,
    payload,
  };
};

export const getUsersAction = (payload: any) => {
  return {
    type: MembersActions.GET_USERS,
    payload,
  };
};

export const fetchUserAction = (payload: any) => {
  return {
    type: MembersActions.FETCH_USER_BY_NAME,
    payload,
  };
};

export const fetchUsersAction = (payload: any) => {
  return {
    type: MembersActions.FETCH_USERS,
    payload,
  };
};

export const catchUsersErrorAction = (payload: any) => {
  return {
    type: MembersActions.CATCH_USERS_ERROR,
    payload,
  };
};

export const fetchEditUserAction = (payload: any) => {
  return {
    type: MembersActions.FETCH_EDIT_USER,
    payload,
  };
};

export const getEditUserAction = (payload: any) => {
  return {
    type: MembersActions.GET_EDIT_USER,
    payload,
  };
};

export const userActivationAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: MembersActions.USER_ACTIVATION,
    payload,
    promise
  };
};

export const clearDataAction = () => {
  return {
    type: MembersActions.CLEAR_MEMBERS_DATA,
  };
};

export const deleteUserServerAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: MembersActions.DELETE_USER_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteUserStoreAction = (payload: any) => {
  return {
    type: MembersActions.DELETE_USER_FROM_STORE,
    payload,
  };
};

export const fetchUsersCompaniesAction = (payload: any) => {
  return {
    type: MembersActions.FETCH_USERS_COMPANIES,
    payload,
  };
};

export const putUsersCompaniesAction = (payload: any) => {
  return {
    type: MembersActions.PUT_USERS_COMPANIES,
    payload,
  };
};

export const clearDataDetailsAction = (payload: string) => {
  return {
    type: MembersActions.CLEAR_USERS_DETAIL_DATA,
    payload,
  };
};
