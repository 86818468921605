import { useState, FC } from "react";

// ? COMPONENTS
import OutsideClickHandler from "react-outside-click-handler";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import { Typography, useTheme, Box } from "@mui/material";

// ? STYLES
import styles from "./ToggleField.module.css";

interface ToggleFieldProps {
  handleSubmit: any;
  schema: any;
  fieldName: string;
  field: string | number;
  isLoading: boolean;
  isTogglable?: boolean;
  type: string;
  initValues: any;
  hasAccess: boolean;
  cb?: any;
}

const TextBox = ({ isTogglable, toggleInput, field }) => {
  const theme = useTheme();
  return (
    <Box onClick={() => isTogglable && toggleInput()} sx={{ minHeight: "inherit" }}>
      <Typography sx={{ display: "flex", alignContent: "center", mr: 1, pt: "7px" }} color={theme.palette.text.primary}>
        {field}
      </Typography>
    </Box>
  );
};

const ToggleField: FC<ToggleFieldProps> = ({
  handleSubmit,
  schema,
  fieldName,
  field,
  isLoading,
  isTogglable,
  type,
  initValues,
  cb,
  hasAccess,
}) => {
  const [toggle, setToggle] = useState(true);

  const toggleInput = () => {
    setToggle(false);
  };

  return (
    <>
      {!hasAccess ? (
        <TextBox isTogglable={isTogglable} toggleInput={toggleInput} field={field} />
      ) : (
        <>
          {toggle ? (
            <TextBox isTogglable={isTogglable} toggleInput={toggleInput} field={field} />
          ) : (
            <Formik
              initialValues={initValues}
              validationSchema={schema}
              onSubmit={(values) => {
                handleSubmit(values);
                setToggle(true);
              }}
            >
              {({ handleSubmit, setFieldValue, values }) => {
                return (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      handleSubmit(values);
                      setToggle(true);
                    }}
                  >
                    <Form className={styles.Form}>
                      {isLoading ? (
                        <SmallLoader />
                      ) : (
                        <Field
                          type={type}
                          id={fieldName}
                          name={fieldName}
                          autoFocus
                          onChange={(evt) => {
                            setFieldValue(fieldName, evt.target.value);
                            if (cb) {
                              cb(evt.target.value);
                            }
                          }}
                        />
                      )}
                    </Form>
                    <ErrorMessage name={fieldName}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                  </OutsideClickHandler>
                );
              }}
            </Formik>
          )}
        </>
      )}
    </>
  );
};

export default ToggleField;
