import * as Yup from "yup";

export const purchaseOrderSchema = Yup.object().shape({
  idvendor: Yup.string().required("Vendor field cannot be empty"),
  idproject1: Yup.string().required("Project field cannot be empty"),
  idcostcode: Yup.string().required("Cost code field cannot be empty"),
});

export const purchaseOrderVoidSchema = Yup.object().shape({
  voidreason: Yup.string().label("Void reason").required("Void reason cannot be empty"),
  voidnotes: Yup.string().label("Void notes"),
});

export const purchaseOrderEmailSchema = Yup.object().shape({
  emailto: Yup.string().label("Email to"),
  emailcc: Yup.string().label("Email cc"),
  emailsubject: Yup.string().label("Email subject"),
  emailbody: Yup.string().label("Email body"),
});

export const purchaseOrderDiasapproveSchema = Yup.object().shape({
  disapprreason: Yup.string().label("Reason").required("Reason cannot be empty"),
  disapprnotes: Yup.string().label("Notes"),
  emailto: Yup.string().label("Email to"),
  emailcc: Yup.string().label("Email cc"),
  emailsubject: Yup.string().label("Email subject"),
  emailbody: Yup.string().label("Email body"),
});

export const purchaseOrderAddPartSchema = Yup.object().shape({
  quantity: Yup.string().label("Quantity").required("Quantity cannot be empty"),
});
