export const PAYMENT_SUMMARY_NAME = "paymentSummary";

export const PAYMENT_SUMMARY = {
  TITLE: "Payment Summary",
  NEW_MODAL_TITLE: "Add New Payment Summary",
  EDIT_MODAL_TITLE: "Update Payment Summary",
  NEW_MODAL_PLUS_BUTTON: "Add New Payment Summary",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Payment Summary",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Payment Summary",
};

export const paymentSummaryFiltersList = ["All", "Active", "Specs", "Sold", "Closed"];

export const paymentSummaryDetailsFiltersList = ["All", "A/P Vendor", "A/P Loan", "A/P Other"];
