import { FC } from "react";

// ? COMPONENTS
import { Box, Typography, useTheme } from "@mui/material";

// ? RESOURCES
import notesIcon from "resource/images/notes.svg";

interface NotesProps {
  notes: string;
}

const Notes: FC<NotesProps> = ({ notes }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ bgcolor: "#F4F4FF", borderRadius: "8px", width: "fit-content", p: "10px 32px", my: "10px", maxWidth: "80%" }}
      display="flex"
      alignItems="center"
    >
      <img src={notesIcon} alt="Notes icon" style={{ width: "14px", marginRight: "20px" }} />
      <Typography variant="h3" color={theme.palette.primary.main}>
        {notes}
      </Typography>
    </Box>
  );
};

export default Notes;
