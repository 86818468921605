import { useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { editProjectAction, fetchProjectDetailAction, getProjectDetailAction } from "redux-store/redux-actions/projects";
import { clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { withLayout } from "hoc/withLayout";
import { TabsUnstyled } from "@mui/base";
import { Grid, CircularProgress } from "@mui/material";
import { Tab, TabPanel, TabsList } from "resource/styled";
import ProjectsDetailGeneralTab from "./ProjectsDetailGeneralTab";
import ProjectsDetailFormulaVariableTab from "./ProjectsDetailFormulaVariableTab";
import ProjectsDetailBasePlansTab from "./ProjectsDetailBasePlansTab";
import ProjectDetailsMembersTab from "./ProjectDetailsMembersTab";
import useAsync from "custom-hooks/useAsync";
import { FormField } from "components/form-field/FormField";
import { SearchField } from "components/form-field/SearchField";
import useModal from "custom-hooks/useModal";
import { nameDelete } from "components/modals/delete/ProjectsDelete";
import ProjectDelete from "components/modals/delete/ProjectsDelete";
import ProjectDetailHeader from "./ProjectsDetailHeader";
import ProjectsFileServer from "components/modals/file-server-modal/ProjectsFileServer";
import FilePreview from "components/modals/file-preview/FilePreview";
import { nameFilePreview } from "components/modals/file-preview/FilePreview";
import { useSearch } from "custom-hooks/useSearch";
import { useNotice } from "custom-hooks/useNotice";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import { DEVELOPMENTS_API, MASTER_FLOORPLANS_API } from "resource/constants/api";
import { ActionName } from "resource/constants/api";
import { noticeTypes } from "resource/consts";
import { menu } from "resource/constants/menu-list";

const ProjectsDetail = ({ idcia, id }) => {
  const dispatch = useDispatch();

  const [setNotice] = useNotice();
  const { hasEditAccess } = useAccess(menu.projectPage.idmenu);
  const project = useSelector((state: RootStateOrAny) => state.projects.projectDetails);
  const fieldList = useSelector((state: RootStateOrAny) => state.projects.projectFields);
  const variablesList = useSelector((state: RootStateOrAny) => state.projects.projectVariables);

  const formikValues = project && {
    idnumber: project.idnumber,
    idcia: project.idcia,
    idplan1: project.idplan1,
    name: project.name,
    status: project.status,
    taddress: project.taddress,
    tcity: project.tcity,
    tstate: project.tstate,
    tzip: project.tzip,
    bankledger: project.bankledger,
    idtrack: project.idtrack,
  };

  const [localFieldList, setLocalFieldList] = useState(fieldList ? fieldList : []);
  const [localFormulaVariableList, setLocalFormulaVariableList] = useState(variablesList ? variablesList : []);

  const [planOptions, setPlanQuery, isPlanPending, planInitialCondition, planErrorMessage] = useSearch(MASTER_FLOORPLANS_API);
  const [banksOptions, setBanksQuery, isBanksPending, initialBanksCondition, banksErrorMessage] = useSearch(
    `${DEVELOPMENTS_API}/bank`,
    { idcia }
  );

  const { open: openDelete, close } = useModal(nameDelete);
  const handleProjectDelete = (): void => {
    openDelete({ ...project, type: "Project" });
  };

  const { open: openFilePreview } = useModal(nameFilePreview);

  const preparedData = (values) => {
    const valuesCopy = values;
    if (localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any, index) => {
          return {
            idnumber: field.idnumber,
            xid: field.xid,
            name: field.name,
            xtype: field.xtype,
            txtvalue: field.txtvalue || "",
          };
        });
    }
    if (localFormulaVariableList.length > 0) {
      valuesCopy.formulaVariableList = localFormulaVariableList
        .filter((field: any) => field.changed)
        .map((variable: any, index) => {
          return {
            idnumber: variable.idnumber,
            xid: variable.xid,
            name: variable.name,
            xtype: variable.xtype,
            txtvalue: variable.txtvalue || "",
          };
        });
    }

    return valuesCopy;
  };

  const [updateLoader, setUpdateLoader] = useState(false);
  const updateProject = useAsync(editProjectAction);

  const [isUpdate, setUpdateCounter] = useState(0);

  const handleSubmit = async (data) => {
    setUpdateLoader(true);

    try {
      await updateProject({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setUpdateLoader(false);
      setUpdateCounter((prev) => prev + 1);
      setNotice("Project updated", noticeTypes.success, 4000);
      close();
    } catch (e) {
      console.error(e);
      setUpdateLoader(false);
    }
  };

  const [detailsLoader, setDetailsLoader] = useState(false);

  const fetchDetailsRequest = useAsync(fetchProjectDetailAction);

  const fetchDetails = async () => {
    setDetailsLoader(true);

    try {
      await fetchDetailsRequest({ idnumber: id, idcia });
      setDetailsLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setDetailsLoader(false);
    }
  };

  useEffect(() => {
    fetchDetails();

    return () => {
      dispatch(getProjectDetailAction({}));
      dispatch(clearFileServerDataAction());
    };
  }, []);

  useEffect(() => {
    fetchDetails();
  }, [isUpdate]);

  return (
    <>
      {project && !detailsLoader && (
        <Formik initialValues={formikValues} onSubmit={(values: any) => handleSubmit(preparedData(values))}>
          {({ setFieldValue, handleSubmit }) => (
            <>
              <ProjectDetailHeader
                project={project}
                submit={handleSubmit}
                handleProjectDelete={handleProjectDelete}
                updateLoader={updateLoader}
              />
              <Form className={styles.ProjectsForm}>
                <fieldset className={styles.Fieldset} disabled={!hasEditAccess}>
                  <Grid className={styles.SubForm} container justifyContent="space-between">
                    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item xs="auto" sm={6} lg={3}>
                        <FormField size="100%" title="Project Name" name="name" placeholder="Enter project name" type="text" />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <SearchField
                          disabled={!hasEditAccess}
                          size="100%"
                          title="ID plan"
                          responseName="idnumber"
                          name="idplan1"
                          label="name"
                          placeholder="Search florrplan ID"
                          setFieldValue={setFieldValue}
                          initialFormValues={formikValues}
                          options={planOptions}
                          onQueryChange={setPlanQuery}
                          isPending={isPlanPending}
                          initialCondition={planInitialCondition}
                          defaultValue={project.idplan1 ? { name: project.planname, label: project.idplan1 } : null}
                        />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <FormField size="100%" title="Address" name="taddress" placeholder="Enter address" type="text" />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <FormField size="100%" title="City" name="tcity" placeholder="Enter city name" type="text" />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <FormField size="100%" title="State" name="tstate" placeholder="Enter state name" type="text" />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <FormField size="100%" title="Zip Code" name="tzip" placeholder="Enter zip code" type="number" />
                      </Grid>
                      <Grid item xs="auto" sm={6} lg={3}>
                        <SearchField
                          disabled={!hasEditAccess}
                          size="100%"
                          title="Prefered Bank Account"
                          responseName="bankledger"
                          name="bankledger"
                          label="name"
                          placeholder="Search bank account"
                          setFieldValue={setFieldValue}
                          initialFormValues={formikValues}
                          options={banksOptions}
                          onQueryChange={setBanksQuery}
                          isPending={isBanksPending}
                          initialCondition={initialBanksCondition}
                          defaultValue={project.bankledger ? { name: project.bankname, label: project.bankledger } : null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>
              </Form>
              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab>General</Tab>
                  <Tab>Formula Variable</Tab>
                  <Tab>Base Plans</Tab>
                  <Tab>Members</Tab>
                </TabsList>
                <TabPanel value={0}>
                  <ProjectsDetailGeneralTab idnumber={project.idnumber} idcia={idcia} callback={setLocalFieldList} />
                </TabPanel>
                <TabPanel value={1}>
                  <ProjectsDetailFormulaVariableTab
                    idnumber={project.idnumber}
                    idcia={idcia}
                    idplan1={project.idplan1}
                    callback={setLocalFormulaVariableList}
                  />
                </TabPanel>
                <TabPanel value={2}>
                  <ProjectsDetailBasePlansTab
                    idnumber={project.idnumber}
                    idcia={idcia}
                    idplan1={project.idplan1}
                    revision={project.revision}
                    openFilePreview={openFilePreview}
                  />
                </TabPanel>
                <TabPanel value={3}>
                  <ProjectDetailsMembersTab idnumber={project.idnumber} idcia={idcia} idsub={project.idsub} />
                </TabPanel>
              </TabsUnstyled>
            </>
          )}
        </Formik>
      )}

      <ProjectDelete />
      <ProjectsFileServer />
      <FilePreview />

      {detailsLoader && (
        <div className={`LoaderScroll  center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(ProjectsDetail);
