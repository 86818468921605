import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum FloorplansActions {
  ADD_NEW_FLOORPLAN = "ADD_NEW_FLOORPLAN",
  EDIT_FLOORPLAN = "EDIT_FLOORPLAN",
  GET_FLOORPLAN_BY_ID = "GET_FLOORPLAN_BY_ID",
  PUT_FLOORPLAN_BY_ID = "PUT_FLOORPLAN_BY_ID",
  FETCH_FLOORPLAN_BY_ID = "FETCH_FLOORPLAN_BY_ID",
  FETCH_FLOORPLAN_DETAIL_BY_ID = "FETCH_FLOORPLAN_DETAIL_BY_ID",
  GET_FLOORPLAN_DETAIL_BY_ID = "GET_FLOORPLAN_DETAIL_BY_ID",
  GET_FLOORPLANS = "GET_FLOORPLANS",
  FETCH_FLOORPLANS = "FETCH_FLOORPLANS",
  DELETE_FLOORPLAN_FROM_SERVER = "DELETE_FLOORPLAN_FROM_SERVER",
  DELETE_FLOORPLAN_FROM_STORE = "DELETE_FLOORPLAN_FROM_STORE",
  CATCH_FLOORPLANS_ERROR = "CATCH_FLOORPLANS_ERROR",
  UPLOAD_PLAN_FILE = "UPLOAD_PLAN_FILE",
  PUT_PLAN_FILE_IN_STORE = "PUT_PLAN_FILE_IN_STORE",
  DELETE_PLAN_FILE = "DELETE_PLAN_FILE",
  DELETE_PLAN_FILE_FROM_STORE = "DELETE_PLAN_FILE_FROM_STORE",

  FETCH_FLOORPLANS_FIELDS = "FETCH_FLOORPLANS_FIELDS",
  FETCH_FLOORPLANS_FORMULA_VARIABLES = "FETCH_FLOORPLANS_FORMULA_VARIABLES",
  FETCH_FLOORPLANS_FILES = "FETCH_FLOORPLANS_FILES",
  FETCH_FLOORPLANS_COMPANIES = "FETCH_FLOORPLANS_COMPANIES",

  PUT_FLOORPLANS_FIELDS = "PUT_FLOORPLANS_FIELDS",
  PUT_FLOORPLANS_FORMULA_VARIABLES = "PUT_FLPUT_FLOORPLANS_FORMULA_VARIABLESOORPLANS_LINKED_PROJECTS",
  PUT_FLOORPLANS_FILES = "PUT_FLOORPLANS_FILES",
  PUT_FLOORPLANS_COMPANIES = "PUT_FLOORPLANS_COMPANIES",

  CLEAR_FLOORPLANS_DATA = "CLEAR_FLOORPLANS_DATA",
  CLEAR_FLOORPLANS_DETAIL_DATA = "CLEAR_FLOORPLANS_DETAIL_DATA",
}

// ? ACTION CREATORS

export const addNewFloorplanAction = (payload: any) => {
  return {
    type: FloorplansActions.ADD_NEW_FLOORPLAN,
    payload,
  };
};

export const editFloorplanAction = (payload: any, promise?: PromiseType) => {
  return {
    type: FloorplansActions.EDIT_FLOORPLAN,
    payload,
    promise,
  };
};

export const getFloorplanAction = (payload: any) => {
  return {
    type: FloorplansActions.GET_FLOORPLAN_BY_ID,
    payload,
  };
};

export const putFloorplanAction = (payload: any) => {
  return {
    type: FloorplansActions.PUT_FLOORPLAN_BY_ID,
    payload,
  };
};

export const fetchFloorplanAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLAN_BY_ID,
    payload,
  };
};

export const getFloorplansAction = (payload: any) => {
  return {
    type: FloorplansActions.GET_FLOORPLANS,
    payload,
  };
};

export const fetchFloorplanDetailAction = (payload: any, promise?: PromiseType) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLAN_DETAIL_BY_ID,
    payload,
    promise,
  };
};

export const getFloorplanDetailAction = (payload: any) => {
  return {
    type: FloorplansActions.GET_FLOORPLAN_DETAIL_BY_ID,
    payload,
  };
};

export const fetchFloorplansAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLANS,
    payload,
  };
};

export const deleteFloorlanServerAction = (payload: any, promise?: PromiseType) => {
  return {
    type: FloorplansActions.DELETE_FLOORPLAN_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteFloorlanStoreAction = (payload: any) => {
  return {
    type: FloorplansActions.DELETE_FLOORPLAN_FROM_STORE,
    payload,
  };
};

export const catchFloorplansErrorAction = (payload: any) => {
  return {
    type: FloorplansActions.CATCH_FLOORPLANS_ERROR,
    payload,
  };
};

export const uploadPlanFileAction = (payload, promise?: PromiseType) => {
  return {
    type: FloorplansActions.UPLOAD_PLAN_FILE,
    payload,
    promise,
  };
};

export const deletePlanFileAction = (payload, promise?: PromiseType) => {
  return {
    type: FloorplansActions.DELETE_PLAN_FILE,
    payload,
    promise,
  };
};

export const putPlanFileInStoreAction = (payload) => {
  return {
    type: FloorplansActions.PUT_PLAN_FILE_IN_STORE,
    payload,
  };
};

export const deletePlanFileFromStoreAction = (payload) => {
  return {
    type: FloorplansActions.DELETE_PLAN_FILE_FROM_STORE,
    payload,
  };
};

export const fetchFloorlanFieldsAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLANS_FIELDS,
    payload,
  };
};

export const putFloorlanFieldsAction = (payload: any) => {
  return {
    type: FloorplansActions.PUT_FLOORPLANS_FIELDS,
    payload,
  };
};

export const fetchFloorlanVariablesAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLANS_FORMULA_VARIABLES,
    payload,
  };
};

export const putFloorlanVariableAction = (payload: any) => {
  return {
    type: FloorplansActions.PUT_FLOORPLANS_FORMULA_VARIABLES,
    payload,
  };
};

export const fetchFloorlanFilesAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLANS_FILES,
    payload,
  };
};

export const putFloorlanFilesAction = (payload: any) => {
  return {
    type: FloorplansActions.PUT_FLOORPLANS_FILES,
    payload,
  };
};

export const fetchFloorlanCompaniesAction = (payload: any) => {
  return {
    type: FloorplansActions.FETCH_FLOORPLANS_COMPANIES,
    payload,
  };
};

export const putFloorlanCompaniesAction = (payload: any) => {
  return {
    type: FloorplansActions.PUT_FLOORPLANS_COMPANIES,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: FloorplansActions.CLEAR_FLOORPLANS_DATA,
  };
};

export const clearDataDetailsAction = (payload: string) => {
  return {
    type: FloorplansActions.CLEAR_FLOORPLANS_DETAIL_DATA,
    payload,
  };
};
