// ? ACTIONS
export enum CompaniesActions {
  ADD_NEW_COMPANY = "ADD_NEW_COMPANY",
  EDIT_COMPANY = "EDIT_COMPANY",
  GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID",
  PUT_COMPANY_BY_ID = "PUT_COMPANY_BY_ID",
  GET_COMPANIES = "GET_COMPANIES",
  FETCH_COMPANY_BY_ID = "FETCH_COMPANY_BY_ID",
  FETCH_COMPANIES = "FETCH_COMPANIES",
  DELETE_COMPANY_FROM_SERVER = "DELETE_COMPANY_FROM_SERVER",
  DELETE_COMPANY_FROM_STORE = "DELETE_COMPANY_FROM_STORE",
  CATCH_COMPANIES_ERROR = "CATCH_COMPANIES_ERROR",
  CLEAR_COMPANIES_DATA = "CLEAR_COMPANIES_DATA",
}

// ? ACTION CREATORS

export const addNewCompanyAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: CompaniesActions.ADD_NEW_COMPANY,
    payload,
    promise,
  };
};

export const editCompanyAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: CompaniesActions.EDIT_COMPANY,
    payload,
    promise,
  };
};

export const getCompanyAction = (payload: any) => {
  return {
    type: CompaniesActions.GET_COMPANY_BY_ID,
    payload,
  };
};
export const putCompanyAction = (payload: any) => {
  return {
    type: CompaniesActions.PUT_COMPANY_BY_ID,
    payload,
  };
};

export const getCompaniesAction = (payload: any) => {
  return {
    type: CompaniesActions.GET_COMPANIES,
    payload,
  };
};

export const fetchCompanyAction = (payload: any) => {
  return {
    type: CompaniesActions.FETCH_COMPANY_BY_ID,
    payload,
  };
};

export const fetchCompaniesAction = (payload: any) => {
  return {
    type: CompaniesActions.FETCH_COMPANIES,
    payload,
  };
};

export const catchCompaniesErrorAction = (payload: any) => {
  return {
    type: CompaniesActions.CATCH_COMPANIES_ERROR,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: CompaniesActions.CLEAR_COMPANIES_DATA,
  };
};

export const deleteCompanyServerAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: CompaniesActions.DELETE_COMPANY_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteCompanyStoreAction = (payload: any) => {
  return {
    type: CompaniesActions.DELETE_COMPANY_FROM_STORE,
    payload,
  };
};
