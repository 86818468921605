import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum PaymentSummaryActions {
  PUT_PAYMENT_SUMMARY_DETAILS = "PUT_PAYMENT_SUMMARY_DETAILS",
  PUT_PAYMENT_SUMMARY = "PUT_PAYMENT_SUMMARY",
  FETCH_PAYMENT_SUMMARY = "FETCH_PAYMENT_SUMMARY",
  FETCH_PAYMENT_SUMMARY_DETAILS = "FETCH_PAYMENT_SUMMARY_DETAILS",
  CLEAR_PAYMENT_SUMMARY_DATA = "CLEAR_PAYMENT_SUMMARY_DATA",
  CLEAR_PAYMENT_SUMMARY_DETAILS_DATA = "CLEAR_PAYMENT_SUMMARY_DETAILS_DATA",
  CATCH_PAYMENT_SUMMARY_ERROR = "CATCH_PAYMENT_SUMMARY_ERROR",
}

// ? ACTION CREATORS
export const putPaymentSummaryAction = (payload: { data; count: number }) => {
  return {
    type: PaymentSummaryActions.PUT_PAYMENT_SUMMARY,
    payload,
  };
};

export const putPaymentSummaryDetailsAction = (payload) => {
  return {
    type: PaymentSummaryActions.PUT_PAYMENT_SUMMARY_DETAILS,
    payload,
  };
};

export const fetchPaymentSummaryAction = (payload: number) => {
  return {
    type: PaymentSummaryActions.FETCH_PAYMENT_SUMMARY,
    payload,
  };
};

export const fetchPaymentSummaryDetailsAction = (payload: any) => {
  return {
    type: PaymentSummaryActions.FETCH_PAYMENT_SUMMARY_DETAILS,
    payload,
  };
};

export const clearPaymentSummaryDataAction = () => {
  return {
    type: PaymentSummaryActions.CLEAR_PAYMENT_SUMMARY_DATA,
  };
};

export const clearPaymentSummaryDetailsDataAction = () => {
  return {
    type: PaymentSummaryActions.CLEAR_PAYMENT_SUMMARY_DETAILS_DATA,
  };
};

export const catchPaymentSummaryErrorAction = (payload: string) => {
  return {
    type: PaymentSummaryActions.CATCH_PAYMENT_SUMMARY_ERROR,
    payload,
  };
};
