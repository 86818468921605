import { useMemo, useState, FC } from "react";
import { Link, useHistory } from "react-router-dom";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchReleaseListAction, releaseTakeoffsAction } from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import { Box, Grid, Typography, useTheme, CircularProgress, Checkbox } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import { withLayout } from "hoc/withLayout";
import { useNotice } from "custom-hooks/useNotice";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { CheckboxIcon } from "components/shared/checkbox-icon/CheckboxIcon";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { resolvePath, isBiggerThanZero } from "resource/utils";
import { noticeTypes } from "resource/consts";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { ReleaseTakeoffItem } from "resource/interfaces/project-takeoff";
import { ROUTES } from "resource/routes/routes";
import noCompany from "resource/images/no-company.svg";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
import pageHeaderStyles from "components/page-header/PageHeader.module.css";

interface ProjectTakeoffReleaseProps {
  id: string;
  idcia: number;
}

const ProjectTakeoffRelease: FC<ProjectTakeoffReleaseProps> = ({ id, idcia }) => {
  const theme = useTheme();
  const history = useHistory();
  const mainPath = ROUTES.projectTakeoff.pathname;
  const subDirectories = [id, idcia];
  const [assembliesIds, setAssembliesIds] = useState(new Set());
  const [isLoading, setLoader] = useState(false);

  const [setNotice] = useNotice();
  const releaseTakeoffs = useAsync(releaseTakeoffsAction);

  const projectTakeoffReleaseListIds: number[] = useSelector(
    (state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffReleaseListIds
  );
  const projectTakeoffReleaseList = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffReleaseList);
  const projectTakeoffReleaseListCount: number = useSelector(
    (state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffReleaseListCount
  );
  const hasMoreToLoad: boolean = projectTakeoffReleaseListCount > projectTakeoffReleaseListIds.length;
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idproject1: id,
      idcia,
    }),
    [id, idcia]
  );

  useInfiniteLoading(fetchReleaseListAction, hasMoreToLoad, paginationLoading, additionalData);

  const handleAssemblySelect = (id: number | string) => {
    const ids = new Set(assembliesIds);

    if (ids.has(id)) {
      ids.delete(id);
    } else {
      ids.add(id);
    }
    setAssembliesIds(ids);
  };

  const handleSubmit = async () => {
    const ids: any[] = Array.from(assembliesIds);
    const list: ReleaseTakeoffItem[] =
      ids.length > 0
        ? ids.map((id) => {
            const newItem = { ...projectTakeoffReleaseList[id], idvendor: Number(projectTakeoffReleaseList[id].idvendor) };
            delete newItem[""];
            delete newItem.xsel;
            return newItem;
          })
        : [];

    const values = {
      idproject1: id,
      idcia: Number(idcia),
      list,
    };

    try {
      await releaseTakeoffs({ values, endpoint: TakeoffEndpoints.ReleaseTakeoff, dataForFetch: { id, idcia } });
      setLoader(false);
      setNotice("Assembly was succesfully released", noticeTypes.success, 4000);
      history.push(resolvePath(mainPath, subDirectories));
      setAssembliesIds(new Set());
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(mainPath, subDirectories)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${id}`}
            </Typography>
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Choose assemblies to release from the list and click "Save"
          </Typography>
        </Grid>
        <Grid item>
          <SubmitButton
            text="Save and Release"
            isLoading={isLoading}
            callback={handleSubmit}
            type="button"
            isDisabled={assembliesIds.size === 0}
          />
        </Grid>
      </Grid>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Assembly</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography color={theme.palette.primary.main}>Assembly Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.primary.main}>Vendor</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Taxrate</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Takeoff</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Select</Typography>
        </Grid>
      </TableListHeader>
      {!paginationLoading ? (
        <>
          {projectTakeoffReleaseListIds.length ? (
            <>
              {projectTakeoffReleaseListIds.map((id: string | number) => (
                <div key={id}>
                  <TableListRow>
                    <Grid item xs={1}>
                      <Typography color={theme.palette.text.primary}>{projectTakeoffReleaseList[id].idassembly1}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography color={theme.palette.text.primary}>{projectTakeoffReleaseList[id].name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography color={theme.palette.text.primary}>{projectTakeoffReleaseList[id].nvendor}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography color={theme.palette.text.primary}>
                        {isBiggerThanZero(projectTakeoffReleaseList[id].taxrate)}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography color={theme.palette.text.primary}>
                        {isBiggerThanZero(projectTakeoffReleaseList[id].total)}
                      </Typography>
                    </Grid>
                    <Grid
                      className={styles.Checkbox}
                      item
                      xs={1}
                      justifyContent="flex-end"
                      display="flex"
                      onClick={() => handleAssemblySelect(id)}
                    >
                      <Checkbox checkedIcon={<CheckboxIcon />} />
                    </Grid>
                  </TableListRow>
                </div>
              ))}
            </>
          ) : (
            <Box display="flex" justifyContent="center" mt={10}>
              <img src={noCompany} alt="no assemblies data" />
            </Box>
          )}
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(ProjectTakeoffRelease);
