import { FC } from "react";

import ModalManager from "../ModalManager";
import MasterRolesForm from "pages/master-roles/MasterRolesForm";
import { MASTER_ROLES, MASTER_ROLES_NAME } from "resource/constants/master-roles";
import { ActionName } from "resource/constants/api";
import { RootStateOrAny, useSelector } from "react-redux";
import useModal from "custom-hooks/useModal";

import { roleOnGet } from "resource/interfaces/master-roles";

interface MasterRolesCreateProps {
  className: string;
}

export const nameCreate = `${MASTER_ROLES_NAME}/${ActionName.CREATE}`;

const MasterRolesCreate: FC<MasterRolesCreateProps> = ({ className }) => {
  const masterRoles: roleOnGet[] = useSelector((state: RootStateOrAny) => state.masterRoles.masterRoles);

  const { close } = useModal(nameCreate);

  const nextId = Math.max(
    ...masterRoles.map(function (role: roleOnGet) {
      return role.idnumber + 1;
    })
  );

  const initialFormValues = {
    idrole: nextId,
    name: "",
    project: 1,
    actions: {},
    sort: 0,
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      <MasterRolesForm
        title={MASTER_ROLES.NEW_MODAL_TITLE}
        formData={initialFormValues}
        buttonText={MASTER_ROLES.NEW_MODAL_SUBMIT_BUTTON}
        handleClose={close}
        actionType={ActionName.CREATE}
      />
    </ModalManager>
  );
};

export default MasterRolesCreate;
