import { useMemo, useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import {
  clearDataAction,
  fetchPlansAction,
} from "redux-store/redux-actions/planDrawer";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import { Search } from "components/search/Search";
import { TabsUnstyled } from "@mui/base";
import { PlanDrawerList } from "./PlanDrawerList";
import { Tab, TabsList, TabPanel } from "resource/styled";
import PageHeader from "components/page-header/PageHeader";
import useAccess from "custom-hooks/useAccess";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import PlanDrawerCreate from "components/modals/create/PlanDrawerCreate";
import PlanDrawerUpdate from "components/modals/update/PlanDrawerUpdate";
import PlanDrawerDelete from "components/modals/delete/PlanDrawerDelete";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { PLAN_DRAWER } from "resource/constants/plan-drawer";
import { menu } from "resource/constants/menu-list";
import { nameCreate } from "components/modals/create/PlanDrawerCreate";

// ? TYPES
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";

const PlanDrawer = () => {
  const { hasAddAccess } = useAccess(menu.planDrawer.idmenu);
  const [category, setCategory] = useState("development");
  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const { open: openCreate} = useModal(nameCreate);

  const development = useSelector((state: RootStateOrAny) => state.plans.development);
  const project = useSelector((state: RootStateOrAny) => state.plans.project);
  const floorplan = useSelector((state: RootStateOrAny) => state.plans.floorplan);

  const content = useSelector((state: RootStateOrAny) => state.plans[category]);
  const contentCounter = useSelector((state: RootStateOrAny) => state.plans[`${category}Count`]);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const hasMoreToLoad = contentCounter > content.length;

  const additionalData = useMemo(
    () => ({
      category,
      keywords: searchKeyword,
    }),
    [category, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchPlansAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue, setSearchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  useEffect(() => {
    setSearchKeyword("");
    setSearchValue("");
    setPage(0);
  }, [category]);

  return (
    <>
      <PageHeader pageName="planDrawer">
        <Search value={searchValue} placeholder="Search plans" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={PLAN_DRAWER.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Development</Tab>
          <Tab>Project</Tab>
          <Tab>Floorplan</Tab>
        </TabsList>
        <TabPanel value={0}>
          {
            <PlanDrawerList
              type="development"
              content={development}
              setCategory={setCategory}
            />
          }
        </TabPanel>
        <TabPanel value={1}>
          {
            <PlanDrawerList
              type="project"
              content={project}
              setCategory={setCategory}
            />
          }
        </TabPanel>
        <TabPanel value={2}>
          {
            <PlanDrawerList
              type="floorplan"
              content={floorplan}
              setCategory={setCategory}
            />
          }
        </TabPanel>
      </TabsUnstyled>

      <PlanDrawerCreate category={category}/>
      <PlanDrawerUpdate category={category}/>
      <PlanDrawerDelete category={category}/>
    </>
  );
};

export default withLayout(PlanDrawer);
