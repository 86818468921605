import { FC } from "react";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import { Grid, Typography, useTheme } from "@mui/material";
import { getFormattedDateTime, formatPrice } from "resource/utils";
import { ChangeOrderDetailListItem } from "resource/interfaces/change-order";
import { useSelector, RootStateOrAny } from "react-redux";

interface ChangeOrderDetailListProp {
  items: any;
}

const ChangeOrderDetailList: FC<ChangeOrderDetailListProp> = ({ items }) => {
  const theme = useTheme();
  const list: ChangeOrderDetailListItem[] = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrderLists);

  return (
    <>
      <TableListHeader>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Item</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color={theme.palette.primary.main}>Description</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Amount</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Updated by</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
      </TableListHeader>

      {items.length > 0 &&
        items.map((it, index: number) => (
          <div key={index}>
            <TableListRow>
              <Grid item xs={1.5}>
                <Typography color={theme.palette.text.primary}>{list[it].item}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color={theme.palette.text.primary}>{list[it].description}</Typography>
              </Grid>
              <Grid item xs={1.5} justifyContent="flex-end" display="flex">
                <Typography color={theme.palette.text.primary}>{formatPrice(String(list[it].price))}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                  {list[it].modiby}
                </Typography>
              </Grid>
              <Grid item xs={1.5} justifyContent="flex-end" display="flex">
                <Typography color={theme.palette.text.primary}>{getFormattedDateTime(list[it].modidate)}</Typography>
              </Grid>
            </TableListRow>
          </div>
        ))}
    </>
  );
};

export default ChangeOrderDetailList;
