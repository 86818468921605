import { Container, Typography, useTheme, Box, Divider } from "@mui/material";
import LandingNavigation from "pages/landing/LandingNavigation";
import AppBar from "components/app-bar/AppBar";
import checkIcon from "resource/images/tick-mark.svg";

const LandingSuccess = () => {
  const theme = useTheme();
  const date = new Date();

  return (
    <>
      <AppBar navigation={<LandingNavigation />} />

      <Container maxWidth="xl" color={theme.palette.secondary.light}>
        <Box sx={{ height: "70vh", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <img src={checkIcon} alt="tick icon" style={{ width: "60px", marginBottom: "20px" }} />
          <Typography variant="h1">Payment is approved</Typography>
          <Divider sx={{ width: "50%", my: 3 }} />
          <Typography variant="h3">
            № of payment in payment system:{" "}
            <Typography component="span" variant="h2">
              {date.getMilliseconds()}
            </Typography>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default LandingSuccess;
