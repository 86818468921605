import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPlanTakeoffsAction, clearDataAction } from "redux-store/redux-actions/planTakeoffs";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import PlanTakeoffList from "./PlanTakeoffList";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import FilterSelectWithSearch from "components/shared/filter-select/FilterSelectWithSearch";

// ? RESOURCES
import { PLAN_TAKEOFFS_NAME, planTakeOffFilterList } from "resource/constants/takeoffs";
import { COMPANIES_API } from "resource/constants/api";

const PlanTakeoffs = () => {
  const dispatch = useDispatch();
  const planTakeoffIds: number[] = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffIds);
  const planTakeoffs = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffItems);
  const planTakeoffsCount: number = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffsCount);
  const hasMoreToLoad: boolean = planTakeoffsCount > planTakeoffIds.length;
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [select, setSelect] = useState(planTakeOffFilterList[0]);
  const [idcia, setCompanyID] = useState(0);

  const additionalData = useMemo(
    () => ({
      status: select,
      keywords: searchKeyword,
      idcia,
    }),
    [select, searchKeyword, idcia]
  );

  const { setPage } = useInfiniteLoading(fetchPlanTakeoffsAction, hasMoreToLoad, paginationLoading, additionalData);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // COMPANY SERACH
  const companyHandleChange = (value) => {
    setCompanyID(value !== null ? value.value : 0);
    setPage(0);
    dispatch(clearDataAction());
  };
  // = = =

  return (
    <>
      <PageHeader pageName={PLAN_TAKEOFFS_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={planTakeOffFilterList} />
        <FilterSelectWithSearch handleChange={companyHandleChange} api={`${COMPANIES_API}/list`} />
        <Search value={searchKeyword} placeholder="Search Plan Takeoff" handleChange={handleSearchChange} />
      </PageHeader>
      {planTakeoffIds.length <= 0 && !paginationLoading && <NoDataScreen pageName={PLAN_TAKEOFFS_NAME} />}
      {planTakeoffIds.length > 0 && <PlanTakeoffList planTakeoffIds={planTakeoffIds} planTakeoffs={planTakeoffs} />}

      {paginationLoading ? (
        <div className={`LoaderScroll ${!planTakeoffIds.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(PlanTakeoffs);
