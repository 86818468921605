import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import FileServerClipIcon from "components/shared/file-server/FileServerClipIcon";

import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";

// ? STYLES
import styles from "./Projects.module.css";

interface ProjectsItemsProps {
  project: any;
}

const ProjectsItem: FC<ProjectsItemsProps> = ({ project }) => {
  const theme = useTheme();
  const mainPath = ROUTES.projectPage.pathname;
  const subDirectories = [project.idcia, project.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1} display="flex">
          <FileServerClipIcon fs={project.fs} />
          <Typography sx={{ marginLeft: "12px" }} color={theme.palette.text.primary}>
            {project.idnumber}
          </Typography>
        </Grid>
        <Grid item xs={1} display="flex">
          <Typography color={theme.palette.text.primary}>{project.idcia}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.text.primary}>{project.name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{project.geoarea_name}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{project.planname}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{project.revision}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{project.sqft}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{project.takeoffsqft}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{project.takeoff}</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.text.primary}>{project.status}</Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default ProjectsItem;
