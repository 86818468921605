// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";

export const VendorsPricingDetailsListHeader = () => {
  const theme = useTheme();

  return (
    <TableListHeader>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Item No</Typography>
      </Grid>
      <Grid item xs={5.5}>
        <Typography color={theme.palette.primary.main}>Description</Typography>
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Typography color={theme.palette.primary.main}>Price</Typography>
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Typography color={theme.palette.primary.main}>SKU</Typography>
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Typography color={theme.palette.primary.main}>Date</Typography>
      </Grid>
      <Grid item xs={1} justifyContent="flex-end" display="flex">
        <Typography color={theme.palette.primary.main}>Actions</Typography>
      </Grid>
    </TableListHeader>
  );
};
