import React from "react";
import AppBar from "components/app-bar/AppBar";
import { Container, Typography, useTheme, Box } from "@mui/material";
import LandingNavigation from "./LandingNavigation";
import LandingHero from "./LandingHero";
import LandingBlock from "./LandingBlock";
import LandingFooter from "./LandingFooter";
import blockAccounting from "resource/images/block-1.svg";
import blockManagement from "resource/images/block-2.png";
import blockPurchasing from "resource/images/block-3.png";
import decorImage from "resource/images/moto-decor.svg";
import motoSignUp from "resource/images/moto-signup.png";
import styles from "./Landing.module.css";
import { ROUTES } from "resource/routes/routes";
import { Link } from "react-router-dom";

const Landing = () => {
  const theme = useTheme();

  return (
    <>
      <AppBar navigation={<LandingNavigation />} />

      <Container maxWidth="xl" color={theme.palette.secondary.light}>
        <LandingHero />
      </Container>
      <Box className={`${styles.Moto} ${styles.Decor}`} sx={{ backgroundImage: `url(${decorImage})` }}>
        <Typography
          sx={{ width: "50%", mb: 2, fontWeight: "600", fontSize: "44px" }}
          variant="h1"
          color={theme.palette.background.default}
        >
          Simplicity is key
        </Typography>
        <Typography sx={{ width: "40%", lineHeight: "140%" }} variant="h1" color={theme.palette.background.default}>
          Handle accounting, project management, purchasing and estimating — with an interface simple enough for anyone to use.
        </Typography>
      </Box>

      <Container maxWidth="xl" color={theme.palette.secondary.light}>
        <LandingBlock
          bgImage={blockAccounting}
          heading="Accounting automation that can handle anything you throw at it"
          category="Accounting"
          text="Construction projects need accounting tools that can work with lots of corporate entities, batch reconciliation, check management, and more. 
          Buildersaccess makes this simple."
        />
        <LandingBlock
          bgImage={blockManagement}
          heading="Project management designed to help you focus on the work, not the “management” part."
          category="Project management"
          text="Designed for construction professionals, always know what’s up next without wrangling tricky task management tools like Asana or JIRA."
          reversed={true}
        />
        <LandingBlock
          bgImage={blockPurchasing}
          heading="Keep track of your spending, and always know the cost to finish a job."
          category="Purchasing and estimating"
          text="As projects go on and unexpected costs mount, ensure your project remains profitable with Buildersaccess’s spending management automation tools."
        />
      </Container>
      <Box className={styles.Moto} sx={{ backgroundImage: `url(${motoSignUp})` }}>
        <Typography
          sx={{ width: "50%", mb: 2, fontWeight: "600", fontSize: "44px" }}
          variant="h1"
          color={theme.palette.background.default}
        >
          Sign up for Buildersaccess today
        </Typography>
        <Link to={ROUTES.signup.pathname} className={`${styles.ButtonLight} ${styles.Button}`}>
          <Typography variant="h2">Get started now</Typography>
        </Link>
      </Box>
      <LandingFooter />
    </>
  );
};

export default Landing;
