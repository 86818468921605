import { FC } from "react";

// ? COMPONENTS
import { Field, ErrorMessage } from "formik";
import { MenuItem } from "@mui/material";

import { Typography, useTheme, Grid, FormGroup, InputLabel, TextField, Select } from "@mui/material";

import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? STYLES
import styles from "./FormField.module.css";

// ? TYPES
interface FieldPropsTypes {
  size: string;
  title: string;
  name: string;
  placeholder: string | undefined;
  type: string;
  options?: { label: string; value: string | number }[] | undefined;
  disabled?: boolean;
}

export const FormField: FC<FieldPropsTypes> = ({ size, title, name, placeholder, type, options, disabled }) => {
  const theme = useTheme();

  return (
    <Grid item style={{ width: size }}>
      <FormGroup className={styles.FormField}>
        <InputLabel htmlFor={name}>
          <Typography variant="body1" color={theme.palette.info.main}>
            {title}
          </Typography>
        </InputLabel>
        {type !== "select" && (
          <Field as={TextField} id={name} name={name} placeholder={placeholder} type={type} disabled={disabled} />
        )}
        {type === "select" && (
          <Field as={Select} fullWidth id={name} name={name} label={placeholder} disabled={disabled}>
            {options &&
              options.length > 0 &&
              options.map((option, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Field>
        )}
        <ErrorMessage name={name}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
      </FormGroup>
    </Grid>
  );
};
