import { useState, useEffect, FC } from "react";

// ? REDUX
import { addNewUserAction } from "redux-store/redux-actions/membersProfile";
import { useSelector, RootStateOrAny } from "react-redux";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { MemberProfileForm } from "pages/members-profile/MemberProfileForm";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/members-profile/MembersProfile.module.css";

// ? RESOURCES
import { MEMBERS_PROFILE, MEMBERS_PROFILE_NAME } from "resource/constants/members-profile";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { newMembersProfileSchema } from "resource/schemas/membersProfileSchema";

export const nameCreate = `${MEMBERS_PROFILE_NAME}/${ActionName.CREATE}`;

const MembersCreate: FC = () => {
  const { close, isOpened } = useModal(nameCreate);
  const [isLoading, setLoader] = useState(false);
  const createUser = useAsync(addNewUserAction);
  const [setNotice] = useNotice();
  // STORE
  const status = useSelector(
    (state: RootStateOrAny) => state.members.options.filter((option) => option.field === "inactiveyn")[0].list
  );
  const companies = useSelector((state: RootStateOrAny) => state.members.usersCompanies);

  const initialFormValues = {
    iddeptos: 1,
    fname: null,
    lname: null,
    email: null,
    password: null,
    confirm_password: null,
    title: null,
    inactiveyn: status[0].value,
    address: null,
    city_st: null,
    zipcode: null,
    poauth: null,
    mobile: null,
    office: null,
    telhome: null,
    photo: null,
  };

  // COMPANY ACCESS LIST
  const [localCiaList, setLocalCiaList] = useState({});

  useEffect(() => {
    const tList = {};
    if (companies.length > 0) {
      companies.forEach((cia) => {
        tList[cia.idnumber] = { idcia: cia.idnumber, name: cia.name, xview: false, xfinancial: false };
      });
    }
    setLocalCiaList(tList);
  }, [companies]);
  //  = =  =

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      data.photoUrl = null;
      data.itemList = Object.values(localCiaList);
      await createUser({
        values: data,
        actionType: ActionName.CREATE,
      });
      setNotice("User created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.Form}>
          <MemberProfileForm
            title={MEMBERS_PROFILE.NEW_MODAL_TITLE}
            buttonText={MEMBERS_PROFILE.NEW_MODAL_SUBMIT_BUTTON}
            formData={initialFormValues}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            validation={newMembersProfileSchema}
            data={{ localCiaList, setLocalCiaList }}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default MembersCreate;
