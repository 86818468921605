import { useState } from "react";

// ? REDUX
import { voidPurchaseOrderAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Typography, useTheme, TextField, InputLabel, FormGroup } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import closeButtonIcon from "resource/images/close-btn.svg";
import { purchaseOrderVoidSchema } from "resource/schemas/purchaseOrderSchema";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

export const nameConfirmVoid = `${PURCHASE_ORDER_NAME}/confirmVoid`;

const initialValues = {
  voidreason: "",
  voidnotes: "",
};

const PurchaseOrderVoidConfirm = () => {
  const theme = useTheme();

  const { close, data } = useModal(nameConfirmVoid);
  const [isLoading, setLoader] = useState<boolean>(false);

  const voidPurchaseOrder = useAsync(voidPurchaseOrderAction);

  const handleVoidPO = async (formData: { voidreason: string; voidnotes: string }) => {
    setLoader(true);
    try {
      await voidPurchaseOrder({
        data: {
          ...formData,
          idnumber: data.idnumber,
          idtrack: data.idtrack,
        },
        actionType: PurchaseOrderEndpoints.VOID,
      });
      setLoader(false);
      close();
      window.open(`mailto:?subject=${formData.voidreason}&body=${formData.voidnotes}`);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameConfirmVoid}>
      <Box className={styles.PartsConfirm}>
        <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
          <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
            {`Void PO-${data.iddoc}?`}
          </Typography>
          <IconButton onClick={close}>
            <img src={closeButtonIcon} alt="close-button" />
          </IconButton>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleVoidPO(values);
          }}
          validationSchema={purchaseOrderVoidSchema}
        >
          <Form style={{ padding: "20px  0 0" }}>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="voidreason">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Void Reason
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="voidreason" id="voidreason" placeholder="Enter Void Reason" />
                <ErrorMessage name="voidreason">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="voidnotes">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Void notes
                  </Typography>
                </InputLabel>
                <Field
                  className="Textarea"
                  type="textarea"
                  name="voidnotes"
                  id="voidnotes"
                  as={TextField}
                  placeholder="Enter brief void notes here"
                  multiline
                  minRows={5}
                />
                <ErrorMessage name="voidnotes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Box className="FormFooter">
              <SubmitButton text="Void Purchase Order" isLoading={isLoading} />
            </Box>
          </Form>
        </Formik>
      </Box>
    </ModalManager>
  );
};

export default PurchaseOrderVoidConfirm;
