import * as Yup from "yup";

export const newMembersProfileSchema = Yup.object().shape({
  fname: Yup.string().required("Required").nullable(),
  lname: Yup.string().required("Required").nullable(),
  email: Yup.string().required("Required").nullable(),
  password: Yup.string().required("Required").nullable(),
  confirm_password: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Passwords don't match")
    .nullable(),
  inactiveyn: Yup.boolean().required("Required").nullable(),
});

export const editMembersProfileSchema = Yup.object().shape({
  fname: Yup.string().required("Required").nullable(),
  lname: Yup.string().required("Required").nullable(),
  email: Yup.string().required("Required").nullable(),
  inactiveyn: Yup.boolean().required("Required").nullable(),
});
