// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";

export const PlanDrawerListHeader = () => {
  const theme = useTheme();

  return (
    <TableListHeader>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Field ID</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography color={theme.palette.primary.main}>Field Name</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Field Type</Typography>
      </Grid>
      <Grid item xs={1} justifyContent="flex-end" display="flex">
        <Typography color={theme.palette.primary.main}>Actions</Typography>
      </Grid>
    </TableListHeader>
  );
};
