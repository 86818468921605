import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Grid, Typography, useTheme } from "@mui/material";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";

// ? STYLES
import pageHeaderStyles from "components/page-header/PageHeader.module.css";

interface JobCostDetailHeaderProps {
  idnumber: any;
}

const JobCostDetailHeader: FC<JobCostDetailHeaderProps> = ({ idnumber }) => {
  const theme = useTheme();

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.jobCostReport.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${idnumber} `}
            </Typography>
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your job costs details will be shown below
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default JobCostDetailHeader;
