import { FC } from "react";
// ? COMPONENTS
import { Button, Box, Typography } from "@mui/material";
import styles from "./ActionButton.module.css";

interface ButtonType {
  color: string;
  text: string;
  action: string;
}

interface ActionButtonProps {
  handleActionsButton: (data: string) => void;
  button: ButtonType;
  isLoading: boolean;
  buttonType?: any;
}

const ActionButton: FC<ActionButtonProps> = ({ handleActionsButton, button, isLoading, buttonType = "button" }) => {
  return (
    <Box sx={{ mr: "15px" }}>
      <Button
        variant={"outlined"}
        className={`${styles.ActionButton}`}
        onClick={() => handleActionsButton(`${button.action}`)}
        sx={{
          bgcolor: `${button.color}`,
          borderColor: `${button.color}`,
          "&:hover": { bgcolor: `${button.color}`, borderColor: `${button.color}` },
        }}
        disabled={isLoading}
        type={buttonType}
      >
        <Typography>{button.text}</Typography>
      </Button>
    </Box>
  );
};

export default ActionButton;
