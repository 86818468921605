import { useState } from "react";

// ? REDUX
import { addNewChangeOrderAction } from "redux-store/redux-actions/changeOrders";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import ChangeOrderCreateForm from "pages/change-order/ChangeOrderCreateForm";

// ? RESOURCES
import { CHANGE_ORDERS_NAME, ChangeOrderEndpoints, CHANGE_ORDERS } from "resource/constants/change-order";
import { ActionName } from "resource/constants/api";

export const nameCreate = `${CHANGE_ORDERS_NAME}/${ActionName.CREATE}`;

const initialFormValues = {
  idcia: 0,
  idproject1: "",
  status: "Draft",
  notes: "",
  agreeyn: false,
  coPriceList: [
    {
      description: "",
      price: 0,
    },
  ],
};

const ChangeOrderCreate = ({ className }) => {
  const { close, isOpened } = useModal(nameCreate);

  const [isLoading, setLoader] = useState<boolean>(false);
  const createChangeOrder = useAsync(addNewChangeOrderAction);

  const handleSubmit = async (values) => {
    setLoader(true);

    try {
      await createChangeOrder({
        values,
        actionType: ChangeOrderEndpoints.CREATE,
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {isOpened && (
        <ModalManager name={nameCreate} className={className}>
          <ChangeOrderCreateForm
            title={CHANGE_ORDERS.NEW_MODAL_TITLE}
            handleSubmit={handleSubmit}
            formData={initialFormValues}
            handleClose={close}
            isLoading={isLoading}
          />
        </ModalManager>
      )}
    </>
  );
};

export default ChangeOrderCreate;
