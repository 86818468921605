import React, { useState, FC } from "react";

// ? COMPONENTS
import { Formik, Form, ErrorMessage, Field } from "formik";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  useTheme,
  TextField,
  InputLabel,
  FormGroup,
  CircularProgress,
  Autocomplete,
  Alert,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { useSearch } from "custom-hooks/useSearch";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { COST_CODES_API } from "resource/constants/api";
import { planTakeoffBudgetSchema } from "resource/schemas/planTakeoffSchema";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";

interface TakeoffsBudgetFormProps {
  title: string;
  buttonText: string;
  handleClose: () => void;
  handleSubmit: (data: any) => void;
  formData: any;
  actionType: string;
  isLoading: boolean;
}

const TakeoffsBudgetForm: FC<TakeoffsBudgetFormProps> = ({
  title,
  buttonText,
  handleSubmit,
  formData,
  handleClose,
  isLoading,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading] = useState(false);
  const [budget, setBudget] = useState<null | any>(null);
  const [optionsBudgets, setQueryBudgets, isPending, initialConditionBudgets, errorMessageBudgets] = useSearch(COST_CODES_API);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={(values) => {
        handleSubmit({
          idcostcode: budget.code,
          budgetrevised: values.budgetrevised,
          notes: values.notes,
        });
      }}
      validationSchema={planTakeoffBudgetSchema}
    >
      {({ setFieldValue, handleBlur }) => {
        return (
          <>
            {errorMessageBudgets && <Alert severity="error">{errorMessageBudgets}</Alert>}
            <Form className={styles.Form}>
              <div className="FormHeader">
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>

              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <FormGroup>
                    <InputLabel htmlFor="upc">
                      <Typography variant="body1" color={theme.palette.info.main}>
                        Add Cost Code
                      </Typography>
                    </InputLabel>
                    <Autocomplete
                      className={styles.SearchField}
                      id="idcostcode"
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      options={optionsBudgets}
                      getOptionLabel={(option: any) => option["name"]}
                      loading={loading}
                      noOptionsText={!initialConditionBudgets ? "Start typing" : "No options"}
                      onChange={(evt, value: any) => {
                        setBudget(value);
                        setFieldValue("idcostcode", budget ? budget.code : 0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={styles.SearchFieldInput}
                          placeholder="Search cost code"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setQueryBudgets(e.target.value);
                          }}
                          name="idcostcode"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <ErrorMessage name="idcostcode">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                  </FormGroup>
                </Grid>
                <Grid item sm={6}>
                  <FormGroup>
                    <InputLabel htmlFor="number">
                      <Typography variant="body1" color={theme.palette.info.main}>
                        Budget Revised
                      </Typography>
                    </InputLabel>
                    <Field
                      as={TextField}
                      type="number"
                      name="budgetrevised"
                      id="budgetrevised"
                      placeholder="Enter budget revised"
                    />
                    <ErrorMessage name="budgetrevised">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                  </FormGroup>
                </Grid>
                <Grid item sm={12}>
                  <FormGroup>
                    <InputLabel htmlFor="notes">
                      <Typography variant="body1" color={theme.palette.info.main}>
                        Notes
                      </Typography>
                    </InputLabel>
                    <Field
                      className="Textarea"
                      type="textarea"
                      name="notes"
                      id="notes"
                      as={TextField}
                      placeholder="Enter notes here"
                      multiline
                      minRows={5}
                    />
                    <ErrorMessage name="notes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                  </FormGroup>
                </Grid>
              </Grid>
              <Box className="FormFooter">
                <SubmitButton text={buttonText} isLoading={isLoading} />
              </Box>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default TakeoffsBudgetForm;
