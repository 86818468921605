import { FC } from "react";
import { Link } from "react-router-dom";
import { Grid, useTheme, Typography, Box } from "@mui/material";
import styles from "./Landing.module.css";
import { ROUTES } from "resource/routes/routes";

interface LandingBlockProps {
  bgImage: string;
  text: string;
  heading: string;
  category: string;
  reversed?: boolean;
}

const ImageBlock = ({ bgImage }) => {
  return (
    <Grid item md={6}>
      <img src={bgImage} alt="Interface preview" style={{ width: "100%", height: "100%" }} />
    </Grid>
  );
};

const LandingBlock: FC<LandingBlockProps> = ({ bgImage, text, heading, category, reversed }) => {
  const theme = useTheme();
  return (
    <Grid container sx={{ p: 10 }}>
      {!reversed && <ImageBlock bgImage={bgImage} />}

      <Grid item md={6}>
        <Typography className={styles.BlockCategory}>{category}</Typography>
        <Typography className={styles.BlockHeading}>{heading}</Typography>
        <Typography className={styles.BlockText}>{text}</Typography>
        <Box sx={{ width: "fit-content" }}>
          <Link to={ROUTES.signup.pathname}>
            <Typography className={styles.BlockLink} variant="h2" color={theme.palette.primary.light}>
              Sign up
            </Typography>
          </Link>
        </Box>
      </Grid>
      {reversed && <ImageBlock bgImage={bgImage} />}
    </Grid>
  );
};

export default LandingBlock;
