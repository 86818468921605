import { takeLatest, put } from "redux-saga/effects";
import { PAYMENT_SUMMARY_API } from "resource/constants/api";
import { get, catchError } from "./_utils-abstract-saga-network";

import { PaymentSummaryActions, putPaymentSummaryDetailsAction, putPaymentSummaryAction } from "../redux-actions/paymentSummary";

import { setPaginationLoaderAction } from "../redux-actions/loaders";

function* fetchPaymentSummaryWorker(action) {
  const {
    payload: {
      additionalData: { filter, keywords, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PAYMENT_SUMMARY_API}/list`, {
    ...params,
    ...(idcia && { idcia }),
    ...(keywords && { keywords }),
    ...(filter && { filter }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putPaymentSummaryAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPaymentSummaryDetailsWorker(action) {
  const {
    payload: {
      additionalData: { filter, keywords, idcia, idproject },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idcia, idproject };

  const { errno, message, data } = yield get(`${PAYMENT_SUMMARY_API}/projectPSList`, {
    ...params,
    ...(keywords && { keywords }),
    ...(filter && { filter }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putPaymentSummaryDetailsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

export function* paymentSummarySaga() {
  yield takeLatest(PaymentSummaryActions.FETCH_PAYMENT_SUMMARY, fetchPaymentSummaryWorker);
  yield takeLatest(PaymentSummaryActions.FETCH_PAYMENT_SUMMARY_DETAILS, fetchPaymentSummaryDetailsWorker);
}
