import { useState, useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { editUserAction, fetchEditUserAction } from "redux-store/redux-actions/membersProfile";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { MemberProfileForm } from "pages/members-profile/MemberProfileForm";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { MEMBERS_PROFILE, MEMBERS_PROFILE_NAME } from "resource/constants/members-profile";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { editMembersProfileSchema } from "resource/schemas/membersProfileSchema";

export const nameUpdate = `${MEMBERS_PROFILE_NAME}/${ActionName.UPDATE}`;

const MembersUpdate: FC = () => {
  const dispatch = useDispatch();

  const { close, isOpened, data } = useModal(nameUpdate);
  const [isLoading, setLoader] = useState(false);
  const updateUser = useAsync(editUserAction);
  const [setNotice] = useNotice();

  // STORE
  const user = useSelector((state: RootStateOrAny) => state.members.editUser);
  const companies = useSelector((state: RootStateOrAny) => state.members.usersCompanies);

  // COMPANY ACCESS LIST
  const [localCiaList, setLocalCiaList] = useState({});

  useEffect(() => {
    const tList = {};
    if (companies.length > 0) {
      companies.forEach((cia) => {
        tList[cia.idcia] = { idcia: cia.idcia, name: cia.name, xview: cia.xview, xfinancial: cia.xfinancial };
      });
    }
    setLocalCiaList(tList);
  }, [companies]);
  //  = =  =

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      data.photoUrl = null;
      data.itemList = Object.values(localCiaList);
      await updateUser({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setNotice("User updated", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };
  // = = =

  useEffect(() => {
    if (isOpened) {
      dispatch(fetchEditUserAction({ username: data.username }));
    }
  }, [isOpened, dispatch, data.username]);

  return (
    <ModalManager name={nameUpdate} className={styles.Modal}>
      {isOpened && user ? (
        <div className={styles.DevelopmentsAddForm}>
          <MemberProfileForm
            title={MEMBERS_PROFILE.EDIT_MODAL_TITLE}
            buttonText={MEMBERS_PROFILE.EDIT_MODAL_SUBMIT_BUTTON}
            formData={user}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            validation={editMembersProfileSchema}
            data={{ localCiaList, setLocalCiaList }}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default MembersUpdate;
