import { FC } from "react";
import { Select, MenuItem, Box } from "@mui/material";
import { capitalizeChar } from "resource/utils";

import styles from "./FilterSelect.module.css";

interface FilterSelectProps {
  options: string[];
  handleChange: (evt) => void;
  select: string;
}

const FilterSelect: FC<FilterSelectProps> = ({ options, handleChange, select }) => {
  return (
    <Box className={styles.Filter}>
      <Select className={styles.FilterSelect} onChange={handleChange} value={select}>
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {capitalizeChar(option)}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default FilterSelect;
