import { FC } from "react";

// ? COMPONENTS
import useModal from "custom-hooks/useModal";
import ModalManager from "../ModalManager";
import PurchaseOrderLumberForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderLumberForm";

//? RESOURCES
import { PURCHASE_ORDER_NAME } from "resource/constants/purchase-order";

interface PurchaseOrderLumberInfoProps {
  handleLumberSubmit: (value: { lumber: number }) => void;
}

export const nameLumber = `${PURCHASE_ORDER_NAME}/lumber`;

const PurchaseOrderLumberInfo: FC<PurchaseOrderLumberInfoProps> = ({ handleLumberSubmit }) => {
  const { close, data } = useModal(nameLumber);

  return (
    <ModalManager name={nameLumber}>
      <PurchaseOrderLumberForm
        handleSubmit={handleLumberSubmit}
        buttonText="Save Lumber"
        handleClose={close}
        description={data.description}
      />
    </ModalManager>
  );
};

export default PurchaseOrderLumberInfo;
