import { call, put, select, delay } from "redux-saga/effects";
import { removeUserDataAction } from "redux-store/redux-actions/login";
import { setNoticeAction, clearNotifierAction } from "redux-store/redux-actions/notifier";

import { preprocessData } from "resource/utils";
import { ACCEPT_CONTENT_TYPE } from "resource/consts";
import { noticeTypes, ERROR_MESSAGE_DELAY_TIME } from "resource/consts";

function* request(url: string, params: RequestInit = {}) {
  const { headers = {}, ...options } = params;
  const { tokenAPI, tokenSecret } = yield select((state) => state.login.userData);

  const response = yield call(fetch, url, {
    ...options,
    headers: {
      ...headers,
      Accept: "application/json",
      "Token-Api": tokenAPI,
      "Token-Secret": tokenSecret,
    },
  });

  const { errno, message, data } = yield response.json();

  if (errno === 10005) {
    yield put(removeUserDataAction());
  }

  return { errno, message, data };
}

export function* get(url: string, data = {}, params: RequestInit = {}) {
  const { headers = {}, ...options } = params;

  return yield request(`${url}?${new URLSearchParams(data).toString()}`, {
    method: "GET",
    headers,
  });
}

export function* post(url: string, data, contentType: string, params: RequestInit = {}) {
  const { headers = {}, ...options } = params;

  return yield request(`${url}`, {
    method: "POST",
    body: preprocessData(contentType, data),
    headers: {
      "Content-Type": ACCEPT_CONTENT_TYPE[contentType],
    },
  });
}

export function* catchError(message: string) {
  yield put(setNoticeAction({ message, type: noticeTypes.error }));
  yield delay(ERROR_MESSAGE_DELAY_TIME);
  yield put(clearNotifierAction());
}
