import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchJobCostsAction, clearJobCostDataAction } from "redux-store/redux-actions/jobCost";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import JobCostList from "./JobCostList";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

// ? RESOURCES
import { JOB_COST_NAME, jobCostFiltersList } from "resource/constants/job-cost";

const JobCost = () => {
  const dispatch = useDispatch();
  const jobCosts = useSelector((state: RootStateOrAny) => state.jobCosts.jobCosts);
  const jobCostsCount = useSelector((state: RootStateOrAny) => state.jobCosts.jobCostsCount);
  const hasMoreToLoad = jobCostsCount > jobCosts.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(jobCostFiltersList[0]);

  const additionalData = useMemo(
    () => ({
      filter: select,
      keywords: searchKeyword,
    }),
    [select, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchJobCostsAction, hasMoreToLoad, paginationLoading, additionalData);

  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearJobCostDataAction());
  };

  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearJobCostDataAction());
  };

  return (
    <>
      <PageHeader pageName={JOB_COST_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={jobCostFiltersList} />
        <Search value={searchKeyword} placeholder="Search job cost report" handleChange={handleSearchChange} />
      </PageHeader>

      {jobCosts.length <= 0 && !paginationLoading && <NoDataScreen pageName={JOB_COST_NAME} />}
      {jobCosts.length > 0 && <JobCostList jobCosts={jobCosts} loading={paginationLoading} />}
      {paginationLoading ? (
        <div className={`LoaderScroll ${!jobCosts.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(JobCost);
