import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { TableListDivider } from "components/shared/table-list/TableList";
import { Grid, Typography, useTheme } from "@mui/material";
import { formatPrice } from "resource/utils";
import ProjectTakeoffBudgetItem from "pages/takeoffs/project-takeoff/ProjectTakeoffBudgetItem";
import PlanTakeoffBudgetItem from "pages/takeoffs/plan-takeoff/PlanTakeoffBudgetItem";
import { TAKEOFFS } from "resource/constants/takeoffs";
import { BudgetInterface } from "resource/interfaces/plan-takeoff";

const getBudgetAreas = (budgets, ids: number[]) => {
  const areas = new Set();
  Object.keys(budgets).forEach((key) => {
    if (ids.includes(Number(key))) {
      areas.add(budgets[key].area);
    }
  });
  return Array.from(areas);
};

interface TakeoffsBudgetListProps {
  takeoffBudget: any;
  takeoffId: any;
  takeoffType: string;
  takeoffIdcia?: any;
}

const TakeoffsBudgetList: FC<TakeoffsBudgetListProps> = ({ takeoffBudget, takeoffId, takeoffType, takeoffIdcia }) => {
  const theme = useTheme();
  const projectBudgets = useSelector((state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffBudget);
  const planBudgets = useSelector((state: RootStateOrAny) => state.planTakeoffs.planTakeoffBudget);
  let areas: any[];
  let budgets: BudgetInterface[];

  if (takeoffType === TAKEOFFS.PLAN_TITLE) {
    areas = getBudgetAreas(planBudgets, takeoffBudget);
    budgets = planBudgets;
  } else {
    areas = getBudgetAreas(projectBudgets, takeoffBudget);
    budgets = projectBudgets;
  }

  return (
    <>
      {areas.map((area: any) => (
        <div key={area}>
          {takeoffBudget
            .filter((id: number) => budgets[id].area === area)
            .map((item: number) => (
              <div key={budgets[item].code}>
                {takeoffType === TAKEOFFS.PLAN_TITLE ? (
                  <PlanTakeoffBudgetItem takeoffId={takeoffId} budget={budgets[item]} />
                ) : (
                  <ProjectTakeoffBudgetItem takeoffId={takeoffId} budget={budgets[item]} takeoffIdcia={takeoffIdcia} />
                )}
              </div>
            ))}
          <TableListDivider>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography color={theme.palette.text.primary}>{area}</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(
                  takeoffBudget
                    .reduce(
                      (prev: number, item: number) => (budgets[item].area === area ? prev + budgets[item].budget : prev + 0),
                      0
                    )
                    .toFixed(2)
                )}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(
                  takeoffBudget
                    .reduce(
                      (prev: number, item: number) =>
                        budgets[item].area === area ? prev + budgets[item].budgetrevised : prev + 0,
                      0
                    )
                    .toFixed(2)
                )}
              </Typography>
            </Grid>
          </TableListDivider>
        </div>
      ))}

      <TableListDivider>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}></Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h3" color={theme.palette.text.primary}>
            Total
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>
            {formatPrice(takeoffBudget.reduce((prev: number, item: number) => prev + budgets[item].budget, 0).toFixed(2))}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>
            {formatPrice(takeoffBudget.reduce((prev: number, item: number) => prev + budgets[item].budgetrevised, 0).toFixed(2))}
          </Typography>
        </Grid>
      </TableListDivider>
    </>
  );
};

export default TakeoffsBudgetList;
