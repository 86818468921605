export const PROJECT_TAKEOFFS_NAME = "projectTakeoffs";
export const PLAN_TAKEOFFS_NAME = "planTakeoffs";

export const TAKEOFFS = {
  PROJECT_TITLE: "Project Takeoffs",
  PLAN_TITLE: "Plan Takeoffs",
  ADD_PART: "Add Part",
  ADD_ASSEMBLY: "Add Assembly",
  ADD_BUDGET: "Add Budget",
  ADD_PLAN: "Add Plan",
  ADD_PROJECT: "Add Project",
  ADD_VENDOR: "Add Vendor",
  IMPORT_PLAN: "Import Plan",
  IMPORT_PROJECT: "Import Project",
};

export const planTakeOffFilterList = ["-All-", "Active", "Under Revision", "Inactive"];
export const projectTakeOffFilterList = ["-All-", "Active", "Specs", "Sold", "Closed"];

export enum TakeoffEndpoints {
  AddAssembly = "addAssembly",
  UpdateAssemblyNotes = "updateAssemblyNotes",
  UpdateAssemblyStatus = "updateStatusAssembly",
  DeleteAssembly = "deleteAssembly",
  DeletePlanTakeoff = "delete",
  UpdateBudget = "updateBudget",
  AddBudget = "addBudget",
  DeleteBudget = "deleteBudget",
  DeletePart = "deletePart",
  AddPart = "addPart",
  UpdatePart = "updatePart",
  DeleteProjectTakeoff = "delete",
  ImportFromPlan = "importFromPlan",
  ImportFromProject = "importFromProject",
  AssignVendor = "assignVendor",
  ReleaseTakeoff = "releaseTakeoff",
}

export enum Statuses {
  Released = "Released",
  Completed = "Completed",
}
