import { takeLatest, put } from "redux-saga/effects";
import { USERS_PROFILE_API, COMPANIES_API, MASTER_ROLES_API } from "resource/constants/api";
import { get, post, catchError } from "./_utils-abstract-saga-network";

import {
  MembersActions,
  getUserAction,
  putUserAction,
  getEditUserAction,
  getUsersAction,
  fetchUserAction,
  deleteUserStoreAction,
  putUsersCompaniesAction,
} from "../redux-actions/membersProfile";

import {
  setButtonLoaderAction,
  setModalLoaderAction,
  setPaginationLoaderAction,
  setAddNewLoaderAction,
} from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchUsersWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords, status },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${USERS_PROFILE_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(status && { status }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getUsersAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchUserWorker(action) {
  const {
    payload: { username, mode },
  } = action;

  if (mode === "create") {
    yield put(setAddNewLoaderAction(true));
  }

  const { errno, message, data } = yield get(`${USERS_PROFILE_API}/${username}`);

  if (errno !== 0) {
    yield catchError(message);
    yield put(setAddNewLoaderAction(false));
  } else {
    yield put(mode === "edit" ? putUserAction(data) : getUserAction(data));
    yield put(setAddNewLoaderAction(false));
  }
}

function* fetchEditUserWorker(action) {
  const {
    payload: { username },
  } = action;

  yield put(setModalLoaderAction(true));

  const { errno, message, data } = yield get(`${USERS_PROFILE_API}/${username}`);

  if (errno !== 0) {
    yield put(setModalLoaderAction(false));
    yield catchError(message);
  } else {
    console.log("User uploaded");
    yield put(getEditUserAction(data));
    yield put(clearNotifierAction());
    yield put(setModalLoaderAction(false));
  }
}

function* updateUserWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  if (actionType === "create") {
    const {
      data: { nextId },
    } = yield get(`${USERS_PROFILE_API}/getNextUserName`, { fname: values.fname, lname: values.lname });

    values.username = nextId;
  }

  const { errno, message } = yield post(`${USERS_PROFILE_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve(`${actionType} user successfull`);
    yield put(
      fetchUserAction({
        username: values.username,
        mode: actionType,
      })
    );
    yield put(clearNotifierAction());
  }
}

// function* userActivationWorker(action) {
//   const {
//     payload: { formData },
//   } = action;

//   const { errno, message } = yield post(`${USERS_PROFILE_API}/update`, formData, "json");

//   if (errno !== 0) {
//     yield catchError(message);
//   } else {
//     console.log("User activate/inactivate");
//     yield put(
//       fetchUserAction({
//         username: formData.username,
//         mode: "edit",
//       })
//     );
//     yield put(clearNotifierAction());
//   }
// }

function* deleteUserWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${USERS_PROFILE_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Delete user successfull");
    yield put(deleteUserStoreAction({ username: values.username }));
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* fetchUsersCompaniesWorker(action) {
  const {
    payload: { username },
  } = action;

  yield put(setPaginationLoaderAction(true));

  let path;
  let params;

  if (username) {
    path = `${USERS_PROFILE_API}/getCompanyAccess`;
    params = { pageSize: 100, username };
  } else {
    path = `${COMPANIES_API}/list`;
    params = { pageSize: 100 };
  }

  const { errno, message, data } = yield get(path, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putUsersCompaniesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

export function* membersProfileSaga() {
  yield takeLatest(MembersActions.FETCH_USERS, fetchUsersWorker);
  yield takeLatest(MembersActions.FETCH_USER_BY_NAME, fetchUserWorker);
  yield takeLatest(MembersActions.ADD_NEW_USER, updateUserWorker);
  yield takeLatest(MembersActions.EDIT_USER, updateUserWorker);
  yield takeLatest(MembersActions.FETCH_EDIT_USER, fetchEditUserWorker);
  yield takeLatest(MembersActions.USER_ACTIVATION, updateUserWorker);
  yield takeLatest(MembersActions.DELETE_USER_FROM_SERVER, deleteUserWorker);
  yield takeLatest(MembersActions.FETCH_USERS_COMPANIES, fetchUsersCompaniesWorker);
}
