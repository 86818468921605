import { takeLatest, put } from "redux-saga/effects";
import { CHANGE_ORDERS_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";
import { ChangeOrderRequest, ChangeOrderDetailResponse } from "resource/interfaces/change-order";
import { normalizeItemsList, normalizeItemById } from "resource/normalize";
import { PromiseType } from "resource/interfaces";

import {
  ChangeOrdersActions,
  putChangeOrderByIdAction,
  putChangeOrdersAction,
  fetchChangeOrderByIdAction,
} from "../redux-actions/changeOrders";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchChangeOrdersWorker(action: {
  payload: { additionalData: { fromDate: string; toDate: string; filter: string; keywords: string }; page: number };
  type: string;
}) {
  const {
    payload: {
      additionalData: { fromDate, toDate, filter, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${CHANGE_ORDERS_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
    ...(filter && { filter }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putChangeOrdersAction({ data: normalizeItemsList<ChangeOrderRequest>(data.list, "orders"), count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchChangeOrderByIdWorker(action: { payload: number; type: string }) {
  const { payload: idnumber } = action;

  const { errno, message, data } = yield get(`${CHANGE_ORDERS_API}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putChangeOrderByIdAction(normalizeItemById<ChangeOrderDetailResponse>(data, "order")));
  }
}

function* createChangeOrderWorker(action: { type: string; payload: { values: any; actionType: string }; promise: PromiseType }) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const {
    data: { nextId: idnumber },
  } = yield get(`${CHANGE_ORDERS_API}/getNextId`);

  const updatedValues = { ...values, idnumber };

  const { errno, message } = yield post(`${CHANGE_ORDERS_API}/${actionType}`, { ...updatedValues }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Change Order was createded successfully`);
    yield put(fetchChangeOrderByIdAction(idnumber));
    yield put(clearNotifierAction());
  }
}

function* updateChangeOrderWorker(action) {
  const {
    payload: { data, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${CHANGE_ORDERS_API}/${actionType}`, { ...data }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Change Order was ${actionType}ed successfully`);
    yield put(fetchChangeOrderByIdAction(data.idnumber));
    yield put(clearNotifierAction());
  }
}

export function* changeOrdersSaga() {
  yield takeLatest(ChangeOrdersActions.FETCH_CHANGE_ORDERS, fetchChangeOrdersWorker);
  yield takeLatest(ChangeOrdersActions.FETCH_CHANGE_ORDER_BY_ID, fetchChangeOrderByIdWorker);
  yield takeLatest(ChangeOrdersActions.VOID_CHANGE_ORDER, updateChangeOrderWorker);
  yield takeLatest(ChangeOrdersActions.APPROVE_CHANGE_ORDER, updateChangeOrderWorker);
  yield takeLatest(ChangeOrdersActions.DISAPPROVE_CHANGE_ORDER, updateChangeOrderWorker);
  yield takeLatest(ChangeOrdersActions.ADD_NEW_CHANGE_ORDER, createChangeOrderWorker);
  yield takeLatest(ChangeOrdersActions.EDIT_CHANGE_ORDER, updateChangeOrderWorker);
}
