export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// ? ACTION CREATORS

export const openModalAction = (name: string, data: any): { type: string; payload: { name: string; data: any } } => {
  return {
    type: OPEN_MODAL,
    payload: { name, data },
  };
};

export const closeModalAction = (name: string): { type: string; payload: { name: string } } => {
  return {
    type: CLOSE_MODAL,
    payload: { name },
  };
};
