import { takeLatest, put } from "redux-saga/effects";
import { PURCHASE_ORDER_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  putPurchaseOrderListAction,
  putPurchaseOrderByIdAction,
  PurchaseOrderActions,
  fetchPurchaseOrderByIdAction,
} from "../redux-actions/purchaseOrder";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchPurchaseOrderWorker(action) {
  const {
    payload: {
      additionalData: { fromDate, toDate, filter, keywords, idassembly1, venderId, idproject1, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PURCHASE_ORDER_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
    ...(filter && { filter }),
    ...(idassembly1 && { idassembly1 }),
    ...(venderId && { venderId }),
    ...(idproject1 && { idproject1 }),
    ...(idcia && { idcia }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putPurchaseOrderListAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchPurchaseOrderByIdWorker(action) {
  const { payload: id } = action;

  const { errno, message, data } = yield get(`${PURCHASE_ORDER_API}/poInfo/${id}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putPurchaseOrderByIdAction({ data }));
  }
}

function* createPurchaseOrderDraftWorker(action) {
  const {
    payload: { data: values, history },
    promise: { resolve, reject },
  } = action;

  const {
    data: { nextpo },
  } = yield get(`${PURCHASE_ORDER_API}/getNextNumber/${values.idcia}`);

  const updatedValues = { ...values, iddoc: nextpo };

  const { errno, message, data } = yield post(`${PURCHASE_ORDER_API}/createdraft`, updatedValues, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`PurchaseOrder Draft was released successfully`);
    history.push(`purchase-order/${data.newId}`);
    yield put(clearNotifierAction());
  }
}

function* purchaseOrderWorker(action) {
  const {
    payload: { data, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PURCHASE_ORDER_API}/${actionType}`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`${actionType} successfully`);
    yield put(fetchPurchaseOrderByIdAction(data.idnumber));
    yield put(clearNotifierAction());
  }
}

export function* purchaseOrderSaga() {
  yield takeLatest(PurchaseOrderActions.FETCH_PURCHASE_ORDER_LIST, fetchPurchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.FETCH_PURCHASE_ORDER_BY_ID, fetchPurchaseOrderByIdWorker);
  yield takeLatest(PurchaseOrderActions.RE_OPEN_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.VOID_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.RELEASE_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.CREATE_PURCHASE_ORDER_DRAFT, createPurchaseOrderDraftWorker);
  yield takeLatest(PurchaseOrderActions.UPDATE_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.DELETE_PURCHASE_ORDER_PART, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.ADD_PURCHASE_ORDER_PART, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.UPDATE_PURCHASE_ORDER_PART, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.REQUEST_APPROVAL_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.DISAPPROVE_PO, purchaseOrderWorker);
  yield takeLatest(PurchaseOrderActions.APPROVE_PO, purchaseOrderWorker);
}
