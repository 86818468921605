import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum VariablesActions {
  ADD_NEW_PLAN_VARIABLE = "ADD_NEW_PLAN_VARIABLE",
  UPDATE_PLAN_VARIABLE = "UPDATE_PLAN_VARIABLE",
  PUT_PLAN_VARIABLE_BY_ID = "PUT_PLAN_VARIABLE_BY_ID",
  PUT_PLAN_VARIABLES = "PUT_PLAN_VARIABLES",
  FETCH_PLAN_VARIABLES = "FETCH_PLAN_VARIABLES",
  FETCH_PLAN_VARIABLE_BY_ID = "FETCH_PLAN_VARIABLE_BY_ID",
  DELETE_PLAN_VARIABLE_FROM_SERVER = "DELETE_PLAN_VARIABLE_FROM_SERVER",
  DELETE_PLAN_VARIABLE_FROM_STORE = "DELETE_PLAN_VARIABLE_FROM_STORE",
  CATCH_PLAN_VARIABLES_ERROR = "CATCH_PLAN_VARIABLES_ERROR",
  CLEAR_VARIABLES = "CLEAR_VARIABLES",
}

// ? ACTION CREATORS

export const addNewPlanVariableAction = (payload, promise?: PromiseType) => {
  return {
    type: VariablesActions.ADD_NEW_PLAN_VARIABLE,
    payload,
    promise,
  };
};

export const updatePlanVariableAction = (payload, promise?: PromiseType) => {
  return {
    type: VariablesActions.UPDATE_PLAN_VARIABLE,
    payload,
    promise,
  };
};

export const deletePlanVariableAction = (payload: any, promise) => {
  return {
    type: VariablesActions.DELETE_PLAN_VARIABLE_FROM_SERVER,
    payload,
    promise,
  };
};

export const deletePlanVariableStoreAction = (payload: any) => {
  return {
    type: VariablesActions.DELETE_PLAN_VARIABLE_FROM_STORE,
    payload,
  };
};

export const putPlanVariablesAction = (payload: any) => {
  return {
    type: VariablesActions.PUT_PLAN_VARIABLES,
    payload,
  };
};

export const putPlanVariableByIdAction = (payload: any) => {
  return {
    type: VariablesActions.PUT_PLAN_VARIABLE_BY_ID,
    payload,
  };
};

export const fetchPlanVariablesAction = (payload: any) => {
  return {
    type: VariablesActions.FETCH_PLAN_VARIABLES,
    payload,
  };
};

export const fetchPlanVariableByIdAction = (payload: any) => {
  return {
    type: VariablesActions.FETCH_PLAN_VARIABLE_BY_ID,
    payload,
  };
};

export const catchPlanVariablesErrorAction = (payload: any) => {
  return {
    type: VariablesActions.CATCH_PLAN_VARIABLES_ERROR,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: VariablesActions.CLEAR_VARIABLES,
  };
};
