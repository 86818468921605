import { PaymentSummaryActions } from "redux-store/redux-actions/paymentSummary";
import { getUniqueData } from "resource/utils";

const initialState = {
  paymentSummaryList: [],
  paymentSummaryCount: 0,
  paymentSummaryDetails: [],
  paymentSummaryDetailsCount: 0,
  paymentSummaryError: "",
};

export const paymentSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PaymentSummaryActions.PUT_PAYMENT_SUMMARY:
      return {
        ...state,
        paymentSummaryList: [...state.paymentSummaryList, ...getUniqueData(payload.data, state.paymentSummaryList, "idnumber")],
        paymentSummaryCount: payload.count as number,
      };
    case PaymentSummaryActions.PUT_PAYMENT_SUMMARY_DETAILS:
      return {
        ...state,
        paymentSummaryDetails: [
          ...state.paymentSummaryDetails,
          ...getUniqueData(payload.data, state.paymentSummaryDetails, "idnumber"),
        ],
        paymentSummaryDetailsCount: payload.count as number,
      };
    case PaymentSummaryActions.CLEAR_PAYMENT_SUMMARY_DATA:
      return {
        ...state,
        paymentSummaryList: [],
        paymentSummaryCount: 0,
      };
    case PaymentSummaryActions.CLEAR_PAYMENT_SUMMARY_DETAILS_DATA:
      return {
        ...state,
        paymentSummaryDetails: [],
        paymentSummaryDetailsCount: 0,
      };
    case PaymentSummaryActions.CATCH_PAYMENT_SUMMARY_ERROR:
      return { ...state, paymentSummaryError: payload as string };
    default:
      return state;
  }
};
