import { ProjectTakeoffActions } from "redux-store/redux-actions/projectTakeoffs";
import { updateNormalizedCollection } from "resource/utils";

interface ProjectTakeoffsStore {
  projectTakeoffItems: any;
  projectTakeoffIds: any[];
  projectTakeoffBudget: any;
  projectTakeoffList: any;
  projectTakeoffParts: any;
  projectTakeoffsCount: number;

  projectTakeoffReleaseList: any;
  projectTakeoffReleaseListIds: any[];
  projectTakeoffReleaseListCount: number;
}

const initialState: ProjectTakeoffsStore = {
  projectTakeoffItems: {},
  projectTakeoffIds: [],
  projectTakeoffBudget: {},
  projectTakeoffList: {},
  projectTakeoffParts: {},
  projectTakeoffsCount: 0,

  projectTakeoffReleaseList: {},
  projectTakeoffReleaseListIds: [],
  projectTakeoffReleaseListCount: 0,
};

export const projectTakeoffsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProjectTakeoffActions.PUT_PROJECT_TAKEOFFS:
      return {
        ...state,
        projectTakeoffItems: { ...state.projectTakeoffItems, ...payload.data.entities.projectTakeoffs },
        projectTakeoffIds: Array.from(new Set([...state.projectTakeoffIds, ...payload.data.result])) as number[],
        projectTakeoffsCount: payload.count as number,
      };
    case ProjectTakeoffActions.PUT_PROJECT_TAKEOFF_BY_ID:
      return {
        ...state,
        projectTakeoffItems: {
          ...state.projectTakeoffItems,
          [payload.result]: payload.entities.projectTakeoff[payload.result],
        },
        projectTakeoffBudget: { ...state.projectTakeoffBudget, ...payload.entities.budget },
        projectTakeoffList: {
          ...state.projectTakeoffList,
          ...payload.entities.list,
        },
        projectTakeoffParts: { ...state.projectTakeoffParts, ...payload.entities.parts },
        projectTakeoffIds: updateNormalizedCollection(state.projectTakeoffIds, payload.result),
      };
    case ProjectTakeoffActions.PUT_PROJECT_TAKEOFF_RELEASE_LIST:
      return {
        ...state,
        projectTakeoffReleaseList: { ...state.projectTakeoffReleaseList, ...payload.data.entities.projectTakeoffReleaseList },
        projectTakeoffReleaseListIds: Array.from(
          new Set([...state.projectTakeoffReleaseListIds, ...payload.data.result])
        ) as number[],
        projectTakeoffReleaseListCount: payload.count as number,
      };
    case ProjectTakeoffActions.CLEAR_PROJECT_TAKEOFF_DATA:
      return {
        ...state,
        projectTakeoffItems: {},
        projectTakeoffIds: [],
        projectTakeoffsCount: 0,
      };
    case ProjectTakeoffActions.CLEAR_PROJECT_TAKEOFF_RELEASE_DATA:
      return {
        ...state,
        projectTakeoffReleaseList: {},
        projectTakeoffReleaseListIds: [],
        projectTakeoffReleaseListCount: 0,
      };
    default:
      return state;
  }
};
