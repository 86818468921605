import { useState, FC } from "react";

// ? REDUX
import { editProductAction } from "redux-store/redux-actions/vendorPricing";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import { VendorPricingDetailForm } from "pages/vendor-pricing/VendorPricingDetailForm";
import useAsync from "custom-hooks/useAsync";

// ? STYLES
import styles from "pages/vendor-pricing/VendorPricing.module.css";

// ? RESOURCES
import { VENDOR_PRICING_NAME, VENDOR_PRICING } from "resource/constants/vendorPricing";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { trimFormStrings } from "resource/utils";

export const nameUpdate = `${VENDOR_PRICING_NAME}/${ActionName.UPDATE}`;
interface VendorPricingUpdateProps {
  idvendor: number;
  idgeoarea: number;
}

const VendorPricingUpdate: FC<VendorPricingUpdateProps> = ({ idvendor, idgeoarea }) => {
  const { close, data, isOpened } = useModal(nameUpdate);
  const [setNotice] = useNotice();

  const [isLoading, setLoader] = useState(false);
  const updatePart = useAsync(editProductAction);

  const handleSubmit = async (data) => {
    setLoader(true);

    data.lastprice = Number(data.lastprice);
    data.idvendor = Number(idvendor);
    data.idgeoarea = Number(idgeoarea);

    try {
      await updatePart({
        values: trimFormStrings(data),
        actionType: ActionName.UPDATE,
      });
      setNotice("Part updatedd", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.CreateForm}>
          <VendorPricingDetailForm
            title={VENDOR_PRICING.EDIT_MODAL_TITLE}
            formData={data}
            buttonText={VENDOR_PRICING.EDIT_MODAL_SUBMIT_BUTTON}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            type={ActionName.UPDATE}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default VendorPricingUpdate;
