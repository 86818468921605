import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { fetchFloorlanFilesAction, clearDataDetailsAction } from "redux-store/redux-actions/masterFloorplans";

// ? COMPONENTS
import { Box, CircularProgress, IconButton, Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { MasterFloorplanFileListItem } from "./MasterFloorplanFileListItem";
import { useFileSelector } from "custom-hooks/useFileSelector";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import { TableListDivider } from "components/shared/table-list/TableList";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

// ? RESOURCES
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const getPlansFolders = (files) => {
  const folders = new Set();
  for (const file of files) {
    folders.add(`Revision ${file.revision}`);
  }
  return Array.from(folders).sort().reverse();
};

const MasterFloorplanDetailFilesTab = ({ idnumber, name, revision, openFilePreview }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const files = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanFiles);
  const filesCount = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplanFilesCount);
  const hasMoreToLoad = filesCount > files.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      revision,
    }),
    [idnumber, revision]
  );

  const folders = getPlansFolders(files);

  // COPY FILES LINKS
  const [selectedFiles, selectFile, copySelectedFilesLinks] = useFileSelector("fid");

  useInfiniteLoading(fetchFloorlanFilesAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDataDetailsAction("Files"));
    };
  }, []);

  return (
    <Box className={styles.MasterFloorplanDetailsDataList}>
      <TableListHeader>
        <Grid item xs={4}>
          <Typography color={theme.palette.primary.main}>Document Name</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Size</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Type</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Attach</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>View</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Delete</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <IconButton className={styles.CopyButton} disabled={selectedFiles.length === 0} onClick={copySelectedFilesLinks}>
            <ContentCopyIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Updated By</Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
      </TableListHeader>
      <div className={styles.FormList}>
        {files.length > 0 && (
          <MasterFloorplanFileListItem
            el={files[0]}
            index={0}
            revision={revision}
            idnumber={idnumber}
            name={name}
            select={selectFile}
            openFilePreview={openFilePreview}
          />
        )}
        {folders.length > 0 &&
          folders.map((folder: any, i) => (
            <div key={i}>
              <TableListDivider>
                <Typography variant="h3" color={theme.palette.text.primary}>
                  {folder}
                </Typography>
              </TableListDivider>
              {files.length > 0 &&
                files.map((el, index) => {
                  if (index > 0 && folder.includes(el.revision)) {
                    return (
                      <MasterFloorplanFileListItem
                        key={index}
                        el={el}
                        index={index}
                        revision={revision}
                        idnumber={idnumber}
                        name={name}
                        select={selectFile}
                        openFilePreview={openFilePreview}
                      />
                    );
                  }
                })}
            </div>
          ))}
      </div>
      {paginationLoading && (
        <div className={`LoaderScroll ${!files.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default MasterFloorplanDetailFilesTab;
