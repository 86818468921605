import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { resolvePath, formatPrice, getFormattedDate } from "resource/utils";
import { ColorListMap } from "resource/consts";
import { ROUTES } from "resource/routes/routes";
import { ContractsRequest } from "resource/interfaces/contracts";
import FileServerClipIcon from "components/shared/file-server/FileServerClipIcon";

interface ContractItemsProps {
  contract: ContractsRequest;
}

const ContractItem: FC<ContractItemsProps> = ({ contract }) => {
  const theme = useTheme();
  const mainPath = ROUTES.contracts.pathname;
  const subDirectories = [contract.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1} display="flex">
          <FileServerClipIcon fs={contract.fs} />
          <Typography sx={{ marginLeft: "12px" }} color={theme.palette.text.primary}>
            {contract.idcia}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{contract.idnumber}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{getFormattedDate(contract.refdate)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.text.primary}>{contract.idproject}</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography color={theme.palette.text.primary}>{contract.nproject}</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.text.primary}>{formatPrice(String(contract.sold))}</Typography>
        </Grid>

        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography sx={{ color: `rgb(${ColorListMap[contract.status]})` }} justifyContent="flex-end" display="flex">
            {String(contract.status)}
          </Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default ContractItem;
