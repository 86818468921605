import { PromiseType } from "resource/interfaces";
import { PurchaseOrderListItem } from "resource/interfaces/purchase-order";

// ? ACTIONS
export enum PurchaseOrderActions {
  PUT_PURCHASE_ORDER_LIST = "PUT_PURCHASE_ORDER_LIST",
  PUT_PURCHASE_ORDER_BY_ID = "PUT_PURCHASE_ORDER_BY_ID",
  FETCH_PURCHASE_ORDER_LIST = "FETCH_PURCHASE_ORDERLST",
  FETCH_PURCHASE_ORDER_BY_ID = "FETCH_PURCHASE_ORDER_BY_ID",
  CREATE_PURCHASE_ORDER_DRAFT = "CREATE_PURCHASE_ORDER_DRAFT",
  DELETE_PURCHASE_ORDER_PART = "DELETE_PURCHASE_ORDER_PART",
  ADD_PURCHASE_ORDER_PART = "ADD_PURCHASE_ORDER_PART",
  UPDATE_PURCHASE_ORDER_PART = "UPDATE_PURCHASE_ORDER_PART",
  CLEAR_PO_DATA = "CLEAR_PO_DATA",
  RELEASE_PO = "RELEASE_PO",
  VOID_PO = "VOID_PO",
  REQUEST_APPROVAL_PO = "REQUEST_APPROVAL_PO",
  UPDATE_PO = "UPDATE_PO",
  APPROVE_PO = "APPROVE_PO",
  DISAPPROVE_PO = "DISAPPROVE_PO",
  RE_OPEN_PO = "RE_OPEN_PO",
  REQUEST_PAYMENT = "REQUEST_PAYMENT",
  APPROVE_PAYMENT = "APPROVE_PAYMENT",
  BOOK_PAYMENT = "BOOK_PAYMENT",
  CATCH_PURCHASE_ORDER_ERROR = "CATCH_PURCHASE_ORDER_ERROR",
}

// ? ACTION CREATORS
export const putPurchaseOrderListAction = (payload: { data: PurchaseOrderListItem[]; count: number }) => {
  return {
    type: PurchaseOrderActions.PUT_PURCHASE_ORDER_LIST,
    payload,
  };
};

export const fetchPurchaseOrderListAction = (payload: number) => {
  return {
    type: PurchaseOrderActions.FETCH_PURCHASE_ORDER_LIST,
    payload,
  };
};

export const putPurchaseOrderByIdAction = (payload) => {
  return {
    type: PurchaseOrderActions.PUT_PURCHASE_ORDER_BY_ID,
    payload,
  };
};

export const fetchPurchaseOrderByIdAction = (payload: string) => {
  return {
    type: PurchaseOrderActions.FETCH_PURCHASE_ORDER_BY_ID,
    payload,
  };
};

export const createPurchaseOrderDraftAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.CREATE_PURCHASE_ORDER_DRAFT,
    payload,
    promise,
  };
};

export const deletePurchaseOrderPartAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.DELETE_PURCHASE_ORDER_PART,
    payload,
    promise,
  };
};

export const addPurchaseOrderPartAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.ADD_PURCHASE_ORDER_PART,
    payload,
    promise,
  };
};

export const updatePurchaseOrderPartAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.UPDATE_PURCHASE_ORDER_PART,
    payload,
    promise,
  };
};

export const clearDataAction = () => {
  return {
    type: PurchaseOrderActions.CLEAR_PO_DATA,
  };
};

export const releasePurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.RELEASE_PO,
    payload,
    promise,
  };
};

export const voidPurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.VOID_PO,
    payload,
    promise,
  };
};

export const requestApprovalPurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.REQUEST_APPROVAL_PO,
    payload,
    promise,
  };
};

export const updatePurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.UPDATE_PO,
    payload,
    promise,
  };
};

export const approvePurchseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.APPROVE_PO,
    payload,
    promise,
  };
};

export const disapprovePurchseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.DISAPPROVE_PO,
    payload,
    promise,
  };
};

export const reOpenPurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.RE_OPEN_PO,
    payload,
    promise,
  };
};

export const requestPaymentPurchseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.REQUEST_PAYMENT,
    payload,
    promise,
  };
};

export const approvePaymentPurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.APPROVE_PAYMENT,
    payload,
    promise,
  };
};

export const bookPaymentPurchaseOrderAction = (payload, promise?: PromiseType) => {
  return {
    type: PurchaseOrderActions.BOOK_PAYMENT,
    payload,
    promise,
  };
};

export const catchPurchaseOrderErrorAction = (payload: any) => {
  return {
    type: PurchaseOrderActions.CATCH_PURCHASE_ORDER_ERROR,
    payload,
  };
};
