import { optionsType } from "resource/interfaces";
export const VENDORS_NAME = "vendor";

export const VENDORS = {
  TITLE: "Vendors ",
  NEW_MODAL_TITLE: "Add New Vendor",
  EDIT_MODAL_TITLE: "Edit Vendor Details",
  NEW_MODAL_PLUS_BUTTON: "Add New Vendor",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Vendor",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Vendor Details",
};

export const basicOptions: { [name: string]: optionsType } = {
  inactiveyn: {
    field: "inactiveyn",
    list: [
      { label: "Inactive", value: true },
      { label: "Active", value: false },
    ],
  },
  vtype: {
    field: "vtype",
    list: [
      { label: "A/P Vendor", value: "A/P Vendor" },
      { label: "A/P Loan", value: "A/P Loan" },
      { label: "A/P Other", value: "A/P Other" },
    ],
  },
  category: {
    field: "category",
    list: [],
  },
  payterms: {
    field: "payterms",
    list: [],
  },
};
