import { useState, useEffect } from "react";

// ? REDUX
import { addPurchaseOrderPartAction } from "redux-store/redux-actions/purchaseOrder";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Grid, Box, TextField, Autocomplete, Typography } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import useModal from "custom-hooks/useModal";
import PurchaseOrderLumberInfo from "components/modals/info/PurchaseOrderLumberInfo";
import { nameLumber } from "components/modals/info/PurchaseOrderLumberInfo";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import useHeaders from "custom-hooks/useHeaders";

// ? RESOURCES
import { VENDOR_PRICING_API } from "resource/constants/api";
import { PARTS_API } from "resource/constants/api";
import { purchaseOrderAddPartSchema } from "resource/schemas/purchaseOrderSchema";
import { PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { Colors } from "resource/consts";
import checkIcon from "resource/images/tick-mark.svg";
import deleteIcon from "resource/images/delete.svg";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

const inlineStyle = {
  autocomplete: {
    "& .MuiOutlinedInput-root": {
      padding: 0,
      paddingRight: "0 !important",
      borderRadius: 0,
      backgroundColor: Colors.WHITE,
    },
    "& .MuiAutocomplete-input": {
      height: "50px",

      backgroundColor: Colors.WHITE,
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
  },
  input: {
    margin: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    "& input": { borderRadius: 0, height: "50px", backgroundColor: Colors.WHITE },
    "& .MuiOutlinedInput-input:-webkit-autofill": { borderRadius: 0 },
  },
} as const;

const initialValues = {
  quantity: "",
  notes: "",
};

const PurchaseOrderAddPartForm = ({ order }) => {
  const headers = useHeaders();
  const [openUpcList, setOpenUpcList] = useState(false);
  const [openDescriptionList, setOpenDescriptionList] = useState(false);
  const [partsOptions, setPartsOptions] = useState<any[]>([]);
  const [partsLoader, setPartsLoader] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const [partValue, setPartValue] = useState<any | null>(null);
  const [price, setPrice] = useState<number | string>("");
  const [preorder, setPreorder] = useState<null | number>(null);
  const [preorderLoading, setPreorderLoading] = useState(true);
  const [isLumber, setIsLumber] = useState(false);
  const [skunumber, setSkunumber] = useState("");
  const [lumber, setLumber] = useState<string | null>(null);

  const { isOpened, close, open } = useModal(nameLumber);

  const handleListOpening = (evt, newInputValue: string) => {
    if (newInputValue.length > 0 && evt) {
      !partValue && evt.target.id === "upc" ? setOpenUpcList(true) : setOpenDescriptionList(true);
    } else {
      setOpenDescriptionList(false);
      setOpenUpcList(false);
    }
  };

  const checkPreorder = async (upc: string) => {
    setLumber(null);
    try {
      setPreorderLoading(true);
      const response = await fetch(`${PARTS_API}/popart?idcia=${order.idcia}&idproject1=${order.idproject1}&upc=${upc}`, {
        headers,
      });
      const data = await response.json();
      setPreorder(data.data.preorder);
    } catch (err) {
      console.error(err);
    } finally {
      setPreorderLoading(false);
    }
  };

  const checkLumber = async (upc: string) => {
    try {
      setPreorderLoading(true);
      const response = await fetch(`${PARTS_API}/${upc}`, {
        headers,
      });
      const data = await response.json();
      setIsLumber(data.data.lumber);
    } catch (err) {
      console.error(err);
    } finally {
      setPreorderLoading(false);
    }
  };

  const resetControlledFields = () => {
    setPrice("");
    setSkunumber("");
    setPreorder(null);
    setIsLumber(false);
    setLumber(null);
    setPartValue(null);
  };

  useEffect(() => {
    fetch(`${VENDOR_PRICING_API}/vendorparts/?idgeoarea=1&idvendor=${order.idvendor}`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        setPartsOptions(response.data.list);
        setPartsLoader(false);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [order.idvendor]);

  useEffect(() => {
    if (isLumber) {
      open(partValue);
    }
  }, [isLumber]);

  useEffect(() => {
    if (partValue) {
      checkPreorder(partValue.upc);
      checkLumber(partValue.upc);
      setPrice(partValue.lastprice);
      setSkunumber(partValue.skunumber);
    } else {
      resetControlledFields();
    }
  }, [partValue]);

  useEffect(() => {
    if (lumber && lumber !== "Random Length" && partValue && price) {
      const partDescription = partValue.description;
      setPartValue({
        ...partValue,
        description:
          partDescription.slice(0, partDescription.indexOf(" ")) +
          `x${lumber} ` +
          partDescription.slice(partDescription.indexOf(" ") + 1, partDescription.length - 1),
        lastprice: partValue.lastprice * Number(lumber),
      });
    }
  }, [lumber]);

  const clearInput = (resetForm) => {
    resetForm();
    resetControlledFields();
  };

  const handleLumberSubmit = (value: { lumber: number }) => {
    if (isOpened) {
      setLumber(String(value.lumber));
      close();
    }
  };

  const addPurchaseOrderPart = useAsync(addPurchaseOrderPartAction);

  const handleSubmit = async (values: { quantity: string; notes: string }, resetForm) => {
    const data = {
      idnumber: Number(order.idnumber),
      upc: partValue.upc,
      description: partValue.description,
      skunumber: skunumber,
      quantity: Number(values.quantity),
      price: Number(price),
      notes: values.notes,
      idtrack: order.idtrack,
    };

    setLoader(true);
    try {
      await addPurchaseOrderPart({ data, actionType: PurchaseOrderEndpoints.INSERT_PART });
      clearInput(resetForm);
      setLumber(null);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        validationSchema={purchaseOrderAddPartSchema}
      >
        {(props) => (
          <>
            <Form className={styles.PoPartsForm}>
              <Grid container flexWrap="nowrap">
                <Grid item sx={{ width: "90px" }}>
                  <>
                    {!partsLoader ? (
                      <>
                        <Autocomplete
                          options={partsOptions}
                          getOptionLabel={(option) => option.upc || ""}
                          value={partValue}
                          id="upc"
                          sx={{
                            ...inlineStyle.autocomplete,
                          }}
                          open={openUpcList}
                          onInputChange={handleListOpening}
                          onChange={(evt, newValue) => {
                            setPartValue(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} sx={{ ...inlineStyle.input }} placeholder="UPC" />}
                        />
                      </>
                    ) : (
                      <SmallLoader />
                    )}
                  </>
                </Grid>
                <Grid item sx={{ width: "300px" }}>
                  <>
                    {!partsLoader ? (
                      <Autocomplete
                        options={partsOptions}
                        getOptionLabel={(option) => option.description || ""}
                        value={partValue}
                        open={openDescriptionList}
                        onInputChange={handleListOpening}
                        id="description"
                        sx={{
                          ...inlineStyle.autocomplete,
                        }}
                        onChange={(evt, newValue) => {
                          setPartValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ ...inlineStyle.input }} placeholder="Search by part" />
                        )}
                      />
                    ) : (
                      <SmallLoader />
                    )}
                  </>
                </Grid>
                <Grid item sx={{ width: "100px" }}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="text"
                    name="unit"
                    id="unit"
                    value={partValue ? partValue.lengthunit : ""}
                    placeholder="U/M"
                  />
                </Grid>
                <Grid item sx={{ width: "100px" }}>
                  <Box sx={{ height: "50px", padding: "16px", border: `1px solid ${Colors.ATHEN_GRAY}` }}>
                    {!preorderLoading ? (
                      <Typography>{preorder}</Typography>
                    ) : (
                      <Typography sx={{ color: Colors.GRAY }}>Preorder</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item sx={{ width: "80px" }}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="text"
                    name="quantity"
                    id="quantity"
                    placeholder="Qty"
                  />
                  <ErrorMessage name="quantity">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </Grid>
                <Grid item sx={{ width: "90px" }}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="text"
                    name="price"
                    id="price"
                    onChange={(evt) => setPrice(evt.target.value)}
                    value={price}
                    placeholder="Price"
                  />
                </Grid>

                <Grid item sx={{ width: "110px" }}>
                  <Box sx={{ height: "50px", padding: "16px", border: `1px solid ${Colors.ATHEN_GRAY}` }}>
                    {price && props.values.quantity ? (
                      <Typography>{Math.round(Number(price) * Number(props.values.quantity) * 100) / 100}</Typography>
                    ) : (
                      <Typography sx={{ color: Colors.GRAY }}>Amount</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item sx={{ width: "100px" }}>
                  <Field
                    as={TextField}
                    sx={{ ...inlineStyle.input }}
                    type="text"
                    name="skunumber"
                    id="skunumber"
                    placeholder="SKU"
                    value={skunumber}
                    onChange={(evt) => setSkunumber(evt.target.value)}
                  />
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Field as={TextField} sx={{ ...inlineStyle.input }} type="text" name="notes" id="notes" placeholder="Notes" />
                </Grid>
                <Grid item sx={{ width: "80px", bgcolor: Colors.ALABASTER }}>
                  {isLoading ? (
                    <SmallLoader />
                  ) : (
                    <>
                      <IconButton type="submit" sx={{ width: "50%", height: "100%" }}>
                        <img src={checkIcon} alt="Add icon" />
                      </IconButton>
                      <IconButton onClick={() => clearInput(props.handleReset)} sx={{ width: "50%", height: "100%" }}>
                        <img src={deleteIcon} alt="Delete icon" />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <PurchaseOrderLumberInfo handleLumberSubmit={handleLumberSubmit} />
    </>
  );
};

export default PurchaseOrderAddPartForm;
