export const PROJECTS_NAME = "projects";

export const PROJECTS = {
  TITLE: "Projects",
  NEW_MODAL_TITLE: "Add New Project",
  EDIT_MODAL_TITLE: "Update Project",
  NEW_MODAL_PLUS_BUTTON: "Add New Project",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Project",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Project",
};

export const projectsFiltersList = ["-All-", "Active", "Specs", "Sold", "Closed"];
