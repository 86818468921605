import { useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchProjectBasePlanAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { Box, CircularProgress, IconButton, Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { TableListDivider } from "components/shared/table-list/TableList";
import { ProjectsDetailBasePlansTabItem } from "./ProjectsDetailBasePlansTabItem";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useFileSelector } from "custom-hooks/useFileSelector";

const getPlansFolders = (plans) => {
  const folders = new Set();
  for (const value of plans) {
    folders.add(value.folderName);
  }
  return Array.from(folders);
};

const ProjectsDetailBasePlansTab = ({ idnumber, idcia, idplan1, revision, openFilePreview }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const plans = useSelector((state: RootStateOrAny) => state.projects.projectBasePlans);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  useEffect(() => {
    dispatch(fetchProjectBasePlanAction({ idnumber, idcia, idplan1, revision }));
  }, []);

  useEffect(() => {}, [plans]);

  const folders = getPlansFolders(plans);

  // COPY FILES LINKS
  const [selectedFiles, selectFile, copySelectedFilesLinks] = useFileSelector("fid");

  return (
    <>
      <div className={styles.ProjectsDetails}>
        <TableListHeader>
          <Grid item xs={1}>
            <Typography color={theme.palette.primary.main}>ID</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color={theme.palette.primary.main}>Name</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography color={theme.palette.primary.main}>Size</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography color={theme.palette.primary.main}>Type</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Attach</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>View</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.primary.main}>Delete</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <IconButton className={styles.CopyButton} disabled={selectedFiles.length === 0} onClick={copySelectedFilesLinks}>
              <ContentCopyIcon />
            </IconButton>
          </Grid>
          <Grid item md={1}>
            <Typography color={theme.palette.primary.main}>Updated By</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography color={theme.palette.primary.main}>Date</Typography>
          </Grid>
        </TableListHeader>
        <Box>
          <div className={styles.FormList}>
            {folders.length > 0 &&
              folders.map((folder: any, i) => (
                <div key={i}>
                  <TableListDivider>
                    <Typography variant="h3" color={theme.palette.text.primary}>
                      {folder}
                    </Typography>
                  </TableListDivider>
                  {plans.length > 0 &&
                    plans
                      .filter((plan) => plan.folderName === folder)
                      .map((plan, index) => (
                        <div key={index}>
                          <ProjectsDetailBasePlansTabItem
                            idnumber={idnumber}
                            idcia={idcia}
                            plan={plan}
                            index={index}
                            select={selectFile}
                            openFilePreview={openFilePreview}
                          />
                        </div>
                      ))}
                </div>
              ))}
            {paginationLoading ? (
              <div className={`LoaderScroll ${!plans.length && "center"}`}>
                <CircularProgress />
              </div>
            ) : null}
          </div>
        </Box>
      </div>
    </>
  );
};

export default ProjectsDetailBasePlansTab;
