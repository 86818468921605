import { useState } from "react";

// ? REDUX
import { approvePurchseOrderAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import PurchaseOrderEmailForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderEmailForm";
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { PurchaseOrderReleaseRequest } from "resource/interfaces/purchase-order";
import { CircularProgress } from "@mui/material";

interface emailData {
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
}

export const nameCreateApproveEmail = `${PURCHASE_ORDER_NAME}/createApproveEmail`;

const PurchaseOrderCreateApproveEmail = () => {
  const approvePurchaseOrder = useAsync(approvePurchseOrderAction);
  const { close, data } = useModal(nameCreateApproveEmail);
  const [isLoading, setLoader] = useState<boolean>(false);

  const handleApprovePurchaseOrder = async (emailValues: emailData) => {
    const dataToSubmit: PurchaseOrderReleaseRequest = { ...data, ...emailValues };

    setLoader(true);
    try {
      await approvePurchaseOrder({ data: dataToSubmit, actionType: PurchaseOrderEndpoints.APPROVE });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreateApproveEmail}>
      {!data.idvendor ? (
        <CircularProgress />
      ) : (
        <PurchaseOrderEmailForm
          handleSubmit={handleApprovePurchaseOrder}
          title="Approve Purchase Order"
          buttonText="Approve PO"
          isLoading={isLoading}
          handleClose={close}
          total={data.total}
          subject={`Approve >>> ${data.nproject} ${data.iddoc}`}
          idvendor={data.idvendor}
        />
      )}
    </ModalManager>
  );
};

export default PurchaseOrderCreateApproveEmail;
