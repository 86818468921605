// ? ACTIONS
export enum PlansActions {
  ADD_NEW_PLAN = "ADD_NEW_PLAN",
  EDIT_PLAN = "EDIT_PLAN",
  GET_PLAN_BY_ID = "GET_PLAN_BY_ID",
  PUT_PLAN_BY_ID = "PUT_PLAN_BY_ID",
  GET_PLANS = "GET_PLANS",
  FETCH_PLAN_BY_ID = "FETCH_PLAN_BY_ID",
  FETCH_PLANS = "FETCH_PLANS",
  DELETE_PLAN_FROM_SERVER = "DELETE_PLAN_FROM_SERVER",
  DELETE_PLAN_FROM_STORE = "DELETE_PLAN_FROM_STORE",
  CATCH_PLANS_ERROR = "CATCH_PLANS_ERROR",
  CLEAR_PLANS_DATA = "CLEAR_PLANS_DATA",
}

// ? ACTION CREATORS

export const addNewPlanAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: PlansActions.ADD_NEW_PLAN,
    payload,
    promise
  };
};

export const editPlanAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: PlansActions.EDIT_PLAN,
    payload,
    promise
  };
};

export const getPlanAction = (payload: any) => {
  return {
    type: PlansActions.GET_PLAN_BY_ID,
    payload,
  };
};

export const putPlanAction = (payload: any) => {
  return {
    type: PlansActions.PUT_PLAN_BY_ID,
    payload,
  };
};

export const getPlansAction = (payload: any) => {
  return {
    type: PlansActions.GET_PLANS,
    payload,
  };
};

export const fetchPlanAction = (payload: any) => {
  return {
    type: PlansActions.FETCH_PLAN_BY_ID,
    payload,
  };
};

export const fetchPlansAction = (payload: any) => {
  return {
    type: PlansActions.FETCH_PLANS,
    payload,
  };
};

export const deletePlanServerAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: PlansActions.DELETE_PLAN_FROM_SERVER,
    payload,
    promise
  };
};

export const deletePlanStoreAction = (payload: any) => {
  return {
    type: PlansActions.DELETE_PLAN_FROM_STORE,
    payload,
  };
};

export const catchPlansErrorAction = (payload: any) => {
  return {
    type: PlansActions.CATCH_PLANS_ERROR,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: PlansActions.CLEAR_PLANS_DATA,
  };
};
