import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchContractsAction, clearDataAction } from "redux-store/redux-actions/contracts";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import Calendar from "components/shared/calendar/Calendar";
import ContractsList from "./ContractsList";
import ContractsCreate, { nameCreate } from "components/modals/create/ContractsCreate";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

// ? RESOURCES
import { CONTRACTS, CONTRACTS_NAME, contractsFiltersList } from "resource/constants/contracts";
import { formatDate } from "resource/utils";
import { ContractDetailResponse } from "resource/interfaces/contracts";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Contracts.module.css";

const CURRENT_DATE = new Date();
const DEFAULT_START_DATE = new Date(CURRENT_DATE.getFullYear(), 0, 1);
const DEFAULT_END_DATE = new Date(CURRENT_DATE.getFullYear(), 11, 31);
const DEFAULT_DATE_RANGE = [DEFAULT_START_DATE, DEFAULT_END_DATE];

interface ContractsType {
  [Key: number]: ContractDetailResponse;
}

const Contracts = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.contracts.idmenu);
  const contractIds: number[] = useSelector((state: RootStateOrAny) => state.contracts.contractIds);
  const contracts: ContractsType[] = useSelector((state: RootStateOrAny) => state.contracts.contractItems);
  const contractsCount: number = useSelector((state: RootStateOrAny) => state.contracts.contractsCount);
  const hasMoreToLoad: boolean = contractsCount > contractIds.length;
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const { open: openCreate } = useModal(nameCreate);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(contractsFiltersList[0]);

  const additionalData = useMemo(
    () => ({
      status: select,
      date_from: dateRange[0] ? formatDate(dateRange[0]) : null,
      date_to: dateRange[1] ? formatDate(dateRange[1]) : null,
      keywords: searchKeyword,
    }),
    [select, dateRange, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchContractsAction, hasMoreToLoad, paginationLoading, additionalData);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());

    if (evt.target.value === contractsFiltersList[0]) {
      setDateRange([null, null]);
    } else {
      setDateRange(DEFAULT_DATE_RANGE);
    }
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handleDatePickerChange = (update) => {
    setDateRange(update);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      const value = evt.target.value.split(" - ").map((data: string): Date => new Date(data));
      handleDatePickerChange(value);
    }
  };

  const datePickerOptions = {
    startDate,
    endDate,
    selectsRange: true,
    placeholderText: "Filter Date",
  };

  return (
    <>
      <PageHeader pageName="contracts">
        <FilterSelect handleChange={handleFilterChange} select={select} options={contractsFiltersList} />
        <Calendar
          handleKeyDown={handleKeyDown}
          handleDatePickerChange={handleDatePickerChange}
          options={datePickerOptions}
          name="dateRangeFilter"
        />
        <Search value={searchKeyword} placeholder="Search contracts" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={CONTRACTS.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      {contractIds.length <= 0 && !paginationLoading && <NoDataScreen pageName={CONTRACTS_NAME} />}
      {contractIds.length > 0 && <ContractsList contractIds={contractIds} contracts={contracts} />}

      {paginationLoading ? (
        <div className={`LoaderScroll ${!contractIds.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}

      <ContractsCreate className={styles.Modal} />
    </>
  );
};

export default withLayout(Contracts);
