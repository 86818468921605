import { takeLatest, put, StrictEffect } from "redux-saga/effects";
import { MASTER_ROLES_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  MasterRolesActions,
  getMasterRoleByIdAction,
  getMasterRolesAction,
  fetchMasterRoleByIdAction,
  deleteRoleFromStoreAction,
} from "../redux-actions/masterRoles";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchMasterRolesWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${MASTER_ROLES_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getMasterRolesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchMasterRoleByIdWorker(action) {
  const { payload: idrole } = action;

  const { errno, message, data } = yield get(`${MASTER_ROLES_API}/${idrole}`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(getMasterRoleByIdAction(data));
  }
}

function* updateMasterRoleWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${MASTER_ROLES_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Master role was ${actionType}ed successfully`);
    yield put(fetchMasterRoleByIdAction(values.idrole));
    yield put(clearNotifierAction());
  }
}

function* deleteMasterRoleWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${MASTER_ROLES_API}/delete`, { idrole: payload }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Master role was deleted successfully`);
    yield put(deleteRoleFromStoreAction(payload));
    yield put(clearNotifierAction());
  }
}

export function* masterRolesSaga(): Generator<StrictEffect> {
  yield takeLatest(MasterRolesActions.FETCH_MASTER_ROLES, fetchMasterRolesWorker);
  yield takeLatest(MasterRolesActions.FETCH_MASTER_ROLE_BY_ID, fetchMasterRoleByIdWorker);
  yield takeLatest(MasterRolesActions.UPDATE_MASTER_ROLE, updateMasterRoleWorker);
  yield takeLatest(MasterRolesActions.DELETE_MASTER_ROLE, deleteMasterRoleWorker);
}
