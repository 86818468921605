import { useEffect, useState, FC } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchPartUpcAction, createPartAction } from "redux-store/redux-actions/parts";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import PartsForm from "pages/parts/PartsForm";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { PARTS, PARTS_NAME } from "resource/constants/parts";
import { ActionName } from "resource/constants/api";
import { PartOnResponse } from "resource/interfaces/part";
import { nameVendorCreate } from "./PartVendorPricingCreate";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

export const nameCreate = `${PARTS_NAME}/${ActionName.CREATE}`;

const getInitFormValues = (upc: string): PartOnResponse => {
  return {
    description: "",
    upc,
    tags: "",
    formula: "",
    lengthunit: "each",
    taxable: false,
    lumber: false,
    discontinued: false,
    minprice: 0,
    maxprice: 0,
    avgprice: 0,
    photo: "",
    idtrack: 0,
  };
};

interface PartCreateProps {
  className: string;
}

const PartCreate: FC<PartCreateProps> = ({ className }) => {
  const nextUpc = useSelector((state: RootStateOrAny) => state.parts.upc);
  const dispatch = useDispatch();
  const { close, isOpened } = useModal(nameCreate);
  const { open: openPartsVendor } = useModal(nameVendorCreate);

  useEffect(() => {
    if (isOpened) {
      dispatch(fetchPartUpcAction());
    }
  }, [isOpened, dispatch]);

  const [isLoading, setLoader] = useState(false);
  const updatePart = useAsync(createPartAction);

  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      await updatePart({
        values: data,
        actionType: ActionName.CREATE,
      });
      setLoader(false);

      if (data.upc) {
        close();
        openPartsVendor(data);
      }
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      {isOpened && nextUpc ? (
        <div className={styles.PartsAddForm}>
          <PartsForm
            title={PARTS.NEW_MODAL_TITLE}
            formData={getInitFormValues(nextUpc)}
            buttonText={PARTS.NEW_MODAL_SUBMIT_BUTTON}
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PartCreate;
