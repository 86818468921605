export const ASSEMBLIES_NAME = "assemblies";

export const ASSEMBLIES = {
  TITLE: "Assemblies ",
  NEW_MODAL_TITLE: "Add New Assemblies",
  EDIT_MODAL_TITLE: "Edit Assemblies Details",
  NEW_MODAL_PLUS_BUTTON: "Add New Assemblies",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Assemblies",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Assemblies Details",
};
