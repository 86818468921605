import { FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import ContractItem from "./ContractItem";

// ? STYLES
import styles from "./Contracts.module.css";

interface ContractsProps {
  contracts: any[];
  contractIds: string[] | number[];
}

const ContractsList: FC<ContractsProps> = ({ contracts, contractIds }) => {
  const theme = useTheme();

  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);
  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Co No</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Number</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Project No</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography color={theme.palette.primary.main}>Project Name</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Sold</Typography>
        </Grid>
        <Grid item xs={1.5} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
      </TableListHeader>
      {addNewLoader && (
        <div className={styles.Fake}>
          <div className={`${styles.Cover} ${styles.Disabled}`}>
            <CircularProgress size={25} className={styles.Loader} />
          </div>
          <TableListRow>
            <div></div>
          </TableListRow>
        </div>
      )}
      {contractIds.map((id: string | number, i: number) => (
        <div key={String(id) + i}>
          <ContractItem contract={contracts[id]} />
        </div>
      ))}
    </>
  );
};

export default ContractsList;
