import { FC } from "react";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Select, MenuItem, Typography, useTheme, TextField, InputLabel, FormGroup } from "@mui/material";

import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { costCodesSchema } from "resource/schemas/costCodesSchema";

// ? STYLES
import { CostCodeOnResponse } from "resource/interfaces/cost-codes";

interface CostCodesFormProps {
  title: string;
  buttonText: string;
  formData: any;
  handleClose: () => void;
  handleSubmit: (values: CostCodeOnResponse) => void;
  costCodesGroups: string[];
  isLoading: boolean;
  isUpdate?: boolean;
}

const CostCodesForm: FC<CostCodesFormProps> = ({
  title,
  buttonText,
  formData,
  handleClose,
  handleSubmit,
  costCodesGroups,
  isLoading,
  isUpdate,
}) => {
  const theme = useTheme();
  return (
    <div className="Form">
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={costCodesSchema}
      >
        {(values: any) => (
          <Form>
            <div className="FormHeader">
              <Typography variant="h1" color={theme.palette.text.primary}>
                {title}
              </Typography>
              <IconButton onClick={() => handleClose()}>
                <img src={closeButtonIcon} alt="close-button" />
              </IconButton>
            </div>
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="costcode">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Cost Code
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="costcode" id="costcode" placeholder="Enter Cost Code Name" />
                  <ErrorMessage name="costcode">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="number">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Cost Code Number
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} disabled={isUpdate} type="number" name="number" id="number" placeholder="Enter number" />
                  <ErrorMessage name="number">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="group">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Cost Type
                    </Typography>
                  </InputLabel>
                  <Field name="group" id="group" type="select" as={Select}>
                    {costCodesGroups.map((value) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  <ErrorMessage name="group">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="description">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Brief Description
                    </Typography>
                  </InputLabel>
                  <Field
                    className="Textarea"
                    type="textarea"
                    name="description"
                    id="description"
                    as={TextField}
                    placeholder="Enter brief description here"
                    multiline
                    minRows={5}
                  />
                  <ErrorMessage name="description">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
            </Grid>
            <Box className="FormFooter">
              <SubmitButton text={buttonText} isLoading={isLoading} />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CostCodesForm;
