import { useState, FC } from "react";

// ? REDUX
import { addProjectTakeoffPartAction } from "redux-store/redux-actions/projectTakeoffs";

// ? COMPONENTS
import useAsync from "custom-hooks/useAsync";
import TakeoffsPartForm from "pages/takeoffs/TakeoffsPartForm";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { useNotice } from "custom-hooks/useNotice";

// ? RESOURCES
import { noticeTypes } from "resource/consts";
import { TakeoffAddPartData } from "resource/interfaces";

interface ProjectTakeoffAddPartProps {
  assemblyId: number;
  takeoffId: string;
  takeoffIdcia: number;
}

const ProjectTakeoffAddPart: FC<ProjectTakeoffAddPartProps> = ({ assemblyId, takeoffId, takeoffIdcia }) => {
  const dataForFetch = { id: takeoffId, idcia: takeoffIdcia };
  const addProjectTakeoffPart = useAsync(addProjectTakeoffPartAction);
  const [loader, setLoader] = useState(false);
  const [setNotice] = useNotice();

  const handleSubmit = async (formData: TakeoffAddPartData, resetForm: () => void, resetControlledFields: () => void) => {
    setLoader(true);
    const values = {
      idproject1: String(takeoffId),
      idcia: String(takeoffIdcia),
      ...formData,
    };
    try {
      await addProjectTakeoffPart({
        values,
        endpoint: TakeoffEndpoints.AddPart,
        dataForFetch,
      });
      setLoader(false);
      resetForm();
      resetControlledFields();
      setNotice("Part was successfully added", noticeTypes.success, 4000);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return <TakeoffsPartForm handleSubmit={handleSubmit} assemblyId={assemblyId} isLoading={loader} />;
};

export default ProjectTakeoffAddPart;
