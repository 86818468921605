// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { VendorsListHeader } from "./VendorsListHeader";
import { VendorsListCard } from "./VendorsListCard";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import { Box, CircularProgress } from "@mui/material";

// ? STYLES
import styles from "./Vendors.module.css";

export const VendorsList = () => {
  const vendors = useSelector((state: RootStateOrAny) => state.vendors.vendors);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  if (!paginationLoading && !vendors.length) {
    return <NoDataScreen pageName="vendors" />;
  }

  return (
    <Box className={styles.BasePlansList}>
      <VendorsListHeader />
      {addNewLoader && (
        <VendorsListCard data={{}} fake />
      )}
      {vendors.map((vendor, index) => (
        <VendorsListCard
          key={index}
          fake={false}
          data={vendor}
        />
      ))}
      {paginationLoading && (
        <div className={`LoaderScroll ${!vendors.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
