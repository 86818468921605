import { useEffect, useState, FC } from "react";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Select, MenuItem, Typography, useTheme, TextField, InputLabel, FormGroup } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import SmallLoader from "components/shared/small-loader/SmallLoader";
import useHeaders from "custom-hooks/useHeaders";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { planVariablesSchema } from "resource/schemas/planVariablesSchema";
import { PLAN_VARIABLES_API } from "resource/constants/api";

interface PlanVariablesFormProps {
  title: string;
  buttonText: string;
  formData: any;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
  isLoading: boolean;
}

const PlanVariablesForm: FC<PlanVariablesFormProps> = ({ title, buttonText, formData, handleClose, handleSubmit, isLoading }) => {
  const theme = useTheme();
  const headers = useHeaders();
  const [options, setOptions] = useState<null | string[]>(null);

  useEffect(() => {
    fetch(`${PLAN_VARIABLES_API}/getTypes`, {
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        setOptions(response.data.typeList);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className="Form">
        <Formik
          initialValues={formData}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={planVariablesSchema}
        >
          <Form>
            <div className="FormHeader">
              <Typography variant="h1" color={theme.palette.text.primary}>
                {title}
              </Typography>
              <IconButton onClick={() => handleClose()}>
                <img src={closeButtonIcon} alt="close-button" />
              </IconButton>
            </div>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="name">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Variables
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="name" id="name" placeholder="Enter variables name" />
                  <ErrorMessage name="name">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="xtype">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Type
                    </Typography>
                  </InputLabel>
                  <Field name="xtype" id="xtype" type="select" as={Select}>
                    {options ? (
                      options.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <SmallLoader />
                    )}
                  </Field>
                  <ErrorMessage name="xtype">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="xvalue">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Default Value
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="xvalue" id="xvalue" placeholder="Enter number" />
                  <ErrorMessage name="xvalue">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="sort">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Sort
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="sort" id="sort" placeholder="Enter number" />
                  <ErrorMessage name="sort">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={6}>
                <FormGroup>
                  <InputLabel htmlFor="varname">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Variable ID
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="varname" id="varname" placeholder="Enter Variable ID" />
                  <ErrorMessage name="varname">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
            </Grid>
            <Box className="FormFooter">
              <SubmitButton text={buttonText} isLoading={isLoading} />
            </Box>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default PlanVariablesForm;
