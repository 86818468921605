import { Colors } from "resource/consts";

export const PURCHASE_ORDER_NAME = "purchaseOrder";

export const fileFolders = {
  DOCUMENTS: "Documents",
  CLAIMS: "Claims",
};

export const PURCHASE_ORDER = {
  TITLE: "Purchase Order",
  NEW_MODAL_TITLE: "Add New Purchase Order",
  EDIT_MODAL_TITLE: "Update Purchase Order",
  NEW_MODAL_PLUS_BUTTON: "Add New P/O",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Purchase Order",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Purchase Order",
};

export const purchseOrderFiltersList = [
  "-All-",
  "Active",
  "Draft",
  "Pending",
  "Released",
  "Disapproved",
  "Billed",
  "Print",
  "Check",
  "Paid",
  "Void",
];

export const PurchaseOrderEndpoints = {
  CREATE_DRAFT: "createdraft",
  UPDATE_DRAFT: "updatedraft",
  INSERT_PART: "insertpart",
  UPDATE_PART: "updatepart",
  DELETE_PART: "deletepart",
  FOR_APPROVE: "forApprove",
  DISAPPROVE: "disapprove",
  APPROVE: "approve",
  RE_OPEN: "reopen",
  RELEASE: "release",
  VOID: "void",
};

export const ActionButtons = {
  update: {
    color: Colors.BLUE,
    text: "Edit P/O",
    action: PurchaseOrderEndpoints.UPDATE_DRAFT,
  },
  requestApproval: {
    color: Colors.ORANGE,
    text: "Request Approval",
    action: PurchaseOrderEndpoints.FOR_APPROVE,
  },
  saveAndRelease: {
    color: Colors.GREEN,
    text: "Release",
    action: PurchaseOrderEndpoints.RELEASE,
  },
  approve: {
    color: Colors.GREEN,
    text: "Approve",
    action: PurchaseOrderEndpoints.APPROVE,
  },
  disapprove: {
    color: Colors.RED,
    text: "Disapprove",
    action: PurchaseOrderEndpoints.DISAPPROVE,
  },
  void: {
    color: Colors.RED,
    text: "Void",
    action: PurchaseOrderEndpoints.VOID,
  },
  reOpen: {
    color: Colors.GREY,
    text: "Re Open",
    action: PurchaseOrderEndpoints.RE_OPEN,
  },
  requestPayment: {
    color: Colors.ORANGE,
    text: "Request Payment",
    action: "",
  },
  approvePayment: {
    color: Colors.GREEN,
    text: "Approve Payment",
    action: "",
  },
  bookPayment: {
    color: Colors.BLUE,
    text: "Book Payment",
    action: "",
  },
  print: {
    color: Colors.GREY,
    text: "Print",
    action: "",
  },
  email: {
    color: Colors.BLUE,
    text: "Email",
    action: "",
  },
};
