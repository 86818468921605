import { useEffect, useMemo, useState } from "react";

// ?REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { fetchDataAction, clearDataAction, getVendorAction } from "redux-store/redux-actions/vendorPricing";

// ? COMPONENTS
import { CircularProgress } from "@mui/material";
import { VendorPricingList } from "./VendorPricingList";
import { withLayout } from "hoc/withLayout";
import { Search } from "components/search/Search";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useSearchUpdate from "custom-hooks/useSearchUpdate";

export const VendorPricing = () => {
  const dispatch = useDispatch();
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const data = useSelector((state: RootStateOrAny) => state.vendorPricing.vendorPricing);
  const dataCount = useSelector((state: RootStateOrAny) => state.vendorPricing.vendorPricingCount);
  const hasMoreToLoad = dataCount > data.length;

  const idgeoarea = 1;

  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const additionalData = useMemo(
    () => ({
      idgeoarea: 1,
      keywords: searchKeyword,
    }),
    [searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchDataAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  useEffect(() => {
    dispatch(getVendorAction({}));
    dispatch(clearDataAction());
  }, [dispatch]);

  return (
    <>
      <PageHeader pageName="vendorPricing">
        <Search value={searchValue} placeholder="Search vendor pricing" handleChange={handleSearchChange} />
      </PageHeader>

      <VendorPricingList data={data} paginationLoading={paginationLoading} idgeoarea={idgeoarea} />
      {paginationLoading && (
        <div className={`LoaderScroll ${!data.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(VendorPricing);
