import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Typography, useTheme, Box, Grid, TextField, InputLabel, FormGroup } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import LandingNavigation from "pages/landing/LandingNavigation";
import AppBar from "components/app-bar/AppBar";
import { Plans } from "resource/constants/landing";
import { capitalizeChar } from "resource/utils";
import styles from "./Landing.module.css";
import bgImage from "resource/images/pay-image.png";
import cardVisa from "resource/images/card-visa.svg";
import cardMC from "resource/images/card-mc.svg";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ROUTES } from "resource/routes/routes";

import { resolvePath } from "resource/utils";
const LandingPay = ({ plan }) => {
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push(resolvePath(ROUTES.success.pathname));
    }, 1000);
  };

  return (
    <>
      <AppBar navigation={<LandingNavigation />} />

      <Container maxWidth="xl" color={theme.palette.secondary.light}>
        <Grid container className={styles.PayInfo}>
          <Grid
            item
            md={6}
            sx={{
              p: "50px 100px",
            }}
            className={styles.PayShadow}
          >
            <Box>
              <Typography variant="h1" sx={{ color: "rgba(26, 26, 26, 0.85)", pb: "10px", fontSize: "30px" }}>{`Subscribe to the
               ${capitalizeChar(Plans[plan].planName)} Plan`}</Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography className={styles.PayPrice}>{`$${Plans[plan].price}.00`}</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "rgba(26, 26, 26, 0.8)" }}>per</Typography>
                  <Typography sx={{ color: "rgba(26, 26, 26, 0.8)" }}>month</Typography>
                </Grid>
              </Grid>
              <Typography sx={{ color: "rgba(26, 26, 26, 0.8)", pt: "10px", pb: "40px" }}>
                Team members get access to all Buildersaccess content and channels.
              </Typography>
              <img src={bgImage} alt="decor" style={{ width: "100%" }} />
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              p: "50px 100px",
            }}
          >
            <Typography variant="h1" sx={{ color: "rgba(26, 26, 26, 0.85)", pb: "10px", fontSize: "30px" }}>
              Pay with Card
            </Typography>
            <Formik
              initialValues={{
                email: "",
                cvc: "",
                expiry: "",
                focus: "",
                name: "",
                number: "",
              }}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <Form style={{ paddingLeft: 0, paddingRight: 0, margin: 0 }}>
                <Field as={TextField} type="email" name="email" id="email" placeholder="Enter Email" />
                <FormGroup>
                  <InputLabel htmlFor="sort" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Card information
                    </Typography>
                    <Box>
                      <img src={cardVisa} alt="Visa logo" style={{ width: "25px", marginRight: "5px" }} />
                      <img src={cardMC} alt="Visa logo" style={{ width: "25px" }} />
                    </Box>
                  </InputLabel>
                  <Field as={TextField} type="number" name="number" id="number" placeholder="1234 1234 1234 1234" />
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Field as={TextField} sx={{ width: "100%" }} type="number" name="cvc" id="cvc" placeholder="CVC" />
                    </Grid>

                    <Grid item md={6}>
                      <Field as={TextField} sx={{ width: "100%" }} type="text" name="expiry" id="expiry" placeholder="MM / YY" />
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup>
                  <InputLabel htmlFor="name">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Name on card
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="name" id="name" placeholder="" />
                </FormGroup>
                <FormGroup>
                  <InputLabel htmlFor="country">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Country or region
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="country" id="country" placeholder="Enter country name" />
                </FormGroup>
                <SubmitButton callback={handleSubmit} text="Subscribe" isLoading={isLoading} />
              </Form>
            </Formik>
            <Typography textAlign="center" sx={{ color: "rgba(26, 26, 26, 0.65)" }}>
              By confirming your subscription, you allow BuildersAccess to charge your card for this payment and future payments
              in accordance with their terms.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LandingPay;
