import { normalize, schema } from "normalizr";

interface normilizeType<T, N> {
  entities: { [key: string]: { [key: number]: T } | undefined };
  result: N;
}

export const normalizeItemsList = <L>(data: L, keyName: string, idAttribute = "idnumber"): normilizeType<L, number[]> => {
  const items = new schema.Entity(
    keyName,
    {},
    {
      idAttribute,
    }
  );
  const itemsSchema = [items];

  return normalize(data, itemsSchema);
};

export const normalizeItemById = <I>(
  data: I,
  keyName: string,
  listName = "list",
  itemIdAttribute = "idnumber",
  listIdAttribute = "idnumber"
): normilizeType<I, number> => {
  const list = new schema.Entity(listName, {}, { idAttribute: listIdAttribute });
  const item = new schema.Entity(keyName, { list: [list] }, { idAttribute: itemIdAttribute });

  return normalize(data, item);
};

export const normalizeTakeoffItemById = <I>(data: I, keyName: string, itemIdAttribute = "idnumber"): normilizeType<I, number> => {
  const parts = new schema.Entity("parts", {}, { idAttribute: "idnumber" });
  const nestedList = new schema.Array(parts);
  const budget = new schema.Entity("budget", {}, { idAttribute: "code" });
  const list = new schema.Entity("list", { list: nestedList }, { idAttribute: "idassembly1" });
  const item = new schema.Entity(keyName, { budget: [budget], list: [list] }, { idAttribute: itemIdAttribute });

  return normalize(data, item);
};

export const normalizeMenuList = <L>(data: L, keyName: string, idAttribute = "idmenu"): normilizeType<L, number[]> => {
  const menulist2 = new schema.Entity("menulist", {}, { idAttribute: "idmenu" });
  const nestedList = new schema.Array(menulist2);
  const items = new schema.Entity(
    keyName,
    { menulist: nestedList },
    {
      idAttribute,
    }
  );
  const itemsSchema = [items];

  return normalize(data, itemsSchema);
};
