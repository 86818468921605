import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchProjectFieldsAction, clearDataDetailAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { Field } from "formik";
import { Box, CircularProgress, TextField } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { TableListRow } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import { getFormattedDateTime, formattedDateTimeNow } from "resource/utils";
import { menu } from "resource/constants/menu-list";

const ProjectsDetailGeneralTab = ({ idnumber, idcia, callback }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { hasEditAccess } = useAccess(menu.projectPage.idmenu);
  const user = useSelector((state: RootStateOrAny) => state.login.userData);

  const fields = useSelector((state: RootStateOrAny) => state.projects.projectFields);
  const fieldsCount = useSelector((state: RootStateOrAny) => state.projects.projectFieldsCount);
  const hasMoreToLoad = fieldsCount > fields.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia: Number(idcia),
    }),
    [idnumber, idcia]
  );

  const localData = [...fields];

  useInfiniteLoading(fetchProjectFieldsAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDataDetailAction("Fields"));
    };
  }, []);

  return (
    <>
      <Box className={styles.ProjectsDetails}>
        <TableListHeader>
          <Grid item xs={1}>
            <Typography color={theme.palette.primary.main}>Field ID</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color={theme.palette.primary.main}>Field Name</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main}>Field Type</Typography>
          </Grid>
          <Grid item xs={4.5}>
            <Typography color={theme.palette.primary.main}>Notes</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main}>Updated By</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
              Date
            </Typography>
          </Grid>
        </TableListHeader>
        <div className={styles.FormList}>
          {fields.length > 0 &&
            fields.map((field, index) => (
              <div key={index}>
                <TableListRow>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>{field.idnumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color={theme.palette.text.primary}>{field.name}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary}>{field.xtype}</Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    {hasEditAccess && (
                      <Field
                        as={TextField}
                        className={styles.ListInput}
                        placeholder="Enter note"
                        value={field.txtvalue}
                        onChange={(e) => {
                          localData[index].txtvalue = e.target.value;
                          localData[index].modiby = user.username;
                          localData[index].modidate = formattedDateTimeNow();
                          localData[index].changed = true;
                          if (callback) {
                            callback(localData);
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary}>{field.modiby}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                      {field.modidate && getFormattedDateTime(field.modidate)}
                    </Typography>
                  </Grid>
                </TableListRow>
              </div>
            ))}
          {paginationLoading ? (
            <div className={`LoaderScroll ${!fields.length && "center"}`}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default ProjectsDetailGeneralTab;
