// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { VendorsPricingDetailsListHeader } from "./VendorsPricingDetailsListHeader";
import { VendorsPricingDetailsListCard } from "./VendorsPricingDetailsListCard";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import { Box, CircularProgress } from "@mui/material";

// ? STYLES
import styles from "./VendorPricing.module.css";

// ? TYPES
interface VendorsPricingDetailsListProps {
  data: any;
}

export const VendorsPricingDetailsList = ({ data }: VendorsPricingDetailsListProps) => {
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  if (!paginationLoading && !data.length) {
    return <NoDataScreen pageName="vendorsPricing" />;
  }

  return (
    <Box className={styles.BasePlansList}>
      <VendorsPricingDetailsListHeader />
      {addNewLoader && <VendorsPricingDetailsListCard data={{}} fake />}
      {data.map((vendorDetails, index) => (
        <VendorsPricingDetailsListCard key={index} data={vendorDetails} />
      ))}
      {paginationLoading && (
        <div className={`LoaderScroll ${!data.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
