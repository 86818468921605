import { useState } from "react";

// ? REDUX
import { addPlanTakeoffPartAction } from "redux-store/redux-actions/planTakeoffs";

// ? COMPONENTS
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import TakeoffsPartForm from "pages/takeoffs/TakeoffsPartForm";

// RESOURCES
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { noticeTypes } from "resource/consts";

const PlanTakeoffAddPart = ({ assemblyId, takeoffId }) => {
  const addPlanTakeoffPart = useAsync(addPlanTakeoffPartAction);
  const [loader, setLoader] = useState(false);
  const [setNotice] = useNotice();

  const handleSubmit = async (formData, resetForm, resetControlledFields) => {
    setLoader(true);
    const values = {
      idplan1: String(takeoffId),
      ...formData,
    };
    try {
      await addPlanTakeoffPart({
        values,
        endpoint: TakeoffEndpoints.AddPart,
        dataForFetch: { id: takeoffId },
      });
      resetForm();
      resetControlledFields();
      setNotice("Part was successfully added", noticeTypes.success, 4000);
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return <TakeoffsPartForm handleSubmit={handleSubmit} assemblyId={assemblyId} isLoading={loader} />;
};

export default PlanTakeoffAddPart;
