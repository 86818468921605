// ? COMPONENTS
import { useTheme, Typography, Grid, Box } from "@mui/material";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { getFormattedDate } from "resource/utils";
import notesIcon from "resource/images/notes.svg";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Contracts.module.css";
import { StatusList } from "resource/consts";

const ContractsFormBasic = ({ formik, contract, isDisabled }) => {
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.contracts.idmenu);

  return (
    <>
      <Box className={styles.NoteTextareaBox} display="flex" alignItems="center">
        <img src={notesIcon} alt="Notes icon" style={{ width: "17px", marginRight: "20px" }} />
        <textarea
          rows={3}
          cols={25}
          className={styles.NoteTextareaInput}
          name="formBasic.notes"
          value={formik.values.formBasic.notes}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={isDisabled || !hasEditAccess}
        />
      </Box>
      <Grid container sx={{ my: 3 }} spacing={2}>
        <Grid item sm={3} md={2} className={styles.Divider}>
          <Typography variant="h3" color={theme.palette.text.primary} sx={{ mb: "8px" }}>
            Contract Number:
          </Typography>
          <Typography variant="body1" color={theme.palette.info.main} sx={{ mb: "8px" }}>
            {contract.idnumber}
          </Typography>
        </Grid>
        <Grid item sm={3} md={2} className={styles.Divider}>
          <Typography variant="h3" color={theme.palette.text.primary} sx={{ mb: "8px" }}>
            Created by:
          </Typography>
          <Typography variant="body1" color={theme.palette.info.main}>
            {contract.creaby}
          </Typography>
        </Grid>
        <Grid item sm={3} md={2} className={styles.Divider}>
          <Typography variant="h3" color={theme.palette.text.primary} sx={{ mb: "8px" }}>
            Contract Date:
          </Typography>
          <Typography variant="body1" color={theme.palette.info.main}>
            {getFormattedDate(contract.refdate)}
          </Typography>
        </Grid>

        {contract.status === StatusList.CLOSED && (
          <Grid item sm={3} md={2} className={styles.Divider}>
            <Typography variant="h3" color={theme.palette.text.primary} sx={{ mb: "8px" }}>
              Closed Date:
            </Typography>
            <Typography variant="body1" color={theme.palette.info.main}>
              {getFormattedDate(contract.closed_date)}
            </Typography>
          </Grid>
        )}
        {contract.approved_by && (
          <Grid item sm={3} md={2} className={styles.Divider}>
            <Typography variant="h3" color={theme.palette.text.primary} sx={{ mb: "8px" }}>
              Approved By:
            </Typography>
            <Typography variant="body1" color={theme.palette.info.main}>
              {`${contract.approved_by} @ ${getFormattedDate(contract.approved_date)}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContractsFormBasic;
