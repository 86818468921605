import { useState, useEffect, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPaymentSummaryDetailsAction, clearPaymentSummaryDetailsDataAction } from "redux-store/redux-actions/paymentSummary";

// ? COMPONENTS
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import FilterSelect from "components/shared/filter-select/FilterSelect";

import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import PaymentSummaryDetailHeader from "./PaymentSummaryDetailHeader";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import { formatPrice } from "resource/utils";
import { TableListDivider } from "components/shared/table-list/TableList";

// ? RESOURCES
import { PAYMENT_SUMMARY_NAME, paymentSummaryDetailsFiltersList } from "resource/constants/payment-summary";

const PaymentSummaryDetail = ({ idcia, id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const paymentSummaryDetails = useSelector((state: RootStateOrAny) => state.paymentSummary.paymentSummaryDetails);
  const paymentSummaryDetailsCount = useSelector((state: RootStateOrAny) => state.paymentSummary.paymentSummaryDetailsCount);
  const hasMoreToLoad = paymentSummaryDetailsCount > paymentSummaryDetails.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(paymentSummaryDetailsFiltersList[0]);

  const additionalData = useMemo(
    () => ({
      filter: select,
      keywords: searchKeyword,
      idcia,
      idproject: id,
    }),
    [select, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchPaymentSummaryDetailsAction, hasMoreToLoad, paginationLoading, additionalData);

  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearPaymentSummaryDetailsDataAction());
  };

  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearPaymentSummaryDetailsDataAction());
  };

  useEffect(() => {
    return () => {
      dispatch(clearPaymentSummaryDetailsDataAction());
    };
  }, []);

  return (
    <>
      <PaymentSummaryDetailHeader idnumber={id}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={paymentSummaryDetailsFiltersList} />
        <Search value={searchKeyword} placeholder="Search payment summary" handleChange={handleSearchChange} />
      </PaymentSummaryDetailHeader>
      <TableListHeader>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>ID</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Vendor Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Contact</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Category</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Phone</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Mobile</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.primary.main}>Email</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Paid</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Open POs</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Total</Typography>
        </Grid>
      </TableListHeader>
      {paymentSummaryDetails.length > 0 && !paginationLoading && (
        <>
          {paymentSummaryDetails.map((item, i) => (
            <div key={i}>
              <TableListRow>
                <Grid item xs={0.5}>
                  <Typography color={theme.palette.text.primary}>{item.idnumber}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography color={theme.palette.text.primary}>{item.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.text.primary}>{item.contact}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color={theme.palette.text.primary}>{item.category}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color={theme.palette.text.primary}>{item.phone1}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color={theme.palette.text.primary}>{item.phone2}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography color={theme.palette.text.primary}>{item.email}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color={theme.palette.text.primary}>
                    {item.mtotal1 > 0 && formatPrice(item.mtotal1.toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item xs={0.5}>
                  <Typography color={theme.palette.text.primary}>
                    {item.mtotal2 > 0 && formatPrice(item.mtotal2.toFixed(2))}
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" display="flex">
                  <Typography color={theme.palette.text.primary}>
                    {item.mtotal > 0 && formatPrice(item.mtotal.toFixed(2))}
                  </Typography>
                </Grid>
              </TableListRow>
            </div>
          ))}
          <TableListDivider>
            <Grid item xs={10.5}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(paymentSummaryDetails.reduce((prev, item) => prev + item.mtotal2, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <Typography color={theme.palette.text.primary}>
                {formatPrice(paymentSummaryDetails.reduce((prev, item) => prev + item.mtotal, 0).toFixed(2))}
              </Typography>
            </Grid>
          </TableListDivider>
        </>
      )}

      {paymentSummaryDetails.length <= 0 && !paginationLoading && <NoDataScreen pageName={PAYMENT_SUMMARY_NAME} />}
      {paginationLoading ? (
        <div className={`LoaderScroll ${!paymentSummaryDetails.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(PaymentSummaryDetail);
