import { useState } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { loginAction } from "redux-store/redux-actions/login";
import { Redirect } from "react-router-dom";

// ? COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  Divider,
  Alert,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Grid,
  FormGroup,
  TextField,
  useTheme,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? STYLES
import styles from "./login.module.css";

// ? RESOURCES
import logo from "resource/images/logo.svg";
import visibilityOnIcon from "resource/images/visibility-on.svg";
import visibilityOffIcon from "resource/images/visibility-off.svg";
import { LOGIN } from "resource/constants/login";
import { loginSchema } from "resource/schemas/loginSchema";
import { ROUTES } from "resource/routes/routes";

export const Login = () => {
  const theme = useTheme();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const dispatch = useDispatch();
  const uploadLoading = useSelector((state: RootStateOrAny) => state.loaders.buttonLoader);
  const serverMessage = useSelector((state: RootStateOrAny) => state.notifier.notification);
  const isAuthorized = useSelector((state: RootStateOrAny) => state.login.isAuthorized);

  const login = (values) => {
    const { appname, email, password } = values;
    const loginData = {
      appname,
      email,
      password,
    };
    dispatch(loginAction(loginData));
    localStorage.setItem("appname", appname);
    localStorage.setItem("login", email);
  };

  if (isAuthorized) {
    return <Redirect to={ROUTES.companies} />;
  }

  return (
    <div className={styles.LoginPage}>
      {serverMessage && <Alert severity={serverMessage.type}>{`${serverMessage.message}`}</Alert>}

      <Formik
        initialValues={{
          appname: localStorage.getItem("appname"),
          email: localStorage.getItem("login"),
          password: "",
        }}
        onSubmit={(values) => {
          login(values);
        }}
        validationSchema={loginSchema}
      >
        <Form className={styles.LoginForm}>
          <img src={logo} alt="logo" />
          <Typography variant="h2" className={styles.LoginPageHeading}>
            Let’s log in!
          </Typography>
          <Typography variant="body1" className={styles.LoginPageText}>
            Please enter the needful informations below to login as a user on Builder Access
          </Typography>
          <Divider className={styles.LoginPageDivider}></Divider>

          <FormField
            size="100%"
            title="Server Name"
            name="appname"
            placeholder="Enter server name"
            type="text"
          />
          <FormField
            size="100%"
            title="User Email"
            name="email"
            placeholder="Enter user email"
            type="email"
          />

          <Grid item style={{ width: "100%" }}>
            <FormGroup className={styles.FormField}>
              <InputLabel htmlFor="password">
                <Typography variant="body1" color={theme.palette.info.main}>
                  Password
                </Typography>
              </InputLabel>
              <div style={{ position: "relative" }}>
                <Field
                  as={TextField}
                  id="password"
                  name="password"
                  placeholder=""
                  type={values.showPassword ? "text" : "password"}
                />
                <InputAdornment position="end" className={styles.LoginFormAdornment}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    className={styles.LoginFormVisibilityIcon}
                  >
                    {values.showPassword ? (
                      <img src={visibilityOnIcon} style={{ width: "15px" }} alt="Visible" />
                    ) : (
                      <img src={visibilityOffIcon} style={{ width: "15px" }} alt="invisible" />
                    )}
                  </IconButton>
                </InputAdornment>
              </div>

              <ErrorMessage name="password">
                {(msg) => <ValidationError>{msg}</ValidationError>}
              </ErrorMessage>
            </FormGroup>
          </Grid>
          <SubmitButton text={LOGIN.LOGIN_SUBMIT_BUTTON} isLoading={uploadLoading} />
        </Form>
      </Formik>
    </div>
  );
};
