import { FC, useState } from "react";

// ? COMPONENTS
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { IconButton, Typography, Grid, Checkbox, useTheme, FormGroup, InputLabel, Box, TextField } from "@mui/material";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import StatusSubmitButtons from "components/shared/status-submit-buttons/StatusSubmitButtons";

// ? RESOURCES
import checkboxCheckedIcon from "resource/images/checkbox-checked-icon.svg";
import { changeOrderUpdateSchema } from "resource/schemas/changeOrderSchema";

// ? STYLES
import styles from "./ChangeOrder.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import plusIcon from "resource/images/plus.svg";
import deleteIcon from "resource/images/delete.svg";
import { TableListDivider } from "components/shared/table-list/TableList";
import { formatPrice } from "resource/utils";

interface ChangeOrderEditFormProps {
  title: string;
  handleSubmit: (values: any, deleted: any) => void;
  formData: any;
  handleClose: () => void;
  isLoading: boolean;
  itemIndex: number;
}

const ChangeOrderEditForm: FC<ChangeOrderEditFormProps> = ({
  title,
  handleSubmit,
  formData,
  handleClose,
  isLoading,
  itemIndex,
}) => {
  const theme = useTheme();
  const [deleted, setDeleted] = useState<any>([]);

  return (
    <>
      <div className={styles.CreateFormLarge}>
        <Formik
          initialValues={formData}
          onSubmit={(values: any) => handleSubmit(values, deleted)}
          validationSchema={changeOrderUpdateSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container spacing={2} sx={{ mb: "10px" }}>
                <Grid item sm={12}>
                  <FormGroup>
                    <InputLabel htmlFor="notes">
                      <Typography variant="body1" color={theme.palette.info.main}>
                        Notes
                      </Typography>
                    </InputLabel>
                    <Field
                      className={styles.ChangeOrderTextarea}
                      type="textarea"
                      name="notes"
                      id="notes"
                      as={TextField}
                      placeholder="Enter notes here"
                      multiline
                      minRows={1}
                    />
                  </FormGroup>
                  <ErrorMessage name="notes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </Grid>
              </Grid>
              <Grid>
                <Box className={styles.Group}>
                  <FieldArray
                    name="coPriceList"
                    render={(arrayHelpers) => (
                      <>
                        <div className={styles.GroupHeader}>
                          <Typography variant="body1" color={theme.palette.info.main} className={styles.GroupTitle}>
                            Add Price List
                          </Typography>
                          <div>
                            <IconButton
                              aria-label="delete"
                              onClick={() => arrayHelpers.push({ description: "", price: 0, idnumber: 0, item: itemIndex })}
                            >
                              <img src={plusIcon} alt="" />
                            </IconButton>
                            <Typography color={theme.palette.primary.main}>Add Row</Typography>
                          </div>
                        </div>

                        <div className={styles.PaymentHeader}>
                          <Grid className={styles.HeaderItem} item xs={1}>
                            <Typography color={theme.palette.primary.main}>#</Typography>
                          </Grid>
                          <Grid className={styles.HeaderItem} item xs={7}>
                            <Typography color={theme.palette.primary.main}>Description</Typography>
                          </Grid>
                          <Grid className={styles.HeaderItem} item xs={4}>
                            <Typography color={theme.palette.primary.main}>Amount</Typography>
                          </Grid>
                        </div>

                        {values.coPriceList &&
                          values.coPriceList.map(
                            (price: { description: string; price: number; idnumber: number }, index: number) => (
                              <div key={index} className={styles.PaymentRow}>
                                <Grid className={styles.PaymentItem} item xs={1}>
                                  <Typography color={theme.palette.primary.main}>{index + 1}</Typography>
                                  <Field type="number" name={`coPriceList[${index}].item`} hidden />
                                </Grid>
                                <Grid className={styles.PaymentItem} item xs={7}>
                                  <Field
                                    as={TextField}
                                    type="text"
                                    name={`coPriceList[${index}].description`}
                                    placeholder="Enter description"
                                  />
                                </Grid>
                                <Grid className={styles.PaymentItem} item xs={3}>
                                  <Field
                                    as={TextField}
                                    type="number"
                                    name={`coPriceList[${index}].price`}
                                    placeholder="Enter price"
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      setDeleted((prevState) => [...prevState, values.coPriceList[index].idnumber]);
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <img src={deleteIcon} alt="Delete icon" />
                                  </IconButton>
                                </Grid>
                              </div>
                            )
                          )}
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <TableListDivider>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography color={theme.palette.text.primary}>Total</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color={theme.palette.text.primary} sx={{ pl: "10px" }}>
                      {values.coPriceList.length > 0 &&
                        formatPrice(
                          values.coPriceList.reduce(
                            (acc: number, it: { description: string; price: number }) => acc + Number(it.price),
                            0
                          )
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              </TableListDivider>
              <FormGroup>
                <Box display="flex">
                  <Field
                    name="agreeyn"
                    id="agreeyn"
                    type="checkbox"
                    as={Checkbox}
                    checkedIcon={<img alt="checkbox" src={checkboxCheckedIcon} style={{ width: "24px", height: "24px" }} />}
                  />
                  <InputLabel htmlFor="agreeyn" sx={{ m: 0, display: "flex" }}>
                    <Typography variant="body1" color={theme.palette.info.main} sx={{ alignSelf: "center" }}>
                      We agreed hereby to make the change(s) specified above at this price
                    </Typography>
                  </InputLabel>
                  <ErrorMessage name="agreeyn">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </Box>
              </FormGroup>

              <Field hidden type="text" name="status" />
              <StatusSubmitButtons isLoading={isLoading} setFieldValue={setFieldValue} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangeOrderEditForm;
