import { useState, FC } from "react";

// ? REDUX
import { addNewCostCodeAction } from "redux-store/redux-actions/costCodes";

// ? COMPONENTS
import ModalManager from "../ModalManager";
import CostCodesForm from "pages/cost-codes/CostCodesForm";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

// ?  RESOURCES
import { CostCodeOnResponse } from "resource/interfaces/cost-codes";
import { COST_CODES_NAME, COST_CODES } from "resource/constants/cost-codes";
import { ActionName } from "resource/constants/api";

interface CostCodesCreateProps {
  costCodesGroups: string[];
}

export const nameCreate = `${COST_CODES_NAME}/${ActionName.CREATE}`;

const CostCodesCreate: FC<CostCodesCreateProps> = ({ costCodesGroups }) => {
  const { close } = useModal(nameCreate);
  const [isLoading, setLoader] = useState(false);
  const addNewCostCode = useAsync(addNewCostCodeAction);

  const initialFormValues = {
    costcode: "",
    number: "",
    group: costCodesGroups ? costCodesGroups[0] : "",
    description: "",
  };

  const handleSubmit = async (values: CostCodeOnResponse) => {
    setLoader(true);

    try {
      await addNewCostCode({ values, actionType: ActionName.CREATE });
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate}>
      <CostCodesForm
        title={COST_CODES.NEW_MODAL_TITLE}
        buttonText={COST_CODES.NEW_MODAL_SUBMIT_BUTTON}
        formData={initialFormValues}
        handleClose={close}
        handleSubmit={handleSubmit}
        costCodesGroups={costCodesGroups}
        isLoading={isLoading}
      />
    </ModalManager>
  );
};

export default CostCodesCreate;
