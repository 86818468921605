// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";

export const VendorsListHeader = () => {
  const theme = useTheme();

  return (
    <TableListHeader>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Number</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.primary.main}>Vendor Name</Typography>
      </Grid>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Contact Name</Typography>
      </Grid>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Category</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Phone</Typography>
      </Grid>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Email</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Tax Rate(%)</Typography>
      </Grid>
      <Grid item xs={1} justifyContent="flex-end" display="flex">
        <Typography color={theme.palette.primary.main}>Actions</Typography>
      </Grid>
    </TableListHeader>
  );
};
