// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Box, Grid, Typography, useTheme } from "@mui/material";
import AddNewButton from "components/shared/add-new-button/AddNewButton";

// ? STYLES
import styles from "./Companies.module.css";

// ? RESOURCES
import { COMPANIES } from "resource/constants/companies";

export const CompaniesHeader = ({ children }) => {
  const theme = useTheme();

  const { fname } = useSelector((state: RootStateOrAny) => state.login.userData);

  return (
    <Box className={styles.CompaniesHeader} color={theme.palette.secondary.light}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h1" color={theme.palette.text.primary} sx={{ mb: "10px" }}>
            {`Welcome to Builder Access, ${fname} 😃`}
          </Typography>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your added companies list will be shown below
          </Typography>
        </Grid>
        <Grid className={styles.ControlPanel} item>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
