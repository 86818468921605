import { useState, FC } from "react";

// ? REDUX
import { addNewDevelopmentAction } from "redux-store/redux-actions/developments";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import { DevelopmentsForm } from "pages/developments/DevelopmentsForm";
import useAsync from "custom-hooks/useAsync";

// ? STYLES
import styles from "pages/developments/Developments.module.css";

// ? RESOURCES
import { DEVELOPMENTS, DEVELOPMENTS_NAME } from "resource/constants/developments";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

export const nameCreate = `${DEVELOPMENTS_NAME}/${ActionName.CREATE}`;

interface DevelopmentsCreateProps {
  className: string;
}

const DevelopmentsCreate: FC<DevelopmentsCreateProps> = ({ className }) => {
  const { close, isOpened } = useModal(nameCreate);
  const [setNotice] = useNotice();

  const [isLoading, setLoader] = useState(false);
  const createDevelopment = useAsync(addNewDevelopmentAction);

  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      await createDevelopment({
        values: data,
        actionType: ActionName.CREATE,
      });
      setNotice("Development created", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      {isOpened ? (
        <div className={styles.DevelopmentsAddForm}>
          <DevelopmentsForm
            title={DEVELOPMENTS.NEW_MODAL_TITLE}
            formData={{}}
            buttonText={DEVELOPMENTS.NEW_MODAL_SUBMIT_BUTTON}
            handleClose={close}
            actionType={ActionName.CREATE}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default DevelopmentsCreate;
