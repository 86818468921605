import { useState } from "react";

// ? REDUX
import { deleteProjectMemberAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { CircularProgress, IconButton } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import deleteIcon from "resource/images/delete.svg";
import { menu } from "resource/constants/menu-list";

export const ProjectsDetailsMemersTabItem = ({ member, idsub }) => {
  const theme = useTheme();

  const { hasEditAccess } = useAccess(menu.projectPage.idmenu);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteMemberRequest = useAsync(deleteProjectMemberAction);

  const deleteMember = async ({ data }) => {
    setDeleteLoading(true);

    try {
      await deleteMemberRequest(data);
      setDeleteLoading(false);
    } catch (e) {
      console.error(e);
      setDeleteLoading(false);
    }
  };

  return (
    <TableListRow>
      <Grid item xs={1}>
        <Typography color={theme.palette.text.primary}>{member.idproject1}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{member.fullname}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.text.primary}>{member.username}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={theme.palette.text.primary}>{member.ac}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography color={theme.palette.text.primary}>{member.ndepto}</Typography>
      </Grid>
      {hasEditAccess && (
        <Grid item xs={1}>
          {!deleteLoading ? (
            <IconButton onClick={() => deleteMember({ data: { idnumber: member.idnumber, idsub } })}>
              <img src={deleteIcon} alt="Delete icon" />
            </IconButton>
          ) : (
            <CircularProgress className={styles.CardLoader} />
          )}
        </Grid>
      )}
    </TableListRow>
  );
};
