import React from "react";
import { match as RouterMatch, matchPath, RouteComponentProps, SwitchProps, withRouter } from "react-router";
import { AuthCheckWrapper } from "routes/auth-check-wrapper/AuthCheckWrapper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

type GenericProps = any;

const DefaultWrapper = (props: GenericProps) => {
  return props.children;
};

class SwitchWithAuth extends React.Component<SwitchProps & RouteComponentProps> {
  render() {
    const { children, location, match } = this.props;
    const loc = location;
    let m: RouterMatch<GenericProps> | null = null;
    let child: React.ReactElement<GenericProps> | null = null;
    React.Children.forEach(children, (element) => {
      if (m === null && React.isValidElement(element)) {
        child = element;
        const { path, exact, strict, sensitive, from } = child.props;
        m = matchPath(loc.pathname, { exact, strict, sensitive, path: path || from }, match);
      }
    });
    if (!m) return null;

    const Wrapper = child!.props.wrapper || DefaultWrapper;

    return (
      <AuthCheckWrapper id={child!.props.id} auth={child!.props.auth}>
        <Wrapper {...child!.props}>{React.cloneElement(child!, { location, computedMatch: m })}</Wrapper>
      </AuthCheckWrapper>
    );
  }
}

export default withRouter(SwitchWithAuth);
