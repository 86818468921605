import { useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { editChangeOrderAction } from "redux-store/redux-actions/changeOrders";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import ChangeOrderEditForm from "pages/change-order/ChangeOrderEditForm";

// ? RESOURCES
import { CHANGE_ORDERS_NAME, ChangeOrderEndpoints, CHANGE_ORDERS } from "resource/constants/change-order";
import { ActionName } from "resource/constants/api";
import { CURRENT_TIME_FOR_SERVER } from "resource/consts";

export const nameUpdate = `${CHANGE_ORDERS_NAME}/${ActionName.UPDATE}`;

const ChangeOrderUpdate = ({ className }) => {
  const priceList = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrderLists);
  const { close, data } = useModal(nameUpdate);
  const [isLoading, setLoader] = useState<boolean>(false);
  const editChangeOrder = useAsync(editChangeOrderAction);

  const amounts = data.list && data.list.length > 0 ? data.list.map((item: string) => priceList[item].item + 1) : [0];
  const lastBiggestItem = Math.max(...amounts);

  const priceItemBoilerplate = (item) => {
    return {
      idnumber: item.idnumber,
      item: item.item,
      description: item.description,
      price: Number(item.price),
      updateyn: false,
      deleteyn: false,
    };
  };

  const initialFormValues = {
    idnumber: data.idnumber,
    status: data.status,
    notes: data.notes,
    agreeyn: data.spec,
    coPriceList:
      data.list && data.list.length > 0
        ? data.list.map((item) => {
            return {
              idnumber: Number(priceList[item].idnumber),
              description: priceList[item].description,
              price: priceList[item].price,
              item: Number(priceList[item].item),
            };
          })
        : [{ idnumber: 0, description: "", price: 0, item: 0 }],
  };

  const handleSubmit = async (values, deleted) => {
    const listExist = values.coPriceList.length > 0;
    const total = listExist ? values.coPriceList.reduce((acc: number, it) => acc + Number(it.price), 0) : 0;

    const coPriceListStructured = listExist
      ? values.coPriceList.reduce((acc, priceIt) => {
          if (priceIt.idnumber === 0) {
            acc.push(priceItemBoilerplate(priceIt));
          } else if (
            priceIt.description !== priceList[priceIt.idnumber].description ||
            priceIt.price !== Number(priceList[priceIt.idnumber].price)
          ) {
            acc.push({ ...priceItemBoilerplate(priceIt), updateyn: true });
          }

          return acc;
        }, [])
      : [];

    deleted.length > 0 &&
      deleted.forEach((index) =>
        coPriceListStructured.push({
          ...priceItemBoilerplate(priceList[index]),
          idnumber: Number(index),
          deleteyn: true,
        })
      );

    const preparedDataForSubmit = {
      idnumber: data.idnumber,
      idtrack: data.idtrack,
      status: values.status,
      notes: values.notes,
      agreeyn: values.agreeyn,
      refdate: CURRENT_TIME_FOR_SERVER,
      tcheck: total,
      tcontract: 0,
      total,
      coPriceList: coPriceListStructured,
    };

    setLoader(true);
    try {
      await editChangeOrder({
        data: preparedDataForSubmit,
        actionType: ChangeOrderEndpoints.UPDATE,
      });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={className}>
      <ChangeOrderEditForm
        title={CHANGE_ORDERS.EDIT_MODAL_TITLE}
        handleSubmit={handleSubmit}
        formData={initialFormValues}
        handleClose={close}
        isLoading={isLoading}
        itemIndex={lastBiggestItem}
      />
    </ModalManager>
  );
};

export default ChangeOrderUpdate;
