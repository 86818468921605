// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Typography, Grid, CircularProgress, Box, useTheme } from "@mui/material";
import { MemberProfileFormCompanyAccessTabItem } from "./MemberProfileFormCompanyAccessTabItem";

// ? STYLES
import styles from "./MembersProfile.module.css";

// ? RESOURCES
import { TableListHeader } from "components/shared/table-list/TableList";

export const MemberProfileFormCompanyAccessTab = ({ localCiaList, setLocalCiaList }) => {
  const theme = useTheme();
  const companies = useSelector((state: RootStateOrAny) => state.members.usersCompanies);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  return (
    <Box className={styles.UsersDetailsDataList}>
      <TableListHeader>
        <Grid item xs={2} md={2}>
          <Typography color={theme.palette.primary.main}>Company No.</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography color={theme.palette.primary.main}>Company Name</Typography>
        </Grid>
        <Grid item xs={1.5} md={1.5}>
          <Typography color={theme.palette.primary.main}>Access</Typography>
        </Grid>
        <Grid item xs={1.5} md={1.5}>
          <Typography color={theme.palette.primary.main}>Financial Report</Typography>
        </Grid>
      </TableListHeader>
      <div className={styles.FormList}>
        {Object.values(localCiaList).length > 0 &&
          Object.values(localCiaList).map((cia, index: number) => (
            <div key={index}>
              <MemberProfileFormCompanyAccessTabItem cia={cia} localCiaList={localCiaList} setLocalCiaList={setLocalCiaList} />
            </div>
          ))}
      </div>
      {paginationLoading && (
        <div className={`LoaderScroll ${!companies.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
