import { ChangeOrdersActions } from "redux-store/redux-actions/changeOrders";
import { ChangeOrderRequest } from "resource/interfaces/change-order";
import { updateNormalizedCollection } from "resource/utils";

const initialState = {
  changeOrderItems: {},
  changeOrderIds: [],
  changeOrderLists: {},
  changeOrdersError: "",
  changeOrdersCount: 0,
};

export const changeOrdersReducer = (state = initialState, action: { type: string; payload: any }) => {
  const { type, payload } = action;

  switch (type) {
    case ChangeOrdersActions.PUT_CHANGE_ORDERS:
      return {
        ...state,
        changeOrderItems: { ...state.changeOrderItems, ...payload.data.entities.orders } as ChangeOrderRequest[],
        changeOrderIds: Array.from(new Set([...state.changeOrderIds, ...payload.data.result])) as number[],
        changeOrdersCount: payload.count as number,
      };
    case ChangeOrdersActions.PUT_CHANGE_ORDER_BY_ID:
      return {
        ...state,
        changeOrderItems: { ...state.changeOrderItems, [payload.result]: payload.entities.order[payload.result] },
        changeOrderIds: updateNormalizedCollection(state.changeOrderIds, payload.result),
        changeOrderLists: { ...state.changeOrderLists, ...payload.entities.list } as ChangeOrderRequest[],
      };
    case ChangeOrdersActions.CLEAR_CHANGE_ORDER_DATA:
      return {
        ...state,
        changeOrderItems: {},
        changeOrderIds: [],
        changeOrdersCount: 0,
      };
    case ChangeOrdersActions.CATCH_CHANGE_ORDERS_ERROR:
      return { ...state, changeOrdersError: payload as string };
    default:
      return state;
  }
};
