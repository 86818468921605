import { useEffect, FC } from "react";

// ? REDUX
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import MasterRolesForm from "pages/master-roles/MasterRolesForm";
import { fetchMasterRoleByIdAction } from "redux-store/redux-actions/masterRoles";
import { processDataForEdit } from "pages/master-roles/master-role-util";
import { CircularProgress } from "@mui/material";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { menuCollection } from "resource/constants/menu-list";
import { MASTER_ROLES, MASTER_ROLES_NAME } from "resource/constants/master-roles";
import { ActionName } from "resource/constants/api";

//  ? INTERFACES
import { roleOnGet } from "resource/interfaces/master-roles";

export const nameUpdate = `${MASTER_ROLES_NAME}/${ActionName.UPDATE}`;
interface MasterRolesUpdateProps {
  className: string;
}

const MasterRolesUpdate: FC<MasterRolesUpdateProps> = ({ className }) => {
  const dispatch = useDispatch();

  const {
    close,
    isOpened,
    data: { idnumber },
  } = useModal(nameUpdate);

  const updateFormValuesStructure = (role) => {
    return {
      idrole: role.idnumber,
      name: role.name,
      project: Number(role.projectyn),
      actions: processDataForEdit(role, menuCollection),
      sort: role.sort,
      idtrack: role.idtrack,
    };
  };

  const masterRole = useSelector((state: RootStateOrAny) => {
    if (isOpened && idnumber !== undefined) {
      const masterRole = state.masterRoles.masterRoles.find((role: roleOnGet) => role.idnumber === idnumber);

      return masterRole.roles ? updateFormValuesStructure(masterRole) : undefined;
    }
  });

  useEffect(() => {
    if (isOpened && idnumber !== undefined) {
      dispatch(fetchMasterRoleByIdAction(idnumber));
    }
  }, [idnumber, dispatch, isOpened]);

  return (
    <>
      <ModalManager name={nameUpdate} className={className}>
        {isOpened && masterRole ? (
          <MasterRolesForm
            title={MASTER_ROLES.EDIT_MODAL_TITLE}
            formData={masterRole}
            buttonText={MASTER_ROLES.EDIT_MODAL_SUBMIT_BUTTON}
            handleClose={close}
            actionType={ActionName.UPDATE}
          />
        ) : (
          <CircularProgress className="Loader" />
        )}
      </ModalManager>
    </>
  );
};

export default MasterRolesUpdate;
