import { FC, useState } from "react";

// ? COMPONENTS
import { Grid } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { Statuses } from "resource/consts";

// ? STYLES
import styles from "./StatusSubmitButtons.module.css";

interface StatusSubmitButtonsProps {
  setFieldValue: (field: string, value: string) => void;
  isLoading: boolean;
  isDisabled?: boolean;
}

const StatusSubmitButtons: FC<StatusSubmitButtonsProps> = ({ setFieldValue, isLoading, isDisabled }) => {
  const [activatedButton, setActivatedButton] = useState("");

  return (
    <Grid container spacing={2} sx={{ marginTop: "15px" }}>
      <Grid item className={styles.StatusSubmitButtons}>
        <SubmitButton
          text={Statuses.DRAFT.buttonText}
          isLoading={activatedButton === Statuses.DRAFT.status && isLoading}
          isDisabled={isDisabled}
          callback={() => {
            setFieldValue("status", Statuses.DRAFT.status);
            setActivatedButton(Statuses.DRAFT.status);
          }}
        />
      </Grid>
      <Grid item className={styles.StatusSubmitButtons}>
        <SubmitButton
          text={Statuses.FOR_APPROVE.buttonText}
          isLoading={activatedButton === Statuses.FOR_APPROVE.status && isLoading}
          isDisabled={isDisabled}
          callback={() => {
            setFieldValue("status", Statuses.FOR_APPROVE.status);
            setActivatedButton(Statuses.FOR_APPROVE.status);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StatusSubmitButtons;
