import { useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchJobCostSublistAction } from "redux-store/redux-actions/jobCost";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { withLayout } from "hoc/withLayout";
import JobCostDetailHeader from "./JobCostDetailHeader";
import { TableListDivider } from "components/shared/table-list/TableList";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

import { formatPrice } from "resource/utils";
import { JOB_COST_NAME } from "resource/constants/job-cost";
import JobCostDetailList from "./JobCostDetailList";

const JobCostDetail = ({ idcia, id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const jobCostSublist = useSelector((state: RootStateOrAny) => state.jobCosts.jobCostSublist);
  const loading = useSelector((state: RootStateOrAny) => state.loaders.globalLoader);

  useEffect(() => {
    dispatch(fetchJobCostSublistAction({ idnumber: id, idcia }));
  }, []);

  if (jobCostSublist.length === 0 && !loading) {
    return <NoDataScreen pageName="jobCosts" />;
  }

  return (
    <>
      <JobCostDetailHeader idnumber={id} />
      {jobCostSublist.length > 0 && !loading && (
        <>
          <JobCostDetailList jobCostSublist={jobCostSublist} />
          <TableListDivider>
            <Grid item xs={3}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(jobCostSublist.reduce((prev, item) => prev + item.budget, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(jobCostSublist.reduce((prev, item) => prev + item.budgetrevised, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(jobCostSublist.reduce((prev, item) => prev + item.incurred, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(jobCostSublist.reduce((prev, item) => prev + item.openpo, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}>
                {formatPrice(jobCostSublist.reduce((prev, item) => prev + item.jobcost, 0).toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.text.primary}></Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex"></Grid>
          </TableListDivider>
        </>
      )}
      {loading && (
        <div className={`LoaderScroll  center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(JobCostDetail);
