import { useState, Fragment, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { deletePartsPricingAction } from "redux-store/redux-actions/parts";

// ? COMPONENTS
import { useTheme, Typography, Grid, IconButton } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import useAsync from "custom-hooks/useAsync";
import PartsVendorPricingForm from "./PartsVendorPricingForm";

// ? RESOURCES
import { getFormattedDateTime, formatPrice } from "resource/utils";
import editIcon from "resource/images/edit1.svg";
import deleteIcon from "resource/images/delete.svg";
import { initialValuesPricingForm } from "resource/constants/parts";
import { ActionName } from "resource/constants/api";
import closeButtonIcon from "resource/images/close-btn.svg";
import { VendorPricing } from "resource/interfaces/part";
import SmallLoader from "components/shared/small-loader/SmallLoader";

// ? STYLES
import styles from "./Parts.module.css";

interface PartsVendorPricingListProps {
  idtrack: number;
  upc: string;
  vendorPricingList: any;
  close: () => void;
}

const PartsVendorPricingList: FC<PartsVendorPricingListProps> = ({ idtrack, upc, vendorPricingList, close }) => {
  const theme = useTheme();

  const modalName = useSelector((state: RootStateOrAny) => state.modals.name);
  const [isLoading, setLoader] = useState(false);
  const [isCurrent, setCurrent] = useState({});
  const [edit, setEdit] = useState(initialValuesPricingForm);
  const deletePartPricing = useAsync(deletePartsPricingAction);

  const handleVendorPricingDelete = async (idtrack: number, upc: string, vendorPricing: VendorPricing) => {
    setLoader(true);
    setCurrent(vendorPricing);

    try {
      await deletePartPricing({ idtrack, upc, vendorPricing });
      setLoader(false);
      setCurrent({});
    } catch (e) {
      console.error(e);
      setLoader(false);
      setCurrent({});
    }
  };

  const handleVendorPricingUpdate = (vendorPricing) => {
    setEdit(vendorPricing);
  };

  return (
    <>
      <div className={styles.PartsFormHeader}>
        <Typography variant="h1" color={theme.palette.text.primary}>
          Vendor Pricing
        </Typography>
        {modalName === "vendorparts/create" && (
          <IconButton onClick={close}>
            <img src={closeButtonIcon} alt="close-button" />
          </IconButton>
        )}
      </div>
      <TableListHeader>
        <Grid item xs={4}>
          <Typography color={theme.palette.primary.main}>Vendor Name</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>SKU</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Price</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Updated</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Actions</Typography>
        </Grid>
      </TableListHeader>
      {vendorPricingList.map((vendorPricing, i: number) => {
        return (
          <Fragment key={i}>
            {edit === vendorPricing ? (
              <PartsVendorPricingForm
                idtrack={idtrack}
                upc={upc}
                setEdit={setEdit}
                formData={edit}
                actionType={ActionName.UPDATE}
              />
            ) : (
              <Grid container className={styles.PartsVendorPricingList}>
                <Grid item xs={4}>
                  <Typography color={theme.palette.secondary.dark}>{vendorPricing.nvendor}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography color={theme.palette.secondary.dark}>{vendorPricing.skunumber}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.secondary.dark}>{formatPrice(vendorPricing.lastprice.toFixed(2))}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color={theme.palette.secondary.dark}>{getFormattedDateTime(vendorPricing.modidate)}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <Typography color={theme.palette.secondary.dark}>{vendorPricing.modiby}</Typography>
                </Grid>
                <Grid item xs={1} className={styles.PartsVendorPricingBtnWrapper}>
                  {isCurrent === vendorPricing && isLoading ? (
                    <SmallLoader />
                  ) : (
                    <>
                      <IconButton
                        className={styles.PartsVendorPricingListBtn}
                        type="button"
                        title="Update pricing"
                        onClick={() => handleVendorPricingUpdate(vendorPricing)}
                      >
                        <img src={editIcon} alt="Edit icon" />
                      </IconButton>
                      <IconButton
                        className={`${styles.PartsVendorPricingListBtn} ${styles.PartsVendorPricingListRemoveBtn}`}
                        type="button"
                        title="Remove pricing"
                        onClick={() => handleVendorPricingDelete(idtrack, upc, vendorPricing)}
                      >
                        <img src={deleteIcon} alt="Delete icon" />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default PartsVendorPricingList;
