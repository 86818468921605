import { useState, useEffect } from "react";

//  ?  COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import { IconButton, Box, Grid, Typography, useTheme, TextField, InputLabel, FormGroup } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import Calendar from "components/shared/calendar/Calendar";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";

// ? UTILS
import { getStartDateForCalendar, convertLocalDateToISOString } from "resource/utils";

const PurchaseOrderUpdateForm = ({ handleSubmit, isLoading, buttonText, handleClose, formData, orderTotal, title }) => {
  const theme = useTheme();
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [receivedDate, setReceivedDate] = useState(new Date());

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      const value = new Date(evt.target.value);
      evt.key.name === receivedDate ? setReceivedDate(value) : setDeliveryDate(value);
    }
  };

  const handleReceivedDate = (update) => {
    setReceivedDate(update);
  };

  const handleDeliverydDate = (update) => {
    setDeliveryDate(update);
  };

  const getDatePickerOptions = (name: string) => {
    return {
      selected: name === "deliveryDate" ? deliveryDate : receivedDate,
      placeholderText: "Choose date",
    };
  };

  useEffect(() => {
    if (formData) {
      setDeliveryDate(getStartDateForCalendar(formData.delivery));
      setReceivedDate(getStartDateForCalendar(formData.received));
    }
  }, [formData]);

  return (
    <div className="Form">
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          const delivery = convertLocalDateToISOString(deliveryDate.toLocaleDateString().slice(0, 10));
          const received = convertLocalDateToISOString(receivedDate.toLocaleDateString().slice(0, 10));
          const total =
            orderTotal +
            values.shipping +
            values.insurance +
            values.others -
            formData.shipping -
            formData.insurance -
            formData.others;

          handleSubmit({
            idnumber: Number(formData.idnumber),
            refdate: formData.refdate,
            shipto: values.shipto,
            invoice: values.invoice,
            idvendor: Number(formData.idvendor),
            total: Math.round(total * 100) / 100,
            idreason: formData.idreason,
            delivery: delivery,
            received: received,
            shipping: values.shipping,
            insurance: values.insurance,
            others: values.others,
            idtrack: formData.idtrack,
          });
        }}
      >
        <Form style={{ width: "100%" }}>
          <Box className="FormHeader" display="flex" justifyContent="space-between">
            <Typography variant="h1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </Box>
          <Grid sx={{ padding: 0 }} container spacing={1}>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="invoice">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Delivery Date
                  </Typography>
                </InputLabel>
                <Box sx={{ pb: "10px" }}>
                  <Calendar
                    options={getDatePickerOptions("deliveryDate")}
                    handleDatePickerChange={handleDeliverydDate}
                    handleKeyDown={handleKeyDown}
                    name="deliveryDate"
                    className="wide"
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="invoice">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Received
                  </Typography>
                </InputLabel>
                <Box sx={{ pb: "10px" }}>
                  <Calendar
                    options={getDatePickerOptions("receivedDate")}
                    handleDatePickerChange={handleReceivedDate}
                    handleKeyDown={handleKeyDown}
                    name="receivedDate"
                    className="wide"
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="invoice">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Invoice
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="text" name="invoice" id="invoice" placeholder="Enter invoice" />
                <ErrorMessage name="invoice">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="others">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Others
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="number" name="others" id="others" placeholder="Enter others" />
                <ErrorMessage name="others">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="shipping">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Shipping
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="number" name="shipping" id="shipping" placeholder="Enter shipping" />
                <ErrorMessage name="shipping">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={6}>
              <FormGroup>
                <InputLabel htmlFor="insurance">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Insurance
                  </Typography>
                </InputLabel>
                <Field as={TextField} type="number" name="insurance" id="insurance" placeholder="Enter insurance" />
                <ErrorMessage name="insurance">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
            <Grid item sm={12}>
              <FormGroup>
                <InputLabel htmlFor="shipto">
                  <Typography variant="body1" color={theme.palette.info.main}>
                    Notes
                  </Typography>
                </InputLabel>
                <Field
                  className="Textarea"
                  type="textarea"
                  name="shipto"
                  id="shipto"
                  as={TextField}
                  placeholder="Enter message"
                  multiline
                  minRows={5}
                />
                <ErrorMessage name="shipto">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
              </FormGroup>
            </Grid>
          </Grid>
          <Box justifyContent="flex-start" className="FormFooter">
            <SubmitButton text={buttonText} isLoading={isLoading} />
          </Box>
        </Form>
      </Formik>
    </div>
  );
};

export default PurchaseOrderUpdateForm;
