import { roleOnPost, roleOnGet } from "resource/interfaces/master-roles";
import { PromiseType } from "resource/interfaces";

interface ResponsePayload {
  values: roleOnPost;
  actionType: string;
}

// ? ACTIONS
export enum MasterRolesActions {
  UPDATE_MASTER_ROLE = "UPDATE_MASTER_ROLE",
  DELETE_MASTER_ROLE = "DELETE_MASTER_ROLE",
  DELETE_ROLE_FROM_STORE = "DELETE_ROLE_FROM_STORE",
  FETCH_EDIT_MASTER_ROLE = "FETCH_EDIT_MASTER_ROLE",
  GET_MASTER_ROLE_BY_ID = "GET_MASTER_ROLE_BY_ID",
  GET_MASTER_ROLES = "GET_MASTER_ROLES",
  FETCH_MASTER_ROLES = "FETCH_MASTER_ROLES",
  FETCH_MASTER_ROLE_BY_ID = "FETCH_MASTER_ROLE_BY_ID",
  CATCH_MASTER_ROLES_ERROR = "CATCH_MASTER_ROLES_ERROR",
  CLEAR_ROLES_DATA = "CLEAR_ROLES_DATA",
}

// ? ACTION CREATORS
export const updateMasterRoleAction = (payload: ResponsePayload, promise?: PromiseType) => {
  return {
    type: MasterRolesActions.UPDATE_MASTER_ROLE,
    payload,
    promise,
  };
};

export const deleteMasterRoleAction = (payload: { idnumber: number }, promise?: PromiseType) => {
  return {
    type: MasterRolesActions.DELETE_MASTER_ROLE,
    payload,
    promise,
  };
};

export const deleteRoleFromStoreAction = (payload: number) => {
  return {
    type: MasterRolesActions.DELETE_ROLE_FROM_STORE,
    payload,
  };
};

export const fetchEditMasterRoleAction = (payload: roleOnGet) => {
  return {
    type: MasterRolesActions.FETCH_EDIT_MASTER_ROLE,
    payload,
  };
};

export const getMasterRolesAction = (payload: { data: roleOnGet[]; count: number }) => {
  return {
    type: MasterRolesActions.GET_MASTER_ROLES,
    payload,
  };
};

export const getMasterRoleByIdAction = (payload: roleOnGet) => {
  return {
    type: MasterRolesActions.GET_MASTER_ROLE_BY_ID,
    payload,
  };
};

export const fetchMasterRolesAction = (payload: number) => {
  return {
    type: MasterRolesActions.FETCH_MASTER_ROLES,
    payload,
  };
};

export const fetchMasterRoleByIdAction = (payload: string) => {
  return {
    type: MasterRolesActions.FETCH_MASTER_ROLE_BY_ID,
    payload,
  };
};

export const catchMasterRolesErrorAction = (payload: string) => {
  return {
    type: MasterRolesActions.CATCH_MASTER_ROLES_ERROR,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: MasterRolesActions.CLEAR_ROLES_DATA,
  };
};
