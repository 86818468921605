import { useEffect, useState, FC } from "react";

// ? COMPONENTS
import { Grid, Typography, useTheme, TextField, FormGroup, InputLabel, CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import { closeContractAction } from "redux-store/redux-actions/contracts";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import { getClosingDate } from "resource/utils";
import { ContractEndpoints } from "resource/constants/contracts";

// ? STYLES
import styles from "./Contracts.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { StatusList } from "resource/consts";

interface ContractsFormContractProps {
  formik: any;
  contractId?: number;
  contractIdtrack?: number;
  contractStatus?: string;
}

const ContractsFormContract: FC<ContractsFormContractProps> = ({ formik, contractId, contractIdtrack, contractStatus }) => {
  const theme = useTheme();
  const [closeDate, setCloseDate] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const closeContract = useAsync(closeContractAction);

  const handleCloseContract = async (data: any) => {
    const dataForSubmit = { idnumber: contractId, idtrack: contractIdtrack, close_date: getClosingDate(data) };

    setLoading(true);
    try {
      await closeContract({ values: dataForSubmit, actionType: ContractEndpoints.CLOSE });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formik.values.formContract.closed_date) {
      setCloseDate(new Date(formik.values.formContract.closed_date));
    }
  }, [formik.values.formContract.closed_date]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="closing_notice">
            <Typography variant="body1" color={theme.palette.info.main}>
              Closing
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            name="formContract.closing_notice"
            id="closing_notice"
            value={formik.values.formContract.closing_notice}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      {contractStatus === StatusList.APPROVED && (
        <Grid item sm={6} md={4}>
          <FormGroup>
            <InputLabel htmlFor="close_date">
              <Typography variant="body1" color={theme.palette.info.main}>
                Close date
                {loading ? <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} /> : null}
              </Typography>
            </InputLabel>
            <DatePicker
              disabled={contractStatus !== StatusList.APPROVED}
              selected={closeDate}
              onChange={(date: Date) => handleCloseContract(date)}
              className={`${styles.DatePickerInput}`}
              popperClassName={`${styles.DatePickerPopper}`}
              calendarClassName={`${styles.DatePickerCalendar}`}
            />
          </FormGroup>
        </Grid>
      )}

      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="sold">
            <Typography variant="body1" color={theme.palette.info.main}>
              Sold*
            </Typography>
          </InputLabel>
          <TextField
            type="number"
            name="formContract.sold"
            id="sold"
            value={formik.values.formContract.sold}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      {contractStatus !== StatusList.APPROVED && <Grid item sm={6} md={4}></Grid>}
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer1">
            <Typography variant="body1" color={theme.palette.info.main}>
              Buyer Name*
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            id="buyer1"
            name="formContract.buyer1"
            value={formik.values.formContract.buyer1}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter Buyer Name"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer1_email">
            <Typography variant="body1" color={theme.palette.info.main}>
              Email Address*
            </Typography>
          </InputLabel>
          <TextField
            type="email"
            id="buyer1_email"
            name="formContract.buyer1_email"
            value={formik.values.formContract.buyer1_email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter Email Address"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="legal_description">
            <Typography variant="body1" color={theme.palette.info.main}>
              Legal Description
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            id="legal_description"
            name="formContract.legal_description"
            value={formik.values.formContract.legal_description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Enter legal description"
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2">
            <Typography variant="body1" color={theme.palette.info.main}>
              2nd Buyer Name
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter 2nd Buyer Name"
            id="buyer2"
            name="formContract.buyer2"
            value={formik.values.formContract.buyer2}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2_email">
            <Typography variant="body1" color={theme.palette.info.main}>
              2nd Email
            </Typography>
          </InputLabel>
          <TextField
            type="email"
            placeholder="Enter 2nd Email"
            id="buyer2_email"
            name="formContract.buyer2_email"
            value={formik.values.formContract.buyer2_email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer_city_state">
            <Typography variant="body1" color={theme.palette.info.main}>
              City, State
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter City, State"
            id="buyer_city_state"
            name="formContract.buyer_city_state"
            value={formik.values.formContract.buyer_city_state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={8}>
        <FormGroup>
          <InputLabel htmlFor="buyer_address">
            <Typography variant="body1" color={theme.palette.info.main}>
              Home - Current Address
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Home - Current Address"
            id="buyer_address"
            name="formContract.buyer_address"
            value={formik.values.formContract.buyer_address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer_zipcode">
            <Typography variant="body1" color={theme.palette.info.main}>
              Zip Code
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter others"
            id="buyer_zipcode"
            name="formContract.buyer_zipcode"
            value={formik.values.formContract.buyer_zipcode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer1_office">
            <Typography variant="body1" color={theme.palette.info.main}>
              Office
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Office"
            id="buyer1_office"
            name="formContract.buyer1_office"
            value={formik.values.formContract.buyer1_office}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer1_fax">
            <Typography variant="body1" color={theme.palette.info.main}>
              Fax
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter Fax"
            id="buyer1_fax"
            name="formContract.buyer1_fax"
            value={formik.values.formContract.buyer1_fax}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer1_mobile">
            <Typography variant="body1" color={theme.palette.info.main}>
              Mobile
            </Typography>
          </InputLabel>
          <TextField
            type="tel"
            placeholder="Enter Mobile"
            name="formContract.buyer1_mobile"
            value={formik.values.formContract.buyer1_mobile}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2_office">
            <Typography variant="body1" color={theme.palette.info.main}>
              2nd Office
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            placeholder="Enter 2nd Office"
            id="buyer2_office"
            name="formContract.buyer2_office"
            value={formik.values.formContract.buyer2_office}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2_fax">
            <Typography variant="body1" color={theme.palette.info.main}>
              2nd Fax
            </Typography>
          </InputLabel>
          <TextField
            type="text"
            id="buyer2_fax"
            placeholder="Enter 2nd Fax"
            name="formContract.buyer2_fax"
            value={formik.values.formContract.buyer2_fax}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={6} md={4}>
        <FormGroup>
          <InputLabel htmlFor="buyer2_mobile">
            <Typography variant="body1" color={theme.palette.info.main}>
              2nd Mobile
            </Typography>
          </InputLabel>
          <TextField
            type="tel"
            id="buyer2_mobile"
            placeholder="Enter 2nd Mobile"
            name="formContract.buyer2_mobile"
            value={formik.values.formContract.buyer2_mobile}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default ContractsFormContract;
