import { all, fork } from "redux-saga/effects";

// ? SAGAS
import { loginSaga } from "./loginSaga";
import { companiesSaga } from "./companiesSaga";
import { costCodesSaga } from "./costCodesSaga";
import { masterRolesSaga } from "./masterRolesSaga";
import { membersProfileSaga } from "./membersProfileSaga";
import { planVariablesSaga } from "./planVariablesSaga";
import { partsSaga } from "./partsSaga";
import { planDrawerSaga } from "./planDrawerSaga";
import { vendorsSaga } from "./vendorsSaga";
import { assembliesSaga } from "./assembliesSaga";
import { purchaseOrderSaga } from "./purchaseOrderSaga";
import { vendorPricingSaga } from "./vendorPricingSaga";
import { masterFloorplansSaga } from "./masterFloorplansSaga";
import { contractsSaga } from "./contractsSaga";
import { projectsSaga } from "./projectsSaga";
import { jobCostSaga } from "./jobCostSaga";
import { changeOrdersSaga } from "./changeOrdersSaga";
import { developmentsSaga } from "./developmentsSaga";
import { paymentSummarySaga } from "./paymentSummarySaga";
import { planTakeoffSaga } from "./planTakeoffSaga";
import { projectTakeoffSaga } from "./projectTakeoffSaga";
import { fileServerSaga } from "./fileServerSaga";

const allSagas = [
  fork(loginSaga),
  fork(companiesSaga),
  fork(costCodesSaga),
  fork(masterRolesSaga),
  fork(membersProfileSaga),
  fork(planVariablesSaga),
  fork(partsSaga),
  fork(planDrawerSaga),
  fork(vendorsSaga),
  fork(assembliesSaga),
  fork(purchaseOrderSaga),
  fork(vendorPricingSaga),
  fork(masterFloorplansSaga),
  fork(contractsSaga),
  fork(projectsSaga),
  fork(changeOrdersSaga),
  fork(jobCostSaga),
  fork(developmentsSaga),
  fork(paymentSummarySaga),
  fork(planTakeoffSaga),
  fork(projectTakeoffSaga),
  fork(fileServerSaga),
];

export function* rootSaga() {
  yield all(allSagas);
}
