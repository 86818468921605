import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";

// ? RESOURCES
import { resolvePath, isBiggerThanZero } from "resource/utils";
import { ColorListMap } from "resource/consts";
import { ROUTES } from "resource/routes/routes";
import { ProjectTakeoffItemType } from "resource/interfaces/project-takeoff";

const ProjectTakeOffItem = ({ projectTakeoff }: { projectTakeoff: ProjectTakeoffItemType }) => {
  const theme = useTheme();
  const mainPath = ROUTES.projectTakeoff.pathname;
  const subDirectories = [projectTakeoff.idnumber, projectTakeoff.idcia];
  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={0.75}>
          <Typography color={theme.palette.text.primary}>{projectTakeoff.idcia}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{projectTakeoff.idnumber}</Typography>
        </Grid>
        <Grid item xs={2.75}>
          <Typography color={theme.palette.text.primary}>{projectTakeoff.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.text.primary}>{projectTakeoff.planname}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{projectTakeoff.revision}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(projectTakeoff.sqft)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(projectTakeoff.takeoffsqft)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{isBiggerThanZero(projectTakeoff.takeoff)}</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography sx={{ color: `rgb(${ColorListMap[projectTakeoff.status]})` }} justifyContent="flex-end" display="flex">
            {projectTakeoff.status}
          </Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default ProjectTakeOffItem;
