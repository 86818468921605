import { takeLatest, put, all } from "redux-saga/effects";
import { VENDORS_API } from "resource/constants/api";
import { get, post, catchError } from "./_utils-abstract-saga-network";

import {
  VendorsActions,
  getVendorsAction,
  getEditVendorAction,
  getVendorAction,
  putVendorAction,
  deleteVendorStoreAction,
  putVendorsTypeListAction,
  fetchVendorAction,
} from "../redux-actions/vendors";

import {
  setModalLoaderAction,
  setPaginationLoaderAction,
  setAddNewLoaderAction,
} from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchVendorsWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords, category, filter },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${VENDORS_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(category && { category }),
    ...(filter && { filter }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getVendorsAction({ data: data.list, count: data.count }));
    yield put(clearNotifierAction());
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchVendorsTypeListWorker() {
  const { errno, message, data } = yield get(`${VENDORS_API}/getCategoryPamentTerms`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putVendorsTypeListAction(data));
  }
}

function* fetchEditVendorWorker(action) {
  const {
    payload: { idnumber },
  } = action;

  yield put(setModalLoaderAction(true));

  const { errno, message, data } = yield get(`${VENDORS_API}/${idnumber}`);

  const vendor = {
    ...data,
    costcodeList: data.costcodeList.map((costCode) => {
      return { code: costCode.idcostcode, name: costCode.name };
    }),
  };

  if (errno !== 0) {
    yield put(setModalLoaderAction(false));
    yield catchError(message);
  } else {
    console.log("Vendor uploaded");
    yield put(getEditVendorAction(vendor));
    yield put(clearNotifierAction());
    yield put(setModalLoaderAction(false));
  }
}

function* fetchVendorWorker(action) {
  const {
    payload: { idnumber, mode },
  } = action;

  if (mode === "create") {
    yield put(setAddNewLoaderAction(true));
  }

  const { errno, message, data } = yield get(`${VENDORS_API}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
    yield put(setAddNewLoaderAction(false));
  } else {
    yield put(mode === "create" ? getVendorAction(data) : putVendorAction(data));
    yield put(setAddNewLoaderAction(false));
  }
}

function* updateVendorWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data } = yield post(`${VENDORS_API}/${actionType}`, values, "json");

  if (actionType === "create") {
    values.idnumber = data.newId;
  }

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`${actionType} vendor successfull`);
    yield put(fetchVendorAction({ idnumber: values.idnumber, mode: actionType }));
    yield put(clearNotifierAction());
  }
}

function* deleteVendorWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${VENDORS_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete vendor successfull");
    yield put(deleteVendorStoreAction({ idnumber: values.idnumber }));
    yield put(clearNotifierAction());
  }
}

function* vendorActivationWorker(action) {
  const {
    payload: { formData, setChecked, setLocalLoading },
  } = action;

  yield setChecked((prev) => !prev);
  yield setLocalLoading(true);

  const { errno, message } = yield post(`${VENDORS_API}/update`, formData, "json");

  if (errno !== 0) {
    yield setLocalLoading(false);
    yield catchError(message);
    yield setChecked((prev) => !prev);
  } else {
    console.log("Vendor activate/inactivate");
    yield put(clearNotifierAction());
    yield put(
      fetchVendorAction({
        idnumber: formData.idnumber,
        mode: "edit",
        setLocalLoading,
      })
    );
  }
}

export function* vendorsSaga() {
  yield takeLatest(VendorsActions.FETCH_VENDORS, fetchVendorsWorker);
  yield takeLatest(VendorsActions.FETCH_VENDORS_TYPE_LIST, fetchVendorsTypeListWorker);
  yield takeLatest(VendorsActions.FETCH_EDIT_VENDOR, fetchEditVendorWorker);
  yield takeLatest(VendorsActions.FETCH_VENDOR_BY_ID, fetchVendorWorker);
  yield takeLatest(VendorsActions.ADD_NEW_VENDOR, updateVendorWorker);
  yield takeLatest(VendorsActions.EDIT_VENDOR, updateVendorWorker);
  yield takeLatest(VendorsActions.DELETE_VENDOR_FROM_SERVER, deleteVendorWorker);
  yield takeLatest(VendorsActions.VENDOR_ACTIVATION, vendorActivationWorker);
}
