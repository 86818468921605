import { takeLatest, put } from "redux-saga/effects";
import { COMPANIES_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  CompaniesActions,
  getCompanyAction,
  putCompanyAction,
  getCompaniesAction,
  fetchCompanyAction,
  deleteCompanyStoreAction,
} from "../redux-actions/companies";

import { setPaginationLoaderAction, setAddNewLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchCompaniesWorker(action) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  const { errno, message, data } = yield get(`${COMPANIES_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(getCompaniesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchCompanyWorker(action) {
  const {
    payload: { idnumber, mode },
  } = action;

  if (mode === "create") {
    yield put(setAddNewLoaderAction(true));
  }

  const { errno, message, data } = yield get(`${COMPANIES_API}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
    yield put(setAddNewLoaderAction(false));
  } else {
    yield put(mode === "create" ? getCompanyAction(data) : putCompanyAction(data));
    yield put(setAddNewLoaderAction(false));
  }
}

function* updateCompanyWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  if (actionType === "create") {
    const {
      data: { nextId },
    } = yield get(`${COMPANIES_API}/getNextId`);

    values.idnumber = nextId;
  }

  const { errno, message } = yield post(`${COMPANIES_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`${actionType} company ${values.name} successfull`);
    yield put(fetchCompanyAction({ idnumber: values.idnumber, mode: actionType }));
    yield put(clearNotifierAction());
  }
}

function* deleteCompanyWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${COMPANIES_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete company successfull");
    yield put(deleteCompanyStoreAction({ idnumber: values.idnumber }));
    yield put(clearNotifierAction());
  }
}

export function* companiesSaga() {
  yield takeLatest(CompaniesActions.FETCH_COMPANIES, fetchCompaniesWorker);
  yield takeLatest(CompaniesActions.FETCH_COMPANY_BY_ID, fetchCompanyWorker);
  yield takeLatest(CompaniesActions.ADD_NEW_COMPANY, updateCompanyWorker);
  yield takeLatest(CompaniesActions.EDIT_COMPANY, updateCompanyWorker);
  yield takeLatest(CompaniesActions.DELETE_COMPANY_FROM_SERVER, deleteCompanyWorker);
}
