// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { clearDataDetailAction } from "redux-store/redux-actions/assemblies";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, Box, TextField, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { AssembliesFormPartsHeader } from "./AssembliesFormPartsHeader";
import { AssembliesFormPartField } from "./AssembliesFormPartField";
import { AssembliesFormSplitsHeader } from "./AssembliesFormSplitsHeader";
import { AssembliesFormSplitField } from "./AssembliesFormSplitField";

// ? STYLES
import styles from "./Assemblies.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { useSearch } from "custom-hooks/useSearch";
import plusIcon from "resource/images/plus.svg";
import minusIcon from "resource/images/minus.svg";
import { assembliesSchema } from "resource/schemas/assembliesSchema";
import { useEffect } from "react";

// ? TYPES
interface Option {
  field: string;
  list: { label: string; value: "string" }[];
}

export const AssembliesFormUpdate = ({
  title,
  buttonText,
  closeModal,
  handleSubmit,
  isLoading,
  splits,
  createSplitRow,
  removeSplitRow,
  parts,
  setParts,
  createPartRow,
  data,
  removePartRow,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);
  const types = useSelector(
    (state: RootStateOrAny) => state.assemblies.options.filter((option: Option) => option.field === "xtype")[0].list
  );

  const initialFormValues = data;

  useEffect(() => {
    return () => {
      dispatch(clearDataDetailAction());
    };
  }, []);

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik
          initialValues={initialFormValues}
          onSubmit={(values: any) => handleSubmit(values)}
          validationSchema={assembliesSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={closeModal}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container justifyContent="space-between">
                <Box className={styles.Group}>
                  <FormField size="100%" title="Assembly Name" name="name" placeholder="Enter assembly name" type="text" />
                  <FormField
                    size="47%"
                    title="Cost Code"
                    name="ncostcode"
                    placeholder="Search cost code or number"
                    type="text"
                    disabled
                  />
                  <FormField size="47%" title="Type" name="xtype" placeholder="Enter last price" type="select" options={types} />
                </Box>

                <Box className={styles.Group}>
                  <div className={styles.GroupHeader}>
                    <Typography className={styles.GroupTitle}>Parts</Typography>
                    <div>
                      <IconButton aria-label="delete" onClick={createPartRow}>
                        <img src={plusIcon} alt="" />
                      </IconButton>
                      <Typography color={theme.palette.primary.main}>Add Row</Typography>
                    </div>
                  </div>

                  <AssembliesFormPartsHeader />
                  {parts.map((part, index) => (
                    <AssembliesFormPartField
                      key={part.tempId ? part.tempId : part.idnumber}
                      part={part}
                      parts={parts}
                      setParts={setParts}
                      isNew={false}
                      removePartRow={removePartRow}
                    />
                  ))}
                </Box>

                <Box className={styles.Group}>
                  <div className={styles.GroupHeader}>
                    <Typography className={styles.GroupTitle}>Split Payments</Typography>
                    <div>
                      <IconButton aria-label="delete" onClick={createSplitRow}>
                        <img src={plusIcon} alt="" />
                      </IconButton>
                      <Typography color={theme.palette.primary.main}>Add Row</Typography>
                    </div>
                    <div>
                      <IconButton aria-label="delete" onClick={() => removeSplitRow(setFieldValue)}>
                        <img src={minusIcon} alt="" />
                      </IconButton>
                      <Typography color={theme.palette.primary.main}>Remove Row</Typography>
                    </div>
                  </div>

                  <AssembliesFormSplitsHeader />
                  {splits.map((split, index) => (
                    <AssembliesFormSplitField
                      key={split.name}
                      split={split}
                      splits={splits}
                      index={index}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </Box>
              </Grid>
              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
