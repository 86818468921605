// ? COMPONENTS
import { Box, Card, Typography, useTheme } from "@mui/material";

// ? STYLES
import styles from "./NoDataScreen.module.css";

// ? RESOURCES
import { customMessage } from "resource/constants/no-data-text";
import noCompany from "resource/images/no-company.svg";

const getClass = (pageName) => {
  return pageName === "basePlans" ? `${styles.NoDataScreen} ${styles.NoDataScreenSmall}` : `${styles.NoDataScreen}`;
};

const NoDataScreen = ({ pageName }) => {
  const theme = useTheme();

  return (
    <Box className={getClass(pageName)}>
      <Card className={styles.NoDataScreenCard}>
        <Box className={styles.NoDataScreenCardBox}>
          <img src={noCompany} alt="Sad box" className={styles.NoDataScreenImage} />
          <Typography variant="h2" className={`${styles.NoDataScreenHeading} ${styles.NoDataScreenText}`}>
            {customMessage[pageName].heading}
          </Typography>
          <Typography variant="body1" color={theme.palette.info.main} className={styles.NoDataScreenText}>
            {customMessage[pageName].text}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default NoDataScreen;
