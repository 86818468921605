//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { MEMBERS_PROFILE_NAME } from "resource/constants/members-profile";
import { USERS_PROFILE_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${MEMBERS_PROFILE_NAME}/file/uploadFile`;

const MembersProfileFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    username: data.username,
  };

  const dataForDelete = {
    username: data.username,
  };

  const dataForFetch = {
    username: data.username,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`User-${data.username} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={true}
      api={USERS_PROFILE_API}
      shouldGetCleans={true}
    />
  );
};

export default MembersProfileFileServer;
