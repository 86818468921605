import { PartOnResponse } from "resource/interfaces/part";

import { PartsActions } from "redux-store/redux-actions/parts";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

interface PartsStore {
  parts: PartOnResponse[];
  partsError: string;
  partsCount: number;
  upc: string;
}

const initialState: PartsStore = {
  parts: [],
  partsCount: 0,
  partsError: "",
  upc: "",
};

export const partsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PartsActions.PUT_PARTS:
      return {
        ...state,
        parts: [...state.parts, ...getUniqueData<PartOnResponse>(payload.data, state.parts, "upc")],
        partsCount: payload.count as number,
      };
    case PartsActions.PUT_PART_BY_ID:
      return {
        ...state,
        parts: updateCollection<PartOnResponse>(state.parts, payload, "upc"),
      };
    case PartsActions.PUT_PART_UPC:
      return {
        ...state,
        upc: payload as string,
      };
    case PartsActions.DELETE_PART_FROM_STORE:
      return { ...state, parts: [...state.parts.filter((part) => part.upc !== payload)] };
    case PartsActions.CLEAR_DATA:
      return {
        ...state,
        parts: [],
        partsCount: 0,
      };
    case PartsActions.CATCH_PARTS_ERROR:
      return {
        ...state,
        partsError: payload as string,
      };
    default:
      return state;
  }
};
