import { FC } from "react";

// ? COMPONENTS
import { Box, Grid, Typography, useTheme } from "@mui/material";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { MASTER_ROLES_NAME } from "resource/constants/master-roles";
import { menu } from "resource/constants/menu-list";

//? INTERFACES
import { roleOnGet } from "resource/interfaces/master-roles";

// ? STYLES
import styles from "./MasterRoles.module.css";

interface MasterRolesProps {
  roles: roleOnGet[];
  loading: boolean;
  handleRoleUpdate: (role: roleOnGet) => void;
  handleRoleDelete: (role: roleOnGet) => void;
}

const MasterRolesList: FC<MasterRolesProps> = ({ roles, loading, handleRoleUpdate, handleRoleDelete }) => {
  const theme = useTheme();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.masterRole.idmenu);

  return (
    <Box>
      {roles.length ? (
        <>
          <TableListHeader>
            <Grid item xs={7}>
              <Typography color={theme.palette.primary.main}>Role Name</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color={theme.palette.primary.main}>Project Level</Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end" display="flex">
              <Typography color={theme.palette.primary.main}>Actions</Typography>
            </Grid>
          </TableListHeader>
          {roles.map((role) => (
            <div key={role.idnumber}>
              <TableListRow>
                <Grid item xs={7}>
                  <Typography color={theme.palette.text.primary}>{role.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <img
                    className={styles.MasterRolesListCheckbox}
                    src={role.projectyn ? checkboxChecked : uncheckedCheckbox}
                    alt="project status"
                  />
                </Grid>
                <Grid item xs={2} justifyContent="flex-end" display="flex">
                  <SettingsButton
                    options={[
                      {
                        text: "Edit",
                        type: "edit",
                        callback: () => handleRoleUpdate(role),
                        access: hasEditAccess,
                      },
                      {
                        text: "Delete",
                        type: "delete",
                        callback: () => handleRoleDelete(role),
                        access: hasDeleteAccess,
                      },
                    ]}
                  />
                </Grid>
              </TableListRow>
            </div>
          ))}
        </>
      ) : (
        <>{loading ? null : <NoDataScreen pageName={MASTER_ROLES_NAME} />}</>
      )}
    </Box>
  );
};

export default MasterRolesList;
