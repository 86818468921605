import React from "react";
import { Link } from "react-router-dom";

import { MenuList, MenuItem, Typography } from "@mui/material";
import { CONTACT_EMAIL } from "resource/consts";

import { ROUTES } from "resource/routes/routes";
import styles from "./Landing.module.css";
import theme from "theme";

const LandingNavigation = () => {
  return (
    <MenuList className={styles.Navigation}>
      <MenuItem className={styles.NavigationItem}>
        <Link to={ROUTES.pricing.pathname} sx={{ height: "100%" }}>
          <Typography variant="body1">Pricing</Typography>
        </Link>
      </MenuItem>
      <MenuItem className={styles.NavigationItem} onClick={() => window.open(`mailto:${CONTACT_EMAIL}`)}>
        <Typography variant="body1">Contact</Typography>
      </MenuItem>
      <MenuItem className={styles.NavigationItemBtn}>
        <Link to={ROUTES.signup.pathname} sx={{ height: "100%" }}>
          <Typography color={theme.palette.primary.light}>Sign Up</Typography>
        </Link>
      </MenuItem>
    </MenuList>
  );
};

export default LandingNavigation;
