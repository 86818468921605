import { PlanTakeoffActions } from "redux-store/redux-actions/planTakeoffs";
import { updateNormalizedCollection } from "resource/utils";

interface PlanTakeoffsStore {
  planTakeoffItems: any;
  planTakeoffIds: any[];
  planTakeoffBudget: any;
  planTakeoffList: any;
  planTakeoffParts: any;
  planTakeoffsError: string;
  planTakeoffsCount: number;
}

const initialState: PlanTakeoffsStore = {
  planTakeoffItems: {},
  planTakeoffIds: [],
  planTakeoffBudget: {},
  planTakeoffList: {},
  planTakeoffParts: {},
  planTakeoffsError: "",
  planTakeoffsCount: 0,
};

export const planTakeoffsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PlanTakeoffActions.PUT_PLAN_TAKEOFFS:
      return {
        ...state,
        planTakeoffItems: { ...state.planTakeoffItems, ...payload.data.entities.planTakeoffs },
        planTakeoffIds: Array.from(new Set([...state.planTakeoffIds, ...payload.data.result])) as number[],
        planTakeoffsCount: payload.count as number,
      };
    case PlanTakeoffActions.PUT_PLAN_TAKEOFF_BY_ID:
      return {
        ...state,
        planTakeoffItems: {
          ...state.planTakeoffItems,
          [payload.result]: payload.entities.planTakeoff[payload.result],
        },
        planTakeoffBudget: { ...state.planTakeoffBudget, ...payload.entities.budget },
        planTakeoffList: {
          ...state.planTakeoffList,
          ...payload.entities.list,
        },
        planTakeoffParts: { ...state.planTakeoffParts, ...payload.entities.parts },
        planTakeoffIds: updateNormalizedCollection(state.planTakeoffIds, payload.result),
      };
    case PlanTakeoffActions.CLEAR_PLAN_TAKEOFF_DATA:
      return {
        ...state,
        planTakeoffItems: {},
        planTakeoffIds: [],
        planTakeoffsCount: 0,
      };
    case PlanTakeoffActions.CATCH_PLAN_TAKEOFFS_ERROR:
      return { ...state, planTakeoffsError: payload as string };
    default:
      return state;
  }
};
