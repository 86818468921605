import { AssembliesActions } from "redux-store/redux-actions/assemblies";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const initialState = {
  assemblies: [],
  assembliesCount: 0,
  options: [],
  editAssembling: {},

  filters: [],
};

export const assembliesReducer = (state = initialState, action: { type: string; payload: any }) => {
  const { type, payload } = action;

  switch (type) {
    case AssembliesActions.GET_ASSEMBLIES:
      return {
        ...state,
        assemblies: [...state.assemblies, ...getUniqueData(payload.data, state.assemblies, "idnumber")],
        assembliesCount: payload.count,
      };
    case AssembliesActions.GET_ASSEMBLING_BY_ID:
      return {
        ...state,
        assemblies: updateCollection(state.assemblies, payload, "idnumber"),
      };
    case AssembliesActions.PUT_ASSEMBLING_BY_ID:
      return {
        ...state,
        assemblies: [
          ...state.assemblies.map((assembling: any) => {
            if (assembling.idnumber === payload.idnumber) {
              return payload;
            } else {
              return assembling;
            }
          }),
        ],
      };
    case AssembliesActions.DELETE_ASSEMBLING_FROM_STORE:
      return {
        ...state,
        assemblies: [...state.assemblies.filter((assembling: any) => assembling.idnumber !== payload.idnumber)],
      };
    case AssembliesActions.PUT_ASSEMBLIES_TYPE_LIST:
      const updatedData = payload.map((type) => {
        return { label: type, value: type };
      });
      return {
        ...state,
        options: [{ field: "xtype", list: [...updatedData] }],
      };
    case AssembliesActions.PUT_ASSEMBLIES_FILTER_LIST:
      return {
        ...state,
        filters: payload,
      };
    case AssembliesActions.PUT_EDIT_ASSEMBLEY:
      return {
        ...state,
        editAssembling: payload,
      };
    case AssembliesActions.CLEAR_ASSEMBLIES_DATA:
      return {
        ...state,
        assemblies: [],
        assembliesCount: 0,
        editAssembling: {},
        options: [],
      };
    case AssembliesActions.CLEAR_ASSEMBLIES_DATA_DETAILS:
      return {
        ...state,
        editAssembling: {},
      };
    default:
      return state;
  }
};
