import { useState, useEffect } from "react";

// ? REDUX
import { userActivationAction } from "redux-store/redux-actions/membersProfile";

// ? COMPONENTS
import { Card, Avatar, CardHeader, CardContent, Divider, CircularProgress } from "@mui/material";
import { CardField } from "components/card-field/CardField";
import { SettingsButton } from "components/settings-button/SettingsButton";
import { nameFileUpload } from "components/modals/file-server-modal/MembersProfileFileServer";
import { nameUpdate } from "components/modals/update/MembersUpdate";
import { nameDelete } from "components/modals/delete/MembersDelete";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import useAsync from "custom-hooks/useAsync";

// ? STYLES
import styles from "./InfoCard.module.css";

// ? RESOURCES
import { getAvatar, getFirstName, getLastName, updateFullName } from "resource/utils";
import { URL } from "resource/constants/api";
import { menu } from "resource/constants/menu-list";
import { noticeTypes } from "resource/consts";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";

// ? TYPES
export interface CardPropsTypes {
  data: any;
  fake?: boolean;
}

export const UserCard = ({ data, fake }: CardPropsTypes) => {
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.membersProfile.idmenu);
  const { ndepto, name, billauth, poauth, title, username, inactiveyn, idtrack, photoUrl } = data;

  const [avatar, setAvatar] = useState("");
  const [avatarColor, setAvatarColor] = useState("");

  const [setNotice] = useNotice();

  const [loader, setLoader] = useState(false);

  // const switchHandleChange = () => {
  //   dispatch(
  //     userActivationAction({
  //       username,
  //       formData: { username, inactiveyn: !inactiveyn, idtrack },
  //       setLocalLoading,
  //     })
  //   );
  // };

  const updateUser = useAsync(userActivationAction);


    // SUBMIT
    const switchHandleChange = async (data) => {
      setLoader(true);
  
      try {
        await updateUser({
          values: data,
          actionType: ActionName.UPDATE,
        });
        setNotice("User updated", noticeTypes.success, 4000);
        setLoader(false);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    };

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openDelete } = useModal(nameDelete);
  const { open: openFileServer } = useModal(nameFileUpload);

  useEffect(() => {
    if (!photoUrl) {
      getAvatar(name, setAvatar, setAvatarColor);
    } else {
      setAvatar(`${URL}${photoUrl}`);
    }
  }, [name, photoUrl]);

  if (fake) {
    return (
      <Card sx={{ maxWidth: 345 }} className={`${styles.InfoCard} ${styles.UserCard}`}>
        <div className={`${styles.Cover} ${styles.Disabled}`}>
          <CircularProgress className={styles.Loader} />
        </div>
        <CardHeader
          className={styles.CardHeader}
          avatar={<Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar"></Avatar>}
          action={<SettingsButton options={[]} />}
          title=""
          subheader=""
        />
        <Divider />
        <CardContent className={styles.InfoCardContent}></CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: 345 }} className={`${styles.InfoCard} ${styles.UserCard}`}>
      {loader && (
        <div className={`${styles.Cover} ${loader && styles.Disabled}`}>
          <CircularProgress className={styles.Loader} />
        </div>
      )}
      <CardHeader
        className={styles.CardHeader}
        avatar={
          <Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar">
            {photoUrl ? <img className={styles.CardAvatar} src={avatar} alt="Member's avatar" /> : avatar}
          </Avatar>
        }
        action={
          <SettingsButton
            options={[
              { text: "Edit", type: "edit", callback: () => openUpdate(data), access: hasEditAccess },
              {
                activeFlag: "Active",
                text: inactiveyn ? "Inactive" : "Active",
                type: "switch",
                callback: () => switchHandleChange({ username, inactiveyn: !inactiveyn, idtrack }),
                access: hasEditAccess,
              },
              {
                text: "Fileserver",
                type: "fileserver",
                callback: () => openFileServer(data),
                access: true,
              },
              {
                text: "Delete",
                type: "delete",
                callback: () => openDelete(data),
                danger: true,
                access: hasDeleteAccess,
              },
            ]}
            loading={loader}
          />
        }
        title={updateFullName(name)}
        subheader={ndepto}
      />
      <Divider />
      <CardContent className={styles.InfoCardContent}>
        <>
          <CardField size="50%" title="First name" name={getFirstName(name)} />
          <CardField size="50%" title="Last name" name={getLastName(name)} />
          <CardField size="50%" title="Title" name={title} />
          <CardField size="50%" title="Bill Approval" name={billauth} />
          <CardField size="50%" title="PO Approval" name={poauth} />
        </>
      </CardContent>
    </Card>
  );
};
