// ? COMPONENTS
import { Field } from "formik";
import { Typography, Grid, TextField, useTheme } from "@mui/material";

// ? STYLES
import styles from "./Assemblies.module.css";

export const AssembliesFormSplitField = ({ split, splits, index, setFieldValue }) => {
  const theme = useTheme();
  return (
    <div className={`${styles.PaymentRow} ${splits.length === 1 && styles.Alone}`} key={split.name}>
      <Grid className={styles.PaymentItem} item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>{split.name}</Typography>
      </Grid>
      <Grid className={styles.PaymentItem} item xs={7}>
        <Field as={TextField} type="text" id={`st${index + 1}des`} name={`st${index + 1}des`} placeholder="Enter note" />
      </Grid>
      <Grid className={styles.PaymentItem} item xs={3} md={3}>
        <Field
          as={TextField}
          id={`stage${index + 1}`}
          name={`stage${index + 1}`}
          placeholder="Enter percentage"
          type="number"
          onChange={(e) => {
            if (e.target.value.length <= 3 && Number(e.target.value) >= 0) {
              setFieldValue(`stage${index + 1}`, Number(e.target.value));
            }
          }}
        />
      </Grid>
    </div>
  );
};
