import { MembersActions } from "redux-store/redux-actions/membersProfile";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";
import { optionListItem } from "resource/interfaces";

const initialState = {
  users: [],
  usersCount: 0,
  options: [
    {
      field: "inactiveyn",
      list: [{ label: "Inactive", value: true } as optionListItem, { label: "Active", value: false } as optionListItem],
    },
  ],
  editUser: {},

  usersCompanies: [],
  usersCompaniesCount: 0,
};

export const membersProfileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MembersActions.GET_USERS:
      return {
        ...state,
        users: [...state.users, ...getUniqueData(payload.data, state.users, "username")],
        usersCount: payload.count,
      };
    case MembersActions.GET_USER_BY_NAME:
      return {
        ...state,
        users: updateCollection(state.users, payload, "username"),
      };
    case MembersActions.PUT_USER_BY_NAME:
      return {
        ...state,
        users: [
          ...state.users.map((user: any) => {
            if (user.username === payload.username) {
              return payload;
            } else {
              return user;
            }
          }),
        ],
      };
    case MembersActions.GET_EDIT_USER:
      return { ...state, editUser: payload };
    case MembersActions.CLEAR_MEMBERS_DATA:
      return {
        ...state,
        users: [],
        usersCount: 0,
      };
    case MembersActions.DELETE_USER_FROM_STORE:
      return {
        ...state,
        users: [...state.users.filter((user: any) => user.username !== payload.username)],
      };
    case MembersActions.PUT_USERS_COMPANIES:
      return {
        ...state,
        usersCompanies: [...state.usersCompanies, ...getUniqueData(payload.data, state.usersCompanies, "idnumber")],
        usersCompaniesCount: payload.count as number,
      };
    case MembersActions.CLEAR_USERS_DETAIL_DATA:
      return {
        ...state,
        [`users${payload}`]: [],
        [`users${payload}Count`]: 0,
      };
    default:
      return state;
  }
};
