import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { fetchDevelopmentsFieldsAction, clearDetailDataAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import { Box, CircularProgress, TextField } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { TableListRow } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";

// ? STYLES
import styles from "./Developments.module.css";
import { getFormattedDate } from "resource/utils";
import { Field } from "formik";

// ? RESOURCES
import { formattedDateTimeNow } from "resource/utils";

const DevelopmentsDetailFieldTab = ({ idnumber, idcia, callback, type, hasAccess }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state: RootStateOrAny) => state.login.userData);

  const fields = useSelector((state: RootStateOrAny) => state.developments.developmentsFields);
  const fieldsCount = useSelector((state: RootStateOrAny) => state.developments.developmentsFieldsCount);
  const hasMoreToLoad = fieldsCount > fields.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia,
      type,
    }),
    [idnumber, idcia, type]
  );

  const localData = [...fields];

  useInfiniteLoading(fetchDevelopmentsFieldsAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {
    return () => {
      dispatch(clearDetailDataAction("Fields"));
    };
  }, []);

  return (
    <>
      <Box className={styles.DevelopmentsDetails}>
        <TableListHeader>
          <Grid item xs={1}>
            <Typography color={theme.palette.primary.main}>Field ID</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color={theme.palette.primary.main}>Field Name</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main}>Field Type</Typography>
          </Grid>
          <Grid item md={4.5}>
            <Typography color={theme.palette.primary.main}>Value</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main}>Updated By</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
              Date
            </Typography>
          </Grid>
        </TableListHeader>
        <div className={styles.FormList}>
          {fields.length > 0 &&
            fields.map((field, index) => (
              <div key={index}>
                <TableListRow>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>{field.idnumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color={theme.palette.text.primary}>{field.name}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary}>{field.xtype}</Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                    {hasAccess && (
                      <Field
                        as={TextField}
                        className={styles.ListInput}
                        placeholder="Enter note"
                        value={field.txtvalue}
                        onChange={(e) => {
                          localData[index].txtvalue = e.target.value;
                          localData[index].modiby = user.username;
                          localData[index].modidate = formattedDateTimeNow();
                          localData[index].changed = true;
                          if (callback) {
                            callback(localData);
                          }
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary}>{field.modiby}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                      {field.modidate && getFormattedDate(field.modidate)}
                    </Typography>
                  </Grid>
                </TableListRow>
              </div>
            ))}
          {paginationLoading ? (
            <div className={`LoaderScroll ${!fields.length && "center"}`}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default DevelopmentsDetailFieldTab;
