//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { MASTER_FLOORPLANS_NAME } from "resource/constants/master-floorplan";
import { MASTER_FLOORPLANS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${MASTER_FLOORPLANS_NAME}/file/uploadFile`;

const FloorplansFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    name: data.name,
  };

  const dataForDelete = {
    name: data.name,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Floorplan-${data.name} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={MASTER_FLOORPLANS_API}
    />
  );
};

export default FloorplansFileServer;
