import React, { useState, useEffect, useRef } from "react";

// ? REDUX
import { fetchUsersCompaniesAction, clearDataDetailsAction } from "redux-store/redux-actions/membersProfile";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, Box, FormGroup, TextField, Autocomplete, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { TabsUnstyled } from "@mui/base";
import { MemberProfileFormGeneralTab } from "./MemberProfileFormGeneralTab";
import { MemberProfileFormCompanyAccessTab } from "./MemberProfileFormCompanyAccessTab";
import { Tab, TabPanel, TabsList } from "resource/styled";
import useHeaders from "custom-hooks/useHeaders";

// ? STYLES
import styles from "./MembersProfile.module.css";
import searchFielsStyles from "components/form-field/FormField.module.css";

// ? RESOURCES
import { URL } from "resource/constants/api";
import { convertBase64, removeBase64Prefix } from "resource/utils";
import { MASTER_ROLES_API } from "resource/constants/api";
import closeButtonIcon from "resource/images/close-btn.svg";
import AvatarUploadIcon from "resource/images/avatar-user-upload.svg";
import deleteIcon from "resource/images/delete.svg";

export const MemberProfileForm = ({ title, buttonText, handleClose, formData, handleSubmit, isLoading, validation, data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const headers = useHeaders();
  // STORE
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);
  const status = useSelector(
    (state: RootStateOrAny) => state.members.options.filter((option) => option.field === "inactiveyn")[0].list
  );

  // UPLOAD IMAGE
  const attachmentRef1 = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState("");

  const uploadImage = async (evt, callback, setFieldValue) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);
      setFieldValue("photo", removeBase64Prefix(String(convertedFile)));
      callback(String(convertedFile));
    }
  };

  const deleteImage = (setFieldValue) => {
    setFieldValue("photo", "");
    setFieldValue("netfile", false);
    setAvatar("");
  };

  useEffect(() => {
    formData.photoUrl ? setAvatar(`${URL}${formData.photoUrl}`) : setAvatar("");
  }, [formData.photoUrl]);
  // = = =

  // ROLE SELECTOR CONTROLS
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const loading = open && options.length === 0;
  const sortFunc = (a, b) => {
    return a.idnumber > b.idnumber ? 1 : b.idnumber > a.idnumber ? -1 : 0;
  };
  const defaultRole = { title: "Full Access", value: 1 };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const json = await fetch(`${MASTER_ROLES_API}/list?pageIndex=0&pageSize=100`, {
        headers,
      });
      const response = await json.json();

      if (active) {
        setOptions([
          ...response.data.list.sort(sortFunc).map((role) => {
            return { title: role.name, value: role.idnumber };
          }),
        ]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  // = = =

  useEffect(() => {
    dispatch(fetchUsersCompaniesAction({ username: formData.username ? formData.username : "" }));

    return () => {
      dispatch(clearDataDetailsAction("Companies"));
      data.setLocalCiaList({});
    };
  }, []);

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik initialValues={formData} onSubmit={(values: any) => handleSubmit(values)} validationSchema={validation}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container justifyContent="space-between">
                <Box className={styles.NameFnameAvatarContainer}>
                  <Box className={styles.FullNameCollumn}>
                    <FormField size="100%" title="First Name" name="fname" placeholder="Enter first name" type="text" />
                    <FormField size="100%" title="Last Name" name="lname" placeholder="Enter last name" type="text" />
                  </Box>
                  <Box>
                    <FormGroup className={styles.UsersAvatarUploadWrapper}>
                      <input
                        ref={attachmentRef1}
                        hidden
                        type="file"
                        onChange={(evt) => uploadImage(evt, setAvatar, setFieldValue)}
                      />
                      <Box
                        className={styles.UsersAvatarUpload}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          attachmentRef1?.current?.click();
                        }}
                      >
                        {!avatar ? (
                          <Box className={styles.AvatarNotUploaded}>
                            <img src={AvatarUploadIcon} alt="Image preview" className={styles.PartsFormImagePreview} />
                          </Box>
                        ) : (
                          <Box className={styles.AvatarUploaded}>
                            <img src={avatar} alt="Image preview" className={styles.PartsFormImagePreview} />
                          </Box>
                        )}
                      </Box>
                      {avatar && (
                        <IconButton className={styles.DeleteAvatarButton} onClick={() => deleteImage(setFieldValue)}>
                          <img src={deleteIcon} alt="deleteButton" />
                        </IconButton>
                      )}

                      {/* <ErrorMessage name="attachment">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
                    </FormGroup>
                  </Box>
                </Box>

                {/* ROLE SELECT */}
                <Grid item style={{ width: "100%" }}>
                  <Typography style={{ marginBottom: "8px" }} variant="body1" color={theme.palette.info.main}>
                    Default Role
                  </Typography>
                  <Autocomplete
                    id="iddeptos"
                    className={searchFielsStyles.SearchField}
                    sx={{ width: 300 }}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option: any, value) => option.title === value.title}
                    getOptionLabel={(option: any) => `${option.value} - ${option.title}`}
                    defaultValue={
                      formData.iddeptos && formData.ndepto ? { title: formData.ndepto, value: formData.iddeptos } : defaultRole
                    }
                    options={options}
                    loading={loading}
                    onChange={(e, value: any) => {
                      setFieldValue("iddeptos", value.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={searchFielsStyles.SearchFieldInput}
                        placeholder="Select role"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab type={"button"}>General</Tab>
                  <Tab type={"button"} sx={{ mr: "5px" }}>
                    Company Access
                  </Tab>
                </TabsList>
                <Box className={styles.Tab}>
                  <TabPanel value={0}>
                    <MemberProfileFormGeneralTab status={status} />
                  </TabPanel>
                  <TabPanel value={1}>
                    <MemberProfileFormCompanyAccessTab localCiaList={data.localCiaList} setLocalCiaList={data.setLocalCiaList} />
                  </TabPanel>
                </Box>
              </TabsUnstyled>
              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
