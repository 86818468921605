import { useState } from "react";

// ? REDUX
import { addNewPlanVariableAction } from "redux-store/redux-actions/planVariables";

// ? COMPONENTS
import { PLAN_VARIABLES_NAME, PLAN_VARIABLES } from "resource/constants/plan-variables";
import { ActionName } from "resource/constants/api";
import ModalManager from "../ModalManager";
import PlanVariablesForm from "pages/plan-variables/PlanVariablesForm";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";

export const nameCreate = `${PLAN_VARIABLES_NAME}/${ActionName.CREATE}`;

const PlanVariableCreate = () => {
  const { close } = useModal(nameCreate);

  const initialFormValues = {
    name: "",
    xtype: "Text",
    xvalue: "",
    sort: "",
    varname: "",
  };

  const [isLoading, setLoader] = useState(false);
  const addNewPlanVariable = useAsync(addNewPlanVariableAction);

  const handleSubmit = async (values) => {
    setLoader(true);

    try {
      await addNewPlanVariable({ values, actionType: ActionName.CREATE });
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate}>
      <PlanVariablesForm
        title={PLAN_VARIABLES.NEW_MODAL_TITLE}
        buttonText={PLAN_VARIABLES.NEW_MODAL_SUBMIT_BUTTON}
        formData={initialFormValues}
        handleClose={close}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </ModalManager>
  );
};

export default PlanVariableCreate;
