import { TabsListUnstyled, TabPanelUnstyled, buttonUnstyledClasses, TabUnstyled } from "@mui/base";
import { styled } from "@mui/system";

// ? TABS

export const Tab = styled(TabUnstyled)`
  color: #596a72;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 0;
  border: none;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background: transparent;

  &.Mui-selected {
    color: #0100be;
    font-weight: bold;
    background: #f4f4ff;
    border-radius: 12px;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #0100be;
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
`;

export const TabsList = styled(TabsListUnstyled)`
  border-radius: 12px;
  padding: 0 10px 0 10px;
  margin: 20px 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  background: #ffffff;
  padding: 8px 16px;
`;
