import { FC, useState, useEffect } from "react";

//  ?  COMPONENTS
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  useTheme,
  TextField,
  InputLabel,
  FormGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import useHeaders from "custom-hooks/useHeaders";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { purchaseOrderEmailSchema } from "resource/schemas/purchaseOrderSchema";
import { formatPrice } from "resource/utils";
import { VENDORS_API } from "resource/constants/api";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

interface FormValues {
  emailto: string;
  emailcc: string;
  emailsubject: string;
  emailbody: string;
}

interface PurchaseOrderEmailFormProps {
  handleSubmit: (values: FormValues) => void;
  isLoading: boolean;
  buttonText: string;
  handleClose: () => void;
  title: string;
  total: number;
  subject: string;
  idvendor: string;
}

const PurchaseOrderEmailForm: FC<PurchaseOrderEmailFormProps> = ({
  handleSubmit,
  isLoading,
  buttonText,
  handleClose,
  title,
  total,
  subject,
  idvendor,
}) => {
  const theme = useTheme();
  const headers = useHeaders();
  const [vendorEmail, setVendorEmail] = useState("");
  const [fetchLoader, setFetchLoader] = useState(true);

  const initialValues = {
    emailto: vendorEmail,
    emailcc: "",
    emailsubject: subject,
    emailbody: "",
  };

  const fetchEmail = async (idvendor: string) => {
    try {
      setFetchLoader(true);
      const response = await fetch(`${VENDORS_API}/${Number(idvendor)}`, {
        headers,
      });
      const resp = await response.json();
      setVendorEmail(resp.data.email);
    } catch (err) {
      console.error(err);
    } finally {
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchEmail(idvendor);
  }, []);

  return (
    <>
      {fetchLoader ? (
        <CircularProgress className="Loader" />
      ) : (
        <Box className={styles.PartsConfirm}>
          <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
            <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <IconButton onClick={handleClose}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </Box>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            enableReinitialize
            validationSchema={purchaseOrderEmailSchema}
          >
            <Form style={{ padding: "20px 0 0" }}>
              <Grid item sm={12}>
                <FormGroup sx={{ position: "relative" }}>
                  <InputLabel htmlFor="emailto">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Email To
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="emailto" id="emailto" placeholder="Enter email" />
                  <ErrorMessage name="emailto">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="emailcc">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Email cc
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="emailcc" id="emailcc" placeholder="Enter email cc" />
                  <ErrorMessage name="emailcc">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="emailsubject">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Email subject
                    </Typography>
                  </InputLabel>
                  <Field as={TextField} type="text" name="emailsubject" id="emailsubject" placeholder="Enter email subject" />
                  <ErrorMessage name="emailsubject">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <FormGroup>
                  <InputLabel htmlFor="emailbody">
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Email message
                    </Typography>
                  </InputLabel>
                  <Field
                    className="Textarea"
                    type="textarea"
                    name="emailbody"
                    id="emailbody"
                    as={TextField}
                    placeholder="Enter message"
                    multiline
                    minRows={5}
                  />
                  <ErrorMessage name="emailbody">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </FormGroup>
                <Grid item sm={12} display="flex" justifyContent="flex-end">
                  <Typography variant="h2">{`Total ${formatPrice(String(total))}`}</Typography>
                </Grid>
              </Grid>
              <Box justifyContent="flex-start" className="FormFooter">
                <Button onClick={handleClose} className={styles.PartsCancelButton}>
                  Cancel
                </Button>
                <SubmitButton text={buttonText} isLoading={isLoading} />
              </Box>
            </Form>
          </Formik>
        </Box>
      )}
    </>
  );
};

export default PurchaseOrderEmailForm;
