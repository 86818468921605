import {
  GLOBAL_DATA_LOADER,
  MODAL_LOADER,
  BUTTON_LOADER,
  ADD_NEW_LOADER,
  UPDATE_ENITY_LOADER,
  PAGINATION_LOADER,
  CARD_LOADER,
  SET_LOADER,
  REMOVE_LOADER,
} from "redux-store/redux-actions/loaders";

const initialState = {
  globalLoader: false,
  modalLoader: false,
  buttonLoader: false,
  paginationLoader: false,
  addNewLoader: false,
  updateEntityLoader: false,
  cardLoader: false,
  loaders: {},
};

export const loadersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_DATA_LOADER:
      return { ...state, globalLoader: payload };
    case MODAL_LOADER:
      return { ...state, modalLoader: payload };
    case BUTTON_LOADER:
      return { ...state, buttonLoader: payload };
    case ADD_NEW_LOADER:
      return { ...state, addNewLoader: payload };
    case UPDATE_ENITY_LOADER:
      return { ...state, updateEnityLoader: payload };
    case PAGINATION_LOADER:
      return { ...state, paginationLoader: payload };
    case CARD_LOADER:
      return { ...state, cardLoader: payload };
    case SET_LOADER:
      return { ...state, loaders: { ...state.loaders, [payload.name]: payload.flag } };
    case REMOVE_LOADER:
      let loadersCopy = state.loaders;
      delete loadersCopy[payload.name];
      return { ...state, loaders: { ...loadersCopy } };
    default:
      return state;
  }
};
