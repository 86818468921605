// ? COMPONENTS
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import PartsItem from "./PartsItem";

import { PartOnResponse } from "resource/interfaces/part";

const PartsList = (props) => {
  const { parts }: { parts: PartOnResponse[] } = props;
  const theme = useTheme();

  return (
    <Box>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Part No</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.primary.main}>Description</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>U/M</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Formula</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Min Cost</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Max Cost</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Avg Cost</Typography>
        </Grid>
        <Grid item xs={1} display={"flex"} justifyContent={"center"}>
          <Typography color={theme.palette.primary.main}>Active</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Actions</Typography>
        </Grid>
      </TableListHeader>
      {parts.map((part: PartOnResponse, index: number) => (
        <PartsItem part={part} key={index} {...props} />
      ))}
    </Box>
  );
};

export default PartsList;
