import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Box, List, ListItemButton, ListItemIcon, Typography, useTheme } from "@mui/material";
import MenuDropdown from "../menu-dropdown/MenuDropdown";

// ? RESOURCES
import { menuList } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Navigation.module.css";

const Navigation = () => {
  const theme = useTheme();
  const location = useLocation();
  const userAccess = useSelector((state: RootStateOrAny) => state.login.readAccess);

  useEffect(() => {
    for (const property in menuList) {
      if (location.pathname.includes(menuList[property].text.toLowerCase())) {
        setActive(menuList[property].idmenu);
      }
    }
  }, []);

  const [active, setActive] = useState(0);
  const toggleActive = (idmenu: number): void => {
    setActive(idmenu);
  };

  const toggleActiveClass = (idmenu: number): string => {
    return idmenu === active ? `${styles.NavigationItemActive}` : `${styles.NavigationItemDefault}`;
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (evt: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <List className={styles.Navigation}>
      {menuList.map((item) => (
        <Box key={item.idmenu} onClick={(evt) => handleClickListItem(evt)}>
          <ListItemButton
            className={`${styles.NavigationItem} ${toggleActiveClass(item.idmenu)}`}
            onClick={() => {
              toggleActive(item.idmenu);
            }}
            aria-expanded={open ? "true" : undefined}
          >
            <ListItemIcon className={styles.NavigationListIcon}>
              <img src={item.icon} alt={item.text} className={styles.NavigationIcon} />
            </ListItemIcon>
            <Typography className={styles.NavigationText} color={theme.palette.text.secondary}>
              {item.text}
            </Typography>
            <img className={styles.NavigationIcon} src="/images/arrow-down.svg" alt={item.text} />
          </ListItemButton>
        </Box>
      ))}
      <MenuDropdown active={active} anchorEl={anchorEl} open={open} handleClose={handleClose} userAccess={userAccess} />
    </List>
  );
};

export default Navigation;
