//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { COMPANIES_NAME } from "resource/constants/companies";
import { COMPANIES_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${COMPANIES_NAME}/file/uploadFile`;

const CompaniesFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    name: data.name,
  };

  const dataForDelete = {
    name: data.name,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Company-${data.name} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={true}
      api={COMPANIES_API}
      shouldGetCleans={true}
    />
  );
};

export default CompaniesFileServer;
