import { useState, useEffect, useRef } from "react";

// ? REDUX
import { useDispatch } from "react-redux";

const useInfiniteLoading = (actionCreator, hasMoreToLoad: boolean, loading: boolean, additionalData?: any) => {
  const BOTTOM_INDENT_ON_DOCUMENT_HEIGHT = 800;

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const localLoading = useRef(false);

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - BOTTOM_INDENT_ON_DOCUMENT_HEIGHT;
    if (bottom && !localLoading.current) {
      localLoading.current = true;
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    localLoading.current = loading;
  }, [loading]);

  useEffect(() => {
    if (!page || hasMoreToLoad) {
      dispatch(actionCreator({ page, additionalData }));
    }
  }, [page, dispatch, additionalData, actionCreator, hasMoreToLoad]);

  return { page, setPage };
};

export default useInfiniteLoading;
