import { useState, useMemo, ChangeEvent, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchPurchaseOrderListAction, clearDataAction } from "redux-store/redux-actions/purchaseOrder";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import PurchaseOrderList from "pages/purchase-order/PurchaseOrderList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import useModal from "custom-hooks/useModal";
import Calendar from "components/shared/calendar/Calendar";
import PurchaseOrderCreate, { nameCreate } from "components/modals/create/PurchaseOrderCreate";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import useAccess from "custom-hooks/useAccess";
import useSearchUpdate from "custom-hooks/useSearchUpdate";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

// ? RESOURCES
import { PURCHASE_ORDER } from "resource/constants/purchase-order";
import { purchseOrderFiltersList } from "resource/constants/purchase-order";
import { formatDate, resolvePath } from "resource/utils";
import { PurchaseOrderListItem } from "resource/interfaces/purchase-order";
import { menu } from "resource/constants/menu-list";
import arrow from "resource/images/arrow-left.svg";
import { ROUTES } from "resource/routes/routes";

const ProjectTakeoffPO = ({ id, idcia, idassembly1, idvendor }) => {
  const dispatch = useDispatch();
  const mainPath = ROUTES.projectTakeoff.pathname;
  const subDirectories = [id, idcia];
  const { hasAddAccess } = useAccess(menu.purchaseOrder.idmenu);
  const purchaseOrders = useSelector((state: RootStateOrAny): PurchaseOrderListItem[] => state.purchaseOrder.purchaseOrder);
  const purchaseOrderCount = useSelector((state: RootStateOrAny) => state.purchaseOrder.purchaseOrderCount);
  const hasMoreToLoad = purchaseOrderCount > purchaseOrders.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [dateRange, setDateRange] = useState<[null, null] | [string | string]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(purchseOrderFiltersList[1]);
  const { open: openDraftCreate } = useModal(nameCreate);

  const additionalData = useMemo(
    () => ({
      filter: select,
      fromDate: dateRange[0] ? formatDate(dateRange[0]) : null,
      toDate: dateRange[1] ? formatDate(dateRange[1]) : null,
      keywords: searchKeyword,
      venderId: String(idvendor),
      idproject1: id,
      idcia,
      idassembly1,
    }),
    [select, dateRange, searchKeyword, idvendor, idassembly1, id, idcia]
  );

  useEffect(() => {
    return () => {
      dispatch(clearDataAction());
    };
  }, []);

  const { setPage } = useInfiniteLoading(fetchPurchaseOrderListAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handleDatePickerChange = (update) => {
    setDateRange(update);
    setPage(0);
    dispatch(clearDataAction());
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      const value = evt.target.value.split(" - ").map((data: string): Date => new Date(data));
      handleDatePickerChange(value);
    }
  };

  const datePickerOptions = {
    startDate,
    endDate,
    selectsRange: true,
    placeholderText: "Filter Date",
  };

  return (
    <>
      <PageHeader pageName="purchaseOrder" customIcon={arrow} path={resolvePath(mainPath, subDirectories)}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={purchseOrderFiltersList} />
        <Calendar
          handleKeyDown={handleKeyDown}
          handleDatePickerChange={handleDatePickerChange}
          options={datePickerOptions}
          name="dateRangeFilter"
        />
        <Search value={searchValue} placeholder="Search purchase order" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={PURCHASE_ORDER.NEW_MODAL_PLUS_BUTTON} openModal={openDraftCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      {<PurchaseOrderList purchaseOrder={purchaseOrders} />}

      <PurchaseOrderCreate className={styles.PoModal} />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!purchaseOrders.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(ProjectTakeoffPO);
