import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";

// ? STYLES
import styles from "./Developments.module.css";

interface DevelopmentsItemProps {
  development: any;
}

const DevelopmentsItem: FC<DevelopmentsItemProps> = ({ development }) => {
  const theme = useTheme();
  const mainPath = ROUTES.developments.pathname;
  const subDirectories = [development.idcia, development.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{development.idnumber}</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography color={theme.palette.text.primary}>{development.name}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{development.geoarea_name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{development.specs}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{development.sold}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{development.closed}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{development.total}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{development.status}</Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default DevelopmentsItem;
