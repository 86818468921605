import { createTheme } from "@mui/material";
import { Colors } from "resource/consts";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BLUE,
      light: "rgba(1, 0, 190, 1)",
    },
    secondary: {
      main: Colors.ALABASTER_WHITE,
      light: "#FBFBFB",
      dark: "#6B7280",
    },
    background: {
      default: Colors.WHITE,
    },
    text: {
      primary: "#141E2F",
      secondary: "#828282",
      disabled: "#9197A0",
    },
    error: {
      main: "#ED2615",
      light: Colors.RED,
    },
    info: {
      main: Colors.GREY,
    },
    divider: Colors.ATHEN_GRAY,
  },
  typography: {
    fontWeightLight: 300,
    htmlFontSize: 16,
    h1: {
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: 10,
      lineHeight: 1.2,
    },
    button: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: { height: "100%", marginBottom: "12px" },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        input: {
          backgroundColor: Colors.ALABASTER_WHITE,
          border: `1px solid ${Colors.ATHEN_GRAY}`,
          boxSizing: "border-box",
          borderRadius: "12px",
          height: "56px",
          padding: "0 16px",
          "&:-webkit-autofill": {
            borderRadius: "12px",
          },
          "&:focus": {
            outline: "1px solid rgba(1, 0, 190, 0.3)",
          },
        },
        notchedOutline: {
          border: "none",
          boxSizing: "border-box",
          borderRadius: "12px",
          height: "56px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: "8px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: Colors.ALABASTER_WHITE,
          border: `1px solid ${Colors.ATHEN_GRAY}`,
          boxSizing: "border-box",
          borderRadius: "12px",
          height: "56px",
          marginBottom: "12px",
          padding: "16px",
        },
        icon: { top: "calc(50% - 0.6em)" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

export default theme;
