import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchProjectMembersAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { Box, CircularProgress } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import { ProjectsAddMemeberForm } from "./ProjectsAddMemeberForm";
import { ProjectsDetailsMemersTabItem } from "./ProjectDetailsMembersTabItem";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Projects.module.css";

const ProjectDetailsMembersTab = ({ idnumber, idcia, idsub }) => {
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.projectPage.idmenu);
  const members = useSelector((state: RootStateOrAny) => state.projects.projectMembers);
  const membersCount = useSelector((state: RootStateOrAny) => state.projects.projectMembersCount);
  const hasMoreToLoad = membersCount > members.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia: Number(idcia),
    }),
    [idnumber, idcia]
  );

  const { page, setPage } = useInfiniteLoading(fetchProjectMembersAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {}, [members]);

  return (
    <Box className={styles.ProjectsDetails}>
      {hasEditAccess && <ProjectsAddMemeberForm idnumber={idnumber} idcia={idcia} />}
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Project ID</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.primary.main}>Full Name</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography color={theme.palette.primary.main}>User Name</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography color={theme.palette.primary.main}>Role</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography color={theme.palette.primary.main}>Access</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography color={theme.palette.primary.main}>Delete</Typography>
        </Grid>
      </TableListHeader>
      <div className={styles.FormList}>
        {members.length > 0 &&
          members.map((member, i) => (
            <div key={i}>
              <ProjectsDetailsMemersTabItem member={member} idsub={idsub} />
            </div>
          ))}
        {paginationLoading ? (
          <div className={`LoaderScroll ${!members.length && "center"}`}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    </Box>
  );
};

export default ProjectDetailsMembersTab;
