import { useMemo, useState, FC } from "react";

// ? REDUX
import { updatePlanTakeoffBudgetAction, deletePlanTakeoffBudgetAction } from "redux-store/redux-actions/planTakeoffs";

// ? COMPONENTS
import { TableListRow } from "components/shared/table-list/TableList";
import { Grid, Typography, useTheme, IconButton } from "@mui/material";
import { formatPrice, getFormattedDateTime } from "resource/utils";
import { planTakeoffBudgetSchema } from "resource/schemas/planTakeoffSchema";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import ToggleField from "components/shared/toggle-field/ToggleField";
import useAsync from "custom-hooks/useAsync";
import useAccess from "custom-hooks/useAccess";
import SmallLoader from "components/shared/small-loader/SmallLoader";

// ? RESOURCES
import deleteIcon from "resource/images/delete.svg";
import { BudgetInterface } from "resource/interfaces/plan-takeoff";
import { menu } from "resource/constants/menu-list";

interface PlanTakeoffBudgetItemProps {
  budget: BudgetInterface;
  takeoffId: number;
}

const PlanTakeoffBudgetItem: FC<PlanTakeoffBudgetItemProps> = ({ budget, takeoffId }) => {
  const theme = useTheme();
  const { hasEditAccess } = useAccess(menu.planTakeoff.idmenu);
  const [isLoading, setLoader] = useState(false);
  const [note, setNote] = useState(budget.notes);
  const [budgetrevised, setBudgetrevised] = useState(budget.budgetrevised);
  const updatePlanTakeoffBudget = useAsync(updatePlanTakeoffBudgetAction);
  const deletePlanTakeoffBudget = useAsync(deletePlanTakeoffBudgetAction);

  useMemo(() => {
    setNote(budget.notes);
    setBudgetrevised(budget.budgetrevised);
  }, [budget]);

  const handleSubmit = async (data: { notes: string; budgetrevised: number }) => {
    if (data.notes !== note || data.budgetrevised !== budgetrevised) {
      setNote(data.notes);
      setBudgetrevised(data.budgetrevised);
      setLoader(true);
      const values = {
        idnumber: budget.idnumber,
        idcostcode: budget.code,
        takeoffId,
        ...data,
      };

      try {
        await updatePlanTakeoffBudget({ values, endpoint: TakeoffEndpoints.UpdateBudget, id: takeoffId });
        setLoader(false);
      } catch (e) {
        console.error(e);
        setLoader(false);
      }
    }
  };

  const handleBudgetDelete = async () => {
    setLoader(true);
    const values = { idplan1: String(takeoffId), idcostcode: String(budget.code) };
    try {
      await deletePlanTakeoffBudget({ values, endpoint: TakeoffEndpoints.DeleteBudget, id: String(takeoffId) });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      <TableListRow>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{budget.code}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={theme.palette.text.primary}>{budget.name}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ minHeight: "32px" }}>
          <ToggleField
            schema={planTakeoffBudgetSchema}
            handleSubmit={handleSubmit}
            fieldName="notes"
            field={note}
            isLoading={isLoading}
            isTogglable={budget.idnumber !== "0"}
            type="text"
            initValues={{ notes: note ? note : "", budgetrevised }}
            hasAccess={hasEditAccess}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>
            {budget.budget > 0 ? formatPrice(budget.budget.toFixed(2)) : ""}
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ minHeight: "32px" }}>
          <ToggleField
            schema={planTakeoffBudgetSchema}
            handleSubmit={handleSubmit}
            fieldName="budgetrevised"
            field={budgetrevised !== 0 ? formatPrice(String(budgetrevised)) : ""}
            isLoading={isLoading}
            isTogglable={true}
            type="number"
            initValues={{ budgetrevised: budgetrevised ? budgetrevised : 0, notes: note }}
            hasAccess={hasEditAccess}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{budget.modiby}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
            {budget.modidate && getFormattedDateTime(budget.modidate)}
          </Typography>
        </Grid>
        <Grid item xs={0.5} justifyContent="flex-end" display="flex">
          {hasEditAccess && (
            <>
              {budget.idnumber !== "0" && (
                <>
                  {isLoading ? (
                    <SmallLoader />
                  ) : (
                    <IconButton
                      name="delete"
                      disabled={isLoading}
                      onClick={handleBudgetDelete}
                      sx={{ width: "100%", height: "100%", mr: "-10px" }}
                    >
                      <img src={deleteIcon} alt="Delete icon" />
                    </IconButton>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </TableListRow>
    </>
  );
};

export default PlanTakeoffBudgetItem;
