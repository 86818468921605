import { useState } from "react";

// ? REDUX
import { useDispatch } from "react-redux";
import { fetchEditVendorAction, vendorActivationAction } from "redux-store/redux-actions/vendors";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, Tooltip, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";
import { nameFileUpload } from "components/modals/file-server-modal/VendorsFileServer";
import { nameUpdate } from "components/modals/update/VendorUpdate";
import { nameDelete } from "components/modals/delete/VendorDelete";

// ? STYLES
import styles from "./Vendors.module.css";

// ? TYPES
export interface CardPropsTypes {
  data: any;
  fake?: boolean;
}

export const VendorsListCard = ({ data, fake }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.companies.idmenu);

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openDelete } = useModal(nameDelete);
  const { open: openFileServer } = useModal(nameFileUpload);

  const [localLoading, setLocalLoading] = useState(false);

  const switchHandleChange = () => {
    dispatch(
      vendorActivationAction({
        formData: { idnumber: data.idnumber, inactiveyn: !data.inactiveyn, idtrack: data.idtrack },
        setLocalLoading,
      })
    );
  };

  if (fake) {
    return (
      <div className="Fake">
        <div className="Cover Disabled">
          <CircularProgress size={25} className="Loader" />
        </div>
        <TableListRow>
          <div></div>
        </TableListRow>
      </div>
    );
  }

  return (
    <div className={styles.VendorCard}>
      <TableListRow>
        <Grid item xs={1} md={1}>
          <Tooltip title={data.idnumber}>
            <Typography color={theme.palette.text.primary}>{data.idnumber}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title={data.name}>
            <Typography color={theme.palette.text.primary}>{data.name}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} md={2}>
          <Tooltip title={data.contact}>
            <Typography color={theme.palette.text.primary}>{data.contact}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} md={2}>
          <Tooltip title={data.category}>
            <Typography color={theme.palette.text.primary}>{data.category}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1} md={1}>
          <Tooltip title={data.phone1}>
            <Typography color={theme.palette.text.primary}>{data.phone1}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} md={2}>
          <Tooltip title={data.email}>
            <Typography color={theme.palette.text.primary}>{data.email}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1} md={1} justifyContent="center" display="flex">
          <Tooltip title={data.taxrate}>
            <Typography color={theme.palette.text.primary}>{data.taxrate}</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          {localLoading ? (
            <CircularProgress className={styles.CardLoader} />
          ) : (
            <SettingsButton
              options={[
                { text: "Edit", type: "edit", callback: () => openUpdate(data), access: hasEditAccess },
                {
                  activeFlag: "Active",
                  text: data.inactiveyn ? "Inactive" : "Active",
                  type: "switch",
                  callback: () => switchHandleChange(),
                  access: hasEditAccess,
                },
                {
                  text: "Fileserver",
                  type: "fileserver",
                  callback: () => openFileServer(data),
                  access: true,
                },
                {
                  text: "Delete",
                  type: "delete",
                  callback: () => openDelete({ idnumber: data.idnumber, name: data.name }),
                  danger: true,
                  access: hasDeleteAccess,
                },
              ]}
              loading={localLoading}
            />
          )}
        </Grid>
      </TableListRow>
    </div>
  );
};
