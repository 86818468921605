// ? ACTIONS
export enum VendorsActions {
  ADD_NEW_VENDOR = "ADD_NEW_VENDOR",
  EDIT_VENDOR = "EDIT_VENDOR",
  GET_VENDOR_BY_ID = "GET_VENDOR_BY_ID",
  PUT_VENDOR_BY_ID = "PUT_VENDOR_BY_ID",
  FETCH_VENDOR_BY_ID = "FETCH_VENDOR_BY_ID",
  FETCH_EDIT_VENDOR = "FETCH_EDIT_VENDOR",
  GET_EDIT_VENDOR = "GET_EDIT_VENDOR",
  GET_VENDORS = "GET_VENDORS",
  FETCH_VENDORS = "FETCH_VENDORS",
  DELETE_VENDOR_FROM_SERVER = "DELETE_VENDOR_FROM_SERVER",
  DELETE_VENDOR_FROM_STORE = "DELETE_VENDOR_FROM_STORE",
  VENDOR_ACTIVATION = "VENDOR_ACTIVATION",
  FETCH_VENDORS_TYPE_LIST = "FETCH_VENDORS_TYPE_LIST",
  PUT_VENDORS_TYPE_LIST = "PUT_VENDORS_TYPE_LIST",
  CLEAR_EDIT_VENDORS_DATA = "CLEAR_EDIT_VENDORS_DATA",
  CATCH_VENDORS_ERROR = "CATCH_VENDORS_ERROR",
  CLEAR_VENDORS_DATA = "CLEAR_VENDORS_DATA",
}

// ? ACTION CREATORS

export const addNewVendorAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: VendorsActions.ADD_NEW_VENDOR,
    payload,
    promise,
  };
};

export const editVendorAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: VendorsActions.EDIT_VENDOR,
    payload,
    promise,
  };
};

export const getVendorAction = (payload: any) => {
  return {
    type: VendorsActions.GET_VENDOR_BY_ID,
    payload,
  };
};

export const putVendorAction = (payload: any) => {
  return {
    type: VendorsActions.PUT_VENDOR_BY_ID,
    payload,
  };
};

export const fetchVendorAction = (payload: any) => {
  return {
    type: VendorsActions.FETCH_VENDOR_BY_ID,
    payload,
  };
};

export const fetchEditVendorAction = (payload: any) => {
  return {
    type: VendorsActions.FETCH_EDIT_VENDOR,
    payload,
  };
};

export const getEditVendorAction = (payload: any) => {
  return {
    type: VendorsActions.GET_EDIT_VENDOR,
    payload,
  };
};

export const getVendorsAction = (payload: any) => {
  return {
    type: VendorsActions.GET_VENDORS,
    payload,
  };
};

export const fetchVendorsAction = (payload: any) => {
  return {
    type: VendorsActions.FETCH_VENDORS,
    payload,
  };
};

export const deleteVendorServerAction = (payload: any, promise?: { resolve: () => void; reject: () => void }) => {
  return {
    type: VendorsActions.DELETE_VENDOR_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteVendorStoreAction = (payload: any) => {
  return {
    type: VendorsActions.DELETE_VENDOR_FROM_STORE,
    payload,
  };
};

export const vendorActivationAction = (payload: any) => {
  return {
    type: VendorsActions.VENDOR_ACTIVATION,
    payload,
  };
};

export const fetchVendorsTypeListAction = () => {
  return {
    type: VendorsActions.FETCH_VENDORS_TYPE_LIST,
  };
};

export const putVendorsTypeListAction = (payload: any) => {
  return {
    type: VendorsActions.PUT_VENDORS_TYPE_LIST,
    payload,
  };
};

export const catchVendorsErrorAction = (payload: any) => {
  return {
    type: VendorsActions.CATCH_VENDORS_ERROR,
    payload,
  };
};

export const clearEditVendorAction = () => {
  return { type: VendorsActions.CLEAR_EDIT_VENDORS_DATA };
};

export const clearDataAction = () => {
  return {
    type: VendorsActions.CLEAR_VENDORS_DATA,
  };
};
