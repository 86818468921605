import { useState } from "react";

// ? REDUX
import { deleteCompanyServerAction } from "redux-store/redux-actions/companies";

// ? COMPONENTS
import { Typography, IconButton, Button, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import useModal from "custom-hooks/useModal";
import ModalManager from "../ModalManager";
import useAsync from "custom-hooks/useAsync";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { COMPANIES_NAME } from "resource/constants/companies";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "./Delete.module.css";

export const nameDelete = `${COMPANIES_NAME}/${ActionName.DELETE}`;

const CompanyDelete = () => {
  const theme = useTheme();
  const { close, data } = useModal(nameDelete);
  const [isLoading, setLoader] = useState(false);
  const [setNotice] = useNotice();

  const deleteCompany = useAsync(deleteCompanyServerAction);
  const handleDeleteCompany = async () => {
    setLoader(true);
    try {
      await deleteCompany({ values: { idnumber: data.idnumber }, actionType: ActionName.DELETE });
      setLoader(false);
      setNotice("Company deleted", noticeTypes.success, 4000);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameDelete}>
      <div className={styles.DeleteModal}>
        <div className={styles.DeleteBox}>
          <div className={styles.FormHeader}>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`Delete Company?`}
            </Typography>
            <IconButton className={styles.CloseButton} onClick={close}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </div>
          <Typography color={theme.palette.text.secondary}>
            {`Are you sure you want to delete "${data.name}"? Once you delete it, you won’t be able to see it again.`}
          </Typography>
          <div className={styles.FormFooter}>
            <Button onClick={close} className={styles.CancelButton}>
              Cancel
            </Button>
            <SubmitButton callback={() => handleDeleteCompany()} large type="delete" text="Delete" isLoading={isLoading} />
          </div>
        </div>
      </div>
    </ModalManager>
  );
};

export default CompanyDelete;
