// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader, TableListRow, TableListDivider } from "components/shared/table-list/TableList";

import { formatPrice } from "resource/utils";

const getJobCostAreas = (jobCostSublist) => {
  const areas = new Set();
  for (const value of jobCostSublist) {
    areas.add(value.area);
  }
  return Array.from(areas);
};

const JobCostDetailList = ({ jobCostSublist }) => {
  const theme = useTheme();
  const areas = getJobCostAreas(jobCostSublist);

  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Number</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.primary.main}>Cost Code</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Original Budget</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Revised Budget</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Incurred</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Open PO</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Job Cost</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Budget Over(Under)</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Budget Sq.Ft.</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Job Cost Sq.Ft.</Typography>
        </Grid>
        <Grid item xs={1} justifyContent="flex-end" display="flex">
          <Typography color={theme.palette.primary.main}>Notes</Typography>
        </Grid>
      </TableListHeader>

      {areas.map((area: any) => (
        <div key={area}>
          {jobCostSublist
            .filter((jobCost) => jobCost.area === area)
            .map((item) => (
              <div key={item.code}>
                <TableListRow>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>{item.code}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color={theme.palette.text.primary}>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>
                      {item.budget !== 0 && formatPrice(item.budget.toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>
                      {item.budgetrevised !== 0 && formatPrice(item.budgetrevised.toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>
                      {item.incurred !== 0 && formatPrice(item.incurred.toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>
                      {item.openpo !== 0 && formatPrice(item.openpo.toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}>
                      {item.jobcost !== 0 && formatPrice(item.jobcost.toFixed(2))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}></Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}></Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color={theme.palette.text.primary}></Typography>
                  </Grid>
                  <Grid item xs={1} justifyContent="flex-end" display="flex">
                    <Typography color={theme.palette.text.primary}>{item.notes}</Typography>
                  </Grid>
                </TableListRow>
              </div>
            ))}
          <>
            <TableListDivider>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography color={theme.palette.text.primary}>{area}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(
                    jobCostSublist
                      .filter((jobCost) => jobCost.area === area)
                      .reduce((prev, item) => prev + item.budget, 0)
                      .toFixed(2)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(
                    jobCostSublist
                      .filter((jobCost) => jobCost.area === area)
                      .reduce((prev, item) => prev + item.budgetrevised, 0)
                      .toFixed(2)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(
                    jobCostSublist
                      .filter((jobCost) => jobCost.area === area)
                      .reduce((prev, item) => prev + item.incurred, 0)
                      .toFixed(2)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(
                    jobCostSublist
                      .filter((jobCost) => jobCost.area === area)
                      .reduce((prev, item) => prev + item.openpo, 0)
                      .toFixed(2)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}>
                  {formatPrice(
                    jobCostSublist
                      .filter((jobCost) => jobCost.area === area)
                      .reduce((prev, item) => prev + item.jobcost, 0)
                      .toFixed(2)
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography color={theme.palette.text.primary}></Typography>
              </Grid>
              <Grid item xs={1} justifyContent="flex-end" display="flex"></Grid>
            </TableListDivider>
          </>
        </div>
      ))}
    </>
  );
};

export default JobCostDetailList;
