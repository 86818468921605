// @ts-nocheck
import { render } from "react-dom";

// ? ROUTING
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux-store/redux-reducers/rootReducer";

// ? REDUX
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux-store/store";

// ? COMPONENTS
import { App } from "App";
import { CircularProgress } from "@mui/material";

// ? STYLES
import { ThemeProvider } from "@mui/material";
import theme from "theme";
import "./index.css";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

if (process.env.NODE_ENV === "production") {
  LogRocket.init("gvbgo0/lovett");
  setupLogRocketReact(LogRocket);
}

const rootComponent = document.getElementById("root");

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={<CircularProgress className="Loader" />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  rootComponent
);
