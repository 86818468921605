import { useState, useMemo, ChangeEvent, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchProjectsAction, clearDataAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";
import { nameCreate } from "components/modals/create/ProjectsCreate";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import ProjectsList from "./ProjectsList";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import ProjectsCreate from "components/modals/create/ProjectsCreate";

// ? RESOURCES
import { PROJECTS, PROJECTS_NAME, projectsFiltersList } from "resource/constants/projects";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Projects.module.css";

const Projects = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.projectPage.idmenu);
  const projects = useSelector((state: RootStateOrAny) => state.projects.projectList);
  const projectsCount = useSelector((state: RootStateOrAny) => state.projects.projectsCount);
  const hasMoreToLoad = projectsCount > projects.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(projectsFiltersList[0]);

  const { open: openCreate } = useModal(nameCreate);

  const additionalData = useMemo(
    () => ({
      status: select,
      keywords: searchKeyword,
    }),
    [select, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchProjectsAction, hasMoreToLoad, paginationLoading, additionalData);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearDataAction());
  };

  useEffect(() => {
    return () => {
      dispatch(clearDataAction());
    };
  }, []);

  return (
    <>
      <PageHeader pageName={PROJECTS_NAME}>
        <FilterSelect handleChange={handleFilterChange} select={select} options={projectsFiltersList} />
        <Search value={searchKeyword} placeholder="Search projects" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={PROJECTS.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <ProjectsList projects={projects} />

      <ProjectsCreate className={styles.ProjetsModal} />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!projects.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(Projects);
