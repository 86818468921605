import { useState, useEffect, useRef } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form } from "formik";
import { IconButton, Typography, Grid, CircularProgress, Box, FormGroup, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";

// ? STYLES
import styles from "./Companies.module.css";

// ? RESOURCES
import { convertBase64, removeBase64Prefix } from "resource/utils";
import { URL } from "resource/constants/api";
import { companiesSchema } from "resource/schemas/companiesSchema";
import closeButtonIcon from "resource/images/close-btn.svg";
import AvatarUploadIcon from "resource/images/avatar-user-upload.svg";
import deleteIcon from "resource/images/delete.svg";

export const CompaniesForm = ({ title, buttonText, handleClose, formData, isLoading, handleSubmit }) => {
  const theme = useTheme();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  // UPLOAD AVATAR
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState("");

  const uploadImage = async (evt, callback, setFieldValue) => {
    if (evt.target.files.length) {
      const file = evt.target.files[0];
      const convertedFile = await convertBase64(file);
      setFieldValue("photo", removeBase64Prefix(String(convertedFile)));
      callback(String(convertedFile));
    }
  };

  const deleteImage = (setFieldValue) => {
    setFieldValue("photo", "");
    setFieldValue("netfile", false);
    setAvatar("");
  };

  useEffect(() => {
    formData.photoUrl ? setAvatar(`${URL}${formData.photoUrl}`) : setAvatar("");
  }, [formData.photoUrl]);
  // = = =

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik initialValues={formData} onSubmit={(values: any) => handleSubmit(values)} validationSchema={companiesSchema}>
          {({ setFieldValue }) => (
            <Form className={styles.CreateForm}>
              {/* HEADER */}
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>

              <Grid container justifyContent="space-between">
                <Box className={styles.NameFnameAvatarContainer}>
                  <Box className={styles.FullNameCollumn}>
                    <FormField size="100%" title="Company Name" name="name" placeholder="Enter company name" type="text" />
                    <FormField
                      size="100%"
                      title="Company Address"
                      name="taddress"
                      placeholder="Enter company address"
                      type="text"
                    />
                  </Box>

                  {/* AVATAR UPLOAD BLOCK */}
                  <Box>
                    <FormGroup className={styles.UsersAvatarUploadWrapper}>
                      <input
                        ref={attachmentRef}
                        hidden
                        type="file"
                        onChange={(evt) => uploadImage(evt, setAvatar, setFieldValue)}
                      />
                      <Box
                        className={styles.UsersAvatarUpload}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          attachmentRef?.current?.click();
                        }}
                      >
                        {!avatar ? (
                          <Box className={styles.AvatarNotUploaded}>
                            <img src={AvatarUploadIcon} alt="Image preview" className={styles.PartsFormImagePreview} />
                          </Box>
                        ) : (
                          <Box className={styles.AvatarUploaded}>
                            <img src={avatar} alt="Image preview" className={styles.PartsFormImagePreview} />
                          </Box>
                        )}
                      </Box>
                      {avatar && (
                        <IconButton className={styles.DeleteAvatarButton} onClick={() => deleteImage(setFieldValue)}>
                          <img src={deleteIcon} alt="deleteButton" />
                        </IconButton>
                      )}
                    </FormGroup>
                  </Box>
                </Box>

                <FormField size="47%" title="Company Email" name="email" placeholder="Enter company email" type="email" />
                <FormField size="47%" title="Company Website" name="website" placeholder="Enter website" type="text" />
                <FormField size="47%" title="City" name="tcity" placeholder="Enter city name" type="text" />
                <FormField size="47%" title="State" name="tstate" placeholder="Enter state name" type="text" />
                <FormField size="47%" title="Telephone number" name="tel" placeholder="Enter telephone number" type="tel" />
                <FormField size="47%" title="Fax Number" name="fax" placeholder="Enter fax number" type="tel" />
                <FormField size="32%" title="Federal ID" name="federalid" placeholder="Enter federal ID" type="number" />
                <FormField size="32%" title="Sales Tax" name="taxrate1" placeholder="Enter sales tax" type="number" />
                <FormField size="32%" title="Late Fee" name="latefee" placeholder="Enter late fee" type="number" />
              </Grid>

              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
