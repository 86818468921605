import { takeLatest, put } from "redux-saga/effects";
import { PROJECT_TAKEOFF_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";
import { normalizeItemsList, normalizeTakeoffItemById } from "resource/normalize";

import {
  ProjectTakeoffActions,
  putProjectTakeoffByIdAction,
  putProjectTakeoffsAction,
  fetchProjectTakeoffByIdAction,
  putReleaseListAction,
  clearDataAction,
} from "../redux-actions/projectTakeoffs";

import { setPaginationLoaderAction } from "../redux-actions/loaders";
import { TakeoffEndpoints } from "resource/constants/takeoffs";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchProjectTakeoffsWorker(action) {
  const {
    payload: {
      additionalData: { status, keywords, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PROJECT_TAKEOFF_API}/list`, {
    ...params,
    ...(idcia && { idcia }),
    ...(keywords && { keywords }),
    ...(status && { filter: status }),
  });

  const dataWithId = data.list.map((item) => {
    return { ...item, uniqueid: `${item.idcia}${item.idnumber}` };
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(
      putProjectTakeoffsAction({ data: normalizeItemsList(dataWithId, "projectTakeoffs", "uniqueid"), count: data.count })
    );
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchProjectTakeoffByIdWorker(action) {
  const {
    payload: { id, idcia },
  } = action;

  const { errno, message, data } = yield get(`${PROJECT_TAKEOFF_API}/${id}/${idcia}`);

  const dataWithUniqueId = { ...data, uniqueid: `${data.idcia}${data.idnumber}` };

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putProjectTakeoffByIdAction(normalizeTakeoffItemById(dataWithUniqueId, "projectTakeoff", "uniqueid")));
  }
}

function* updateProjectTakeoffWorker(action) {
  const {
    payload: { values, endpoint, dataForFetch },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PROJECT_TAKEOFF_API}/${endpoint}`, { ...values }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Plan Takeoff's ${endpoint} endpoint was updated successfully`);
    yield put(fetchProjectTakeoffByIdAction(dataForFetch));
    yield put(clearNotifierAction());
  }
}

function* updateProjectTakeoffBudgetWorker(action) {
  const {
    payload: { valuesForBudgetCreate, valuesForBudgetUpdate, endpoint, dataForFetch },
    promise: { resolve, reject },
  } = action;

  let budgetId = valuesForBudgetCreate.idnumber;
  if (budgetId === "0") {
    const { errno, message, data } = yield post(
      `${PROJECT_TAKEOFF_API}/${TakeoffEndpoints.AddBudget}`,
      { ...valuesForBudgetCreate },
      "json"
    );

    if (errno !== 0) {
      reject(message);
      yield catchError(message);
    } else {
      budgetId = data.newId;
    }
  }

  if (budgetId !== "0") {
    const { errno, message } = yield post(
      `${PROJECT_TAKEOFF_API}/${endpoint}`,
      { ...valuesForBudgetUpdate, idnumber: budgetId },
      "urlEncode"
    );

    if (errno !== 0) {
      reject(message);
      yield catchError(message);
    } else {
      resolve(`Plan Takeoff's updateBudget endpoint was updated successfully`);
      yield put(fetchProjectTakeoffByIdAction({ ...dataForFetch }));
      yield put(clearNotifierAction());
    }
  }
}

function* deleteProjectTakeoffWorker(action) {
  const {
    payload: { values, endpoint },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${PROJECT_TAKEOFF_API}/${endpoint}`, { ...values }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Project Takeoff was deleted successfully`);
    yield put(clearDataAction());
  }
}

function* fetchProjectTakeoffReleaseListWorker(action) {
  const {
    payload: { page, additionalData },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { ...additionalData, pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${PROJECT_TAKEOFF_API}/releaselist`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(
      putReleaseListAction({
        data: normalizeItemsList(data.list, "projectTakeoffReleaseList", "idassembly1"),
        count: data.count,
      })
    );
    yield put(setPaginationLoaderAction(false));
  }
}

export function* projectTakeoffSaga() {
  yield takeLatest(ProjectTakeoffActions.FETCH_PROJECT_TAKEOFFS, fetchProjectTakeoffsWorker);
  yield takeLatest(ProjectTakeoffActions.FETCH_PROJECT_TAKEOFF_BY_ID, fetchProjectTakeoffByIdWorker);
  yield takeLatest(ProjectTakeoffActions.ADD_NEW_PROJECT_TAKEOFF, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.EDIT_PROJECT_TAKEOFF, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF, deleteProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_ASSEMBLY_NOTES, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_BUDGET, updateProjectTakeoffBudgetWorker);
  yield takeLatest(ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_BUDGET, updateProjectTakeoffBudgetWorker);
  yield takeLatest(ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_BUDGET, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_ASSEMBLY, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_ASSEMBLY, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.DELETE_PROJECT_TAKEOFF_PART, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.ADD_PROJECT_TAKEOFF_PART, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_PART, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.IMPORT_PROJECT_TAKEOFF_PLAN, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.RELEASE_PROJECT_TAKEOFFS_LIST, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.IMPORT_PROJECT_TAKEOFF_PROJECT, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.UPDATE_PROJECT_TAKEOFF_ASSEMBLY_STATUS, updateProjectTakeoffWorker);
  yield takeLatest(ProjectTakeoffActions.FETCH_PROJECT_TAKEOFFS_RELEASE_LIST, fetchProjectTakeoffReleaseListWorker);
}
