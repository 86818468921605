import { PartOnRequest, PartOnResponse } from "resource/interfaces/part";
import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum PartsActions {
  UPDATE_PART = "UPDATE_PART",
  CREATE_PART = "CREATE_PART",
  PUT_PARTS = "PUT_PARTS",
  PUT_PART_BY_ID = "PUT_PART_BY_ID",
  PUT_PART_UPC = "GET_PART_UPC",
  DELETE_PART = "DELETE_PART",
  DELETE_PART_FROM_STORE = "DELETE_PART_FROM_STORE",
  DELETE_PARTS_PRICING = "DELETE_PARTS_PRICING",
  FETCH_PARTS = "FETCH_PARTS",
  FETCH_PART_BY_ID = "FETCH_PART_BY_ID",
  FETCH_PART_UPC = "FETCH_PARTS_UPC",
  CREATE_PARTS_VENDOR_PRICING = "CREATE_PARTS_VENDOR_PRICING",
  CLEAR_DATA = "CLEAR_DATA",
  CATCH_PARTS_ERROR = "CATCH_PARTS_ERROR",
}

// ? ACTION CREATORS
export const updatePartAction = (
  payload: { values: PartOnRequest; actionType: string },
  promise?: { resolve: () => void; reject: () => void }
) => {
  return {
    type: PartsActions.UPDATE_PART,
    payload,
    promise,
  };
};

export const createPartAction = (
  payload: { values: PartOnRequest; actionType: string },
  promise?: { resolve: () => void; reject: () => void }
) => {
  return {
    type: PartsActions.CREATE_PART,
    payload,
    promise,
  };
};

export const fetchPartsAction = (payload: number | { additionalData: string; page: number }) => {
  return {
    type: PartsActions.FETCH_PARTS,
    payload,
  };
};

export const fetchPartByIdAction = (payload: string) => {
  return {
    type: PartsActions.FETCH_PART_BY_ID,
    payload,
  };
};

export const fetchPartUpcAction = () => {
  return {
    type: PartsActions.FETCH_PART_UPC,
  };
};

export const putPartsAction = (payload: { data: PartOnResponse[]; count: number }) => {
  return {
    type: PartsActions.PUT_PARTS,
    payload,
  };
};

export const putPartByIdAction = (payload: PartOnResponse) => {
  return {
    type: PartsActions.PUT_PART_BY_ID,
    payload,
  };
};

export const putPartUpcAction = (payload: string) => {
  return {
    type: PartsActions.PUT_PART_UPC,
    payload,
  };
};

export const deletePartAction = (payload, promise) => {
  return {
    type: PartsActions.DELETE_PART,
    payload,
    promise,
  };
};

export const deletePartFromStoreAction = (payload: string) => {
  return {
    type: PartsActions.DELETE_PART_FROM_STORE,
    payload,
  };
};

export const deletePartsPricingAction = (payload, promise) => {
  return {
    type: PartsActions.DELETE_PARTS_PRICING,
    payload,
    promise,
  };
};

export const createPartsVendorPricingAction = (payload, promise) => {
  return {
    type: PartsActions.CREATE_PARTS_VENDOR_PRICING,
    payload,
    promise,
  };
};

export const clearDataAction = () => {
  return {
    type: PartsActions.CLEAR_DATA,
  };
};

export const catchPartsErrorAction = (payload: string) => {
  return {
    type: PartsActions.CATCH_PARTS_ERROR,
    payload,
  };
};
