import { PlansActions } from "redux-store/redux-actions/planDrawer";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const initialState = {
  development: [],
  developmentCount: 0,
  project: [],
  projectCount: 0,
  floorplan: [],
  floorplanCount: 0,
  plansError: "",
};

export const planDrawerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PlansActions.GET_PLANS:
      return {
        ...state,
        [payload.category]: [...state[payload.category], ...getUniqueData(payload.data, state[payload.category], "idnumber")],
        [payload.categoryCount]: payload.count,
      };
    case PlansActions.GET_PLAN_BY_ID:
      return {
        ...state,
        [payload.category]: updateCollection(state[payload.category], payload, "idnumber"),
      };
    case PlansActions.PUT_PLAN_BY_ID:
      return {
        ...state,
        [payload.category]: [
          ...state[payload.category].map((plan: any) => {
            if (plan.idnumber === payload.idnumber) {
              return payload;
            } else {
              return plan;
            }
          }),
        ],
      };
    case PlansActions.DELETE_PLAN_FROM_STORE:
      return {
        ...state,
        [payload.category]: [...state[payload.category].filter((plan) => plan.idnumber !== payload.idnumber)],
      };
    case PlansActions.CLEAR_PLANS_DATA:
      return {
        ...state,
        development: [],
        developmentCount: 0,
        project: [],
        projectCount: 0,
        floorplan: [],
        floorplanCount: 0,
      };
    case PlansActions.CATCH_PLANS_ERROR:
      return { ...state, plansError: payload };
    default:
      return state;
  }
};
