import { NotifierActions } from "redux-store/redux-actions/notifier";

const initialState = {
  notification: null,
};

export const notifierReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NotifierActions.SET_NOTICE:
      return {
        ...state,
        notification: payload,
      };
    case NotifierActions.CLEAR_NOTIFIER:
      return {
        ...state,
        notification: null,
      };
    default:
      return state;
  }
};
