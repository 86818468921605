// ? COMPONENTS
import { Button, Typography } from "@mui/material";

// ? STYLES
import styles from "./AddNewButton.module.css";

// ? TYPES
interface AddNewButtonProps {
  text: string;
  openModal?: () => void;
  disabled: boolean;
}

const AddNewButton = ({ text, openModal, disabled }: AddNewButtonProps) => {
  return (
    <Button variant={"outlined"} className={styles.AddNewButton} onClick={openModal} disabled={disabled}>
      <img src="/images/plus.svg" alt="Plus sign" />
      <Typography>{`${text}`}</Typography>
    </Button>
  );
};

export default AddNewButton;
