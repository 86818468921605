// ? RESOURCES
import checkboxCheckedIcon from "resource/images/checkbox-checked-icon.svg";

//  ? STYLES
import styles from "./CheckboxIcon.module.css";

enum CheckboxSize {
  large = "styles.CheckboxIconLarge",
  small = "CheckboxIconSmall",
}

export const CheckboxIcon = ({ disabled = false, size = "small" }) => {
  return (
    <>
      <img
        alt="checkbox"
        src={checkboxCheckedIcon}
        className={`${styles[CheckboxSize[size]]} ${disabled ? `${styles.CheckboxIconDisabled}` : ``}`}
      />
    </>
  );
};
