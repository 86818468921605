import { FC, useState, useEffect } from "react";

// ? COMPONENTS
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { IconButton, Typography, Grid, Alert, Checkbox, useTheme, FormGroup, InputLabel, Box, TextField } from "@mui/material";
import { useSearch } from "custom-hooks/useSearch";
import { SearchField } from "components/form-field/SearchField";
import { PROJECTS_API, CHANGE_ORDERS_API } from "resource/constants/api";
import { resolvePath } from "resource/utils";
import { ValidationError } from "components/shared/validation-error/ValidationError";
import { CURRENT_TIME_FOR_SERVER } from "resource/consts";
import useHeaders from "custom-hooks/useHeaders";

// ? RESOURCES
import checkboxCheckedIcon from "resource/images/checkbox-checked-icon.svg";
import { changeOrderCreateSchema } from "resource/schemas/changeOrderSchema";

// ? STYLES
import styles from "./ChangeOrder.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import plusIcon from "resource/images/plus.svg";
import deleteIcon from "resource/images/delete.svg";
import { TableListDivider } from "components/shared/table-list/TableList";
import { formatPrice } from "resource/utils";
import StatusSubmitButtons from "components/shared/status-submit-buttons/StatusSubmitButtons";

interface ChangeOrderCreateFormProps {
  title: string;
  handleSubmit: (values: any) => void;
  formData: any;
  handleClose: () => void;
  isLoading: boolean;
}

const ChangeOrderCreateForm: FC<ChangeOrderCreateFormProps> = ({ title, handleSubmit, formData, handleClose, isLoading }) => {
  const theme = useTheme();
  const headers = useHeaders();

  const [project, setProject] = useState<null | any>(null);
  const [error, setError] = useState<null | string>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [optionsProjects, setQueryProjects, isPendingProjects, initialConditionProjects, errorMessageProjects] =
    useSearch(PROJECTS_API);
  const subDirectories = ["checkCreateChangeOrder"];

  useEffect(() => {
    if (project) {
      const params = { idcia: project.idcia, idproject1: project.idnumber };
      fetch(resolvePath(CHANGE_ORDERS_API, subDirectories), {
        headers,
        method: "POST",
        body: new URLSearchParams(params),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.errno) {
            setError(response.message);
            setIsDisabled(true);
            setTimeout(() => setError(null), 5000);
          } else {
            setIsDisabled(false);
          }
        })
        .catch((err) => {
          console.error(err.message);
        });
    }
  }, [project]);

  const prepareValuesForSubmit = (values) => {
    const currentProject: any =
      optionsProjects.length > 0 && optionsProjects.find((project: any) => project.idnumber === values.idproject1);
    const total = values.coPriceList.length > 0 ? values.coPriceList.reduce((acc, it) => acc + Number(it.price), 0) : 0;

    const coPriceListStructured =
      values.coPriceList.length > 0 &&
      values.coPriceList.map((priceIt: { description: string; price: number }, index: number) => {
        const priceItem = priceIt.description &&
          priceIt.price && {
            idnumber: 0,
            item: index + 1,
            description: priceIt.description,
            price: Number(priceIt.price),
            updateyn: false,
            deleteyn: false,
          };

        return priceItem;
      });

    const updatedValues = {
      idcia: currentProject.idcia,
      idproject1: values.idproject1,
      refdate: CURRENT_TIME_FOR_SERVER,
      status: values.status,
      notes: values.notes,
      agreeyn: values.agreeyn,
      tcheck: total,
      tcontract: 0,
      total,
      coPriceList: coPriceListStructured,
    };

    handleSubmit(updatedValues);
  };

  return (
    <>
      {errorMessageProjects && <Alert severity="error">{errorMessageProjects}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <div className={styles.CreateFormExtraLarge}>
        <Formik
          initialValues={formData}
          onSubmit={(values: any) => prepareValuesForSubmit(values)}
          validationSchema={changeOrderCreateSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container spacing={2} sx={{ mb: "10px" }}>
                <Grid item sm={12}>
                  <InputLabel htmlFor="group" sx={{ p: 0, m: 0 }}>
                    <Typography variant="body1" color={theme.palette.info.main}>
                      Choose Project
                    </Typography>
                  </InputLabel>
                  <SearchField
                    size="100%"
                    title=""
                    responseName="idnumber"
                    name="idproject1"
                    label="name"
                    placeholder="Search project"
                    setFieldValue={setFieldValue}
                    initialFormValues={""}
                    options={optionsProjects}
                    initialCondition={initialConditionProjects}
                    onQueryChange={setQueryProjects}
                    isPending={isPendingProjects}
                    callback={setProject}
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormGroup>
                    <InputLabel htmlFor="notes">
                      <Typography variant="body1" color={theme.palette.info.main}>
                        Notes
                      </Typography>
                    </InputLabel>
                    <Field
                      className={styles.ChangeOrderTextarea}
                      type="textarea"
                      name="notes"
                      id="notes"
                      as={TextField}
                      placeholder="Enter notes here"
                      multiline
                      minRows={3}
                    />
                  </FormGroup>
                  <ErrorMessage name="notes">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </Grid>
              </Grid>
              <Grid>
                <Box className={styles.Group}>
                  <FieldArray
                    name="coPriceList"
                    render={(arrayHelpers) => (
                      <>
                        <div className={styles.GroupHeader}>
                          <Typography variant="body1" color={theme.palette.info.main} className={styles.GroupTitle}>
                            Add Price List
                          </Typography>
                          <div>
                            <IconButton aria-label="delete" onClick={() => arrayHelpers.push({ description: "", price: 0 })}>
                              <img src={plusIcon} alt="" />
                            </IconButton>
                            <Typography color={theme.palette.primary.main}>Add Row</Typography>
                          </div>
                        </div>

                        <Grid container className={styles.PaymentHeader}>
                          <Grid className={styles.HeaderItem} item xs={1}>
                            <Typography color={theme.palette.primary.main}>#</Typography>
                          </Grid>
                          <Grid className={styles.HeaderItem} item xs={7}>
                            <Typography color={theme.palette.primary.main}>Description</Typography>
                          </Grid>
                          <Grid className={styles.HeaderItem} item xs={4}>
                            <Typography color={theme.palette.primary.main}>Amount</Typography>
                          </Grid>
                        </Grid>

                        {values.coPriceList &&
                          values.coPriceList.map((price: { description: string; price: number }, index: number) => (
                            <Grid container key={index} className={styles.PaymentRow}>
                              <Grid className={styles.PaymentItem} item xs={1}>
                                <Typography color={theme.palette.primary.main}>{index + 1}</Typography>
                              </Grid>
                              <Grid className={styles.PaymentItem} item xs={6}>
                                <Field
                                  as={TextField}
                                  type="text"
                                  name={`coPriceList[${index}].description`}
                                  placeholder="Enter description"
                                />
                              </Grid>
                              <Grid className={styles.PaymentItem} item xs={3}>
                                <Field
                                  as={TextField}
                                  type="number"
                                  name={`coPriceList[${index}].price`}
                                  placeholder="Enter price"
                                />
                              </Grid>
                              <Grid item xs={1} display="flex" justifyContent="flex-end">
                                <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                                  <img src={deleteIcon} alt="Delete icon" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                      </>
                    )}
                  />
                </Box>
              </Grid>
              <TableListDivider>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography color={theme.palette.text.primary}>Total</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color={theme.palette.text.primary} sx={{ pl: "10px" }}>
                      {values.coPriceList.length > 0 &&
                        formatPrice(
                          values.coPriceList.reduce(
                            (acc: number, it: { description: string; price: number }) => acc + Number(it.price),
                            0
                          )
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              </TableListDivider>
              <FormGroup>
                <Box display="flex">
                  <Field
                    name="agreeyn"
                    id="agreeyn"
                    type="checkbox"
                    as={Checkbox}
                    checkedIcon={<img alt="checkbox" src={checkboxCheckedIcon} style={{ width: "24px", height: "24px" }} />}
                  />
                  <InputLabel htmlFor="agreeyn" sx={{ m: 0, display: "flex" }}>
                    <Typography variant="body1" color={theme.palette.info.main} sx={{ alignSelf: "center" }}>
                      We agreed hereby to make the change(s) specified above at this price
                    </Typography>
                  </InputLabel>
                  <ErrorMessage name="agreeyn">{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                </Box>
              </FormGroup>
              <Field hidden type="text" name="status" />
              <StatusSubmitButtons isDisabled={isDisabled} isLoading={isLoading} setFieldValue={setFieldValue} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangeOrderCreateForm;
