import { useState, FC } from "react";

// ? REDUX
import { addNewPlanAction } from "redux-store/redux-actions/planDrawer";

//  ?  COMPONENTS
import { PlanDrawerForm } from "pages/plan-drawer/PlanDrawerForm";
import { CircularProgress } from "@mui/material";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import { PLAN_DRAWER, PLAN_DRAWER_NAME } from "resource/constants/plan-drawer";
import { ActionName } from "resource/constants/api";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";
import { trimFormStrings } from "resource/utils";

export const nameUpdate = `${PLAN_DRAWER_NAME}/${ActionName.UPDATE}`;


interface PlanDrawerUpdateProps {
  category: string;
}

const PlanDrawerUpdate: FC<PlanDrawerUpdateProps> = ({ category }) => {
  const { close, isOpened, data } = useModal(nameUpdate);
  const [isLoading, setLoader] = useState(false);
  const updatePlan = useAsync(addNewPlanAction);
  const [setNotice] = useNotice();

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);

    data.category = category;
    try {
      await updatePlan({
        values: trimFormStrings(data),
        actionType: ActionName.UPDATE,
      });
      setNotice("Plan updated", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.Form}>
          <PlanDrawerForm
            title={PLAN_DRAWER.EDIT_MODAL_TITLE}
            buttonText={PLAN_DRAWER.EDIT_MODAL_SUBMIT_BUTTON}
            formData={data}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PlanDrawerUpdate;
