import { useState } from "react";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";
import { SettingsButton } from "components/settings-button/SettingsButton";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";
import { nameUpdate } from "components/modals/update/VendorPricingUpdate";
import { nameDelete } from "components/modals/delete/VendorPricingDelete";

// ? STYLES
import styles from "./VendorPricing.module.css";

// ? UTILS
import { getFormattedDate } from "resource/utils";

// ? TYPES
interface CardPropsTypes {
  data: any;
  fake?: boolean;
}

export const VendorsPricingDetailsListCard = ({ data, fake }: CardPropsTypes) => {
  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openDelete } = useModal(nameDelete);

  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.vendorPricing.idmenu);
  const { upc, description, lastprice, skunumber, modidate } = data;
  const theme = useTheme();

  const [localLoading, setLocalLoading] = useState(false);

  if (fake) {
    return (
      <div className="Fake">
        <div className="Cover Disabled">
          <CircularProgress size={25} className="Loader" />
        </div>
        <TableListRow>
          <div></div>
        </TableListRow>
      </div>
    );
  }

  return (
    <div className={styles.VendorCard}>
      <TableListRow>
        {localLoading ? (
          <CircularProgress className={styles.CardLoader} />
        ) : (
          <>
            <Grid item xs={1} md={1}>
              <Typography color={theme.palette.text.primary}>{upc}</Typography>
            </Grid>
            <Grid item xs={5.5}>
              <Typography color={theme.palette.text.primary}>{description}</Typography>
            </Grid>
            <Grid item xs={1.5} md={1.5}>
              <Typography color={theme.palette.text.primary}>{lastprice}</Typography>
            </Grid>
            <Grid item xs={1.5} md={1.5}>
              <Typography color={theme.palette.text.primary}>{skunumber}</Typography>
            </Grid>
            <Grid item xs={1.5} md={1.5}>
              <Typography color={theme.palette.text.primary}>{getFormattedDate(modidate)}</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              {localLoading ? (
                <CircularProgress className={styles.CardLoader} />
              ) : (
                <SettingsButton
                  options={[
                    { text: "Edit", type: "edit", callback: () => openUpdate(data), access: hasEditAccess },
                    {
                      text: "Delete",
                      type: "delete",
                      callback: () => openDelete(data),
                      danger: true,
                      access: hasDeleteAccess,
                    },
                  ]}
                />
              )}
            </Grid>
          </>
        )}
      </TableListRow>
    </div>
  );
};
