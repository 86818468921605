import { Typography, Grid, useTheme } from "@mui/material";
// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";

const TakeoffsDetailInfo = ({ takeoff }) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={2} className={`${styles.DetailsInfoItem} ${styles.PoDetailsInfoItemFisrt}`}>
        <Typography variant="h3" color={theme.palette.text.primary}>
          Sq.Ft:
        </Typography>
        <Typography variant="body1" color={theme.palette.info.main}>
          {takeoff.sqft}
        </Typography>
      </Grid>
      <Grid item xs={2} className={styles.DetailsInfoItem}>
        <Typography variant="h3" color={theme.palette.text.primary}>
          Price Sq.Ft:
        </Typography>
        <Typography variant="body1" color={theme.palette.info.main}>
          {takeoff.takeoffsqft}
        </Typography>
      </Grid>
      <Grid item xs={2} className={`${styles.DetailsInfoItem} ${styles.PoDetailsInfoItemLast}`}>
        <Typography variant="h3" color={theme.palette.text.primary}>
          Takeoff Total:
        </Typography>
        <Typography variant="body1" color={theme.palette.info.main}>
          {takeoff.takeoff}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TakeoffsDetailInfo;
