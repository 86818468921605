import { takeLatest, put } from "redux-saga/effects";
import { post, get, catchError } from "./_utils-abstract-saga-network";
import { normalizeItemsList } from "resource/normalize";

import {
  FileServerActions,
  putFileFoldersAction,
  putFilesAction,
  fetchFilesAction,
  deleteFileFromStoreAction,
} from "redux-store/redux-actions/fileServer";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchFileFolderWorker(action) {
  const { payload: api } = action;
  const { errno, message, data } = yield get(`${api}/file/getFolderList`);

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putFileFoldersAction(data.list));
  }
}

function* fetchFilesWorker(action) {
  const {
    payload: { dataForFetch, folder, api },
  } = action;

  const { errno, message, data } = yield get(`${api}/file/getFileList`, {
    ...dataForFetch,
    folder,
  });

  if (errno !== 0) {
    yield catchError(message);
  } else {
    yield put(putFilesAction({ data: normalizeItemsList<any>(data.list, folder, "id"), folder }));
    yield put(clearNotifierAction());
  }
}

function* uploadFileWorker(action) {
  const {
    payload: { data, dataForFetch, api },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${api}/file/uploadFile`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`File was uploaded successfully`);
    yield put(fetchFilesAction({ dataForFetch, folder: data.folder, api }));
    yield put(clearNotifierAction());
  }
}

function* deleteFileWorker(action) {
  const {
    payload: { values, folder, api },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${api}/file/deleteFile`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`File was deleted successfully`);
    yield put(deleteFileFromStoreAction({ id: values.id, folder }));
    yield put(clearNotifierAction());
  }
}

export function* fileServerSaga() {
  yield takeLatest(FileServerActions.FETCH_FILE_FOLDERS, fetchFileFolderWorker);
  yield takeLatest(FileServerActions.FETCH_FILES, fetchFilesWorker);
  yield takeLatest(FileServerActions.UPLOAD_FILE, uploadFileWorker);
  yield takeLatest(FileServerActions.DELETE_FILE, deleteFileWorker);
}
