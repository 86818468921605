import { RootStateOrAny, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { ROUTES } from "resource/routes/routes";

export function AuthCheckWrapper(props: { id?: number; auth?: boolean; children: JSX.Element }) {
  const userAccess = useSelector((state: RootStateOrAny) => state.login.isAuthorized && state.login.readAccess);

  if ((props.auth || props.id) && !userAccess) return <Redirect to={ROUTES.login.login} />;

  if (props.id && !userAccess.includes(props.id)) return <Redirect to={ROUTES.accessForbidden} />;

  return props.children;
}
