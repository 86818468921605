import { useState, useEffect } from "react";

// ? COMPONENTS
import { Typography, Grid, Checkbox, useTheme } from "@mui/material";

// ? RESOURCES
import { TableListRow } from "components/shared/table-list/TableList";

export const MemberProfileFormCompanyAccessTabItem = ({ cia, localCiaList, setLocalCiaList }) => {
  const theme = useTheme();

  return (
    <TableListRow>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.text.primary}>{cia.idcia}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography color={theme.palette.text.primary}>{cia.name}</Typography>
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Checkbox
          checked={cia.xview}
          onChange={() => {
            setLocalCiaList((companies) => {
              return { ...companies, [cia.idcia]: { ...cia, xview: !cia.xview, xfinancial: !cia.xview && false } };
            });
          }}
        />
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Checkbox
          checked={cia.xfinancial}
          disabled={!cia.xview}
          onChange={() => {
            setLocalCiaList((companies) => {
              return { ...companies, [cia.idcia]: { ...cia, xfinancial: !cia.xfinancial } };
            });
          }}
        />
      </Grid>
    </TableListRow>
  );
};
