import { takeLatest, takeEvery, put } from "redux-saga/effects";
import { DEVELOPMENTS_API, CUSTOM_FIELDS_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  DevelopmentsActions,
  putDevelopmentByIdAction,
  putDevelopmentsAction,
  fetchDevelopmentByIdAction,
  putDevelopmentsBanksAction,
  putDevelopmentsFieldsAction,
  putDevelopmentsFilesAction,
  putDevelopmentsLinkedProjectsAction,
  putDevelopmentsMembersAction,
  deleteDevelopmentFromStoreAction,
  putDevelopmentFileInStoreAction,
  deleteDevelopmentFileFromStoreAction,
  putDevelopmentsMembersInStoreAction,
  deleteDevelopmentsMembersFromStoreAction,
  getDevelopmentDetailAction,
} from "../redux-actions/developments";

import { setPaginationLoaderAction, setButtonLoaderAction, setAddNewLoaderAction } from "../redux-actions/loaders";
import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchDevelopmentsWorker(action) {
  const {
    payload: {
      additionalData: { status, keywords, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/list`, {
    ...params,
    ...(idcia && { idcia }),
    ...(keywords && { keywords }),
    ...(status && { status }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentsBanksWorker(action) {
  const {
    payload: { page },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/bank/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsBanksAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentsFieldsWorker(action) {
  const {
    payload: {
      additionalData: { idcia, idnumber, type },
      page,
    },
  } = action;

  const BASE_API = type === "create" ? `${CUSTOM_FIELDS_API}/development/list` : `${DEVELOPMENTS_API}/field/list`;

  yield put(setPaginationLoaderAction(true));

  const params = type === "create" ? { pageIndex: page, pageSize: 100 } : { pageIndex: page, pageSize: 100, idcia, idnumber };

  const { errno, message, data } = yield get(BASE_API, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsFieldsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentsLinkedProjectsWorker(action) {
  const {
    payload: {
      additionalData: { idcia, idnumber },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idcia, idnumber };

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/linkedProjects/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsLinkedProjectsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentsFilesWorker(action) {
  const {
    payload: {
      additionalData: { idcia, idnumber },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idcia, idnumber };

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/developmentFiles/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsFilesAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentsMembersWorker(action) {
  const {
    payload: {
      additionalData: { idnumber, idcia },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100, idnumber, idcia };

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/members/list`, {
    ...params,
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putDevelopmentsMembersAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchDevelopmentByIdWorker(action) {
  const {
    payload: { idnumber, idcia, mode },
  } = action;

  yield mode === "create" && put(setAddNewLoaderAction(true));

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/${idcia}/${idnumber}`);

  if (errno !== 0) {
    yield catchError(message);
    yield mode === "create" && put(setAddNewLoaderAction(false));
  } else {
    yield put(putDevelopmentByIdAction(data[0]));
    yield mode === "create" && put(setAddNewLoaderAction(false));
  }
}

function* updateDevelopmentWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  if (actionType === "create") {
    const {
      data: { nextId },
    } = yield get(`${DEVELOPMENTS_API}/getNextId`, { idcia: values.idcia });

    values.idnumber = nextId;
  }

  const { errno, message } = yield post(`${DEVELOPMENTS_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Developments was ${actionType}ed successfully`);
    yield put(fetchDevelopmentByIdAction({ idnumber: values.idnumber, idcia: values.idcia, mode: actionType }));
    yield put(clearNotifierAction());
  }
}

function* deleteDevelopmentWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(
    `${DEVELOPMENTS_API}/delete`,
    { idnumber: payload.idnumber, idcia: payload.idcia },
    "urlEncode"
  );

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve(`Development was deleted successfully`);
    yield put(deleteDevelopmentFromStoreAction(payload.idnumber));
    yield put(clearNotifierAction());
  }
}

function* uploadDevelopmentFileWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message, data: responseData } = yield post(`${DEVELOPMENTS_API}/developmentFiles/uploadFile`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Upload development file successfull");
    yield put(
      putDevelopmentFileInStoreAction({
        attachyn: false,
        viewyn: true,
        deleteyn: true,
        fid: responseData.newId,
        idnumber: data.idnumber,
        url: data.path,
        ftype: data.ftype,
        modiby: data.modiby,
        modidate: data.modidate,
        fsizekb: data.fsizekb,
      })
    );
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* deleteDevelopmentFileWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${DEVELOPMENTS_API}/developmentFiles/deleteFile`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    resolve("Deleting development file successfull");
    yield put(deleteDevelopmentFileFromStoreAction(data));
    yield put(clearNotifierAction());
    yield put(setButtonLoaderAction(false));
  }
}

function* uploadDevelopmentMemberWorker(action) {
  const {
    payload: { data },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data: responseData } = yield post(`${DEVELOPMENTS_API}/member/create`, data, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Upload development member successfull");
    yield put(
      putDevelopmentsMembersInStoreAction({
        ac: "Development",
        fullname: data.name,
        idnumber: responseData.newId,
        ndepto: data.ndepto,
        username: data.xusername,
      })
    );
    yield put(clearNotifierAction());
  }
}

function* deleteDevelopmentMemberWorker(action) {
  const {
    payload: { idnumber },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${DEVELOPMENTS_API}/member/delete`, { idnumber }, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete development member successfull");
    yield put(deleteDevelopmentsMembersFromStoreAction({ idnumber }));
    yield put(clearNotifierAction());
  }
}

function* fetchDevelopmentDetailWorker(action) {
  const {
    payload: { idnumber, idcia },
    promise: { resolve, reject },
  } = action;

  const { errno, message, data } = yield get(`${DEVELOPMENTS_API}/${idcia}/${idnumber}`);

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Development detail uploaded");
    yield put(getDevelopmentDetailAction(data[0]));
  }
}

export function* developmentsSaga() {
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS, fetchDevelopmentsWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENT_BY_ID, fetchDevelopmentByIdWorker);
  yield takeLatest(DevelopmentsActions.ADD_NEW_DEVELOPMENT, updateDevelopmentWorker);
  yield takeLatest(DevelopmentsActions.EDIT_DEVELOPMENT, updateDevelopmentWorker);
  yield takeLatest(DevelopmentsActions.DELETE_DEVELOPMENT, deleteDevelopmentWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS_BANKS, fetchDevelopmentsBanksWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS_FIELDS, fetchDevelopmentsFieldsWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS_FILES, fetchDevelopmentsFilesWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS_LINKED_PROJECTS, fetchDevelopmentsLinkedProjectsWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENTS_MEMBERS, fetchDevelopmentsMembersWorker);
  yield takeEvery(DevelopmentsActions.UPLOAD_DEVELOPMENT_FILE, uploadDevelopmentFileWorker);
  yield takeEvery(DevelopmentsActions.DELETE_DEVELOPMENT_FILE, deleteDevelopmentFileWorker);
  yield takeLatest(DevelopmentsActions.UPLOAD_DEVELOPMENT_MEMBER, uploadDevelopmentMemberWorker);
  yield takeLatest(DevelopmentsActions.DELETE_DEVELOPMENT_MEMBER, deleteDevelopmentMemberWorker);
  yield takeLatest(DevelopmentsActions.FETCH_DEVELOPMENT_DETAIL, fetchDevelopmentDetailWorker);
}
