import { useDispatch } from "react-redux";

const useAsync = (actionCreator) => {
  const dispatch = useDispatch();
  return (arg) =>
    new Promise((resolve, reject) => {
      dispatch(actionCreator(arg, { resolve, reject }));
    });
};

export default useAsync;
