import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchDevelopmentsLinkedProjectsAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import { Box, CircularProgress } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import { TableListRow } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";

// ? STYLES
import styles from "./Developments.module.css";

const DevelopmentsDetailLinkedProjectsTab = ({ idnumber, idcia }) => {
  const theme = useTheme();
  const linkedProjects = useSelector((state: RootStateOrAny) => state.developments.developmentsLinkedProjects);
  const fieldsCount = useSelector((state: RootStateOrAny) => state.developments.developmentsLinkedProjectsCount);
  const hasMoreToLoad = fieldsCount > linkedProjects.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia,
    }),
    [idnumber, idcia]
  );

  useInfiniteLoading(fetchDevelopmentsLinkedProjectsAction, hasMoreToLoad, paginationLoading, additionalData);

  return (
    <>
      <Box className={styles.DevelopmentsDetails}>
        <TableListHeader>
          <Grid item xs={2}>
            <Typography color={theme.palette.primary.main}>Project ID</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color={theme.palette.primary.main}>Project Name</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography color={theme.palette.primary.main}>Plan Name</Typography>
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main}>Revision</Typography>
          </Grid>
          <Grid item md={1.5}>
            <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
              Status
            </Typography>
          </Grid>
        </TableListHeader>
        <div className={styles.FormList}>
          {linkedProjects.length > 0 &&
            linkedProjects.map((project, i) => (
              <div key={project.idnumber + i}>
                <TableListRow>
                  <Grid item xs={2}>
                    <Typography color={theme.palette.text.primary}>{project.idnumber}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color={theme.palette.text.primary}>{project.name}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color={theme.palette.text.primary}>{project.planname}</Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary}>{project.revision}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
                      {project.status}
                    </Typography>
                  </Grid>
                </TableListRow>
              </div>
            ))}
          {paginationLoading ? (
            <div className={`LoaderScroll ${!linkedProjects.length && "center"}`}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default DevelopmentsDetailLinkedProjectsTab;
