import { useState, FC } from "react";

// ? REDUX
import { updatePurchaseOrderAction } from "redux-store/redux-actions/purchaseOrder";

// ? COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import PurchaseOrderUpdateForm from "pages/purchase-order/purchase-order-forms/PurchaseOrderUpdateForm";

// ? RESOURCES
import { PURCHASE_ORDER_NAME } from "resource/constants/purchase-order";
import { ActionName } from "resource/constants/api";
import { PurchaseOrderUpdateType } from "resource/interfaces/purchase-order";

interface PurchaseOrderUpdateProps {
  className: string;
}

export const nameUpdate = `${PURCHASE_ORDER_NAME}/${ActionName.UPDATE}`;

const PurchaseOrderUpdate: FC<PurchaseOrderUpdateProps> = ({ className }) => {
  const { close, data } = useModal(nameUpdate);
  const {
    idnumber,
    idvendor,
    refdate,
    shipto,
    invoice,
    delivery,
    received,
    idreason,
    shipping,
    insurance,
    others,
    idtrack,
    iddoc,
  } = data;

  const [isLoading, setLoader] = useState<boolean>(false);

  const formData = {
    idnumber: Number(idnumber),
    idvendor,
    refdate,
    shipto,
    invoice,
    delivery,
    received,
    idreason,
    shipping,
    insurance,
    others,
    idtrack,
  };

  const updatePurchaseOrder = useAsync(updatePurchaseOrderAction);
  const handleSubmit = async (values: PurchaseOrderUpdateType) => {
    setLoader(true);

    try {
      await updatePurchaseOrder({ data: values, actionType: "updatedraft" });
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={className}>
      <PurchaseOrderUpdateForm
        handleSubmit={handleSubmit}
        formData={formData}
        buttonText="Update Purchase Order"
        isLoading={isLoading}
        handleClose={close}
        orderTotal={data.total}
        title={`Update PO-${iddoc}`}
      />
    </ModalManager>
  );
};

export default PurchaseOrderUpdate;
