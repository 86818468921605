// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Box, CircularProgress } from "@mui/material";
import { MasterFloorplansListHeader } from "./MasterFloorplansListHeader";
import { MasterFloorplansListCard } from "./MasterFloorplansListCard";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

// ? STYLES
import styles from "./MasterFloorplans.module.css";

export const MasterFloorplansList = ({ openModal }) => {
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  const floorplans = useSelector((state: RootStateOrAny) => state.masterFloorplans.floorplans);

  if (!paginationLoading && !floorplans.length) {
    return <NoDataScreen pageName="masterFloorplan" />;
  }

  return (
    <Box className={styles.BasePlansList}>
      <MasterFloorplansListHeader />
      {addNewLoader && <MasterFloorplansListCard idnumber={""} name={""} marketing={""} revision={0} status={""} fake />}
      {floorplans.length > 0 &&
        floorplans.map((floorplan, index) => (
          <MasterFloorplansListCard
            key={index}
            idnumber={floorplan.idnumber}
            name={floorplan.name}
            marketing={floorplan.marketing}
            revision={floorplan.revision}
            status={floorplan.status}
          />
        ))}
      {paginationLoading && (
        <div className={`LoaderScroll ${!floorplans.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
