import { FC, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import ProjectTakeoffAssemblyItem from "./ProjectTakeoffAssemblyItem";
import TakeoffsExpandSearch from "pages/takeoffs/TakeoffsExpandSearch";
import { Box, Typography, useTheme, Grid, CircularProgress } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";

// ? RESOURCES
import noCompany from "resource/images/no-company.svg";
import { ProjectTakeoffInterface, AssemblyInterface } from "resource/interfaces/project-takeoff";

interface ProjectTakeoffDetailTabProps {
  takeoff: ProjectTakeoffInterface;
}

const ProjectTakeoffDetailTab: FC<ProjectTakeoffDetailTabProps> = ({ takeoff }) => {
  const theme = useTheme();
  const [isAllCollapsed, setAllCollapsed] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const assemblies: { [idnumber: string]: AssemblyInterface } = useSelector(
    (state: RootStateOrAny) => state.projectTakeoffs.projectTakeoffList
  );

  return (
    <>
      <TakeoffsExpandSearch setSearchQuery={setSearchQuery} setAllCollapsed={setAllCollapsed} isAllCollapsed={isAllCollapsed} />
      <TableListHeader>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Part</Typography>
        </Grid>
        <Grid item xs={0.75}>
          <Typography color={theme.palette.primary.main}>Assembly</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Name</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Unit</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Formula</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Notes* (50max)</Typography>
        </Grid>
        <Grid item xs={0.75}>
          <Typography color={theme.palette.primary.main}>Qty</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main}>Tax</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Price</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Amount</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Updated</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Date</Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.primary.main} justifyContent="flex-end" display="flex">
            Actions
          </Typography>
        </Grid>
      </TableListHeader>
      {takeoff && takeoff.list ? (
        <>
          {takeoff.list.length > 0 ? (
            <>
              {takeoff.list.map((assemblyId) => (
                <div key={assemblyId}>
                  <ProjectTakeoffAssemblyItem
                    assembly={assemblies[assemblyId]}
                    takeoffId={takeoff.idnumber}
                    takeoffIdcia={takeoff.idcia}
                    searchQuery={searchQuery}
                    isAllCollapsed={isAllCollapsed}
                  />
                </div>
              ))}
            </>
          ) : (
            <Box display="flex" justifyContent="center" mt={10}>
              <img src={noCompany} alt="no assemblies data" />
            </Box>
          )}
        </>
      ) : (
        <div className="LoaderScroll center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default ProjectTakeoffDetailTab;
