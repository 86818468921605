import { FC, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { disapproveChangeOrderAction, approveChangeOrderAction } from "redux-store/redux-actions/changeOrders";

// ? COMPONENTS
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import ActionButton from "components/shared/action-button/ActionButton";
import { Grid } from "@mui/material";
import { nameConfirmVoid } from "components/modals/confirm/ChangeOrderVoidConfirm";
import { nameUpdate } from "components/modals/update/ChangeOrderUpdate";

// ? RESOURCES
import { ActionButtons, ChangeOrderEndpoints } from "resource/constants/change-order";
import { StatusList } from "resource/consts";
import { ChangeOrderDetailResponse } from "resource/interfaces/change-order";
import { PromiseType } from "resource/interfaces";

interface ButtonType {
  color: string;
  text: string;
  action: string;
}

interface ChangeOrderDetailActionsProps {
  order: ChangeOrderDetailResponse;
}

const getButtons = (status: string, total: number, limit: number): ButtonType[] => {
  switch (status) {
    case StatusList.ACTIVE:
      return [ActionButtons.void];
    case StatusList.DRAFT:
      return total > limit
        ? [ActionButtons.void, ActionButtons.update]
        : [ActionButtons.void, ActionButtons.approve, ActionButtons.disapprove, ActionButtons.update];
    case StatusList.FOR_APPROVE:
      return total > limit
        ? [ActionButtons.void, ActionButtons.update]
        : [ActionButtons.void, ActionButtons.approve, ActionButtons.disapprove, ActionButtons.update];
    case StatusList.DISAPPROVED:
      return [ActionButtons.void];
    default:
      return [];
  }
};

const ChangeOrderDetailActions: FC<ChangeOrderDetailActionsProps> = ({ order }) => {
  const approvalLimit = useSelector((state: RootStateOrAny) => state.login.userData.billauth);
  const buttonsToRender = getButtons(order.status, order.total, approvalLimit);
  const [loader, setLoader] = useState(false);
  const disapproveChangeOrder = useAsync(disapproveChangeOrderAction);
  const approveChangeOrder = useAsync(approveChangeOrderAction);
  const { open: openConfirmVoid } = useModal(nameConfirmVoid);
  const { open: openUpdate } = useModal(nameUpdate);

  const handleActionChangeOrder = async (
    action: (payload: any, promise?: PromiseType | undefined) => void,
    actionType: string
  ) => {
    setLoader(true);
    try {
      action({
        data: {
          idnumber: order.idnumber,
          idtrack: order.idtrack,
        },
        actionType,
      });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleActionsButton = (actionType: string) => {
    switch (actionType) {
      case ActionButtons.void.action:
        openConfirmVoid(order);
        break;
      case ActionButtons.update.action:
        openUpdate(order);
        break;
      case ActionButtons.disapprove.action:
        handleActionChangeOrder(disapproveChangeOrder, ChangeOrderEndpoints.DISAPPROVE);
        break;
      case ActionButtons.approve.action:
        handleActionChangeOrder(approveChangeOrder, ChangeOrderEndpoints.APPROVE);
        break;
      default:
        console.log(`${actionType}`);
    }
  };

  return (
    <>
      <Grid item display={"flex"} flexDirection={"row"}>
        {buttonsToRender?.map((button) => (
          <div key={button.text}>
            <ActionButton button={button} handleActionsButton={handleActionsButton} isLoading={loader} />
          </div>
        ))}
      </Grid>
    </>
  );
};

export default ChangeOrderDetailActions;
