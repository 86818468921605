import { FC } from "react";
import DatePicker from "react-datepicker";

// ? STYLES
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Calendar.module.css";

interface CalendarProps {
  data: any;
  callback: (data: any) => void;
  name?: string;
  className?: string;
  disabled?: boolean;
}

const CalendarUpd: FC<CalendarProps> = ({ data, callback, name, className, disabled }) => {
  const handleKeyDown = (evt, callback) => {
    if (evt.key === "Enter") {
      const value = new Date(evt.target.value);
      callback(value);
    }
  };

  const handledDate = (update, callback) => {
    callback(update);
  };

  const getDatePickerOptions = (field) => {
    return {
      selected: field,
      placeholderText: "Choose date",
    };
  };

  return (
    <div>
      <DatePicker
        {...getDatePickerOptions(data)}
        onChange={(update) => {
          handledDate(update, callback);
        }}
        onKeyDown={handleKeyDown}
        wrapperClassName={className ? `${styles.DatePickerWide}` : `${styles.DatePicker}`}
        className={`${styles.DatePickerInput}`}
        popperClassName={`${styles.DatePickerPopper}`}
        calendarClassName={`${styles.DatePickerCalendar}`}
        name={name}
        disabled={disabled}
      />
    </div>
  );
};

export default CalendarUpd;
