import { useState, useEffect, FC } from "react";

// ? REDUX
import { assemblyActivationAction } from "redux-store/redux-actions/assemblies";

// ? COMPONENTS
import { Card, Avatar, CardHeader, CardContent, Divider, CircularProgress } from "@mui/material";
import { CardField } from "components/card-field/CardField";
import { SettingsButton } from "components/settings-button/SettingsButton";
import { useNotice } from "custom-hooks/useNotice";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";
import useAsync from "custom-hooks/useAsync";

// ? STYLES
import styles from "./InfoCard.module.css";

// ? RESOURCES
import { noticeTypes } from "resource/consts";
import { ActionName } from "resource/constants/api";
import { nameUpdate } from "components/modals/update/AssembliesUpdate";
import { nameDelete } from "components/modals/delete/AssembliesDelete";

// ? UTILS
import { getAvatar, updateFullName } from "resource/utils";
import { AssemblyRequest } from "resource/interfaces/assemblies";
import { menu } from "resource/constants/menu-list";

// ? TYPES
interface CardPropsTypes {
  data: AssemblyRequest | null;
  fake?: boolean;
}

export const AssemblingCard: FC<CardPropsTypes> = ({ data, fake }) => {
  const [setNotice] = useNotice();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.assemblies.idmenu);
  const { name, idnumber, xtype, formula, stage1, discontinue, idtrack } = data || {};

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openDelete } = useModal(nameDelete);

  const [avatar, setAvatar] = useState("");
  const [avatarColor, setAvatarColor] = useState("");

  const [loading, setLoader] = useState(false);

  const handleSwitch = useAsync(assemblyActivationAction);

  const switchHandleChange = async () => {
    setLoader(true);

    try {
      await handleSwitch({
        values: { idnumber, discontinue: !discontinue, idtrack },
        actionType: ActionName.UPDATE,
      });
      setNotice(`User ${discontinue ? "deactivated" : "activate"}`, noticeTypes.success, 4000);
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (name) {
      getAvatar(name, setAvatar, setAvatarColor);
    }
  }, [name]);

  if (fake) {
    return (
      <Card sx={{ maxWidth: 345 }} className={`${styles.InfoCard} ${styles.AssembliesCard} ${styles.FakeCard}`}>
        <div className={`${styles.Cover} ${styles.Disabled}`}>
          <CircularProgress className={styles.Loader} />
        </div>

        <CardHeader
          className={styles.CardHeader}
          avatar={<Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar"></Avatar>}
          action={<SettingsButton options={[]} />}
          title={""}
        />
        <Divider />
        <CardContent className={styles.InfoCardContent}></CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxWidth: 345, cursor: "auto" }} className={styles.InfoCard}>
      {loading && (
        <div className={`${styles.Cover} ${loading && styles.Disabled}`}>
          <CircularProgress className={styles.Loader} />
        </div>
      )}
      <CardHeader
        className={styles.CardHeader}
        avatar={
          <Avatar sx={{ bgcolor: avatarColor, width: 52, height: 52 }} aria-label="avatar">
            {avatar}
          </Avatar>
        }
        action={
          <SettingsButton
            options={[
              { text: "Edit", type: "edit", callback: () => openUpdate(data), access: hasEditAccess },
              {
                activeFlag: "Activate",
                text: discontinue ? "Activate" : "Discontinue",
                type: "switch",
                callback: () => switchHandleChange(),
                access: hasEditAccess,
              },
              {
                text: "Delete",
                type: "delete",
                callback: () => openDelete(data),
                danger: true,
                access: hasDeleteAccess,
              },
            ]}
          />
        }
        title={name && updateFullName(name)}
      />
      <Divider />
      <CardContent className={styles.InfoCardContent}>
        <CardField size="50%" title="Type" name={xtype ? xtype : ""} />
        <CardField size="50%" title="Formula" name={formula ? formula : ""} />
        <CardField size="50%" title="1st PO" name={`${stage1}%`} />
        <CardField className={styles.CheckboxField} size="50%" title="Do Not Use" name="do not use" type="checkbox" />
      </CardContent>
    </Card>
  );
};
