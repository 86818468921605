import { FC } from "react";

// ? COMPONENTS
import iconClip from "resource/images/icon-clip.svg";

interface FileServerClipIconProps {
  fs: boolean;
}

const FileServerClipIcon: FC<FileServerClipIconProps> = ({ fs }) => {
  return <>{fs && <img src={iconClip} alt="Attachment icon" style={{ height: "17px", marginRight: "-7px" }} />}</>;
};

export default FileServerClipIcon;
