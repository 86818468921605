import { Colors } from "./consts";

// capitalize first char only
export const capitalizeChar = (str: string): string => {
  if (typeof str !== `string`) {
    return ``;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const updateCollection = <T>(itemsCollection: T[], newItem: T, id: string | number): T[] => {
  const index = itemsCollection.findIndex((item) => item[id] === newItem[id]);
  if (index === -1) {
    return [newItem].concat(itemsCollection);
  }

  return itemsCollection.map((item) => {
    return item[id] === newItem[id] ? newItem : item;
  });
};

export const updateNormalizedCollection = (collection, idnumber) => {
  const newCollection = !collection.includes(idnumber) ? Array.from(new Set([idnumber, ...collection])) : collection;
  return newCollection;
};

// prepare form data
export const trimFormStrings = (data) => {
  for (const i in data) {
    if (typeof data[i] === "string") {
      data[i] = data[i].replace(/\s+/g, " ").trim();
    }
  }

  return data;
};

// avatar for cards
export const getAvatar = (name: string, setAvatar, setAvatarColor): void => {
  const backgrounds = [Colors.LIGHT_BLUE, Colors.GREEN, Colors.RED];

  name
    ? setAvatar(
        name
          .replace(/\s+/g, " ")
          .trim()
          .split(" ")
          .slice(0, 2)
          .map((word) => word[0].toUpperCase())
          .join("")
      )
    : setAvatar("");
  setAvatarColor(backgrounds[Math.floor(Math.random() * 3)]);
};

// convert full name
export const updateFullName = (fullname: string): string => {
  return fullname.split(", ").join(" ");
};

export const getFirstName = (fullname: string): string => {
  return fullname.split(", ")[0];
};

export const getLastName = (fullname: string): string => {
  return fullname.split(", ")[1];
};

// convert to base64

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

// remove base64 prefix
export const removeBase64Prefix = (image: string): string => {
  const REGEXP_BASE_64 = /^data:.+;base64,/;
  return REGEXP_BASE_64.test(image) ? image.replace(REGEXP_BASE_64, "") : image;
};

// get uniq data array
export const getUniqueData = <T>(newData: T[], oldData: T[], param: string | number): T[] => {
  return newData.filter((newItem) => oldData.findIndex((oldItem) => oldItem[param] == newItem[param]) === -1);
};

//format date mm/ss/YY
export const getFormattedDate = (date: string) => {
  if (date.length > 0) {
    const localDate = new Date(date);

    const month = localDate.getMonth() + 1;
    const day = localDate.getDate().toString().padStart(2, "0");
    const year = localDate.getFullYear();

    return `${month.toString().padStart(2, "0")}/${day}/${year}`;
  } else {
    return "";
  }
};

// format time hh:mm
export const getFormattedTime = (date: string) => {
  if (date.length > 0) {
    const localDate = new Date(date);
    return localDate.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
  } else {
    return "";
  }
};

// format date mm/dd/YY hh:mm
export const getFormattedDateTime = (date: string): string => {
  return `${getFormattedDate(date)} ${getFormattedTime(date)}`;
};

export const formattedDateTimeNow = () => {
  const now = new Date(Date.now());
  const month = now.getMonth() + 1;
  const day = now.getDate().toString().padStart(2, "0");
  const year = now.getFullYear();
  const time = now.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

  return `${month.toString().padStart(2, "0")}/${day}/${year} ${time}`;
};

// format date yyyy-MM-dd
export const formatDate = (date: string): string => {
  const newDate = JSON.stringify(date);
  return newDate.slice(1, 11);
};

// get file size
export const getFileSize = (size) => {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size > 1024 && size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  if (size > 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  }
};

export const getClosingDate = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

// fetch all
export const fetchAll = (urls: string[], headers) => {
  return Promise.all(
    urls.map((url) =>
      fetch(url, {
        headers,
      })
        .then((r) => r.json())
        .then((data) => data)
        .catch((error) => ({ error, url }))
    )
  );
};

// Format price in '$0,000.00'
export const formatPrice = (nStr: string): string => {
  if (nStr.length) {
    nStr += "";
    const x = nStr.split(".");
    let x1 = x[0];
    const x2 = x.length > 1 ? "." + x[1] : "";
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }

    let result = `$${x1 + x2}`;
    const decimalPart = result.slice(result.indexOf("."), result.length);
    if (decimalPart[0] === "." && decimalPart.length === 2) {
      result = `${result}0`;
    }

    return result;
  } else {
    return "";
  }
};

// Parse formatted price
export const parseFormattedPrice = (price: string): number => {
  return parseFloat(price.slice(1, price.length - 1).replace(/,/g, ""));
};

export const preprocessData = (contentType: string, data: any) => {
  return contentType === "json" ? JSON.stringify({ ...data }) : new URLSearchParams(data).toString();
};

export const resolvePath = (path: string, subDirectories: any[] = [], params = {}) => {
  const paramsSerial = new URLSearchParams(params).toString();
  const subFolders = subDirectories.length > 0 ? subDirectories.reduce((acc, item) => acc + `/${item}`, "") : "";

  return `${path}${subFolders}?${paramsSerial}`;
};

export const isBiggerThanZero = (property: number) => (property > 0 ? property : "");

export const getStartDateForCalendar = (date: string) => {
  if (date) {
    const [year, month, day] = date.split("-");
    return new Date(Number(year), Number(month) - 1, Number(day));
  } else {
    return new Date();
  }
};

export const convertLocalDateToISOString = (date) => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};
