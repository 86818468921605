import { FC, useState } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Button, IconButton, Grid, Typography, Menu, MenuItem, useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileServerIcon from "components/shared/file-server/FileServerIcon";
import useAccess from "custom-hooks/useAccess";
import { nameFileUpload } from "components/modals/file-server-modal/ProjectsFileServer";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";
import { menu } from "resource/constants/menu-list";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

// ? STYLES
import styles from "./Projects.module.css";
import pageHeaderStyles from "components/page-header/PageHeader.module.css";

interface ProjectDetailHeaderProps {
  project: any;
  submit: () => void;
  handleProjectDelete: () => void;
  updateLoader: boolean;
}

const ProjectDetailHeader: FC<ProjectDetailHeaderProps> = ({ project, submit, handleProjectDelete, updateLoader }) => {
  const theme = useTheme();
  const { hasEditAccess, hasDeleteAccess } = useAccess(menu.projectPage.idmenu);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.projectPage.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${project.idnumber} - ${project.name}`}
            </Typography>
            <FileServerIcon data={project} nameFileUpload={nameFileUpload} />
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your project details will be shown below
          </Typography>
        </Grid>
        <Grid item>
          {hasEditAccess && (
            <LoadingButton
              variant="outlined"
              className={`${!updateLoader ? styles.ButtonUpdate : styles.LoadingButton}`}
              loadingPosition="start"
              onClick={() => {
                submit();
              }}
              loading={updateLoader}
            >
              Update
            </LoadingButton>
          )}
          {hasDeleteAccess && (
            <Button variant="outlined" className={styles.ButtonDelete} onClick={handleProjectDelete}>
              Delete
            </Button>
          )}
          <IconButton
            className={styles.DropDownButton}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <InsertLinkIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link to={resolvePath(ROUTES.jobCostReport.pathname, [project.idcia, project.idnumber])}>Job Coste Report</Link>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectDetailHeader;
