import { useCallback } from "react";
// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { openModalAction, closeModalAction } from "redux-store/redux-actions/modals";

const useModal = (modalName: string) => {
  const dispatch = useDispatch();
  const { name, data }: { name: string; data: any } = useSelector((state: RootStateOrAny) => state.modals);
  const isOpened = name === modalName;

  const open = useCallback((data?) => {
    dispatch(openModalAction(modalName, data));
  }, [dispatch, modalName]);

  const close = useCallback(() => {
    dispatch(closeModalAction(modalName));
  }, [dispatch, modalName]);

  return { open, close, data, isOpened };
};

export default useModal;
