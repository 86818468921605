import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Button, Grid, Typography, useTheme, Menu, MenuItem } from "@mui/material";
import StatusMark from "components/shared/status-mark/StatusMark";
import { nameAddAssembly } from "components/modals/create/PlanTakeoffAddAssembly";
import { nameAddBudget } from "components/modals/create/PlanTakeoffAddBudget";
import { nameDeletePlanTakeoff } from "components/modals/delete/PlanTakeoffDelete";
import { nameAddPlanImport } from "components/modals/import/PlanTakeoffPlanImport";
import { nameAddProjectImport } from "components/modals/import/PlanTakeoffProjectImport";
import useModal from "custom-hooks/useModal";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { ROUTES } from "resource/routes/routes";
import { resolvePath } from "resource/utils";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import pageHeaderStyles from "components/page-header/PageHeader.module.css";
import styles from "pages/takeoffs/Takeoffs.module.css";
import arrow from "resource/images/arrow-down.svg";
import addIcon from "resource/images/plus.svg";
import importIcon from "resource/images/import.svg";
import deleteIcon from "resource/images/delete.svg";

interface PlanTakeoffDetailHeaderProps {
  id: number;
  name: string;
  status: string;
}

const PlanTakeoffDetailHeader: FC<PlanTakeoffDetailHeaderProps> = ({ id, name, status }) => {
  const theme = useTheme();
  const roleRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  const { hasEditAccess, hasDeleteAccess, hasAddAccess } = useAccess(menu.planTakeoff.idmenu);
  const hasAccess = hasEditAccess || hasDeleteAccess || hasAddAccess;
  const { open: openAddAssembly } = useModal(nameAddAssembly);
  const { open: openAddBudget } = useModal(nameAddBudget);
  const { open: openAddPlanImport } = useModal(nameAddPlanImport);
  const { open: openAddProjectImport } = useModal(nameAddProjectImport);
  const { open: openPlanTakeoffDelete } = useModal(nameDeletePlanTakeoff);

  return (
    <>
      <Grid className={pageHeaderStyles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box className={pageHeaderStyles.PageHeaderText}>
            <Link to={resolvePath(ROUTES.planTakeoff.pathname)}>
              <Box className={pageHeaderStyles.PageHeaderIcon}>
                <img src="/images/arrow-left.svg" alt="" role="presentation" />
              </Box>
            </Link>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`${id} - ${name}`}
            </Typography>
            <StatusMark status={status} />
          </Box>
          <Typography variant="body1" color={theme.palette.info.main}>
            Your plan takeoff details will be shown below
          </Typography>
        </Grid>
        <Grid item>
          {hasAccess && (
            <Button
              variant={"outlined"}
              className={`${styles.ActionButton}`}
              onClick={toggleMenuOpen}
              ref={roleRef}
              type="button"
            >
              <Typography sx={{ mr: "5px" }}>Actions</Typography>
              <img
                src={arrow}
                alt=""
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(4%) hue-rotate(30deg) brightness(116%) contrast(100%)",
                }}
              />
            </Button>
          )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={roleRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        classes={{ root: styles.AccountMenuDropdown }}
        PaperProps={{ elevation: 0 }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        sx={{ "& .MuiPopover-paper": { boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)" } }}
      >
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddAssembly(id)}>
            <img src={addIcon} alt="" />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Add Assembly
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddBudget(id)}>
            <img src={addIcon} alt="" />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Add Budget
            </Typography>
          </MenuItem>
        )}

        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddPlanImport({ id, name })}>
            <img src={importIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Import from Plan
            </Typography>
          </MenuItem>
        )}
        {hasEditAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openAddProjectImport({ id, name })}>
            <img src={importIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Import from Project
            </Typography>
          </MenuItem>
        )}

        {hasDeleteAccess && (
          <MenuItem className={styles.AccountMenuItem} onClick={() => openPlanTakeoffDelete({ id, name })}>
            <img src={deleteIcon} alt="" style={{ width: "10px" }} />
            <Typography sx={{ ml: 1 }} color={theme.palette.primary.main}>
              Delete Plan Takeoff
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default PlanTakeoffDetailHeader;
