import { FC, useEffect, useState } from "react";

// ? COMPONENTS
import { Grid, Box, List, ListItem, IconButton, CircularProgress, Typography, useTheme } from "@mui/material";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { deleteFileAction, fetchFilesAction } from "redux-store/redux-actions/fileServer";

// ? RESOURCES
import { URL } from "resource/constants/api";
import notesIcon from "resource/images/notes.svg";
import deleteIcon from "resource/images/delete.svg";
import useAsync from "custom-hooks/useAsync";
import SmallLoader from "../small-loader/SmallLoader";

interface FileServerItemProps {
  dataForDelete: any;
  dataForFetch: any;
  folderName: string;
  isFileServerExist: boolean;
  api: string;
}

const FileServerItem: FC<FileServerItemProps> = ({ folderName, isFileServerExist, dataForDelete, dataForFetch, api }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const files = useSelector((state: RootStateOrAny) => state.fileServer.files);
  const filesOrder: string[] = useSelector((state: RootStateOrAny) => state.fileServer.filesOrder);
  const deleteFile = useAsync(deleteFileAction);
  const [fileToDelete, setFileToDelete] = useState<string>("");

  useEffect(() => {
    if (folderName && isFileServerExist) {
      dispatch(fetchFilesAction({ dataForFetch, folder: folderName, api }));
    }
  }, [folderName, isFileServerExist, api, dispatch]);

  const handleFileDelete = async ({ id, folderName }) => {
    const values = { ...dataForDelete, id: Number(id) };
    setFileToDelete(id);

    try {
      await deleteFile({ values, folder: folderName, api });
      setFileToDelete("");
    } catch (e) {
      console.error(e);
      setFileToDelete("");
    }
  };

  return (
    <>
      {filesOrder[folderName] && filesOrder[folderName].length > 0 && (
        <List sx={{ mt: "5px" }}>
          {filesOrder[folderName].map((id: string) => (
            <ListItem key={id} disablePadding sx={{ py: "2px" }}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <a
                    key={files[folderName][id].fname}
                    rel="noreferrer"
                    target="_blank"
                    href={`${URL}${files[folderName][id].url}`}
                  >
                    <Box display="flex">
                      <img src={notesIcon} alt="Notes icon" style={{ width: "14px", marginRight: "10px" }} />
                      <Typography variant="h3" color={theme.palette.info.main}>
                        {files[folderName][id].fname}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
                <Grid item xs={1} justifyContent="flex-end" display="flex">
                  {fileToDelete === id ? (
                    <SmallLoader />
                  ) : (
                    <IconButton sx={{ width: "30px", ml: "10px" }} onClick={() => handleFileDelete({ id, folderName })}>
                      <img src={deleteIcon} alt="Delete icon" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      )}
      {isFileServerExist && filesOrder && !filesOrder[folderName] && <CircularProgress />}
    </>
  );
};

export default FileServerItem;
