export const VENDOR_PRICING_NAME = "Vendor Pricing";

export const VENDOR_PRICING = {
  TITLE: "Vendor Pricing",
  NEW_MODAL_TITLE: "Add New Part",
  EDIT_MODAL_TITLE: "Edit Vendor Details",
  NEW_MODAL_PLUS_BUTTON: "Add Part",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Part",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Vendor Details",
};
