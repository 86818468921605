import { useMemo, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchMasterRolesAction, clearDataAction } from "redux-store/redux-actions/masterRoles";

// ? COMPONENTS
import MasterRolesList from "./MasterRolesList";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import MasterRolesCreate from "components/modals/create/MasterRolesCreate";
import MasterRolesUpdate from "components/modals/update/MasterRolesUpdate";
import MasterRolesDelete from "components/modals/delete/MasterRolesDelete";
import useModal from "custom-hooks/useModal";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import { Search } from "components/search/Search";
import { CircularProgress } from "@mui/material";
import { withLayout } from "hoc/withLayout";
import { nameUpdate } from "components/modals/update/MasterRolesUpdate";
import { nameCreate } from "components/modals/create/MasterRolesCreate";
import { nameDelete } from "components/modals/delete/MasterRolesDelete";

// ? RESOURCES
import { MASTER_ROLES } from "resource/constants/master-roles";
import { menu } from "resource/constants/menu-list";

// ? INTERFACES
import { roleOnGet } from "resource/interfaces/master-roles";

// ? STYLES
import styles from "./MasterRoles.module.css";

const MasterRoles = () => {
  const masterRoles = useSelector((state: RootStateOrAny) => state.masterRoles.masterRoles);
  const masterRolesCount = useSelector((state: RootStateOrAny) => state.masterRoles.masterRolesCount);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const { hasAddAccess } = useAccess(menu.masterRole.idmenu);
  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const hasMoreToLoad = masterRolesCount > masterRoles.length;

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
    }),
    [searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchMasterRolesAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  const { open: openUpdate } = useModal(nameUpdate);
  const { open: openCreate } = useModal(nameCreate);
  const { open: openDelete } = useModal(nameDelete);

  const handleRoleUpdate = (role: roleOnGet) => {
    openUpdate(role);
  };

  const handleRoleDelete = (role: roleOnGet) => {
    openDelete(role);
  };

  return (
    <>
      <PageHeader pageName="masterRoles">
        <Search value={searchValue} placeholder="Search master roles" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={MASTER_ROLES.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      <MasterRolesList
        roles={masterRoles}
        loading={paginationLoading}
        handleRoleUpdate={handleRoleUpdate}
        handleRoleDelete={handleRoleDelete}
      />

      <MasterRolesUpdate className={styles.MasterRolesModal} />
      <MasterRolesCreate className={styles.MasterRolesModal} />
      <MasterRolesDelete />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!masterRoles.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(MasterRoles);
