// ? REDUX
import { useDispatch } from "react-redux";
import { setNoticeAction, clearNotifierAction } from "redux-store/redux-actions/notifier";

export const useNotice = () => {
  const dispatch = useDispatch();

  const setNotice = (message: string, type: string, timeUntilClosing: number) => {
    dispatch(setNoticeAction({ message, type }));

    if (timeUntilClosing) {
      setTimeout(() => {
        dispatch(clearNotifierAction());
      }, timeUntilClosing);
    }
  };

  return [setNotice] as [(message: string, type: string, timeUntilClosing: number) => void];
};
