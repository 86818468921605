import { FC } from "react";

// ? COMPONENTS
import { Box } from "@mui/material";
import FileServerItem from "./FileServerItem";

// ? RESOURCES
import { TabsUnstyled } from "@mui/base";
import { Tab, TabPanel, TabsList } from "resource/styled";

// ? STYLES
import styles from "./FileServer.module.css";

interface FileServerListProps {
  folders: string[];
  isFileServerExist: boolean;
  api: string;
  dataForDelete: any;
  dataForFetch: any;
}

const FileServerList: FC<FileServerListProps> = ({ folders, isFileServerExist, api, dataForDelete, dataForFetch }) => {
  return (
    <>
      <div className={styles.TabWidth}>
        <TabsUnstyled defaultValue={0}>
          <Box width="100%">
            <TabsList>
              {folders.map((folder, i) => (
                <Tab key={folder + i} type={"button"} sx={{ mr: "5px" }}>
                  {folder}
                </Tab>
              ))}
            </TabsList>
            <Box className={styles.Tab}>
              {folders.map((folder, i) => (
                <TabPanel key={folder} value={i}>
                  <FileServerItem
                    folderName={folder}
                    isFileServerExist={isFileServerExist}
                    api={api}
                    dataForDelete={dataForDelete}
                    dataForFetch={dataForFetch}
                  />
                </TabPanel>
              ))}
            </Box>
          </Box>
        </TabsUnstyled>
      </div>
    </>
  );
};

export default FileServerList;
