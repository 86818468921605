import React, { useState, useEffect } from "react";

// ? REDUX
import { uploadProjectMemberAction } from "redux-store/redux-actions/projects";

// ? COMPONENTS
import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import { useSearch } from "custom-hooks/useSearch";
import useAsync from "custom-hooks/useAsync";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import { USERS_PROFILE_API } from "resource/constants/api";

export const ProjectsAddMemeberForm = ({ idnumber, idcia }) => {
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const uploadMemberRequest = useAsync(uploadProjectMemberAction);

  const [membersOptions, setMembersQuery, isMembersPending, membersInitialCondition, membersErrorMessage] =
    useSearch(USERS_PROFILE_API);

  const uploadMember = async (user) => {
    setUploadLoading(true);

    try {
      await uploadMemberRequest({
        data: { idnumber, idcia, xusername: user.username, name: user.name, ndepto: user.ndepto },
      });
      setUploadLoading(false);
      setUserName("");
    } catch (e) {
      console.error(e);
      setUploadLoading(false);
    }
  };

  useEffect(() => {
    setQueryLoading(isMembersPending);
  }, [isMembersPending]);

  return (
    <div className={styles.AddMemberForm}>
      {!uploadLoading && (
        <Autocomplete
          className={styles.SearchField}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          options={membersOptions}
          getOptionLabel={(option: any) => `${option.name} ( ${option.email} )`}
          loading={queryLoading}
          noOptionsText={!membersInitialCondition ? "Start typing" : "No options"}
          onChange={(e, value: any) => {
            uploadMember(value ? value : "");
          }}
          renderInput={(params) => (
            <TextField
              className={styles.SearchFieldInput}
              placeholder="Enter member name"
              onChange={(e) => {
                setMembersQuery(e.target.value);
              }}
              name="membersForm"
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {queryLoading ? (
                      <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      {uploadLoading && <CircularProgress className={styles.CardLoader} size={40} style={{ margin: "20px 0" }} />}
      {/* <ErrorMessage name={name}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage> */}
    </div>
  );
};
