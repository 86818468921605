import { useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form, ErrorMessage } from "formik";
import { IconButton, Typography, Grid, CircularProgress, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { TabsUnstyled } from "@mui/base";
import { Tab, TabsList, TabPanel } from "resource/styled";
import DevelopmentsDetailFieldTab from "./DevelopmentsDetailFieldTab";
import { SearchField } from "components/form-field/SearchField";

// ? STYLES
import styles from "./Developments.module.css";

// ? RESOURCES
import { COMPANIES_API, DEVELOPMENTS_API } from "resource/constants/api";
import { useSearch } from "custom-hooks/useSearch";
import closeButtonIcon from "resource/images/close-btn.svg";
import { developmentsSchema } from "resource/schemas/developmentsScema";

const statuses = [
  { label: "Active", value: "Active" },
  { label: "Closed", value: "Closed" },
];

import { ValidationError } from "yup";

export const DevelopmentsForm = ({ title, formData, buttonText, handleClose, actionType, handleSubmit, isLoading }) => {
  const theme = useTheme();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const [idcia, setIdcia] = useState<any>(null);
  const [ciaOptions, setCiaQuery, isCiaPending, ciaInitialCondition, ciaErrorMessage] = useSearch(COMPANIES_API);
  const [banksOptions, setBanksQuery, isBanksPending, initialBanksCondition, banksErrorMessage] = useSearch(
    `${DEVELOPMENTS_API}/bank`,
    idcia && { idcia: idcia.idnumber }
  );

  const initialFormValues = {
    idcia: null,
    name: null,
    status: statuses[0].value,
    taddress: null,
    tcity: null,
    tstate: null,
    tzip: null,
    bankledger: "",
  };

  const [localFieldList, setLocalFieldList] = useState([]);

  const formikValues = initialFormValues;

  const preparedData = (values) => {
    const valuesCopy = values;
    if (localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any, index) => {
          return {
            idnumber: field.idnumber,
            xid: 0,
            name: field.name,
            xtype: field.xtype,
            txtvalue: field.txtvalue || "",
          };
        });
    }

    return valuesCopy;
  };

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik
          initialValues={formikValues}
          onSubmit={(values: any) => handleSubmit(preparedData(values))}
          validationSchema={developmentsSchema}
        >
          {({ setFieldValue, handleSubmit }) => (
            <div className={styles.DevelopmentsForm}>
              <Form className={styles.UpdateForm}>
                <div className={styles.FormHeader}>
                  <Typography variant="h1" color={theme.palette.text.primary}>
                    {title}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <img src={closeButtonIcon} alt="close-button" />
                  </IconButton>
                </div>

                <Grid className={styles.SubForm} container justifyContent="space-between">
                  <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs="auto" sm={6} lg={3}>
                      <SearchField
                        size="100%"
                        title="Company"
                        responseName="idnumber"
                        name="idcia"
                        label="name"
                        placeholder="Search company"
                        setFieldValue={setFieldValue}
                        initialFormValues={initialFormValues}
                        options={ciaOptions}
                        onQueryChange={setCiaQuery}
                        isPending={isCiaPending}
                        initialCondition={ciaInitialCondition}
                        callback={setIdcia}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Development Name"
                        name="name"
                        placeholder="Enter development name"
                        type="text"
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Status"
                        name="status"
                        placeholder="Select status"
                        type="select"
                        options={statuses}
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Address"
                        name="taddress"
                        placeholder="Enter address"
                        type="text"
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="City"
                        name="tcity"
                        placeholder="Enter city name"
                        type="text"
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="State"
                        name="tstate"
                        placeholder="Enter state name"
                        type="text"
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Zip Code"
                        name="tzip"
                        placeholder="Enter zip code"
                        type="number"
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <SearchField
                        size="100%"
                        title="Prefered Bank Account"
                        responseName="bankledger"
                        name="bankledger"
                        label="name"
                        placeholder="Search bank account"
                        setFieldValue={setFieldValue}
                        initialFormValues={initialFormValues}
                        options={banksOptions}
                        onQueryChange={setBanksQuery}
                        isPending={isBanksPending}
                        initialCondition={initialBanksCondition}
                        disabled={idcia === null ? true : false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>

              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab>General</Tab>
                </TabsList>
                <TabPanel value={0}>
                  <DevelopmentsDetailFieldTab
                    type={actionType}
                    idnumber={1}
                    idcia={1}
                    callback={setLocalFieldList}
                    hasAccess={true}
                  />
                </TabPanel>
              </TabsUnstyled>

              <div className={styles.FormFooter}>
                <SubmitButton
                  text={buttonText}
                  large
                  isLoading={isLoading}
                  callback={(e) => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
