export const MASTER_ROLES_NAME = "masterRoles";

export const roleActionMap = new Map();
roleActionMap.set("view", "xview");
roleActionMap.set("create", "xadd");
roleActionMap.set("edit", "xedit");
roleActionMap.set("delete", "xdel");
roleActionMap.set("print", "xprint");
roleActionMap.set("export", "xexcel");

export const MASTER_ROLES = {
  TITLE: "Master Roles",
  NEW_MODAL_TITLE: "Add New Role",
  EDIT_MODAL_TITLE: "Edit Role",
  NEW_MODAL_PLUS_BUTTON: "Add New Role",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Role",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Role",
};
