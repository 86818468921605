import { useEffect, useMemo } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchDevelopmentsMembersAction } from "redux-store/redux-actions/developments";

// ? COMPONENTS
import { Box, CircularProgress } from "@mui/material";
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import { DevelopmentAddMemeberForm } from "./DevelopmentAddMemeberForm";
import { DevelopmetsDetailsMemersTabItem } from "./DevelopmentsDetailMembersTabItem";
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./Developments.module.css";

const DevelopmentsDetailMembersTab = ({ idnumber, idcia }) => {
  const theme = useTheme();

  const { hasEditAccess } = useAccess(menu.developments.idmenu);
  const members = useSelector((state: RootStateOrAny) => state.developments.developmentsMembers);
  const membersCount = useSelector((state: RootStateOrAny) => state.developments.developmentsMembersCount);
  const hasMoreToLoad = membersCount > members.length;
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);

  const additionalData = useMemo(
    () => ({
      idnumber,
      idcia: idcia,
    }),
    [idnumber, idcia]
  );

  useInfiniteLoading(fetchDevelopmentsMembersAction, hasMoreToLoad, paginationLoading, additionalData);

  useEffect(() => {}, [members]);

  return (
    <>
      <Box className={styles.DevelopmentsDetails}>
        {hasEditAccess && <DevelopmentAddMemeberForm idnumber={idnumber} idcia={idcia} />}
        <TableListHeader>
          <Grid item xs={1}>
            <Typography color={theme.palette.primary.main}>ID</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography color={theme.palette.primary.main}>Full Name</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography color={theme.palette.primary.main}>User Name</Typography>
          </Grid>
          <Grid item md={3}>
            <Typography color={theme.palette.primary.main}>Role</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography color={theme.palette.primary.main}>Access</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography color={theme.palette.primary.main}>Delete</Typography>
          </Grid>
        </TableListHeader>
        <div className={styles.FormList}>
          {members.length > 0 &&
            members.map((member, i) => (
              <div key={i}>
                <DevelopmetsDetailsMemersTabItem member={member} />
              </div>
            ))}
          {paginationLoading ? (
            <div className={`LoaderScroll ${!members.length && "center"}`}>
              <CircularProgress />
            </div>
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default DevelopmentsDetailMembersTab;
