import AppBar from "components/app-bar/AppBar";
import { Alert, Box, Container, useTheme } from "@mui/material";
import { useSelector, RootStateOrAny } from "react-redux";
import AccountMenu from "components/app-bar/account-menu/AccountMenu";
import Navigation from "components/app-bar/navigation/Navigation";

export const withLayout = (Component) => {
  return (props) => {
    const theme = useTheme();
    const serverMessage = useSelector((state: RootStateOrAny) => state.notifier.notification);

    const { tokenAPI, tokenSecret } = useSelector((state: RootStateOrAny) => state.login.userData);

    return (
      <>
        {serverMessage && <Alert severity={serverMessage.type}>{`${serverMessage.message}`}</Alert>}
        <AppBar menu={<AccountMenu />} navigation={<Navigation />} />
        <Container maxWidth="xl" color={theme.palette.secondary.light}>
          <Box p={{ xs: "25px 0", lg: "25px 48px" }}>
            <Component tokenAPI={tokenAPI} tokenSecret={tokenSecret} {...props} />
          </Box>
        </Container>
      </>
    );
  };
};
