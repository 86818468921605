import React, { useState, useEffect } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form, Field } from "formik";
import {
  IconButton,
  Typography,
  Grid,
  CircularProgress,
  Box,
  TextField,
  Checkbox,
  FormGroup,
  InputLabel,
  Autocomplete,
  useTheme,
} from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import { TabsUnstyled } from "@mui/base";
import { Tab, TabPanel, TabsList } from "resource/styled";
import CalendarUpd from "components/shared/calendar/CalendarUpd";
import { ErrorMessage } from "formik";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? STYLES
import styles from "./Vendors.module.css";
import fieldStyles from "components/form-field/FormField.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { useSearch } from "custom-hooks/useSearch";
import { COST_CODES_API, MASTER_ACCOUNT_API } from "resource/constants/api";
import { vendorsSchema } from "resource/schemas/vendorsSchema";

// ? UTILS
import { getStartDateForCalendar } from "resource/utils";

export const VendorsForm = ({ title, buttonText, handleClose, handleSubmit, formData, isLoading }) => {
  const theme = useTheme();

  // STORE
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const types = useSelector((state: RootStateOrAny) => state.vendors.options.vtype.list);
  const status = useSelector((state: RootStateOrAny) => state.vendors.options.inactiveyn.list);
  const categories = useSelector((state: RootStateOrAny) => state.vendors.options.category.list);
  const payterms = useSelector((state: RootStateOrAny) => state.vendors.options.payterms.list);

  // MASTER ACCOUNT
  const [openMA, setMAOpen] = useState(false);
  const [optionsMA, setMAQuery, isMAPending, initialMACondition, errorMAMessage] = useSearch(MASTER_ACCOUNT_API);
  // = = =

  // COST CODES
  const [openCC, setCCOpen] = useState(false);
  const [optionsCC, setCCQuery, isCCPending, initialCCCondition, errorCCMessage] = useSearch(COST_CODES_API);
  // = = =

  // DATE PICKERS
  const [workDate, setWorkDate] = useState(new Date());
  const [insuranceDate, setInsuranceDate] = useState(new Date());

  useEffect(() => {
    if (formData) {
      setWorkDate(getStartDateForCalendar(formData.workdate));
      setInsuranceDate(getStartDateForCalendar(formData.insdate));
    }
  }, [formData]);

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formData}
          onSubmit={(values: any) => handleSubmit(values, workDate, insuranceDate)}
          validationSchema={vendorsSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className={styles.FormHeader}>
                <Typography variant="h1" color={theme.palette.text.primary}>
                  {title}
                </Typography>
                <IconButton onClick={handleClose}>
                  <img src={closeButtonIcon} alt="close-button" />
                </IconButton>
              </div>
              <Grid container justifyContent="space-between">
                <Box className={styles.Group}>
                  <Typography className={styles.GroupTitle}>General Information</Typography>
                  <FormField size="100%" title="Vendor Name" name="name" placeholder="Enter vendor name" type="text" />
                  <FormField size="100%" title="Vendor Address" name="taddress" placeholder="Enter vendor address" type="text" />
                  <FormField size="100%" title="Email" name="email" placeholder="Enter vendor email" type="email" />
                  <FormField
                    size="47%"
                    title="Type"
                    name="vtype"
                    placeholder="Enter type of vendor"
                    type="select"
                    options={types}
                  />
                  <FormField
                    size="47%"
                    title="Status"
                    name="inactiveyn"
                    placeholder="Enter status"
                    type="select"
                    options={status}
                  />
                  <FormField
                    size="47%"
                    title="Category"
                    name="category"
                    placeholder="Choose category of vendor"
                    type="select"
                    options={categories}
                  />
                  <FormField
                    size="47%"
                    title="Payment Term"
                    name="payterms"
                    placeholder="Choose payment term"
                    type="select"
                    options={payterms}
                  />
                </Box>
                <TabsUnstyled className={styles.Tabs} defaultValue={0}>
                  <TabsList>
                    <Tab type={"button"}>Account Information</Tab>
                    <Tab type={"button"} sx={{ mr: "5px" }}>
                      Contact Information
                    </Tab>
                    <Tab type={"button"} sx={{ mr: "5px" }}>
                      Taxes
                    </Tab>
                  </TabsList>
                  <TabPanel value={0}>
                    <Box className={styles.Group}>
                      {/* MASTER ACCOUNT SELECTOR */}
                      <FormGroup className={styles.SearchField}>
                        <InputLabel htmlFor={`paymentledger`}>
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Primary Account
                          </Typography>
                        </InputLabel>
                        <Autocomplete
                          className={fieldStyles.SearchField}
                          id={`paymentledger`}
                          open={openMA}
                          onOpen={() => {
                            setMAOpen(true);
                          }}
                          onClose={() => {
                            setMAOpen(false);
                          }}
                          options={optionsMA}
                          defaultValue={
                            formData
                              ? { name: formData.primaryAccount, value: formData.paymentledger }
                              : { name: values.primaryAccount, value: values.paymentledger }
                          }
                          getOptionLabel={(option: any) => option.name}
                          loading={isMAPending}
                          noOptionsText={!initialMACondition ? "Start typing" : "No options"}
                          onChange={(e, value: any) => {
                            setFieldValue("paymentledger", value !== null ? value.idledger : values.paymentledger);
                            setFieldValue("primaryAccount", value !== null ? value.name : values.primaryAccount);
                          }}
                          renderInput={(params) => (
                            <TextField
                              className={fieldStyles.SearchFieldInput}
                              placeholder={`Enter account name`}
                              name={`paymentledger`}
                              onChange={(e) => {
                                setMAQuery(e.target.value);
                              }}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isMAPending ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        style={{ position: "absolute", right: "40px" }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name={`paymentledger`}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>

                      {/* COST CODES SELECTOR */}
                      <FormGroup className={styles.SearchField}>
                        <InputLabel htmlFor={`costcodeList`}>
                          <Typography variant="body1" color={theme.palette.info.main}>
                            Default Cost Code
                          </Typography>
                        </InputLabel>
                        <Autocomplete
                          className={fieldStyles.SearchField}
                          id={`costcodeList`}
                          open={openCC}
                          onOpen={() => {
                            setCCOpen(true);
                          }}
                          onClose={() => {
                            setCCOpen(false);
                          }}
                          options={optionsCC}
                          defaultValue={formData ? formData.costcodeList : values.costcodeList}
                          getOptionLabel={(option: any) => option.name}
                          loading={isCCPending}
                          noOptionsText={!initialCCCondition ? "Start typing" : "No options"}
                          onChange={(e, value: any) => {
                            setFieldValue(
                              "costcodeList",
                              value !== null
                                ? value.map((el) => {
                                    return { code: el.code, name: el.name };
                                  })
                                : { code: values.code, name: values.name }
                            );
                          }}
                          multiple
                          renderInput={(params) => (
                            <TextField
                              className={fieldStyles.SearchFieldInput}
                              placeholder={`Enter default cost code here`}
                              name={`costcodeList`}
                              onChange={(e) => {
                                setCCQuery(e.target.value);
                              }}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isCCPending ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        style={{ position: "absolute", right: "40px" }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage name={`costcodeList`}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
                      </FormGroup>
                    </Box>
                  </TabPanel>
                  <TabPanel value={1}>
                    <Box className={styles.Group}>
                      <FormField size="47%" title="Contact Name" name="contact" placeholder="Enter contact name" type="text" />
                      <FormField size="47%" title="Federal ID" name="federalid" placeholder="Enter federal ID" type="number" />
                      <FormField size="32%" title="City" name="tcity" placeholder="Enter city name" type="text" />
                      <FormField size="32%" title="State" name="tstate" placeholder="Enter state name" type="text" />
                      <FormField size="32%" title="ZIP Code" name="tzip" placeholder="Enter zip code" type="number" />
                      <FormField size="47%" title="Phone" name="phone1" placeholder="Enter phone number" type="tel" />
                      <FormField size="47%" title="Fax" name="phone2" placeholder="Enter fax number" type="tel" />
                      <FormField size="47%" title="Mobile" name="phone3" placeholder="Enter mobile number" type="tel" />
                      <FormField size="47%" title="Website" name="website" placeholder="Enter website" type="text" />
                    </Box>
                  </TabPanel>
                  <TabPanel value={2}>
                    <>
                      <Box className={styles.PaymentGroup}>
                        <div className={styles.TitleWithCheckbox}>
                          <Field as={Checkbox} id="insexpire" name="insexpire" checked={values.insexpire} />
                          <Typography>Apply Liability Insuranse Terms</Typography>
                        </div>
                        <div>
                          <Typography variant="body1" color={theme.palette.info.main} style={{ marginBottom: "8px" }}>
                            Wrk/Cm Exp.
                          </Typography>
                          <CalendarUpd
                            data={workDate}
                            callback={setWorkDate}
                            name="workdate"
                            className="wide"
                            disabled={!values.insexpire}
                          />
                        </div>
                        <div>
                          <Typography variant="body1" color={theme.palette.info.main} style={{ marginBottom: "8px" }}>
                            Insurance/Crt Exp.
                          </Typography>
                          <CalendarUpd
                            data={insuranceDate}
                            callback={setInsuranceDate}
                            name="insdate"
                            className="wide"
                            disabled={!values.insexpire}
                          />
                        </div>

                        <Box>
                          <Typography style={{ marginBottom: "8px" }} color={theme.palette.info.main}>
                            Ins/Crt Rate %
                          </Typography>
                          <Field
                            as={TextField}
                            id="inspercent"
                            name="inspercent"
                            type="number"
                            placeholder="Enter ins rate"
                            disabled={!values.insexpire}
                            onChange={(e) => {
                              if (e.target.value.length <= 3 && Number(e.target.value) >= 0) {
                                setFieldValue("inspercent", e.target.value);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box className={styles.PaymentGroup}>
                        <div className={styles.TitleWithCheckbox}>
                          <Field as={Checkbox} id="disapply" name="disapply" checked={values.disapply} />
                          <Typography>Apply Early Payment Discount</Typography>
                        </div>
                        <div className={styles.DiscountRow}>
                          <Field
                            as={TextField}
                            id="disrate"
                            name="disrate"
                            type="number"
                            onChange={(e) => {
                              if (e.target.value.length <= 3 && Number(e.target.value) >= 0) {
                                setFieldValue("disrate", e.target.value);
                              }
                            }}
                            className={styles.DiscountField}
                            disabled={!values.disapply}
                          />
                          <Typography style={{ marginRight: "10px", marginLeft: "10px" }} color={theme.palette.text.primary}>
                            % discount if paid by the
                          </Typography>
                          <Field
                            as={TextField}
                            id="disterm"
                            name="disterm"
                            type="number"
                            onChange={(e) => {
                              if (Number(e.target.value) >= 0) {
                                setFieldValue("disterm", e.target.value);
                              }
                            }}
                            className={styles.DiscountField}
                            disabled={!values.disapply}
                          />
                          <Typography style={{ marginLeft: "10px" }} color={theme.palette.text.primary}>
                            of the month.
                          </Typography>
                        </div>
                        <div className={styles.DiscountRow}>
                          <Typography style={{ marginRight: "10px" }} color={theme.palette.text.primary}>
                            Net amount due by the
                          </Typography>
                          <Field
                            as={TextField}
                            id="dueterm"
                            name="dueterm"
                            type="number"
                            onChange={(e) => {
                              if (Number(e.target.value) >= 0) {
                                setFieldValue("dueterm", e.target.value);
                              }
                            }}
                            className={styles.DiscountField}
                            disabled={!values.disapply}
                          />
                          <Typography style={{ marginLeft: "10px" }} color={theme.palette.text.primary}>
                            of the month.
                          </Typography>
                        </div>
                      </Box>
                      <Box className={styles.PaymentGroup}>
                        <div className={styles.TitleWithoutCheckbox}>
                          <Typography>Tax Information</Typography>
                        </div>
                        <Box>
                          <Typography style={{ marginBottom: "8px" }} color={theme.palette.info.main}>
                            Tax Rate %
                          </Typography>
                          <Field
                            as={TextField}
                            id="taxrate"
                            name="taxrate"
                            type="number"
                            placeholder="Enter tax rate"
                            onChange={(e) => {
                              if (e.target.value.length <= 3 && Number(e.target.value) >= 0) {
                                setFieldValue("taxrate", e.target.value);
                              }
                            }}
                          />
                        </Box>
                        <div className={styles.TaxRow}>
                          <Field
                            as={Checkbox}
                            id="form1099"
                            name="form1099"
                            checked={values.form1099}
                            className={styles.DiscountField}
                          />
                          <Typography color={theme.palette.text.primary}>Form 1099</Typography>
                          <Field
                            as={Checkbox}
                            id="invoiceyn"
                            name="invoiceyn"
                            checked={values.invoiceyn}
                            className={styles.DiscountField}
                          />
                          <Typography color={theme.palette.text.primary}>Invoice Required</Typography>
                        </div>
                      </Box>
                    </>
                  </TabPanel>
                </TabsUnstyled>
              </Grid>
              <div className={styles.FormFooter}>
                <SubmitButton text={buttonText} large isLoading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
