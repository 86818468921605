import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";
import ProjectsItem from "./ProjectsItem";

interface ProjectsProps {
  projects: any[];
}

// ? STYLES
import styles from "./Projects.module.css";

const ProjectsList: FC<ProjectsProps> = ({ projects }) => {
  const theme = useTheme();

  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);
  return (
    <>
      {projects.length ? (
        <>
          <TableListHeader>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Project No</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Company ID</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography color={theme.palette.primary.main}>Project Name</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Location</Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color={theme.palette.primary.main}>Plan Name</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Revision</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Sq.Ft</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Price Sq.Ft</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color={theme.palette.primary.main}>Takeoff</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <Typography color={theme.palette.primary.main}>Status</Typography>
            </Grid>
          </TableListHeader>
          {addNewLoader && (
            <div className={styles.Fake}>
              <div className={`${styles.Cover} ${styles.Disabled}`}>
                <CircularProgress size={25} className={styles.Loader} />
              </div>
              <TableListRow>
                <div></div>
              </TableListRow>
            </div>
          )}
          {projects.map((project, i) => (
            <div key={i}>
              <ProjectsItem project={project} />
            </div>
          ))}
        </>
      ) : (
        <>{paginationLoading ? null : <NoDataScreen pageName="projects" />}</>
      )}
    </>
  );
};

export default ProjectsList;
