import { useMemo, useState } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";
import { fetchCompaniesAction, clearDataAction } from "redux-store/redux-actions/companies";

// ? COMPONENTS
import { CircularProgress } from "@mui/material";
import { CompaniesHeader } from "./CompaniesHeader";
import { CompaniesList } from "./CompaniesList";
import CompaniesCreate from "components/modals/create/CompaniesCreate";
import CompaniesUpdate from "components/modals/update/CompaniesUpdate";
import CompanyDelete from "components/modals/delete/CompaniesDelete";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import CompaniesFileServer from "components/modals/file-server-modal/CompaniesFileServer";
import { withLayout } from "hoc/withLayout";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import { Search } from "components/search/Search";
import { nameCreate } from "components/modals/create/CompaniesCreate";
import useAccess from "custom-hooks/useAccess";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { COMPANIES } from "resource/constants/companies";
import { menu } from "resource/constants/menu-list";

const Companies = () => {
  const [searchKeyword, setSearchKeyword] = useState<any>(null);
  const { open: openCreate } = useModal(nameCreate);
  const { hasAddAccess } = useAccess(menu.companies.idmenu);
  // LOADERS
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  // STORE
  const companies = useSelector((state: RootStateOrAny) => state.companies.companies);
  const companiesCount = useSelector((state: RootStateOrAny) => state.companies.companiesCount);
  const hasMoreToLoad = companiesCount > companies.length;

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
    }),
    [searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchCompaniesAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  return (
    <>
      <CompaniesHeader>
        <Search value={searchValue} placeholder="Search companies" handleChange={handleSearchChange} />
        {hasAddAccess && <AddNewButton text={COMPANIES.NEW_MODAL_TITLE} openModal={openCreate} disabled={paginationLoading} />}
      </CompaniesHeader>

      <CompaniesList companies={companies} />

      <CompaniesCreate />
      <CompaniesUpdate />
      <CompanyDelete />
      <CompaniesFileServer />

      {paginationLoading && (
        <div className={`LoaderScroll ${!companies.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(Companies);
