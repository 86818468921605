import { useState, useMemo, ChangeEvent } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchChangeOrdersAction, clearChangeOrderDataAction } from "redux-store/redux-actions/changeOrders";

// ? COMPONENTS
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useModal from "custom-hooks/useModal";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import FilterSelect from "components/shared/filter-select/FilterSelect";
import Calendar from "components/shared/calendar/Calendar";
import ChangeOrderList from "./ChangeOrderList";
import ChangeOrderCreate, { nameCreate } from "components/modals/create/ChangeOrderCreate";
import useAccess from "custom-hooks/useAccess";
import { withLayout } from "hoc/withLayout";
import { CircularProgress } from "@mui/material";
import { Search } from "components/search/Search";

// ? RESOURCES
import { CHANGE_ORDERS, changeOrderFiltersList } from "resource/constants/change-order";
import { formatDate } from "resource/utils";
import { ChangeOrderRequest, ChangeOrderDetailResponse } from "resource/interfaces/change-order";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "./ChangeOrder.module.css";

interface changeOrderItemsType {
  [idnumber: number | string]: ChangeOrderRequest | ChangeOrderDetailResponse;
}

const ChangeOrder = () => {
  const dispatch = useDispatch();
  const { hasAddAccess } = useAccess(menu.changeOrders.idmenu);
  const changeOrderIds: number[] = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrderIds);
  const changeOrderItems: changeOrderItemsType = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrderItems);
  const changeOrdersCount: number = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrdersCount);
  const hasMoreToLoad: boolean = changeOrdersCount > changeOrderIds.length;
  const paginationLoading: boolean = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [select, setSelect] = useState<string>(changeOrderFiltersList[1]);
  const { open: openDraftCreate } = useModal(nameCreate);

  const additionalData = useMemo(
    () => ({
      filter: select,
      fromDate: dateRange[0] ? formatDate(dateRange[0]) : null,
      toDate: dateRange[1] ? formatDate(dateRange[1]) : null,
      keywords: searchKeyword,
    }),
    [select, dateRange, searchKeyword]
  );

  const { setPage } = useInfiniteLoading(fetchChangeOrdersAction, hasMoreToLoad, paginationLoading, additionalData);

  // handle filters change
  const handleFilterChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSelect(evt.target.value);
    setPage(0);
    dispatch(clearChangeOrderDataAction());
  };

  // handle search change
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setSearchKeyword(evt.target.value);
    setPage(0);
    dispatch(clearChangeOrderDataAction());
  };

  const handleDatePickerChange = (update) => {
    setDateRange(update);
    setPage(0);
    dispatch(clearChangeOrderDataAction());
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      const value = evt.target.value.split(" - ").map((data: string): Date => new Date(data));
      handleDatePickerChange(value);
    }
  };

  const datePickerOptions = {
    startDate,
    endDate,
    selectsRange: true,
    placeholderText: "Filter Date",
  };

  return (
    <>
      <PageHeader pageName="changeOrders">
        <FilterSelect handleChange={handleFilterChange} select={select} options={changeOrderFiltersList} />
        <Calendar
          handleKeyDown={handleKeyDown}
          handleDatePickerChange={handleDatePickerChange}
          options={datePickerOptions}
          name="dateRangeFilter"
        />
        <Search value={searchKeyword} placeholder="Search change orders" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={CHANGE_ORDERS.NEW_MODAL_PLUS_BUTTON} openModal={openDraftCreate} disabled={paginationLoading} />
        )}
      </PageHeader>
      <ChangeOrderList changeOrderIds={changeOrderIds} changeOrderItems={changeOrderItems} />

      <ChangeOrderCreate className={styles.Modal} />

      {paginationLoading ? (
        <div className={`LoaderScroll ${!changeOrderIds.length && "center"}`}>
          <CircularProgress />
        </div>
      ) : null}
    </>
  );
};

export default withLayout(ChangeOrder);
