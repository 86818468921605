import { FC, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";

//  ?  COMPONENTS
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";

// ? STYLES
import styles from "./FilePreview.module.css";

// ? RESOURCES
import { URL as serverURL } from "resource/constants/api";

export const nameFilePreview = `FilePreview`;

const FilePreview: FC = () => {
  const { close, isOpened, data } = useModal(nameFilePreview);
  const [isLoading, setLoader] = useState(false);

  const getSrc = (path) => {
    return path.includes("blob") ? `${path}` : `${serverURL}${path}`;
  };

  const openFile = (path) => {
    const image = new Image();
    image.src = getSrc(path);
    const width = image.width;
    const height = image.height;
    window.open(path.includes("blob") ? `${path}` : `${serverURL}${path}`, "Image", "width=" + width + ",height=" + height);
  };

  const handleDownload = async (url, filename, type) => {
    setLoader(true);
    try {
      const res = await axios.get(url, {
        responseType: "blob",
      });
      fileDownload(res.data, `${filename}.${type}`);
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  return (
    <ModalManager name={nameFilePreview}>
      {isOpened ? (
        <div className={styles.PreviewForm}>
          <Button className={styles.SubmitButton} onClick={() => openFile(data.url)}>
            Preview
          </Button>
          <LoadingButton
            className={!isLoading ? styles.SubmitButton : styles.LoadingSubmitButton}
            onClick={() => {
              handleDownload(getSrc(data.url), data.name, data.ftype.toLowerCase());
            }}
            loading={isLoading}
            disabled={isLoading}
          >
            Download
          </LoadingButton>
          <Button className={styles.CloseButton} onClick={close}>
            Close
          </Button>
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default FilePreview;
