import React from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Box, Grid, Typography, CircularProgress, useTheme } from "@mui/material";

// ? STYLES
import styles from "./PageHeader.module.css";

// ? RESOURCES
import { pageHeaderContent } from "resource/constants/page-header-content";

// ? TYPES
interface PageHeaderProps {
  pageName: string;
  customName?: string;
  path?: string;
  children?: React.ReactNode;
  loading?: boolean;
  customIcon?: string;
}

const PageHeader = ({ pageName, customName, path, children, loading, customIcon }: PageHeaderProps) => {
  const { heading, icon, text } = pageHeaderContent[pageName];
  const theme = useTheme();

  return (
    <Grid className={styles.PageHeader} container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box className={styles.PageHeaderText}>
          {path ? (
            <Link to={path}>
              <Box className={styles.PageHeaderIcon}>
                <img src={customIcon ? customIcon : icon} alt="" role="presentation" />
              </Box>
            </Link>
          ) : (
            <Box className={styles.PageHeaderIcon}>
              <img src={icon} alt="" role="presentation" />
            </Box>
          )}
          <Typography variant="h1" color={theme.palette.text.primary}>
            {customName ? customName : heading}
          </Typography>
          {loading && <CircularProgress size={20} style={{ marginLeft: "10px" }} />}
        </Box>
        <Typography variant="body1" color={theme.palette.info.main}>
          {text}
        </Typography>
      </Grid>
      <Grid className={styles.ControlPanel} item>
        {children}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
