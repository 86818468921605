import { useRef } from "react";

const useTimer = (cb, time) => {
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  timer.current = setTimeout(() => cb, time);

  const cleanedTimeout = () => {
    return clearTimeout(timer.current as NodeJS.Timeout);
  };

  const settedTimeout = () => setTimeout(cb, time);

  return { timer, cleanedTimeout, settedTimeout };
};

export default useTimer;
