import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// ?REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import {
  fetchDataDetailsAction,
  clearDataAction,
  fetchVendorAction,
} from "redux-store/redux-actions/vendorPricing";

// ? COMPONENTS
import { VendorsPricingDetailsList } from "./VendorsPricingDetailsList";
import { withLayout } from "hoc/withLayout";
import { Search } from "components/search/Search";
import PageHeader from "components/page-header/PageHeader";
import useInfiniteLoading from "custom-hooks/useInfiniteLoading";
import useSearchUpdate from "custom-hooks/useSearchUpdate";
import useAccess from "custom-hooks/useAccess";
import AddNewButton from "components/shared/add-new-button/AddNewButton";
import VendorPricingCreate from "components/modals/create/VendorPricingCreate";
import VendorPricingUpdate from "components/modals/update/VendorPricingUpdate";
import VendorPricingDelete from "components/modals/delete/VendorPricingDelete";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { VENDOR_PRICING } from "resource/constants/vendorPricing";
import { menu } from "resource/constants/menu-list";
import { nameCreate } from "components/modals/create/VendorPricingCreate";

export const VendorPricingDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { open: openCreate } = useModal(nameCreate);

  const { hasAddAccess } = useAccess(menu.vendorPricing.idmenu);
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const detailsData = useSelector((state: RootStateOrAny) => state.vendorPricing.vendorPricingDetails);
  const detailsDataCount = useSelector((state: RootStateOrAny) => state.vendorPricing.vendorPricingDetailsCount);
  const hasMoreToLoad = detailsDataCount > detailsData.length;
  const [searchKeyword, setSearchKeyword] = useState<any>(null);

  const idvendor = location.pathname.split("/").pop();
  const vendorName = useSelector((state: RootStateOrAny) => state.vendorPricing.vendor).name;

  const idgeoarea = 1;

  const modifyVendorDetailData = (data) => {
    return data.map((el) => {
      return { ...el, idvendor, idgeoarea };
    });
  };

  const additionalData = useMemo(
    () => ({
      keywords: searchKeyword,
      idgeoarea,
      idvendor,
    }),
    [searchKeyword, idgeoarea, idvendor]
  );

  const { setPage } = useInfiniteLoading(fetchDataDetailsAction, hasMoreToLoad, paginationLoading, additionalData);
  const { handleSearchChange, searchValue } = useSearchUpdate(setPage, clearDataAction, setSearchKeyword);

  useEffect(() => {
    if (!vendorName) {
      dispatch(fetchVendorAction({ idvendor }));
    }
  }, []);

  return (
    <>
      <PageHeader
        pageName="vendorPricingDetails"
        customName={vendorName || "Vendor name"}
        path="/purchasing/vendor-pricing"
        loading={!vendorName ? true : false}
      >
        <Search value={searchValue} placeholder="Search products" handleChange={handleSearchChange} />
        {hasAddAccess && (
          <AddNewButton text={VENDOR_PRICING.NEW_MODAL_PLUS_BUTTON} openModal={openCreate} disabled={paginationLoading} />
        )}
      </PageHeader>

      <VendorsPricingDetailsList
        data={modifyVendorDetailData(detailsData)}
      />

      <VendorPricingCreate idvendor={idvendor} idgeoarea={idgeoarea} />
      <VendorPricingUpdate idvendor={idvendor} idgeoarea={idgeoarea} />
      <VendorPricingDelete />
    </>
  );
};

export default withLayout(VendorPricingDetail);
