// ? COMPONENTS
import { Typography, Grid, useTheme } from "@mui/material";

// ? STYLES
import styles from "./Assemblies.module.css";

export const AssembliesFormPartsHeader = () => {
  const theme = useTheme();

  return (
    <div className={styles.PaymentHeader}>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Part No.</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Part Name</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Formula</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Quantity</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Price</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1}>
        <Typography color={theme.palette.primary.main}>Amount</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={3}>
        <Typography color={theme.palette.primary.main}>Notes</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1}>
        <Typography color={theme.palette.primary.main}>Updated</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Date</Typography>
      </Grid>
      <Grid className={styles.HeaderItem} item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Actions</Typography>
      </Grid>
    </div>
  );
};
