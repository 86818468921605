import { useState, FC } from "react";

// ? REDUX
import useAsync from "custom-hooks/useAsync";
import { deletePurchaseOrderPartAction, updatePurchaseOrderPartAction } from "redux-store/redux-actions/purchaseOrder";
import { IconButton, Box } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

// ? COMPONENTS
import useAccess from "custom-hooks/useAccess";

// ? RESOURCES
import checkboxChecked from "resource/images/checkbox-checked-icon.svg";
import uncheckedCheckbox from "resource/images/unchecked.svg";
import deleteIcon from "resource/images/delete.svg";
import { formatPrice, getFormattedDateTime } from "resource/utils";
import { PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { PurchaseOrderDetailResponse, PartsListPoDetails } from "resource/interfaces/purchase-order";
import { StatusList } from "resource/consts";
import { menu } from "resource/constants/menu-list";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";
const inlineStyle = {
  "& .MuiDataGrid-row": { borderBottom: "2px solid #E0E0E0" },
  "& .MuiDataGrid-virtualScrollerContent:last-child div div": { borderBottom: "none" },
  "& .MuiDataGrid-columnSeparator": { display: "none" },
  "& .MuiDataGrid-row.Mui-selected": { bgcolor: "inherit" },
  "& .MuiDataGrid-row.Mui-selected:hover": { bgcolor: "rgba(244, 244, 255, 0.8)" },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& div.MuiDataGrid-overlay": {
    display: "none",
  },
} as const;

interface PurchaseOrderDetailPartsProps {
  order: PurchaseOrderDetailResponse;
  isEditable: boolean;
}

interface ParamsType {
  id: string;
  field: string;
  value: string;
}

const markAsEditable = (name: string, isEditable: boolean) => {
  return isEditable ? `${name}*` : name;
};

const processUpdatePartData = (params, part, order: PurchaseOrderDetailResponse) => {
  const data = {
    idnumber: Number(order.idnumber),
    itemid: Number(params.id),
    idtrack: Number(order.idtrack),
    quantity: Number(part.qty),
    notes: part.notes,
    price: Number(part.tprice),
    skunumber: part.skunumber,
  };

  switch (params.field) {
    case "qty":
      return { ...data, quantity: Number(params.value) };
    case "tprice":
      return { ...data, price: Number(params.value) };
    case "notes":
      return { ...data, notes: params.value };
    default:
      return { ...data };
  }
};

const getColumns = (
  isEditable: boolean,
  isDraft: boolean,
  handlePartDelete: (id: number) => void,
  isLoading: boolean,
  hasEditAccess: boolean
) => {
  const columns: GridColDef[] = [
    { field: "upc", headerName: "Parts №", headerClassName: `${styles.PoColumnHeader}`, flex: 1, minWidth: 60, editable: false },
    {
      field: "description",
      headerName: "Part Name",
      flex: 3,
      minWidth: 120,
      headerClassName: `${styles.PoColumnHeader}`,
      editable: false,
    },
    {
      field: "lengthunit",
      headerName: "Unit",
      flex: 1,
      minWidth: 60,
      headerClassName: `${styles.PoColumnHeader}`,
      editable: false,
    },
    {
      field: "preorder",
      headerName: "Preorder",
      headerClassName: `${styles.PoColumnHeader}`,
      flex: 1,
      minWidth: 60,
      editable: false,
      sortable: false,
      align: "center",
      valueGetter: (params: GridValueGetterParams) => {
        return Number(params.row.preorder) - Number(params.row.qty);
      },
    },
    {
      field: "qty",
      headerName: markAsEditable("Quantity", isEditable),
      flex: 1,
      minWidth: 60,
      align: "center",
      headerClassName: `${styles.PoColumnHeader}`,
      editable: !isLoading && isEditable,
    },
    {
      field: "ucost",
      headerName: markAsEditable("Price", isEditable),
      headerClassName: `${styles.PoColumnHeader} ${styles.PoColumnHeaderCentered}`,
      align: "right",
      flex: 1,
      editable: !isLoading && isEditable,
      valueFormatter: (params) => {
        const result = Number(params.value).toFixed(2);
        return formatPrice(String(result));
      },
    },
    {
      field: "tprice",
      headerName: "Amount",
      headerClassName: `${styles.PoColumnHeader} ${styles.PoColumnHeaderCentered}`,
      align: "right",
      flex: 1,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        const result = Number(params.value).toFixed(2);
        return formatPrice(String(result));
      },
    },
    {
      field: "taxyn",
      headerName: "Tax",
      headerClassName: `${styles.PoColumnHeader}`,
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 60,
      align: "center",
      renderCell: (cellValues) => {
        return (
          <img
            style={{ opacity: "0.6" }}
            src={cellValues.value ? checkboxChecked : uncheckedCheckbox}
            alt="parts taxable status"
          />
        );
      },
    },
    {
      field: "notes",
      headerName: markAsEditable("Notes", isEditable),
      headerClassName: `${styles.PoColumnHeader}`,
      flex: 1,
      minWidth: 100,
      editable: !isLoading && isEditable,
      sortable: false,
    },
    {
      field: "modiby",
      headerName: "Updated",
      headerClassName: `${styles.PoColumnHeader}`,
      align: "center",
      flex: 1,
      minWidth: 90,
      editable: false,
      sortable: false,
    },
    {
      field: "modidate",
      headerName: "Date",
      headerClassName: `${styles.PoColumnHeader}`,
      flex: 1,
      minWidth: 130,
      editable: false,
      sortable: false,
      valueFormatter: (params) => {
        return getFormattedDateTime(String(params.value));
      },
    },
  ];

  const actionColumn = {
    field: "actions",
    headerName: "Actions",
    headerClassName: `${styles.PoColumnHeader}`,
    flex: 1,
    minWidth: 50,
    editable: false,
    sortable: false,
    renderCell: (params) => {
      const handleClick = () => {
        handlePartDelete(params.row.idnumber);
      };
      return (
        <>
          <IconButton name="delete" disabled={isLoading} onClick={handleClick} sx={{ width: "100%", height: "100%" }}>
            <img src={deleteIcon} alt="Delete icon" />
          </IconButton>
        </>
      );
    },
  };

  if (isDraft && hasEditAccess) {
    return [...columns, actionColumn];
  } else {
    return columns;
  }
};

const PurchaseOrderDetailParts: FC<PurchaseOrderDetailPartsProps> = ({ order, isEditable }) => {
  const { hasEditAccess } = useAccess(menu.purchaseOrder.idmenu);
  const { partsList, idnumber, idtrack, status } = order;
  const PARTS_LIST_LENGTH = [partsList.length];
  const TABLE_HEIGHT = `${(partsList.length + 1) * 64}px`;
  const [isLoading, setLoader] = useState(false);
  const [prevIdtrack, setPrevIdtrack] = useState<null | number>(null);
  const deletePurchaseOrderPart = useAsync(deletePurchaseOrderPartAction);
  const updatePurchaseOrderPart = useAsync(updatePurchaseOrderPartAction);

  const handlePartDelete = async (itemid: number) => {
    const dataForSubmit = { itemid, idnumber, idtrack };
    setLoader(true);
    try {
      await deletePurchaseOrderPart({ data: dataForSubmit, actionType: PurchaseOrderEndpoints.DELETE_PART });
      setLoader(false);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const handleRowEditCommit = (params) => {
    if (!isLoading && idtrack !== prevIdtrack) {
      handlePartUpdate(params);
    }
  };

  const handlePartUpdate = async (params: ParamsType) => {
    const editedPart = partsList.find((part: PartsListPoDetails) => part.idnumber === params.id);
    const data = processUpdatePartData(params, editedPart, order);
    setLoader(true);
    try {
      await updatePurchaseOrderPart({ data, actionType: PurchaseOrderEndpoints.UPDATE_PART });
      setLoader(false);
      setPrevIdtrack(data.idtrack);
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  const isDraft = status === StatusList.DRAFT;
  const allowEdit = hasEditAccess && isEditable && !isLoading && idtrack !== prevIdtrack;
  return (
    <Box sx={{ height: TABLE_HEIGHT, width: "100%" }}>
      <DataGrid
        rows={partsList}
        rowsPerPageOptions={PARTS_LIST_LENGTH}
        columns={getColumns(allowEdit, isDraft, handlePartDelete, isLoading, hasEditAccess)}
        getRowId={(row) => row.idnumber}
        hideFooterPagination
        hideFooterSelectedRowCount
        hideFooter
        rowHeight={58}
        onCellEditCommit={handleRowEditCommit}
        sx={{ ...inlineStyle }}
      />
    </Box>
  );
};

export default PurchaseOrderDetailParts;
