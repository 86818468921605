import { FC } from "react";
// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListHeader, TableListRow } from "components/shared/table-list/TableList";
import DevelopmentsItem from "./DevelopmentsItem";

interface DevelopmentsListProps {
  developments: any[];
}

// ? STYLES
import styles from "./Developments.module.css";

const DevelopmentsList: FC<DevelopmentsListProps> = ({ developments }) => {
  const theme = useTheme();

  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  return (
    <>
      <TableListHeader>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>ID</Typography>
        </Grid>
        <Grid item xs={3.5}>
          <Typography color={theme.palette.primary.main}>Name</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Location</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Specs</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Sold</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.primary.main}>Closed</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Total Projects</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.primary.main}>Status</Typography>
        </Grid>
      </TableListHeader>
      {addNewLoader && (
        <div className={styles.Fake}>
          <div className={`${styles.Cover} ${styles.Disabled}`}>
            <CircularProgress size={25} className={styles.Loader} />
          </div>
          <TableListRow>
            <div></div>
          </TableListRow>
        </div>
      )}
      {developments.map((development, i) => (
        <div key={i}>
          <DevelopmentsItem development={development} />
        </div>
      ))}
    </>
  );
};

export default DevelopmentsList;
