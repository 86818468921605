import React, { useState, useEffect, FC } from "react";

// ? COMPONENTS
import { ErrorMessage } from "formik";
import { Typography, Grid, FormGroup, InputLabel, CircularProgress, TextField, Autocomplete, useTheme } from "@mui/material";
import { ValidationError } from "components/shared/validation-error/ValidationError";

// ? STYLES
import styles from "./FormField.module.css";

// ? TYPES
interface SearchFieldProps {
  size: string;
  title: string;
  responseName: string;
  name: string;
  label: string;
  placeholder: string;
  setFieldValue: (name: string, value: any) => void;
  initialFormValues: any;
  defaultValue?: any;
  options: any;
  onQueryChange: (data: any) => void;
  isPending: boolean;
  initialCondition: boolean;
  multiple?: boolean;
  disabled?: boolean;
  callback?: any;
}

export const SearchField: FC<SearchFieldProps> = ({
  size,
  title,
  responseName,
  name,
  label,
  placeholder,
  setFieldValue,
  initialFormValues,
  defaultValue,
  options,
  onQueryChange,
  isPending,
  initialCondition,
  multiple,
  disabled,
  callback,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isPending);
  }, [isPending]);

  return (
    <Grid item style={{ width: size }}>
      <FormGroup className={styles.FormField}>
        <InputLabel htmlFor={name}>
          <Typography variant="body1" color={theme.palette.info.main}>
            {title}
          </Typography>
        </InputLabel>
        <Autocomplete
          className={styles.SearchField}
          id={name}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disabled={disabled}
          options={options}
          defaultValue={defaultValue}
          getOptionLabel={(option: any) => option[label]}
          loading={loading}
          noOptionsText={!initialCondition ? "Start typing" : "No options"}
          multiple={multiple}
          onChange={(e, value: any) => {
            if (!multiple) {
              setFieldValue(name, value !== null ? value[responseName] : initialFormValues[name]);
              callback && callback(value);
            } else {
              setFieldValue(
                name,
                value !== null
                  ? value.map((el) => {
                      return { [responseName]: el[responseName] };
                    })
                  : initialFormValues[name]
              );
            }
          }}
          renderInput={(params) => (
            <TextField
              className={styles.SearchFieldInput}
              placeholder={placeholder}
              onChange={(e) => {
                onQueryChange(e.target.value);
              }}
              name={name}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "40px" }} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <ErrorMessage name={name}>{(msg) => <ValidationError>{msg}</ValidationError>}</ErrorMessage>
      </FormGroup>
    </Grid>
  );
};
