export enum NotifierActions {
  SET_NOTICE = "SET_NOTICE",
  CLEAR_NOTIFIER = "CLEAR_NOTIFIER",
}

export const setNoticeAction = (payload: any) => {
  return {
    type: NotifierActions.SET_NOTICE,
    payload,
  };
};

export const clearNotifierAction = () => {
  return {
    type: NotifierActions.CLEAR_NOTIFIER,
  };
};
