import { useState, FC } from "react";

import { useHistory } from "react-router-dom";

// ? REDUX
import { createPurchaseOrderDraftAction } from "redux-store/redux-actions/purchaseOrder";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import PurchaseOrderCreateFormInit from "pages/purchase-order/purchase-order-forms/PurchaseOrderCreateFormInit";

// ? RESOURCES
import { PURCHASE_ORDER_NAME, PurchaseOrderEndpoints } from "resource/constants/purchase-order";
import { ActionName } from "resource/constants/api";
import { PurchaseOrderCreateRequest } from "resource/interfaces/purchase-order";
interface PurchaseOrderCreateProps {
  className: string;
}

export const nameCreate = `${PURCHASE_ORDER_NAME}/${ActionName.CREATE}`;

const initialFormValues = {
  invoice: "",
  idreason: 1,
  shipping: "",
  insurance: "",
  others: "",
  shipto: "",
  idvendor: "",
  idproject1: "",
  idcostcode: "",
};

const PurchaseOrderCreate: FC<PurchaseOrderCreateProps> = ({ className }) => {
  const history = useHistory();
  const { close } = useModal(nameCreate);

  const [isLoading, setLoader] = useState<boolean>(false);
  const createPurchaseOrder = useAsync(createPurchaseOrderDraftAction);

  const handleSubmit = async (data: PurchaseOrderCreateRequest) => {
    setLoader(true);

    try {
      await createPurchaseOrder({
        data,
        actionType: PurchaseOrderEndpoints.CREATE_DRAFT,
        history,
      });
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameCreate} className={className}>
      <PurchaseOrderCreateFormInit
        buttonText="Save as Draft and Review"
        handleSubmit={handleSubmit}
        formData={initialFormValues}
        handleClose={close}
        isLoading={isLoading}
      />
    </ModalManager>
  );
};

export default PurchaseOrderCreate;
