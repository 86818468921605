import { PurchaseOrderActions } from "redux-store/redux-actions/purchaseOrder";
import { updateCollection, getUniqueData } from "resource/utils";
import { PurchaseOrderListItem } from "resource/interfaces/purchase-order";

const initialState = {
  purchaseOrder: [],
  purchaseOrderCount: 0,
  purchaseOrderError: "",
  isPurchaseOrderDetailExist: false,
};

export const purchaseOrderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PurchaseOrderActions.PUT_PURCHASE_ORDER_LIST:
      return {
        ...state,
        purchaseOrder: [
          ...state.purchaseOrder,
          ...getUniqueData<PurchaseOrderListItem>(payload.data, state.purchaseOrder, "idnumber"),
        ],
        purchaseOrderCount: payload.count as number,
      };
    case PurchaseOrderActions.PUT_PURCHASE_ORDER_BY_ID:
      return {
        ...state,
        purchaseOrder: Object.prototype.hasOwnProperty.call(payload.data, "exist")
          ? [...state.purchaseOrder]
          : updateCollection(state.purchaseOrder, payload.data, "idnumber"),
        isPurchaseOrderDetailExist: !Object.prototype.hasOwnProperty.call(payload.data, "exist"),
      };
    case PurchaseOrderActions.CLEAR_PO_DATA:
      return {
        ...state,
        purchaseOrder: [],
        purchaseOrderCount: 0,
      };
    case PurchaseOrderActions.CATCH_PURCHASE_ORDER_ERROR:
      return { ...state, purchaseOrderError: payload };
    default:
      return state;
  }
};
