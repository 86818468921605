import { AssemblyRequest, AssemblyDetailRequest } from "resource/interfaces/assemblies";
import { PromiseType } from "resource/interfaces";

// ? ACTIONS
export enum AssembliesActions {
  ADD_NEW_ASSEMBLING = "ADD_NEW_ASSEMBLING",
  EDIT_ASSEMBLING = "EDIT_ASSEMBLING",
  GET_ASSEMBLING_BY_ID = "GET_ASSEMBLING_BY_ID",
  PUT_ASSEMBLING_BY_ID = "PUT_ASSEMBLING_BY_ID",
  FETCH_ASSEMBLING_BY_ID = "FETCH_ASSEMBLING_BY_ID",
  GET_ASSEMBLIES = "GET_ASSEMBLIES",
  FETCH_ASSEMBLIES = "FETCH_ASSEMBLIES",
  DELETE_ASSEMBLING_FROM_SERVER = "DELETE_ASSEMBLING_FROM_SERVER",
  DELETE_ASSEMBLING_FROM_STORE = "DELETE_ASSEMBLING_FROM_STORE",
  ASSEMBLY_ACTIVATION = "ASSEMBLY_ACTIVATION",
  PUT_ASSEMBLIES_TYPE_LIST = "PUT_ASSEMBLIES_TYPE_LIST",
  CLEAR_ASSEMBLIES_DATA = "CLEAR_ASSEMBLIES_DATA",
  FETCH_EDIT_ASSEMBLEY = "FETCH_EDIT_ASSEMBLEY",
  PUT_EDIT_ASSEMBLEY = "PUT_EDIT_ASSEMBLEY",
  CLEAR_ASSEMBLIES_DATA_DETAILS = "CLEAR_ASSEMBLIES_DATA_DETAILS",

  PUT_ASSEMBLIES_FILTER_LIST = "PUT_ASSEMBLIES_FILTER_LIST",
}

// ? ACTION CREATORS

export const addNewAssemblingAction = (payload: { values; actionType }, promise?: PromiseType) => {
  return {
    type: AssembliesActions.ADD_NEW_ASSEMBLING,
    payload,
    promise,
  };
};

export const editAssemblingAction = (payload: { values; actionType }, promise?: PromiseType) => {
  return {
    type: AssembliesActions.EDIT_ASSEMBLING,
    payload,
    promise,
  };
};

export const getAssemblingAction = (payload: AssemblyDetailRequest) => {
  return {
    type: AssembliesActions.GET_ASSEMBLING_BY_ID,
    payload,
  };
};

export const putAssemblingAction = (payload: AssemblyDetailRequest[]) => {
  return {
    type: AssembliesActions.PUT_ASSEMBLING_BY_ID,
    payload,
  };
};

export const fetchAssemblingAction = (payload: {
  idnumber: number;
  mode: string;
  loader?: (data: boolean) => void;
  setLocalLoading?: (data: boolean) => void;
}) => {
  return {
    type: AssembliesActions.FETCH_ASSEMBLING_BY_ID,
    payload,
  };
};

export const getAssembliesAction = (payload: { data: AssemblyRequest[]; count: number }) => {
  return {
    type: AssembliesActions.GET_ASSEMBLIES,
    payload,
  };
};

export const fetchAssembliesAction = (payload: {
  additionalData: { keywords: null | string; filter: null | string };
  page: number;
}) => {
  return {
    type: AssembliesActions.FETCH_ASSEMBLIES,
    payload,
  };
};

export const deleteAssemblingServerAction = (payload: { idnumber: number }, promise?: PromiseType) => {
  return {
    type: AssembliesActions.DELETE_ASSEMBLING_FROM_SERVER,
    payload,
    promise,
  };
};

export const deleteAssemblingStoreAction = (payload: { idnumber: number }) => {
  return {
    type: AssembliesActions.DELETE_ASSEMBLING_FROM_STORE,
    payload,
  };
};

export const putAssembliesTypeListAction = (payload: string[]) => {
  return {
    type: AssembliesActions.PUT_ASSEMBLIES_TYPE_LIST,
    payload,
  };
};

export const putAssembliesFilterListAction = (payload: string[]) => {
  return {
    type: AssembliesActions.PUT_ASSEMBLIES_FILTER_LIST,
    payload,
  };
};

export const clearDataAction = () => {
  return {
    type: AssembliesActions.CLEAR_ASSEMBLIES_DATA,
  };
};

export const clearDataDetailAction = () => {
  return {
    type: AssembliesActions.CLEAR_ASSEMBLIES_DATA_DETAILS,
  };
};

export const assemblyActivationAction = (payload: { values }, promise?: PromiseType) => {
  return {
    type: AssembliesActions.ASSEMBLY_ACTIVATION,
    payload,
    promise,
  };
};

export const fetchEditAssemblyAction = (payload: { idnumber: number }) => {
  return {
    type: AssembliesActions.FETCH_EDIT_ASSEMBLEY,
    payload,
  };
};

export const putEditAssemblyAction = (payload: { idnumber: number }) => {
  return {
    type: AssembliesActions.PUT_EDIT_ASSEMBLEY,
    payload,
  };
};
