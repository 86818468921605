import { CompaniesActions } from "redux-store/redux-actions/companies";
import { updateCollection, getUniqueData } from "resource/utils";

const initialState = {
  companies: [],
  companiesError: "",
  companiesCount: 0,
};

export const companiesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CompaniesActions.GET_COMPANIES:
      return {
        ...state,
        companies: [...state.companies, ...getUniqueData(payload.data, state.companies, "idnumber")],
        companiesCount: payload.count,
      };
    case CompaniesActions.GET_COMPANY_BY_ID:
      return {
        ...state,
        companies: updateCollection(state.companies, payload, "idnumber"),
      };
    case CompaniesActions.PUT_COMPANY_BY_ID:
      return {
        ...state,
        companies: [
          ...state.companies.map((company: any) => {
            if (company.idnumber === payload.idnumber) {
              return payload;
            } else {
              return company;
            }
          }),
        ],
      };
    case CompaniesActions.CLEAR_COMPANIES_DATA:
      return {
        ...state,
        companies: [],
        companiesCount: 0,
      };
    case CompaniesActions.DELETE_COMPANY_FROM_STORE:
      return {
        ...state,
        companies: [...state.companies.filter((company: any) => company.idnumber !== payload.idnumber)],
      };
    case CompaniesActions.CATCH_COMPANIES_ERROR:
      return { ...state, companiesError: payload };
    default:
      return state;
  }
};
