export const customMessage = {
  company: {
    heading: `You haven’t added a company yet!`,
    text: `Please add a client company by clicking on the “Add New Company”
    button. Once you’ve added them, the list will appear here.`,
  },
  costCodes: {
    heading: `You haven’t added a cost code yet!`,
    text: `Please add a cost code by clicking on the “Add New Cost Code”
    button. Once you’ve added them, the list will appear here.`,
  },
  masterRoles: {
    heading: `You haven’t added a role yet!`,
    text: `Please add a new role by clicking on the “Add New Role"
    button. Once you’ve added them, the list will appear here.`,
  },
  usersProfile: {
    heading: `You haven’t added an user yet!`,
    text: `Please add a new user by clicking on the “+ New User Profile"
    button. Once you’ve added them, the list will appear here.`,
  },
  basePlans: {
    heading: `You haven’t added a plan yet!`,
    text: `Please add a new base plan by clicking on the “+ Add New Base Plan"
    button. Once you’ve added them, the list will appear here.`,
  },
  planVariables: {
    heading: `You haven’t added a plan variables yet!`,
    text: `Please add a new variable by clicking on the “Add New Variable"
    button. Once you’ve added them, the list will appear here.`,
  },
  parts: {
    heading: `You haven’t added a part yet!`,
    text: `Please add a new variable by clicking on the “Add New Part" button. Once you’ve added them, the list will appear here.`,
  },
  vendors: {
    heading: `You haven’t added a vendor yet!`,
    text: `Please add a new vendor by clicking on the “+ Add New Vendor"
    button. Once you’ve added them, the list will appear here.`,
  },
  vendorsPricing: {
    heading: `You haven’t added a data yet!`,
    text: `Please add a new data by clicking on the “Add New Vendors"
    button. Once you’ve added them, the list will appear here.`,
  },
  purchaseOrder: {
    heading: `You haven’t added a data yet!`,
    text: `Please add a new data by clicking on the “Add New P/O"
    button. Once you’ve added them, the list will appear here.`,
  },
  masterFloorplan: {
    heading: `You haven’t added a floorplans yet!`,
    text: `Please add a new floorplan by clicking on the “Add New Floorplan"
    button. Once you’ve added them, the list will appear here.`,
  },
  contracts: {
    heading: `You haven’t added a contract yet!`,
    text: `Please add a new contract by clicking on the “Add New Contracts"
    button. Once you’ve added them, the list will appear here.`,
  },
  projects: {
    heading: `You haven’t added a project yet!`,
    text: `Please add a new project by clicking on the “Add New Project"
    button. Once you’ve added them, the list will appear here.`,
  },
  developments: {
    heading: `You haven’t added a development yet!`,
    text: `Please add a new development by clicking on the “Add New Development"
    button. Once you’ve added them, the list will appear here.`,
  },
  jobCosts: {
    heading: `You haven’t added a Job Cost Report yet!`,
    text: "",
  },
  paymentSummary: {
    heading: `You haven’t added a Payment Summary yet!`,
    text: "",
  },
  planTakeoffs: {
    heading: `You haven’t added a plan takeoff yet!`,
    text: `Please add a new plan takeoff by clicking on the “Add New Plan Takeoff"
    button. Once you’ve added them, the list will appear here.`,
  },
  planTakeoffAssemblie: {
    heading: `You haven’t added an assemblie yet!`,
    text: `Please add a new assemblies"
    button. Once you’ve added them, the list will appear here.`,
  },
  projectTakeoffs: {
    heading: `You haven’t added a project takeoff yet!`,
    text: `Please add a new project takeoff by clicking on the “Add New Project Takeoff"
    button. Once you’ve added them, the list will appear here.`,
  },
};
