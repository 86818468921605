export const MASTER_FLOORPLANS_NAME = "masterFloorplans";

export const MASTER_FLOORPLANS = {
  TITLE: "Master Floorplans",
  NEW_MODAL_TITLE: "Add New Floorplan",
  NEW_MODAL_PLUS_BUTTON: "Add New Floorplan",
  NEW_MODAL_SUBMIT_BUTTON: "Add New Floorplan",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Floorplan",
};

export const filterOptions = ["-All-", "Active", "Under Revision", "Inactive"];
