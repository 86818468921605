import { useState } from "react";
import ModalManager from "../ModalManager";
import { MASTER_ROLES_NAME } from "resource/constants/master-roles";
import { ActionName } from "resource/constants/api";
import useAsync from "custom-hooks/useAsync";

// ? REDUX
import { deleteMasterRoleAction } from "redux-store/redux-actions/masterRoles";

// ? COMPONENTS
import { Typography, IconButton, Button, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import useModal from "custom-hooks/useModal";

// ? STYLES
import styles from "./Delete.module.css";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";

export const nameDelete = `${MASTER_ROLES_NAME}/${ActionName.DELETE}`;

const MasterRolesDelete = () => {
  const { close, data } = useModal(nameDelete);
  const [isLoading, setLoader] = useState(false);
  const theme = useTheme();

  const deleteRole = useAsync(deleteMasterRoleAction);

  const handleDeleteRole = async () => {
    setLoader(true);
    try {
      await deleteRole(data.idnumber);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameDelete}>
      <div className={styles.DeleteModal}>
        <div className={styles.DeleteBox}>
          <div className={styles.FormHeader}>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`Delete Master Role?`}
            </Typography>
            <IconButton className={styles.CloseButton} onClick={close}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </div>
          <Typography color={theme.palette.text.secondary}>
            {`Are you sure you want to delete "${data.name}"? Once you delete it, you won’t be able to see it again.`}
          </Typography>
          <div className={styles.FormFooter}>
            <Button onClick={close} className={styles.CancelButton}>
              Cancel
            </Button>
            <SubmitButton callback={() => handleDeleteRole()} large type="delete" text="Delete" isLoading={isLoading} />
          </div>
        </div>
      </div>
    </ModalManager>
  );
};

export default MasterRolesDelete;
