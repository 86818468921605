import { useLocation, Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, CircularProgress, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";

export interface CardPropsTypes {
  fake?: boolean;
  name: string;
  marketing: string;
  revision: number;
  status: string;
  idnumber: string | number;
}

export const MasterFloorplansListCard = ({ idnumber, name, marketing, revision, status, fake }: CardPropsTypes) => {
  const theme = useTheme();
  const location = useLocation();

  if (fake) {
    return (
      <div className="Fake">
        <div className="Cover Disabled">
          <CircularProgress size={25} className="Loader" />
        </div>
        <TableListRow>
          <div></div>
        </TableListRow>
      </div>
    );
  }

  return (
    <div>
      <Link to={`${location.pathname}/${idnumber}`}>
        <TableListRow>
          <Grid item xs={1.5} md={1.5}>
            <Typography color={theme.palette.text.primary}>{idnumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color={theme.palette.text.primary}>{name}</Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography color={theme.palette.text.primary}>{marketing}</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Typography color={theme.palette.text.primary}>{revision}</Typography>
          </Grid>
          <Grid item xs={1.5} md={1.5}>
            <Typography color={theme.palette.text.primary}>{status}</Typography>
          </Grid>
        </TableListRow>
      </Link>
    </div>
  );
};
