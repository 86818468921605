import { takeLatest, put } from "redux-saga/effects";
import { JOB_COST_API } from "resource/constants/api";
import { get, catchError } from "./_utils-abstract-saga-network";

import { JobCostActions, putJobCostSublistAction, putJobCostsAction } from "../redux-actions/jobCost";

import { setPaginationLoaderAction, setGlobalLoaderAction } from "../redux-actions/loaders";

function* fetchJobCostsWorker(action) {
  const {
    payload: {
      additionalData: { filter, keywords },
      page,
    },
  } = action;

  yield put(setPaginationLoaderAction(true));

  const params = { pageIndex: page, pageSize: 100 };

  const { errno, message, data } = yield get(`${JOB_COST_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(filter && { filter }),
  });

  if (errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(putJobCostsAction({ data: data.list, count: data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchJobCostSublistWorker(action) {
  const {
    payload: { idnumber, idcia },
  } = action;

  const params = { idcia, idproject: idnumber };
  yield put(setGlobalLoaderAction(true));
  const { errno, message, data } = yield get(`${JOB_COST_API}/sublist`, { ...params });

  if (errno !== 0) {
    yield catchError(message);
    yield put(setGlobalLoaderAction(false));
  } else {
    yield put(putJobCostSublistAction(data));
    yield put(setGlobalLoaderAction(false));
  }
}

export function* jobCostSaga() {
  yield takeLatest(JobCostActions.FETCH_JOB_COSTS, fetchJobCostsWorker);
  yield takeLatest(JobCostActions.FETCH_JOB_COST_SUBLIST, fetchJobCostSublistWorker);
}
