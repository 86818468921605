import { menu } from "resource/constants/menu-list";
import { COST_CODES } from "./cost-codes";
import { MEMBERS_PROFILE } from "./members-profile";
import { MASTER_ROLES } from "./master-roles";
import { PLAN_VARIABLES } from "./plan-variables";
import { PARTS } from "./parts";
import { PLAN_DRAWER } from "./plan-drawer";
import { VENDORS } from "./vendors";
import { ASSEMBLIES } from "./assemblies";
import { PURCHASE_ORDER } from "./purchase-order";
import { VENDOR_PRICING } from "./vendorPricing";
import { MASTER_FLOORPLANS } from "./master-floorplan";
import { CONTRACTS } from "./contracts";
import { PROJECTS } from "./projects";
import { JOB_COST } from "./job-cost";
import { CHANGE_ORDERS } from "./change-order";
import { DEVELOPMENTS } from "./developments";
import { PAYMENT_SUMMARY } from "./payment-summary";
import { TAKEOFFS } from "./takeoffs";

export const pageHeaderContent = {
  costCodes: {
    heading: COST_CODES.TITLE,
    icon: menu.costCodes.icon,
    text: "Your added cost codes list will be shown below",
    buttonText: COST_CODES.NEW_MODAL_PLUS_BUTTON,
  },
  masterRoles: {
    heading: MASTER_ROLES.TITLE,
    icon: menu.masterRole.icon,
    text: "Your added roles list will be shown below",
    buttonText: MASTER_ROLES.NEW_MODAL_PLUS_BUTTON,
  },
  membersProfile: {
    heading: MEMBERS_PROFILE.TITLE,
    icon: menu.membersProfile.icon,
    text: "Your added member profiles list will be shown below",
    buttonText: MEMBERS_PROFILE.NEW_MODAL_PLUS_BUTTON,
  },
  planVariables: {
    heading: PLAN_VARIABLES.TITLE,
    icon: menu.planVariable.icon,
    text: "Your added plan variables list will be shown below",
    buttonText: PLAN_VARIABLES.NEW_MODAL_PLUS_BUTTON,
  },
  planDrawer: {
    heading: PLAN_DRAWER.TITLE,
    icon: menu.planDrawer.icon,
    text: "Your added base plans profiles list will be shown below",
    buttonText: PLAN_DRAWER.NEW_MODAL_PLUS_BUTTON,
  },
  parts: {
    heading: PARTS.TITLE,
    icon: menu.parts.icon,
    text: "Your added parts list will be shown below",
    buttonText: PARTS.NEW_MODAL_TITLE,
  },
  vendors: {
    heading: VENDORS.TITLE,
    icon: menu.vendors.icon,
    text: "Your added vendors list will be shown below",
    buttonText: VENDORS.NEW_MODAL_PLUS_BUTTON,
  },
  assemblies: {
    heading: ASSEMBLIES.TITLE,
    icon: menu.assemblies.icon,
    text: "Your added assemblies list will be shown below",
    buttonText: ASSEMBLIES.NEW_MODAL_PLUS_BUTTON,
  },
  purchaseOrder: {
    heading: PURCHASE_ORDER.TITLE,
    icon: menu.purchaseOrder.icon,
    text: "Your added purchse order list will be shown below",
    buttonText: PURCHASE_ORDER.NEW_MODAL_PLUS_BUTTON,
  },
  vendorPricing: {
    heading: VENDOR_PRICING.TITLE,
    icon: menu.vendorPricing.icon,
    text: "Your added vendor pricing list will be shown below",
    buttonText: VENDOR_PRICING.NEW_MODAL_PLUS_BUTTON,
  },
  vendorPricingDetails: {
    heading: VENDOR_PRICING.TITLE,
    icon: "/images/arrow-left.svg",
    text: "Your added vendor pricing details list will be shown below",
    buttonText: VENDOR_PRICING.NEW_MODAL_PLUS_BUTTON,
  },
  masterFloorplans: {
    heading: MASTER_FLOORPLANS.TITLE,
    icon: menu.masterFloorplans.icon,
    text: "Your added floorplans list will be shown below",
    buttonText: MASTER_FLOORPLANS.NEW_MODAL_PLUS_BUTTON,
  },
  masterFloorplansDetails: {
    heading: MASTER_FLOORPLANS.TITLE,
    icon: "/images/arrow-left.svg",
    text: "",
    buttonText: MASTER_FLOORPLANS.NEW_MODAL_PLUS_BUTTON,
  },
  contracts: {
    heading: CONTRACTS.TITLE,
    icon: menu.contracts.icon,
    text: "Your added contracts list will be shown below",
    buttonText: CONTRACTS.NEW_MODAL_PLUS_BUTTON,
  },
  projects: {
    heading: PROJECTS.TITLE,
    icon: menu.projectPage.icon,
    text: "Your added projects list will be shown below",
    buttonText: PROJECTS.NEW_MODAL_PLUS_BUTTON,
  },
  changeOrders: {
    heading: CHANGE_ORDERS.TITLE,
    icon: menu.changeOrders.icon,
    text: "Your added change orders list will be shown below",
    buttonText: CHANGE_ORDERS.NEW_MODAL_PLUS_BUTTON,
  },
  jobCosts: {
    heading: JOB_COST.TITLE,
    icon: menu.jobCostReport.icon,
    text: "Your added job cost reports list will be shown below",
  },
  developments: {
    heading: DEVELOPMENTS.TITLE,
    icon: menu.developments.icon,
    text: "Your added developments list will be shown below",
  },
  paymentSummary: {
    heading: PAYMENT_SUMMARY.TITLE,
    icon: menu.paymentSummary.icon,
    text: "Your added payment summary list will be shown below",
  },
  planTakeoffs: {
    heading: TAKEOFFS.PLAN_TITLE,
    icon: menu.planDrawer.icon,
    text: "Your added plan takeoffs list will be shown below",
  },
  projectTakeoffs: {
    heading: TAKEOFFS.PROJECT_TITLE,
    icon: menu.projectPage.icon,
    text: "Your added project takeoffs list will be shown below",
  },
};
