import { OPEN_MODAL, CLOSE_MODAL } from "redux-store/redux-actions/modals";

interface stateInterface {
  name: string;
  data: Record<string, unknown>;
}

const initialState: stateInterface = {
  name: "",
  data: {},
};

export const modalsReducer = (state = initialState, action): stateInterface => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_MODAL:
      return { ...state, name: payload.name, data: payload.data };
    case CLOSE_MODAL:
      return {
        ...state,
        name: "",
        data: {},
      };
    default:
      return state;
  }
};
