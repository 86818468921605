import React, { FC } from "react";

// ? COMPONENTS
import { Modal as MUIModal } from "@mui/material";
import useModal from "custom-hooks/useModal";

interface ModalProps {
  name: string;
  className?: string;
  children: React.ReactNode;
  ariaLabelledBy?: string;
  ariaDescibedBy?: string;
}

const ModalManager: FC<ModalProps> = ({ name, className, children, ariaLabelledBy, ariaDescibedBy }) => {
  const { close, isOpened } = useModal(name);

  return (
    <MUIModal
      className={className}
      open={isOpened}
      onClose={close}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescibedBy}
    >
      <>{children}</>
    </MUIModal>
  );
};

export default ModalManager;
