import { useState, useEffect, FC } from "react";

// ? REDUX
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { fetchChangeOrderByIdAction } from "redux-store/redux-actions/changeOrders";
import { clearFileServerDataAction } from "redux-store/redux-actions/fileServer";

// ? COMPONENTS
import Notes from "components/shared/notes/Notes";
import ChangeOrderDetailList from "./ChangeOrderDetailList";
import ChangeOrderDetailHeader from "./ChangeOrderDetailHeader";
import ChangeOrderDetailSummary from "./ChangeOrderDetailSummary";
import ChangeOrderVoidConfirm from "components/modals/confirm/ChangeOrderVoidConfirm";

import ChangeOrderFileServer from "components/modals/file-server-modal/ChangeOrderFileServer";
import ChangeOrderUpdate from "components/modals/update/ChangeOrderUpdate";
import { Grid, CircularProgress, Typography, useTheme } from "@mui/material";
import { withLayout } from "hoc/withLayout";

// ? RESOURCES
import { CHANGE_ORDERS_NAME } from "resource/constants/change-order";
import { StatusList } from "resource/consts";
import { ChangeOrderDetailResponse } from "resource/interfaces/change-order";
import { getFormattedDateTime } from "resource/utils";

// ? STYLES
import styles from "./ChangeOrder.module.css";

interface ChangeOrderDetailProps {
  id: string;
}

const ChangeOrderDetail: FC<ChangeOrderDetailProps> = ({ id }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loader, setLoader] = useState(true);
  const order: ChangeOrderDetailResponse = useSelector((state: RootStateOrAny) => state.changeOrders.changeOrderItems[id]);

  useEffect(() => {
    if (order) {
      setLoader(false);
    }
  }, [order]);

  useEffect(() => {
    setLoader(true);
    dispatch(fetchChangeOrderByIdAction(Number(id)));

    return () => {
      dispatch(clearFileServerDataAction());
    };
  }, []);

  return (
    <>
      {!loader && order && (
        <>
          <ChangeOrderDetailHeader order={order} />

          {order.notes.length > 0 && <Notes notes={order.notes} />}
          <Grid container sx={{ my: 3 }} spacing={2}>
            <Grid item xs={2} className={styles.ChangeOrderDivider}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                Project ID:
              </Typography>
              <Typography variant="body1" color={theme.palette.info.main}>
                {order.idproject1}
              </Typography>
            </Grid>
            <Grid item xs={2} className={styles.ChangeOrderDivider}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                Created by:
              </Typography>
              <Typography variant="body1" color={theme.palette.info.main}>
                {order.creaby}
              </Typography>
            </Grid>
            <Grid item xs={2} className={styles.ChangeOrderDivider}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                Order Date:
              </Typography>
              <Typography variant="body1" color={theme.palette.info.main}>
                {getFormattedDateTime(order.refdate)}
              </Typography>
            </Grid>
          </Grid>
          <ChangeOrderDetailList items={order.list} />
          {order.status !== StatusList.VOID && <ChangeOrderDetailSummary order={order} />}
          <ChangeOrderVoidConfirm />
          <ChangeOrderFileServer />
          <ChangeOrderUpdate className={styles.Modal} />
        </>
      )}

      {loader && (
        <div className={`LoaderScroll  center`}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default withLayout(ChangeOrderDetail);
