//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { CONTRACTS_NAME } from "resource/constants/contracts";
import { CONTRACTS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${CONTRACTS_NAME}/uploadFile`;

const ContractFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    name: data.idproject,
  };

  const dataForDelete = {
    name: data.idproject,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Contract-${data.idnumber} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={data.fs}
      api={CONTRACTS_API}
    />
  );
};

export default ContractFileServer;
