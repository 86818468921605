import { useSelector, RootStateOrAny } from "react-redux";

const useAccess = (menuItem: number) => {
  const {
    xadd: hasAddAccess,
    xedit: hasEditAccess,
    xdel: hasDeleteAccess,
  } = useSelector((state: RootStateOrAny) => state.login.menuList[menuItem]);

  return { hasAddAccess, hasEditAccess, hasDeleteAccess };
};

export default useAccess;
