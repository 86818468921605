import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";

import { formatPrice, resolvePath } from "resource/utils";
import { ROUTES } from "resource/routes/routes";

interface PaymentSummaryItemsProps {
  paymentSummary: any;
}

const PaymentSummaryItem: FC<PaymentSummaryItemsProps> = ({ paymentSummary }) => {
  const theme = useTheme();
  const mainPath = ROUTES.paymentSummary.pathname;
  const subDirectories = [paymentSummary.idcia, paymentSummary.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{paymentSummary.idnumber}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.text.primary}>{paymentSummary.name}</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary}>{paymentSummary.geoarea_name}</Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography color={theme.palette.text.primary}>{paymentSummary.planname}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{paymentSummary.status}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {paymentSummary.xopen > 0 && formatPrice(String(paymentSummary.xopen))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {paymentSummary.takeoff > 0 && formatPrice(String(paymentSummary.takeoff))}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={theme.palette.text.primary} justifyContent="flex-end" display="flex">
            {paymentSummary.xtotal > 0 && formatPrice(String(paymentSummary.xtotal))}
          </Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default PaymentSummaryItem;
