//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { importProjectTakeoffAction } from "redux-store/redux-actions/planTakeoffs";
import { CircularProgress } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import TakeoffsImportProjectForm from "pages/takeoffs/TakeoffsImportProjectForm";

// ? RESOURCES
import { PLAN_TAKEOFFS_NAME, TAKEOFFS, TakeoffEndpoints } from "resource/constants/takeoffs";
import { ActionName } from "resource/constants/api";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
import { nameConfirm } from "components/modals/confirm/TakeoffImportConfirm";
export const nameAddProjectImport = `${PLAN_TAKEOFFS_NAME}ProjectImport/${ActionName.CREATE}`;

const initFormValues = {
  idplan1: "",
};

const PlanTakeoffProjectImport = () => {
  const { close, isOpened, data } = useModal(nameAddProjectImport);
  const { open: openImportConfirm } = useModal(nameConfirm);
  const importProjectTakeoff = useAsync(importProjectTakeoffAction);

  const handleSubmit = (formData) => {
    close();

    setTimeout(
      () =>
        openImportConfirm({
          valuesForSubmit: { idcia: String(formData.idcia), idproject1: formData.idproject1, idplan1: data.id },
          endpoint: TakeoffEndpoints.ImportFromProject,
          action: importProjectTakeoff,
          dataForFetch: { id: data.id },
        }),
      150
    );
  };

  return (
    <ModalManager name={nameAddProjectImport}>
      {isOpened ? (
        <>
          <div className={styles.AddForm}>
            <TakeoffsImportProjectForm
              title={TAKEOFFS.IMPORT_PROJECT}
              formData={initFormValues}
              buttonText="Save"
              handleClose={close}
              actionType={ActionName.CREATE}
              handleSubmit={handleSubmit}
              isLoading={false}
            />
          </div>
        </>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default PlanTakeoffProjectImport;
