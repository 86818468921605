export const PLAN_VARIABLES_NAME = "planVariables";

export const PLAN_VARIABLES = {
  TITLE: "Plan Variable",
  NEW_MODAL_TITLE: "Add Variable",
  EDIT_MODAL_TITLE: "Edit Variable",
  NEW_MODAL_PLUS_BUTTON: "Add Variable",
  NEW_MODAL_SUBMIT_BUTTON: "Add Variables",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Variables",
};

export const groups = [
  {
    fields: [
      {
        title: "Variables",
        placeholder: "Enter variables name",
        name: "name",
        size: "100%",
        type: "text",
      },
      {
        title: "Type",
        placeholder: "active",
        name: "xtype",
        size: "47%",
        type: "select",
      },
      {
        title: "Default Value",
        placeholder: "Enter Number",
        name: "xvalue",
        size: "47%",
        type: "text",
      },
      {
        title: "Sort",
        placeholder: "Enter number",
        name: "sort",
        size: "47%",
        type: "text",
      },
      {
        title: "Variable ID",
        placeholder: "Enter Variable ID",
        name: "varname",
        size: "47%",
        type: "text",
      },
    ],
  },
];
