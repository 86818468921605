import { useEffect } from "react";

// ? REDUX
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { clearDataAction } from "redux-store/redux-actions/planDrawer";

// ? COMPONENTS
import { Box, CircularProgress } from "@mui/material";
import { PlanDrawerListHeader } from "./PlanDrawerListHeader";
import { PlanDrawerListCard } from "./PlanDrawerListCard";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

// ? STYLES
import styles from "./PlanDrawer.module.css";

export const PlanDrawerList = ({ type, content, setCategory }) => {
  const dispatch = useDispatch();

  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  useEffect(() => {
    setCategory(type);

    return () => {
      dispatch(clearDataAction());
    };
  }, []);

  if (!paginationLoading && !content.length) {
    return <NoDataScreen pageName="basePlans" />;
  }

  return (
    <Box className={styles.BasePlansList}>
      <PlanDrawerListHeader />
      {addNewLoader && (
        <PlanDrawerListCard
          idnumber={""}
          name={""}
          type={""}
          idtrack={0}
          category={""}
          fake
        />
      )}
      {content.length > 0 &&
        content.map((plan, index) => (
          <PlanDrawerListCard
            key={index}
            idnumber={plan.idnumber}
            name={plan.name}
            type={plan.xtype}
            idtrack={plan.idtrack}
            category={plan.xcategory.toLowerCase()}
          />
        ))}
      {paginationLoading && (
        <div className={`LoaderScroll ${!content.length && "center"}`}>
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};
