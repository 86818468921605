import { FC } from "react";
import { Link } from "react-router-dom";

// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListRow } from "components/shared/table-list/TableList";

import { formatPrice, resolvePath } from "resource/utils";
import { ROUTES } from "resource/routes/routes";

interface JobCostItemsProps {
  jobCost: any;
}

const JobCostItem: FC<JobCostItemsProps> = ({ jobCost }) => {
  const theme = useTheme();
  const mainPath = ROUTES.jobCostReport.pathname;
  const subDirectories = [jobCost.idcia, jobCost.idnumber];

  return (
    <Link to={resolvePath(mainPath, subDirectories)}>
      <TableListRow>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>{jobCost.idnumber}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={theme.palette.text.primary}>{jobCost.name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.budget !== 0 && formatPrice(String(jobCost.budget))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.incurred !== 0 && formatPrice(String(jobCost.incurred))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.openpo !== 0 && formatPrice(String(jobCost.openpo))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.jobcost !== 0 && formatPrice(String(jobCost.jobcost))}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography color={jobCost.overbudget <= 0 ? theme.palette.text.primary : theme.palette.error.light}>
            {jobCost.overbudget !== 0 && formatPrice(String(jobCost.overbudget))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.jobcostsqft !== 0 && formatPrice(String(jobCost.jobcostsqft))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.budgetsqft !== 0 && formatPrice(String(jobCost.budgetsqft))}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography color={theme.palette.text.primary}>
            {jobCost.jobcostsqft !== 0 && formatPrice(String(jobCost.jobcostsqft))}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography color={theme.palette.text.primary}>{jobCost.status}</Typography>
        </Grid>
      </TableListRow>
    </Link>
  );
};

export default JobCostItem;
