import { takeLatest, put, all } from "redux-saga/effects";
import { ASSEMBLIES_API } from "resource/constants/api";
import { post, get, catchError } from "./_utils-abstract-saga-network";

import {
  AssembliesActions,
  getAssembliesAction,
  getAssemblingAction,
  putAssemblingAction,
  deleteAssemblingStoreAction,
  fetchAssemblingAction,
  putAssembliesTypeListAction,
  putEditAssemblyAction,
  putAssembliesFilterListAction,
} from "../redux-actions/assemblies";

import {
  setGlobalLoaderAction,
  setButtonLoaderAction,
  setPaginationLoaderAction,
  setAddNewLoaderAction,
  setModalLoaderAction,
} from "../redux-actions/loaders";

import { clearNotifierAction } from "redux-store/redux-actions/notifier";

function* fetchAssembliesWorker(action: {
  type: string;
  payload: { additionalData: { keywords: string; filter: string }; page: number };
}) {
  yield put(setPaginationLoaderAction(true));

  const {
    payload: {
      additionalData: { keywords, filter },
      page,
    },
  } = action;

  const params = { pageIndex: page, pageSize: 20 };

  const typesRequest = get(`${ASSEMBLIES_API}/typeList`);
  const filtersRequest = get(`${ASSEMBLIES_API}/filterList`);
  const assembliesRequest = get(`${ASSEMBLIES_API}/list`, {
    ...params,
    ...(keywords && { keywords }),
    ...(filter && { filter }),
  });

  const [typesInfo, filtersInfo, assembliesInfo] = yield all([typesRequest, filtersRequest, assembliesRequest]);

  if (typesInfo.errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(typesInfo.message);
  } else if (assembliesInfo.errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(assembliesInfo.message);
  } else if (filtersInfo.errno !== 0) {
    yield put(setPaginationLoaderAction(false));
    yield catchError(filtersInfo.message);
  } else {
    yield put(putAssembliesTypeListAction(typesInfo.data));
    yield put(putAssembliesFilterListAction(filtersInfo.data));
    yield put(getAssembliesAction({ data: assembliesInfo.data.list, count: assembliesInfo.data.count }));
    yield put(setPaginationLoaderAction(false));
  }
}

function* fetchAssemblingWorker(action) {
  const {
    payload: { idnumber, mode, loader, setLocalLoading },
  } = action;

  yield loader && put(loader(true));

  const { errno, message, data } = yield get(`${ASSEMBLIES_API}/${idnumber}`);

  if (errno !== 0) {
    yield loader && put(loader(false));
    yield setLocalLoading && setLocalLoading(false);
    yield catchError(message);
  } else {
    yield put(mode === "create" ? getAssemblingAction(data) : putAssemblingAction(data));
    yield loader && put(loader(false));
    yield setLocalLoading && setLocalLoading(false);
  }
}

function* addNewAssemblingWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const {
    data: { nextId },
  } = yield get(`${ASSEMBLIES_API}/getNextId`);

  values.idnumber = nextId;

  values.discontinue = true;

  const { errno, message } = yield post(`${ASSEMBLIES_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Add assembling successfull");
    yield put(fetchAssemblingAction({ idnumber: nextId, mode: "create", loader: setAddNewLoaderAction }));
    yield put(clearNotifierAction());
  }
}

function* editAssemblingWorker(action) {
  const {
    payload: { values, actionType },
    promise: { resolve, reject },
  } = action;

  const { errno, message } = yield post(`${ASSEMBLIES_API}/${actionType}`, values, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Edit assembling successfull");
    yield put(fetchAssemblingAction({ idnumber: values.idnumber, mode: "edit", loader: setGlobalLoaderAction }));
    yield put(clearNotifierAction());
  }
}

function* deleteAssemblingWorker(action) {
  const {
    payload,
    promise: { resolve, reject },
  } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message } = yield post(`${ASSEMBLIES_API}/delete`, payload, "json");

  if (errno !== 0) {
    reject(message);
    yield catchError(message);
  } else {
    resolve("Delete assembling successfull");
    yield put(deleteAssemblingStoreAction({ idnumber: payload.idnumber }));
    yield put(clearNotifierAction());
  }
}

function* fetchEditAssemblyWorker(action: {
  type: string;
  payload: {
    idnumber: number;
  };
}) {
  const {
    payload: { idnumber },
  } = action;

  yield put(setModalLoaderAction(true));

  const { errno, message, data } = yield get(`${ASSEMBLIES_API}/${idnumber}`);

  if (errno !== 0) {
    yield put(setModalLoaderAction(false));
    yield catchError(message);
  } else {
    console.log("User uploaded");
    yield put(putEditAssemblyAction(data));
    yield put(clearNotifierAction());
    yield put(setModalLoaderAction(false));
  }
}

export function* assembliesSaga() {
  yield takeLatest(AssembliesActions.FETCH_ASSEMBLIES, fetchAssembliesWorker);
  yield takeLatest(AssembliesActions.FETCH_ASSEMBLING_BY_ID, fetchAssemblingWorker);
  yield takeLatest(AssembliesActions.ADD_NEW_ASSEMBLING, addNewAssemblingWorker);
  yield takeLatest(AssembliesActions.EDIT_ASSEMBLING, editAssemblingWorker);
  yield takeLatest(AssembliesActions.DELETE_ASSEMBLING_FROM_SERVER, deleteAssemblingWorker);
  yield takeLatest(AssembliesActions.ASSEMBLY_ACTIVATION, editAssemblingWorker);
  yield takeLatest(AssembliesActions.FETCH_EDIT_ASSEMBLEY, fetchEditAssemblyWorker);
}
