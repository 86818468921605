import React from "react";

const HighlightedText = ({ text, searchQuery }) => {
  const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));

  return (
    <span>
      {parts.map((part, i) => (
        <span key={`${text}${i}`} style={part.toLowerCase() === searchQuery.toLowerCase() ? { color: "red" } : {}}>
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightedText;
