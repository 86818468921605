export const MEMBERS_PROFILE_NAME = "user";

export const MEMBERS_PROFILE = {
  TITLE: "Members Profile",
  NEW_MODAL_TITLE: "New Member Profile",
  EDIT_MODAL_TITLE: "Edit Member Profile",
  NEW_MODAL_PLUS_BUTTON: "Add New Member",
  NEW_MODAL_SUBMIT_BUTTON: "Add Member",
  EDIT_MODAL_SUBMIT_BUTTON: "Update Member Information",
};

export const filterOptions = ["-All-", "Active", "Inactive"];
