import { useState, FC } from "react";

// ? REDUX
import { editCompanyAction } from "redux-store/redux-actions/companies";

//  ?  COMPONENTS
import { CircularProgress } from "@mui/material";
import { CompaniesForm } from "pages/companies/CompaniesForm";
import ModalManager from "../ModalManager";

// ? STYLES
import styles from "pages/companies/Companies.module.css";

// ? RESOURCES
import useModal from "custom-hooks/useModal";
import useAsync from "custom-hooks/useAsync";
import { COMPANIES, COMPANIES_NAME } from "resource/constants/companies";
import { ActionName } from "resource/constants/api";
import { useNotice } from "custom-hooks/useNotice";
import { noticeTypes } from "resource/consts";

export const nameUpdate = `${COMPANIES_NAME}/${ActionName.UPDATE}`;


const CompaniesUpdate: FC = () => {
  const { close, isOpened, data } = useModal(nameUpdate);
  const [isLoading, setLoader] = useState(false);
  const updateCopmany = useAsync(editCompanyAction);
  const [setNotice] = useNotice();

  // SUBMIT
  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      await updateCopmany({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setNotice("Company updated", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <ModalManager name={nameUpdate} className={styles.Modal}>
      {isOpened ? (
        <div className={styles.DevelopmentsAddForm}>
          <CompaniesForm
            title={COMPANIES.EDIT_MODAL_TITLE}
            buttonText={COMPANIES.EDIT_MODAL_SUBMIT_BUTTON}
            formData={data}
            handleClose={close}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default CompaniesUpdate;
