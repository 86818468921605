// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

const useHeaders = () => {
  const { tokenAPI, tokenSecret } = useSelector((state: RootStateOrAny) => state.login.userData);

  return {
    Accept: "application/json",
    "Token-Api": tokenAPI,
    "Token-Secret": tokenSecret,
    "Content-Type": "application/x-www-form-urlencoded",
  };
};

export default useHeaders;
