// ? COMPONENTS
import { TextField, InputAdornment } from "@mui/material";
import icon from "resource/images/search-icon.svg";

// ? STYLES
import styles from "./Search.module.css";

// ? TYPES
interface SearchFieldProps {
  value: string | null | undefined;
  placeholder: string;
  handleChange: (query: any) => void;
}

export const Search = ({ value, placeholder, handleChange }: SearchFieldProps) => {
  return (
    <TextField
      className={styles.Search}
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={icon} alt="search-icon" />
          </InputAdornment>
        ),
      }}
    />
  );
};
