import * as Yup from "yup";

export const projectTakeoffNotesSchema = Yup.object().shape({
  notes: Yup.string().max(50),
});

export const planTakeoffAssembliesSchema = Yup.object().shape({
  idnumber: Yup.string().required("Assemblies field cannot be empty").nullable(),
});

export const projectTakeoffBudgetSchema = Yup.object().shape({
  notes: Yup.string().max(50),
  budgetrevised: Yup.number().required("Required"),
});

export const projectTakeoffPartSchema = Yup.object().shape({
  notes: Yup.string().required("Required").max(50),
  qty: Yup.number().required("Required").moreThan(0),
});

export const projectTakeoffAddPartSchema = Yup.object().shape({
  upc: Yup.string().required("Part is Required"),
  notes: Yup.string().required("Notes are Required").max(50),
  qty: Yup.number().required("Quantity is Required").moreThan(0),
  lumber: Yup.string().required("Lumber is Required"),
});

export const projectTakeoffPartQtyUpdateSchema = Yup.object().shape({
  // qty: Yup.number().required("Required").moreThan(0),
});

export const projectTakeoffPartNotesUpdateSchema = Yup.object().shape({
  // notes: Yup.string().required("Required").max(50),
});
