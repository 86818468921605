import * as Yup from "yup";

export const changeOrderUpdateSchema = Yup.object().shape({
  notes: Yup.string().label("Notes").max(255),
});

export const changeOrderCreateSchema = Yup.object().shape({
  idproject1: Yup.string().required("Project field cannot be empty").nullable(),
  notes: Yup.string().label("Notes").max(255),
});
