export const menu = {
  database: {
    text: "Database",
    icon: "/images/nav-icons/database/database.svg",
    idmenu: 1,
  },
  customFields: {
    text: "Custom Fields",
    idmenu: 1.1,
  },
  companies: {
    text: "Companies",
    icon: "/images/placeholder.svg",
    pathname: "/database/companies",
    idmenu: 1.01,
  },
  masterRole: {
    text: "Master Roles",
    icon: "/images/nav-icons/database/master-roles.svg",
    pathname: "/database/master-roles",
    idmenu: 1.02,
  },
  membersProfile: {
    text: "Members Profile",
    icon: "/images/nav-icons/database/members-profile.svg",
    pathname: "/database/members-profile",
    idmenu: 1.03,
  },
  costCodes: {
    text: "Cost Codes",
    icon: "/images/nav-icons/database/cost-codes.svg",
    pathname: "/database/cost-codes",
    idmenu: 1.05,
  },
  planVariable: {
    text: "Plan Variable",
    icon: "/images/nav-icons/database/plan-variables.svg",
    pathname: "/database/plan-variables",
    idmenu: 1.08,
  },
  planDrawer: {
    text: "Plan Drawer",
    icon: "/images/nav-icons/database/plan-drawer.svg",
    pathname: "/database/plan-drawer",
    idmenu: 1.09,
  },
  accounting: {
    text: "Accounting",
    icon: "/images/nav-icons/accounting/accounting.svg",
    idmenu: 2,
  },
  vendors: {
    text: "Vendors",
    icon: "/images/nav-icons/accounting/vendors.svg",
    pathname: "/accounting/vendors",
    idmenu: 4.01,
  },
  purchasing: {
    text: "Purchasing",
    icon: "/images/nav-icons/purchasing/purchasing.svg",
    idmenu: 4,
  },
  parts: {
    text: "Parts",
    icon: "/images/nav-icons/purchasing/parts.svg",
    pathname: "/purchasing/parts",
    idmenu: 4.02,
  },
  vendorPricing: {
    text: "Vendor Pricing",
    icon: "/images/nav-icons/purchasing/vendor-pricing.svg",
    pathname: "/purchasing/vendor-pricing",
    idmenu: 4.03,
  },
  assemblies: {
    text: "Assemblies",
    icon: "/images/nav-icons/purchasing/assemblies.svg",
    pathname: "/purchasing/assemblies",
    idmenu: 4.04,
  },
  planTakeoff: {
    text: "Plan Takeoff",
    icon: "/images/nav-icons/database/plan-drawer.svg",
    pathname: "/purchasing/plan-takeoff",
    idmenu: 4.05,
  },
  projectTakeoff: {
    text: "Project Takeoff",
    icon: "/images/nav-icons/projects/projects-sub.svg",
    pathname: "/purchasing/project-takeoff",
    idmenu: 4.06,
  },
  purchaseOrder: {
    text: "Purchase Order",
    icon: "/images/nav-icons/purchasing/purchase-order.svg",
    pathname: "/purchasing/purchase-order",
    idmenu: 4.07,
  },
  projects: {
    text: "Projects",
    icon: "/images/nav-icons/projects/projects.svg",
    idmenu: 5,
  },
  masterFloorplans: {
    text: "Master Floorplans",
    icon: "/images/nav-icons/projects/floorplan.svg",
    pathname: "/projects/master-floorplans",
    idmenu: 5.01,
  },
  developments: {
    text: "Developments",
    icon: "/images/nav-icons/projects/developments.svg",
    pathname: "/projects/developments",
    idmenu: 5.02,
  },
  projectPage: {
    text: "Project",
    icon: "/images/nav-icons/projects/projects-sub.svg",
    pathname: "/projects/project",
    idmenu: 5.03,
  },
  changeOrders: {
    text: "Change Orders",
    icon: "/images/nav-icons/projects/change-orders.svg",
    pathname: "/projects/change-orders",
    idmenu: 5.04,
  },
  contracts: {
    text: "Contracts",
    icon: "/images/nav-icons/projects/contracts.svg",
    pathname: "/projects/contracts",
    idmenu: 5.05,
  },
  paymentSummary: {
    text: "Payment Summary",
    icon: "/images/nav-icons/projects/payment-summary.svg",
    pathname: "/projects/payment-summary",
    idmenu: 5.07,
  },
  jobCostReport: {
    text: "Job Cost Report",
    icon: "/images/nav-icons/projects/job-cost-report.svg",
    pathname: "/projects/job-cost-report",
    idmenu: 5.08,
  },
};

export const menuList = [
  {
    ...menu.database,
    subMenu: [menu.companies, menu.masterRole, menu.membersProfile, menu.costCodes, menu.planVariable, menu.planDrawer],
  },
  {
    ...menu.accounting,
    subMenu: [menu.vendors],
  },
  {
    ...menu.purchasing,
    subMenu: [menu.parts, menu.vendorPricing, menu.assemblies, menu.purchaseOrder, menu.planTakeoff, menu.projectTakeoff],
  },
  {
    ...menu.projects,
    subMenu: [
      menu.masterFloorplans,
      menu.developments,
      menu.projectPage,
      menu.changeOrders,
      menu.contracts,
      menu.paymentSummary,
      menu.jobCostReport,
    ],
  },
];

export const menuCollection = Object.values(menu);
