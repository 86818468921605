import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { history } from "./redux-reducers/rootReducer";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "./redux-reducers/rootReducer";
import { rootSaga } from "./saga/rootSaga";
import LogRocket from "logrocket";

const sagaMidleware = createSagaMiddleware();

const logger = (store: any) => (next: any) => (action: any) => {
  next(action);
  console.log(action);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMidleware, routerMiddleware(history), logger, LogRocket.reduxMiddleware()))
);

export const persistor = persistStore(store);

sagaMidleware.run(rootSaga);
