import * as Yup from "yup";

export const partsSchema = Yup.object().shape({
  description: Yup.string().label("Part Description").required("Part Description cannot be empty").max(255),
});

export const partsVendorPricingSchema = Yup.object().shape({
  skunumber: Yup.string().label("SKU Number").required("SKU Number cannot be empty"),
  lastprice: Yup.number().label("Price").required("Price cannot be empty"),
});
