//  ?  COMPONENTS
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import { VENDORS_NAME } from "resource/constants/vendors";
import { VENDORS_API } from "resource/constants/api";
import FileServerModal from "components/shared/file-server/FileServerModal";
export const nameFileUpload = `${VENDORS_NAME}/file/uploadFile`;

const VendorsFileServer = () => {
  const { data } = useModal(nameFileUpload);

  const dataForSubmit = {
    idnumber: data.idnumber,
    name: data.name,
  };

  const dataForDelete = {
    id: data.idnumber,
    name: data.name,
  };

  const dataForFetch = {
    idnumber: data.idnumber,
  };

  return (
    <FileServerModal
      name={nameFileUpload}
      title={`Vendor - ${data.name} File Server`}
      dataForSubmit={dataForSubmit}
      dataForDelete={dataForDelete}
      dataForFetch={dataForFetch}
      isFileServerExist={true}
      api={VENDORS_API}
      shouldGetCleans={true}
    />
  );
};

export default VendorsFileServer;
