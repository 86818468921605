import { useEffect, useState, FC, Fragment } from "react";

// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import useHeaders from "custom-hooks/useHeaders";

// ? STYLES
import styles from "./FilterSelect.module.css";

interface FilterSelectProps {
  api: string;
  handleChange: (evt) => void;
}

const FilterSelectWithSearch: FC<FilterSelectProps> = ({ handleChange, api }) => {
  const { tokenAPI, tokenSecret } = useSelector((state: RootStateOrAny) => state.login.userData);
  const headers = useHeaders();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const json = await fetch(api, {
        headers,
      });
      const response = await json.json();

      if (active) {
        setOptions([
          ...response.data.list.map((role) => {
            return { title: role.name, value: role.idnumber };
          }),
        ]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, api, tokenAPI, tokenSecret]);

  return (
    <Autocomplete
      className={styles.SearchField}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option: any, value) => option.title === value.title}
      getOptionLabel={(option: any) => option.title}
      options={options}
      loading={loading}
      onChange={(e, value: any) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={styles.SearchFieldInput}
          placeholder="Select company"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FilterSelectWithSearch;
