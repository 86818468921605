import { FC } from "react";

// ? COMPONENTS
import { Box, IconButton } from "@mui/material";
import useModal from "custom-hooks/useModal";

// ? RESOURCES
import folderIcon from "resource/images/folder-icon.svg";

interface FileServerIconProps {
  nameFileUpload: string;
  data: any;
}

const FileServerIcon: FC<FileServerIconProps> = ({ nameFileUpload, data }) => {
  const { open } = useModal(nameFileUpload);

  return (
    <Box sx={{ ml: "15px" }}>
      <IconButton sx={{ width: "50px", height: "auto", borderRadius: 0 }} onClick={() => open(data)}>
        <img src={folderIcon} alt="file server" />
      </IconButton>
    </Box>
  );
};

export default FileServerIcon;
