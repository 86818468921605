import { takeLatest, put } from "redux-saga/effects";
import { LOGIN_API } from "resource/constants/api";
import { post, catchError } from "./_utils-abstract-saga-network";
import { normalizeMenuList } from "resource/normalize";

import { LOGIN, LOGOUT_WORKER, authorizeUserAction, removeUserDataAction } from "../redux-actions/login";

import { setButtonLoaderAction } from "../redux-actions/loaders";

function* loginWorker(action) {
  const { payload: loginData } = action;

  yield put(setButtonLoaderAction(true));

  const { errno, message, data } = yield post(`${LOGIN_API}/login`, loginData, "urlEncode");

  if (errno !== 0) {
    yield put(setButtonLoaderAction(false));
    yield catchError(message);
  } else {
    yield put(setButtonLoaderAction(false));
    yield put(authorizeUserAction({ data, menu: normalizeMenuList(data.menulist, "menulist", "idmenu") }));
  }
}

function* logoutWorker() {
  const { errno } = yield post(`${LOGIN_API}/logout`, {}, "urlEncode");

  if (errno !== 0) {
    throw new Error("Logout failed");
  } else {
    console.log("Logout successful");
    yield put(removeUserDataAction());
    localStorage.removeItem("persist:root");
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN, loginWorker);
  yield takeLatest(LOGOUT_WORKER, logoutWorker);
}
