// ? COMPONENTS
import { Typography, Box } from "@mui/material";
import { FormField } from "components/form-field/FormField";

// ? STYLES
import styles from "./MembersProfile.module.css";

export const MemberProfileFormGeneralTab = ({ status }) => {
  return (
    <>
      <Box className={styles.Group}>
        <FormField size="100%" title="Email Address" name="email" placeholder="Enter email address" type="email" />
        <FormField size="47%" title="Password" name="password" placeholder="Enter password" type="password" />
        <FormField
          size="47%"
          title="Confirm Password"
          name="confirm_password"
          placeholder="Enter confirm password"
          type="password"
        />
        <FormField size="47%" title="Title" name="title" placeholder="Enter title" type="text" />
        <FormField size="47%" title="Status" name="inactiveyn" placeholder="Select status" type="select" options={status} />
      </Box>
      <Box className={styles.Group}>
        <Typography className={styles.GroupTitle}>Contact Information</Typography>
        <FormField size="100%" title="Company Address" name="address" placeholder="Enter company address" type="text" />
        <FormField size="47%" title="State" name="city_st" placeholder="Enter state name" type="text" />
        <FormField size="47%" title="ZIP Code" name="zipcode" placeholder="Enter ZIP code" type="number" />
        <FormField size="47%" title="Authorization Limit" name="poauth" placeholder="Enter authorization limit" type="number" />
        <FormField size="47%" title="Mobile number" name="mobile" placeholder="Enter mobile number" type="tel" />
        <FormField size="47%" title="Work number" name="office" placeholder="Enter work number" type="tel" />
        <FormField size="47%" title="Home number" name="telhome" placeholder="Enter home number" type="tel" />
      </Box>
    </>
  );
};
