import { FloorplansActions } from "redux-store/redux-actions/masterFloorplans";
import { updateCollection } from "resource/utils";
import { getUniqueData } from "resource/utils";

const filterParam = {
  fieldlist: "idbaseplan",
  formulavariablelist: "idformulavariable",
  filelist: "idnumber",
  cialist: "idnumber",
};

const initialState = {
  floorplans: [],
  floorplansCount: 0,
  floorplanDetail: {},
  floorplanFields: [],
  floorplanFieldsCount: 0,
  floorplanVariables: [],
  floorplanVariablesCount: 0,
  floorplanFiles: [],
  floorplanFilesCount: 0,
  floorplanCompanies: [],
  floorplanCompaniesCount: 0,
  masterFloorplansError: "",
};

export const masterFloorplanReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FloorplansActions.GET_FLOORPLANS:
      return {
        ...state,
        floorplans: [...state.floorplans, ...getUniqueData(payload.data, state.floorplans, "idnumber")],
        floorplansCount: payload.count,
      };
    case FloorplansActions.GET_FLOORPLAN_BY_ID:
      return {
        ...state,
        floorplans: updateCollection(state.floorplans, payload, "idnumber"),
      };
    case FloorplansActions.GET_FLOORPLAN_DETAIL_BY_ID:
      return {
        ...state,
        floorplanDetail: payload,
      };
    case FloorplansActions.PUT_FLOORPLAN_BY_ID:
      return {
        ...state,
        floorplans: [
          ...state.floorplans.map((floorplan: any) => {
            if (floorplan.idnumber === payload.idnumber) {
              return payload;
            } else {
              return floorplan;
            }
          }),
        ],
      };
    case FloorplansActions.DELETE_FLOORPLAN_FROM_STORE:
      return {
        ...state,
        floorplans: [...state.floorplans.filter((plan: any) => plan.idnumber !== payload.idnumber)],
      };
    case FloorplansActions.CLEAR_FLOORPLANS_DATA:
      return {
        ...state,
        floorplans: [],
        floorplansCount: 0,
        floorplanDetail: {},
        floorplanFields: [],
        floorplanFieldsCount: 0,
        floorplanVariables: [],
        floorplanVariablesCount: 0,
        floorplanFiles: [],
        floorplanFilesCount: 0,
        floorplanCompanies: [],
        floorplanCompaniesCount: 0,
      };
    case FloorplansActions.CLEAR_FLOORPLANS_DETAIL_DATA:
      return {
        ...state,
        [`floorplan${payload}`]: [],
        [`floorplan${payload}Count`]: 0,
      };
    case FloorplansActions.PUT_PLAN_FILE_IN_STORE:
      return {
        ...state,
        floorplanFiles: [
          ...state.floorplanFiles.map((file: any) => {
            if (file.idnumber === payload.idnumber && file.revision === payload.revision) {
              return { name: file.name, modidate: "", ...payload };
            } else {
              return file;
            }
          }),
        ],
      };
    case FloorplansActions.DELETE_PLAN_FILE_FROM_STORE:
      return {
        ...state,
        floorplanFiles: [
          ...state.floorplanFiles.map((file: any) => {
            if (file.idnumber === payload.id && file.revision === payload.revision) {
              return {
                name: file.name,
                idnumber: payload.id,
                revision: payload.revision,
                modidate: "",
                attachyn: true,
                deleteyn: false,
                viewyn: false,
              };
            } else {
              return file;
            }
          }),
        ],
        filelistCount: state.floorplanFilesCount - 1,
      };
    case FloorplansActions.PUT_FLOORPLANS_FIELDS:
      return {
        ...state,
        floorplanFields: [...state.floorplanFields, ...getUniqueData(payload.data, state.floorplanFields, "idnumber")],
        floorplanFieldsCount: payload.count as number,
      };
    case FloorplansActions.PUT_FLOORPLANS_FORMULA_VARIABLES:
      return {
        ...state,
        floorplanVariables: [...state.floorplanVariables, ...getUniqueData(payload.data, state.floorplanVariables, "idnumber")],
        floorplanVariablesCount: payload.count as number,
      };
    case FloorplansActions.PUT_FLOORPLANS_FILES:
      return {
        ...state,
        floorplanFiles: [...state.floorplanFiles, ...getUniqueData(payload.data, state.floorplanFiles, "idnumber")],
        floorplanFilesCount: payload.count as number,
      };
    case FloorplansActions.PUT_FLOORPLANS_COMPANIES:
      return {
        ...state,
        floorplanCompanies: [...state.floorplanCompanies, ...getUniqueData(payload.data, state.floorplanCompanies, "idnumber")],
        floorplanCompaniesCount: payload.count as number,
      };
    default:
      return state;
  }
};
