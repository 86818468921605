import { Colors } from "resource/consts";

export const Plans = {
  team: {
    price: 99,
    color: Colors.RED,
    subtitile: "starting at",
    btnText: "Pay now",
    features: ["Starting at 1,000 companies", "Core BuildersAccess features"],
    planName: "team",
  },
  professional: {
    price: 500,
    color: Colors.ORANGE,
    subtitle: "starting at",
    btnText: "Go Pro",
    features: ["Any number of companies", "Advanced Filters & Issues"],
    planName: "professional",
  },
  enterprise: {
    price: "Custom pricing",
    color: Colors.BLUE,
    subtitle: "contact us",
    btnText: "Ask for a Deal",
    features: ["Any number of companies", "Advanced Filters & Issues", "Privacy controls", "Data export"],
    planName: "enterprise",
  },
};
