import { useState, useEffect, FC } from "react";

// ? REDUX
import { editAssemblingAction, fetchEditAssemblyAction } from "redux-store/redux-actions/assemblies";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { CircularProgress } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import { AssembliesFormUpdate } from "pages/assemblies/AssembliesFormUpdate";

// ? STYLES
import styles from "pages/developments/Developments.module.css";

// ? RESOURCES
import { ASSEMBLIES, ASSEMBLIES_NAME } from "resource/constants/assemblies";
import { ActionName } from "resource/constants/api";

export const nameUpdate = `${ASSEMBLIES_NAME}/${ActionName.UPDATE}`;
interface AssembliesUpdateProps {
  className: string;
}

interface Stage {
  name: string;
}

const AssembliesUpdate: FC<AssembliesUpdateProps> = ({ className }) => {
  const { close, isOpened, data } = useModal(nameUpdate);
  const dispatch = useDispatch();

  const assembling = useSelector((state: RootStateOrAny) => state.assemblies.editAssembling);

  const [isLoading, setLoader] = useState(false);
  const updateAssembling = useAsync(editAssemblingAction);

  const getSplits = (): Stage[] => {
    let filteredStages: any[] = [];
    for (const el in assembling) {
      if (el.indexOf("stage") !== -1 && assembling[el] !== 0) {
        filteredStages = [...filteredStages, { [el]: data[el] }];
      }
    }

    if (filteredStages.length) {
      return filteredStages.map((stage: Stage[]) => {
        return { name: `St${Object.keys(stage)[0].slice(5)}` };
      });
    } else {
      return [{ name: "St1" }];
    }
  };

  const [splits, setSplit] = useState([{ name: "St1" }]);

  const createSplitRow = (): void => {
    if (splits.length < 10) {
      setSplit((prev): any => [...prev, { name: `St${splits.length + 1}` }]);
    }
  };

  const removeSplitRow = (setFieldValue): void => {
    if (splits.length > 1) {
      setFieldValue(`st${splits.length}des`, "");
      setFieldValue(`stage${splits.length}`, 0);
      setSplit((prev): any => {
        return [...prev.slice(0, prev.length - 1)];
      });
    } else if (splits.length === 1) {
      setFieldValue(`st${splits.length}des`, "");
      setFieldValue(`stage${splits.length}`, 0);
      setSplit([{ name: `St${splits.length}` }]);
    }
  };

  const [parts, setParts] = useState([
    {
      tempId: Date.now(),
      idnumber: 0,
      upc: "",
      description: "",
      qty: 0,
      price: 0,
      amount: 0,
      notes: "",
      lumber: 0,
      updateyn: false,
      deleteyn: false,
    },
  ]);

  const createPartRow = (): void => {
    if (parts.length < 30 && parts[parts.length - 1].upc) {
      setParts((prev): any => [
        ...prev,
        {
          tempId: Date.now(),
          idnumber: 0,
          upc: "",
          description: "",
          qty: 0,
          price: 0,
          amount: 0,
          notes: "",
          lumber: 0,
          updateyn: false,
          deleteyn: false,
        },
      ]);
    }
  };

  const removePartRow = (id) => {
    if (parts.length > 1) {
      setParts((parts): any => [...parts.filter((part) => part.tempId !== id)]);
    } else if (parts.length === 1) {
      setParts([
        {
          tempId: Date.now(),
          idnumber: 0,
          upc: "",
          description: "",
          qty: 0,
          price: 0,
          amount: 0,
          notes: "",
          lumber: 0,
          updateyn: false,
          deleteyn: false,
        },
      ]);
    }
  };

  const handleSubmit = async (data) => {
    setLoader(true);

    try {
      data.list = parts;
      await updateAssembling({
        values: data,
        actionType: ActionName.UPDATE,
      });
      setLoader(false);
      setParts([
        {
          tempId: Date.now(),
          idnumber: 0,
          upc: "",
          description: "",
          qty: 0,
          price: 0,
          amount: 0,
          notes: "",
          lumber: 0,
          updateyn: false,
          deleteyn: false,
        },
      ]);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isOpened) {
      dispatch(fetchEditAssemblyAction({ idnumber: data.idnumber }));
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened && assembling.list.length > 0) {
      setParts(
        assembling.list.map((part) => {
          return { ...part, updateyn: false, deleteyn: false };
        })
      );
    }
    if (isOpened && assembling) {
      setSplit(getSplits());
    }
  }, [assembling]);

  useEffect(() => {
    if (parts.every((part) => part.deleteyn)) {
      setParts((parts) => {
        return [
          ...parts,
          {
            tempId: Date.now(),
            idnumber: 0,
            upc: "",
            description: "",
            qty: 0,
            price: 0,
            amount: 0,
            notes: "",
            lumber: 0,
            updateyn: false,
            deleteyn: false,
          },
        ];
      });
    }
  }, [parts]);

  return (
    <ModalManager name={nameUpdate} className={className}>
      {isOpened && assembling ? (
        <div className={styles.DevelopmentsAddForm}>
          <AssembliesFormUpdate
            title={ASSEMBLIES.EDIT_MODAL_TITLE}
            buttonText={ASSEMBLIES.EDIT_MODAL_SUBMIT_BUTTON}
            closeModal={close}
            data={assembling}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            splits={splits}
            createSplitRow={createSplitRow}
            removeSplitRow={removeSplitRow}
            parts={parts}
            setParts={setParts}
            createPartRow={createPartRow}
            removePartRow={removePartRow}
          />
        </div>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default AssembliesUpdate;
