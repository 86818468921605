import { useState, useEffect } from "react";

// ? REDUX
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { Formik, Form, ErrorMessage } from "formik";
import { IconButton, Typography, Grid, CircularProgress, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { FormField } from "components/form-field/FormField";
import ProjectsDetailGeneralTab from "./ProjectsDetailGeneralTab";
import { SearchField } from "components/form-field/SearchField";

// ? STYLES
import styles from "./Projects.module.css";

// ? RESOURCES
import { DEVELOPMENTS_API, MASTER_FLOORPLANS_API } from "resource/constants/api";
import { useSearch } from "custom-hooks/useSearch";
import closeButtonIcon from "resource/images/close-btn.svg";
import { projectsSchema } from "resource/schemas/projectsSchema";

// ? TYPES
const FORM_TYPE = {
  small: "",
  large: styles.CreateFormLarge,
  xlarge: styles.CreateFormExtraLarge,
};

import { ValidationError } from "yup";

export const ProjectsForm = ({ title, formData, buttonText, handleClose, actionType, handleSubmit, isLoading }) => {
  const theme = useTheme();
  const modalLoading = useSelector((state: RootStateOrAny) => state.loaders.modalLoader);

  const [dev, setDev] = useState<any>(null);
  const [devOptions, setDevQuery, isDevPending, devInitialCondition, devErrorMessage] = useSearch(DEVELOPMENTS_API, {
    status: "-All-",
  });
  const [planOptions, setPlanQuery, isPlanPending, planInitialCondition, planErrorMessage] = useSearch(MASTER_FLOORPLANS_API);
  const [banksOptions, setBanksQuery, isBanksPending, initialBanksCondition, banksErrorMessage] = useSearch(
    `${DEVELOPMENTS_API}/bank`,
    dev && { idcia: dev.idcia }
  );

  const initialFormValues = {
    devid: null,
    idcia: null,
    idnumber: null,
    name: null,
    idplan1: null,
    taddress: null,
    tcity: null,
    tstate: null,
    tzip: null,
    bankledger: null,
  };

  const [localFieldList, setLocalFieldList] = useState([]);

  const formikValues = initialFormValues;

  const preparedData = (values) => {
    const valuesCopy = values;
    delete valuesCopy.devid;
    if (localFieldList.length > 0) {
      valuesCopy.fieldList = localFieldList
        .filter((field: any) => field.changed)
        .map((field: any, index) => {
          return {
            idnumber: field.idnumber,
            xid: field.xid,
            name: field.name,
            xtype: field.xtype,
            txtvalue: field.txtvalue || "",
          };
        });
    }

    return valuesCopy;
  };

  return (
    <div className={styles.CreateForm}>
      {modalLoading ? (
        <CircularProgress className={styles.Loader} />
      ) : (
        <Formik
          initialValues={formikValues}
          onSubmit={(values: any) => handleSubmit(preparedData(values))}
          validationSchema={projectsSchema}
        >
          {({ handleChange, values, setFieldValue, handleSubmit }) => (
            <div className={styles.ProjectsForm}>
              <Form className={styles.UpdateForm}>
                <div className={styles.FormHeader}>
                  <Typography variant="h1" color={theme.palette.text.primary}>
                    {title}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <img src={closeButtonIcon} alt="close-button" />
                  </IconButton>
                </div>

                <Grid className={styles.SubForm} container justifyContent="space-between">
                  <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs="auto" sm={6} lg={3}>
                      <SearchField
                        size="100%"
                        title="Development"
                        responseName="idnumber"
                        name="devid"
                        label="name"
                        placeholder="Search development"
                        setFieldValue={setFieldValue}
                        initialFormValues={initialFormValues}
                        options={devOptions}
                        onQueryChange={setDevQuery}
                        isPending={isDevPending}
                        initialCondition={devInitialCondition}
                        callback={(value) => {
                          if (value !== null && value !== undefined) {
                            setDev(value);
                            setFieldValue("idcia", value.idcia);
                          } else if (value === null) {
                            setDev(null);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Project Name"
                        name="name"
                        placeholder="Enter project name"
                        type="text"
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <SearchField
                        size="100%"
                        title="IDplan"
                        responseName="idnumber"
                        name="idplan1"
                        label="name"
                        placeholder="Search florrplan ID"
                        setFieldValue={setFieldValue}
                        initialFormValues={initialFormValues}
                        options={planOptions}
                        onQueryChange={setPlanQuery}
                        isPending={isPlanPending}
                        initialCondition={planInitialCondition}
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Address"
                        name="taddress"
                        placeholder="Enter address"
                        type="text"
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="City"
                        name="tcity"
                        placeholder="Enter city name"
                        type="text"
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="State"
                        name="tstate"
                        placeholder="Enter state name"
                        type="text"
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <FormField
                        size="100%"
                        title="Zip Code"
                        name="tzip"
                        placeholder="Enter zip code"
                        type="number"
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                    <Grid item xs="auto" sm={6} lg={3}>
                      <SearchField
                        size="100%"
                        title="Prefered Bank Account"
                        responseName="bankledger"
                        name="bankledger"
                        label="name"
                        placeholder="Search bank account"
                        setFieldValue={setFieldValue}
                        initialFormValues={initialFormValues}
                        options={banksOptions}
                        onQueryChange={setBanksQuery}
                        isPending={isBanksPending}
                        initialCondition={initialBanksCondition}
                        disabled={dev === null ? true : false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>

              <ProjectsDetailGeneralTab idnumber={0} idcia={1} callback={setLocalFieldList} />

              <div className={styles.FormFooter}>
                <SubmitButton
                  text={buttonText}
                  large
                  isLoading={isLoading}
                  callback={(e) => {
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
