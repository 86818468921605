import { AUTHORIZE_USER, CATCH_AUTHORISATION_ERROR, REMOVE_USER_DATA } from "redux-store/redux-actions/login";

const initialState = {
  userData: {},
  menuList: {},
  isAuthorized: false,
  authorisationError: "",
  readAccess: [],
};

const flattenAccess = (list) => {
  return list.reduce((acc: any[], { idmenu }) => {
    return acc.concat(idmenu);
  }, []);
};

export const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTHORIZE_USER:
      return {
        ...state,
        userData: { ...payload.data, menulist: [] },
        isAuthorized: true,
        menuList: payload.menu.entities.menulist,
        readAccess: flattenAccess(Object.values(payload.menu.entities.menulist)),
      };
    case REMOVE_USER_DATA:
      return {
        ...state,
        userData: {},
        isAuthorized: false,
        menuList: {},
        readAccess: [],
      };
    default:
      return state;
  }
};
