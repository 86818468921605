import { FC } from "react";
//  ?  COMPONENTS
import { Formik, Form, Field } from "formik";
import { IconButton, Box, Typography, useTheme, InputLabel, MenuItem, Select } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { getLumber } from "resource/consts";

// ? STYLES
import styles from "pages/purchase-order/PurchaseOrder.module.css";

const initialValues = {
  lumber: 8,
};

interface PurchaseOrderLumberFormProps {
  handleSubmit: (data: any) => void;
  handleClose: () => void;
  buttonText: string;
  description: string;
}

const PurchaseOrderLumberForm: FC<PurchaseOrderLumberFormProps> = ({ handleSubmit, buttonText, handleClose, description }) => {
  const theme = useTheme();

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form className={styles.PoLumberForm}>
          <Box display="flex" justifyContent="space-between" sx={{ mb: "15px" }}>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {`Lumber ${description}`}
            </Typography>
            <IconButton onClick={handleClose}>
              <img src={closeButtonIcon} alt="close-button" />
            </IconButton>
          </Box>
          <InputLabel htmlFor="lumber">
            <Typography variant="body1" color={theme.palette.info.main}>
              Choose Lumber
            </Typography>
          </InputLabel>
          <Field name="lumber" id="lumber" type="select" as={Select}>
            {getLumber().map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Field>
          <Box justifyContent="flex-start">
            <SubmitButton text={buttonText} isLoading={false} />
          </Box>
        </Form>
      </Formik>
    </>
  );
};

export default PurchaseOrderLumberForm;
