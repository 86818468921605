// ? REDUX
import { useSelector, RootStateOrAny } from "react-redux";

// ? COMPONENTS
import { CompanyCard } from "components/info-card/CompanyCard";
import { Grid } from "@mui/material";
import NoDataScreen from "components/shared/no-data-screen/NoDataScreen";

export const CompaniesList = ({ companies }) => {
  const paginationLoading = useSelector((state: RootStateOrAny) => state.loaders.paginationLoader);
  const addNewLoader = useSelector((state: RootStateOrAny) => state.loaders.addNewLoader);

  if (!paginationLoading && !companies.length) {
    return <NoDataScreen pageName="company" />;
  }

  return (
    <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
      {addNewLoader && (
        <Grid item xs="auto" sm={6} lg={4}>
          <CompanyCard data={{}} fake />
        </Grid>
      )}
      {companies.map((company) => (
        <Grid key={company.idnumber} item xs="auto" sm={6} lg={4}>
          <CompanyCard data={company} />
        </Grid>
      ))}
    </Grid>
  );
};
