// ? ACTIONS
export const LOGIN = "LOGIN";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const LOGOUT_WORKER = "LOGOUT_WORKER";
export const AUTHORIZE_USER = "AUTHORIZE_USER";
export const CATCH_AUTHORISATION_ERROR = "GET_AUTHORISATION_ERROR";

// ? ACTION CREATORS

export const loginAction = (payload: Record<string, unknown>) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const removeUserDataAction = () => {
  return {
    type: REMOVE_USER_DATA,
  };
};

export const logoutWorkerAction = () => {
  return {
    type: LOGOUT_WORKER,
  };
};

export const authorizeUserAction = (payload) => {
  return {
    type: AUTHORIZE_USER,
    payload,
  };
};
