import { useState } from "react";

//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { Box, Button, Typography, IconButton, useTheme } from "@mui/material";
import { SubmitButton } from "components/submit-button/SubmitButton";
import { useNotice } from "custom-hooks/useNotice";

// ? RESOURCES
import closeButtonIcon from "resource/images/close-btn.svg";
import { noticeTypes } from "resource/consts";

// ? STYLES
import styles from "pages/parts/Parts.module.css";

export const nameConfirm = `takeoff/confirm`;

const TakeoffImportConfirm = () => {
  const theme = useTheme();

  const [setNotice] = useNotice();
  const { close, data, isOpened } = useModal(nameConfirm);
  const [isLoading, setLoader] = useState<boolean>(false);

  const handlePlanImport = async () => {
    setLoader(true);
    try {
      await data.action({
        values: data.valuesForSubmit,
        endpoint: data.endpoint,
        dataForFetch: data.dataForFetch,
      });
      setNotice("Takeoff was successfully imported", noticeTypes.success, 4000);
      setLoader(false);
      close();
    } catch (e) {
      console.error(e);
      setLoader(false);
    }
  };

  return (
    <>
      {isOpened && (
        <ModalManager name={nameConfirm}>
          <Box sx={{ width: "480px", minWidth: "480px" }} className={styles.PartsConfirm}>
            <Box className={styles.PartsConfirmTop} display="flex" justifyContent="space-between">
              <Typography className={styles.PartsConfirmHeading} variant="h1" color={theme.palette.text.primary}>
                Update Part?
              </Typography>
              <IconButton onClick={close}>
                <img src={closeButtonIcon} alt="close-button" />
              </IconButton>
            </Box>
            <Typography
              color={theme.palette.text.secondary}
            >{`Are you sure you want to import takeoff? After imported, the current information will be replaced. Are you sure you want to continue?`}</Typography>
            <Box display="flex" justifyContent="flex-start" sx={{ marginTop: "10px" }}>
              <Button onClick={close} className={styles.PartsCancelButton}>
                Cancel
              </Button>
              <SubmitButton callback={() => handlePlanImport()} isLoading={isLoading} text="Confirm" large type="submit" />
            </Box>
          </Box>
        </ModalManager>
      )}
    </>
  );
};

export default TakeoffImportConfirm;
