// ? COMPONENTS
import { Grid, Typography, useTheme } from "@mui/material";
import { TableListHeader } from "components/shared/table-list/TableList";

export const MasterFloorplansListHeader = () => {
  const theme = useTheme();

  return (
    <TableListHeader>
      <Grid item xs={1.5} md={1.5}>
        <Typography color={theme.palette.primary.main}>Plan Number</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography color={theme.palette.primary.main}>Plan Name</Typography>
      </Grid>
      <Grid item xs={2} md={2}>
        <Typography color={theme.palette.primary.main}>Marketing Name</Typography>
      </Grid>
      <Grid item xs={1} md={1}>
        <Typography color={theme.palette.primary.main}>Revision</Typography>
      </Grid>
      <Grid item xs={1.5} md={1.5}>
        <Typography color={theme.palette.primary.main}>Status</Typography>
      </Grid>
    </TableListHeader>
  );
};
