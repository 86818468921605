//  ?  COMPONENTS
import ModalManager from "../ModalManager";
import useModal from "custom-hooks/useModal";
import { importPlanTakeoffAction } from "redux-store/redux-actions/projectTakeoffs";
import { CircularProgress } from "@mui/material";
import useAsync from "custom-hooks/useAsync";
import TakeoffsImportPlanForm from "pages/takeoffs/TakeoffsImportPlanForm";

// ? RESOURCES
import { PROJECT_TAKEOFFS_NAME, TAKEOFFS, TakeoffEndpoints } from "resource/constants/takeoffs";
import { ActionName } from "resource/constants/api";

// ? STYLES
import styles from "pages/takeoffs/Takeoffs.module.css";
import { nameConfirm } from "components/modals/confirm/TakeoffImportConfirm";
export const nameAddPlanImport = `${PROJECT_TAKEOFFS_NAME}PlanImport/${ActionName.CREATE}`;

const initFormValues = {
  idplan1: "",
};

const ProjectTakeoffPlanImport = () => {
  const { close, isOpened, data } = useModal(nameAddPlanImport);
  const { open: openImportConfirm } = useModal(nameConfirm);
  const importPlanTakeoff = useAsync(importPlanTakeoffAction);

  const handleSubmit = (formData) => {
    if (formData.idplan1) {
      close();
      const valuesForSubmit = { idproject1: String(data.id), idcia: String(data.idcia), idplan1: String(formData.idplan1) };

      setTimeout(
        () =>
          openImportConfirm({
            valuesForSubmit,
            endpoint: TakeoffEndpoints.ImportFromPlan,
            action: importPlanTakeoff,
            dataForFetch: { id: data.id, idcia: data.idcia },
          }),
        150
      );
    }
  };

  return (
    <ModalManager name={nameAddPlanImport}>
      {isOpened ? (
        <>
          <div className={styles.AddForm}>
            <TakeoffsImportPlanForm
              title={TAKEOFFS.IMPORT_PLAN}
              formData={initFormValues}
              buttonText="Save"
              handleClose={close}
              actionType={ActionName.CREATE}
              handleSubmit={handleSubmit}
              isLoading={false}
            />
          </div>
        </>
      ) : (
        <CircularProgress className="Loader" />
      )}
    </ModalManager>
  );
};

export default ProjectTakeoffPlanImport;
